import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { confirmAlert } from "react-confirm-alert";
import StripePaymentModal from "../Modal/StripePaymentModal";
import InPageNav from "../components/PageNav/InPageNav";
import imagePlaceholder from "../images/placeholder.png";
import wallet from "../images/wallet.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import AddBalanceModal from "../Modal/AddBalanceModal";
import Loader, { LoaderType } from "../components/Loader";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";

const CartConfirmation = () => {
  let param = useParams();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [clientSecret, setClientSecret] = useState("");
  const [PaymentData, setPaymentData] = useState();

  const pageNav = [
    {
      name: "Cart",
      link: "/cart",
      active: false,
    },
    {
      name: "Confirmation",
      link: "/cart-confirmation",
      active: true,
    },
  ];
  const [Loading, setLoading] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [StripModalShow, setStripModalShow] = useState(false);
  const [CartData, setCartData] = useState([]);
  const [WalletModalShow, setWalletModalShow] = useState(false);
  const [SubTotal, setSubTotal] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [TotalSummary, setTotalSummary] = useState([]);
  const [CurrentBalance, setCurrentBalance] = useState(0);
  const [TotalItems, setTotalItems] = useState(0);
  let navigate = useNavigate();

  async function getCartList() {
    setLoading(true);

    if (param.id !== null) {
      let body = {
        cart_id: param.id,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CART_GET_ORDER_CONFIRMATION_DETAILS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        setTotalAmount(response?.data?.data?.total_amount);
        setTotalSummary(response?.data?.data?.sub_list);
        setCartData(response?.data.data.list);
        setTotalItems(response?.data.data.total_items);
        setCurrentBalance(response?.data.data.current_balance);
        const subtotalItem = response?.data.data?.sub_list.find(
          (item) => item.title === "Subtotal"
        );
        if (subtotalItem) {
          const subtotalValue = subtotalItem.value;
          setSubTotal(subtotalValue);
        }
        const subtotalItem1 = response?.data.data?.sub_list.find(
          (item) => item.title === "Total Amount"
        );
        // if (subtotalItem1) {

        //   const subtotalValue1 = subtotalItem1.value;
        //   setTotalAmount(subtotalValue1);
        // }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CART_GET_ORDER_CONFIRMATION_DETAILS,
        },
        false
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        setCartData(response?.data.data.list);
        setTotalItems(response?.data.data.total_items);
        setCurrentBalance(response?.data.data.current_balance);
        const subtotalItem = response?.data.data?.sub_list.find(
          (item) => item.title === "Subtotal"
        );
        if (subtotalItem) {
          const subtotalValue = subtotalItem.value;
          setSubTotal(subtotalValue);
        }
        const subtotalItem1 = response?.data.data?.sub_list.find(
          (item) => item.title === "Total Amount"
        );
        if (subtotalItem1) {
          const subtotalValue1 = subtotalItem1.value;
          setTotalAmount(subtotalValue1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  }
  const handleAddBalanceModal = () => {
    setWalletModalShow(true);
  };

  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }

  useEffect(() => {
    getCartList();
  }, []);

  const handleCheckboxChange = (event) => {
    if (CurrentBalance !== null) {
      setChecked(event.target.checked);
    } else {
      confirmAlert({
        title: "Your wallet balance is too low.please add money in walllet.",
        // message: "This action cannot be undone",
        buttons: [
          {
            label: "No",
          },
          {
            label: "Add Money",
            onClick: () => handleAddBalanceModal(),
            // onClick=() => handleAddBalanceModal(),
          },
        ],
      });
    }

    // You can do more with the checked state if needed
  };

  async function fnStripePayment() {
    let body = {
      payment_via: "payment_method",
    };
    CartData.forEach((item, index) => {
      body[`list[${index}][cart_id]`] = item.cart_id;
    });

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_BOOK,
        body: body,
      },
      true
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setClientSecret(response?.data?.data.payment_intent.client_secret);
      setPaymentData(response?.data.data);

      setStripModalShow(true);
    }
    if (!response?.isValidate) {
      console.log("Error when register new user", response);
    }
  }

  async function fnWalletPayment(item) {
    let body = {
      payment_via: "wallet",
    };
    CartData.forEach((item, index) => {
      body[`list[${index}][cart_id]`] = item.cart_id;
    });

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_BOOK,
        body: body,
      },
      true
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      // setClientSecret(response?.data?.data.payment_intent.client_secret);
      // setPaymentData(response?.data.data);
      navigate(
        `/payment-success/${response?.data?.data?.order_details?.order_id}`
      );

      // setStripModalShow(true);
    }
    if (!response?.isValidate) {
      console.log("Error when register new user", response);
    }
  }

  console.log("TotalAmount ::", TotalAmount);
  return (
    <>
      {/* {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm TotalAmount={TotalAmount} />
        </Elements>
      )} */}
      <div className="cart-confirmation-page-main">
        <div className="small-container container">
          <h6 className="cart-title">
            <div className="show-route">
              <InPageNav pageNav={pageNav} />
            </div>
          </h6>
          <Row className="cart-page-wrapper">
            <Col xl={7}>
              <div className="cart-page-left-main">
                {Loading ? (
                  <LoaderOverlay />
                ) : (
                  <div className="">
                    {CartData?.map((item, index) => (
                      <>
                        <div className="my-cart-item-combine">
                          {item?.items?.length > 0 &&
                            item?.items.map((item_b, index_b) =>
                              item_b?.item_type === "club_booking" ? (
                                <div
                                  className={
                                    item_b.item_type === "club_booking"
                                      ? "activity-section-bottom-single activity-section-bottom-single-diff position-relative club_booking"
                                      : "activity-section-bottom-single activity-section-bottom-single-diff position-relative"
                                  }
                                >
                                  <div className="activity-section-bottom-single-top">
                                    <p>
                                      {commonservices.getDayDateMonth(
                                        item_b?.start_time,
                                        item_b?.timezone
                                      )}{" "}
                                      |{" "}
                                      {commonservices.getTimeInFormat(
                                        item_b?.start_time,
                                        item_b?.timezone
                                      )}{" "}
                                      -{" "}
                                      {commonservices.getTimeInFormat(
                                        item_b?.end_time,
                                        item_b?.timezone
                                      )}
                                    </p>
                                    <span>{fnHour(item_b?.duration)}</span>
                                  </div>
                                  <div className="activity-section-bottom-single-bottom d-flex align-items-start gap-2">
                                    <div>
                                      <img
                                        src={imagePlaceholder}
                                        alt="imagePlaceholder"
                                        className="my-image"
                                      />
                                    </div>
                                    <div className="">
                                      <p>{item_b.court_title}</p>
                                      <h6>{item_b.club_name}</h6>
                                      <p>{item_b.game_type_term}</p>
                                      <h6 className="m-0">
                                        $
                                        {item_b?.total_amount === 0
                                          ? "0.00"
                                          : item_b?.total_amount?.toFixed(2)}
                                      </h6>
                                      {item_b?.cart_item_status ===
                                        "not_available" && (
                                        <p className="font-red">
                                          <b>Not available</b>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            )}

                          {item?.items?.length > 0 &&
                            item?.items.map((item_b, index_b) =>
                              item_b?.item_type === "trainer_booking" ? (
                                <div
                                  className={
                                    item_b.item_type === "club_booking"
                                      ? "activity-section-bottom-single activity-section-bottom-single-diff position-relative trainer_booking"
                                      : "activity-section-bottom-single activity-section-bottom-single-diff position-relative"
                                  }
                                >
                                  <div className="activity-section-bottom-single-top">
                                    <p>
                                      {commonservices.getDayDateMonth(
                                        item_b?.start_time,
                                        item_b?.timezone
                                      )}{" "}
                                      |{" "}
                                      {commonservices.getTimeInFormat(
                                        item_b?.start_time,
                                        item_b?.timezone
                                      )}{" "}
                                      -{" "}
                                      {commonservices.getTimeInFormat(
                                        item_b?.end_time,
                                        item_b?.timezone
                                      )}
                                    </p>
                                    <span>{fnHour(item_b?.duration)}</span>
                                  </div>
                                  <div className="activity-section-bottom-single-bottom d-flex align-items-start gap-2">
                                    <div>
                                      <img
                                        src={imagePlaceholder}
                                        alt="imagePlaceholder"
                                        className="my-image"
                                      />
                                    </div>
                                    <div className="">
                                      <p>Trainer</p>
                                      <h6>{item_b.trainer_name}</h6>
                                      <h6>
                                        $
                                        {item_b?.total_amount === 0
                                          ? "0.00"
                                          : item_b?.total_amount?.toFixed(2)}
                                      </h6>
                                      {item_b?.cart_item_status ===
                                        "not_available" && (
                                        <p className="font-red">
                                          <b>Not available</b>
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ) : null
                            )}
                        </div>
                      </>
                    ))}
                  </div>
                )}
              </div>
            </Col>
            <Col xl={5}>
              <div className="cart-page-right-main">
                <h5>Summary</h5>
                <div className="summary-inner">
                  {TotalSummary?.length > 0 &&
                    TotalSummary.map((item_tt, index_tt) => (
                      <div className="summary-inner-sub-total">
                        <p>{item_tt?.title}</p>
                        <p>{item_tt?.value}</p>
                      </div>
                    ))}
                  {/* <div className="summary-inner-sub-total">
                    <p>Total Amount</p>
                    <p>{TotalAmount}</p>
                  </div> */}
                </div>
                <div className="cart-page-payment-method">
                  <h5>Payment Method</h5>

                  <div className="cart-page-payment-inner">
                    <div className="cart-page-payment-inner-img">
                      <img src={wallet} alt="credit" />
                      <p>
                        Wallet($
                        {CurrentBalance !== "" && CurrentBalance !== null
                          ? CurrentBalance.toFixed(2)
                          : 0}
                        )
                      </p>
                    </div>
                    <Form.Check
                      type="checkbox"
                      className="circular-checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                </div>
                <div className="continue-payment-btn mt-4">
                  <button className="red-btn red-border w-100">
                    {isChecked ? (
                      <div onClick={() => fnWalletPayment(TotalAmount)}>
                        Pay $
                        {TotalAmount === 0 ? "0.00" : TotalAmount?.toFixed(2)}
                      </div>
                    ) : (
                      <div onClick={() => fnStripePayment(TotalAmount)}>
                        Pay $
                        {TotalAmount === 0 ? "0.00" : TotalAmount?.toFixed(2)}
                      </div>
                    )}
                    {/* <Elements stripe={stripePromise}> */}
                    {/* <form onSubmit={handleSubmit}>
                     
                      <button type="submit" disabled={!stripe}>
                        Pay
                      </button>
                    </form> */}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {StripModalShow && (
            <StripePaymentModal
              PaymentData={PaymentData}
              // bindList={getSlotbyDate}
              show={StripModalShow}
              // AvailableCartData={AvailableCartData}
              // ClubDetail={ClubDetail}
              onHide={() => setStripModalShow(false)}
            />
          )}
        </Elements>
      )}
      {WalletModalShow && (
        <AddBalanceModal
          // WalletList={WalletList}
          bindList={getCartList}
          PayType="confirmpage"
          show={WalletModalShow}
          CurrentBalance={CurrentBalance}
          // WalletCurrentBalance={}
          // AvailableCartData={AvailableCartData}
          // ClubDetail={ClubDetail}
          onHide={() => setWalletModalShow(false)}
        />
      )}
    </>
  );
};

export default CartConfirmation;
