import React, { useEffect, useState } from "react";
import Loader, { LoaderType } from "../Loader";
import imagePlaceholder from "../../images/placeholder.png";
import { apiCall } from "../../services/AxiosService";
import { API_URL } from "../../services/APIRoute";
import { apiResponse } from "../../services/APIResponse";

const BlokedUserComponent = () => {
  const [BlockedLoader, setBlockedLoader] = useState(false);
  const [BlockedList, setBlockedList] = useState([]);
  const [pageNumberBlocked, setPageNumberBlocked] = useState(1);
  const [RemoveMoreButtionBlocked, setRemoveMoreButtionBlocked] =
    useState(false);
  async function fnUnblocked(item) {
    let body = {
      member_id: item?.member_id,
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_UNBLOCK,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getBlockedList(pageNumberBlocked);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  async function getBlockedList(pageno) {
    setBlockedLoader(true);
    let body = {
      page: pageno,
      limit: 10,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_BLOCK_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setBlockedLoader);
    if (response?.isValidate) {
      if (pageno === 1) {
        setBlockedList(response?.data?.data?.list);
      } else {
        setBlockedList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionBlocked(true);
      }
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumberBlocked(pageno + 1);
      }
    }
  }
  const handleLoadMoreBlocked = () => {
    getBlockedList(pageNumberBlocked);
  };
  useEffect(() => {
    getBlockedList(pageNumberBlocked)
  }, [])
  
  
  return (
    <div className="settings-right-side-main">
      <div className="FindClubCard-main">
        <h5>Blocked User</h5>

        {BlockedLoader && !BlockedList?.length > 0 ? (
          <>
            <div>
              <Loader type={LoaderType.InviteFriend} count={4} />
            </div>
          </>
        ) : (
          <>
            {BlockedList?.length > 0 ? (
              BlockedList?.map((item_fw, index_fw) => (
                <div className="d-flex align-items-center profile-and-time mb-3 justify-content-between scroll-down">
                  <div className="d-flex align-items-center gap-2">
                    <div className="write-something-profile">
                      {item_fw?.profile_pic !== null &&
                      item_fw?.profile_pic !== "" &&
                      item_fw?.profile_pic !== undefined ? (
                        <img
                          src={item_fw?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                        />
                      ) : (
                        <img src={imagePlaceholder} alt="club-detail-image" />
                      )}
                    </div>
                    <div className="username-with-time">
                      <h5 className="mb-0">
                        {item_fw?.member_name}
                        {item_fw?.member_type === "Standard" && (
                          <span className="profile-after-initials">S</span>
                        )}
                      </h5>
                      <p>{item_fw?.nickname}</p>
                    </div>
                  </div>
                  <button
                    onClick={() => fnUnblocked(item_fw)}
                    // onClick={() =>
                    //   fnAddRemoveFollowing(
                    //     item_fw,
                    //     item_fw?.is_following === 1 ? 0 : 1
                    //   )
                    // }
                    className="gray-btn"
                    style={{
                      height: "34px",
                      fontSize: "14px",
                      width: "100px",
                    }}
                  >
                    Unblock
                    {/* {item_fw?.is_following === 1 ? "Unfollow" : "Follow"} */}
                  </button>
                </div>
              ))
            ) : (
              <Loader type={LoaderType.NODATAFOUND} />
            )}
          </>
        )}
        {BlockedLoader && BlockedList?.length > 0 && (
          <Loader type={LoaderType.InviteFriend} count={4} />
        )}

        {BlockedList?.length > 0 && (
          <>
            {!RemoveMoreButtionBlocked && (
              <div className="text-center w-100 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMoreBlocked}
                  disabled={BlockedLoader}
                >
                  {BlockedLoader ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default BlokedUserComponent;
