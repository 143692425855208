import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import locationIcon from "../images/locationIcon.svg";
import information from "../images/informaton.svg";
import websiteIcon from "../images/Website.svg";
import threedots from "../images/threeDots.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvitePlayerModal from "../Modal/InvitePlayerModal";
import RateCourtModal from "../Modal/RateCourtModal";
import RatePeopleModal from "../Modal/RatePeopleModal";
import UploadScoreModal from "../Modal/UploadScoreModal";
import InPageNav from "../components/PageNav/InPageNav";
import addbtn from "../images/addwithbg.svg";
import call from "../images/black-call.svg";
import email from "../images/black-email.svg";
import location from "../images/black-location.svg";
import calendarsmall from "../images/calendarsmall.svg";
import clock from "../images/clock.svg";
import deleteIcon from "../images/deleteIcon.svg";
import pencil from "../images/editPen.svg";
import imagePlaceholder from "../images/placeholder.png";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import rateCourt from "../images/rateCourt.svg";
import share from "../images/shareIcon.svg";

import star from "../images/transparentStar.svg";
import uploadScore from "../images/uploadScore.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { useSelector } from "react-redux";
import moment from "moment";
import CalncelBokkingDetailModal from "../Modal/CalncelBokingDetailModal";
import CalncelBokingDetailModal from "../Modal/CalncelBokingDetailModal";
import PlayerBookingModal from "../Modal/PlayerBookingModal";
import StarRateModal from "../Modal/StarRateModal";
import UploadScoreModalMain from "../Modal/UploadScoreModal";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";

function BookingDeatil() {
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [PolicyHour, setPolicyHour] = useState(24);
  const [BookingDetailLoader, setBookingDetailLoader] = useState(false);
  const [StartHour, setStartHour] = useState(false);

  const [CancelBookingModalShow, setCancelBookingModalShow] = useState(false);
  const [StarRateModalShow, setStarRateModalShow] = useState(false);
  const [BookingPlayerModalShow, setBookingPlayerModalShow] = useState(false);
  let navigate = useNavigate();
  // Get the current date and time
  const currentDate = new Date();

  const pageNav = [
    {
      name: "Club",
      link: "/find-club",
      active: true,
    },
    {
      name: "My Booking",
      link: "/my-booking",
      active: true,
    },
    {
      name: "Booking Detail",
      link: "/booking-detail",
      active: false,
    },
  ];
  let param = useParams();
  // console.log("param ::", param);
  const [BookingDetail, setBookingDetail] = useState();
  const [RateModalType, setRateModalType] = useState("");
  const [RatePlayerType, setRatePlayerType] = useState("");
  const [PlayerModalType, setPlayerModalType] = useState("");
  const [RateCourtModalShow, setRateCourtModalShow] = useState(false);
  const [UploadRateCourtModalShow, setUploadRateCourtModalShow] =
    useState(false);
  const [InviteModalShow, setInviteModalShow] = useState(false);
  // const [showRateCourtModal, setRateCourtModal] = useState(false);
  const [showRatePeopleModal, setRatePeopleModal] = useState(false);
  const [showUploadScoreModal, setUploadScoreModal] = useState(false);

  async function getBookingDetail() {
    setBookingDetailLoader(true);
    let body = {
      sub_order_id: param.id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_BOOKING_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setBookingDetailLoader);
    if (response?.isValidate) {
      setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  console.log("BookingDetail ::", BookingDetail);

  function fnCancelBookingModal() {
    setCancelBookingModalShow(true);
  }

  async function fnCancelBooking() {
    let body = {
      sub_order_id: param.id,
      refund_method: "original",
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_CANCEL,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData);
    if (response?.isValidate) {
      getBookingDetail();
      // setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnleaveInvitation(BookingDetail) {
    let body = {
      booking_player_id: BookingDetail?.players_details[0]?.booking_player_id,
      notify_id: BookingDetail?.notify_id,
    };

    // return;
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_PLAYERS_LEAVE_INVITATION,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData);
    if (response?.isValidate) {
      getBookingDetail();
      // setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnDeletePlayer(item) {
    let body = {
      booking_player_id: item.booking_player_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_PLAYER_REMOVE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getBookingDetail();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  function fnIviteModalShow(type) {
    if (BookingDetail && BookingDetail.max_no_of_players === 0) {
      setInviteModalShow(true);
      setPlayerModalType("MaxPlayer");
    } else {
      if (type === "MaxPlayer") {
        if (LoginInfo?.association_id === BookingDetail?.from_member_id) {
          setInviteModalShow(true);
          setPlayerModalType(type);
        }
      } else if (BookingDetail && BookingDetail.max_no_of_players !== 0) {
        setInviteModalShow(true);
        setPlayerModalType(type);
      }
    }
  }
  // debugger;

  function Add24Hours() {
    if (BookingDetail !== undefined) {
      let current_time = new Date();
      let timer_start_time = new Date(
        commonservices.getDayDateMonthTimeSeconds(
          BookingDetail?.club_details?.start_time
        )
      );
      let diff = (timer_start_time - current_time) / 1000;
      let order_cancellation_hours = AppSettingData?.order_cancellation_hours
        ? AppSettingData?.order_cancellation_hours
        : 86400;
      if (diff < order_cancellation_hours) {
        setStartHour(false);
      } else {
        setStartHour(true);
      }
    }
  }

  useEffect(() => {
    getBookingDetail();
    // debugger;
    if (AppSettingData) {
      const Appmilliseconds = AppSettingData?.order_cancellation_hours;
      const Apphours = Appmilliseconds / 3600;
      setPolicyHour(Apphours);
    }
  }, []);

  useEffect(() => {
    if (BookingDetail) {
      Add24Hours();
    }
  }, [BookingDetail]);

  function fnBookingRateModal(type) {
    if (type !== "") {
      setRateCourtModalShow(true);
      setRateModalType(type);
    } else {
      setUploadRateCourtModalShow(true);
    }
  }

  const handleRatePeopleModal = () => {
    setRatePeopleModal(true);
  };

  const closeRatePeopleModal = () => {
    setRatePeopleModal(false);
  };

  const handleUploadScoreModal = () => {
    setUploadScoreModal(true);
  };

  const closeUploadScoreModal = () => {
    setUploadScoreModal(false);
  };

  function fnRateModal(type) {
    setBookingPlayerModalShow(true);
    setRatePlayerType(type);
  }

  async function fnAccessforInvitation(item) {
    let body = {};
    if (item.is_allow_add) {
      body = {
        booking_player_id: item?.booking_player_id,
        is_allow_remove: 0,
        is_allow_add: 0,
      };
    } else {
      body = {
        booking_player_id: item?.booking_player_id,
        is_allow_add: 1,
        is_allow_remove: 0,
      };
    }

    console.log("body ::", body);

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_PLAYERS_ALLOW_ACCESS,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData);
    if (response?.isValidate) {
      getBookingDetail();
      // setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnMyFriendGroupsModal(type) {
    fnIviteModalShow();
  }

  console.log("BookingDetail ::", BookingDetail);

  return (
    <>
      <div className="find-clubs-main booking-detail-main">
        {BookingDetailLoader && <LoaderOverlay />}
        <div className="big-container container">
          <div className="page-top-section">
            <div className="page-top-section-left">
              <div className="show-route">
                <InPageNav pageNav={pageNav} />
              </div>
            </div>
            {BookingDetail?.order_item_status === "completed" && (
              <div className="page-top-section-right">
                <div
                  className="page-top-section-right-single-wrapper"
                  onClick={() => fnBookingRateModal("Count")}
                >
                  <img src={rateCourt} alt="rateCourt" />
                  <p>Rate Court </p>
                </div>
                {BookingDetail?.total_players_count > 0 && (
                  <div
                    className="page-top-section-right-single-wrapper"
                    onClick={() => fnRateModal("BasicPeople")}
                    // onClick={() => setBookingPlayerModalShow(true)}
                  >
                    <img src={rateCourt} alt="rateCourt" />
                    <p>Rate People</p>
                  </div>
                )}
                {BookingDetail?.dropin_players?.length > 0 && (
                  <div
                    className="page-top-section-right-single-wrapper"
                    onClick={() => fnRateModal("DropInPeople")}
                    // onClick={() => setBookingPlayerModalShow(true)}
                  >
                    <img src={rateCourt} alt="rateCourt" />
                    <p>Rate People</p>
                  </div>
                )}
                {BookingDetail?.club_details?.slot_type === "booking" ? (
                  <div
                    className="page-top-section-right-single-wrapper"
                    onClick={() => fnBookingRateModal("")}
                  >
                    <img src={uploadScore} alt="uploadScore" />
                    {BookingDetail?.score_details !== null ? (
                      <p>Update Score</p>
                    ) : (
                      <p>Upload Score</p>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="small-container container">
          <Row>
            <Col xl={6}>
              <div className="booking-detail-image-main">
                {/* <img src={detailImg} alt="detailImg" /> */}
                {BookingDetail?.club_details?.club_image !== null &&
                BookingDetail?.club_details?.club_image !== "" &&
                BookingDetail?.club_details?.club_image !== undefined ? (
                  <img
                    src={BookingDetail?.club_details?.club_image}
                    alt="club-detail-image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = imagePlaceholder1;
                    }}
                  />
                ) : (
                  <img src={imagePlaceholder1} alt="club-detail-image" />
                )}
              </div>
              <div className="booking-detail-image-content">
                <div className="courtContent">
                  <div className="court-and-court-name">
                    <p>{BookingDetail?.club_details?.court_title}</p>
                    <h5>{BookingDetail?.club_details?.club_name}</h5>
                  </div>
                  {BookingDetail?.total_players_count > 0 && (
                    <div className="booking-court-type">
                      <p>{BookingDetail?.total_players_count} Players</p>
                    </div>
                  )}
                  <div className="booking-court-type">
                    <p>{BookingDetail?.club_details?.game_type_term}</p>
                    {/* <p>Crystal | </p>
                    <p>Double</p> */}
                  </div>
                  <div className="booking-date-and-time">
                    <div className="booking-date">
                      <img src={calendarsmall} alt="calendarsmall" />
                      <p>
                        {commonservices.getDateInFormat(
                          BookingDetail?.club_details?.start_time
                        )}
                      </p>
                    </div>
                    <div className="booking-date">
                      <img src={clock} alt="clock" />
                      <p>
                        {commonservices.getTimeInFormat(
                          BookingDetail?.club_details?.start_time
                        )}{" "}
                        -{" "}
                        {commonservices.getTimeInFormat(
                          BookingDetail?.club_details?.end_time
                        )}
                      </p>
                    </div>
                    {BookingDetail?.club_details?.avg_ratings !== null && (
                      <div
                        className="booking-date"
                        onClick={() => setStarRateModalShow(true)}
                      >
                        <img src={star} alt="star" />
                        <p>
                          {BookingDetail?.club_details?.avg_ratings?.toFixed(1)}
                        </p>
                      </div>
                    )}
                  </div>
                  {BookingDetail?.order_item_status === "cancelled" ? (
                    <span className="red-badge">
                      {BookingDetail?.order_item_status}
                    </span>
                  ) : BookingDetail?.order_item_status === "booked" ? (
                    <span className="green-badge">
                      {BookingDetail?.order_item_status}
                    </span>
                  ) : BookingDetail?.order_item_status === "completed" ? (
                    <span className="grey-badge">
                      {BookingDetail?.order_item_status}
                    </span>
                  ) : BookingDetail?.order_item_status ===
                    "payment_requested" ? (
                    <span className="grey-badge">
                      {BookingDetail?.order_item_status}
                    </span>
                  ) : (
                    <span className="dark-badge">
                      {BookingDetail?.order_item_status}
                    </span>
                  )}
                </div>
                <div className="booking-detail-location-email-call">
                  {BookingDetail?.club_details?.website !== "" &&
                    BookingDetail?.club_details?.website !== undefined &&
                    BookingDetail?.club_details?.website !== null && (
                      <a
                        href={
                          "https://www." + BookingDetail?.club_details?.website
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="club-detail-map-top"
                      >
                        <div className="booking-detail-location-single">
                          <div className="booking-detail-location-email-call-bg">
                            <img src={websiteIcon} alt="websiteIcon" />
                          </div>
                          <p>Website</p>
                        </div>
                      </a>
                    )}

                  {BookingDetail?.club_details?.latitude &&
                    BookingDetail?.club_details?.longitude && (
                      <a
                        className="club-detail-map-top"
                        target="_blank"
                        href={`https://www.google.co.in/maps/dir/?saddr=&daddr=${BookingDetail?.latitude},${BookingDetail?.longitude}&directionsmode=driving`}
                      >
                        <div className="booking-detail-location-single">
                          <div className="booking-detail-location-email-call-bg">
                            <img src={location} alt="location" />
                          </div>
                          <p>Direction</p>
                        </div>
                      </a>
                    )}

                  {BookingDetail?.club_details?.club_email !== "" &&
                    BookingDetail?.club_details?.club_email !== undefined &&
                    BookingDetail?.club_details?.club_email !== null && (
                      <a
                        href={
                          "mailto:" + BookingDetail?.club_details?.club_email
                        }
                        className="club-detail-map-top"
                      >
                        <div className="booking-detail-location-single">
                          <div className="booking-detail-location-email-call-bg">
                            <img src={email} alt="email" />
                          </div>
                          <p>Email</p>
                        </div>
                      </a>
                    )}

                  {BookingDetail?.club_details?.phone_no !== "" &&
                    BookingDetail?.club_details?.phone_no !== undefined &&
                    BookingDetail?.club_details?.phone_no !== null && (
                      <a
                        href={"tel:+" + BookingDetail?.club_details?.phone_no}
                        className="club-detail-map-top"
                      >
                        <div className="booking-detail-location-single">
                          <div className="booking-detail-location-email-call-bg">
                            <img src={call} alt="call" />
                          </div>
                          <p>Call</p>
                        </div>
                      </a>
                    )}
                </div>
              </div>
              <div className="booking-detail-right-middle">
                {BookingDetail?.payment_details !== "" &&
                  BookingDetail?.payment_details !== undefined &&
                  BookingDetail?.payment_details !== null && (
                    <div className="booking-detail-right-bottom">
                      <h5 className="mb-4">Payment Details</h5>
                      <div>
                        {BookingDetail?.payment_details?.length > 0 &&
                          BookingDetail?.payment_details.map(
                            (item_b, index_b) => (
                              <>
                                <div
                                  className="booking-detail-right-bottom-single"
                                  key={index_b}
                                >
                                  <p>{item_b?.title}</p>
                                  <span>{item_b?.value}</span>
                                </div>
                              </>
                            )
                          )}
                      </div>
                      {BookingDetail?.order_item_status !== "completed" && (
                        <div className="cancellation-policy">
                          <div className="d-flex align-items-center gap-2">
                            <img src={information} alt="information" />
                            <p>Cancellation Policy</p>
                          </div>
                          <p className="red-text">Before {PolicyHour} hours</p>
                        </div>
                      )}

                      {BookingDetail?.order_item_status !== "completed" &&
                        BookingDetail?.order_item_status !== "cancelled" && (
                          <>
                            {LoginInfo?.association_id ===
                            BookingDetail?.from_member_id ? (
                              <>
                                {/* {Add24Hours()} */}
                                {StartHour && (
                                  <>
                                    <button
                                      className="red-btn red-border w-100"
                                      onClick={() => fnCancelBookingModal()}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <button
                                className="red-btn red-border w-100"
                                onClick={() => fnleaveInvitation(BookingDetail)}
                              >
                                Leave Invitation
                              </button>
                            )}
                          </>
                        )}
                    </div>
                  )}
              </div>
            </Col>
            <Col xl={6} className="booking-detail-right-main-wrapper">
              {BookingDetail?.trainer_details !== null && (
                <div
                  className="booking-detail-right-top p-0 mb-3"
                  style={{ background: "transparent", boxShadow: "none" }}
                >
                  <h6>Trainer Detail</h6>
                  <div className="booking-detail-right-top-single d-flex justify-content-between">
                    <Link
                      to={{
                        pathname:
                          "/profile-detail/" +
                          BookingDetail?.trainer_details?.trainer_id,
                      }}
                      className="d-flex align-items-center gap-2"
                    >
                      <div className="booking-detail-right-top-image">
                        {BookingDetail?.trainer_details?.trainer_image !==
                          null &&
                        BookingDetail?.trainer_details?.trainer_image !== "" &&
                        BookingDetail?.trainer_details?.trainer_image !==
                          undefined ? (
                          <img
                            src={BookingDetail?.trainer_details?.trainer_image}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                            className="completed-profile-image"
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt="club-detail-image"
                            className="completed-profile-image"
                          />
                        )}
                      </div>
                      <div>
                        <span>Trainer</span>
                        <p>{BookingDetail?.trainer_details?.trainer_name}</p>
                        <span>
                          {BookingDetail?.trainer_details
                            ?.trainer_avg_rating === 0
                            ? "0.0"
                            : BookingDetail?.trainer_details
                                ?.trainer_avg_rating}
                        </span>
                      </div>
                    </Link>
                    <a
                      href={"tel:+" + BookingDetail?.trainer_details?.phone}
                      className="booking-detail-location-email-call-bg"
                    >
                      <img src={call} alt="call" />
                    </a>
                  </div>
                </div>
              )}
              {BookingDetail !== "" &&
                BookingDetail?.club_details?.slot_type !== "drop_in" && (
                  <div className="player-list">
                    <h6>Player(s)</h6>
                    {BookingDetail?.member_access?.is_allow_add === 1 && (
                      //  {LoginInfo?.association_id ===
                      //             BookingDetail?.from_member_id && (
                      <>
                        {BookingDetail?.order_item_status === "booked" && (
                          // {BookingDetail?.order_item_status !== "cancelled" &&
                          //   BookingDetail?.order_item_status !== "completed" && (
                          <div
                            className="invite-player-main"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="d-flex align-items-center">
                              <div
                                className="invite-friends-btn gap-2"
                                // onClick={() => fnIviteModalShow()}
                              >
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <img src={addbtn} alt="add" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() =>
                                        // fnMyFriendGroupsModal('Friends')
                                        fnIviteModalShow("Friends")
                                      }
                                    >
                                      My Friends
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        // fnMyFriendGroupsModal('Groups')
                                        fnIviteModalShow("Groups")
                                      }
                                    >
                                      My Groups
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                                <p>
                                  Invite Player{" "}
                                  {BookingDetail?.players_details?.length >
                                    0 && (
                                    <span>
                                      ({BookingDetail?.accept_player_count}/
                                      {BookingDetail?.players_details.length})
                                    </span>
                                  )}
                                </p>
                              </div>
                            </div>
                            <div
                              className="d-flex align-items-center gap-1"
                              onClick={() => fnIviteModalShow("MaxPlayer")}
                            >
                              <p>
                                Max player{" "}
                                {BookingDetail?.max_no_of_players
                                  ? BookingDetail?.max_no_of_players
                                  : 0}
                              </p>
                              {LoginInfo?.association_id ===
                                BookingDetail?.from_member_id && (
                                <img src={pencil} alt="pencil" />
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}

                    {BookingDetail?.players_details !== null &&
                      BookingDetail?.players_details?.length > 0 &&
                      BookingDetail?.players_details?.map(
                        (item_pd, index_pd) => (
                          <div
                            // onClick={() => {
                            //   navigate("/profile-detail/" + item_pd?.member_id);
                            // }}
                            className="invited-player-single"
                          >
                            <div
                              onClick={() => {
                                navigate(
                                  "/profile-detail/" + item_pd?.member_id
                                );
                              }}
                              className="d-flex align-items-center gap-2"
                            >
                              {item_pd?.profile_pic !== null &&
                              item_pd?.profile_pic !== "" &&
                              item_pd?.profile_pic !== undefined ? (
                                <img
                                  className="write-something-profile"
                                  src={item_pd?.profile_pic}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                  className="write-something-profile"
                                />
                              )}
                              <div>
                                <h4
                                  className={
                                    item_pd?.member_type === "Standard"
                                      ? "profile-standard-after-initials-title border-0 m-0 p-0"
                                      : item_pd?.member_type === "Premium"
                                      ? "profile-premium-after-initials-title border-0 m-0 p-0"
                                      : "border-0 m-0 p-0"
                                  }
                                >
                                  {item_pd?.member_name}
                                </h4>
                                {BookingDetail?.order_item_status !==
                                  "completed" &&
                                BookingDetail?.order_item_status !==
                                  "cancelled" ? (
                                  <>
                                    {item_pd?.order_member_type === "owner" ? (
                                      <p className="font-green text-capitalize">
                                        owner
                                      </p>
                                    ) : (
                                      <>
                                        <p
                                          className="text-capitalize"
                                          // className={
                                          //   item_pd?.action_status === "pending"
                                          //     ? "font-yellow"
                                          //     : item_pd?.action_status ===
                                          //       "waiting"
                                          //     ? "font-yellow"
                                          //     : "font-green"
                                          // }
                                        >
                                          <span
                                            className={
                                              item_pd?.action_status ===
                                              "pending"
                                                ? "font-yellow"
                                                : item_pd?.action_status ===
                                                  "waiting"
                                                ? "font-yellow"
                                                : "font-green"
                                            }
                                          >
                                            {item_pd?.action_status}
                                          </span>{" "}
                                          | {item_pd?.invite_type}
                                        </p>
                                        {item_pd?.pay_amount !== null && (
                                          <p>{item_pd?.payment_status}</p>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <p>{item_pd?.avg_rating?.toFixed(1)}</p>
                                )}
                              </div>
                            </div>
                            {BookingDetail?.order_item_status !== "completed" &&
                              BookingDetail?.order_item_status !==
                                "cancelled" &&
                              // LoginInfo?.association_id ===
                              //   BookingDetail?.from_member_id &&
                              item_pd?.order_member_type !== "owner" && (
                                <Dropdown className="my-differenr-dropdown">
                                  <Dropdown.Toggle id="dropdown-basic2">
                                    <img src={threedots} alt="threedots" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {item_pd?.invite_type === "player" && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          fnAccessforInvitation(item_pd)
                                        }
                                      >
                                        {item_pd.is_allow_add
                                          ? "Remove"
                                          : "Give"}{" "}
                                        Access For Invitation
                                      </Dropdown.Item>
                                    )}
                                    <Dropdown.Item
                                      onClick={() => fnDeletePlayer(item_pd)}
                                    >
                                      Remove Player
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              )}
                          </div>
                        )
                      )}
                  </div>
                )}
              <div className="booking-detail-right-middle">
                {BookingDetail !== "" &&
                  BookingDetail?.score_details !== null &&
                  BookingDetail?.score_details !== null &&
                  BookingDetail?.score_details !== "" && (
                    <>
                      <div className="booking-detail-right-middle-top">
                        <h5>Score</h5>
                        {/* <img src={share} alt="share" /> */}
                      </div>
                      <div className="club-detail-recent-result-main">
                        {/* <div className="d-flex gap-2 all-results-main"> */}
                        <div className="recent-result-wrapper-main">
                          <div className="recent-result-wrapper">
                            {BookingDetail?.score_details?.score.length > 0 &&
                              BookingDetail?.score_details.score.map(
                                (s_item, s_index) => (
                                  <div className="recent-result-single-team">
                                    <p className="team-name">
                                      {s_item.team_name}
                                    </p>
                                    <div className="two-players-section">
                                      {s_item?.players.length > 0 &&
                                        s_item.players.map(
                                          (p_item, p_index) => (
                                            <div className="single-players-section">
                                              {/* <img
                                                  src={p_item.player_profile}
                                                  alt="profile"
                                                /> */}
                                              {p_item.player_profile !== null &&
                                              p_item.player_profile !== "" &&
                                              p_item.player_profile !==
                                                undefined ? (
                                                <img
                                                  src={p_item.player_profile}
                                                  alt="club-detail-image"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null; // prevents looping
                                                    currentTarget.src =
                                                      imagePlaceholder;
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={imagePlaceholder}
                                                  alt="club-detail-image"
                                                />
                                              )}
                                              <h6>{p_item.player_name}</h6>
                                              <p>
                                                {" "}
                                                {p_item.avg_rating === 0
                                                  ? "0.0"
                                                  : p_item.avg_rating?.toFixed(
                                                      1
                                                    )}
                                              </p>
                                            </div>
                                          )
                                        )}
                                    </div>
                                    <div className="recent-result-scores-wrapper">
                                      {s_item?.score?.map(
                                        (ss_item, ss_index) => (
                                          <h6
                                            className="recent-result-scores"
                                            key={ss_index}
                                          >
                                            {ss_item}
                                          </h6>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </>
                  )}
              </div>
              <div>
                {BookingDetail !== "" &&
                  BookingDetail?.dropin_players !== null &&
                  BookingDetail?.dropin_players !== undefined &&
                  BookingDetail?.dropin_players !== "" &&
                  BookingDetail?.dropin_players?.length > 0 && (
                    <>
                      <div className="booking-detail-right-middle-top">
                        <h5>Participant(s)</h5>
                      </div>
                      <div className="club-detail-recent-result-main">
                        <div className="d-flex gap-2 all-results-main">
                          <div className="booking-detail-participants-wrapper">
                            {BookingDetail?.dropin_players.length > 0 &&
                              BookingDetail?.dropin_players.map(
                                (s_item, s_index) => (
                                  <div className="booking-detail-participants-single">
                                    {s_item?.profile_pic !== null &&
                                    s_item?.profile_pic !== "" &&
                                    s_item?.profile_pic !== undefined ? (
                                      <div className="">
                                        <img
                                          src={s_item?.profile_pic}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <div className="">
                                        <img
                                          src={imagePlaceholder}
                                          alt="club-detail-image"
                                        />
                                      </div>
                                    )}
                                    <div className="">
                                      <h5 className="">{s_item.member_name}</h5>
                                      <p className="">
                                        {s_item.avg_rating === 0
                                          ? "0.0"
                                          : s_item.avg_rating}
                                      </p>
                                    </div>
                                  </div>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* <RateCourtModal
        showRateCourtModal={showRateCourtModal}
        closeRateCourtModal={closeRateCourtModal}
      /> */}
      {UploadRateCourtModalShow && (
        <UploadScoreModalMain
          show={UploadRateCourtModalShow}
          param={param?.id}
          bindList={getBookingDetail}
          Players={BookingDetail !== "" && BookingDetail?.players_details}
          ScoreDetail={BookingDetail !== "" && BookingDetail?.score_details}
          BookingDetail={BookingDetail}
          onHide={() => setUploadRateCourtModalShow(false)}
        />
      )}
      {RateCourtModalShow && (
        <RateCourtModal
          show={RateCourtModalShow}
          RateModalType={RateModalType}
          param={param?.id}
          bindList={getBookingDetail}
          Players={BookingDetail !== "" && BookingDetail?.players_details}
          ScoreDetail={BookingDetail !== "" && BookingDetail?.score_details}
          BookingDetail={BookingDetail}
          onHide={() => setRateCourtModalShow(false)}
        />
      )}
      {InviteModalShow && (
        <InvitePlayerModal
          show={InviteModalShow}
          param={param?.id}
          BookingDetail={BookingDetail}
          CloseModal={() => setInviteModalShow(false)}
          PlayerModalType={PlayerModalType}
          bindList={getBookingDetail}
          onHide={() => setInviteModalShow(false)}
        />
      )}
      {BookingPlayerModalShow && (
        <PlayerBookingModal
          show={BookingPlayerModalShow}
          param={param?.id}
          BookingDetail={BookingDetail}
          RatePlayerType={RatePlayerType}
          CloseModal={() => setBookingPlayerModalShow(false)}
          //  PlayerModalType={PlayerModalType}
          bindList={getBookingDetail}
          onHide={() => setBookingPlayerModalShow(false)}
        />
      )}
      {CancelBookingModalShow && (
        <CalncelBokingDetailModal
          show={CancelBookingModalShow}
          param={param?.id}
          BookingDetail={BookingDetail}
          bindList={getBookingDetail}
          onHide={() => setCancelBookingModalShow(false)}
        />
      )}
      {StarRateModalShow && (
        <StarRateModal
          show={StarRateModalShow}
          BookingDetail={BookingDetail}
          Type="BookingRate"
          onHide={() => setStarRateModalShow(false)}
        />
      )}
      <RatePeopleModal
        showRatePeopleModal={showRatePeopleModal}
        closeRatePeopleModal={closeRatePeopleModal}
      />
      <UploadScoreModal
        showUploadScoreModal={showUploadScoreModal}
        closeUploadScoreModal={closeUploadScoreModal}
      />
    </>
  );
}

export default BookingDeatil;
