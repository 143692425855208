import { ENUMS } from "./Constant/Enums";
export const UserInfo = (data) => ({
  type: ENUMS.actions.USER_INFO,
  payload: data,
});
export const EditUserInfo = (data) => ({
  type: ENUMS.actions.EDIT_USER_INFO,
  payload: data,
});
export const ClubFilter = (data) => ({
  type: ENUMS.actions.CLUB_FILTER,
  payload: data,
});
export const ClubFilterDate = (data) => ({
  type: ENUMS.actions.CLUB_FILTER_DATE,
  payload: data,
});
export const CartData = (data) => ({
  type: ENUMS.actions.CART_DATA,
  payload: data,
});
export const AppSetting = (data) => ({
  type: ENUMS.actions.APP_SETTING,
  payload: data,
});
export const TrainerFilter = (data) => ({
  type: ENUMS.actions.TRAINER_FILTER,
  payload: data,
});
export const FinalSlotsAction = (data) => ({
  type: ENUMS.actions.FINAL_SLOTS,
  payload: data,
});
