import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import Slider from "react-slick";
import like from "../images/like.svg";
import liked from "../images/liked.png";
import imagePlaceholder from "../images/placeholder.png";
import redAdd from "../images/redAdd.png";
import searchIcon from "../images/searchIcon.svg";

import moment from "moment";
import { useSelector } from "react-redux";
import Loader, { LoaderType } from "../components/Loader";
import add from "../images/circularAdd.jpg";
import comment from "../images/comment.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { Link } from "react-router-dom";

function CommentsModal(props) {
  // debugger;
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [Loading, setLoading] = useState(false);
  const [AddCommentLoader, setAddCommentLoader] = useState(false);
  const [SearchLikesUser, setSearchLikesUser] = useState("");
  const [CommnentData1, setCommnentData1] = useState();
  const [SearchInterestedUser, setSearchInterestedUser] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [InterestedpageNumber, setInterestedPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingInterest, setIsLoadingInterest] = useState(false);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [RemoveLikeMoreButtion, setRemoveLikeMoreButtion] = useState(false);
  const [RemoveInterestMoreButtion, setRemoveInterestMoreButtion] =
    useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [error, setError] = useState(null);

  const [CommentList, setCommentList] = useState([]);
  const [LikeUserList, setLikeUserList] = useState([]);
  const [IterestUserList, setIterestUserList] = useState([]);
  // const [Comment, setComment] = useState('');
  const [input, setInput] = useState({
    comment: "",
  });

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  async function fnLikeDeslikes(item, value) {
    try {
      let body = {
        post_id: item.post_id,
        is_like: value,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_LIKE_DISLIKE_POST,
          body: body,
        },
        false
      );

      let response = apiResponse(false, resData);

      if (response?.isValidate) {
        props.bindList(1);

        setCommnentData1((prevData) => {
          return {
            ...prevData,
            total_likes:
              value === 1 ? prevData.total_likes + 1 : prevData.total_likes - 1,
            is_like: value === 1 ? 1 : 0,
          };
        });
      } else {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error in fnLikeDeslikes:", error);
    }
  }

  async function fnAddRemoveFollowing(item, value) {
    try {
      let body = {
        receiver_member_id: item?.member_id,
        follow: value,
      };
      // debugger;
      // return;
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        // getFollowerMembers();
        // getFollowingMembers();
        fnInterestList(1);
        fnLikeList(1);

        // setFollowingMembersList(response?.data?.data?.list);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
      setError(error);
    } finally {
      // setIsLoading(false);
    }
  }

  async function fnCommentList(pageno) {
    setLoading(true);
    // setIsLoading(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
        post_id: props?.CommentPost?.post_id,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_GET_COMMENTS,
          body: body,
        },
        false
      );

      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        if (pageno === 1 || pageno === undefined) {
          setCommentList(response?.data?.data?.list);
        } else {
          setCommentList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
        setInput({
          ...input,
          ["comment"]: "",
        });
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }
  async function fnInterestList(pageno) {
    setIsLoadingInterest(true);
    setError(null);
    let body = {
      page: pageno,
      limit: 10,
      post_id: props?.CommentPost?.post_id,
      search: SearchInterestedUser,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_ITERESTED_USERS,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData, setIsLoadingInterest);
    if (response?.isValidate) {
      if (pageno === 1) {
        setIterestUserList(response?.data?.data?.list);
      } else {
        setIterestUserList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveInterestMoreButtion(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setInterestedPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnLikeList(pageno) {
    setIsLoading(true);
    let body = {
      page: pageno,
      limit: 10,
      post_id: props?.CommentPost?.post_id,
      search: SearchLikesUser,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_GET_LIKED_USERS,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData, setIsLoading);
    if (response?.isValidate) {
      // setLikeUserList(response?.data?.data?.list);
      if (pageno === 1) {
        setLikeUserList(response?.data?.data?.list);
      } else {
        setLikeUserList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveLikeMoreButtion(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnAddComment() {
    if (input?.comment === "") {
      return;
    }
    setAddCommentLoader(true);

    let body = {
      post_id: CommnentData1?.post_id,
      comment: input?.comment,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_POST_COMMENTS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setAddCommentLoader);
    if (response?.isValidate) {
      props.bindList();

      fnCommentList(1);
      setInput({
        ...input,
        ["comment"]: "",
      });

      // setCommentList(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnDeleteComment(item) {
    let body = {
      comment_id: item.comment_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_DELETE_COMMENT,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      props.bindList();
      fnCommentList(pageNumber);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  useEffect(() => {
    setCommnentData1(props.CommentPost);
  }, [props.CommentPost]);

  useEffect(() => {
    fnLikeList(1);
  }, [SearchLikesUser]);

  useEffect(() => {
    fnCommentList(1);
  }, [CommnentData1]);

  const handleLoadMore = () => {
    fnCommentList(pageNumber);
  };
  const handleLoadLike = () => {
    fnLikeList(pageNumber);
  };
  const handleLoadInterest = () => {
    fnInterestList(InterestedpageNumber);
  };

  useEffect(() => {
    fnInterestList(InterestedpageNumber);
  }, [SearchInterestedUser]);

  return (
    <div className="likes-modal-wrapper">
      <Modal
        className="likes-modal-main"
        size="xl"
        centered
        style={{ overflow: "hidden" }}
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            {" "}
            {props?.ModalType === "comment"
              ? "Comments"
              : props?.ModalType === "like"
              ? "Likes"
              : "Interested"}
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body>
          <div className="like-modal-inner-wrapper">
            <Row>
              {props?.ModalType === "comment" && (
                <>
                  <Col xl={7} md={6} className="like-modal-inner-left">
                    {CommnentData1?.type_of_post === "upload_score" ? (
                      <div className="single-post-wrapper FindClubCard-main">
                        <div className="single-post-top">
                          <div className="d-flex align-items-center profile-and-time">
                            <div className="write-something-profile">
                              {CommnentData1?.profile_pic !== null &&
                              CommnentData1?.profile_pic !== "" &&
                              CommnentData1?.profile_pic !== undefined ? (
                                <img
                                  src={CommnentData1?.profile_pic}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                />
                              )}
                            </div>
                            <div className="username-with-time">
                              <h5>
                                {CommnentData1?.member_name}{" "}
                                <span>shared a score</span>
                              </h5>
                              <p>
                                {moment(CommnentData1?.created_at).fromNow()}{" "}
                              </p>
                            </div>
                          </div>
                          {/* <div>
                          <img src={threedots} alt="threedots" />
                        </div> */}
                        </div>
                        <div className="all-post-section-main">
                          <div className="post-section">
                            <div className="recent-result-wrapper-main">
                              <div className="recent-result-wrapper">
                                {CommnentData1?.score_details.score.length >
                                  0 &&
                                  CommnentData1?.score_details.score.map(
                                    (s_item, s_index) => (
                                      <div className="recent-result-single-team">
                                        <p className="team-name">
                                          {s_item.team_name}
                                        </p>
                                        <div className="two-players-section">
                                          {s_item?.players.length > 0 &&
                                            s_item.players.map(
                                              (p_item, p_index) => (
                                                <div className="single-players-section">
                                                  {/* <img
                                                    src={p_item.player_profile}
                                                    alt="profile"
                                                  /> */}
                                                  {p_item.player_profile !==
                                                    null &&
                                                  p_item.player_profile !==
                                                    "" &&
                                                  p_item.player_profile !==
                                                    undefined ? (
                                                    <img
                                                      src={
                                                        p_item.player_profile
                                                      }
                                                      alt="club-detail-image"
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null; // prevents looping
                                                        currentTarget.src =
                                                          imagePlaceholder;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={imagePlaceholder}
                                                      alt="club-detail-image"
                                                    />
                                                  )}
                                                  <h6>{p_item.player_name}</h6>
                                                  <p>{p_item.avg_rating}</p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div className="recent-result-scores-wrapper">
                                          {s_item?.score?.map(
                                            (ss_item, ss_index) => (
                                              <h6
                                                className="recent-result-scores"
                                                key={ss_index}
                                              >
                                                {ss_item}
                                              </h6>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-post-bottom">
                          <div className="like-and-comment">
                            <div
                              className="like-with-like-number"
                              // onClick={handleLikesModal}
                              onClick={() =>
                                fnLikeDeslikes(
                                  CommnentData1,
                                  CommnentData1?.is_like === 1 ? 0 : 1
                                )
                              }
                            >
                              <img
                                src={
                                  CommnentData1?.total_likes > 0 ? liked : like
                                }
                                alt="like"
                              />
                              {/* <img src={liked} alt="like" /> */}
                              <p>{CommnentData1?.total_likes} likes</p>
                            </div>

                            <div
                              className="like-with-like-number"
                              // onClick={handleCommentsModal}
                              // onClick={() => fnCommentModal(CommnentData1)}
                            >
                              <img src={comment} alt="like" />
                              <p>{CommnentData1?.total_comment} comments</p>
                            </div>
                            {CommnentData1?.interest_id !== null && (
                              <div
                                className="like-with-like-number"
                                // onClick={handleInterestModal}
                              >
                                <img src={add} alt="add" />
                                <p>
                                  {CommnentData1?.total_interested_user}{" "}
                                  Interested
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : CommnentData1?.type_of_post === "normal" ? (
                      <>
                        <div className="single-post-wrapper FindClubCard-main">
                          <div className="single-post-top">
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {CommnentData1?.profile_pic !== null &&
                                CommnentData1?.profile_pic !== "" &&
                                CommnentData1?.profile_pic !== undefined ? (
                                  <img
                                    src={CommnentData1?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                <h5>
                                  {CommnentData1?.member_name}{" "}
                                  <span>shared a score</span>
                                </h5>
                                <p>
                                  {moment(CommnentData1?.created_at).fromNow()}
                                </p>
                              </div>
                            </div>
                            {/* <div>
                            <img src={threedots} alt="threedots" />
                          </div> */}
                          </div>
                          <div className="single-post-middle">
                            <div className="post-section">
                              <Slider {...settings}>
                                {CommnentData1?.media_files?.length > 0 &&
                                  CommnentData1?.media_files.map(
                                    (m_item, m_index) => (
                                      <div className="slide-out-of-total ">
                                        <p className="slide-out-of-total-para">
                                          {m_index + 1}/{" "}
                                          {CommnentData1?.media_files?.length}
                                        </p>
                                        <div className="slide-out-of-total11">
                                          {m_item?.image_path !== null &&
                                          m_item?.image_path !== "" &&
                                          m_item?.image_path !== undefined ? (
                                            <img
                                              src={m_item?.image_path}
                                              alt="club-detail-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  imagePlaceholder;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={imagePlaceholder}
                                              alt="club-detail-image"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </Slider>
                            </div>
                          </div>
                          <div className="single-post-bottom">
                            <div className="like-and-comment">
                              <div
                                className="like-with-like-number"
                                onClick={() =>
                                  fnLikeDeslikes(
                                    CommnentData1,
                                    CommnentData1?.is_like === 1 ? 0 : 1
                                  )
                                }
                              >
                                {/* <img src={liked} alt="like" /> */}
                                <img
                                  src={
                                    CommnentData1?.total_likes > 0
                                      ? liked
                                      : like
                                  }
                                  alt="like"
                                />
                                <p>{CommnentData1?.total_likes} likes</p>
                              </div>

                              <div
                                className="like-with-like-number"
                                // onClick={() => fnCommentModal(CommnentData1)}
                              >
                                <img src={comment} alt="like" />
                                {/* <p>{item?.total_likes} comments</p> */}
                                <p>{CommnentData1?.total_comment} comments</p>
                              </div>
                              {CommnentData1?.is_interested !== 0 && (
                                <div className="like-with-like-number">
                                  {CommnentData1?.total_interested_user > 0 ? (
                                    <p>"red icon"</p>
                                  ) : (
                                    <img src={add} alt="like" />
                                  )}
                                  <p>
                                    {CommnentData1?.total_interested_user}{" "}
                                    Interested
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="post-detail">
                              <p>
                                {CommnentData1?.comment !== null ? (
                                  CommnentData1?.comment
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      CommnentData1?.type_of_post === "interest_activity" && (
                        <>
                          <div className="single-post-wrapper FindClubCard-main">
                            <div className="single-post-top">
                              <div className="d-flex align-items-center profile-and-time">
                                <div className="write-something-profile">
                                  {CommnentData1?.profile_pic !== null &&
                                  CommnentData1?.profile_pic !== "" &&
                                  CommnentData1?.profile_pic !== undefined ? (
                                    <img
                                      src={CommnentData1?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                                <div className="username-with-time">
                                  <h5>
                                    {CommnentData1?.member_name}{" "}
                                    <span>shared a score</span>
                                  </h5>
                                  <p>
                                    {moment(
                                      CommnentData1?.created_at
                                    ).fromNow()}
                                  </p>
                                </div>
                              </div>
                              {/* <div>
                              <img src={threedots} alt="threedots" />
                            </div> */}
                            </div>
                            <div className="single-post-middle">
                              <div className="post-section">
                                <div className="slide-out-of-total slide-out-of-total11">
                                  {CommnentData1?.club_details?.profile_pic !==
                                    null &&
                                  CommnentData1?.club_details?.profile_pic !==
                                    "" &&
                                  CommnentData1?.club_details?.profile_pic !==
                                    undefined ? (
                                    <img
                                      src={
                                        CommnentData1?.club_details?.profile_pic
                                      }
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="single-post-bottom">
                              <div className="like-and-comment">
                                <div
                                  className="like-with-like-number"
                                  onClick={() =>
                                    fnLikeDeslikes(
                                      CommnentData1,
                                      CommnentData1?.is_like === 1 ? 0 : 1
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      CommnentData1?.total_likes > 0
                                        ? liked
                                        : like
                                    }
                                    alt="like"
                                  />
                                  <p>{CommnentData1?.total_likes} likes</p>
                                </div>

                                <div
                                  className="like-with-like-number"
                                  // onClick={() => fnCommentModal(CommnentData1)}
                                >
                                  <img src={comment} alt="like" />
                                  <p>{CommnentData1?.total_comment} comments</p>
                                </div>
                                {/* {item?.interest_id !== null && (
                                  <div className="like-with-like-number">
                                    <img src={add} alt="like" />
                                    <p>
                                      {item?.total_interested_user} Interested
                                    </p>
                                  </div>
                                )} */}
                                {CommnentData1?.is_interested !== 0 && (
                                  <div className="like-with-like-number">
                                    {CommnentData1?.total_interested_user >
                                    0 ? (
                                      <img src={redAdd} alt="like" />
                                    ) : (
                                      <img src={add} alt="like" />
                                    )}
                                    <p>
                                      {CommnentData1?.total_interested_user}{" "}
                                      Interested
                                    </p>
                                  </div>
                                )}
                              </div>
                              <div className="post-detail">
                                <p>
                                  {CommnentData1?.comment !== null ? (
                                    CommnentData1?.comment
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}
                  </Col>
                </>
              )}
              <Col
                xl={props?.ModalType === "comment" && 5}
                md={props?.ModalType === "comment" && 6}
              >
                {props?.ModalType === "comment" ? (
                  <div className="all-likes-wrapper">
                    <h5 className="likes-title">
                      {props?.ModalType === "comment"
                        ? "Comments"
                        : props?.ModalType === "like"
                        ? "Likes"
                        : "Interest"}
                    </h5>
                    {Loading && !CommentList?.length > 0 ? (
                      <Loader type={LoaderType.InviteFriend} count={5} />
                    ) : (
                      <>
                        <div className="all-comments-main">
                          {CommentList?.length > 0 ? (
                            CommentList?.map((item_c, index_c) => (
                              <div
                                // to={{
                                //   pathname:
                                //     "/profile-detail/" + item_c?.member_id,
                                // }}
                                className="single-notification-single-wrapper"
                              >
                                <div className="single-notification-single mb-2">
                                  <Link
                                    to={{
                                      pathname:
                                        "/profile-detail/" + item_c?.member_id,
                                    }}
                                    className="single-notification-single-image"
                                  >
                                    {item_c?.profile_pic !== null &&
                                    item_c?.profile_pic !== "" &&
                                    item_c?.profile_pic !== undefined ? (
                                      <img
                                        src={item_c?.profile_pic}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </Link>
                                  <div className="single-notification-single-content w-100">
                                    <div className="single-notification-single-name-hrs">
                                      <Link
                                        to={{
                                          pathname:
                                            "/profile-detail/" +
                                            item_c?.member_id,
                                        }}
                                      >
                                        <h4>{item_c?.member_name}</h4>
                                      </Link>
                                      <div className="d-flex gap-2 align-items-center">
                                        <span>
                                          {commonservices.getAgo(
                                            item_c?.comment_on
                                          )}
                                        </span>
                                        {(LoginInfo?.association_id ===
                                          item_c?.member_id ||
                                          LoginInfo?.member_id ===
                                            item_c?.member_id) && (
                                          <div className="homeDashboardMain">
                                            <Dropdown className="header-dropdown">
                                              <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    confirmAlert({
                                                      title:
                                                        "Are you sure you want to delete this?",
                                                      message:
                                                        "This action cannot be undone",
                                                      buttons: [
                                                        { label: "No" },
                                                        {
                                                          label: "Yes",
                                                          onClick: () =>
                                                            fnDeleteComment(
                                                              item_c
                                                            ),
                                                        },
                                                      ],
                                                    })
                                                  }
                                                >
                                                  Delete
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    <span>{item_c?.comment}</span>
                                  </div>
                                </div>
                                <div className="accept-reject-btns">
                                  <button
                                    className="red-btn red-border w-100"
                                    style={{
                                      height: "30px",
                                      maxWidth: "100px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Accept
                                  </button>
                                  <button
                                    className="white-btn dark-color w-100"
                                    style={{
                                      height: "30px",
                                      maxWidth: "100px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    Reject{" "}
                                  </button>
                                </div>
                              </div>
                            ))
                          ) : (
                            <Loader type={LoaderType.NODATAFOUND} />
                          )}
                          {!RemoveMoreButtion && (
                            <div className="text-center w-100 view-more-btn">
                              <button
                                className="red-btn red-border width150"
                                onClick={handleLoadMore}
                                disabled={Loading}
                              >
                                {Loading ? "Loading..." : "View More"}
                              </button>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                    {Loading && CommentList?.length > 0 && (
                      <Loader type={LoaderType.InviteFriend} count={5} />
                    )}

                    <div className="write-comment-here">
                      <InputGroup
                        className="search-input-group write-comment-here-input-group"
                        style={{ height: "70px" }}
                      >
                        <InputGroup.Text id="basic-addon1">
                          {/* <img
                            src={profile2}
                            alt="profile2"
                            className="comment-here-profile-image"
                          /> */}
                          {LoginInfo?.profile_pic !== null &&
                          LoginInfo?.profile_pic !== "" &&
                          LoginInfo?.profile_pic !== undefined ? (
                            <img
                              src={LoginInfo?.profile_pic}
                              alt="profile"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder;
                              }}
                              className="comment-here-profile-image"
                            />
                          ) : (
                            <img
                              className="comment-here-profile-image"
                              src={imagePlaceholder}
                              alt="profile"
                            />
                          )}
                        </InputGroup.Text>
                        <Form.Control
                          type="text"
                          placeholder="Write a comment..."
                          value={input.comment}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              ["comment"]: e.target.value,
                            });
                          }}
                        />

                        <InputGroup.Text
                          id="basic-addon2"
                          className="comment-here-post-text"
                          onClick={() => fnAddComment()}
                        >
                          {AddCommentLoader ? (
                            <Spinner
                              animation="grow"
                              className="custom-loader"
                            />
                          ) : (
                            "Post"
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                  </div>
                ) : props?.ModalType === "like" ? (
                  <div className="all-likes-wrapper border-0 p-0">
                    {/* <h5 className="likes-title">Likes</h5> */}
                    <div className="modal-header-search-group w-100">
                      <InputGroup className="search-input-group">
                        <Form.Control
                          // placeholder="Search"
                          // aria-label="Search"
                          // aria-describedby="basic-addon1"
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-addon1"
                          value={SearchLikesUser}
                          // onChange={(e) => setSearchInput(e.target.value)}
                          onChange={(e) => setSearchLikesUser(e.target.value)}
                        />
                        <InputGroup.Text id="basic-addon1">
                          <img
                            src={searchIcon}
                            alt="search"
                            className="black-search-icon"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="all-sungle-likes-wrapper all-comments-main">
                      {isLoading && !LikeUserList?.length > 0 ? (
                        <Loader type={LoaderType.InviteFriend} count={5} />
                      ) : (
                        <>
                          {LikeUserList?.length > 0 &&
                            LikeUserList.map((item_l, index_l) => (
                              <Link
                                // to={{
                                //   pathname:
                                //     "/profile-detail/" + item_l?.member_id,
                                // }}
                                className="single-notification-single-wrapper123 likes-signle d-flex align-items-center justify-content-between"
                              >
                                <div className="single-post-top">
                                  <div className="d-flex align-items-center profile-and-time">
                                    <div className="write-something-profile">
                                      {item_l?.profile_pic !== null &&
                                      item_l?.profile_pic !== "" &&
                                      item_l?.profile_pic !== undefined ? (
                                        <img
                                          src={item_l?.profile_pic}
                                          alt="profile"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="profile"
                                        />
                                      )}
                                    </div>
                                    <div className="username-with-time">
                                      <h5
                                        // className={
                                        //   item_l?.member_type === "Standard"
                                        //     ? "profile-standard-after-initials-title"
                                        //     : ""
                                        // }
                                        className={
                                          item_l?.member_type === "Standard"
                                            ? "profile-standard-after-initials-title "
                                            : item_l?.member_type === "Premium"
                                            ? "profile-premium-after-initials-title "
                                            : ""
                                        }
                                      >
                                        {item_l?.member_name}{" "}
                                        {/* {item_l?.member_type === "Standard" && (
                                      <span className="profile-standard-after-initials-title"></span>
                                    )} */}
                                      </h5>
                                      <p>{item_l?.nickname}</p>
                                    </div>
                                  </div>
                                </div>
                                {LoginInfo?.association_id !==
                                  item_l?.member_id && (
                                  <button
                                    // onClick={() => fnRemoveFollowing(item_fw)}
                                    onClick={() =>
                                      fnAddRemoveFollowing(
                                        item_l,
                                        item_l?.is_following === 1 ? 0 : 1
                                      )
                                    }
                                    className="gray-btn"
                                    style={{
                                      height: "34px",
                                      fontSize: "14px",
                                      width: "100px",
                                    }}
                                  >
                                    {item_l?.is_following === 1
                                      ? "Following"
                                      : "Follow"}
                                  </button>
                                )}
                              </Link>
                            ))}
                        </>
                      )}
                      {isLoading && LikeUserList?.length > 0 && (
                        <Loader type={LoaderType.InviteFriend} count={5} />
                      )}
                      {!RemoveLikeMoreButtion && (
                        <div className="text-center w-100 view-more-btn">
                          <button
                            className="red-btn red-border width150"
                            onClick={handleLoadLike}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "View More"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="all-likes-wrapper  border-0 p-0">
                    {/* <h5 className="likes-title">Interested</h5> */}
                    <div className="modal-header-search-group w-100">
                      <InputGroup className="search-input-group">
                        <Form.Control
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="basic-addon1"
                          value={SearchInterestedUser}
                          onChange={(e) =>
                            setSearchInterestedUser(e.target.value)
                          }
                        />
                        <InputGroup.Text id="basic-addon1">
                          <img
                            src={searchIcon}
                            alt="search"
                            className="black-search-icon"
                          />
                        </InputGroup.Text>
                      </InputGroup>
                    </div>
                    <div className="all-sungle-likes-wrapper all-comments-main">
                      {isLoadingInterest ? (
                        <Loader type={LoaderType.InviteFriend} count={5} />
                      ) : (
                        <>
                          {IterestUserList?.length > 0 &&
                            IterestUserList.map((item_l, index_l) => (
                              <div
                                // to={{
                                //   pathname:
                                //     "/profile-detail/" + item_l?.member_id,
                                // }}
                                className="likes-signle d-flex align-items-center justify-content-between single-notification-single-wrapper12"
                              >
                                <div className="single-post-top">
                                  <Link
                                    to={{
                                      pathname:
                                        "/profile-detail/" + item_l?.member_id,
                                    }}
                                    className="d-flex align-items-center profile-and-time"
                                  >
                                    <div className="write-something-profile">
                                      {item_l?.profile_pic !== null &&
                                      item_l?.profile_pic !== "" &&
                                      item_l?.profile_pic !== undefined ? (
                                        <img
                                          src={item_l?.profile_pic}
                                          alt="profile"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="profile"
                                        />
                                      )}
                                    </div>
                                    <div className="username-with-time">
                                      <h5
                                        // className={
                                        //   item_l?.member_type === "Standard"
                                        //     ? "profile-standard-after-initials-title"
                                        //     : ""
                                        // }
                                        className={
                                          item_l?.member_type === "Standard"
                                            ? "profile-standard-after-initials-title "
                                            : item_l?.member_type === "Premium"
                                            ? "profile-premium-after-initials-title "
                                            : ""
                                        }
                                      >
                                        {item_l?.member_name}{" "}
                                      </h5>
                                      <p>{item_l?.nickname}</p>
                                    </div>
                                  </Link>
                                </div>
                                {LoginInfo?.association_id !==
                                  item_l?.member_id && (
                                  <button
                                    // className="red-btn red-border"
                                    className={
                                      item_l?.is_following === 1
                                        ? "white-btn dark-color"
                                        : " red-btn red-border"
                                    }
                                    style={{
                                      height: "34px",
                                      fontSize: "14px",
                                      width: "100px",
                                    }}
                                    onClick={() =>
                                      fnAddRemoveFollowing(
                                        item_l,
                                        item_l?.is_following === 1 ? 0 : 1
                                      )
                                    }
                                  >
                                    {item_l?.is_following === 1
                                      ? "Unfollow"
                                      : "Follow"}
                                  </button>
                                )}
                              </div>
                            ))}
                        </>
                      )}
                      {!RemoveInterestMoreButtion && (
                        <div className="text-center w-100 view-more-btn">
                          <button
                            className="red-btn red-border width150"
                            onClick={handleLoadInterest}
                            disabled={isLoadingInterest}
                          >
                            {isLoadingInterest ? "Loading..." : "View More"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CommentsModal;
