import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import imagePlaceholder from "../images/placeholder.png";
import { Range, getTrackBackground } from "react-range";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import AddPlayerModal from "./AddPlayerModal";
import RangeRatting from "../components/CommonComponents/RangeRatting";

function RateTrainerModal(props) {
  // debugger;
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  // console.log("LoginInfo", LoginInfo);

  const [onFinalChangeData, setonFinalChangeData] = useState();
  let dispatch = useDispatch();
  const [values, setValues] = useState([100]);
  // const [MIN1, setMIN1] = useState();
  const STEP1 = 1.1;
  const MIN1 = 100;
  const MAX1 = 500;
  const RangeNumber1 = [];
  for (let i = MIN1; i <= MAX1; i += MIN1) {
    RangeNumber1.push(i);
  }

  const [selectedRatings, setSelectedRatings] = useState({});
  const [ClubRating, setClubRating] = useState([]);
  const [TeamName, setTeamName] = useState("");
  const [TeamM, setTeamM] = useState("");
  const [ClubRatingPeople, setClubRatingPeople] = useState([]);
  const [InviteFriendData, setInviteFriendData] = useState([]);

  const [ScoreDuration, setScoreDuration] = useState([]);
  const [CourtType, setCourtType] = useState([]);
  const [InviteFriendModal, setInviteFriendModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [MatchType, setMatchType] = useState([]);
  const [selectedRating, setSelectedRating] = useState({});
  const minRange = 1;
  const maxRange = 5;
  const step = 0.1;
  const RangeNumber = [];
  for (let i = minRange; i <= maxRange; i += step) {
    RangeNumber.push(i.toFixed(1));
  }

  const [input, setInput] = useState([
    {
      rating_term_id: 0,
      rating: 0,
    },
  ]);

  function fnCustomRangeSelect(item, id) {
    setSelectedRating((prevSelectedRating) => ({
      ...prevSelectedRating,
      [id]: item?.toFixed(1),
    }));

    setInput((prevInput) => {
      return [
        ...prevInput,
        {
          rating_term_id: id,
          rating: item?.toFixed(1),
        },
      ];
    });
  }

  async function getClubRateList() {
    let body = {
      term: "club_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRating(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getClubRatePeopleList() {
    // setLoading(true);
    let body = {
      term: "trainer_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRatingPeople(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getDurationTerm() {
    // setLoading(true);
    let body = {
      term: "duration",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setScoreDuration(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getCourtTypeTerm() {
    // setLoading(true);
    let body = {
      term: "court_type_term",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCourtType(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getMatchTypeTerm() {
    // setLoading(true);
    let body = {
      term: "match_type_term",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setMatchType(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getClubRateList();
    getClubRatePeopleList();
    getDurationTerm();
    getCourtTypeTerm();
    getMatchTypeTerm();
  }, []);

  // useEffect(() => {
  //   const initialSelectedRating = {};
  //   ClubRatingPeople.forEach((item) => {
  //     initialSelectedRating[item.term_id] = "1.0";
  //   });
  //   setSelectedRating(initialSelectedRating);
  // }, [ClubRatingPeople]);

  useEffect(() => {
    const initialSelectedRating = {};
    ClubRatingPeople.forEach((item) => {
      initialSelectedRating[item.term_id] = "1.0";
    });
    setSelectedRating(initialSelectedRating);
  }, [ClubRatingPeople]);

  const handleRatingChange = (item, value) => {
    setSelectedRating((prevSelectedRating) => ({
      ...prevSelectedRating,
      [item.term_id]: item.term_id,
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let body = {
      to_association_id: props?.BookingDetail?.trainer_details?.trainer_id,
      to_association_type_term: "trainer",
      ...ClubRatingPeople.reduce((acc, item, index) => {
        const ratingTermId = item.term_id;

        if (ratingTermId !== undefined && ratingTermId !== null) {
          acc[`rating_data[${index}][rating_term_id]`] = ratingTermId;
          acc[`rating_data[${index}][rating]`] =
            selectedRating[ratingTermId] || "1.0";
        }

        return acc;
      }, {}),
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SAVE_RATING,
        body: body,
      },
      true
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      props.onHide();
      props.bindList();
    }
    if (!response?.isValidate) {
    }
  };


  return (
    <div className="RateTrainerModalMain">
      <Modal
        className="AddForgotPasswordModalMain"
        size="lg"
        centered
        {...props}
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>A</div>
            <Modal.Title id="contained-modal-title-vcenter">
              Rate Trainer
            </Modal.Title>
            <div style={{ visibility: "hidden" }}>A</div>
          </Modal.Header>
          <Modal.Body>
            <div className="rate-court-main">
              <div className="d-flex align-items-center gap-2 mb-3">
                <div className="booking-detail-image-main">
                  {props?.BookingDetail?.trainer_details?.trainer_image !==
                    null &&
                  props?.BookingDetail?.trainer_details?.trainer_image !== "" &&
                  props?.BookingDetail?.trainer_details?.trainer_image !==
                    undefined ? (
                    <img
                      src={props?.BookingDetail?.trainer_details?.trainer_image}
                      alt="club-detail-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imagePlaceholder;
                      }}
                    />
                  ) : (
                    <img src={imagePlaceholder} alt="club-detail-image" />
                  )}
                </div>
                <div className="booking-detail-image-content m-0 w-100">
                  <div className="courtContent">
                    <div className="court-and-court-name">
                      <h5 className="m-0">
                        {props?.BookingDetail?.trainer_details?.trainer_name}
                      </h5>
                      <p className="m-0"></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rate-court-ratings">
                <h4>Give Ratings</h4>
                {ClubRatingPeople?.length > 0 &&
                  ClubRatingPeople.map((item, index) => (
                    <RangeRatting
                      type="dot"
                      item={item}
                      index={index}
                      onFinalChangeData={(value) => {
                        console.log("v 01 ", value);

                        fnCustomRangeSelect(value, item.term_id);
                      }}
                    />
                  ))}
                {/* {ClubRatingPeople?.length > 0 &&
                  ClubRatingPeople.map((item, index) => (
                    <div className="slidermain-with-title">
                      <div className="slidermain-with-title-header">
                        <h5 className="m-0">{item.value}</h5>
                        <span>
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="#FFB500"
                          >
                            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                          </svg>{" "}
                          {values[0] / 100}
                        </span>{" "}
                      </div>
                      <div className="range-parent">
                        <div
                          style={{
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            background: "#eee",
                            borderRadius: "25px",
                          }}
                        >
                          <Range
                            values={values}
                            // step={STEP1}
                            min={MIN1}
                            max={MAX1}
                            onChange={(newValues) => {
                              setValues(newValues);
                              fnCustomRangeSelect(
                                newValues[0] / 100,
                                item.term_id
                              );
                            }}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "24px",
                                  display: "flex",
                                  width: "100%",
                                }}
                                className=""
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "18px",
                                    width: "100%",
                                    borderRadius: "30px",
                                    background: getTrackBackground({
                                      values,
                                      colors: ["#ef333f", "#eee"],
                                      min: MIN1,
                                      max: MAX1,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>

                                <div className="dot-range-parent">
                                  {RangeNumber1.map((item, index) => (
                                    <span
                                      className="dot-range"
                                      style={
                                        index === 0 || index === 4
                                          ? {
                                              background: "transparent",
                                            }
                                          : {
                                              background:
                                                values[0] / 100 - 1 < index
                                                  ? "black"
                                                  : "white",
                                            }
                                      }
                                    ></span>
                                  ))}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50%",
                                  backgroundColor: "#ef333f",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  boxShadow: "0px 2px 6px #AAA",
                                }}
                              >
                                <div
                                  style={{
                                    height: "5px",
                                    borderRadius: "50%",
                                    width: "5px",
                                    backgroundColor: isDragged
                                      ? "#fff"
                                      : "#fff",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="d-flex justify-content-between redrange w-100">
                        {RangeNumber1.map((item1, index1) => (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill={values[0] >= item1 ? "#FFB500" : "#BBBBBB"}
                            >
                              <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                            </svg>
                          </>
                        ))}
                      </div>
                    </div>
                  ))} */}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
              <button type="submit" className="red-btn w-100">
                {Loading ? "Loading..." : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {InviteFriendModal && (
        // <AddPlayerModal
        //   show={InviteFriendModal}
        //   InviteFriendData={setInviteFriendData}
        //   InviteData={InviteFriendData}
        //   onHide={() => setInviteFriendModal(false)}
        // />
        <AddPlayerModal
          show={InviteFriendModal}
          InviteFriendData={setInviteFriendData}
          InviteData={InviteFriendData}
          TeamName={TeamName}
          TeamM={TeamM}
          onHide={() => setInviteFriendModal(false)}
        />
      )}
    </div>
  );
}

export default RateTrainerModal;
