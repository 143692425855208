import React from "react";
import Skeleton from "react-loading-skeleton";
import information from "../images/informaton.svg";
// import Slider from "react-slick";
import { Card, Col, Row, Table } from "react-bootstrap";
import "react-loading-skeleton/dist/skeleton.css";
// import { ReactComponent as NoDataFound } from "../src/assets/Icons/no-record-found.svg";
// import { ReactComponent as NoDataFoundWhite } from "../src/assets/Icons/no-record-found-white.svg";
export const LoaderType = {
  ClubCard: "ClubCard",
  BookingCard: "BookingCard",
  ProfileCard: "ProfileCard",
  InviteFriend: "InviteFriend",
  HomePost: "HomePost",
  EditProfile: "EditProfile",
  RecentPost: "RecentPost",
  Table: "Table",
  AVAILABLE_SLOT: "AVAILABLE_SLOT",
  Line: "Line",
  PROJECTLIST: "PROJECTLIST",
  ProjectDetails: "PROJECTDETAILS",
  PROJECTCOUNT: "PROJECTCOUNT",
  TaskDetails: "TASKDETAILS",
  PROJECTSLIDER: "PROJECTSLIDER",
  DOCFILE: "DOCFILE",
  NODATAFOUND: "NODATAFOUND",
  TASKLIST: "TASKLIST",
  HEADERSEARCH: "HEADERSEARCH",
  NODATAFOUND_WHITE: "NODATAFOUND_WHITE",
  DOCUpload: "DOCUpload",
  TaskComment: "TaskComment",
  CountBox: "COUNTBOX",
};

function Loader(prop) {
  var projectslider = {
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnFocus: true,
    pauseOnHover: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 2,
    draggable: true,
    variableWidth: true,
    slide: ".black-card",
    centerMargin: "15px",
  };

  let count = prop.count || 4;
  let type = prop.type || "Line";
  let lineheight = prop.lineheight || 40;
  let height = prop.height || "100%";
  let width = prop.width || "100%";
  let color = prop.color || "#000000";

  let line = [];
  for (let i = 0; i < count; i++) {
    line.push(i);
  }

  if (type.toUpperCase() === "TABLE") {
    return (
      <div className="skeleton">
        <Table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {line.map((item, index) => (
              <tr key={index}>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
                <td>
                  <Skeleton height={lineheight} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else if (type.toUpperCase() === "PROJECTLIST") {
    return (
      <>
        {" "}
        {line.map((item, index) => (
          <Col
            className="mb-3 col-xxl-4 col-xl-6 col-lg-6 col-md-12"
            key={index}
          >
            <Card className="card-white-bg">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Skeleton height={80} width={80} circle={true} />
                    <div className="ms-3">
                      <Card.Title>
                        <Skeleton height={20} width={100} />
                      </Card.Title>
                      <div className="task-date">
                        <div className="d-flex align-items-center">
                          <Skeleton height={15} width={15} />
                          <div className="ms-2">
                            <Skeleton height={15} width={60} />
                          </div>
                        </div>
                      </div>
                      <div className="project-cards-labels d-flex">
                        <Skeleton height={24} width={65} className="me-1" />
                        <Skeleton height={24} width={65} className="me-1" />
                        <Skeleton height={24} width={65} />
                      </div>
                    </div>
                  </div>
                  <div className="mb-auto ms-2">
                    <Skeleton height={10} width={20} />
                  </div>
                </div>
                <div className="mt-3 mb-1">
                  <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                    <div className="ProgressBar-text">
                      <Skeleton height={12} width={60} />
                    </div>
                    <div className="ProgressBar-percentage">
                      <Skeleton height={15} width={60} />
                    </div>
                  </div>
                  <Skeleton height={10} width="100%" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center me-3">
                    <Skeleton height={30} width={60} className="me-1" />
                    <Skeleton height={30} width={60} />
                  </div>
                  <div className="project-details">
                    <div className="users d-flex">
                      <Skeleton
                        height={50}
                        width={50}
                        circle={true}
                        className="me-1"
                      />
                      <Skeleton
                        height={50}
                        width={50}
                        circle={true}
                        className="me-1"
                      />
                      <Skeleton height={50} width={50} circle={true} />
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "PROJECTDETAILS") {
    return (
      <>
        {" "}
        {
          <Card className="border-0">
            <Card.Body>
              {/* <div className="project-no"><Skeleton height={lineheight} width={20} height={20} /></div> */}
              <div className="customer-col">
                <div className="customer-name">
                  <i className="me-3">
                    {" "}
                    <Skeleton
                      height={lineheight}
                      width={30}
                      circle={true}
                      height={25}
                      width={25}
                    />
                  </i>
                  <Skeleton height={lineheight} width={25} height={25} />
                </div>
                <label className="label-badge  min-width-90px">
                  <Skeleton height={lineheight} width={100} height={25} />
                </label>
              </div>
              <Row>
                <Col xl={4}>
                  <div className="project-status-date">
                    <span className="project-date-status">
                      <Skeleton height={lineheight} width={150} height={20} />
                    </span>
                    <div className="date">
                      <Skeleton height={lineheight} width={100} height={15} />
                    </div>
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="project-status-date">
                    <span className="project-date-status">
                      <Skeleton height={lineheight} width={150} height={20} />
                    </span>
                    <div className="date">
                      <Skeleton height={lineheight} width={100} height={15} />
                    </div>
                  </div>
                </Col>
                <Col xl={4}>
                  <div className="project-status-date">
                    <span className="project-date-status">
                      <Skeleton height={lineheight} width={150} height={20} />
                    </span>
                    <div className="date">
                      <Skeleton height={lineheight} width={100} height={15} />
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="project-description">
                <h5>
                  <Skeleton height={lineheight} width={120} height={25} />
                </h5>
                <p className="description">
                  <Skeleton height={lineheight} width={"100%"} height={18} />
                  <Skeleton height={lineheight} width={"100%"} height={18} />
                  <Skeleton height={lineheight} width={"100%"} height={18} />
                </p>
              </div>
            </Card.Body>
          </Card>
        }
      </>
    );
  } else if (type.toUpperCase() === "PROJECTCOUNT") {
    return (
      <>
        {" "}
        {
          <div className="row cards mb-4">
            <div className="col-4">
              <Card className="today-task bg-gray square-bg-img">
                <Card.Body>
                  <Card.Title>
                    <Skeleton height={lineheight} width={30} height={40} />
                  </Card.Title>
                  <Card.Text>
                    <Skeleton height={lineheight} width={70} height={20} />
                    <span className="d-block">
                      <Skeleton height={lineheight} width={50} height={30} />
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-4">
              <Card className="today-task bg-gray square-bg-img">
                <Card.Body>
                  <Card.Title>
                    <Skeleton height={lineheight} width={30} height={40} />
                  </Card.Title>
                  <Card.Text>
                    <Skeleton height={lineheight} width={70} height={20} />
                    <span className="d-block">
                      <Skeleton height={lineheight} width={50} height={30} />
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-4">
              <Card className="today-task bg-gray square-bg-img">
                <Card.Body>
                  <Card.Title>
                    <Skeleton height={lineheight} width={30} height={40} />
                  </Card.Title>
                  <Card.Text>
                    <Skeleton height={lineheight} width={70} height={20} />
                    <span className="d-block">
                      <Skeleton height={lineheight} width={50} height={30} />
                    </span>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        }
      </>
    );
  } else if (type.toUpperCase() === "TASKDETAILS") {
    return (
      <>
        {" "}
        {
          <Row>
            <div className="task-detail-title">
              <Skeleton height={lineheight} />
            </div>
            <Col className="">
              <Skeleton height={40} />
              <div className="tab-inner-box">
                <Row>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-lg-6">
                    <Row>
                      <Col className="col-xl-4">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right">
                          <div className="form-floating">
                            <Skeleton height={lineheight} />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-2 col-12">
                    <Row>
                      <Col className="col-xl-2">
                        <div className="tab-inner-box-left">
                          <Skeleton height={lineheight} />
                        </div>
                      </Col>
                      <Col className="col-xl-8">
                        <div className="tab-inner-box-right d-flex">
                          <Skeleton height={lineheight} width={100} />
                          <div className="project-assign-profile ms-2">
                            <div className="project-details">
                              <div className="users">
                                <Skeleton
                                  height={35}
                                  width={35}
                                  circle={true}
                                />
                                <Skeleton
                                  height={35}
                                  width={35}
                                  circle={true}
                                />
                                <Skeleton
                                  height={35}
                                  width={35}
                                  circle={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={12} className="mt-4">
              <div className="form-floating">
                <Skeleton height={lineheight} />
                <Skeleton height={lineheight} />
                <Skeleton height={lineheight} />
                <Skeleton height={lineheight} />
                <Skeleton height={lineheight} width={"50%"} />
              </div>
            </Col>
            <Col xs={12} className="mt-4">
              <Skeleton height={400} />
            </Col>
          </Row>
        }
      </>
    );
  } else if (type.toUpperCase() === "PROJECTSLIDER") {
    return (
      <>
        {
          //   <Slider {...projectslider}>
          <>
            <Card className="card">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Skeleton height={78} width={78} circle={true} />
                    <div className="ms-4">
                      <Card.Title>
                        <Skeleton height={lineheight} height={20} width={50} />
                      </Card.Title>
                      <div className="task-date">
                        <div className="d-flex align-items-center">
                          <Skeleton
                            height={lineheight}
                            height={15}
                            width={15}
                          />
                          <div className="ms-2">
                            <Skeleton
                              height={lineheight}
                              height={15}
                              width={50}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="project-cards-labels d-flex">
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                    <div className="ProgressBar-text">
                      <Skeleton height={lineheight} height={13} width={50} />
                    </div>
                    <div className="ProgressBar-percentage">
                      <Skeleton height={lineheight} height={15} width={48} />
                    </div>
                  </div>
                  <Skeleton height={lineheight} height={12} width="100%" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center me-3">
                    <div>
                      <label>
                        <Skeleton height={lineheight} height={30} width={95} />
                      </label>
                    </div>
                    <div className="ms-2">
                      <Skeleton height={lineheight} height={30} width={75} />
                    </div>
                  </div>
                  <div className="project-details d-flex">
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="card">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Skeleton height={78} width={78} circle={true} />
                    <div className="ms-4">
                      <Card.Title>
                        <Skeleton height={lineheight} height={20} width={50} />
                      </Card.Title>
                      <div className="task-date">
                        <div className="d-flex align-items-center">
                          <Skeleton
                            height={lineheight}
                            height={15}
                            width={15}
                          />
                          <div className="ms-2">
                            <Skeleton
                              height={lineheight}
                              height={15}
                              width={50}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="project-cards-labels d-flex">
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                    <div className="ProgressBar-text">
                      <Skeleton height={lineheight} height={13} width={50} />
                    </div>
                    <div className="ProgressBar-percentage">
                      <Skeleton height={lineheight} height={15} width={48} />
                    </div>
                  </div>
                  <Skeleton height={lineheight} height={12} width="100%" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center me-3">
                    <div>
                      <label>
                        <Skeleton height={lineheight} height={30} width={95} />
                      </label>
                    </div>
                    <div className="ms-2">
                      <Skeleton height={lineheight} height={30} width={75} />
                    </div>
                  </div>
                  <div className="project-details d-flex">
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                  </div>
                </div>
              </Card.Body>
            </Card>
            <Card className="card">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <Skeleton height={78} width={78} circle={true} />
                    <div className="ms-4">
                      <Card.Title>
                        <Skeleton height={lineheight} height={20} width={50} />
                      </Card.Title>
                      <div className="task-date">
                        <div className="d-flex align-items-center">
                          <Skeleton
                            height={lineheight}
                            height={15}
                            width={15}
                          />
                          <div className="ms-2">
                            <Skeleton
                              height={lineheight}
                              height={15}
                              width={50}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="project-cards-labels d-flex">
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                        <Skeleton
                          height={lineheight}
                          height={22}
                          width={60}
                          className="me-1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3 mb-3">
                  <div className="ProgressBar-content mb-1 d-flex align-items-center justify-content-between">
                    <div className="ProgressBar-text">
                      <Skeleton height={lineheight} height={13} width={50} />
                    </div>
                    <div className="ProgressBar-percentage">
                      <Skeleton height={lineheight} height={15} width={48} />
                    </div>
                  </div>
                  <Skeleton height={lineheight} height={12} width="100%" />
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-between align-items-center me-3">
                    <div>
                      <label>
                        <Skeleton height={lineheight} height={30} width={95} />
                      </label>
                    </div>
                    <div className="ms-2">
                      <Skeleton height={lineheight} height={30} width={75} />
                    </div>
                  </div>
                  <div className="project-details d-flex">
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                    <Skeleton height={50} width={50} circle={true} />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </>
          //   </Slider>
        }
      </>
    );
  } else if (type.toUpperCase() === "DOCFILE") {
    return (
      <>
        <div className="files-tab-items">
          {line.map((item, index) => (
            <div className="files-tab-item">
              <Skeleton
                height={lineheight}
                width={"100%"}
                height={150}
                className="py-2 rounded-top"
              />
              <div className="files-tab-item-bottom">
                <div className="files-tab-bottom-left">
                  <div className="files-tab-bottom-title">
                    <Skeleton height={lineheight} width={180} height={20} />
                  </div>
                  <div className="files-bottom-size-data">
                    <div className="files-size-bottom">
                      <Skeleton height={lineheight} width={50} height={20} />
                    </div>
                    <div className="files-upload-data-bottom">
                      <Skeleton height={lineheight} width={50} height={20} />
                    </div>
                  </div>
                </div>
                <div className="files-tab-bottom-right">
                  <Skeleton height={lineheight} width={15} height={20} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  } else if (type.toUpperCase() === "DOCUPLOAD") {
    return (
      <div className="files-tab-item">
        <Skeleton
          height={lineheight}
          width={"100%"}
          height={150}
          className="py-2 rounded-top"
        />
        <div className="files-tab-item-bottom">
          <div className="files-tab-bottom-left">
            <div className="files-tab-bottom-title">
              <Skeleton height={lineheight} width={180} height={20} />
            </div>
            <div className="files-bottom-size-data">
              <div className="files-size-bottom">
                <Skeleton height={lineheight} width={50} height={20} />
              </div>
              <div className="files-upload-data-bottom">
                <Skeleton height={lineheight} width={50} height={20} />
              </div>
            </div>
          </div>
          <div className="files-tab-bottom-right">
            <Skeleton height={lineheight} width={15} height={20} />
          </div>
        </div>
      </div>
    );
  } else if (type.toUpperCase() === "NODATAFOUND") {
    return (
      <div
        style={{
          height: height,
          width: width,
          // fontSize: fontsize,
          color: color,
          textAlign: "center",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <NoDataFound /> */}
        <div className="text-center d-flex align-items-center justify-content-center gap-1"><img src={information} alt="information"/> <p>No data found</p></div>
      </div>
    );
  } else if (type.toUpperCase() === "NODATAFOUND_WHITE") {
    return (
      <div
        style={{
          height: height,
          width: width,
          // fontSize: fontsize,
          color: color,
          textAlign: "center",
          margin: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <NoDataFoundWhite /> */}
        <div className="text-center d-flex align-items-center justify-content-center gap-1"><img src={information} alt="information"/> <p>No data found</p></div>
      </div>
    );
  } else if (type.toUpperCase() === "TASKLIST") {
    return (
      <div className="table-col task-table-tab">
        <div className="recent-task-section">
          <div className="d-flex align-items-center justify-content-between table-header-col">
            <div className="project-title me-3">
              <Skeleton height={lineheight} width={180} />
            </div>
            <div className="total-time">
              <Skeleton height={lineheight} width={180} />
            </div>
          </div>
          <div className="table-scroll table-section-col">
            <Table responsive className="table-inner-section mb-0 pb-0">
              <tbody>
                <tr>
                  <td className="text-nowrap padding-top-bottom width-300px">
                    <div className="d-flex align-items-center">
                      <Skeleton height={44} width={44} circle={true} />
                      <div className="content-project-col">
                        <div className="project-name">
                          <Skeleton width={100} />
                        </div>
                        <div className="project-description">
                          <Skeleton width={100} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-nowrap padding-top-bottom width-200px">
                    <div className="project-details">
                      <div className="d-flex align-items-center users">
                        <Skeleton height={34} width={34} circle={true} />
                        <Skeleton height={34} width={34} circle={true} />
                      </div>
                    </div>
                  </td>
                  <td className="width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-start width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-end width-75px">
                    <div>
                      <Skeleton width={20} />
                    </div>
                  </td>
                  <td className="text-center text-nowrap padding-top-bottom width-200px">
                    <div className="d-flex align-items-baseline justify-content-end">
                      <div className="task-time-status d-flex align-items-center justify-content-end">
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap padding-top-bottom width-300px">
                    <div className="d-flex align-items-center">
                      <Skeleton height={44} width={44} circle={true} />
                      <div className="content-project-col">
                        <div className="project-name">
                          <Skeleton width={100} />
                        </div>
                        <div className="project-description">
                          <Skeleton width={100} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-nowrap padding-top-bottom width-200px">
                    <div className="project-details">
                      <div className="d-flex align-items-center users">
                        <Skeleton height={34} width={34} circle={true} />
                        <Skeleton height={34} width={34} circle={true} />
                      </div>
                    </div>
                  </td>
                  <td className="width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-start width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-end width-75px">
                    <div>
                      <Skeleton width={20} />
                    </div>
                  </td>
                  <td className="text-center text-nowrap padding-top-bottom width-200px">
                    <div className="d-flex align-items-baseline justify-content-end">
                      <div className="task-time-status d-flex align-items-center justify-content-end">
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="recent-task-section">
          <div className="d-flex align-items-center justify-content-between table-header-col">
            <div className="project-title me-3">
              <Skeleton height={lineheight} width={180} />
            </div>
            <div className="total-time">
              <Skeleton height={lineheight} width={180} />
            </div>
          </div>
          <div className="table-scroll table-section-col">
            <Table responsive className="table-inner-section mb-0 pb-0">
              <tbody>
                <tr>
                  <td className="text-nowrap padding-top-bottom width-300px">
                    <div className="d-flex align-items-center">
                      <Skeleton height={44} width={44} circle={true} />
                      <div className="content-project-col">
                        <div className="project-name">
                          <Skeleton width={100} />
                        </div>
                        <div className="project-description">
                          <Skeleton width={100} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-nowrap padding-top-bottom width-200px">
                    <div className="project-details">
                      <div className="d-flex align-items-center users">
                        <Skeleton height={34} width={34} circle={true} />
                        <Skeleton height={34} width={34} circle={true} />
                      </div>
                    </div>
                  </td>
                  <td className="width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-start width-150px">
                    <div className="table-text-common text-nowrap">
                      <Skeleton width={100} />
                    </div>
                  </td>
                  <td className="text-end width-75px">
                    <div>
                      <Skeleton width={20} />
                    </div>
                  </td>
                  <td className="text-center text-nowrap padding-top-bottom width-200px">
                    <div className="d-flex align-items-baseline justify-content-end">
                      <div className="task-time-status d-flex align-items-center justify-content-end">
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                        <Skeleton height={24} width={24} circle={true} />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  } else if (type.toUpperCase() === "HEADERSEARCH") {
    return (
      <>
        {" "}
        {line.map((item, index) => (
          <div className="mb-3 col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-12">
            <div className="card-col d-flex">
              <Card.Body className="">
                <div className="emplo-img d-flex justify-content-between align-items-start">
                  <Skeleton height={65} width={65} circle={true} />
                  <Skeleton
                    className="department ms-2"
                    height={20}
                    width={50}
                  />
                </div>

                <Card.Title className="mb-1 d-flex align-items-center text-capitalize justify-content-between">
                  <Skeleton width={150} />
                </Card.Title>
                <div className="emplo-details d-flex flex-wrap">
                  <Skeleton
                    className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2"
                    height={20}
                    width={50}
                  />
                  <Skeleton
                    className="lables project-lable d-flex align-items-center justify-content-center mt-2 me-2"
                    height={20}
                    width={50}
                  />
                </div>
              </Card.Body>
            </div>
          </div>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "TASKCOMMENT") {
    return (
      <>
        {" "}
        {line.map((item, index) => (
          <div className="comment-item mb-3 d-flex">
            <div className="comment-profile me-3">
              <Skeleton height={42} width={42} circle={true} />
            </div>
            <div className="comment-col w-100 mt-2">
              <div className="d-flex">
                <div className="user-comment-name">
                  <Skeleton height={20} width={100} />
                </div>
                <div className="comment-time ms-3">
                  <Skeleton height={20} width={100} />
                </div>
              </div>
              <div className="comments">
                <Skeleton height={20} width={"100%"} />
                <Skeleton height={20} width={"100%"} />
              </div>
            </div>
          </div>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "COUNTBOX") {
    return (
      <>
        {" "}
        {line.map((item, index) => (
          <Col xl={3} lg={4} md={6}>
            <Card className="today-task blue-crayola square-bg-img">
              <Card.Body>
                <Card.Title className="ms-auto me-0">
                  <Skeleton
                    height={85}
                    width={85}
                    circle={true}
                    className="float-end"
                  />
                </Card.Title>

                <Card.Text>
                  <Skeleton height={20} width={100} />
                  <span className="d-block">
                    <Skeleton height={25} width={"100%"} />
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "CLUBCARD") {
    return (
      <>
        {line.map((item, index) => (
          <Card className="m-0 p-0">
            <Skeleton height={196} width={"95%"} className="m-2" />
            <Card.Body className="m-0 p-0">
              <Card.Text>
                <p>
                  <Skeleton height={20} width={85} />
                </p>

                <div>
                  <span className="text-center d-flex align-items-center     justify-content-between">
                    <Skeleton height={50} width={82} />
                    <Skeleton height={50} width={82} />
                    <Skeleton height={50} width={82} />
                  </span>
                </div>
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "INVITEFRIEND") {
    return (
      <>
        <div className="invite-friends-main">
          {line.map((item, index) => (
            <div key={index} className="invite-friends-single">
              <div className="invite-friends-single-img-name">
                <Skeleton height={50} width={50} circle={true} />
                <p>
                  <Skeleton height={22} width={85} />
                </p>
              </div>
              <Skeleton height={30} width={50} />
            </div>
          ))}
        </div>
      </>
    );
  } else if (type.toUpperCase() === "HOMEPOST") {
    return (
      <>
        {line.map((item, index) => (
          <>
            <div className="single-post-wrapper FindClubCard-main">
              <div className="single-post-top">
                <div className="FindClubCard-main">
                  <div className="d-flex align-items-center profile-and-time">
                    <div className="write-something-profile">
                      <Skeleton height={50} width={50} circle={true} />
                    </div>
                    <div className="username-with-time">
                      <h5>
                        <Skeleton height={18} width={200} />
                      </h5>
                      <p>
                        <Skeleton height={15} width={50} />
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <Skeleton height={50} width={50} circle={true} />
                </div>
              </div>
              <div className="single-post-middle">
                <div className="post-section">
                  <div className="slide-out-of-total">
                    <Skeleton height={250} width={"100%"} />
                  </div>
                </div>
              </div>
              <div className="single-post-bottom">
                <div className="like-and-comment">
                  <div className="like-with-like-number">
                    <Skeleton height={20} width={20} circle={true} />
                    <p>
                      <Skeleton height={20} width={50} />
                    </p>
                  </div>
                  <div className="like-with-like-number">
                    <Skeleton height={20} width={20} circle={true} />
                    <p>
                      <Skeleton height={20} width={50} />
                    </p>
                  </div>
                </div>
                <div className="post-detail">
                  <p>
                    <Skeleton height={18} width={"90%"} />
                    {/* <Skeleton height={18} width={"90%"} />
                    <Skeleton height={18} width={"90%"} /> */}
                  </p>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "RECENTPOST") {
    return (
      <>
        {line.map((item, index) => (
          <>
            <div className="ActivityList-class">
              <div className="activity-section-bottom-single">
                <div className="activity-section-bottom-single-top">
                  <p>
                    <Skeleton height={20} width={220} />
                  </p>
                  <span>
                    <Skeleton height={20} width={50} />
                  </span>
                </div>
                <div className="activity-section-bottom-single-bottom d-block">
                  <p>
                    <Skeleton height={20} width={70} />
                  </p>
                  <h6 className="mb-0">
                    <Skeleton height={20} width={120} />
                  </h6>
                  <span>
                    <Skeleton height={20} width={40} />
                  </span>
                </div>
              </div>
            </div>
          </>
        ))}
      </>
    );
  } else if (type.toUpperCase() === "BOOKINGCARD") {
    return (
      <>
        <div className="my-booking-wrapper mb-5">
          <div className="all-my-bookings">
            {line.map((item, index) => (
              <>
                <div className="my-single-booking ">
                  <>
                    <div className="court1Image">
                      <Skeleton height={70} width={70} circle={true} />
                    </div>
                    <div className="courtContent">
                      <div className="court-and-court-name">
                        <p>
                          <Skeleton height={18} width={80} />
                        </p>
                        <h5>
                          <Skeleton height={20} width={200} />
                        </h5>
                      </div>
                      <div className="booking-court-type">
                        <p>
                          <Skeleton height={18} width={60} />
                        </p>
                      </div>

                      <div className="booking-court-type">
                        <p>
                          <Skeleton height={30} width={120} />
                        </p>
                        <p>
                          <Skeleton height={30} width={120} />
                        </p>
                      </div>
                    </div>
                  </>

                  <div
                    className="booking-status-badge"
                    style={{ background: "transparent" }}
                  >
                    <p>
                      <Skeleton height={30} width={68} />
                    </p>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </>
    );
  } else if (type.toUpperCase() === "PROFILECARD") {
    return (
      <>
        <div className="profile-detail-page-right">
          <div className="profile-detail-page-right-inner">
            <div>
              <Skeleton height={100} width={100} circle={true} />
              <h4 className="name-having-initials">
                <Skeleton height={19} width={180} />
              </h4>
            </div>

            <p
              style={{
                color: "#ef333f",
              }}
            >
              <Skeleton height={15} width={180} />
            </p>

            <span>
              <Skeleton height={15} width={80} />
            </span>
            <div className="post-following-followers-wrapper">
              <div className="post-following-followers-inner">
                <h6>
                  <Skeleton height={20} width={20} />
                </h6>
                <span>
                  <Skeleton height={16} width={80} />
                </span>
              </div>
              <div className="post-following-followers-inner">
              <h6>
                  <Skeleton height={20} width={20} />
                </h6>
                <span>
                  <Skeleton height={16} width={80} />
                </span>
              </div>
              <div className="post-following-followers-inner">
              <h6>
                  <Skeleton height={20} width={20} />
                </h6>
                <span>
                  <Skeleton height={16} width={80} />
                </span>
              </div>
            </div>

            <div className="unfollow-and-three-dots">
              <div className=" w-100">
                <Skeleton height={50} width={300} />
              </div>
              <Skeleton height={50} width={50} />
            </div>

            <div
              //  className="level-information-on-badge"
              className={
                " level-information-on-badge level-information-on-badge-for-standard-user"
              }
            >
              <h5>
                <Skeleton height={56} width={56} circle={true} />
              </h5>
              {/* <p>
                <Skeleton height={18} width={80} />
              </p> */}
            </div>

            {/* <div className="level-information-on-badge">
                  <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                  <p>Level</p>
                </div> */}
          </div>
        </div>
      </>
    );
  } else if (type.toUpperCase() === "AVAILABLE_SLOT") {
    return (
      <>
       
        <div className="legends-of-available-slots-content">
          {line.map((item, index) => (
            <>
              <div class="legends-of-available-slots-inner-para ">
                <p className="p-2">
                  <Skeleton height={35} width={100} />
                </p>
              </div>
            </>
          ))}
        </div>
      </>
    );
  } else {
    return (
      <div className="skeleton">
        <Skeleton count={count} height={lineheight} />
      </div>
    );
  }
}
export default Loader;
