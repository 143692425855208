import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import { commonservices } from "../services/CommonServices";
import walletIcon from "../images/walletIcon.svg";
import bankIcon from "../images/bankIcon.png";

const CalncelBokingDetailModal = (props) => {
  // console.log("Cancel booking ;;", props);
  const [Loading, setLoading] = useState(false);
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [MemberConnection, setMemberConnection] = useState([]);

  const [input, setInput] = useState({
    wallet: "wallet",
    is_phone_verified: "",
    errors: {
      wallet: "",
      is_phone_verified: "",
      ValidationRules: [
        {
          FieldName: "is_phone_verified",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });
    if (obj.isValid) {
      setLoading(true);
      let body = {
        sub_order_id: props?.param,
        refund_method: input?.wallet,
      };

      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_CANCEL,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData);
      if (response?.isValidate) {
        props.bindList();
        props.onHide();
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  };

  return (
    <>
      <Modal className="" size="md" centered {...props}>
        <Modal.Header closeButton>
          {/* <div style={{ visibility: "hidden" }}>A</div> */}
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to calncel your match ?
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Body>
            <div className="label-with-icon-main-wrapper">
              {props?.BookingDetail?.payment_via === "wallet" ? (
                <div className="red-radio-btn justify-content-between">
                  <label
                    className="form-check-label d-flex align-items-center gap-2"
                    for="wallet"
                  >
                    <img src={walletIcon} alt="walletIcon" />
                    Wallet
                  </label>
                  <Form.Check
                    type="radio"
                    id="wallet"
                    className="circular-checkbox"
                    name="fav_language"
                    value="wallet"
                    checked={input.wallet === "wallet"}
                    onChange={(e) => {
                      setInput({
                        ...input,
                        ["wallet"]: e.target.value,
                      });
                    }}
                  />
                </div>
              ) : (
                <>
                  <div className="red-radio-btn justify-content-between">
                    <label
                      className="form-check-label d-flex align-items-center gap-2"
                      for="wallet"
                    >
                      <img src={walletIcon} alt="walletIcon" />
                      Wallet
                    </label>
                    <Form.Check
                      type="radio"
                      id="wallet"
                      className="circular-checkbox"
                      name="fav_language"
                      value="wallet"
                      checked={input.wallet === "wallet"}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["wallet"]: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="red-radio-btn justify-content-between">
                    <label
                      className="form-check-label d-flex align-items-center gap-2"
                      for="original"
                    >
                      <img src={bankIcon} alt="walletIcon" />
                      Original payment method
                    </label>
                    <Form.Check
                      type="radio"
                      id="original"
                      name="fav_language"
                      className="circular-checkbox"
                      value="original"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["wallet"]: e.target.value,
                        });
                      }}
                    />
                  </div>
                </>
              )}

              <div className="red-radio-btn  justify-content-between">
                <Form.Check
                  type="checkbox"
                  className="dark-border-check-box"
                  id="is_phone_verified"
                  name="is_phone_verified"
                  onChange={(e) => {
                    setInput({
                      ...input,
                      ["is_phone_verified"]: e.target.value,
                    });
                  }}
                  value="1"
                  label="I accept Terms and Conditions and Privacy policy"
                  isInvalid={input.errors.is_phone_verified}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-2 align-items-center w-100">
              <button
                className="white-btn dark-color red-border w-100"
                style={{ height: "40px", fontSize: "16px" }}
                onClick={() => props.onHide()}
              >
                No
              </button>
              {Loading ? (
                <button
                  type="submit"
                  className="red-btn red-border w-100"
                  style={{ height: "40px", fontSize: "16px" }}
                >
                  Loading...
                </button>
              ) : (
                <button
                  className="red-btn red-border w-100"
                  style={{ height: "40px", fontSize: "16px" }}
                  type="submit"
                >
                  Yes
                </button>
              )}
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CalncelBokingDetailModal;
