import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
const lib = ["places"];

const AppointmentModal = (props) => {
  
  const [Loading, setLoading] = useState(false);

  async function fnFinalCreateSlot() {
    setLoading(true);
    
    let body = {
      start_time: props.body.start_time,
      end_time: props.body.end_time,
      price: props.body.price,
      is_confirmed: 1,
      days: props.body.days,
      all_days: props.body.all_days,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_SAVE_AVAILABILITY,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      props.onHide();
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  return (
    <>
      <Modal className="" size="sm" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Appointment</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body className="">
          Some of slots have booking!Do you want continue slot creation
          excluding that booked slots? or you can manulally cancel all booking
          from booking list.
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
            <Button
              onClick={() => fnFinalCreateSlot()}
              className="red-btn w-50"
            >
              {Loading ? "Loading..." : "Yes"}
            </Button>
            <button onClick={() => props.onHide()} className="red-btn w-50">
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AppointmentModal;
