// import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

import React, { useState } from "react";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { toast } from "react-toastify";
import { EditUserInfo, UserInfo } from "../Redux/Action";
import { useDispatch } from "react-redux";

const MembershipBuyModal = (props) => {
  let dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);

  const clientSecret = process.env.REACT_APP_STRIPE_SECRET_KEY;
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [input, setInput] = useState({
    reason_for_cancellation: "",
  });

  function PaymentForm(data) {
    // const clientSecret =
    //   "ek_test_YWNjdF8xTmQ5T3NKQzdZV3hkZUgyLE94UzE1OThEc3N3cEJKQ2dmOGlpdWk0SWJJek1pT1Y_00qZOSj6S5";
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!stripe || !elements) {
        console.log("Stripe.js has not yet loaded.");
        return;
      }

      // create a payment method
      const paymentMethod = await stripe
        ?.createPaymentMethod({
          type: "card",
          card: elements?.getElement(CardElement),
        })
        .then(function (result) {
          setLoading(true);
          if (result.hasOwnProperty("paymentMethod")) {
            buySubscription(
              result?.paymentMethod?.id,
              data?.id?.Subscriptiondata?.subscription_id
            );
          } else {
            setLoading(false);
          }
        });
    };
    async function getAuthProfile() {
      try {
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.GET_USER_PROFILE,
          },
          false
        );
        let response = apiResponse(false, resData);
        if (response?.isValidate) {
          let temp = [];
          temp.push({
            ...response?.data?.data?.user,
            association_id: response?.data?.data?.user?.member_id,
          });
          dispatch(EditUserInfo(temp[0]));
          // dispatch(EditUserInfo(response?.data?.data?.user));
          window.location.reload();
        }
        if (!response?.isValidate) {
          console.log("Error getting country list", response);
        }
      } catch (error) {
        console.error("Error fetching club data:", error);
      }
    }

    async function buySubscription(paymentMethodid, subscription_id) {
      let body = {
        subscription_id: subscription_id,
        payment_method_id: paymentMethodid,
      };
      try {
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.SUBSCRIPTION_BUY_SUBSCRIPTION,
            body: body,
          },
          false
        );
        let response = apiResponse(true, resData);
        if (response?.isValidate) {
          getAuthProfile();
          props.onHide();
        }
        if (!response?.isValidate) {
          console.log("Error getting list", response);
        }
      } catch (error) {
        console.error("Error fetching club data:", error);
        setLoading(false);
      }
    }

    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement id="card" />
        <Button type="submit" className="red-btn red-border w-100 mt-4">
          {Loading ? "Processing..." : "Buy Subscription"}
        </Button>
      </form>
    );
  }

  async function handleSubmitCancel() {
    // const handleSubmitCancel = async (e) => {
    // e.preventDefault();
    if (props?.MembershipDetail) {
      setLoading(true);
      // let temp = props?.SubscriptionList[0]?.prices.find(
      //   (f) => f.subscription_details === null
      // );

      const body = {
        user_subscription_id: props?.MembershipDetail?.subscription_id,
        reason_for_cancellation: input.reason_for_cancellation,
      };
      try {
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.SUBSCRIPTION_CANCEL,
            body: body,
          },
          false
        );
        // let response = apiResponse(true, resData);
        // if (response?.isValidate) {

        //   props.onHide();
        // }
        if (resData.status === 0) {
          props.onHide();
          toast.success(resData?.message);
        } else {
          toast.error(resData?.message);
        }
        props?.bindList();
      } catch (error) {
        console.error("Error fetching club data:", error);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <Modal className="membership-buy-modal" size="lg" centered {...props}>
        {/* <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        > */}
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.SubscribeType === "cancel" ? (
              <>Are you sure you want to cancel this membership ?</>
            ) : (
              "MembershipBuyModal"
            )}
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        {props?.SubscribeType === "cancel" ? (
          <Modal.Body>
            <Form.Control
              as="textarea"
              placeholder="Reason for cancel..."
              style={{ height: "100px" }}
              onChange={(e) => {
                setInput({
                  ...input,
                  ["reason_for_cancellation"]: e.target.value,
                });
              }}
              className="black-colored-focused"
            />
          </Modal.Body>
        ) : (
          <Modal.Body>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise}>
                <PaymentForm id={props} />
              </Elements>
            )}
          </Modal.Body>
        )}

        <Modal.Footer>
          {/* {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="red-btn otp-btn w-100">Next</button>
            )} */}

          {props?.SubscribeType === "cancel" && (
            <div className="d-flex gap-2 w-100">
              <button
                className="white-btn dark-color red-border w-100"
                onClick={() => props.onHide()}
              >
                No
              </button>
              {Loading ? (
                <button className="red-btn red-border w-100">Loading...</button>
              ) : (
                <button
                  className="red-btn red-border w-100"
                  onClick={() => handleSubmitCancel()}
                >
                  Yes
                </button>
              )}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MembershipBuyModal;
