export const ENUMS = {
  actions: {
    USER_INFO: "USER INFO",
    EDIT_USER_INFO: "EDIT USER INFO",
    CLUB_FILTER: "CLUB FILTER",
    TRAINER_FILTER: "TRAINER FILTER",
    CLUB_FILTER_DATE: "CLUB FILTER DATE",
    CART_DATA: "CART DATA",
    APP_SETTING: "APP SETTING",
    FINAL_SLOTS: "FINAL SLOTS",
  },
};
