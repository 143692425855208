import React, { useEffect, useRef, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import passwordmatch from "../images/passwordmatch.svg";
import HidePass from "../images/hidePassword.svg";
import profilePic from "../images/imagePlaceholder.svg";
import leftArrow from "../images/leftArrow.svg";
import ShowPass from "../images/showPassword.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { UserInfo } from "../Redux/Action";
import ForgotPasswordModal from "../Modal/ForgotPasswordModal";
import PhoneCodeModal from "../Modal/PhoneCodeModal";
import { toast } from "react-toastify";

const Signup = () => {
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  let dispatch = useDispatch();
  const [ForgotPasswordModalShow, setForgotPasswordModalShow] = useState(false);
  let navigate = useNavigate();
  const [Loading, setLoading] = useState(false);
  const [PhoneCountryCode, setPhoneCountryCode] = useState();
  const [PhoneCountryID, setPhoneCountryID] = useState();
  const [PhoneCodeModalShow, setPhoneCodeModalShow] = useState(false);
  const [Check_Pass, setCheck_Pass] = useState(false);
  const [Country_list, setCountry_list] = useState([]);
  const [SignUpData, setSignUpData] = useState({});
  const [Profile_Image, setProfile_Image] = useState();
  const [input, setInput] = useState({
    member_name: "",
    nickname: "",
    email: "",
    phone: "",
    // country_code: PhoneCountryCode,
    password: "",
    confirm_password: "",
    profile_pic: "",
    is_phone_verified: "",
    errors: {
      member_name: "",
      nickname: "",
      email: "",
      phone: "",
      // country_code: "",
      confirm_password: "",
      password: "",
      profile_pic: "",
      // is_phone_verified: "",
      ValidationRules: [
        {
          FieldName: "member_name",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "nickname",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "phone",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "password",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        // {
        //   FieldName: "is_phone_verified",
        //   ValidationType: "required",
        //   ValidationMessage: "This Field is a required field",
        // },
      ],
    },
  });
  const [file, setFile] = useState("");
  const [PasswordShowIcon, setPasswordShowIcon] = useState(false);
  const inputFile = useRef();

  function handleImageChange(event) {
    setProfile_Image(event.target.files[0]);
  }

  async function getCountryCode() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_COUNTRY_LIST,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCountry_list(response?.data?.data?.list);
      response?.data?.data?.list?.forEach((element) => {
        if (element?.sortname === "US") {
          setPhoneCountryID(element.id);
          setPhoneCountryCode(element.phonecode);
        }
      });
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });
    if (input.password !== input.confirm_password) {
      setLoading(false);
      toast.error("Passwords do not match");
      return;
    }
    if (!input.is_phone_verified) {
      setLoading(false);
      toast.error("Accept terms and conditions");
      return;
    }

    if (obj.isValid) {
      setLoading(true);

      let body = {
        member_name: input.member_name.trim(),
        social_type_term: "email",
        country_id: PhoneCountryID.toString(),
        email: input.email.trim(),
        phone: input.phone.trim(),
        country_code: PhoneCountryCode.toString(),
        password: input.password.trim(),
        profile_pic: input.profile_pic ? input.profile_pic : "",
        is_phone_verified:
          input.is_phone_verified === ""
            ? 0
            : parseInt(input.is_phone_verified),
        nickname: input.nickname.trim(),
        // otp :
      };
      setSignUpData(body);
      setForgotPasswordModalShow(true);
      // setInput({
      //   ...input,
      //   ["member_name"]: "",
      //   // ["social_type_term"]: "",
      //   ["email"]: "",
      //   ["phone"]: "",
      //   // ["country_code"]: "",
      //   ["password"]: "",
      //   ["confirm_password"]: "",
      //   ["profile_pic"]: "",
      //   ["is_phone_verified"]: false,
      // });
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  const showHidePass = (e) => {
    setPasswordShowIcon(!PasswordShowIcon);

    // let button = document.getElementById("showHidePassBtn");
    // let PassInput = document.querySelector(".password-show-hide");
    // const open = button.querySelector(".open-icon");
    // const close = button.querySelector(".close-icon");
    // const currentValue = PassInput.getAttribute("type");
    // const newValue = currentValue === "password" ? "text" : "password";
    // PassInput.setAttribute("type", newValue);
    // if (newValue == "password") {
    //   open.style.display = "inline-flex";
    //   close.style.display = "none";
    // } else if (newValue == "text") {
    //   close.style.display = "inline-flex";
    //   open.style.display = "none";
    // } else {
    //   open.style.display = "inline-flex";
    //   close.style.display = "none";
    // }
  };

  // 2mb code
  async function fnResizeImage(oForm) {
    const file = oForm;
    const targetSize = 2 * 1024 * 1024; // 2 MB
    const compressedBlobInfo = await compressImage(file, 50, targetSize);
    const { blob, lastModified, name, size, type, webkitRelativePath } =
      compressedBlobInfo;

    const profilePicFile = new File([blob], name, { type, lastModified });

    setInput({
      ...input,
      ["profile_pic"]: profilePicFile,
    });
  }
  async function compressImage(imageFile, initialQuality, targetSize) {
    return new Promise(async (resolve, reject) => {
      let quality = initialQuality;
      let compressedBlob = await compress(imageFile, quality);

      while (compressedBlob.size > targetSize && quality > 0) {
        quality -= 10;
        compressedBlob = await compress(imageFile, quality);
      }

      resolve({
        blob: compressedBlob,
        size: compressedBlob.size,
        type: compressedBlob.type,
        lastModified: imageFile.lastModified,
        lastModifiedDate: imageFile.lastModifiedDate,
        name: imageFile.name,
        webkitRelativePath: imageFile.webkitRelativePath,
      });
    });

    async function compress(imageFile, quality) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          const $canvas = document.createElement("canvas");
          $canvas.width = image.width;
          $canvas.height = image.height;
          const ctx = $canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);

          $canvas.toBlob(
            (blob) => {
              if (blob === null) {
                reject(new Error("Failed to create blob."));
              } else {
                resolve(blob);
              }
            },
            "image/jpeg",
            quality / 100
          );
        };

        // Correct the order: Set the image source after the onload event is defined
        image.src = URL.createObjectURL(imageFile);
      });
    }
  }
  // 2mb code

  

  return (
    <div className="sign-in-main sign-up-main sign-up-main-wrapper">
      <Container>
        <div className="sign-in-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <img
              src={leftArrow}
              alt="previous"
              className="previous-step"
              onClick={() => navigate("/sign-in")}
            />
            <h3 className="text-center m-0  page-head">Sign up</h3>
            <img
              src={leftArrow}
              alt="previous"
              style={{ visibility: "hidden" }}
            />
          </div>
          <div className="sign-in-inner-main">
            <div className="sign-in-inner-all">
              <div className="sign-in-inputs">
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <div className="text-center profileImageSelection">
                    <input
                      type="file"
                      className="form-control d-none"
                      id="profileImg"
                      ref={inputFile}
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        if (e?.target?.files?.[0]) {
                          if (
                            e?.target?.files?.[0]?.type !== "image/png" &&
                            e?.target?.files?.[0]?.type !== "image/jpeg"
                          ) {
                          }
                          // setInput({
                          //   ...input,
                          //   ["profile_pic"]: e?.target?.files?.[0],
                          // });
                          setFile(URL.createObjectURL(e.target.files[0]));
                          fnResizeImage(e?.target?.files[0]);
                        }
                      }}
                    />
                    {input?.profile_pic ? (
                      <img
                        src={file}
                        alt=""
                        type="file"
                        className="image-select"
                        onClick={() => {
                          inputFile.current.click();
                        }}
                      />
                    ) : (
                      <img
                        src={profilePic}
                        alt=""
                        type="file"
                        className="image-select"
                        onClick={() => {
                          inputFile.current.click();
                        }}
                      />
                    )}
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    value={input.member_name}
                    onChange={(e) =>
                      setInput({
                        ...input,
                        ["member_name"]: e.target.value,
                      })
                    }
                    isInvalid={input.errors.member_name}
                  />
                  {input.errors.member_name && (
                    <Form.Control.Feedback type="invalid">
                      {input.errors.member_name}
                    </Form.Control.Feedback>
                  )}
                  <Form.Control
                    type="text"
                    placeholder="Enter Username"
                    value={input.nickname}
                    onChange={(e) =>
                      setInput({
                        ...input,
                        ["nickname"]: e.target.value,
                      })
                    }
                    isInvalid={input.errors.nickname}
                  />
                  {input.errors.nickname && (
                    <Form.Control.Feedback type="invalid">
                      {input.errors.nickname}
                    </Form.Control.Feedback>
                  )}
                  <Form.Control
                    type="email"
                    placeholder="Enter your Email Address"
                    value={input.email}
                    onChange={(e) =>
                      setInput({
                        ...input,
                        ["email"]: e.target.value,
                      })
                    }
                    isInvalid={input.errors.email}
                  />
                  {input.errors.email && (
                    <Form.Control.Feedback type="invalid">
                      {input.errors.email}
                    </Form.Control.Feedback>
                  )}
                  <InputGroup
                    className={
                      input.errors.phone ? "error phone-group" : "phone-group"
                    }
                  >
                    <Button
                      className="countr-code"
                      onClick={() => setPhoneCodeModalShow(true)}
                    >
                      +{PhoneCountryCode}
                    </Button>
                    <Form.Control
                      type="text"
                      placeholder="Mobile Number"
                      value={input.phone}
                      onChange={(e) =>
                        setInput({
                          ...input,
                          ["phone"]: e.target.value,
                        })
                      }
                      isInvalid={input.errors.phone}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {input.errors.phone && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.phone}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                  <InputGroup
                    className={
                      input.errors.password
                        ? "error password-group"
                        : "password-group"
                    }
                  >
                    {/* {PasswordShowIcon ? "true" : "false"} */}
                    <Form.Control
                      // type="password"
                      type={PasswordShowIcon ? "text" : "password"}
                      style={{ borderRight: "none" }}
                      className="password-show-hide"
                      placeholder="Enter Password"
                      value={input.password}
                      onChange={(e) =>
                        setInput({
                          ...input,
                          ["password"]: e.target.value,
                        })
                      }
                      isInvalid={input.errors.password}
                    />
                    {input.errors.password && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.password}
                      </Form.Control.Feedback>
                    )}
                    <Button
                      className="border-0"
                      id="showHidePassBtn"
                      onClick={showHidePass}
                    >
                      <img
                        // src={ShowPass}
                        src={PasswordShowIcon ? HidePass : ShowPass}
                        className="open-icon"
                        alt="OpenPass"
                      />
                      {/* <img
                        src={HidePass}
                        className="close-icon"
                        alt="ClosePass"
                      /> */}
                    </Button>
                  </InputGroup>
                  <InputGroup className="password-group">
                    <Form.Control
                      type={PasswordShowIcon ? "text" : "password"}
                      // style={{ borderRight: "none" }}
                      className="password-show-hide"
                      placeholder="Confirm Password"
                      value={input.confirm_password}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["confirm_password"]: e.target.value,
                        });

                        // {
                        //   e.target.value === input.password &&
                        //     setCheck_Pass(true);
                        // }
                        if (e.target.value === input.password) {
                          setCheck_Pass(true);
                        } else {
                          setCheck_Pass(false);
                        }
                      }}
                      // isInvalid={input.errors.confirm_password}
                    />
                    {/* {input.errors.confirm_password && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.confirm_password}
                      </Form.Control.Feedback>
                    )} */}
                    {Check_Pass && (
                      <Button
                        className="border-0"
                        style={{ marginBottom: "10px", cursor: "auto" }}
                      >
                        <img src={passwordmatch} alt="passwordmatch" />{" "}
                      </Button>
                    )}

                    {/* {Check_Pass ? (
                      <Button
                        className="border-0"
                        style={{ marginBottom: "10px", cursor: "auto" }}
                      >
                        <img src={passwordmatch} alt="passwordmatch" />{" "}
                      </Button>
                    ) : (
                      <>
                        {input.errors.confirm_password && (
                          <Form.Control.Feedback type="invalid">
                            {input.errors.confirm_password}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )} */}
                    {/* <Button
                      className="border-0"
                      style={{ marginBottom: "10px", cursor: "auto" }}
                    >
                      {Check_Pass ? (
                        <>
                          <img src={passwordmatch} alt="passwordmatch" />{" "}
                        </>
                      ) : (
                        <></>
                      )}
                    </Button> */}
                  </InputGroup>
                  <div className="d-flex gap-2 align-items-center diff-check mt-2 mb-3">
                    <Form.Check
                      type="checkbox"
                      id="Terms"
                      name="Terms"
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["is_phone_verified"]: e.target.value,
                        });
                      }}
                      value="1"
                      className="no-margin-input"
                      // label="I accept Terms and Conditions."
                      // isInvalid={input.errors.is_phone_verified}
                    />

                    <Form.Label for="Terms" className="font-white m-0 d-flex">
                      I accept{" "}
                      <a
                        target="_blank"
                        href={AppSettingData?.term_and_conditions_url}
                        className="mx-1"
                      >
                        <b>Terms and Conditions</b>
                      </a>{" "}
                      and{" "}
                      <a
                        target="_blank"
                        href={AppSettingData?.privacy_policy_url}
                        className="mx-1"
                      >
                        <b>Privacy Policy</b>
                      </a>
                    </Form.Label>
                    {/* {input.errors.is_phone_verified && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.is_phone_verified}
                      </Form.Control.Feedback>
                    )} */}
                  </div>

                  <div className="text-center">
                    {/* {Loading ? (
                      <button type="submit" className="red-btn w-100">
                        Loading...
                      </button>
                    ) : ( */}
                    <button type="submit" className="red-btn w-100 mb-2">
                      Create an account
                    </button>
                    {/* )} */}

                    <div className="small-text text-center dont-have-account">
                      Already had an account?
                      <span
                        className="sign-up-link"
                        onClick={() => navigate("/sign-in")}
                      >
                        Sign in
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </Container>
      {ForgotPasswordModalShow && (
        <ForgotPasswordModal
          show={ForgotPasswordModalShow}
          SignUpData={SignUpData}
          onHide={() => setForgotPasswordModalShow(false)}
        />
      )}
      {PhoneCodeModalShow && (
        <PhoneCodeModal
          show={PhoneCodeModalShow}
          CountryList={Country_list}
          PhoneCode={setPhoneCountryCode}
          CountryID={setPhoneCountryID}
          // SignUpData={SignUpData}
          onHide={() => setPhoneCodeModalShow(false)}
        />
      )}
    </div>
  );
};

export default Signup;
