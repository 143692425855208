import React, { useEffect, useState } from "react";
import { Button, Card, FloatingLabel, Form, Modal } from "react-bootstrap";
import { commonservices } from "../services/CommonServices";
import { apiCall } from "../services/AxiosService";
import imagePlaceholder from "../images/placeholder.png";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
// import DatePicker from "react-multi-date-picker";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { UserInfo } from "../Redux/Action";
import court1 from "../images/findclub1.png";
import addbtn from "../images/addwithbg.svg";
import InviteFriendsModal from "./InviteFriendsModal";
import moment from "moment";

const ClubActivityModal = (props) => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);

  let dispatch = useDispatch();
  const [DateOfActivity, setDateOfActivity] = useState(new Date());
  const [Club_activity_option, setClub_activity_option] = useState([]);
  const [Club_Status, setClub_Status] = useState([]);
  const [InviteFriendData, setInviteFriendData] = useState([]);
  const [TimeOfActivity, setTimeOfActivity] = useState(new Date());

  let navigate = useNavigate();
  const [otpTimer, setOtpTimer] = useState(60);
  const [Loading, setLoading] = useState(false);
  const [InviteFriendModal, setInviteFriendModal] = useState(false);

  const [input, setInput] = useState({
    club_id: "",
    activity_type: "",
    interest_date: new Date(),
    interest_time: new Date(),
    club_activity_option: "",
    comment: "",
    club_status: "",
    no_of_people: "",

    errors: {
      comment: "",
      // social_type_term: "",
      // email: "",
      // phone: "",
      // country_code: "",
      // password: "",
      // profile_pic: "",
      // is_phone_verified: "",
      ValidationRules: [
        {
          FieldName: "comment",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        //   {
        //     FieldName: "lastName",
        //     ValidationType: "required",
        //     ValidationMessage: "This Field is a required field",
        //   },
        //   {
        //     FieldName: "email",
        //     ValidationType: "required",
        //     ValidationMessage: "This Field is a required field",
        //   },
        //   {
        //     FieldName: "password",
        //     ValidationType: "required",
        //     ValidationMessage: "This Field is a required field",
        //   },
        //   {
        //     FieldName: "mobile",
        //     ValidationType: "required",
        //     ValidationMessage: "This Field is a required field",
        //   },
      ],
    },
  });

  async function getclub_activity_option() {
    let body = {
      term: "club_activity_option",
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClub_activity_option(response?.data?.data?.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getclub_Status() {
    let body = {
      term: "club_activity_status",
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClub_Status(response?.data?.data?.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getclub_activity_option();
    getclub_Status();
  }, []);

  const handleSubmit = async (e) => {
    debugger;
    setLoading(true);
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });
    const modalContent = document.getElementById("modal-content12");
    if (modalContent) {
        setTimeout(() => {
            modalContent.scrollTop = 0;
        }, 100); 
    }

    if (props?.ActivityType === "Interest" && input.comment !== "") {
      // setLoading(false);
      let body = {
        club_id: props?.ClubId,
        activity_type: "add_interest_activity",
        interest_datetime:
          moment(input.interest_date).format("YYYY-MM-DD") +
          " " +
          moment(input.interest_time).format("HH:mm:ss"),
        comment: input.comment,
        // interested_members: InviteFriendData.map((item) => item.member_id),
      };
      InviteFriendData.forEach((item, index) => {
        body[`interested_members[${index}]`] = item.member_id;
      });
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ACTIVITY_SAVE,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        setLoading(false);
        props.onHide();
        props.bindList(1);
      }
      if (!response?.isValidate) {
        // props.onHide();
        console.log("Error when register new user", response);
      }
    }
    if (props?.ActivityType === "People") {
      let body = {
        club_id: props?.ClubId,
        activity_type: "add_people_activity",
        no_of_people: input?.no_of_people,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ACTIVITY_SAVE,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        props.onHide();
        props.bindList(1);
        props.bindList2();
      }
      if (!response?.isValidate) {
        // props.onHide();
        console.log("Error when register new user", response);
      }
    }
    if (props?.ActivityType === "Status") {
      let body = {
        club_id: props?.ClubId,
        activity_type: "club_status_activity",
        club_activity_option: input?.club_activity_option,
        club_status: input?.club_status,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ACTIVITY_SAVE,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        props.onHide();
        props.bindList(1);
      }
      if (!response?.isValidate) {
        // props.onHide();
        console.log("Error when register new user", response);
      }
    }
    setLoading(false);
  };

  function handleRadioChange(event) {
    const selectedValue = event.target.value;
  }

  const handleDateOfActivity = (date) => {
    setDateOfActivity(new Date(date));
  };

  function fnInviteFriend(item) {
    setInviteFriendModal(true);
  }

  return (
    <>
      <div >
        <Modal
          className="clubactivity-modal-main  only-for-add-club-friend"
          size="md"
          centered
          {...props}
          // id="modal-content"
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>A</div>
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.ActivityType === "Interest" && <>Add Interest</>}
              {props?.ActivityType === "People" && <>Add no. of Peoples</>}
              {props?.ActivityType === "Status" && <>Add Court Status</>}
              {/* {props?.ActivityType === "Status" && <>Add no. of Peoples</>} */}
            </Modal.Title>
            <div style={{ visibility: "hidden" }}>A</div>
          </Modal.Header>
          <Form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            {props?.ActivityType === "Interest" && (
              <>
                <Modal.Body id="modal-content12">
                  <>
                    <div className="add-interest-profile-pic-name">
                      {/* <img src={LoginInfo?.profile_pic} /> */}
                      {LoginInfo?.profile_pic !== null &&
                      LoginInfo?.profile_pic !== "" &&
                      LoginInfo?.profile_pic !== undefined ? (
                        <img
                          src={LoginInfo?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = imagePlaceholder;
                          }}
                          className="invite-friends-single-image"
                        />
                      ) : (
                        <img
                          src={imagePlaceholder}
                          alt="club-detail-image"
                          className="invite-friends-single-image"
                        />
                      )}
                      {LoginInfo?.display_name ? (
                        <p>{LoginInfo?.display_name}</p>
                      ) : (
                        <p>{LoginInfo?.member_name}</p>
                      )}
                    </div>

                    <div className="date-and-time-container">
                      <div>
                        <p>Date</p>
                        <DatePicker
                          selected={input.interest_date}
                          className=""
                          // value={DateOfActivity}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              ["interest_date"]: e,
                            });
                          }}
                          format="DD/MM/YYYY"
                        />
                      </div>
                      <div>
                        <p>Time</p>
                        <DatePicker
                          selected={input.interest_time}
                          // onChange={(date) => setTimeOfActivity(date)}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              ["interest_time"]: e,
                            });
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>
                    <Form.Control
                      as="textarea"
                      placeholder="Leave a comment here"
                      className="black-colored-focused gray-bg-input"
                      style={{ height: "100px" }}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["comment"]: e.target.value,
                        });
                      }}
                      isInvalid={input.errors.comment}
                    />
                    {input.errors.comment && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.comment}
                      </Form.Control.Feedback>
                    )}
                    <div className="interestedcourt-wrapper mt-3 mb-3">
                      <h5>Interested Court</h5>
                      <div className="interestedcourt-inner mt-2">
                        <Card>
                          {/* <Card.Img variant="top" src={court1} alt="court1" /> */}
                          {props?.ClubDetail?.profile_pic !== null &&
                          props?.ClubDetail?.profile_pic !== "" &&
                          props?.ClubDetail?.profile_pic !== undefined ? (
                            <img
                              src={props?.ClubDetail?.profile_pic}
                              alt="club-detail-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                              className="card-img-top"
                            />
                          ) : (
                            <img
                              src={imagePlaceholder}
                              alt="club-detail-image"
                              className="card-img-top"
                            />
                          )}
                          <Card.Body>
                            <Card.Title className="d-block">
                              <h6 style={{ fontWeight: "500" }}>
                                {props?.ClubDetail?.club_name} -{" "}
                                {props?.ClubDetail?.city}
                              </h6>
                              <p>9 km - {props?.ClubDetail?.country}</p>
                            </Card.Title>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                    <div className="invite-friends-btn">
                      <h5>Invite Friends</h5>
                      <Button onClick={() => fnInviteFriend()}>
                        <img src={addbtn} alt="add" />
                      </Button>
                    </div>
                    {InviteFriendData?.length > 0 &&
                      InviteFriendData.map((item, index) => (
                        <div key={index} className="invite-friends-single">
                          <div className="invite-friends-single-img-name">
                            {item?.profile_pic !== null &&
                            item?.profile_pic !== "" &&
                            item?.profile_pic !== undefined ? (
                              <img
                                src={item?.profile_pic}
                                alt="club-detail-image"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = imagePlaceholder;
                                }}
                                className="invite-friends-single-image"
                              />
                            ) : (
                              <img
                                src={imagePlaceholder}
                                alt="club-detail-image"
                                className="invite-friends-single-image"
                              />
                            )}
                            <p>{item?.member_name}</p>
                          </div>
                          <Button
                            className="white-btn"
                            style={{ height: "34px", fontSize: "14px" }}
                            // onClick={() => fnInviteFriendData(item)}
                          >
                            Requested
                          </Button>
                        </div>
                      ))}
                  </>
                </Modal.Body>
                <Modal.Footer>
                  {Loading ? (
                    <button type="submit" className="red-btn red-border w-100">
                      Loading...
                    </button>
                  ) : (
                    <button className="red-btn red-border w-100">
                      Publish
                    </button>
                  )}
                </Modal.Footer>
              </>
            )}
            {props?.ActivityType === "People" && (
              <>
                <Modal.Body className="add-no-of-people-main">
                  <Form.Control
                    type="number"
                    placeholder="0"
                    min={1} // set your desired minimum value
                    max={9999} // set the maximum 4-digit number
                    onChange={(e) => {
                      setInput({
                        ...input,
                        ["no_of_people"]: e.target.value,
                      });
                    }}
                    className="black-colored-focused text-center"
                  />
                </Modal.Body>
                <Modal.Footer>
                  {Loading ? (
                    <button type="submit" className="red-btn w-100">
                      Loading...
                    </button>
                  ) : (
                    <button className="red-btn otp-btn w-100">Save</button>
                  )}
                </Modal.Footer>
              </>
            )}
            {props?.ActivityType === "Status" && (
              <>
                <Modal.Body>
                  {/* <FloatingLabel
                  controlId="floatingInput"
                  // label="Email address"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    onChange={(e) => {
                      setInput({
                        ...input,
                        ["no_of_people"]: e.target.value,
                      });
                    }}
                  />
                </FloatingLabel> */}
                  <p>Select</p>
                  <Form.Select
                    aria-label="select club_activity_option"
                    className="black-colored-focused"
                    onChange={(e) => {
                      setInput({
                        ...input,
                        ["club_activity_option"]: e.target.value,
                      });
                    }}
                  >
                    <option>Select</option>
                    {Club_activity_option?.length > 0 &&
                      Club_activity_option.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                  </Form.Select>
                  {/* <div className="red-radio-btn">
                  <Form.Check type="radio" label="Singles" id="Singles" />
                  <Form.Check type="radio" label="Doubles" id="Doubles" />
                </div> */}
                  <p className="d-block mt-2 ">Status</p>
                  <div className="two-red-radio-btn">
                    {Club_Status?.map((item, index) => (
                      <div className="red-radio-btn">
                        <Form.Check
                          type="radio"
                          id={item.value}
                          name="fav_language"
                          value={item.value}
                          // onChange={handleRadioChange}
                          onChange={(e) => {
                            setInput({
                              ...input,
                              ["club_status"]: e.target.value,
                            });
                          }}
                        />
                          <label for={item.value}>{item.value}</label>
                      </div>
                    ))}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  {Loading ? (
                    <button type="submit" className="red-btn red-border w-100">
                      Loading...
                    </button>
                  ) : (
                    <button className="red-btn red-border w-100">Save</button>
                  )}
                </Modal.Footer>
              </>
            )}
          </Form>
        </Modal>
      </div>
      {InviteFriendModal && (
        <InviteFriendsModal
          show={InviteFriendModal}
          InviteFriendData={setInviteFriendData}
          InviteData={InviteFriendData}
          // setFilterName={setFilterName}
          // FilterType={FilterType}
          // FilterData={FilterName}
          // FilterDateTime={DateTimefromFilter}
          // setDateTimefromFilter={setDateTimefromFilter}
          onHide={() => setInviteFriendModal(false)}
        />
      )}
    </>
  );
};

export default ClubActivityModal;
