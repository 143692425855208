import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CartData } from "../Redux/Action";
import addbtn from "../images/addwithbg.svg";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall, apiCallformData } from "../services/AxiosService";
import AddPlayerModal from "./AddPlayerModal";
import InvitePlayerModal from "./InvitePlayerModal";

function UploadScoreModalMain(props) {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [GroupMembersModalShow, setGroupMembersModalShow] = useState(false);
  let dispatch = useDispatch();
  const [CurrentGroupID, setCurrentGroupID] = useState(
    props?.CurrentGroupID ? props?.CurrentGroupID : ""
  );
  const [ClubRating, setClubRating] = useState([]);
  const [TeamName, setTeamName] = useState("");
  const [TeamM, setTeamM] = useState("");
  const [ScoreLoading, setScoreLoading] = useState(false);
  const [ClubRatingPeople, setClubRatingPeople] = useState([]);
  const [InviteFriendData, setInviteFriendData] = useState([]);
  const [CurrentGroupType, setCurrentGroupType] = useState(
    props?.type === "SocialGroup" ? "Group" : "Public"
  );
  const [ScoreDuration, setScoreDuration] = useState([]);
  const [CourtType, setCourtType] = useState([]);
  const [InviteFriendModal, setInviteFriendModal] = useState(false);
  const [MatchType, setMatchType] = useState([]);
  const [inputPeople, setInputPeople] = useState({
    interest_date: new Date(),
    interest_time: new Date(),
    duration: "60",
    court_type_term: "Single",
    match_type_term: "Competitive",
    teams: [
      {
        player_ids: [],
        score: [0],
        team_name: "TEAM A",
        team_term: "team_a",
        NewFriends: {
          m1: LoginInfo,
          m2: "",
          m3: "",
          m4: "",
        },
      },
      {
        player_ids: [],
        score: [0],
        team_name: "TEAM B",
        team_term: "team_b",
        NewFriends: {
          m1: LoginInfo,
          m2: "",
          m3: "",
          m4: "",
        },
      },
    ],
  });
  async function getCartNotificationCount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_RECENT_UPDATES,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(CartData(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }
  async function getClubRateList() {
    let body = {
      term: "club_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRating(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getClubRatePeopleList() {
    let body = {
      term: "member_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRatingPeople(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getDurationTerm() {
    let body = {
      term: "duration",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setScoreDuration(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getCourtTypeTerm() {
    let body = {
      term: "court_type_term",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCourtType(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getMatchTypeTerm() {
    let body = {
      term: "match_type_term",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setMatchType(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  useEffect(() => {
    if (TeamName === "team_b" && TeamM === "M3") {
      let temp = InviteFriendData?.filter(
        (x) => x?.TeamName === "team_b" && x?.TeamM === "M3"
      );
      let t = { ...inputPeople };
      t.teams.map((item, index) => {
        item.NewFriends.m3 = temp?.length > 0 ? temp?.[0] : "";
      });
      setInputPeople(t);
    }
    if (TeamName === "team_a" && TeamM === "M2") {
      let temp = InviteFriendData?.filter(
        (x) => x?.TeamName === "team_a" && x?.TeamM === "M2"
      );
      let t = { ...inputPeople };
      t.teams.map((item, index) => {
        item.NewFriends.m2 = temp?.length > 0 ? temp?.[0] : "";
      });
      setInputPeople(t);
    }
    if (TeamName === "team_a" && TeamM === "M1") {
      let temp = InviteFriendData?.filter(
        (x) => x?.TeamName === "team_a" && x?.TeamM === "M1"
      );
      let t = { ...inputPeople };
      t.teams.map((item, index) => {
        item.NewFriends.m1 = temp?.length > 0 ? temp?.[0] : "";
      });
      setInputPeople(t);
    }
    if (TeamName === "team_b" && TeamM === "M4") {
      let temp = InviteFriendData?.filter(
        (x) => x?.TeamName === "team_b" && x?.TeamM === "M4"
      );
      let t = { ...inputPeople };
      t.teams.map((item, index) => {
        item.NewFriends.m4 = temp?.length > 0 ? temp?.[0] : "";
      });
      setInputPeople(t);
    }
  }, [InviteFriendData]);
  const handleDeleteFriend = (team_term, friendKey) => {
    setInviteFriendData((prevInviteFriendData) => {
      const updatedInviteFriendData = prevInviteFriendData.filter(
        (friend) =>
          friend.TeamName !== team_term ||
          friend.TeamM.toLowerCase() !== friendKey
      );
      return updatedInviteFriendData;
    });

    setInputPeople((prevInputPeople) => {
      const updatedTeams = prevInputPeople.teams.map((team) => {
        if (team.team_term === "team_a" || team.team_term === "team_b") {
          return {
            ...team,
            NewFriends: {
              ...team.NewFriends,
              [friendKey]: "",
            },
          };
        }
        return team;
      });

      return {
        ...prevInputPeople,
        teams: updatedTeams,
      };
    });
  };
  function fnInviteFriend(item, M) {
    setTeamName(item);
    setTeamM(M);
    setInviteFriendModal(true);
  }
  useEffect(() => {
    if (props?.ScoreDetail !== undefined && props?.ScoreDetail !== null) {
      let m1 = {
        member_id: props?.ScoreDetail?.score[0]?.players[0]?.player_id,
        member_name: props?.ScoreDetail?.score[0]?.players[0]?.player_name,
        profile_pic: props?.ScoreDetail?.score[0]?.players[0]?.player_profile,
        avg_rating: props?.ScoreDetail?.score[0]?.players[0]?.avg_rating,
        member_type: null,
      };
      let m2 = {
        member_id: props?.ScoreDetail?.score[0]?.players[1]?.player_id,
        member_name: props?.ScoreDetail?.score[0]?.players[1]?.player_name,
        profile_pic: props?.ScoreDetail?.score[0]?.players[1]?.player_profile,
        avg_rating: props?.ScoreDetail?.score[0]?.players[1]?.avg_rating,
        member_type: null,
      };

      let m3 = {
        member_id: props?.ScoreDetail?.score[1]?.players[0]?.player_id,
        member_name: props?.ScoreDetail?.score[1]?.players[0]?.player_name,
        profile_pic: props?.ScoreDetail?.score[1]?.players[0]?.player_profile,
        avg_rating: props?.ScoreDetail?.score[1]?.players[0]?.avg_rating,
        member_type: null,
      };
      let m4 = {
        member_id: props?.ScoreDetail?.score[1]?.players[1]?.player_id,
        member_name: props?.ScoreDetail?.score[1]?.players[1]?.player_name,
        profile_pic: props?.ScoreDetail?.score[1]?.players[1]?.player_profile,
        avg_rating: props?.ScoreDetail?.score[1]?.players[1]?.avg_rating,
        member_type: null,
      };

      const { datetime, duration, court_type_term, match_type_term, score } =
        props?.ScoreDetail;
      const timeParts = duration.split(":");
      const hoursInMinutes = parseInt(timeParts[0]) * 60;
      const minutes = parseInt(timeParts[1]);
      const totalMinutes = hoursInMinutes + minutes;
      setInputPeople({
        interest_date: new Date(datetime),
        interest_time: new Date(datetime),
        duration: totalMinutes,
        court_type_term,
        match_type_term,
        teams: score.map((team) => ({
          player_ids: team.players,
          score: team.score,
          team_name: team.team_name,
          team_term: team.team_name === "TEAM A" ? "team_a" : "team_b",
          NewFriends: {
            m1: m1,
            m2: m2,
            m3: m3,
            m4: m4,
          },
        })),
      });
      // setNewFriends({
      //   ...NewFriends,
      //   m1: LoginInfo,
      //   m2: m2,
      //   m3: m3,
      //   m4: m4,
      // });
    }
  }, [props?.ScoreDetail]);
  // useEffect(() => {
  //   setInputPeople((prevInputPeople) => {
  //     const updatedTeams = prevInputPeople.teams.map((team) => {
  //       if (team.team_term === "team_b") {
  //         return {
  //           ...team,
  //           player_ids: InviteFriendData.map((item_i) => item_i.member_id),
  //         };
  //       }
  //       return team;
  //     });

  //     return {
  //       ...prevInputPeople,
  //       teams: updatedTeams,
  //     };
  //   });
  // }, [InviteFriendData]);

  useEffect(() => {
    // getClubRateList();
    getClubRatePeopleList();
    getDurationTerm();
    getCourtTypeTerm();
    getMatchTypeTerm();
  }, []);
  useEffect(() => {
    if (props?.ScoreDetail !== null && props?.ScoreDetail !== undefined) {
      const playerIDForTeamA =
        props?.ScoreDetail?.score[0]?.players[0]?.player_id;
      const playerIDForTeam2 =
        props?.ScoreDetail?.score[0]?.players[1]?.player_id;
      const playerIDForTeam3 =
        props?.ScoreDetail?.score[1]?.players[0]?.player_id;
      const playerIDForTeam4 =
        props?.ScoreDetail?.score[1]?.players[1]?.player_id;
      setInputPeople((prevInputPeople) => {
        const updatedTeams = prevInputPeople.teams.map((team) => {
          if (team.team_term === "team_a") {
            return {
              ...team,
              player_ids: [playerIDForTeamA, playerIDForTeam2],
            };
          } else if (team.team_term === "team_b") {
            return {
              ...team,
              player_ids: [playerIDForTeam3, playerIDForTeam4],
            };
          }
          return team;
        });
        return {
          ...prevInputPeople,
          teams: updatedTeams,
        };
      });
    } else {
      const playerIDForTeamA =
        inputPeople.teams[0].NewFriends?.m1.association_id;
      const playerIDForTeam2 = inputPeople.teams[0].NewFriends?.m2?.member_id;
      const playerIDForTeam3 = inputPeople.teams[0].NewFriends?.m3?.member_id;
      const playerIDForTeam4 = inputPeople.teams[0].NewFriends?.m4?.member_id;
      setInputPeople((prevInputPeople) => {
        const updatedTeams = prevInputPeople.teams.map((team) => {
          if (team.team_term === "team_a") {
            return {
              ...team,
              player_ids: [playerIDForTeamA, playerIDForTeam2],
            };
          } else if (team.team_term === "team_b") {
            return {
              ...team,
              player_ids: [playerIDForTeam3, playerIDForTeam4],
            };
          }
          return team;
        });
        return {
          ...prevInputPeople,
          teams: updatedTeams,
        };
      });
    }
  }, [inputPeople.teams[0].NewFriends, InviteFriendData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setScoreLoading(true);
    if (props?.ScoreDetail !== null && props?.ScoreDetail !== undefined) {
      const teamsValid = inputPeople.teams.every((team) => {
        if (team.team_term === "team_b") {
          if (inputPeople.court_type_term === "Single") {
            return team.NewFriends.m3;
          } else if (inputPeople.court_type_term === "Doubles") {
            return team.NewFriends.m3 && team.NewFriends.m4;
          }
        } else if (
          team.team_term === "team_a"
          // &&
          // inputPeople.court_type_term === "Doubles"
        ) {
          // return team.NewFriends.m2;
          if (inputPeople.court_type_term === "Single") {
            return team.NewFriends.m1;
          } else if (inputPeople.court_type_term === "Doubles") {
            return team.NewFriends.m1 && team.NewFriends.m2;
          }
        }

        return true;
      });
      if (!teamsValid) {
        let errorMessage = "Please fill in all required fields for ";
        if (inputPeople.court_type_term === "Single") {
          errorMessage += inputPeople.teams[1].team_name;
        } else if (inputPeople.court_type_term === "Doubles") {
          errorMessage += `both ${inputPeople.teams[0].team_name} and ${inputPeople.teams[1].team_name}.`;
        }

        toast.error(errorMessage);
        setScoreLoading(false);
        return;
      }
      const teams = inputPeople.teams.map((team, index) => {
        let temp_a = [];
        let temp_b = [];

        if (team.team_term === "team_a") {
          temp_a.push(
            team.NewFriends.m1.member_id === undefined
              ? team.NewFriends.m1.association_id
              : team.NewFriends.m1.member_id,
            // LoginInfo?.association_id,
            team.NewFriends.m2.member_id
          );
        }

        if (team.team_term === "team_b") {
          temp_b.push(
            team.NewFriends.m3.member_id,
            team.NewFriends.m4.member_id
          );
        }

        const player_ids =
          team.team_term === "team_a"
            ? [...temp_a]
            : team.team_term === "team_b"
            ? [...temp_b]
            : [];

        return {
          player_ids,
          score: team.score,
          team_name: team.team_name,
          team_term: team.team_term,
        };
      });
      // const teams = inputPeople.teams.map((team, index) => {
      //   return {
      //     player_ids: team.player_ids,
      //     score: team.score,
      //     team_name: team.team_name,
      //     team_term: team.team_term,
      //   };
      // });
      const minutes = inputPeople.duration;
      const duration = moment.duration(minutes, "minutes");
      const formatted = moment
        .utc(duration.asMilliseconds())
        .format("HH:mm:ss");

      let body = {};
      if (CurrentGroupType === "Public") {
        body = {
          sub_order_id: props?.param,
          datetime:
            moment(inputPeople.interest_date).format("YYYY-MM-DD") +
            " " +
            moment(inputPeople.interest_time).format("HH:mm:ss"),
          match_id: props?.ScoreDetail?.match_id,
          duration: formatted,
          post_type_term: CurrentGroupType?.toLowerCase(),
          court_type_term: inputPeople.court_type_term,
          match_type_term: inputPeople.match_type_term,
          start_time: moment(inputPeople.interest_time).format("HH:mm"),
          teams: teams,
        };
      } else {
        body = {
          sub_order_id: props?.param,
          datetime:
            moment(inputPeople.interest_date).format("YYYY-MM-DD") +
            " " +
            moment(inputPeople.interest_time).format("HH:mm:ss"),
          match_id: props?.ScoreDetail?.match_id,
          duration: formatted,
          post_type_term: CurrentGroupType?.toLowerCase(),
          group_id: CurrentGroupID?.group_id,
          court_type_term: inputPeople.court_type_term,
          match_type_term: inputPeople.match_type_term,
          start_time: moment(inputPeople.interest_time).format("HH:mm"),
          teams: teams,
        };
      }

      // const body = {
      //   sub_order_id: props?.param,
      //   datetime:
      //     moment(inputPeople.interest_date).format("YYYY-MM-DD") +
      //     " " +
      //     moment(inputPeople.interest_time).format("HH:mm:ss"),
      //   match_id: props?.ScoreDetail?.match_id,
      //   duration: formatted,
      //   post_type_term: CurrentGroupType?.toLowerCase(),
      //   group_id: CurrentGroupID?.group_id,
      //   court_type_term: inputPeople.court_type_term,
      //   match_type_term: inputPeople.match_type_term,
      //   start_time: moment(inputPeople.interest_time).format("HH:mm"),
      //   teams: teams,
      // };

      let formData = new FormData();
      for (const [key, value] of Object.entries(body)) {
        if (
          typeof value === "object" &&
          Array.isArray(value) &&
          key !== "componentjson"
        ) {
          value?.map((item, index) => {
            for (const [keyL1, valueL1] of Object.entries(item)) {
              if (
                typeof valueL1 === "object" &&
                Array.isArray(valueL1) &&
                keyL1 !== "componentjson"
              ) {
                valueL1?.map((itemL1, indexL1) => {
                  formData.append(
                    `${key}[${index}][${keyL1}][${indexL1}]`,
                    itemL1
                  );
                });
              } else {
                formData.append(`${key}[${index}][${keyL1}]`, valueL1);
              }
            }
          });
        } else {
          formData.append(`${key}`, value);
        }
      }
      let resData = await apiCallformData(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_UPLOAD_SCORE,
          body: formData,
        },
        true
      );
      let response = apiResponse(false, resData, setScoreLoading);
      if (response?.isValidate) {
        getCartNotificationCount();
        props.onHide();
        props.bindList();
      }
      if (!response?.isValidate) {
      }
    } else {
      const teamsValid = inputPeople.teams.every((team) => {
        if (team.team_term === "team_b") {
          if (inputPeople.court_type_term === "Single") {
            return team.NewFriends.m3;
          } else if (inputPeople.court_type_term === "Doubles") {
            return team.NewFriends.m3 && team.NewFriends.m4;
          }
        } else if (
          team.team_term === "team_a" &&
          inputPeople.court_type_term === "Doubles"
        ) {
          return team.NewFriends.m2;
        }

        return true;
      });
      if (!teamsValid) {
        let errorMessage = "Please fill in all required fields for ";
        if (inputPeople.court_type_term === "Single") {
          errorMessage += inputPeople.teams[1].team_name;
        } else if (inputPeople.court_type_term === "Doubles") {
          errorMessage += `both ${inputPeople.teams[0].team_name} and ${inputPeople.teams[1].team_name}.`;
        }

        toast.error(errorMessage);
        setScoreLoading(false);
        return;
      }
      const teams = inputPeople.teams.map((team, index) => {
        let temp_a = [];
        let temp_b = [];

        if (team.team_term === "team_a") {
          temp_a.push(
            // team.NewFriends.m1.association_id,
            LoginInfo?.association_id,
            team.NewFriends.m2.member_id
          );
        }

        if (team.team_term === "team_b") {
          temp_b.push(
            team.NewFriends.m3.member_id,
            team.NewFriends.m4.member_id
          );
        }

        const player_ids =
          team.team_term === "team_a"
            ? [...temp_a]
            : team.team_term === "team_b"
            ? [...temp_b]
            : [];

        return {
          player_ids,
          score: team.score,
          team_name: team.team_name,
          team_term: team.team_term,
        };
      });

      const minutes = inputPeople.duration;
      const duration = moment.duration(minutes, "minutes");
      const formatted = moment
        .utc(duration.asMilliseconds())
        .format("HH:mm:ss");
      let body = {};
      if (CurrentGroupType === "Public") {
        body = {
          sub_order_id: props?.param,
          datetime:
            moment(inputPeople.interest_date).format("YYYY-MM-DD") +
            " " +
            moment(inputPeople.interest_time).format("HH:mm:ss"),
          duration: formatted,
          post_type_term: CurrentGroupType?.toLowerCase(),
          court_type_term: inputPeople.court_type_term,
          match_type_term: inputPeople.match_type_term,
          start_time: moment(inputPeople.interest_time).format("HH:mm"),
          teams: teams,
        };
      } else {
        body = {
          sub_order_id: props?.param,
          datetime:
            moment(inputPeople.interest_date).format("YYYY-MM-DD") +
            " " +
            moment(inputPeople.interest_time).format("HH:mm:ss"),
          duration: formatted,
          post_type_term: CurrentGroupType?.toLowerCase(),
          group_id: CurrentGroupID?.group_id,
          court_type_term: inputPeople.court_type_term,
          match_type_term: inputPeople.match_type_term,
          start_time: moment(inputPeople.interest_time).format("HH:mm"),
          teams: teams,
        };
      }
      // const body = {
      //   sub_order_id: props?.param,
      //   datetime:
      //     moment(inputPeople.interest_date).format("YYYY-MM-DD") +
      //     " " +
      //     moment(inputPeople.interest_time).format("HH:mm:ss"),
      //   duration: formatted,
      //   post_type_term: CurrentGroupType?.toLowerCase(),
      //   group_id: CurrentGroupID?.group_id,
      //   court_type_term: inputPeople.court_type_term,
      //   match_type_term: inputPeople.match_type_term,
      //   start_time: moment(inputPeople.interest_time).format("HH:mm"),
      //   teams: teams,
      // };

      let formData = new FormData();
      for (const [key, value] of Object.entries(body)) {
        if (
          typeof value === "object" &&
          Array.isArray(value) &&
          key !== "componentjson"
        ) {
          value?.map((item, index) => {
            for (const [keyL1, valueL1] of Object.entries(item)) {
              if (
                typeof valueL1 === "object" &&
                Array.isArray(valueL1) &&
                keyL1 !== "componentjson"
              ) {
                valueL1?.map((itemL1, indexL1) => {
                  formData.append(
                    `${key}[${index}][${keyL1}][${indexL1}]`,
                    itemL1
                  );
                });
              } else {
                formData.append(`${key}[${index}][${keyL1}]`, valueL1);
              }
            }
          });
        } else {
          formData.append(`${key}`, value);
        }
      }
      let resData = await apiCallformData(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_UPLOAD_SCORE,
          body: formData,
        },
        true
      );
      let response = apiResponse(false, resData, setScoreLoading);
      if (response?.isValidate) {
        getCartNotificationCount();
        props.onHide();
        props.bindList();
      }
      if (!response?.isValidate) {
      }
    }
  };

  return (
    <div className="UploadScoreModalMain">
      <Modal
        className="UploadScoreModalMainWrapper"
        size="lg"
        centered
        {...props}
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>A</div>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload Score
            </Modal.Title>
            <div style={{ visibility: "hidden" }}>A</div>
          </Modal.Header>

          <Modal.Body>
            <div className="rate-court-main">
              <div className="d-flex align-items-center gap-2 mb-3">
                <div className="booking-detail-image-main">
                  {LoginInfo?.profile_pic !== null &&
                  LoginInfo?.profile_pic !== "" &&
                  LoginInfo?.profile_pic !== undefined ? (
                    <img
                      src={LoginInfo?.profile_pic}
                      alt="club-detail-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imagePlaceholder;
                      }}
                    />
                  ) : (
                    <img src={imagePlaceholder} alt="club-detail-image" />
                  )}
                </div>
                <div className="booking-detail-image-content m-0">
                  <div className="courtContent w-100">
                    <div className="court-and-court-name">
                      <h5 className="m-0">
                        {LoginInfo?.display_name ? (
                          <p>{LoginInfo?.display_name}</p>
                        ) : (
                          <p>{LoginInfo?.member_name}</p>
                        )}
                      </h5>
                      {props?.GroupType === "GroupType" && (
                        <select
                          defaultValue={
                            props?.type === "SocialGroup"
                              ? "Group +" + props?.CurrentGroupID?.title
                              : "Public"
                          }
                          aria-label="select club_activity_option"
                          className="black-colored-focused my-different-select"
                          onChange={(e) => {
                            setCurrentGroupID(
                              props?.CurrentGroupID ? props?.CurrentGroupID : ""
                            );
                            setCurrentGroupType(e.target.value);
                            if (e.target.value === "Group") {
                              setGroupMembersModalShow(true);
                            }
                          }}
                        >
                          <option value="Group">
                            Group
                            {CurrentGroupID && (
                              <>{"-" + CurrentGroupID?.title}</>
                            )}
                          </option>
                          <option value="Public">Public</option>
                        </select>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="upload-score-inner">
                <h6 className="mb-3">Provide the schedule of your activity</h6>
                <div className="upload-score-datepicker-wrapper">
                  <div>
                    <p>Date</p>
                    <DatePicker
                      selected={inputPeople.interest_date}
                      className=""
                      onChange={(e) => {
                        setInputPeople({
                          ...inputPeople,
                          ["interest_date"]: e,
                        });
                      }}
                      format="DD/MM/YYYY"
                    />
                  </div>
                  <div>
                    <p>Time</p>
                    <DatePicker
                      selected={inputPeople.interest_time}
                      onChange={(e) => {
                        setInputPeople({
                          ...inputPeople,
                          ["interest_time"]: e,
                        });
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="H:mm a"
                    />
                  </div>
                </div>
                <div className="upload-score-duration mt-3">
                  <p>Duration</p>
                  <div className="all-duration-main">
                    {ScoreDuration?.length > 0 &&
                      ScoreDuration?.map((item_s, index_s) => (
                        <Button
                          key={item_s.value}
                          className={`duration-inner ${
                            inputPeople.duration.toString() ===
                            item_s.value.toString()
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            setInputPeople({
                              ...inputPeople,
                              duration: item_s.value,
                            });
                          }}
                        >
                          {item_s.value} mins
                        </Button>
                      ))}
                  </div>
                  <div className="red-radio-btn">
                    {CourtType?.length > 0 &&
                      CourtType.map((item, index) => (
                        <>
                          <Form.Check
                            type="radio"
                            id={item.value}
                            label={item.value}
                            name="court_type_term"
                            value={item.value}
                            checked={inputPeople.court_type_term === item.value}
                            // onChange={(e) => {
                            //   setInputPeople({
                            //     ...inputPeople,
                            //     ["court_type_term"]: e.target.value,
                            //   });
                            // }}
                            onChange={(e) => {
                              const inputPepleUpdate = inputPeople;

                              inputPepleUpdate.court_type_term = e.target.value;

                              let updatedTeam = inputPepleUpdate.teams.map(
                                (team) => {
                                  if (team.team_term === "team_a") {
                                    team.NewFriends = {
                                      m1: LoginInfo,
                                      m2: "",
                                      m3: "",
                                      m4: "",
                                    };
                                  } else {
                                    team.NewFriends = {
                                      m1: "",
                                      m2: "",
                                      m3: "",
                                      m4: "",
                                    };
                                  }

                                  return team;
                                }
                              );

                              setInputPeople({
                                ...inputPeople,
                                ["court_type_term"]: e.target.value,
                                ["teams"]: updatedTeam,
                              });

                              setInviteFriendData([]);
                            }}
                          />
                        </>
                      ))}
                  </div>
                </div>
                <div className="upload-score-enter-score-main">
                  <h6>Enter Score</h6>
                  {inputPeople.teams.map((team, index) => (
                    <div className="enter-score-inner-main" key={index}>
                      <div className="enter-score-inner-wrapper">
                        <div className="enter-score-inner-input">
                          <Form.Control
                            type="text"
                            placeholder={`Enter team ${team.team_term} name`}
                            className="black-colored-focused"
                            value={inputPeople.teams[index].team_name}
                            onChange={(e) => {
                              const updatedTeams = [...inputPeople.teams];
                              updatedTeams[index] = {
                                ...updatedTeams[index],
                                team_name: e.target.value,
                              };
                              setInputPeople({
                                ...inputPeople,
                                teams: updatedTeams,
                              });
                            }}
                          />
                        </div>
                        <div className="enter-score-inner">
                          {team?.team_term === "team_a" && (
                            <div className="enter-score-inner-profile-two-person">
                              <div className="enter-score-inner-profile-single">
                                {team.NewFriends?.m1 && (
                                  <>
                                    <div>
                                      {team.NewFriends?.m1?.profile_pic !==
                                        null &&
                                      team.NewFriends?.m1?.profile_pic !== "" &&
                                      team.NewFriends?.m1?.profile_pic !==
                                        undefined ? (
                                        <img
                                          src={team.NewFriends?.m1?.profile_pic}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </div>

                                    <p className="delete-icon-one">
                                      {team.NewFriends?.m1?.member_name
                                        ? team.NewFriends?.m1?.member_name
                                        : team.NewFriends?.m1?.display_name}
                                      <div
                                        className="delete-icon"
                                        onClick={() =>
                                          handleDeleteFriend(
                                            team.team_term,
                                            "m1"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon icon-tabler icon-tabler-circle-x-filled"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#ffffff"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path
                                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                            stroke-width="0"
                                            fill="#ef333f"
                                          ></path>
                                        </svg>
                                      </div>
                                    </p>
                                  </>
                                )}
                                {team.NewFriends?.m1 !== "" ? (
                                  <></>
                                ) : (
                                  <div
                                    onClick={() =>
                                      fnInviteFriend(team.team_term, "M1")
                                    }
                                  >
                                    <img src={addbtn} alt="add" />
                                  </div>
                                )}
                              </div>
                              {inputPeople.court_type_term === "Doubles" && (
                                <>
                                  <div className="enter-score-inner-profile-single">
                                    {team.NewFriends?.m2 && (
                                      <>
                                        <div>
                                          {team.NewFriends?.m2?.profile_pic !==
                                            null &&
                                          team.NewFriends?.m2?.profile_pic !==
                                            "" &&
                                          team.NewFriends?.m2?.profile_pic !==
                                            undefined ? (
                                            <img
                                              src={
                                                team.NewFriends?.m2?.profile_pic
                                              }
                                              alt="club-detail-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  imagePlaceholder;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={imagePlaceholder}
                                              alt="club-detail-image"
                                            />
                                          )}
                                        </div>
                                        {/* <p>
                                              {team.NewFriends?.m2?.member_name}
                                            </p> */}
                                        <p className="delete-icon-one">
                                          {team.NewFriends?.m2?.member_name}
                                          <div
                                            className="delete-icon"
                                            onClick={() =>
                                              handleDeleteFriend(
                                                team.team_term,
                                                "m2"
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-circle-x-filled"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#ffffff"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              ></path>
                                              <path
                                                d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                                stroke-width="0"
                                                fill="#ef333f"
                                              ></path>
                                            </svg>
                                          </div>
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {team.NewFriends?.m2 !== "" ? (
                                    <></>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        fnInviteFriend(team.team_term, "M2")
                                      }
                                    >
                                      <img src={addbtn} alt="add" />
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                          {team.team_term === "team_b" && (
                            <>
                              <div className="enter-score-inner-profile-two-person enter-score-inner-profile-single">
                                {team.NewFriends?.m3 !== "" ? (
                                  <>
                                    <div>
                                      {team.NewFriends?.m3?.profile_pic !==
                                        null &&
                                      team.NewFriends?.m3?.profile_pic !== "" &&
                                      team.NewFriends?.m3?.profile_pic !==
                                        undefined ? (
                                        <img
                                          src={team.NewFriends?.m3?.profile_pic}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </div>
                                    <p className="delete-icon-one">
                                      {team.NewFriends?.m3?.member_name}
                                      <div
                                        className="delete-icon"
                                        onClick={() =>
                                          handleDeleteFriend(
                                            team.team_term,
                                            "m3"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon icon-tabler icon-tabler-circle-x-filled"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#ffffff"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path
                                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                            stroke-width="0"
                                            fill="#ef333f"
                                          ></path>
                                        </svg>
                                      </div>
                                    </p>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {team.NewFriends?.m3 !== "" ? (
                                  <></>
                                ) : (
                                  <div
                                    onClick={() =>
                                      fnInviteFriend(team.team_term, "M3")
                                    }
                                  >
                                    <img src={addbtn} alt="add" />
                                  </div>
                                )}
                              </div>
                              {inputPeople.court_type_term === "Doubles" && (
                                <>
                                  <div className="enter-score-inner-profile-single">
                                    {team.NewFriends?.m4 && (
                                      <>
                                        <div>
                                          {team.NewFriends?.m4?.profile_pic !==
                                            null &&
                                          team.NewFriends?.m4?.profile_pic !==
                                            "" &&
                                          team.NewFriends?.m4?.profile_pic !==
                                            undefined ? (
                                            <img
                                              src={
                                                team.NewFriends?.m4?.profile_pic
                                              }
                                              alt="club-detail-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  imagePlaceholder;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={imagePlaceholder}
                                              alt="club-detail-image"
                                            />
                                          )}
                                        </div>

                                        <p className="delete-icon-one">
                                          {team.NewFriends?.m4?.member_name}
                                          <div
                                            className="delete-icon"
                                            onClick={() =>
                                              handleDeleteFriend(
                                                team.team_term,
                                                "m4"
                                              )
                                            }
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              class="icon icon-tabler icon-tabler-circle-x-filled"
                                              width="20"
                                              height="20"
                                              viewBox="0 0 24 24"
                                              stroke-width="1.5"
                                              stroke="#ffffff"
                                              fill="none"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              ></path>
                                              <path
                                                d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                                stroke-width="0"
                                                fill="#ef333f"
                                              ></path>
                                            </svg>
                                          </div>
                                        </p>
                                      </>
                                    )}
                                  </div>
                                  {team.NewFriends?.m4 !== "" ? (
                                    <></>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        fnInviteFriend(team.team_term, "M4")
                                      }
                                    >
                                      <img src={addbtn} alt="add" />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          <div className="enter-score-inner-profile-score">
                            {team.score.map((score, scoreIndex) => (
                              <Form.Control
                                type="text"
                                placeholder={`Enter score ${
                                  scoreIndex + 1
                                } for team ${team.team_term}`}
                                value={
                                  inputPeople.teams[index].score[scoreIndex]
                                }
                                className="black-colored-focused"
                                maxLength={2}
                                onChange={(e) => {
                                  const inputValue = parseInt(e.target.value);
                                  const validInput = !isNaN(inputValue)
                                    ? inputValue
                                    : 0;
                                  const updatedTeams = [...inputPeople.teams];
                                  updatedTeams[index].score[scoreIndex] =
                                    validInput;
                                  setInputPeople({
                                    ...inputPeople,
                                    teams: updatedTeams,
                                  });
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <h6>Choose Match Type</h6>
                  <div className="red-radio-btn">
                    {MatchType?.length > 0 &&
                      MatchType.map((item_m, index) => (
                        <>
                          <Form.Check
                            type="radio"
                            label={item_m.value}
                            id={item_m.value}
                            name="match_type_term"
                            checked={
                              inputPeople.match_type_term === item_m.value
                            }
                            value={item_m.value}
                            onChange={(e) => {
                              setInputPeople({
                                ...inputPeople,
                                ["match_type_term"]: e.target.value,
                              });
                            }}
                          />{" "}
                           
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
              <button type="submit" className="red-btn w-100">
                {ScoreLoading ? "Loading..." : "Save"}
              </button>
            </div>
          </Modal.Footer>
        </Form>
        {InviteFriendModal && (
          <AddPlayerModal
            show={InviteFriendModal}
            InviteFriendData={setInviteFriendData}
            InviteData={InviteFriendData}
            TeamName={TeamName}
            TeamM={TeamM}
            onHide={() => setInviteFriendModal(false)}
          />
        )}
        {GroupMembersModalShow && (
          <InvitePlayerModal
            show={GroupMembersModalShow}
            PlayerModalType="HomeScore"
            CurrentGroupIDHome={setCurrentGroupID}
            onHide={() => setGroupMembersModalShow(false)}
          />
        )}
      </Modal>
    </div>
  );
}

export default UploadScoreModalMain;
