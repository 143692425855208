import axios from "axios";
import React, { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import cam from "../images/camera.png";
import imagePlaceholder from "../images/placeholder.png";
import { API_URL } from "../services/APIRoute";
import { commonservices } from "../services/CommonServices";
import InvitePlayerModal from "./InvitePlayerModal";

function CreatePostModal(props) {
  const videoRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [CurrentGroupID, setCurrentGroupID] = useState(
    props?.CurrentGroupID ? props?.CurrentGroupID : ""
  );
  const [GroupMembersModalShow, setGroupMembersModalShow] = useState(false);
  const chunksRef = useRef([]);
  const [capturedImage, setCapturedImage] = useState(null);
  const [Camerashow, setCamerashow] = useState(false);
  const [CurrentGroupType, setCurrentGroupType] = useState(
    props?.type === "SocialGroup" ? "Group" : "Public"
  );
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [input, setInput] = useState({
    comment: "",
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleFileChange = async (e) => {
    setCamerashow(false);
    const files = e.target.files;
    const compressedFiles = [];

    if (selectedFiles.length + files.length > 10) {
      toast.error("You can't select more than 10 files");
      return;
    }

    for (const file of files) {
      try {
        const compressedFile = await compressImage(file);
        compressedFiles.push(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }

    setSelectedFiles([...selectedFiles, ...compressedFiles]);
    // const files = e.target.files;
    // const compressedFiles = [];

    // for (const file of files) {
    //   try {
    //     const compressedFile = await compressImage(file);
    //     compressedFiles.push(compressedFile);
    //   } catch (error) {
    //     console.error("Error compressing image:", error);
    //   }
    // }

    // setSelectedFiles([...selectedFiles, ...compressedFiles]);
  };

  const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const maxWidth = 800; // Adjust the maximum width as needed
          const maxHeight = 800; // Adjust the maximum height as needed

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: Date.now(),
              });
              resolve(compressedFile);
            },
            file.type,
            0.7 // Adjust the quality (0.7 is a good starting point)
          );
        };
      };

      reader.readAsDataURL(file);
    });
  };
  const handleImageDelete = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const renderSelectedImages = () => {
    if (selectedFiles.length === 0) {
      return null;
    }

    return selectedFiles.map((file, index) => (
      <div key={index} className="uploded-images-gallery-single">
        {file instanceof File ? (
          <img src={URL.createObjectURL(file)} alt={`Image ${index}`} />
        ) : (
          <img src={file} alt={`Image ${index}`} />
        )}

        <svg
          onClick={() => handleImageDelete(index)}
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-circle-x-filled"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="#ffffff"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
            stroke-width="0"
            fill="#ef333f"
          />
        </svg>
      </div>
    ));
  };
  const captureFromCamera = async () => {
    setCamerashow(true);
    try {
      if (selectedFiles.length >= 10) {
        toast.error("You can't capture more than 10 images");
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: "environment" },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        });

        mediaRecorderRef.current.addEventListener("stop", () => {
          const blob = new Blob(chunksRef.current, { type: "video/webm" });
          const videoURL = URL.createObjectURL(blob);
        });
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };
  const OpenCamera = () => {
    if (selectedFiles.length >= 10) {
      toast.error("You can't capture more than 10 images");
      return;
    }

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL("image/png");
    const capturedFile = dataURLtoFile(dataURL, "captured_image.png");

    // Update the selectedFiles state with the captured image
    setSelectedFiles([...selectedFiles, capturedFile]);
  };

  // Helper function to convert data URL to File
  const dataURLtoFile = (dataURL, filename) => {
    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedFiles.length === 0) {
      toast.error("Please select at least one image");
      return;
    }
    setIsLoading(true);
    let body = {};
    let formData = new FormData();
    if (CurrentGroupType === "Public") {
      body = {
        comment: input.comment,
        media_files: [],
        post_type_term: CurrentGroupType?.toLowerCase(),
      };
    } else {
      body = {
        comment: input.comment,
        media_files: [],
        post_type_term: CurrentGroupType?.toLowerCase(),
        group_id: CurrentGroupID?.group_id,
      };
    }
    // let body = {
    //   comment: input.comment,
    //   media_files: [],
    //   post_type_term: CurrentGroupType?.toLowerCase(),
    //   group_id: CurrentGroupID?.group_id,
    // };

    selectedFiles.forEach((file, index) => {
      body.media_files[`${index}`] = file;
    });

    for (const [key, value] of Object.entries(body)) {
      if (typeof value === "object" && Array.isArray(value)) {
        value?.map((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(`${key}`, value);
      }
    }

    try {
      let resData = await axios({
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_CREATE,
        data: formData,
        headers: commonservices.getHeadersFromData(),
      });

      if (resData?.data?.status === 1) {
        setIsLoading(false);
        // toast.success(resData?.data?.message);
        props.onHide();
        props.bindList();
      }
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  return (
    <div className="CreatePostModal-Main">
      <Modal
        className="create-post-modal-main"
        size="md    "
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Post</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Body>
            <div className="create-post-modal">
              <div className="single-post-top">
                <div className="d-flex align-items-center profile-and-time">
                  <div className="write-something-profile">
                    {LoginInfo?.profile_pic !== null &&
                    LoginInfo?.profile_pic !== "" &&
                    LoginInfo?.profile_pic !== undefined ? (
                      <img
                        src={LoginInfo?.profile_pic}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagePlaceholder;
                        }}
                      />
                    ) : (
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    )}
                  </div>
                  <div className="username-with-time">
                    {/* <h5>{LoginInfo?.display_name}</h5> */}
                    {LoginInfo?.display_name ? (
                      <h5>{LoginInfo?.display_name}</h5>
                    ) : (
                      <h5>{LoginInfo?.member_name}</h5>
                    )}
                    <select
                      defaultValue={
                        props?.type === "SocialGroup"
                          ? "Group +" + props?.CurrentGroupID?.title
                          : "Public"
                      }
                      aria-label="select club_activity_option"
                      className="black-colored-focused my-different-select"
                      // value={props?.CurrentGroupID?.title}
                      onChange={(e) => {
                        setCurrentGroupID(
                          props?.CurrentGroupID ? props?.CurrentGroupID : ""
                        );
                        setCurrentGroupType(e.target.value);
                        if (e.target.value === "Group") {
                          setGroupMembersModalShow(true);
                        }
                      }}
                    >
                      <option value="Group">
                        Group
                        {CurrentGroupID && <>{"-" + CurrentGroupID?.title}</>}
                      </option>
                      <option value="Public">Public</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="create-post-write-something">
                <Form.Control
                  as="textarea"
                  placeholder="Write Something..."
                  className="black-colored-focused"
                  style={{ height: "120px" }}
                  onChange={(e) => {
                    setInput({
                      ...input,
                      ["comment"]: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="create-post-upload">
                <h5>Upload ({selectedFiles?.length}/10)</h5>
                {Camerashow && (
                  <div>
                    <video ref={videoRef} autoPlay playsInline />
                    <Button onClick={OpenCamera}>Capture Picture</Button>
                  </div>
                )}
                <div className="uploded-images-gallery">
                  {renderSelectedImages()}{" "}
                  {selectedFiles?.length !== 10 && (
                    <>
                      {/* <div
                        className="uploded-images-gallery-single"
                        onClick={captureFromCamera}
                      >
                        <img src={cam} alt="cam" />
                      </div> */}
                      <div className="uploded-images-gallery-single wrapper-all-selected-images">
                        {/* {renderSelectedImages()}{" "} */}
                        <label className="btn-up">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            multiple
                            hidden
                          />
                        </label>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="red-btn red-border w-100">
              {isLoading ? "Loading..." : "Publish"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {GroupMembersModalShow && (
        <InvitePlayerModal
          show={GroupMembersModalShow}
          PlayerModalType="HomeScore"
          CurrentGroupIDHome={setCurrentGroupID}
          onHide={() => setGroupMembersModalShow(false)}
        />
      )}
    </div>
  );
}

export default CreatePostModal;
