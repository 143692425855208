import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { commonservices } from "../services/CommonServices";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useRef } from "react";
import { UserInfo } from "../Redux/Action";
import { toast } from "react-toastify";

const ForgotPasswordModal = (props) => {
  // debugger;
  let dispatch = useDispatch();
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);

  let navigate = useNavigate();
  const [otpTimer, setOtpTimer] = useState(60);
  const [Loading, setLoading] = useState(false);

  const [input, setInput] = useState({
    Number1: "",
    Number2: "",
    Number3: "",
    Number4: "",
  });

  const inputRefs = {
    Number1: useRef(),
    Number2: useRef(),
    Number3: useRef(),
    Number4: useRef(),
  };

  const handleInputChange = (e, inputName) => {
    const value = e.target.value;

    setInput({
      ...input,
      [inputName]: value,
    });

    if (value !== "" && inputName !== "Number4") {
      // Focus on the next input if the current input is not empty
      inputRefs[inputName]?.current?.blur(); // Remove focus from the current input
      const nextInputName = `Number${parseInt(inputName[6]) + 1}`;
      inputRefs[nextInputName]?.current?.focus(); // Focus on the next input
    }
  };

  const handleKeyDown = (e, inputName) => {
    // Prevent backspace key from navigating back
    if (e.key === "Backspace" && input[inputName] === "") {
      e.preventDefault();
      const prevInputName = `Number${parseInt(inputName[6]) - 1}`;
      inputRefs[prevInputName]?.current?.focus(); // Focus on the previous input
    }
  };

  useEffect(() => {
    if (props?.PhoneCodeOTP !== undefined) {
      if (
        props?.PhoneCodeOTP[0]?.otp !== "" &&
        props?.PhoneCodeOTP[0]?.otp !== null &&
        props?.PhoneCodeOTP[0]?.otp !== undefined
      ) {
        let temp = props?.PhoneCodeOTP[0]?.otp.toString();
        for (let i = 0; i < temp.length; i++) {
          setInput({
            ...input,
            Number1: temp[0],
            Number2: temp[1],
            Number3: temp[2],
            Number4: temp[3],
          });
        }
      }
      fnResendWithAuth();
    } else {
      console.log("email data pass");
    }

    if (props.SignUpData !== undefined) {
      fnResend();
    }
  }, []);

  async function fnResend() {
    if (props?.SignUpData !== undefined) {
      let body = {
        email: props.SignUpData.email.trim(),
        phone: props.SignUpData.phone.trim(),
        country_code:
          props.SignUpData.country_code.trim() !== ""
            ? props.SignUpData.country_code.trim()
            : "1",
        nickname: props.SignUpData.nickname.trim(),
        country_id: props.SignUpData.country_id.trim(),
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SENT_OTP_WITHOUT_AUTH,
          body: body,
        },
        true
      );
      let response = apiResponse(true, resData, setLoading);
      if (response?.isValidate) {
        let temp = response?.data.data.otp.toString();
        for (let i = 0; i < temp.length; i++) {
          setInput({
            ...input,
            Number1: temp[0],
            Number2: temp[1],
            Number3: temp[2],
            Number4: temp[3],
          });
        }
      }
      if (!response?.isValidate) {
        props.onHide();
        console.log("Error when register new user", response);
      }
    } else {
      let body = {};

      if (props.OTPType === "email") {
        body = {
          email: props.EmailCode.trim(),
        };
      } else {
        body = {
          // email: props.SignUpData.email.trim(),
          // phone: props.SignUpData.phone.trim(),
          // country_code:
          //   props.SignUpData.country_code.trim() !== ""
          //     ? props.SignUpData.country_code.trim()
          //     : "1",
          // nickname: props.SignUpData.nickname.trim(),
          phone: props?.PhoneCodeOTP[0]?.phone.trim(),
          country_code:
            props?.PhoneCodeOTP[0]?.code.trim() !== ""
              ? props?.PhoneCodeOTP[0]?.code.trim()
              : "1",
          country_id: props.SignUpData.country_id.trim(),
          // country_code: props?.PhoneCodeOTP[0]?.otp,
        };
      }

      // let body = {
      //   // email: props.SignUpData.email.trim(),
      //   // phone: props.SignUpData.phone.trim(),
      //   // country_code:
      //   //   props.SignUpData.country_code.trim() !== ""
      //   //     ? props.SignUpData.country_code.trim()
      //   //     : "1",
      //   // nickname: props.SignUpData.nickname.trim(),
      //   phone: props?.PhoneCodeOTP[0]?.phone.trim(),
      //   country_code:
      //     props?.PhoneCodeOTP[0]?.code.trim() !== ""
      //       ? props?.PhoneCodeOTP[0]?.code.trim()
      //       : "1",
      //   // country_code: props?.PhoneCodeOTP[0]?.otp,
      // };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.FORGOT_PASSWORD,
          body: body,
        },
        true
      );
      let response = apiResponse(true, resData, setLoading);
      if (response?.isValidate) {
        let temp = response?.data.data.otp.toString();
        for (let i = 0; i < temp.length; i++) {
          setInput({
            ...input,
            Number1: temp[0],
            Number2: temp[1],
            Number3: temp[2],
            Number4: temp[3],
          });
        }
      }
      if (!response?.isValidate) {
        // props.onHide();
        console.log("Error when register new user", response);
      }
    }
  }
  async function fnResendWithAuth() {
    let body = {
      email: props.SignUpData.email.trim(),
      phone: props.SignUpData.phone.trim(),
      country_code:
        props.SignUpData.country_code.trim() !== ""
          ? props.SignUpData.country_code.trim()
          : "1",
      nickname: props.SignUpData.nickname.trim(),
      country_id: props.SignUpData.country_id.trim(),
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SENT_OTP_WITH_AUTH,
        body: body,
      },
      true
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      let temp = response?.data.data.otp.toString();
      for (let i = 0; i < temp.length; i++) {
        setInput({
          ...input,
          Number1: temp[0],
          Number2: temp[1],
          Number3: temp[2],
          Number4: temp[3],
        });
      }
    }
    if (!response?.isValidate) {
      props.onHide();
      console.log("Error when register new user", response);
    }
  }

  
  async function VerifyOTP(type) {
    if (type === "forgotPassword") {
      let body = {
        username: props?.PhoneCodeOTP[0]?.phone.trim(),
        // country_code:
        //   props?.PhoneCodeOTP[0]?.code.trim() !== ""
        //     ? props?.PhoneCodeOTP[0]?.code.trim()
        //     : "1",
        otp: props?.PhoneCodeOTP[0]?.otp,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.VERIFY_OTP,
          body: body,
        },
        true
      );

      let response = apiResponse(true, resData, setLoading);

      if (response?.isValidate) {
      }
      if (!response?.isValidate) {
        props.onHide();
        console.log("Error in forgot passowrd::", response);
      }
    } else {
      let body = {
        username: props?.SignUpData?.phone.trim(),
        // country_code:
        //   props?.PhoneCodeOTP[0]?.code.trim() !== ""
        //     ? props?.PhoneCodeOTP[0]?.code.trim()
        //     : "1",
        otp: input.Number1 + input.Number2 + input.Number3 + input.Number4,
      };
      // let body = {
      //   username: props?.PhoneCodeOTP[0]?.phone.trim(),
      //   country_code:
      //     props?.PhoneCodeOTP[0]?.code.trim() !== ""
      //       ? props?.PhoneCodeOTP[0]?.code.trim()
      //       : "1",
      //   otp: props?.PhoneCodeOTP[0]?.otp,
      // };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.VERIFY_OTP,
          body: body,
        },
        true
      );

      let response = apiResponse(true, resData, setLoading);

      if (response?.isValidate) {
        if (response?.data?.data?.is_verifed === 1) {
          let resData = await apiCall(
            {
              method: "POST",
              url: API_URL.BASEURL + API_URL.SIGNUP,
              body: props.SignUpData,
            },
            true
          );
          let response = apiResponse(true, resData, setLoading);
          if (response?.isValidate) {
            // debugger;
            dispatch(UserInfo(response?.data.data));
            localStorage.setItem("Token", response?.data.data.auth_token);
            if (response?.data.data.is_profile_step_completed !== 1) {
              navigate("/signup-steps");
              window.location.reload();
            } else {
              navigate("/sign-in");
              window.location.reload();
            }
          }
          if (!response?.isValidate) {
            props.onHide();
            console.log("Error when register new user", response);
          }
        } else {
          toast.error(response.data.message);
        }
        // return;
        // if (props.SignUpData !== undefined) {

        // }
      }
      if (!response?.isValidate) {
        // props.onHide();
        console.log("Error in forgot passowrd::", response);
      }
    }
    // if (props.SignUpData !== undefined) {
    //   let resData = await apiCall(
    //     {
    //       method: "POST",
    //       url: API_URL.BASEURL + API_URL.SIGNUP,
    //       body: props.SignUpData,
    //     },
    //     true
    //   );
    //   let response = apiResponse(true, resData, setLoading);
    //   if (response?.isValidate) {
    //     // debugger;
    //     dispatch(UserInfo(response?.data.data));
    //     localStorage.setItem("Token", response?.data.data.auth_token);
    //     if (response?.data.data.is_profile_step_completed !== 1) {
    //       navigate("/signup-steps");
    //       window.location.reload();
    //     } else {
    //       navigate("/sign-in");
    //       window.location.reload();
    //     }
    //   }
    //   if (!response?.isValidate) {
    //     props.onHide();
    //     console.log("Error when register new user", response);
    //   }
    // }
    // navigate("/change-password");
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (props.SignUpData !== undefined) {
      VerifyOTP("signUp");
    }
    if (props.EmailCode !== undefined) {
      VerifyOTP("forgotPassword");
    }

    // if (props.SignUpData !== undefined) {
    //   let resData = await apiCall(
    //     {
    //       method: "POST",
    //       url: API_URL.BASEURL + API_URL.SIGNUP,
    //       body: props.SignUpData,
    //     },
    //     true
    //   );
    //   let response = apiResponse(true, resData, setLoading);
    //   if (response?.isValidate) {
    //     // debugger;
    //     dispatch(UserInfo(response?.data.data));
    //     localStorage.setItem("Token", response?.data.data.auth_token);
    //     if (response?.data.data.is_profile_step_completed !== 1) {
    //       navigate("/signup-steps");
    //       window.location.reload();
    //     } else {
    //       navigate("/sign-in");
    //       window.location.reload();
    //     }
    //   }
    //   if (!response?.isValidate) {
    //     props.onHide();
    //     console.log("Error when register new user", response);
    //   }
    // }

    if (props?.PhoneCodeOTP !== undefined) {
      let body = {
        username: props?.PhoneCodeOTP[0]?.phone.trim(),
        country_code:
          props?.PhoneCodeOTP[0]?.code.trim() !== ""
            ? props?.PhoneCodeOTP[0]?.code.trim()
            : "1",
        otp: props?.PhoneCodeOTP[0]?.otp,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.VERIFY_OTP,
          body: body,
        },
        true
      );

      let response = apiResponse(true, resData, setLoading);

      if (response?.isValidate) {
        navigate("/change-password");
      }

      if (!response?.isValidate) {
        props.onHide();
        console.log("Error in forgot passowrd::", response);
      }
    }
  };

  useEffect(() => {
    if (otpTimer > 0) {
      const timer = setInterval(() => {
        setOtpTimer(otpTimer - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpTimer]);

  return (
    <>
      <Modal
        className="AddForgotPasswordModalMain"
        size="md"
        centered
        {...props}
      >
        <Modal.Header>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Enter OTP
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Body>
            <div className="forgotPassOtpMain">
              <p className="modalParagraph">
                {props.SignUpData !== undefined ? (
                  <>
                    Please enter four digit code sent to your mobile number (
                    {props.SignUpData.country_code}) {props.SignUpData.phone}
                  </>
                ) : (
                  <>
                    {props?.PhoneCodeOTP !== undefined ? (
                      <>
                        Please enter four digit code sent to your mobile number
                        ({props?.PhoneCodeOTP[0]?.code}){" "}
                        {props?.PhoneCodeOTP[0]?.phone}
                      </>
                    ) : (
                      <>
                        Please enter four digit code sent to your email address
                        ({props?.EmailCode})
                      </>
                    )}
                  </>
                )}
              </p>
              <div className="otpDigitsMain">
                <input
                  ref={inputRefs.Number1}
                  className="otpDigits"
                  value={input.Number1}
                  // onChange={(e) =>
                  //   setInput({
                  //     ...input,
                  //     ["Number1"]: e.target.value,
                  //   })
                  // }
                  onChange={(e) => handleInputChange(e, "Number1")}
                  onKeyDown={(e) => handleKeyDown(e, "Number1")}
                ></input>
                <input
                  ref={inputRefs.Number2}
                  className="otpDigits"
                  value={input.Number2}
                  // onChange={(e) =>
                  //   setInput({
                  //     ...input,
                  //     ["Number2"]: e.target.value,
                  //   })
                  // }
                  onChange={(e) => handleInputChange(e, "Number2")}
                  onKeyDown={(e) => handleKeyDown(e, "Number2")}
                ></input>
                <input
                  ref={inputRefs.Number3}
                  className="otpDigits"
                  value={input.Number3}
                  // onChange={(e) =>
                  //   setInput({
                  //     ...input,
                  //     ["Number3"]: e.target.value,
                  //   })
                  // }
                  onChange={(e) => handleInputChange(e, "Number3")}
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  onKeyDown={(e) => handleKeyDown(e, "Number3")}
                ></input>
                <input
                  ref={inputRefs.Number4}
                  className="otpDigits"
                  value={input.Number4}
                  onChange={(e) => handleInputChange(e, "Number4")}
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  onKeyDown={(e) => handleKeyDown(e, "Number4")}
                ></input>
              </div>
              <div className="resendOtp">
                <p>
                  Didn’t receive code?{" "}
                  <span
                    className={otpTimer ? "disable" : ""}
                    // onClick={() => fnResend()}
                    onClick={() => {
                      fnResend();
                      setOtpTimer(60); // Reset the otpTimer to 60 seconds
                    }}
                  >
                    Resend
                  </span>
                </p>
                {/* <p>{otpTimer === 60 ? <>00:00</> : <>00:{otpTimer}</>}</p> */}
                <p>
                  {otpTimer === 60
                    ? "00:00"
                    : `00:${otpTimer < 10 ? `0${otpTimer}` : otpTimer}`}
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="red-btn otp-btn w-100">Next</button>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
