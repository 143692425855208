// import { useGoogleLogin } from "@react-oauth/google";
// import axios from "axios";
// import React from "react";
// import googleIcon from "../images/google.svg";
// import { Link } from "react-router-dom";

// function GoogleLogin(props) {

//   const googleLogin = useGoogleLogin({
//     onSuccess: async (tokenResponse) => {

//       const userInfo = await axios.get(
//         "https://www.googleapis.com/oauth2/v3/userinfo",
//         { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
//       );
//     },
//     onError: (errorResponse) =>
//       console.log("error in google login", errorResponse),
//     isSignedIn: true,
//     accessType: "offline",
//     scope: "https://mail.google.com/",
//     prompt: "consent",
//   });
//   return (
//     <>
//       <div onClick={googleLogin} style={{ cursor: "pointer" }}>
//         <img src={googleIcon} alt="google" title="google" />
//       </div>
//     </>
//   );
// }

// export default GoogleLogin;
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import React from "react";
import googleIcon from "../images/google.svg";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../Redux/Action";
import { editableInputTypes } from "@testing-library/user-event/dist/utils";

function GoogleLogin({ check }) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo1 = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
      );

// debugger
      //code
      let body = {
        authentication_type: "google",
        social_token: tokenResponse.access_token,
        social_id: userInfo1.data.sub,
        email: userInfo1.data.email,
        member_name: userInfo1.data.name,
        nickname: userInfo1.data.name,
        // profile_pic: userInfo1.data.picture,
      };
      

      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.LOGIN,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        if (response?.data?.data?.is_user_exists === 0) {
          let body1 = {
            social_type_term: "google",
            social_token: tokenResponse.access_token,
            social_id: userInfo1.data.sub,
            email: userInfo1.data.email,
            member_name: userInfo1.data.name,
            nickname: userInfo1.data.name,
            // profile_pic: userInfo1.data.picture,
          };
          let resData1 = await apiCall(
            {
              method: "POST",
              url: API_URL.BASEURL + API_URL.SIGNUP,
              body: body1,
            },
            true
          );
          let response1 = apiResponse(true, resData1);
          if (response1?.isValidate) {
            dispatch(UserInfo(response1?.data.data));
            localStorage.setItem("Token", response1?.data.data.auth_token);
            if (response1?.data.data.is_profile_step_completed !== 1) {
              navigate("/signup-steps");
              window.location.reload();
            } else {
              window.location.reload();
            }
          }
        } else {
          if (response?.data.data.is_profile_step_completed !== 1) {
            dispatch(UserInfo(response?.data.data));
            localStorage.setItem("Token", response?.data.data.auth_token);
            navigate("/signup-steps");
            window.location.reload();
          } else {
            dispatch(UserInfo(response?.data.data));
            localStorage.setItem("Token", response?.data.data.auth_token);
            localStorage.setItem("TokenStep", "Pickler");
            window.location.reload();
          }
        }
      }
    },
    onError: (errorResponse) =>
      console.log("error in google login", errorResponse),
    isSignedIn: true,
    accessType: "offline",
    scope: "https://mail.google.com/",
    prompt: "consent",
  });

  const handleGoogleLogin = () => {
    if (check === "1") {
      googleLogin();
    } else {
      toast.error("Click on Terms & Conditions");
    }
  };

  return (
    <>
      <div onClick={handleGoogleLogin}>
        <img src={googleIcon} alt="google" title="google" />
      </div>
    </>
  );
}

export default GoogleLogin;
