import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { FinalSlotsAction } from "../Redux/Action";

function AddAvailabilitySlotModal(props) {
  const FinalSlotsRedux = useSelector((state) => state.userinfo.FinalSlots);
  const [SlotError, setSlotError] = useState(false);
  const [DuplicateSlotError, setDuplicateSlotError] = useState(false);
  let dispatch = useDispatch();
  // const currentDateStart = new Date();
  // currentDateStart.setHours(9);
  // currentDateStart.setMinutes(0);
  // const currentDateEnd = new Date();
  // currentDateEnd.setHours(17);
  // currentDateEnd.setMinutes(0);
  var newDateObj = new Date(
    new Date().getTime() + props.DefaultDuration * 60000
  );
  let iniData = {
    start_time: new Date(),
    end_time: newDateObj,
    duration: newDateObj,
    price: 0,
  };
  const [InputSlottemp, setInputSlottemp] = useState(iniData);

  function fnSaveNewAvailability() {
    if (!props.BookSlotData.is_selected) {
      
      let temp = [];
      if (FinalSlotsRedux.length > 0) {
        FinalSlotsRedux.forEach((element) => {
          if (!element.is_selected) {
            element.is_selected = true;
            element.slots.push(InputSlottemp);
            props.onHide();
          }
          temp.push(element);
        });
      }
      dispatch(FinalSlotsAction(temp));
    } else {
      let temp1 = [];
      FinalSlotsRedux.forEach((element11) => {
        if (props.BookSlotData.day === element11.day) {
          let validation = true;
          props.BookSlotData.slots.forEach((element) => {
            const check1 = InputSlottemp.start_time >= element.start_time;
            const check2 = InputSlottemp.start_time < element.end_time;
            const check3 = InputSlottemp.end_time > element.start_time;
            const check4 = InputSlottemp.end_time <= element.end_time;

            if ((check1 && check2) || (check3 && check4)) {
              validation = false;
              setDuplicateSlotError(true);
            }
          });
          if (validation) {
            props.BookSlotData.slots.push(InputSlottemp);
            props.onHide();
          } else {
            setDuplicateSlotError(true);
          }
        }
        temp1.push(element11);
      });
      dispatch(FinalSlotsAction(temp1));
    }
  }
  return (
    <div className="AddAvailabilitySlotModal-main">
      <Modal className="AddAvailabilitySlotModal" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Book Slot for {props.BookSlotData.day}
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className="settings-edit-availability-date-picker">
              <div className="find-club-filter-datepicker-main justify-content-start gap-3">
                <div className="find-club-filter-datepicker-single">
                  <p>Start Time</p>
                  <DatePicker
                    selected={InputSlottemp.start_time}
                    onChange={(date) => {
                      if (date <= InputSlottemp.end_time) {
                        setInputSlottemp({
                          ...InputSlottemp,
                          start_time: date,
                        });
                        setSlotError(false);
                        setDuplicateSlotError(false);
                      } else {
                        setSlotError(true);
                        setDuplicateSlotError(false);
                      }
                    }}
                    showTimeSelect
                    minTime={InputSlottemp.end_time}
                    maxTime={new Date()}
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                  />
                </div>
                <div className="find-club-filter-datepicker-single">
                  <p>End Time</p>
                  <DatePicker
                    selected={InputSlottemp.end_time}
                    onChange={(date) => {
                      setInputSlottemp({
                        ...InputSlottemp,
                        end_time: date,
                      });
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="HH:mm"
                  />
                </div>
              </div>
            </div>
            <p className="font-red">
              {SlotError && "Start time not bigger than end time"}
            </p>
            <p className="font-red">
              {DuplicateSlotError && "Slot already exist with selected time"}
            </p>
            <div className="settings-availability-price">
              <h6>Price</h6>
              <Form.Control
                type="text"
                placeholder="$"
                aria-label="Price"
                aria-describedby="basic-addon1"
                className="black-colored-focused"
                onChange={(e) => {
                  setInputSlottemp({
                    ...InputSlottemp,
                    price: e.target.value,
                  });
                }}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <div
              className="d-flex gap-2 mt-4"
              style={{ position: "sticky", bottom: "0", background: "#ffffff" }}
            >
              <button
                className="red-btn red-border w-100"
                // type="submit"
                onClick={() => fnSaveNewAvailability()}
              >
                Select
              </button>
              <button className="red-btn red-border w-100">Cancel</button>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddAvailabilitySlotModal;
