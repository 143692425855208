import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddAvailabilitySlotModal from "../Modal/AddAvailabilitySlotModal";
import AddBalanceModal from "../Modal/AddBalanceModal";
import AlreadyExistsModal from "../Modal/AlreadyExistsModal";
import AppointmentModal from "../Modal/AppointmentModal";
import AvailabilityScheduleTimeModal from "../Modal/AvailabilityScheduleTimeModal";
import BankDetailModal from "../Modal/BankDetailModal";
import BankStripeModal from "../Modal/BankStripeModal";
import GooglePlaceModal from "../Modal/GooglePlaceModal";
import MembershipBuyModal from "../Modal/MembershipBuyModal";
import PhoneCodeModal from "../Modal/PhoneCodeModal";
import ShareProfileModal from "../Modal/ShareProfileModal";
import SubsciptionModal from "../Modal/SubscriptionModal";
import TrasactionModal from "../Modal/TrasactionModal";
import { EditUserInfo, FinalSlotsAction } from "../Redux/Action";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";
import FullCalender from "../components/Full_Calender";
import Loader, { LoaderType } from "../components/Loader";
import address from "../images/address.png";
import availability from "../images/availability.png";
import bankDetail from "../images/bankDetail.png";
import becomeTrainer from "../images/becomeTrainer.svg";
import call from "../images/black-call.svg";
import {
  default as calendar,
  default as calendarsmall,
} from "../images/calendarsmall.svg";
import clock from "../images/clock.svg";
import contact from "../images/contact.svg";
import editPen from "../images/editPen.svg";
import editProfile from "../images/editProfile.svg";
import shareprofileIcon from "../images/greyShare.svg";
import block from "../images/greyblock.png";
import greyleftarrow from "../images/greyleftarrow.svg";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import locationIcon from "../images/locationIcon.svg";
import logout from "../images/logoutIcon.svg";
import matchesScore from "../images/matchesScore.svg";
import membership from "../images/membership.svg";
import myBookings from "../images/myBookings.svg";
import myCart from "../images/myCart.svg";
import myschedule from "../images/myschedule.svg";
import logo from "../images/onlylogo.svg";
import paidto from "../images/paidto.svg";
import personalInfo from "../images/personalInfo.png";
import imagePlaceholder from "../images/placeholder.png";
import leftarrow from "../images/previous.svg";
import privacy from "../images/privacy.svg";
import recievedfrom from "../images/recievedfrom.svg";
import deleleicon from "../images/redDelete.svg";
import standardMemberlogo from "../images/standard_member.svg";
import walletIcon from "../images/walletIcon.svg";
import yellowLogo from "../images/yellowLogo.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import ClubMemberShipList from "../components/CommonComponents/ClubMemberShipList";
import SubscriptionDetailComponent from "../components/CommonComponents/SubscriptionDetailComponent";
import BlokedUserComponent from "../components/CommonComponents/BlokedUserComponent";

function Settings() {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const FinalSlotsRedux = useSelector((state) => state.userinfo.FinalSlots);
  let CurrentDateMain = new Date();
  const [CalenderDate, setCalenderDate] = useState(new Date());
  const [CalOption, setCalOption] = useState({
    date: new Date(),
    viewtype: "Month",
    data: [],
  });
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  const [PolicyHour, setPolicyHour] = useState(24);
  let navigate = useNavigate();
  const [ShareProfileData, setShareProfileData] = useState();
  const [SettingShow, setSettingShow] = useState("EditProfile");
  const [PhoneCountryCode, setPhoneCountryCode] = useState();
  const [FinalSlots, setFinalSlots] = useState([]);
  const [BookingDetail, setBookingDetail] = useState();
  const [PhoneCountryID, setPhoneCountryID] = useState(231);
  const [SlotProce, setSlotProce] = useState(0);
  const [PhoneCodeModalShow, setPhoneCodeModalShow] = useState(false);
  const [BankDetailModalshow, setBankDetailModalshow] = useState(false);
  const [IsAlreadyExistsModalShow, setIsAlreadyExistsModalShow] =
    useState(false);
  const [IsAlreadyExistsModaldata, setIsAlreadyExistsModaldata] = useState();
  const [AppointmentsValue, setAppointmentsValue] = useState("booked");
  const [FinalCreateSlotLoading, setFinalCreateSlotLoading] = useState(false);
  const [GooglePlaceModalShow, setGooglePlaceModalShow] = useState(false);
  const [BankStripeModalShow, setBankStripeModalShow] = useState(false);
  const [ScheduleModalShow, setScheduleModalShow] = useState(false);
  const [DefaultDuration, setDefaultDuration] = useState(null);
  const [BankStripeModalLink, setBankStripeModalLink] = useState("");
  const [GooglePlaceData, setGooglePlaceData] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageNumberSchedule, setpageNumberSchedule] = useState(1);
  const [pageNumberWallet, setPageNumberWallet] = useState(1);
  const [Country_list, setCountry_list] = useState([]);
  const [AvailabilitySlotsList, setAvailabilitySlotsList] = useState();
  const [ScheduleDatesList, setScheduleDatesList] = useState([]);
  const [ScheduleDatesByDatesList, setScheduleDatesByDatesList] = useState([]);
  const [ScheduleDatesByDatesLoader, setScheduleDatesByDatesLoader] =
    useState(false);
  const [AvailableScheduleInfo, setAvailableScheduleInfo] = useState();
  const [AvailabilitySlotLoader, setAvailabilitySlotLoader] = useState(false);
  const [BookSlotModalShow, setBookSlotModalShow] = useState(false);
  const [AvailabilityScheduleLoader, setAvailabilityScheduleLoader] =
    useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [RemoveMoreButtionSchedule, setRemoveMoreButtionSchedule] =
    useState(false);
  const [RemoveMoreButtionWallet, setRemoveMoreButtionWallet] = useState(false);
  let dispatch = useDispatch();
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  const [ProfileData, setProfileData] = useState();
  const [GameTypeTerm, setGameTypeTerm] = useState([]);
  const [DurationTerm, setDurationTerm] = useState([]);
  const [UserFullData, setUserFullData] = useState();
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [AppointmentList, setAppointmentList] = useState([]);
  const [AppointmentLoader, setAppointmentLoader] = useState(false);
  const [RemoveMoreButtionAppointment, setRemoveMoreButtionAppointment] =
    useState(false);
  const [pageNumberAppointment, setPageNumberAppointment] = useState(1);
  const [SubscriptionLoader, setSubscriptionLoader] = useState(false);
  const [InputCoachLoader, setInputCoachLoader] = useState(false);
  const [MatchesScoreList, setMatchesScoreList] = useState([]);
  const [MatchesScoreLoader, setMatchesScoreLoader] = useState(false);
  const [LevelTerm, setLevelTerm] = useState([]);
  const [SportsLevelTerm, setSportsLevelTerm] = useState([]);
  const [GenderTerm, setGenderTerm] = useState([]);
  const [Subscriptiondata, setSubscriptiondata] = useState("");
  const [MembershipDetail, setMembershipDetail] = useState("");
  const [NewSubscribeLoader, setNewSubscribeLoader] = useState(false);
  const [WalletList, setWalletList] = useState([]);
  const [WalletLoader, setWalletLoader] = useState(false);
  const [AppointmentModalShow, setAppointmentModalShow] = useState(false);
  const [SubscribeModalValue, setSubscribeModalValue] = useState();
  const [MembershipData, setMembershipData] = useState([]);
  const [WalletCurrentBalance, setWalletCurrentBalance] = useState(0);

  const [SubscribeType, setSubscribeType] = useState("");
  const [file, setFile] = useState("");
  const [UserProfileFile, setUserProfileFile] = useState("");
  const [ClubProfileFile, setClubProfileFile] = useState("");
  const inputFile = useRef();
  const inputFile1 = useRef();
  const inputFile2 = useRef();
  const [showAddBalanceModal, setAddBalanceModal] = useState(false);
  const [UpdateProfileLoading, setUpdateProfileLoading] = useState(false);
  const [UpdateProfileButtonLoader, setUpdateProfileButtonLoader] =
    useState(false);
  const [UpdateClubButtonLoader, setUpdateClubButtonLoader] = useState(false);
  const [MembershipLoading, setMembershipLoading] = useState(false);

  const [Membershipmodalshow, setMembershipmodalshow] = useState(false);
  const [WalletModalShow, setWalletModalShow] = useState(false);
  const [BookSlotData, setBookSlotData] = useState();
  const [TransactionModalShow, setTransactionModalShow] = useState(false);
  const [TransactionValue, setTransactionValue] = useState();
  const [ShareProfileModalShow, setShareProfileModalShow] = useState(false);
  const [InputSlotStartTime, setInputSlotStartTime] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(9);
    currentDate.setMinutes(0);
    return currentDate;
  });
  const [InputSlotEndTime, setInputSlotEndTime] = useState(() => {
    const currentDate = new Date();
    currentDate.setHours(17);
    currentDate.setMinutes(0);
    return currentDate;
  });
  let dayList = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  function fnDurationInMinute(item) {
    const minutes = item;
    const duration = moment.duration(minutes, "minutes");
    const formatted = moment.utc(duration.asMilliseconds()).format("HH:mm:ss");
    return formatted;
  }
  function fnOnlyTrainerLink(item) {
    if (item?.items?.length > 0) {
      let temp = item?.items.filter((f) => f.item_type === "club_booking");
      if (temp.length > 0) {
        navigate("/booking-detail/" + item.sub_order_id);
      } else {
        navigate("/coach-detail/" + item.sub_order_id);
      }
    }
  }
  // console.log("dayList ::", dayList);
  const [input, setInput] = useState({
    member_name: "",
    email: "",
    phone: "",
    country_code: "",
    password: "",
    pickleball_level_term: "",
    level_of_sportsmenship_term: "",
    profile_pic: "",
    nickname: "",
    social_type_term: "",
    gender_type_term: "",
    dob: "",
    errors: {
      email: "",
      phone: "",
      country_code: "",
      social_type_term: "",
      password: "",
      ValidationRules: [
        {
          FieldName: "member_name",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "social_type_term",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });
  const [InputUserProfile, setInputUserProfile] = useState({
    member_name: "",
    email: "",
    phone: "",
    country_code: "",
    password: "",
    pickleball_level_term: "",
    level_of_sportsmenship_term: "",
    profile_pic: "",
    nickname: "",
    social_type_term: "",
    gender_type_term: "",
    dob: "",
    rate: "",
    distance: "",
    errors: {
      email: "",
      phone: "",
      country_code: "",
      social_type_term: "",
      password: "",
      rate: "",
      distance: "",
      ValidationRules: [
        {
          FieldName: "member_name",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "email",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "social_type_term",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "rate",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "distance",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });
  const [InputClubProfile, setInputClubProfile] = useState({
    club_name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    country_code: "",
    phone_no: "",
    detail: "",
    court_title: "",
    court_no: "",
    game_type_term: "",
    profile_pic: "",
    errors: {
      club_name: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      country_code: "",
      phone_no: "",
      detail: "",
      court_title: "",
      court_no: "",
      game_type_term: "",
      ValidationRules: [
        {
          FieldName: "club_name",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "address1",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "city",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "state",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "country",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "zipcode",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "phone_no",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "court_title",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "court_no",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });
  const [InputCoachAddress, setInputCoachAddress] = useState({
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    errors: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      ValidationRules: [
        {
          FieldName: "address1",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
        {
          FieldName: "zipcode",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });

  const currentDateStart = new Date();
  currentDateStart.setHours(9);
  currentDateStart.setMinutes(0);
  // return currentDate;
  const currentDateEnd = new Date();
  currentDateEnd.setHours(17);
  currentDateEnd.setMinutes(0);
  // return currentDate;

  let iniData = {
    startTime: currentDateStart,
    endTime: currentDateEnd,
    duration: DefaultDuration,
    price: 0,
    slots: [],
  };

  const [InputSlottemp, setInputSlottemp] = useState(iniData);

  const [InputSlot, setInputSlot] = useState({
    days: dayList.map((day) => ({
      day: day.toLowerCase(),
      is_selected: true,
      slots: [],
    })),
  });

  useEffect(() => {
    setInputSlot((prevInputSlot) => ({
      ...prevInputSlot,
      days: prevInputSlot.days.map((day) => ({
        ...day,
      })),
    }));
  }, []);

  const [showAddAvailabilitySlotModal, setAddAvailabilitySlotModal] =
    useState(false);
  function handleAddAvailabilitySlotModal(item) {
    setBookSlotModalShow(true);
    setBookSlotData(item);
  }

  const closeAddAvailabilitySlotModal = () => {
    setAddAvailabilitySlotModal(false);
  };

  const handleCheckboxChange = (event) => {
    // setChecked(event.target.checked);
  };

  const handleAddBalanceModal = () => {
    setWalletModalShow(true);
  };

  const closeAddBalanceModal = () => {
    setAddBalanceModal(false);
  };
  const [showTransactionModal, setTransactionModal] = useState(false);
  function handleTransactionModal(item) {
    setTransactionValue(item);
    setTransactionModalShow(true);
  }

  const closeTransactionModal = () => {
    setTransactionModal(false);
  };

  async function getCountryCode() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_COUNTRY_LIST,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCountry_list(response?.data?.data?.list);
      // response?.data?.data?.list?.forEach((element) => {
      //   if (element?.sortname === "US") {
      //     setPhoneCountryID(element.id);
      //     setPhoneCountryCode(element.phonecode);
      //   }
      // });
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSubscriptionList() {
    setSubscriptionLoader(true);
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_LIST,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setSubscriptionList(response.data.data.list);
        if (LoginInfo?.is_subscriber === 1) {
          let temp = "";
          response?.data?.data?.list?.forEach((element) => {
            temp = element?.prices?.filter(
              (f) => f.subscription_id === LoginInfo?.subscription_id
            );
          });

          // console.log('temptemp',temp);
          // let temp = response.data.data.list.find(
          //   (f) => f.subscription_id === LoginInfo?.subscription_id
          // );
          setMembershipData(temp);
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setSubscriptionLoader(false);
    }
  }
  async function getSubscriptionDetails() {
    setMembershipLoading(true);
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_DETAILS,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setMembershipLoading(false);
        setMembershipDetail(response?.data?.data);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }

  async function getAuthProfile() {
    setUpdateProfileLoading(true);

    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_USER_PROFILE,
        },
        false
      );
      let response = apiResponse(false, resData, setUpdateProfileLoading);
      if (response?.isValidate) {
        setUserFullData(response?.data?.data);
        // console.log("GET_USER_PROFILE", response?.data?.data?.user);
        setProfileData(response?.data?.data?.user);
        let temp = [];
        temp.push({
          ...response?.data?.data?.user,
          association_id: response?.data?.data?.user?.member_id,
        });
        dispatch(EditUserInfo(temp[0]));
        // dispatch(EditUserInfo(response?.data?.data?.user));
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  function fnSubscribeModal(item) {
    setSubscribeModalShow(true);
    setSubscribeModalValue(item);
  }

  useEffect(() => {
    getAuthProfile();
  }, []);

  async function fnSubscribe(type) {
    if (Subscriptiondata !== "") {
      // setMembershipmodalshow(true);
      setNewSubscribeLoader(true);
      let body = {
        session_type: "app_member_level",
        cancel_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
        package_id: Subscriptiondata?.subscription_id,
        // package_price_id: Subscriptiondata?.package_price_id,
        success_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CLUB_SESSION_CREATE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setNewSubscribeLoader);
      if (response?.isValidate) {
        if (
          response?.data?.data?.url !== undefined &&
          response?.data?.data?.url !== null
        ) {
          window.open(response.data.data.url, "_self");
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
    if (SubscribeType === "cancel") {
      setMembershipmodalshow(true);
    }
    setSubscribeType(type);
  }

  async function getLevelTerm() {
    // setLoading(true);
    let body = {
      term: "level_type",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // console.log("Level", response);
      setLevelTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSportsLevelTerm() {
    // setLoading(true);
    let body = {
      term: "level_of_sportsmenship",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setSportsLevelTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getGenderTerm() {
    // setLoading(true);
    let body = {
      term: "gender_type",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // console.log("Level", response);
      setGenderTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getGenderTerm() {
    // setLoading(true);
    let body = {
      term: "gender_type",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // console.log("Level", response);
      setGenderTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function getGameTypeTerm() {
    // setLoading(true);
    let body = {
      term: "game_type",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGameTypeTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getDurationTerm() {
    // setLoading(true);
    let body = {
      term: "duration",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setDurationTerm(response.data.data.terms);

      if (response.data.data.terms.length > 0) {
        setDefaultDuration(response.data.data.terms[0].value);
        setInputSlottemp({
          ...InputSlottemp,
          duration: response.data.data.terms[0].value,
        });
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getAvailabilitySlots() {
    setAvailabilitySlotLoader(true);
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_GET_AVAILABILITY_SLOTS,
      },
      false
    );
    let response = apiResponse(false, resData, setAvailabilitySlotLoader);
    // if (response?.isValidate) {
    //   setAvailabilitySlotsList(response?.data?.data);
    //   let temp = [];
    //   response?.data?.data?.day_term.forEach((element) => {
    //     temp.push({
    //       day: element.day_term,
    //       is_selected: element.is_selected,
    //       slots: null,
    //     });
    //   });
    //   response?.data?.data?.days.forEach((element1) => {
    //     const matchingElement = temp.find(
    //       (element2) => element1.day === element2.day
    //     );
    //     if (matchingElement) {
    //       matchingElement.slots = element1.slots;
    //     }
    //   });

    //   console.log("PPP", temp);

    //   dispatch(FinalSlotsAction(temp));
    // }
    if (response?.isValidate) {
      setAvailabilitySlotsList(response?.data?.data);
      let temp = [];
      setSlotProce(response?.data?.data?.price);
      response?.data?.data?.day_term.forEach((element) => {
        temp.push({
          day: element.day_term,
          is_selected: element.is_selected,
          slots: null,
        });
      });
      response?.data?.data?.days.forEach((element1) => {
        const matchingElement = temp.find(
          (element2) => element1.day === element2.day
        );
        if (matchingElement) {
          const slotsWithFullDate = element1.slots.map((slot) => ({
            ...slot,
            start_time: new Date(
              CurrentDateMain.toDateString() + " " + slot.start_time
            ),
            end_time: new Date(
              CurrentDateMain.toDateString() + " " + slot.end_time
            ),
          }));
          matchingElement.slots = slotsWithFullDate;
        }
      });

      dispatch(FinalSlotsAction(temp));
    }

    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function getScheduleDates(pageno) {
    setAvailabilityScheduleLoader(true);
    let body = {
      page: pageno,
      limit: 10,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_SCHEDULE_DATES,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setAvailabilityScheduleLoader);
    if (response?.isValidate) {
      if (pageno === 1 || pageno === undefined) {
        setScheduleDatesList(response?.data?.data?.list);
      } else {
        setScheduleDatesList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionSchedule(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setpageNumberSchedule(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getCountryCode();
    if (AppSettingData) {
      const Appmilliseconds = AppSettingData?.order_cancellation_hours;
      const Apphours = Appmilliseconds / 3600;
      setPolicyHour(Apphours);
    }
    getAuthProfile();
    getSportsLevelTerm();
    getDurationTerm();
    getLevelTerm();
    getGenderTerm();
    getGameTypeTerm();
    getAvailabilitySlots();
    getScheduleDates(pageNumberSchedule);
  }, []);

  const handleLoadMoreSchedule = () => {
    getScheduleDates(pageNumberSchedule);
  };

  async function getMatchesScores(pageno) {
    setMatchesScoreLoader(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SETTINGS_MATCHES_SCORES,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setMatchesScoreLoader);
      if (response?.isValidate) {
        if (pageno === 1) {
          setMatchesScoreList(response?.data?.data?.list);
        } else {
          setMatchesScoreList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  // console.log("AvailabilitySlotsList ::", AvailabilitySlotsList);
  async function getWalletList(pageno) {
    setWalletLoader(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.MY_WALLET,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setWalletLoader);
      if (response?.isValidate) {
        setWalletCurrentBalance(response?.data?.data?.current_balance);
        if (pageno === 1) {
          setWalletList(response?.data?.data?.list);
        } else {
          setWalletList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtionWallet(true);
        }
        // setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumberWallet(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }

  async function getAppointmentList(pageno) {
    setAppointmentLoader(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
        order_item_status: AppointmentsValue,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.TRAINER_MY_APPOINTMENTS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setAppointmentLoader);
      if (response?.isValidate) {
        if (pageno === 1) {
          setAppointmentList(response?.data?.data?.list);
        } else {
          setAppointmentList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtionAppointment(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumberAppointment(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  useEffect(() => {
    if (SettingShow === "MatchesScores") {
      getMatchesScores(pageNumber);
    }
    // if (SettingShow === "Wallet") {
    //   getWalletList(pageNumberWallet);
    // }
    getWalletList(pageNumberWallet);
    getSubscriptionList();
  }, [SettingShow]);

  useEffect(() => {
    console.log("window.location.pathname", window.location.href);
    if (window.location.href.includes("payment_intent")) {
      setSettingShow("Wallet");
    }
  }, []);

  useEffect(() => {
    getAppointmentList(1);
  }, [AppointmentsValue]);

  const handleLoadMore = () => {
    getMatchesScores(pageNumber);
  };
  const handleLoadMoreAppointment = () => {
    getAppointmentList(pageNumberAppointment);
  };
  const handleLoadMoreWallet = () => {
    getWalletList(pageNumberWallet);
  };

  useEffect(() => {
    if (
      ProfileData !== null &&
      ProfileData !== "" &&
      ProfileData !== undefined
    ) {
      setInput({
        ...input,
        ["member_name"]: ProfileData?.member_name,
        ["email"]: ProfileData?.email,
        ["phone"]: ProfileData?.phone,
        ["pickleball_level_term"]: ProfileData?.pickleball_level_term,
        ["level_of_sportsmenship_term"]:
          ProfileData?.level_of_sportsmenship_term,
        ["profile_pic"]: ProfileData?.profile_pic,
        ["social_type_term"]: ProfileData?.nickname,
        ["gender_type_term"]: ProfileData?.gender_type_term,
        ["dob"]: ProfileData?.dob === null ? null : new Date(ProfileData?.dob),
      });
      setFile(ProfileData?.profile_pic);
      // setUpdateProfileLoading(true);
    }
    if (
      UserFullData !== null &&
      UserFullData !== "" &&
      UserFullData !== undefined
    ) {
      setInputUserProfile({
        ...InputUserProfile,

        ["member_name"]: UserFullData?.user?.member_name,
        ["email"]: UserFullData?.user?.email,
        ["phone"]: UserFullData?.user?.phone,
        ["pickleball_level_term"]: UserFullData?.user?.pickleball_level_term,
        ["level_of_sportsmenship_term"]:
          UserFullData?.user?.level_of_sportsmenship_term,
        ["profile_pic"]: UserFullData?.user?.profile_pic,
        ["social_type_term"]: UserFullData?.user?.nickname,
        ["gender_type_term"]: UserFullData?.user?.gender_type_term,
        ["rate"]: UserFullData?.user?.rate,
        ["distance"]: UserFullData?.user?.distance,
        ["dob"]:
          UserFullData?.user?.dob === null
            ? null
            : new Date(UserFullData?.user?.dob),
      });
      setUserProfileFile(UserFullData?.user?.profile_pic);
      // setUpdateProfileLoading(true);
    }

    if (
      UserFullData !== null &&
      UserFullData !== "" &&
      UserFullData !== undefined
    ) {
      setInputClubProfile({
        ...InputClubProfile,
        ["club_name"]: UserFullData?.club_details?.club_name,
        ["address1"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.line1
            : UserFullData?.club_details?.address,
        ["address2"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.line2
            : UserFullData?.club_details?.address2,
        ["city"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.city
            : UserFullData?.club_details?.city,
        ["state"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.state
            : UserFullData?.club_details?.state,
        ["zipcode"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.pinCode
            : UserFullData?.club_details?.zipcode,
        ["country"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.country
            : UserFullData?.club_details?.country,
        ["country_code"]: UserFullData?.club_details?.country_code,
        ["phone_no"]: UserFullData?.club_details?.phone_no,
        ["detail"]: UserFullData?.club_details?.detail,
        ["court_title"]: UserFullData?.court_details?.court_title,
        ["court_no"]: UserFullData?.court_details?.court_no,
        ["game_type_term"]: UserFullData?.court_details?.game_type_term,
        ["profile_pic"]: UserFullData?.club_details?.club_image,
      });
      setClubProfileFile(UserFullData?.club_details?.club_image);
      setPhoneCountryCode(UserFullData?.club_details?.country_code);

      setInputCoachAddress({
        ...InputCoachAddress,
        ["address1"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.line1
            : UserFullData?.user?.address1,
        ["address2"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.line2
            : UserFullData?.user?.address2,
        ["city"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.city
            : UserFullData?.user?.city,
        ["state"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.state
            : UserFullData?.user?.state,
        ["zipcode"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.pinCode
            : UserFullData?.user?.zipcode,
        ["country"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.country
            : UserFullData?.user?.country,
        ["latitude"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.latitude
            : UserFullData?.user?.latitude,
        ["longitude"]:
          GooglePlaceData !== undefined
            ? GooglePlaceData?.longitude
            : UserFullData?.user?.longitude,
      });
    }

    // if (window.location.pathname.includes('/onbording/callback')) {
    //   // Reload the window
    //   getAuthProfile();
    //   window.location.reload();
    // }

    // setInputCoachAddress
  }, [ProfileData, LoginInfo, UserFullData, GooglePlaceData]);

  console.log("SettingShow ::", SettingShow);

  const handleSubmit = async (e, manage) => {
    if (manage === "manage") {
      e.preventDefault();
      let obj = commonservices.fnCheckValidationOfObject(InputUserProfile);
      setInputUserProfile({
        ...obj.obj,
      });
      if (obj.isValid) {
        setUpdateProfileButtonLoader(true);
        let body = {
          member_name: InputUserProfile.member_name.trim(),
          email: InputUserProfile.email.trim(),
          phone: InputUserProfile.phone.trim(),
          // country_code: PhoneCountryCode.toString(),
          // profile_pic: document.getElementById("profileImg").files[0]
          //   ? document.getElementById("profileImg").files[0]
          //   : ProfileData?.profile_pic,
          profile_pic: InputUserProfile.profile_pic
            ? InputUserProfile.profile_pic
            : UserProfileFile?.profile_pic,
          nickname: InputUserProfile.social_type_term.trim(),
          pickleball_level_term: InputUserProfile.pickleball_level_term.trim(),
          level_of_sportsmenship_term:
            InputUserProfile.level_of_sportsmenship_term.trim(),
          dob:
            InputUserProfile.dob !== ""
              ? moment(InputUserProfile?.dob).format("YYYY-DD-MM")
              : "",
          gender_type_term: InputUserProfile.gender_type_term,
          rate: InputUserProfile.rate,
          distance: InputUserProfile.distance,
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.UPDATE_USER_PROFILE,
            body: body,
          },
          true
        );
        let response = apiResponse(
          false,
          resData,
          setUpdateProfileButtonLoader
        );
        if (response?.isValidate) {
          getAuthProfile();
          let temp = [];
          temp.push({
            ...response?.data?.data?.user,
            association_id: response?.data?.data?.user?.member_id,
          });
          dispatch(EditUserInfo(temp[0]));
          // dispatch(EditUserInfo(response?.data?.data?.user));
        }
        if (!response?.isValidate) {
          console.log("Error  getting country list", response);
        }
      }
    } else if (manage === "club") {
      e.preventDefault();
      let obj = commonservices.fnCheckValidationOfObject(InputClubProfile);
      setInputClubProfile({
        ...obj.obj,
      });
      if (obj.isValid) {
        setUpdateClubButtonLoader(true);
        let body = {
          club_name: InputClubProfile.club_name?.trim(),
          address1: InputClubProfile.address1?.trim(),
          address2:
            InputClubProfile?.address2 !== null
              ? InputClubProfile?.address2?.trim()
              : "",
          city: InputClubProfile.city?.trim(),
          state: InputClubProfile.state?.trim(),
          zipcode: InputClubProfile.zipcode?.trim(),
          country: InputClubProfile.country?.trim(),
          country_code: PhoneCountryCode,
          phone_no: InputClubProfile.phone_no?.trim(),
          detail:
            InputClubProfile.detail !== null
              ? InputClubProfile?.detail?.trim()
              : "",
          court_title: InputClubProfile.court_title?.trim(),
          court_no: InputClubProfile.court_no,
          game_type_term: InputClubProfile.game_type_term?.trim(),
          profile_pic: InputClubProfile.profile_pic,
        };

        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.CLUB_SAVE_CLUB_DETAILS,
            body: body,
          },
          true
        );
        let response = apiResponse(false, resData, setUpdateClubButtonLoader);
        if (response?.isValidate) {
          getAuthProfile();
          // dispatch(EditUserInfo(response?.data?.data?.user));
        }
        if (!response?.isValidate) {
          console.log("Error  getting country list", response);
        }
      }
    } else if (manage === "coachaddress") {
      // alert('coachaddress')
      e.preventDefault();
      let obj = commonservices.fnCheckValidationOfObject(InputCoachAddress);
      setInputCoachAddress({
        ...obj.obj,
      });
      if (obj.isValid) {
        setInputCoachLoader(true);
        let body = {
          address1: InputCoachAddress.address1?.trim(),
          // address2: InputCoachAddress.address2?.trim(),
          address2:
            InputCoachAddress?.address2 !== null
              ? InputCoachAddress?.address2?.trim()
              : "",
          // city: InputCoachAddress.city?.trim(),
          city:
            InputCoachAddress?.city !== null
              ? InputCoachAddress?.city?.trim()
              : "",
          // state: InputCoachAddress.state?.trim(),
          state:
            InputCoachAddress?.state !== null
              ? InputCoachAddress?.state?.trim()
              : "",
          zipcode: InputCoachAddress.zipcode?.trim(),
          // country: InputCoachAddress.country?.trim(),
          country:
            InputCoachAddress?.country !== null
              ? InputCoachAddress?.country?.trim()
              : "",
          latitude: InputCoachAddress.latitude,
          longitude: InputCoachAddress.longitude,
        };

        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.SAVE_ADDRESS,
            body: body,
          },
          true
        );
        let response = apiResponse(false, resData, setInputCoachLoader);
        if (response?.isValidate) {
          getAuthProfile();
        }
        if (!response?.isValidate) {
          console.log("Error  getting country list", response);
        }
      }
    } else {
      setUpdateProfileButtonLoader(true);
      e.preventDefault();
      let obj = commonservices.fnCheckValidationOfObject(input);
      setInput({
        ...obj.obj,
      });
      if (obj.isValid) {
        let body = {
          member_name: input.member_name.trim(),
          email: input.email.trim(),
          phone: input.phone.trim(),
          // country_code: PhoneCountryCode.toString(),
          // profile_pic: document.getElementById("profileImg").files[0]
          //   ? document.getElementById("profileImg").files[0]
          //   : ProfileData?.profile_pic,
          profile_pic: input.profile_pic
            ? input.profile_pic
            : ProfileData?.profile_pic,
          nickname: input.social_type_term.trim(),
          pickleball_level_term: input.pickleball_level_term.trim(),
          level_of_sportsmenship_term: input.level_of_sportsmenship_term.trim(),
          dob: input.dob !== "" ? moment(input?.dob).format("YYYY-DD-MM") : "",
          gender_type_term: input.gender_type_term,
          rate: InputUserProfile.rate,
          distance: InputUserProfile.distance,
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.UPDATE_USER_PROFILE,
            body: body,
          },
          true
        );
        let response = apiResponse(
          false,
          resData,
          setUpdateProfileButtonLoader
        );
        if (response?.isValidate) {
          getAuthProfile();
          let temp = [];
          temp.push({
            ...response?.data?.data?.user,
            association_id: response?.data?.data?.user?.member_id,
          });
          dispatch(EditUserInfo(temp[0]));
        }
        if (!response?.isValidate) {
          console.log("Error  getting country list", response);
        }
      }
    }
  };

  function getTimeDifference(startTime, endTime) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const timeDifference = endDate - startDate;
    const seconds = Math.floor((timeDifference / 1000) % 60);
    const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
    const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
    return {
      hours: hours,
      minutes: minutes,
      seconds: seconds,
      totalMilliseconds: timeDifference,
    };
  }

  function divideHoursByDurationInMinutes(hours, durationInMinutes) {
    hours = Math.max(0, parseFloat(hours));
    durationInMinutes = Math.max(0, parseInt(durationInMinutes, 10));
    const result = hours / (durationInMinutes / 60);
    return result;
  }

  const fnSaveAvailability = async (e) => {
    e.preventDefault();
    const timeDifference = getTimeDifference(
      InputSlottemp.startTime,
      InputSlottemp.endTime
    );
    const result = divideHoursByDurationInMinutes(
      timeDifference.hours,
      InputSlottemp.duration
    );

    if (DefaultDuration !== null) {
      let finalSlots1 = [];
      let x = parseInt(result);
      let DurationPars = parseInt(InputSlottemp.duration);
      InputSlot.days.forEach((element) => {
        if (element.is_selected) {
          for (let index = 0; index < x; index++) {
            let next_time = null;
            if (index > 0) {
              next_time = element.slots[index - 1].end_time;
            } else {
              next_time = InputSlottemp.startTime;
            }
            var newDateObj = new Date(
              next_time.getTime() + DurationPars * 60000
            );
            let slot = {
              duration: fnDurationInMinute(DefaultDuration),
              start_time: next_time,
              end_time: newDateObj,
              price: InputSlottemp.price,
            };
            element.slots.push(slot);
          }
        }

        finalSlots1.push(element);
      });
      dispatch(FinalSlotsAction(finalSlots1));
      setFinalSlots(finalSlots1);
      setInputSlottemp({
        ...InputSlottemp,
        price: 0,
      });
      setSettingShow("AvailabilityEdit");
    } else {
      toast.error("DefaultDuration is null.");
    }
  };

  function clearSlots() {
    let finalSlots1 = [];
    InputSlot.days.forEach((element) => {
      element.slots = [];
    });
    setFinalSlots(finalSlots1);
    dispatch(FinalSlotsAction(finalSlots1));
  }
  function fnDeleteDayAllSlot(item) {
    let temp = [];
    if (FinalSlotsRedux.length > 0) {
      FinalSlotsRedux.forEach((element) => {
        if (element.day === item.day) {
          temp.push({
            day: element.day,
            is_selected: 0,
            slots: [],
          });
        } else {
          temp.push({
            day: element.day,
            is_selected: 1,
            slots: element.slots,
          });
        }
      });
    }
    dispatch(FinalSlotsAction(temp));
  }
  async function fnDeleteDayOneSlot(item) {
    if (FinalSlotsRedux.length > 0) {
      let temp = [];
      if (FinalSlotsRedux.length > 0) {
        FinalSlotsRedux.forEach((element) => {
          let updatedElement = {
            day: element.day,
            is_selected: element?.is_selected,
            slots: [],
          };

          element?.slots?.forEach((element1) => {
            if (
              element1?.start_time !== item?.start_time ||
              element1?.end_time !== item?.end_time
            ) {
              updatedElement?.slots.push(element1);
            }
          });

          temp.push(updatedElement);
        });
      }
      dispatch(FinalSlotsAction(temp));
    }
  }
  async function fnFinalCreateSlot() {
    setFinalCreateSlotLoading(true);
    console.log("FinalSlotsRedux ::", FinalSlotsRedux);
    let tempDays = [];
    let FinalSlotsReduxConvertTimeofSlots = [];
    let body = {};

    if (FinalSlotsRedux.length > 0) {
      FinalSlotsRedux.forEach((element) => {
        tempDays.push({
          day: element.day,
          is_selected: element?.is_selected === true ? 1 : 0,
        });
        let formattedSlots = [];

        element?.slots?.forEach((element1) => {
          let slot = {
            duration: fnDurationInMinute(DefaultDuration),
            start_time: moment(element1?.start_time).format("HH:mm"),
            end_time: moment(element1?.end_time).format("HH:mm"),
            price: element1.price,
          };
          // setSlotProce(element1.price)
          formattedSlots.push(slot);
        });
        FinalSlotsReduxConvertTimeofSlots.push({
          slots: formattedSlots,
          day: element.day,
        });
      });

      body = {
        start_time: moment(InputSlottemp.startTime).format("HH:mm"),
        end_time: moment(InputSlottemp.endTime).format("HH:mm"),
        price: SlotProce,
        is_confirmed: 0,
        days: tempDays,
        all_days: FinalSlotsReduxConvertTimeofSlots,
      };
    }
    // else {
    //   console.log(AvailabilitySlotsList?.days);
    //   // AvailabilitySlotsList.forEach((element) => {
    //   //         tempDays.push(AvailabilitySlotsList.day_term);
    //   //         let formattedSlots = [];
    //   //         element.slots.forEach((element1) => {
    //   //           let slot = {
    //   //             duration: fnDurationInMinute(DefaultDuration),
    //   //             start_time: moment(element1.start_time).format("HH:mm"),
    //   //             end_time: moment(element1.end_time).format("HH:mm"),
    //   //             price: SlotProce,
    //   //           };
    //   //           formattedSlots.push(slot);
    //   //         });
    //   //         // Push the array of formattedSlots for each day
    //   //         // FinalSlotsReduxConvertTimeofSlots.push(formattedSlots);
    //   //         FinalSlotsReduxConvertTimeofSlots.push({
    //   //           slots: formattedSlots,
    //   //           day: element.day,
    //   //         });
    //   //       });
    //   // start_time
    //   // tempDays.push(AvailabilitySlotsList.day_term);
    //   body = {
    //     start_time: moment(AvailabilitySlotsList.start_time).format("HH:mm"),
    //     end_time: moment(AvailabilitySlotsList.end_time).format("HH:mm"),
    //     price: AvailabilitySlotsList.price,
    //     is_confirmed: 1,
    //     days: AvailabilitySlotsList.day_term,
    //     all_days: AvailabilitySlotsList.days,
    //   };
    // }
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_SAVE_AVAILABILITY,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setFinalCreateSlotLoading);
    if (response?.isValidate) {
      getAvailabilitySlots();
      // dispatch(FinalSlotsAction([]));
      setSettingShow("Availability");

      if (response?.data?.data?.is_already_exists === 1) {
        setIsAlreadyExistsModalShow(true);
        setIsAlreadyExistsModaldata(body);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  // console.log("AvailabilitySlotsList.days :", AvailabilitySlotsList.days);
  // useEffect(() => {
  //   const dataFromLocalStorage =
  //     JSON.parse(localStorage.getItem("FinalSlots")) || [];
  //   setFinalSlotsData(dataFromLocalStorage);
  // }, []);
  const removeToken = () => {
    localStorage.clear();
    window.location.reload();
  };

  async function fnDeleteAccount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.DELETE_ACCOUNT,
      },
      false
    );
    if (resData.status === 0) {
      toast.error(resData.message);
    }
    if (resData.status === 1) {
      toast.success(resData.message);
      localStorage.clear();
      window.location.reload();
    }
  }

  function fnOpenGooglePlaceModal() {
    setGooglePlaceModalShow(true);
  }

  async function fnBankDetails() {
    if (LoginInfo.stripe_connect_account_status !== "active") {
      let body = {
        return_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.TRAINER_GET_PAYMENT_ONBOARDING_LINK,
          body: body,
        },
        false
      );
      if (resData.status === 0) {
        toast.error(resData.message);
      }
      if (resData.status === 1) {
        // navigate(`${resData?.data?.link}`)
        window.location.href = resData?.data?.link;
        // window.open(resData?.data?.link);
        getAuthProfile();

        // Check if the current URL matches the callback URL
        // if (
        //   window.location.href ===
        //   "https://qa.picklerclubs.com/onbording/callback"
        // ) {
        //   // Reload the page
        //   getAuthProfile();
        //   window.location.reload();
        // }
        // window.open(resData?.data?.link);
        //  navigateToLink(resData?.data?.link);
        // setBankStripeModalLink(resData?.data?.link)
        // setBankStripeModalShow(true)
      }
    }
  }

  async function fnBookingDetail(item) {
    let body = {
      sub_order_id: item?.sub_order_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_BOOKING_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getScheduleDatesByYear(date) {
    let body = {
      year: moment(date).format("yyyy"),
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_SHEDULE_DATES_BY_YEAR,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // setScheduleDatesByYearList(response.data.data);
      setCalOption({
        date: new Date(),
        viewtype: "Month",
        data: response.data.data.dates,
      });
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSchedulesByDate(date) {
    setScheduleDatesByDatesLoader(true);
    let body = {
      date: moment(date).format("yyyy-MM-DD"),
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_SHEDULES_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setScheduleDatesByDatesLoader);
    if (response?.isValidate) {
      setScheduleDatesByDatesList(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getSchedulesByDate(CalenderDate);
  }, [CalenderDate]);

  useEffect(() => {
    getScheduleDatesByYear(new Date());
    // getSubscriptionDetails();
  }, []);

  function CalChange(data) {
    getScheduleDatesByYear(data?.StartDate);
    setCalenderDate(data?.StartDate);
  }

  function fnShareProfile(data) {
    setShareProfileData(data);
    setShareProfileModalShow(true);
  }

  // 2mb code
  async function fnResizeImage(oForm, imageType) {
    const file = oForm;
    const targetSize = 2 * 1024 * 1024; // 2 MB
    const compressedBlobInfo = await compressImage(file, 50, targetSize);
    const { blob, lastModified, name, size, type, webkitRelativePath } =
      compressedBlobInfo;

    const profilePicFile = new File([blob], name, { type, lastModified });

    if (imageType === "Editprofile") {
      setInput({
        ...input,
        ["profile_pic"]: profilePicFile,
      });
    }
    if (imageType === "Trainerprofile") {
      setInputUserProfile({
        ...InputUserProfile,
        ["profile_pic"]: profilePicFile,
      });
    }
    if (imageType === "Clubprofile") {
      setInputClubProfile({
        ...InputClubProfile,
        ["profile_pic"]: profilePicFile,
      });
    }
  }
  async function compressImage(imageFile, initialQuality, targetSize) {
    return new Promise(async (resolve, reject) => {
      let quality = initialQuality;
      let compressedBlob = await compress(imageFile, quality);

      while (compressedBlob.size > targetSize && quality > 0) {
        quality -= 10;
        compressedBlob = await compress(imageFile, quality);
      }

      resolve({
        blob: compressedBlob,
        size: compressedBlob.size,
        type: compressedBlob.type,
        lastModified: imageFile.lastModified,
        lastModifiedDate: imageFile.lastModifiedDate,
        name: imageFile.name,
        webkitRelativePath: imageFile.webkitRelativePath,
      });
    });

    async function compress(imageFile, quality) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
          const $canvas = document.createElement("canvas");
          $canvas.width = image.width;
          $canvas.height = image.height;
          const ctx = $canvas.getContext("2d");
          ctx.drawImage(image, 0, 0);

          $canvas.toBlob(
            (blob) => {
              if (blob === null) {
                reject(new Error("Failed to create blob."));
              } else {
                resolve(blob);
              }
            },
            "image/jpeg",
            quality / 100
          );
        };

        // Correct the order: Set the image source after the onload event is defined
        image.src = URL.createObjectURL(imageFile);
      });
    }
  }
  // 2mb code

  return (
    <>
      {/* <div onClick={() => fnSubscribe(5)}>Subscribe</div> */}
      <div className="profile-setting-main">
        {UpdateProfileLoading && <LoaderOverlay />}
        <div className="small-container container">
          <Row>
            <Col xl={5} md={4} className="settings-aside-main">
              <div className="general-settings-main">
                <h5>General</h5>
                <ul>
                  <li onClick={() => setSettingShow("EditProfile")}>
                    <div
                      className={
                        SettingShow === "EditProfile"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={editProfile} alt="editProfile" />
                      <p>Edit Profile</p>
                    </div>
                  </li>
                  <li onClick={() => setSettingShow("MatchesScores")}>
                    <div
                      className={
                        SettingShow === "MatchesScores"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={matchesScore} alt="matchesScore" />
                      <p>Matches & Scores</p>
                    </div>
                  </li>
                  <li
                  // onClick={() => setSettingShow("MyBookings")}
                  >
                    <Link to="/my-booking">
                      <div
                        className={
                          SettingShow === "MyBookings"
                            ? "settings-aside-single active"
                            : "settings-aside-single"
                        }
                      >
                        <img src={myBookings} alt="myBookings" />
                        <p>My Bookings</p>
                      </div>
                    </Link>
                  </li>

                  {/* <li>
                    <Link to="/cart">
                      <div className="settings-aside-single">
                        <img src={myCart} alt="myCart" />
                        <p>My Cart</p>
                      </div>
                    </Link>
                  </li> */}
                  {LoginInfo?.is_subscriber === 1 && (
                    <li onClick={() => setSettingShow("MySchedules")}>
                      <div
                        className={
                          SettingShow === "MySchedules"
                            ? "settings-aside-single active"
                            : "settings-aside-single"
                        }
                      >
                        <img src={myschedule} alt="myschedule" />{" "}
                        <p>My Schedules</p>
                      </div>
                    </li>
                  )}
                  <li onClick={() => setSettingShow("Wallet")}>
                    <div
                      className={
                        SettingShow === "Wallet"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={walletIcon} alt="walletIcon" />
                      <p>Wallet</p>
                    </div>
                  </li>
                  {LoginInfo?.is_coach === 1 ? (
                    <li onClick={() => setSettingShow("ManageCoach")}>
                      <div
                        className={
                          SettingShow === "ManageCoach"
                            ? "settings-aside-single active"
                            : "settings-aside-single"
                        }
                      >
                        <img src={becomeTrainer} alt="becomeTrainer" />
                        <p>Manage Coach</p>
                      </div>
                    </li>
                  ) : (
                    <li onClick={() => setSettingShow("MembershipsCopy")}>
                      <div
                        className={
                          SettingShow === "MembershipsCopy"
                            ? "settings-aside-single active"
                            : "settings-aside-single"
                        }
                      >
                        <img src={becomeTrainer} alt="becomeTrainer" />
                        <p>Become Coach</p>
                      </div>
                    </li>
                  )}

                  <li onClick={() => setSettingShow("Memberships")}>
                    <div
                      className={
                        SettingShow === "Memberships"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={membership} alt="membership" />
                      <p>Memberships</p>
                    </div>
                  </li>
                  <li onClick={() => setSettingShow("ClubMemberships")}>
                    <div
                      className={
                        SettingShow === "ClubMemberships"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={membership} alt="membership" />
                      <p>Club Memberships</p>
                    </div>
                  </li>
                  <li onClick={() => setSettingShow("Blocked")}>
                    <div
                      className={
                        SettingShow === "Blocked"
                          ? "settings-aside-single active"
                          : "settings-aside-single"
                      }
                    >
                      <img src={block} alt="Blocked" />
                      <p>Blocked</p>
                    </div>
                  </li>
                  <li
                    onClick={() => {
                      setSettingShow("EditProfile");
                      fnShareProfile(LoginInfo);
                    }}
                  >
                    <div
                      className={
                        // SettingShow === "EditProfile"
                        //   ? "settings-aside-single active"
                        // :
                        "settings-aside-single"
                      }
                    >
                      <img src={shareprofileIcon} alt="shareprofileIcon" />
                      <p>Share my Profile</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="general-settings-main mt-1">
                <h5>Support</h5>
                <ul>
                  {/* <li>
                    <div className="settings-aside-single">
                      <img src={privacy} alt="privacy" />
                      <p>Privacy Settings</p>
                    </div>
                  </li> */}
                  <li>
                    <a
                      target="_blank"
                      href={AppSettingData?.privacy_policy_url}
                      className="settings-aside-single"
                    >
                      <img src={privacy} alt="privacy" />
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={AppSettingData?.term_and_conditions_url}
                      className="settings-aside-single"
                    >
                      <img src={privacy} alt="privacy" />
                      Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href={AppSettingData?.about_us_url}
                      className="settings-aside-single"
                    >
                      <img src={contact} alt="contact" />
                      About
                    </a>
                  </li>
                  <li onClick={removeToken}>
                    <div className="settings-aside-single">
                      <img src={logout} alt="logout" />
                      <p>Logout</p>
                    </div>
                  </li>
                  <li onClick={() => fnDeleteAccount()} className="mb-0">
                    <div className="settings-aside-single">
                      <img src={deleleicon} alt="deleleicon" />
                      <p className="font-red">Delete Account</p>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xl={7} md={8}>
              {SettingShow === "Memberships" && <SubscriptionDetailComponent />}
              {SettingShow === "MembershipsCopy" && (
                <SubscriptionDetailComponent />
              )}

              {SettingShow === "Wallet" && (
                <>
                  <div className="settings-right-side-main">
                    <div className="settings-wallet-main">
                      <div className="settings-wallet-header">
                        <h5>Wallet</h5>
                        <div>
                          <h3>wallet balance</h3>
                          <div className="d-flex align-items-center justify-content-between">
                            <h1>
                              ${" "}
                              {WalletCurrentBalance === 0
                                ? "0.00"
                                : WalletCurrentBalance?.toFixed(2)}
                            </h1>
                            <span
                              className="dark-badge"
                              onClick={() => handleAddBalanceModal()}
                            >
                              Add Balance
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="settings-wallet-bottom-main">
                        <div className="settings-wallet-bot-header">
                          <h5>Transactions</h5>
                          {/* <Link to="#">
                            <span>see all</span>{" "}
                            <img src={rightArrow} alt="more" />
                          </Link> */}
                        </div>
                        <div className="settings-wallet-bot-content">
                          {WalletLoader && !WalletList?.length > 0 ? (
                            <Loader type={LoaderType.InviteFriend} count={5} />
                          ) : (
                            <>
                              {WalletList?.length > 0 ? (
                                WalletList?.map((item_w, index_w) => (
                                  <div
                                    // className="settings-wallet-bot-content-single"
                                    className={
                                      item_w?.action_type === "credit"
                                        ? "font-green settings-wallet-bot-content-single scroll-down"
                                        : "settings-wallet-bot-content-single scroll-down"
                                    }
                                    onClick={() =>
                                      handleTransactionModal(item_w)
                                    }
                                  >
                                    <div className="my-single-booking p-0">
                                      <div className="court1Image">
                                        {item_w?.action_type === "credit" ? (
                                          <div>
                                            <img
                                              src={recievedfrom}
                                              alt="recievedfrom"
                                            />
                                          </div>
                                        ) : (
                                          <div>
                                            <img src={paidto} alt="paidto" />
                                          </div>
                                        )}
                                      </div>
                                      <div className="courtContent">
                                        <div className="court-and-court-name">
                                          <p className="text-capitalize">
                                            {item_w?.action_type + "ed"}
                                          </p>
                                          <h5>{item_w?.narration}</h5>
                                          <p>
                                            {commonservices.getDayDateMonthTimeWallet(
                                              item_w?.created_at
                                            )}
                                          </p>
                                          {item_w?.transaction_status ===
                                            "succeeded" && (
                                            <p className="font-green">
                                              succeeded
                                            </p>
                                          )}
                                          {item_w?.transaction_status ===
                                            "pending" && (
                                            <p className="font-yellow">
                                              pending
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <h2>${item_w?.amount?.toFixed(2)}</h2>
                                  </div>
                                ))
                              ) : (
                                <Loader type={LoaderType.NODATAFOUND} />
                              )}
                            </>
                          )}
                          {WalletLoader && WalletList?.length > 0 && (
                            <Loader type={LoaderType.InviteFriend} count={5} />
                          )}

                          {WalletList?.length > 0 && (
                            <>
                              {!RemoveMoreButtionWallet && (
                                <div className="text-center w-100 view-more-btn">
                                  <button
                                    className="red-btn red-border width150"
                                    onClick={handleLoadMoreWallet}
                                    disabled={WalletLoader}
                                  >
                                    {WalletLoader ? "Loading..." : "View More"}
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {SettingShow === "EditProfile" && (
                <div className="settings-right-side-main">
                  <h5>Edit Profile</h5>
                  <Form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div>
                      <div className=" profileImageSelection">
                        <input
                          type="file"
                          className="form-control d-none"
                          id="profileImg"
                          ref={inputFile}
                          accept="image/png, image/gif, image/jpeg"
                          onChange={(e) => {
                            if (e?.target?.files?.[0]) {
                              if (
                                e?.target?.files?.[0]?.type !== "image/png" &&
                                e?.target?.files?.[0]?.type !== "image/jpeg"
                              ) {
                              }
                              // setInput({
                              //   ...input,
                              //   ["profile_pic"]: e?.target?.files?.[0],
                              // });
                              setFile(URL.createObjectURL(e.target.files[0]));
                              fnResizeImage(e?.target?.files[0], "Editprofile");
                            }
                          }}
                        />
                        {input?.profile_pic ? (
                          <img
                            src={file}
                            alt=""
                            type="file"
                            className="image-select edit-profile-image"
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt=""
                            type="file"
                            className="image-select edit-profile-image"
                            onClick={() => {
                              inputFile.current.click();
                            }}
                          />
                        )}
                      </div>

                      <div className="edit-profile-inputs">
                        {UpdateProfileLoading ? (
                          <Loader type={LoaderType.Line} count={8} />
                        ) : (
                          <div className="edit-profile-inputs">
                            <Form.Control
                              type="text"
                              placeholder="Full Name"
                              className="black-colored-focused"
                              value={input.member_name}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  ["member_name"]: e.target.value,
                                })
                              }
                              isInvalid={input.errors.member_name}
                            />
                            {input.errors.member_name && (
                              <Form.Control.Feedback type="invalid">
                                {input.errors.member_name}
                              </Form.Control.Feedback>
                            )}
                            <Form.Control
                              type="text"
                              placeholder="Username"
                              className="black-colored-focused"
                              value={input.social_type_term}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  ["social_type_term"]: e.target.value,
                                })
                              }
                              isInvalid={input.errors.social_type_term}
                            />
                            {input.errors.social_type_term && (
                              <Form.Control.Feedback type="invalid">
                                {input.errors.social_type_term}
                              </Form.Control.Feedback>
                            )}
                            <Form.Control
                              type="email"
                              placeholder="Email"
                              className="black-colored-focused"
                              value={input.email}
                              onChange={(e) =>
                                setInput({
                                  ...input,
                                  ["email"]: e.target.value,
                                })
                              }
                              isInvalid={input.errors.email}
                            />
                            {input.errors.email && (
                              <Form.Control.Feedback type="invalid">
                                {input.errors.email}
                              </Form.Control.Feedback>
                            )}
                            <InputGroup>
                              <Form.Select
                                disabled
                                aria-label="select country_code"
                                className="countr-code black-colored-focused"
                              >
                                <option>+1</option>
                                <option>+1</option>
                                <option>+1</option>
                                <option>+1</option>
                              </Form.Select>
                              <Form.Control
                                type="text"
                                disabled
                                placeholder="Mobile Number"
                                className="black-colored-focused"
                                value={input.phone}
                                onChange={(e) =>
                                  setInput({
                                    ...input,
                                    ["phone"]: e.target.value,
                                  })
                                }
                                isInvalid={input.errors.phone}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {input.errors.phone && (
                                <Form.Control.Feedback type="invalid">
                                  {input.errors.phone}
                                </Form.Control.Feedback>
                              )}
                            </InputGroup>
                            <Form.Select
                              aria-label="select club_activity_option"
                              className="black-colored-focused"
                              value={input.pickleball_level_term}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  ["pickleball_level_term"]: e.target.value,
                                });
                              }}
                            >
                              {LevelTerm?.length > 0 &&
                                LevelTerm.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                            </Form.Select>
                            <Form.Select
                              aria-label="select club_activity_option"
                              className="black-colored-focused"
                              value={input.level_of_sportsmenship_term}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  ["level_of_sportsmenship_term"]:
                                    e.target.value,
                                });
                              }}
                            >
                              {SportsLevelTerm?.length > 0 &&
                                SportsLevelTerm.map((item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.value}
                                  </option>
                                ))}
                            </Form.Select>
                            <DatePicker
                              selected={input.dob}
                              className=""
                              // value={DateOfActivity}
                              onChange={(e) => {
                                setInput({
                                  ...input,
                                  ["dob"]: e,
                                });
                              }}
                              format="DD/MM/YYYY"
                            />
                            <div className="gender-input">
                              <h5>Gender</h5>
                              <div className="red-radio-btn gap-2">
                                {GenderTerm?.length > 0 &&
                                  GenderTerm.map((item_l, index_l) => (
                                    <div className="red-radio-btn">
                                      <Form.Check
                                        type="radio"
                                        name="gender"
                                        label={item_l.value}
                                        id={item_l.value}
                                        value={item_l.value}
                                        checked={
                                          input.gender_type_term ===
                                          item_l.value
                                        }
                                        onChange={(e) => {
                                          setInput({
                                            ...input,
                                            ["gender_type_term"]:
                                              e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="edit-profile-save">
                          {UpdateProfileButtonLoader ? (
                            <button
                              type="submit"
                              className="red-border red-btn w-100"
                            >
                              Loading...
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="red-btn red-border w-100"
                            >
                              Save
                            </button>
                          )}
                          {/* <button
                            type="submit"
                            className="red-btn red-border w-100"
                          >
                           
                            Save
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              )}

              {SettingShow === "MatchesScores" && (
                <div className="settings-right-side-main" id="global-search">
                  <div className="settings-matches-scores-main">
                    <h5>Matches & Scores</h5>
                    {MatchesScoreLoader && !MatchesScoreList?.length > 0 ? (
                      <Loader type={LoaderType.HomePost} count={1} />
                    ) : (
                      <>
                        {MatchesScoreList?.length > 0 ? (
                          MatchesScoreList?.map((item_m, index_m) => (
                            <div className="settings-matches-scores-inner-single scroll-down">
                              {item_m?.club_details !== null ? (
                                <div className="my-single-booking">
                                  <div className="court1Image">
                                    <Link>
                                      {item_m?.club_details?.club_image !==
                                        null &&
                                      item_m?.club_details?.club_image !== "" &&
                                      item_m?.club_details?.club_image !==
                                        undefined ? (
                                        <img
                                          src={item_m?.club_details?.club_image}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </Link>
                                  </div>
                                  <div className="courtContent">
                                    <div className="court-and-court-name">
                                      <p>
                                        {commonservices.getDayDateMonthTimeTransaction(
                                          item_m?.score_details?.datetime
                                        )}
                                      </p>
                                      <h5>{item_m?.club_details?.club_name}</h5>
                                    </div>
                                    <div className="booking-court-type">
                                      <p>
                                        {item_m?.club_details?.game_type_term}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="my-single-booking">
                                  <div className="court1Image">
                                    <Link>
                                      {item_m?.member_details?.profile_pic !==
                                        null &&
                                      item_m?.member_details?.profile_pic !==
                                        "" &&
                                      item_m?.member_details?.profile_pic !==
                                        undefined ? (
                                        <img
                                          src={
                                            item_m?.member_details?.profile_pic
                                          }
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </Link>
                                  </div>
                                  <div className="courtContent">
                                    <div className="court-and-court-name">
                                      <p>
                                        {commonservices.getDayDateMonthTimeTransaction(
                                          item_m?.score_details?.datetime
                                        )}
                                      </p>
                                      <h5>
                                        {item_m?.member_details?.member_name}
                                      </h5>
                                    </div>
                                    <div className="booking-court-type">
                                      <p>
                                        {item_m?.member_details?.avg_rating?.toFixed(
                                          1
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="club-detail-recent-result-main w-100">
                                <div className="recent-result-wrapper">
                                  {item_m?.score_details?.score.length > 0 &&
                                    item_m?.score_details?.score.map(
                                      (s_item, s_index) => (
                                        <div className="recent-result-single-team">
                                          <p className="team-name">
                                            {s_item.team_name}
                                          </p>
                                          <div className="two-players-section">
                                            {s_item?.players.length > 0 &&
                                              s_item.players.map(
                                                (p_item, p_index) => (
                                                  <div className="single-players-section">
                                                    {/* <img
                                                  src={p_item.player_profile}
                                                  alt="profile"
                                                /> */}
                                                    {p_item.player_profile !==
                                                      null &&
                                                    p_item.player_profile !==
                                                      "" &&
                                                    p_item.player_profile !==
                                                      undefined ? (
                                                      <img
                                                        src={
                                                          p_item.player_profile
                                                        }
                                                        alt="club-detail-image"
                                                        onError={({
                                                          currentTarget,
                                                        }) => {
                                                          currentTarget.onerror =
                                                            null; // prevents looping
                                                          currentTarget.src =
                                                            imagePlaceholder;
                                                        }}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={imagePlaceholder}
                                                        alt="club-detail-image"
                                                      />
                                                    )}
                                                    <h6>
                                                      {p_item.player_name}
                                                    </h6>
                                                    <p>{p_item.avg_rating}</p>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                          <div className="recent-result-scores-wrapper">
                                            {s_item?.score?.map(
                                              (ss_item, ss_index) => (
                                                <h6
                                                  className="recent-result-scores"
                                                  key={ss_index}
                                                >
                                                  {ss_item}
                                                </h6>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="text-center">
                            No matches & scores found
                          </div>
                        )}
                      </>
                    )}
                    {MatchesScoreLoader && MatchesScoreList?.length > 0 && (
                      <Loader type={LoaderType.HomePost} count={4} />
                    )}

                    {MatchesScoreList?.length > 0 && (
                      <>
                        {!RemoveMoreButtion && (
                          <div className="text-center w-100 view-more-btn">
                            <button
                              className="red-btn red-border width150"
                              onClick={handleLoadMore}
                              disabled={MatchesScoreLoader}
                            >
                              {MatchesScoreLoader ? "Loading..." : "View More"}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {SettingShow === "Blocked" && <BlokedUserComponent />}
              {SettingShow === "ManageCoach" && (
                <div className="settings-right-side-main">
                  <div className="settings-become-trainer-main">
                    <h5>Trainer</h5>
                    <div className="become-trainer-top-four-box">
                      <Row className="become-trainer-four-box-top">
                        <Col
                          onClick={() => setSettingShow("PersonalInfo")}
                          xl={6}
                          className="become-trainer-four-box-top-single"
                        >
                          <img src={personalInfo} alt="personalInfo" />
                          <p>Personal Info.</p>
                        </Col>
                        <Col
                          onClick={() => fnBankDetails()}
                          xl={6}
                          className="become-trainer-four-box-top-single"
                        >
                          <img src={bankDetail} alt="bankDetail" />
                          <p>Bank Details</p>
                          <span>
                            {LoginInfo?.stripe_connect_account_status}
                          </span>
                        </Col>
                      </Row>
                      <Row className="become-trainer-four-box-top">
                        <Col
                          xl={6}
                          onClick={() => {
                            if (
                              LoginInfo.stripe_connect_account_status !==
                              "active"
                            ) {
                              setBankDetailModalshow(true);
                            } else {
                              setSettingShow("Availability");
                            }
                          }}
                          className="become-trainer-four-box-top-single"
                        >
                          <img src={availability} alt="Availability" />
                          <p>Availability.</p>
                        </Col>
                        <Col
                          xl={6}
                          onClick={() => setSettingShow("PersonalAddress")}
                          className="become-trainer-four-box-top-single"
                        >
                          <img src={address} alt="address" />
                          <p>Address</p>
                        </Col>
                      </Row>
                    </div>
                    {AppointmentLoader && !AppointmentList?.length > 0 ? (
                      <>
                        <div
                        // className="find-clubs-wrapper"
                        // id="find-clubs-wrapper"
                        >
                          <Loader type={LoaderType.InviteFriend} count={4} />
                        </div>
                      </>
                    ) : (
                      <div className="become-trainer-bottom-wrapper">
                        <div className="d-flex mb-4 justify-content-between align-items-center">
                          <h5 className="mb-0">Recent Appointments</h5>
                          <div
                            // onClick={()=>setAppointmentModalShow(true)}
                            onClick={() => {
                              setSettingShow("Appointments");
                              // getAppointmentList(1);
                              setAppointmentsValue("");
                            }}
                            // onClick={()=>alert()}
                            className="d-flex  justify-content-between align-items-center"
                          >
                            <span className="seeall">see all</span>{" "}
                            <img src={greyleftarrow} alt="more" />
                          </div>
                          {/* <p>see all <img src="../me"/></p>  */}
                        </div>
                        <div className="all-apointments-main-wraper">
                          {AppointmentLoader && AppointmentList?.length > 0 ? (
                            <div
                            // className="find-clubs-wrapper"
                            // id="find-clubs-wrapper"
                            >
                              <Loader
                                type={LoaderType.InviteFriend}
                                count={4}
                              />
                            </div>
                          ) : (
                            <>
                              {AppointmentList?.length > 0 ? (
                                AppointmentList.map((item_ap, index_ap) => (
                                  <div
                                    className="my-single-booking"
                                    onClick={() => {
                                      setSettingShow("AppointmentDetail");
                                      fnBookingDetail(item_ap);
                                    }}
                                  >
                                    <div className="court1Image">
                                      <Link to="/booking-detail">
                                        {item_ap?.profile_pic !== null &&
                                        item_ap?.profile_pic !== "" &&
                                        item_ap?.profile_pic !== undefined ? (
                                          <img
                                            src={item_ap?.profile_pic}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </Link>
                                    </div>
                                    <div className="courtContent">
                                      {item_ap?.order_item_status ===
                                        "booked" && (
                                        <div className="booking-status-badge">
                                          <p>{item_ap?.order_item_status}</p>
                                        </div>
                                      )}
                                      {item_ap?.order_item_status ===
                                        "cancelled" && (
                                        <div
                                          className="booking-status-badge"
                                          style={{ background: "#EF333F" }}
                                        >
                                          <p>{item_ap?.order_item_status}</p>
                                        </div>
                                      )}
                                      {item_ap?.order_item_status ===
                                        "completed" && (
                                        <div
                                          className="booking-status-badge"
                                          style={{ background: "#E0E0E0" }}
                                        >
                                          <p style={{ color: "#000000" }}>
                                            {item_ap?.order_item_status}
                                          </p>
                                        </div>
                                      )}
                                      <div className="court-and-court-name">
                                        <h5>{item_ap?.member_name}</h5>
                                        <h5>{item_ap?.club_name}</h5>
                                      </div>
                                      <div className="booking-court-type">
                                        <p>{item_ap?.court_title}</p>

                                        {/* <p>Crystal | </p>
                                    <p>Double</p> */}
                                      </div>
                                      <div className="booking-court-type">
                                        <p>{item_ap?.game_type_term}</p>
                                      </div>

                                      <div className="booking-date-and-time">
                                        <div className="booking-date">
                                          <img
                                            src={calendarsmall}
                                            alt="calendarsmall"
                                          />
                                          <p>
                                            {commonservices.getDateInFormat(
                                              item_ap?.start_time
                                            )}
                                          </p>
                                        </div>
                                        <div className="booking-date">
                                          <img src={clock} alt="clock" />
                                          <p>
                                            {" "}
                                            {commonservices.getTimeInFormat(
                                              item_ap?.start_time
                                            )}{" "}
                                            -{" "}
                                            {commonservices.getTimeInFormat(
                                              item_ap?.end_time
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <h5 className="text-center">
                                  No recent appointes
                                </h5>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {AppointmentLoader && AppointmentList?.length > 0 && (
                      <div
                      // className="find-clubs-wrapper"
                      // id="find-clubs-wrapper"
                      >
                        <Loader type={LoaderType.InviteFriend} count={4} />
                      </div>
                    )}
                    {AppointmentList?.length > 0 && (
                      <>
                        {!RemoveMoreButtionAppointment && (
                          <div className="text-center w-100 mb-3 view-more-btn">
                            <button
                              className="red-btn red-border width150"
                              onClick={handleLoadMoreAppointment}
                              disabled={AppointmentLoader}
                            >
                              {AppointmentLoader ? "Loading..." : "View More"}
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
              {SettingShow === "PersonalInfo" && (
                <div className="settings-right-side-main">
                  <div className="settings-personal-information-wrapper">
                    <div className="setting-personal-information-top">
                      <div className="setting-personal-information-top-inner  d-flex gap-2 align-items-center">
                        <img
                          onClick={() => setSettingShow("ManageCoach")}
                          src={leftarrow}
                          className="cursor-pointer my-white-icon"
                          alt="leftarrow"
                        />
                        <h5 className="mb-0 white-font">Personal Info</h5>
                        {/* <img
                          src={editPen}
                          alt="editPen"
                          className="edit-icon-setting"
                        /> */}
                      </div>
                    </div>
                    <div className="club-detail-activity-tabs">
                      <Tabs defaultActiveKey="Personal">
                        <Tab eventKey="Personal" title="Personal">
                          <Form
                            onSubmit={(e) => {
                              handleSubmit(e, "manage");
                            }}
                          >
                            <div className="setting-personal-profile-image">
                              {/* <img src={profilepic} alt="profilepic" /> */}
                              <input
                                type="file"
                                className="form-control d-none"
                                id="profileImg"
                                ref={inputFile1}
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  if (e?.target?.files?.[0]) {
                                    if (
                                      e?.target?.files?.[0]?.type !==
                                        "image/png" &&
                                      e?.target?.files?.[0]?.type !==
                                        "image/jpeg"
                                    ) {
                                    }
                                    // setInputUserProfile({
                                    //   ...InputUserProfile,
                                    //   ["profile_pic"]: e?.target?.files?.[0],
                                    // });
                                    setUserProfileFile(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                    fnResizeImage(
                                      e?.target?.files[0],
                                      "Trainerprofile"
                                    );
                                  }
                                }}
                              />
                              {InputUserProfile?.profile_pic ? (
                                <img
                                  src={UserProfileFile}
                                  alt=""
                                  type="file"
                                  className="image-select edit-profile-image"
                                  onClick={() => {
                                    inputFile1.current.click();
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt=""
                                  type="file"
                                  className="image-select edit-profile-image"
                                  onClick={() => {
                                    inputFile1.current.click();
                                  }}
                                />
                              )}
                            </div>
                            <div className="setting-personal-profile-edit-form">
                              <Form.Control
                                type="text"
                                placeholder="Full Name"
                                className="black-colored-focused"
                                value={InputUserProfile.member_name}
                                onChange={(e) =>
                                  setInputUserProfile({
                                    ...InputUserProfile,
                                    ["member_name"]: e.target.value,
                                  })
                                }
                                isInvalid={InputUserProfile.errors.member_name}
                              />
                              {InputUserProfile.errors.member_name && (
                                <Form.Control.Feedback type="invalid">
                                  {InputUserProfile.errors.member_name}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="Username"
                                className="black-colored-focused"
                                value={InputUserProfile.social_type_term}
                                onChange={(e) =>
                                  setInputUserProfile({
                                    ...InputUserProfile,
                                    ["social_type_term"]: e.target.value,
                                  })
                                }
                                isInvalid={
                                  InputUserProfile.errors.social_type_term
                                }
                              />
                              {InputUserProfile.errors.social_type_term && (
                                <Form.Control.Feedback type="invalid">
                                  {InputUserProfile.errors.social_type_term}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="email"
                                placeholder="Email"
                                className="black-colored-focused"
                                value={InputUserProfile.email}
                                onChange={(e) =>
                                  setInputUserProfile({
                                    ...InputUserProfile,
                                    ["email"]: e.target.value,
                                  })
                                }
                                isInvalid={InputUserProfile.errors.email}
                              />
                              {InputUserProfile.errors.email && (
                                <Form.Control.Feedback type="invalid">
                                  {InputUserProfile.errors.email}
                                </Form.Control.Feedback>
                              )}
                              <InputGroup>
                                <Form.Select
                                  disabled
                                  aria-label="select country_code"
                                  className="countr-code black-colored-focused"
                                >
                                  <option>+1</option>
                                  <option>+1</option>
                                  <option>+1</option>
                                  <option>+1</option>
                                </Form.Select>
                                <Form.Control
                                  type="text"
                                  disabled
                                  placeholder="Mobile Number"
                                  className="black-colored-focused"
                                  value={InputUserProfile.phone}
                                  onChange={(e) =>
                                    setInputUserProfile({
                                      ...InputUserProfile,
                                      ["phone"]: e.target.value,
                                    })
                                  }
                                  isInvalid={InputUserProfile.errors.phone}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {InputUserProfile.errors.phone && (
                                  <Form.Control.Feedback type="invalid">
                                    {InputUserProfile.errors.phone}
                                  </Form.Control.Feedback>
                                )}
                              </InputGroup>
                              <Form.Select
                                aria-label="select club_activity_option"
                                className="black-colored-focused"
                                value={InputUserProfile.pickleball_level_term}
                                onChange={(e) => {
                                  setInputUserProfile({
                                    ...InputUserProfile,
                                    ["pickleball_level_term"]: e.target.value,
                                  });
                                }}
                              >
                                {LevelTerm?.length > 0 &&
                                  LevelTerm.map((item, index) => (
                                    <option key={index} value={item.value}>
                                      {item.value}
                                    </option>
                                  ))}
                              </Form.Select>
                              <div>
                                <Form.Control
                                  type="text"
                                  placeholder="Rate (in $)"
                                  className="black-colored-focused"
                                  value={InputUserProfile?.rate}
                                  onChange={(e) =>
                                    setInputUserProfile({
                                      ...InputUserProfile,
                                      ["rate"]: e.target.value,
                                    })
                                  }
                                  isInvalid={InputUserProfile.errors.rate}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                <p>Upload your coaching rate (fee)</p>
                                {InputUserProfile.errors.rate && (
                                  <Form.Control.Feedback type="invalid">
                                    {InputUserProfile.errors.rate}
                                  </Form.Control.Feedback>
                                )}
                              </div>
                              <div>
                                <Form.Control
                                  type="text"
                                  placeholder="Distance (km)"
                                  className="black-colored-focused"
                                  value={InputUserProfile?.distance}
                                  onChange={(e) =>
                                    setInputUserProfile({
                                      ...InputUserProfile,
                                      ["distance"]: e.target.value,
                                    })
                                  }
                                  isInvalid={InputUserProfile.errors.distance}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                <p>Enter your accessibility of training area</p>
                                {InputUserProfile.errors.distance && (
                                  <Form.Control.Feedback type="invalid">
                                    {InputUserProfile.errors.distance}
                                  </Form.Control.Feedback>
                                )}
                              </div>
                              {/* <button className="red-btn red-border w-100">
                                Save
                              </button> */}
                              {UpdateProfileButtonLoader ? (
                                <button
                                  type="submit"
                                  className="red-btn red-border  w-100"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="red-btn red-border w-100"
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </Form>
                        </Tab>

                        <Tab eventKey="Court Info" title="Court Info">
                          <Form
                            onSubmit={(e) => {
                              handleSubmit(e, "club");
                            }}
                          >
                            <div className="setting-personal-profile-image">
                              {/* <img src={profilepic} alt="profilepic" /> */}
                              <input
                                type="file"
                                className="form-control d-none"
                                id="profileImg"
                                ref={inputFile2}
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  if (e?.target?.files?.[0]) {
                                    if (
                                      e?.target?.files?.[0]?.type !==
                                        "image/png" &&
                                      e?.target?.files?.[0]?.type !==
                                        "image/jpeg"
                                    ) {
                                    }
                                    // setInputClubProfile({
                                    //   ...InputClubProfile,
                                    //   ["profile_pic"]: e?.target?.files?.[0],
                                    // });
                                    setClubProfileFile(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                    fnResizeImage(
                                      e?.target?.files[0],
                                      "Clubprofile"
                                    );
                                  }
                                }}
                              />
                              {InputClubProfile?.profile_pic ? (
                                <img
                                  src={ClubProfileFile}
                                  alt=""
                                  type="file"
                                  className="image-select edit-profile-image"
                                  onClick={() => {
                                    inputFile2.current.click();
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt=""
                                  type="file"
                                  className="image-select edit-profile-image"
                                  onClick={() => {
                                    inputFile2.current.click();
                                  }}
                                />
                              )}
                            </div>
                            <div className="setting-personal-profile-edit-form">
                              <Form.Control
                                type="text"
                                placeholder="Club Name"
                                className="black-colored-focused"
                                value={InputClubProfile?.club_name}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["club_name"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.club_name}
                                // onKeyPress={(event) => {
                                //   if (!/[0-9]/.test(event.key)) {
                                //     event.preventDefault();
                                //   }
                                // }}
                              />
                              {InputClubProfile.errors.club_name && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.club_name}
                                </Form.Control.Feedback>
                              )}
                              {/* <Button onClick={() => fnOpenGooglePlaceModal()}>
                                Google Address
                              </Button>
                              <Form.Control
                                type="text"
                                placeholder="Address line 1"
                                className="black-colored-focused"
                                value={InputClubProfile?.address1}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["address1"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.address1}
                              />
                              {InputClubProfile.errors.address1 && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.address1}
                                </Form.Control.Feedback>
                              )} */}
                              <InputGroup className="my-error">
                                <Form.Control
                                  type="text"
                                  placeholder="Address line 1"
                                  className="black-colored-focused"
                                  value={InputClubProfile?.address1}
                                  onChange={(e) =>
                                    setInputClubProfile({
                                      ...InputClubProfile,
                                      ["address1"]: e.target.value,
                                    })
                                  }
                                  isInvalid={InputClubProfile.errors.address1}
                                />

                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                  onClick={() => fnOpenGooglePlaceModal()}
                                  className="m-0 loc-btn"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-current-location"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    />
                                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                                    <path d="M12 12m-8 0a8 8 0 1 0 16 0a8 8 0 1 0 -16 0" />
                                    <path d="M12 2l0 2" />
                                    <path d="M12 20l0 2" />
                                    <path d="M20 12l2 0" />
                                    <path d="M2 12l2 0" />
                                  </svg>
                                </Button>
                                {InputClubProfile.errors.address1 && (
                                  <Form.Control.Feedback type="invalid">
                                    {InputClubProfile.errors.address1}
                                  </Form.Control.Feedback>
                                )}
                              </InputGroup>
                              <Form.Control
                                type="text"
                                placeholder="Address line 2"
                                className="black-colored-focused"
                                value={InputClubProfile?.address2}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["address2"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.address2}
                              />
                              {InputClubProfile.errors.address2 && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.address2}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="City"
                                className="black-colored-focused"
                                value={InputClubProfile?.city}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["city"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.city}
                              />
                              {InputClubProfile.errors.city && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.city}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="State"
                                className="black-colored-focused"
                                value={InputClubProfile?.state}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["state"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.state}
                              />
                              {InputClubProfile.errors.state && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.state}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="Country"
                                className="black-colored-focused"
                                value={InputClubProfile?.country}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["country"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.country}
                              />
                              {InputClubProfile.errors.country && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.country}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="Zipcode"
                                className="black-colored-focused"
                                value={InputClubProfile?.zipcode}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["zipcode"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.zipcode}
                                onKeyPress={(event) => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                              {InputClubProfile.errors.zipcode && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.zipcode}
                                </Form.Control.Feedback>
                              )}
                              <InputGroup>
                                <Button
                                  // type="text"
                                  className="m-0 loc-btn"
                                  onClick={() => {
                                    // setInputClubProfile({
                                    //   ...InputClubProfile,
                                    //   ["country_code"]: PhoneCountryCode,
                                    // });
                                    setPhoneCodeModalShow(true);
                                  }}
                                >
                                  +{PhoneCountryCode}
                                </Button>
                                <Form.Control
                                  type="text"
                                  placeholder="Mobile Number"
                                  className="black-colored-focused"
                                  value={InputClubProfile.phone_no}
                                  onChange={(e) =>
                                    setInputClubProfile({
                                      ...InputClubProfile,
                                      ["phone_no"]: e.target.value,
                                    })
                                  }
                                  isInvalid={InputClubProfile.errors.phone_no}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                                {InputClubProfile.errors.phone_no && (
                                  <Form.Control.Feedback type="invalid">
                                    {InputClubProfile.errors.phone_no}
                                  </Form.Control.Feedback>
                                )}
                              </InputGroup>
                              <Form.Control
                                as="textarea"
                                style={{ height: "100px" }}
                                placeholder="detail"
                                className="black-colored-focused"
                                value={InputClubProfile?.detail}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["detail"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.detail}
                              />
                              {InputClubProfile.errors.detail && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.detail}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="court_title"
                                className="black-colored-focused"
                                value={InputClubProfile?.court_title}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["court_title"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.court_title}
                              />
                              {InputClubProfile.errors.court_title && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.court_title}
                                </Form.Control.Feedback>
                              )}
                              <Form.Control
                                type="text"
                                placeholder="court_no"
                                className="black-colored-focused"
                                value={InputClubProfile?.court_no}
                                onChange={(e) =>
                                  setInputClubProfile({
                                    ...InputClubProfile,
                                    ["court_no"]: e.target.value,
                                  })
                                }
                                isInvalid={InputClubProfile.errors.court_no}
                              />
                              {InputClubProfile.errors.court_no && (
                                <Form.Control.Feedback type="invalid">
                                  {InputClubProfile.errors.court_no}
                                </Form.Control.Feedback>
                              )}

                              <h6>Game Type</h6>
                              <div className="red-radio-btn">
                                {GameTypeTerm?.length > 0 &&
                                  GameTypeTerm.map((item_m, index) => (
                                    <>
                                      <Form.Check
                                        type="radio"
                                        label={item_m.value}
                                        id={item_m.value}
                                        name="game_type_term"
                                        checked={
                                          InputClubProfile.game_type_term ===
                                          item_m.value
                                        }
                                        value={item_m.value}
                                        onChange={(e) => {
                                          setInputClubProfile({
                                            ...InputClubProfile,
                                            ["game_type_term"]: e.target.value,
                                          });
                                        }}
                                      />
                                       
                                    </>
                                  ))}
                              </div>
                              {UpdateClubButtonLoader ? (
                                <button
                                  type="submit"
                                  className="red-btn red-border  w-100"
                                >
                                  Loading...
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="red-btn red-border w-100"
                                >
                                  Save
                                </button>
                              )}
                            </div>
                          </Form>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              )}
              {SettingShow === "PersonalAddress" && (
                <div className="settings-right-side-main">
                  <div className="settings-edit-address-wrapper-main">
                    <div className=" d-flex gap-2 align-items-center">
                      <img
                        onClick={() => setSettingShow("ManageCoach")}
                        src={leftarrow}
                        className="cursor-pointer"
                        alt="leftarrow"
                      />
                      <h5 className="m-0">Address</h5>
                    </div>

                    <Form
                      onSubmit={(e) => {
                        handleSubmit(e, "coachaddress");
                      }}
                    >
                      <div className="setting-personal-profile-edit-form">
                        <InputGroup className="my-error">
                          <Form.Control
                            type="text"
                            placeholder="Address line 1"
                            className="black-colored-focused"
                            value={InputCoachAddress?.address1}
                            onChange={(e) =>
                              setInputCoachAddress({
                                ...InputCoachAddress,
                                ["address1"]: e.target.value,
                              })
                            }
                            isInvalid={InputCoachAddress.errors.address1}
                          />

                          <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={() => fnOpenGooglePlaceModal()}
                            className="m-0 loc-btn"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon icon-tabler icon-tabler-current-location"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="#000000"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0" />
                              <path d="M12 12m-8 0a8 8 0 1 0 16 0a8 8 0 1 0 -16 0" />
                              <path d="M12 2l0 2" />
                              <path d="M12 20l0 2" />
                              <path d="M20 12l2 0" />
                              <path d="M2 12l2 0" />
                            </svg>
                          </Button>
                          {InputCoachAddress.errors.address1 && (
                            <Form.Control.Feedback type="invalid">
                              {InputCoachAddress.errors.address1}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>

                        {/* 
                        <Button onClick={() => fnOpenGooglePlaceModal()}>
                          Google Address
                        </Button>
                        <Form.Control
                          type="text"
                          placeholder="Address line 1"
                          className="black-colored-focused"
                          value={InputCoachAddress?.address1}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["address1"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.address1}
                        />
                        {InputCoachAddress.errors.address1 && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.address1}
                          </Form.Control.Feedback>
                        )} */}
                        <Form.Control
                          type="text"
                          placeholder="Address line 2"
                          className="black-colored-focused"
                          value={InputCoachAddress?.address2}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["address2"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.address2}
                        />
                        {InputCoachAddress.errors.address2 && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.address2}
                          </Form.Control.Feedback>
                        )}
                        <Form.Control
                          type="text"
                          placeholder="City"
                          className="black-colored-focused"
                          value={InputCoachAddress?.city}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["city"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.city}
                        />
                        {InputCoachAddress.errors.city && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.city}
                          </Form.Control.Feedback>
                        )}
                        <Form.Control
                          type="text"
                          placeholder="State"
                          className="black-colored-focused"
                          value={InputCoachAddress?.state}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["state"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.state}
                        />
                        {InputCoachAddress.errors.state && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.state}
                          </Form.Control.Feedback>
                        )}
                        <Form.Control
                          type="text"
                          placeholder="Country"
                          className="black-colored-focused"
                          value={InputCoachAddress?.country}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["country"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.country}
                        />
                        {InputCoachAddress.errors.country && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.country}
                          </Form.Control.Feedback>
                        )}
                        <Form.Control
                          type="text"
                          placeholder="Zipcode"
                          className="black-colored-focused"
                          value={InputCoachAddress?.zipcode}
                          onChange={(e) =>
                            setInputCoachAddress({
                              ...InputCoachAddress,
                              ["zipcode"]: e.target.value,
                            })
                          }
                          isInvalid={InputCoachAddress.errors.zipcode}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {InputCoachAddress.errors.zipcode && (
                          <Form.Control.Feedback type="invalid">
                            {InputCoachAddress.errors.zipcode}
                          </Form.Control.Feedback>
                        )}

                        {InputCoachLoader ? (
                          <button
                            type="submit"
                            className="red-btn red-border  w-100"
                          >
                            Loading...
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="red-btn red-border w-100"
                          >
                            Save
                          </button>
                        )}
                      </div>
                    </Form>
                  </div>
                </div>
              )}

              {SettingShow === "Appointments" && (
                <div className="settings-right-side-main">
                  <div className="settings-edit-address-wrapper-main d-flex align-items-center justify-content-between mb-3">
                    <div className=" d-flex gap-2 align-items-center">
                      {/* <h5
                        onClick={() => {
                          setSettingShow("ManageCoach");
                          setAppointmentsValue("booked");
                        }}
                      >
                        Back
                      </h5> */}
                      <img
                        onClick={() => {
                          setSettingShow("ManageCoach");
                          setAppointmentsValue("booked");
                        }}
                        src={leftarrow}
                        className="cursor-pointer"
                        alt="leftarrow"
                      />
                      <h5 className="m-0">Appointments</h5>
                    </div>

                    <DropdownButton
                      id="dropdown-basic-button"
                      className="special-dropdown"
                    >
                      <Dropdown.Item
                        onClick={() => {
                          setAppointmentsValue("");
                        }}
                      >
                        All
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAppointmentsValue("booked");
                        }}
                      >
                        Booked
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAppointmentsValue("cancelled");
                        }}
                      >
                        Cancelled
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAppointmentsValue("completed");
                        }}
                      >
                        Completed
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setAppointmentsValue("pending");
                        }}
                      >
                        Pending
                      </Dropdown.Item>
                    </DropdownButton>
                  </div>
                  <div className="all-apointments-main-wraper">
                    {AppointmentLoader ? (
                      <Loader type={LoaderType.InviteFriend} count={6} />
                    ) : (
                      <>
                        {AppointmentList?.length > 0 ? (
                          AppointmentList.map((item_ap, index_ap) => (
                            <div
                              onClick={() => {
                                setSettingShow("AppointmentDetail");
                                fnBookingDetail(item_ap);
                              }}
                              className="my-single-booking"
                            >
                              <div className="court1Image">
                                <Link>
                                  {item_ap?.profile_pic !== null &&
                                  item_ap?.profile_pic !== "" &&
                                  item_ap?.profile_pic !== undefined ? (
                                    <img
                                      src={item_ap?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </Link>
                              </div>
                              <div className="courtContent">
                                {item_ap?.order_item_status === "booked" && (
                                  <div className="booking-status-badge">
                                    <p>{item_ap?.order_item_status}</p>
                                  </div>
                                )}
                                {item_ap?.order_item_status === "cancelled" && (
                                  <div
                                    className="booking-status-badge"
                                    style={{ background: "#EF333F" }}
                                  >
                                    <p>{item_ap?.order_item_status}</p>
                                  </div>
                                )}
                                {item_ap?.order_item_status === "completed" && (
                                  <div
                                    className="booking-status-badge"
                                    style={{ background: "#E0E0E0" }}
                                  >
                                    <p style={{ color: "#000000" }}>
                                      {item_ap?.order_item_status}
                                    </p>
                                  </div>
                                )}
                                <div className="court-and-court-name">
                                  <h5>{item_ap?.member_name}</h5>
                                  <h5>{item_ap?.club_name}</h5>
                                </div>
                                <div className="booking-court-type">
                                  <p>{item_ap?.court_title}</p>

                                  {/* <p>Crystal | </p>
                                    <p>Double</p> */}
                                </div>
                                <div className="booking-court-type">
                                  <p>{item_ap?.game_type_term}</p>
                                </div>

                                <div className="booking-date-and-time">
                                  <div className="booking-date">
                                    <img
                                      src={calendarsmall}
                                      alt="calendarsmall"
                                    />
                                    <p>
                                      {commonservices.getDateInFormat(
                                        item_ap?.start_time
                                      )}
                                    </p>
                                  </div>
                                  <div className="booking-date">
                                    <img src={clock} alt="clock" />
                                    <p>
                                      {" "}
                                      {commonservices.getTimeInFormat(
                                        item_ap?.start_time
                                      )}{" "}
                                      -{" "}
                                      {commonservices.getTimeInFormat(
                                        item_ap?.end_time
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <h5 className="text-center">No appoints found</h5>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}

              {SettingShow === "Availability" && (
                <div className="settings-right-side-main">
                  <div className="settings-availability-wrapper-main">
                    <div className="settings-availability-top">
                      {/* <h5 className="mb-0" >
                        Back
                      </h5> */}
                      <div className="d-flex align-items-center gap-2">
                        <img
                          onClick={() => setSettingShow("ManageCoach")}
                          src={leftarrow}
                          className="cursor-pointer"
                          alt="leftarrow"
                        />
                        <h5 className="m-0">Availability </h5>
                      </div>
                      <div className="d-flex align-items-center gap-2">
                        {AvailabilitySlotsList?.days?.length <= 0 && (
                          <div
                            onClick={() =>
                              setSettingShow("CreateAvailablitySlot")
                            }
                            className="cursor-pointer rounded-plus"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                            >
                              <path
                                d="M11 4.58325V17.4166"
                                stroke="#ffffff"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M4.5835 11H17.4168"
                                stroke="#ffffff"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                        )}

                        {AvailabilitySlotsList?.days?.length > 0 && (
                          <div className="settings-availability-top-icon d-flex gap-2">
                            <div
                              onClick={() => {
                                setSettingShow("AvailabilityEdit");
                                getAvailabilitySlots();
                              }}
                              className="settings-availability-top-icon-single"
                            >
                              <img
                                src={editPen}
                                alt="editPen"
                                className="edit-icon-setting"
                              />
                            </div>
                            {/* <div
                            onClick={() =>
                              setSettingShow("AvailabilitySchedules")
                            }
                            className="settings-availability-top-icon-single"
                          >
                            <img
                              src={calendar}
                              alt="editPen"
                              className="edit-icon-setting"
                            />
                          </div> */}
                          </div>
                        )}
                        <div className="settings-availability-top-icon d-flex gap-2">
                          <div
                            onClick={() =>
                              setSettingShow("AvailabilitySchedules")
                            }
                            className="settings-availability-top-icon-single"
                          >
                            <img
                              src={calendar}
                              alt="editPen"
                              className="edit-icon-setting"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="settings-availability-inner-main">
                      {AvailabilitySlotLoader ? (
                        <Loader type={LoaderType.InviteFriend} count={4} />
                      ) : (
                        <>
                          {AvailabilitySlotsList?.days !== "" &&
                          AvailabilitySlotsList?.days !== undefined &&
                          AvailabilitySlotsList?.days !== null &&
                          AvailabilitySlotsList?.days?.length > 0 ? (
                            AvailabilitySlotsList?.days.map(
                              (item_av, index_av) => (
                                <>
                                  <h6>{item_av?.day}</h6>
                                  <div className="legends-of-available-slots-content">
                                    {item_av?.slots !== null &&
                                      item_av?.slots?.length > 0 &&
                                      item_av?.slots.map(
                                        (item_sl, index_sl) => (
                                          <div className="legends-of-available-slots-inner-para">
                                            <p>
                                              {moment(
                                                item_sl?.start_time,
                                                "HH:mm"
                                              ).format("HH:mm")}{" "}
                                              -{" "}
                                              {moment(
                                                item_sl?.end_time,
                                                "HH:mm"
                                              ).format("HH:mm")}
                                              <strong className="d-block">
                                                ${item_sl?.price?.toFixed(2)}
                                              </strong>
                                            </p>
                                            {/* <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-circle-x-filled"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#ffffff"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path
                                  d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                  stroke-width="0"
                                  fill="#ef333f"
                                ></path>
                              </svg>
                            </span> */}
                                          </div>
                                        )
                                      )}
                                  </div>
                                </>
                              )
                            )
                          ) : (
                            <>
                              <h6 className="text-center my-5">
                                Slot not available
                              </h6>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {SettingShow === "AvailabilityEdit" && (
                <>
                  <div className="settings-right-side-main">
                    <div className="settings-edit-schedule-main-wrapper">
                      <div className="d-flex align-items-center appointment-detail-title settings-availability-top">
                        <div className="d-flex align-items-center gap-3">
                          <img
                            onClick={() => {
                              setSettingShow("ManageCoach");
                              clearSlots();
                            }}
                            src={leftarrow}
                            alt="leftarrow"
                            className="cursor-pointer"
                          />
                          <h5 className="mb-0">Availability</h5>
                        </div>
                        <div className="settings-availability-top-icon">
                          <div>
                            {/* <img
                              src={calendar}
                              alt="editPen"
                              className="edit-icon-setting"
                            /> */}
                          </div>
                        </div>
                      </div>

                      <div className="settings-availability-inner-main settings-edit-schedule-main ">
                        {FinalSlotsRedux?.length > 0 &&
                          FinalSlotsRedux.map((item_f, index_f) => (
                            <div>
                              <div className="d-flex align-items-center justify-content-between">
                                <h6>{item_f.day}</h6>
                                <span
                                  onClick={() =>
                                    confirmAlert({
                                      title: `Are you sure you want to clear all slot for ${item_f.day}?`,
                                      buttons: [
                                        {
                                          label: "No",
                                        },
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            fnDeleteDayAllSlot(item_f),
                                        },
                                      ],
                                    })
                                  }
                                >
                                  clear all
                                </span>
                              </div>
                              <div className="legends-of-available-slots-content">
                                {item_f?.slots?.length > 0 &&
                                  item_f.slots.map((item_is, index_is) => (
                                    <div className="legends-of-available-slots-inner-para">
                                      <p>
                                        {moment(item_is?.start_time).format(
                                          "HH:mm"
                                        )}{" "}
                                        -{" "}
                                        {moment(item_is?.end_time).format(
                                          "HH:mm"
                                        )}
                                        <strong className="d-block">
                                          $
                                          {item_is?.price === 0
                                            ? "0.00"
                                            : item_is?.price}
                                        </strong>
                                      </p>
                                      <span
                                        onClick={() =>
                                          confirmAlert({
                                            title: `Are you sure you want to delete ${moment(
                                              item_is.start_time
                                            ).format("HH:mm")}-${moment(
                                              item_is.end_time
                                            ).format("HH:mm")} slot for ${
                                              item_f.day
                                            }?`,
                                            buttons: [
                                              {
                                                label: "No",
                                              },
                                              {
                                                label: "Yes",
                                                onClick: () =>
                                                  fnDeleteDayOneSlot(item_is),
                                              },
                                            ],
                                          })
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          class="icon icon-tabler icon-tabler-circle-x-filled"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="#ffffff"
                                          fill="none"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          ></path>
                                          <path
                                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                                            stroke-width="0"
                                            fill="#ef333f"
                                          ></path>
                                        </svg>
                                      </span>
                                    </div>
                                  ))}

                                <div className="legends-of-available-slots-inner-para">
                                  <p
                                    onClick={(e) =>
                                      handleAddAvailabilitySlotModal(item_f)
                                    }
                                  >
                                    +
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className="d-flex gap-4">
                          <button
                            className="red-btn red-border w-100"
                            onClick={() => fnFinalCreateSlot()}
                          >
                            {FinalCreateSlotLoading ? "Loading..." : "Save"}
                          </button>
                          <button
                            className="white-btn dark-color red-border w-100"
                            onClick={() => setSettingShow("Availability")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {SettingShow === "AvailabilitySchedules" && (
                <div className="settings-right-side-main">
                  <div className="settings-availability-schedule-main-wrapper">
                    <div className="d-flex align-items-center gap-3 appointment-detail-title">
                      <img
                        src={leftarrow}
                        alt="leftarrow"
                        className="cursor-pointer"
                        onClick={() => setSettingShow("Availability")}
                      />
                      <h5 className="mb-0">Availability Schedules</h5>
                    </div>
                    <div className="settings-availability-schedule-inner">
                      {AvailabilityScheduleLoader &&
                      !ScheduleDatesList?.length > 0 ? (
                        <Loader type={LoaderType.InviteFriend} count={4} />
                      ) : (
                        <>
                          {ScheduleDatesList?.length > 0 ? (
                            ScheduleDatesList.map((item_sl, index_sl) => (
                              <div
                                onClick={() => {
                                  setScheduleModalShow(true);
                                  setAvailableScheduleInfo(item_sl);
                                }}
                                className="settings-availability-schedule-inner-single"
                              >
                                <p>
                                  {moment(item_sl?.booking_date).format(
                                    "ddd DD MMM yyyy"
                                  )}
                                </p>
                                <span className="dark-badge">
                                  {item_sl?.total_timeslots}
                                </span>
                              </div>
                            ))
                          ) : (
                            <Loader type={LoaderType.NODATAFOUND} />
                          )}
                        </>
                      )}
                      {AvailabilityScheduleLoader &&
                        ScheduleDatesList?.length > 0 && (
                          <Loader type={LoaderType.InviteFriend} count={5} />
                        )}
                      {ScheduleDatesList?.length > 0 && (
                        <>
                          {!RemoveMoreButtionSchedule && (
                            <div className="text-center w-100 mb-3 view-more-btn">
                              <button
                                className="red-btn red-border width150"
                                onClick={handleLoadMoreSchedule}
                                disabled={AvailabilityScheduleLoader}
                              >
                                {AvailabilityScheduleLoader
                                  ? "Loading..."
                                  : "View More"}
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {SettingShow === "CreateAvailablitySlot" && (
                <>
                  {/* {AvailabilitySlotsList?.days?.length > 0 ? ( */}
                  <div className="settings-right-side-main">
                    <div className="settings-edit-availability-main">
                      <div className="d-flex align-items-center gap-3 appointment-detail-title">
                        <img
                          onClick={() => setSettingShow("Availability")}
                          src={leftarrow}
                          alt="leftarrow"
                          className="cursor-pointer"
                        />
                        <h5 className="mb-0">Availability</h5>
                      </div>
                      <Form
                        onSubmit={(e) => {
                          fnSaveAvailability(e);
                        }}
                      >
                        <div className="settings-edit-availability-date-picker">
                          <div className="find-club-filter-datepicker-main justify-content-start gap-3">
                            <div className="find-club-filter-datepicker-single">
                              <p>Start Time</p>
                              <DatePicker
                                selected={InputSlotStartTime}
                                onChange={(date) => {
                                  const newStartTime = date.toLocaleTimeString(
                                    "en-US",
                                    {
                                      hour12: false,
                                    }
                                  );
                                  setInputSlottemp({
                                    ...InputSlottemp,
                                    startTime: date,
                                  });
                                  setInputSlot((prevInputSlot) => ({
                                    ...prevInputSlot,
                                    days: prevInputSlot.days.map((day) => ({
                                      ...day,
                                      slots: day.slots.map((slot) => ({
                                        ...slot,
                                        start_time: newStartTime,
                                      })),
                                    })),
                                  }));
                                  // updateSlots({ start_time: newStartTime });
                                  setInputSlotStartTime(date);
                                  // localStorage.setItem("ST", newStartTime);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                              />
                            </div>
                            <div className="find-club-filter-datepicker-single">
                              <p>End Time</p>
                              <DatePicker
                                selected={InputSlotEndTime}
                                onChange={(date) => {
                                  const newEndTime = date.toLocaleTimeString(
                                    "en-US",
                                    { hour12: false }
                                  );
                                  setInputSlottemp({
                                    ...InputSlottemp,
                                    endTime: date,
                                  });
                                  setInputSlot((prevInputSlot) => ({
                                    ...prevInputSlot,
                                    days: prevInputSlot.days.map((day) => ({
                                      ...day,
                                      slots: day.slots.map((slot) => ({
                                        ...slot,
                                        end_time: newEndTime,
                                      })),
                                    })),
                                  }));
                                  // updateSlots({ end_time: newEndTime });
                                  setInputSlotEndTime(date);
                                  // localStorage.setItem("ET", newEndTime);
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="HH:mm"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="settings-availability-inner-main">
                          <h6>Select Days</h6>
                          <div className="all-days-for-availability">
                            {InputSlot.days.map((item_d, index_d) => (
                              <div
                                className="dropIn-court border-0 p-0 mb-2"
                                key={index_d}
                              >
                                <input
                                  type="checkbox"
                                  id={item_d.day}
                                  name={item_d.day}
                                  checked={item_d.is_selected}
                                  onChange={(e) => {
                                    const updatedDays = InputSlot.days.map(
                                      (day, index) => ({
                                        ...day,
                                        is_selected:
                                          index === index_d
                                            ? e.target.checked
                                            : day.is_selected,
                                      })
                                    );

                                    setInputSlot((prevInputSlot) => ({
                                      ...prevInputSlot,
                                      days: updatedDays,
                                    }));
                                  }}
                                />
                                <label htmlFor={item_d.day}>{item_d.day}</label>
                              </div>
                            ))}
                          </div>
                          <div className="upload-score-duration mt-3">
                            <h6>Duration</h6>
                            <div className="all-duration-main">
                              {DurationTerm &&
                                DurationTerm.length > 0 &&
                                DurationTerm.map((item_s, index_s) => (
                                  <div
                                    key={item_s.value}
                                    className={`duration-inner ${
                                      fnDurationInMinute(DefaultDuration) ===
                                      fnDurationInMinute(item_s.value)
                                        ? "active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setInputSlottemp({
                                        ...InputSlottemp,
                                        duration: item_s.value,
                                      });
                                      // updateSlots({ duration: item_s.value });
                                      setDefaultDuration(item_s.value);
                                      setInputSlot((prevInputSlot) => ({
                                        ...prevInputSlot,
                                        days: prevInputSlot.days.map((day) => ({
                                          ...day,
                                          slots: day.slots.map((slot) => ({
                                            ...slot,
                                            duration: fnDurationInMinute(
                                              item_s.value
                                            ),
                                          })),
                                        })),
                                      }));
                                    }}
                                  >
                                    {item_s.value} mins
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="settings-availability-price">
                            <h6>Price</h6>
                            <Form.Control
                              type="text"
                              placeholder="$"
                              aria-label="Price"
                              aria-describedby="basic-addon1"
                              className="black-colored-focused"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e) => {
                                setInputSlottemp({
                                  ...InputSlottemp,
                                  price: e.target.value,
                                });
                                setSlotProce(e.target.value);
                                setInputSlot((prevInputSlot) => ({
                                  ...prevInputSlot,
                                  days: prevInputSlot.days.map((day) => ({
                                    ...day,
                                    slots: day.slots.map((slot) => ({
                                      ...slot,
                                      price: e.target.value,
                                    })),
                                  })),
                                }));
                              }}
                            />
                          </div>
                          <button
                            // onClick={() => setSettingShow("AvailabilityEdit")} // comment out after save data in local
                            className="red-btn red-border w-100"
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                  {/* ) : ( */}

                  {/* )} */}
                </>
              )}

              {SettingShow === "AppointmentDetail" && (
                <>
                  <div className="settings-right-side-main">
                    <div className="settings-appointment-detail-wrapper">
                      <div className="d-flex align-items-center gap-3 appointment-detail-title">
                        <img
                          onClick={() => {
                            setSettingShow("ManageCoach");
                            setAppointmentsValue("booked");
                          }}
                          src={leftarrow}
                          alt="leftarrow"
                          className="cursor-pointer"
                        />
                        <h5 className="mb-0">Appointment Detail</h5>
                      </div>
                      {BookingDetail?.order_item_status === "booked" && (
                        <div className="w-100 mb-3">
                          <span className="green-badge mb-3">
                            {BookingDetail?.order_item_status}
                          </span>
                        </div>
                      )}
                      {BookingDetail?.order_item_status === "cancelled" && (
                        <div className="w-100 mb-3">
                          <span className="red-badge">
                            {BookingDetail?.order_item_status}
                          </span>
                        </div>
                      )}
                      {BookingDetail?.order_item_status === "completed" && (
                        <div className="w-100 mb-3">
                          <span className="grey-badge mb-3">
                            {BookingDetail?.order_item_status}
                          </span>
                        </div>
                      )}
                      {/* <h2 className={BookingDetail?.order_item_status }>{BookingDetail?.order_item_status}</h2> */}
                      <h5 className="mb-1">User Detail</h5>
                      <div className="all-apointments-main-wraper">
                        <div className="my-single-booking align-items-center">
                          <div className="court1Image">
                            <Link>
                              {BookingDetail?.from_member_details
                                ?.profile_pic !== null &&
                              BookingDetail?.from_member_details
                                ?.profile_pic !== "" &&
                              BookingDetail?.from_member_details
                                ?.profile_pic !== undefined ? (
                                <img
                                  src={
                                    BookingDetail?.from_member_details
                                      ?.profile_pic
                                  }
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                />
                              )}
                            </Link>
                          </div>
                          <div className="courtContent">
                            <div className="court-and-court-name">
                              <h5>
                                {
                                  BookingDetail?.from_member_details
                                    ?.member_name
                                }
                              </h5>
                            </div>
                            <div className="booking-court-type">
                              <p>{BookingDetail?.from_member_details?.phone}</p>
                            </div>
                          </div>
                          {BookingDetail?.from_member_details?.phone !== "" &&
                            BookingDetail?.from_member_details?.phone !==
                              undefined &&
                            BookingDetail?.from_member_details?.phone !==
                              null && (
                              <a
                                href={
                                  "tel:+" +
                                  BookingDetail?.from_member_details?.phone
                                }
                                className="club-detail-map-top"
                              >
                                <div className="booking-detail-location-single">
                                  <div className="booking-detail-location-email-call-bg">
                                    <img src={call} alt="call" />
                                  </div>
                                </div>
                              </a>
                            )}
                        </div>
                      </div>
                      {BookingDetail?.club_details && (
                        <>
                          <h5>Court Detail</h5>
                          <div className="setting-court-detail-wrapper">
                            {BookingDetail?.club_details?.club_image !== null &&
                            BookingDetail?.club_details?.club_image !== "" &&
                            BookingDetail?.club_details?.club_image !==
                              undefined ? (
                              <img
                                src={BookingDetail?.club_details?.club_image}
                                alt="club-detail-image"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = imagePlaceholder1;
                                }}
                                className="w-100"
                              />
                            ) : (
                              <img
                                src={imagePlaceholder1}
                                alt="club-detail-image"
                                className="w-100"
                              />
                            )}
                            <div className="setting-court-detail-content p-0">
                              <div className="setting-court-detail-content-inner d-flex align-items-center justify-between w-100">
                                <div>
                                  <h6>
                                    {BookingDetail?.club_details?.club_name}
                                  </h6>
                                  <p>
                                    {BookingDetail?.club_details?.court_title}
                                  </p>
                                  <p>
                                    {
                                      BookingDetail?.club_details
                                        ?.game_type_term
                                    }
                                  </p>
                                </div>
                                <div className="ms-auto">
                                  {BookingDetail?.club_details?.latitude !==
                                    "" &&
                                    BookingDetail?.club_details?.longitude !==
                                      "" &&
                                    BookingDetail?.club_details?.latitude !==
                                      undefined &&
                                    BookingDetail?.club_details?.latitude !==
                                      null &&
                                    BookingDetail?.club_details?.longitude !==
                                      undefined &&
                                    BookingDetail?.club_details?.longitude !==
                                      null && (
                                      <a
                                        className="d-flex align-items-center"
                                        target="_blank"
                                        href={`https://www.google.co.in/maps/dir/?saddr=&daddr=${BookingDetail?.latitude},${BookingDetail?.longitude}&directionsmode=driving`}
                                      >
                                        <img
                                          src={locationIcon}
                                          alt="location"
                                        />
                                      </a>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {BookingDetail?.trainer_details && (
                        <>
                          <h5>Schedule Details</h5>
                          <div className="booking-date-and-time mb-3">
                            <div className="booking-date">
                              <img src={calendarsmall} alt="calendarsmall" />
                              <p>
                                {commonservices.getDateInFormat(
                                  BookingDetail?.trainer_details?.start_time
                                )}
                              </p>
                            </div>
                            <div className="booking-date">
                              <img src={clock} alt="clock" />
                              <p>
                                {" "}
                                {commonservices.getTimeInFormat(
                                  BookingDetail?.trainer_details?.start_time
                                )}{" "}
                                -{" "}
                                {commonservices.getTimeInFormat(
                                  BookingDetail?.trainer_details?.end_time
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {BookingDetail?.payment_details !== "" &&
                        BookingDetail?.payment_details !== undefined &&
                        BookingDetail?.payment_details !== null && (
                          <div className="booking-detail-right-bottom mt-0 profile-setting-main">
                            <h5>Payment Details</h5>
                            <div className="booking-detail-right-bottom setting-payment-detail">
                              {BookingDetail?.payment_details?.length > 0 &&
                                BookingDetail?.payment_details.map(
                                  (item_b, index_b) => (
                                    <>
                                      <div
                                        className="booking-detail-right-bottom-single"
                                        key={index_b}
                                      >
                                        <p>{item_b?.title}</p>
                                        <span>{item_b?.value}</span>
                                      </div>
                                    </>
                                  )
                                )}

                              {BookingDetail?.order_item_status !==
                                "completed" && (
                                <button
                                  className="red-btn red-border w-100"
                                  // onClick={() => fnCancelBookingModal()}
                                >
                                  Cancel
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </>
              )}
              {SettingShow === "MySchedules" && (
                <>
                  <div className="settings-right-side-main">
                    <div className="settings-bank-detail-wrapper-main">
                      <h5>My Schedules</h5>
                      <div className="my-schedule-in-setting-main">
                        <FullCalender
                          option={CalOption}
                          changeCallBack={CalChange}
                          FullCurrentDate={setCalenderDate}
                        />
                      </div>
                      {ScheduleDatesByDatesList?.length > 0 && (
                        <h5 className="mb-2">Time</h5>
                      )}
                      {ScheduleDatesByDatesLoader ? (
                        <Loader type={LoaderType.InviteFriend} count={1} />
                      ) : (
                        <div className="my-schedule-time-main">
                          {ScheduleDatesByDatesList?.length > 0 &&
                            ScheduleDatesByDatesList.map(
                              (item_sc, index_sc) => (
                                <div className="my-schedule-time-single">
                                  <div className="my-schedule-time-single-time">
                                    <p className="mb-1">
                                      {commonservices.getTimeInFormat(
                                        item_sc?.items[0]?.start_time
                                      )}
                                    </p>
                                    <p>
                                      {" "}
                                      {commonservices.getTimeInFormat(
                                        item_sc?.items[0]?.end_time
                                      )}
                                    </p>
                                  </div>
                                  <div className="my-schedule-time-single-content-all">
                                    {item_sc?.items?.length > 0 &&
                                      item_sc?.items.map(
                                        (item_cc, index_cc) => (
                                          <>
                                            {item_cc?.item_type ===
                                            "club_booking" ? (
                                              <div
                                                onClick={() =>
                                                  fnOnlyTrainerLink(item_sc)
                                                }
                                                className="my-schedule-time-single-content-single"
                                              >
                                                <h6>Pickleball Match</h6>
                                                <p>{item_cc?.club_name}</p>
                                              </div>
                                            ) : (
                                              <div
                                                onClick={() =>
                                                  fnOnlyTrainerLink(item_sc)
                                                }
                                                className="my-schedule-time-single-content-single"
                                              >
                                                <h6>Training</h6>
                                                <p>{item_cc?.trainer_name}</p>
                                              </div>
                                            )}
                                          </>
                                        )
                                      )}
                                    {/* <div className="my-schedule-time-single-content-single">
                                  <h6>L1 Competition</h6>
                                  <p>Ronald Richard</p>
                                </div>
                                <div className="my-schedule-time-single-content-single">
                                  <h6>L1 Competition</h6>
                                  <p>Ronald Richard</p>
                                </div>
                                <div className="my-schedule-time-single-content-single">
                                  <h6>L1 Competition</h6>
                                  <p>Ronald Richard</p>
                                </div>
                                <div className="my-schedule-time-single-content-single">
                                  <h6>L1 Competition</h6>
                                  <p>Ronald Richard</p>
                                </div>
                                <div className="my-schedule-time-single-content-single">
                                  <h6>L1 Competition</h6>
                                  <p>Ronald Richard</p>
                                </div> */}
                                  </div>
                                </div>
                              )
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {SettingShow === "ClubMemberships" && (
                <>
                  <ClubMemberShipList />
                </>
              )}
              {/* <div className="settings-right-side-main">
                <div className="settings-bank-detail-wrapper-main">
                  <h5>Bank Detail</h5>
                  <div className="setting-personal-profile-edit-form">
                    <Form.Control
                      type="text"
                      placeholder="Account Name"
                      className="black-colored-focused"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Account Number"
                      className="black-colored-focused"
                    />
                    <Form.Control
                      type="text"
                      placeholder="IFSC Code"
                      className="black-colored-focused"
                    />
                    <Form.Control
                      type="text"
                      placeholder="Branch"
                      className="black-colored-focused"
                    />

                    <button className="red-btn red-border w-100">Save</button>
                  </div>
                </div>
              </div> */}
            </Col>
          </Row>
        </div>
      </div>
      {WalletModalShow && (
        <AddBalanceModal
          WalletList={WalletList}
          PayType="settingmpage"
          show={WalletModalShow}
          WalletCurrentBalance={WalletCurrentBalance}
          onHide={() => setWalletModalShow(false)}
          setSettingShow={setSettingShow}
        />
      )}
      {Membershipmodalshow && (
        <MembershipBuyModal
          Subscriptiondata={Subscriptiondata}
          SubscriptionList={SubscriptionList}
          MembershipDetail={MembershipDetail}
          show={Membershipmodalshow}
          SubscribeType={SubscribeType}
          onHide={() => setMembershipmodalshow(false)}
        />
      )}
      {TransactionModalShow && (
        <TrasactionModal
          TransactionValue={TransactionValue}
          show={TransactionModalShow}
          onHide={() => setTransactionModalShow(false)}
        />
      )}
      {SubscribeModalShow && (
        <SubsciptionModal
          show={SubscribeModalShow}
          bindList={getSubscriptionDetails}
          Tag="settingPage"
          SubscribeModalData={SubscribeModalValue}
          onHide={() => setSubscribeModalShow(false)}
        />
      )}
      {PhoneCodeModalShow && (
        <PhoneCodeModal
          show={PhoneCodeModalShow}
          CountryList={Country_list}
          PhoneCode={setPhoneCountryCode}
          CountryID={setPhoneCountryID}
          onHide={() => setPhoneCodeModalShow(false)}
        />
      )}
      {GooglePlaceModalShow && (
        <GooglePlaceModal
          show={GooglePlaceModalShow}
          GooglePlaceData={setGooglePlaceData}
          onHide={() => setGooglePlaceModalShow(false)}
        />
      )}
      {IsAlreadyExistsModalShow && (
        <AlreadyExistsModal
          show={IsAlreadyExistsModalShow}
          body={IsAlreadyExistsModaldata}
          onHide={() => setIsAlreadyExistsModalShow(false)}
        />
      )}
      {AppointmentModalShow && (
        <AppointmentModal
          show={AppointmentModalShow}
          AppointmentList={AppointmentList}
          onHide={() => setAppointmentModalShow(false)}
        />
      )}
      {BankStripeModalShow && (
        <BankStripeModal
          show={BankStripeModalShow}
          BankStripeModalLink={BankStripeModalLink}
          onHide={() => setBankStripeModalShow(false)}
        />
      )}
      {ScheduleModalShow && (
        <AvailabilityScheduleTimeModal
          show={ScheduleModalShow}
          AvailableScheduleInfo={AvailableScheduleInfo}
          bindList={getScheduleDates}
          onHide={() => setScheduleModalShow(false)}
        />
      )}
      {BankDetailModalshow && (
        <BankDetailModal
          show={BankDetailModalshow}
          onHide={() => setBankDetailModalshow(false)}
        />
      )}
      {BookSlotModalShow && (
        <AddAvailabilitySlotModal
          show={BookSlotModalShow}
          BookSlotData={BookSlotData}
          DefaultDuration={DefaultDuration}
          onHide={() => setBookSlotModalShow(false)}
        />
      )}
      {ShareProfileModalShow && (
        <ShareProfileModal
          show={ShareProfileModalShow}
          Type="profile"
          ShareProfileData={ShareProfileData}
          onHide={() => setShareProfileModalShow(false)}
        />
      )}
    </>
  );
}

export default Settings;
