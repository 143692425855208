import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import information from "../images/informaton.svg";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";
import RateCourtModal from "./RateCourtModal";
import DropInPeopleModal from "./DropInPeopleModal";
import { useSelector } from "react-redux";

const PlayerBookingModal = (props) => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [RateCourtModalShow, setRateCourtModalShow] = useState(false);
  const [DropinRateCourtModalShow, setDropinRateCourtModalShow] =
    useState(false);
  const [RateModalType, setRateModalType] = useState("");
  const [Loading, setLoading] = useState(false);
  const [searchFollower, setsearchFollower] = useState("");
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [MemberConnection, setMemberConnection] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [Players, setPlayers] = useState();
  // const [PlayerType, setPlayerType] = useState('');

  function fnBookingRateModal(type, item) {
    setRateCourtModalShow(true);
    setRateModalType(type);
    setPlayers(item);
    //  props.onHide();
  }
  function fnDropinBookingRateModal(item) {
    setDropinRateCourtModalShow(true);
    setPlayers(item);
  }

  

  return (
    <>
      <Modal
        className="invite-friend-modal-main-wrapper"
        size="md"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Rate Player
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        {props?.RatePlayerType === "BasicPeople" ? (
          <Modal.Body id="global-search">
            <div className="invite-friends-main">
              {props?.BookingDetail?.players_details?.length > 0 ? (
                props?.BookingDetail?.players_details.map(
                  (item, index) =>
                    item.member_id !== LoginInfo?.association_id && (
                      <div
                        key={index}
                        className="invite-friends-single"
                        // onClick={() => alert("sss")}
                        onClick={() => fnBookingRateModal("People", item)}
                      >
                        <div className="invite-friends-single-img-name">
                          {item?.profile_pic !== null &&
                          item?.profile_pic !== "" &&
                          item?.profile_pic !== undefined ? (
                            <img
                              src={item?.profile_pic}
                              alt="club-detail-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder;
                              }}
                              className="invite-friends-single-image"
                            />
                          ) : (
                            <img
                              src={imagePlaceholder}
                              alt="club-detail-image"
                              className="invite-friends-single-image"
                            />
                          )}
                          <p
                            className={
                              item?.member_type === "Standard"
                                ? "profile-standard-after-initials-title border-0 m-0 p-0"
                                : item?.member_type === "Premium"
                                ? "profile-premium-after-initials-title border-0 m-0 p-0"
                                : "border-0 m-0 p-0"
                            }
                          >
                            {item?.member_name}
                          </p>
                        </div>
                      </div>
                    )
                )
              ) : (
                <div className="d-flex justify-content-center gap-1 align-items-center w-100">
                  <img src={information} alt="info" />
                  <p>Data not found</p>
                </div>
              )}
            </div>
          </Modal.Body>
        ) : (
          <Modal.Body id="global-search">
            {" "}
            <div className="invite-friends-main">
              {props?.BookingDetail?.dropin_players?.length > 0 ? (
                props?.BookingDetail?.dropin_players.map((item, index) => (
                  <div
                    key={index}
                    className="invite-friends-single"
                    // onClick={() => alert("sss")}
                    onClick={() => fnDropinBookingRateModal(item)}
                  >
                    <div className="invite-friends-single-img-name">
                      {item?.profile_pic !== null &&
                      item?.profile_pic !== "" &&
                      item?.profile_pic !== undefined ? (
                        <img
                          src={item?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                          className="invite-friends-single-image"
                        />
                      ) : (
                        <img
                          src={imagePlaceholder}
                          alt="club-detail-image"
                          className="invite-friends-single-image"
                        />
                      )}
                      <p>{item?.member_name}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center gap-1 align-items-center w-100">
                  <img src={information} alt="info" />
                  <p>Data not found</p>
                </div>
              )}
            </div>
          </Modal.Body>
        )}
      </Modal>

      {RateCourtModalShow && (
        <RateCourtModal
          show={RateCourtModalShow}
          RateModalType={RateModalType}
          param={props?.param}
          CloseModal={props.CloseModal}
          bindList={props?.bindList}
          Players={Players}
          BookingDetail={props?.BookingDetail}
          onHide={() => setRateCourtModalShow(false)}
        />
      )}
      {DropinRateCourtModalShow && (
        <DropInPeopleModal
          show={DropinRateCourtModalShow}
          RateModalType={RateModalType}
          param={props?.param}
          bindList={props?.bindList}
          CloseModal={props.CloseModal}
          Players={Players}
          BookingDetail={props?.BookingDetail}
          onHide={() => setDropinRateCourtModalShow(false)}
        />
      )}
    </>
  );
};

export default PlayerBookingModal;
