import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";

const GroupMembersModal = (props) => {
  const [Loading, setLoading] = useState(false);
  const [LoadingSubmit, setLoadingSubmit] = useState(false);
  const [CurrentGroupID, setCurrentGroupID] = useState();
  const [GroupListMembersList, setGroupListMembersList] = useState([]);
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [
    RemoveMoreButtionGroupListMembersList,
    setRemoveMoreButtionGroupListMembersList,
  ] = useState(false);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [GroupListMembersListPageNumber, setGroupListMembersListPageNumber] =
    useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFollower, setsearchFollower] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [input, setInput] = useState({
    no_of_people: "",
  });

  const [MemberConnection, setMemberConnection] = useState([]);

  async function getGroupMembersList(pageno) {
    setIsLoading(true);
    let body = {
      group_id: props?.CurrentGroupID?.group_id,
      // page: pageno,
      // limit: 10,
      search: searchQuery,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_GET_GROUP_MEMBERS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setIsLoading);
    if (response?.isValidate) {
      if (pageno === 1 || pageno === undefined) {
        setGroupListMembersList(response?.data?.data?.list);
      } else {
        setGroupListMembersList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionGroupListMembersList(true);
      } else {
        setRemoveMoreButtionGroupListMembersList(false);
      }
      if (response?.data?.data?.totalPages > pageno) {
        setGroupListMembersListPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  const handleLoadMoreGroupListMembersList = () => {
    getGroupMembersList(GroupListMembersListPageNumber);
  };

  useEffect(() => {
    getGroupMembersList(1);
  }, [searchQuery]);

  
  return (
    <>
      <Modal
        className="clubactivity-modal-main  invite-only-player-modal-main-wrapper min-heighted-modal"
        size="md"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.CurrentGroupID?.title}'s Members
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body id="global-search">
          <div className="invite-friends-main">
            {isLoading && !GroupListMembersList?.length > 0 ? (
              <Loader type={LoaderType.InviteFriend} count={4} />
            ) : (
              <>
                {GroupListMembersList?.length > 0 ? (
                  GroupListMembersList.map((item, index) => (
                    <div key={index} className="invite-friends-single align-items-start">
                      <div className="invite-friends-single-img-name">
                        {item?.profile_pic !== null &&
                        item?.profile_pic !== "" &&
                        item?.profile_pic !== undefined ? (
                          <img
                            src={item?.profile_pic}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                            className="invite-friends-single-image"
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt="club-detail-image"
                            className="invite-friends-single-image"
                          />
                        )}
                        <div>
                          <h5
                            className={
                              item?.member_type === "Standard"
                                ? "profile-standard-after-initials-title border-0 m-0 p-0"
                                : item?.member_type === "Premium"
                                ? "profile-premium-after-initials-title border-0 m-0 p-0"
                                : "border-0 m-0 p-0"
                            }
                          >
                            {item?.member_name}
                          </h5>
                          <p>{item?.nickname}</p>
                          {item?.ownership !== "group_admin" && (
                            <p
                              className={
                                item?.action_status === "pending"
                                  ? "font-yellow"
                                  : item?.action_status === "waiting"
                                  ? "font-yellow"
                                  : "font-green"
                              }
                            >
                              {item?.action_status}
                            </p>
                          )}
                        </div>
                      </div>
                        {item?.ownership === "group_admin" && <p>Owner</p>}
                    </div>
                  ))
                ) : (
                  <Loader type={LoaderType.NODATAFOUND} />
                )}
              </>
            )}
            {isLoading && GroupListMembersList?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={4} />
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GroupMembersModal;
