import React, { useState } from "react";
import { Range, getTrackBackground } from "react-range";

const RangeRatting = (props) => {
  const [values, setValues] = useState([100]);
  const MIN1 = 100;
  const MAX1 = 500;
  const RangeNumber1 = [];
  for (let i = MIN1; i <= MAX1; i += MIN1) {
    RangeNumber1.push(i);
  }
  return (
    <div className="slidermain-with-title">
      <div className="slidermain-with-title-header">
        <h5 className="m-0">{props.item.value}</h5>
        <span>
          <svg
            key={props.index}
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            viewBox="0 0 22 20"
            fill="#FFB500"
          >
            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
          </svg>{" "}
          {/* {selectedRating[item.term_id] !== undefined &&
                          selectedRating[item.term_id] !== "" &&
                          selectedRating[item.term_id] !== null
                            ? selectedRating[item.term_id]
                            : 1} */}
          {values[0] / 100}
        </span>{" "}
      </div>
      <div className="range-parent">
        <div
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            background: "#eee",
            borderRadius: "25px",
          }}
        >
          <Range
            values={values}
            // step={STEP1}
            min={MIN1}
            max={MAX1}
            // onChange={(newValues) => {
            //   setValues(newValues);
            //   fnCustomRangeSelect(
            //     newValues[0] / 100,
            //     item.term_id
            //   );
            // }}
            onFinalChange={(newValues) => {
              if (props?.type === "dot") {
                props?.onFinalChangeData(newValues[0] / 100);
              } else {
                props?.onFinalChangeData(newValues[0]);
              }

              //   fnCustomRangeSelect(newValues[0] / 100, item.term_id);
              // handleRatingChange(item, newValues[0] / 100);
            }}
            onChange={(newValues) => {
              setValues(newValues);
            }}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "24px",
                  display: "flex",
                  width: "100%",
                  // padding: "10px"
                }}
                className=""
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "18px",
                    width: "100%",
                    borderRadius: "30px",
                    background: getTrackBackground({
                      values,
                      colors: ["#ef333f", "#eee"],
                      min: MIN1,
                      max: MAX1,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>

                <div className="dot-range-parent">
                  {RangeNumber1.map((item, index) => (
                    <span
                      className="dot-range"
                      style={
                        index === 0 || index === 4
                          ? {
                              background: "transparent",
                            }
                          : {
                              background:
                                values[0] / 100 - 1 < index ? "black" : "white",
                            }
                      }
                    ></span>
                  ))}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "20px",
                  width: "20px",
                  borderRadius: "50%",
                  backgroundColor: "#ef333f",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 2px 6px #AAA",
                }}
              >
                <div
                  style={{
                    height: "5px",
                    borderRadius: "50%",
                    width: "5px",
                    backgroundColor: isDragged ? "#fff" : "#fff",
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
      <div className="d-flex justify-content-between redrange w-100 mt-1">
        {RangeNumber1.map((item1, index1) => (
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill={values[0] >= item1 ? "#FFB500" : "#BBBBBB"}
            >
              <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
            </svg>
          </>
        ))}
      </div>
    </div>
  );
};

export default RangeRatting;
