import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import forgotPasswordImage from "../images/forgotPassword.svg";
import leftArrow from "../images/leftArrow.svg";
import { commonservices } from "../services/CommonServices";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
import ForgotPasswordModal from "../Modal/ForgotPasswordModal";
import { useSelector } from "react-redux";
import PhoneCodeModal from "../Modal/PhoneCodeModal";
// import ForgotPasswordModal from "../Modal/ForgotPasswordModal";

const Forgot_Password = () => {
  const [Loading, setLoading] = useState(false);
  const [PhoneCodeOTP, setPhoneCodeOTP] = useState();
  const [OTPType, setOTPType] = useState('');
  const [PhoneCountryCode, setPhoneCountryCode] = useState(1);
  const [PhoneCountryID, setPhoneCountryID] = useState(231);
  const [PhoneCodeModalShow, setPhoneCodeModalShow] = useState(false);
  // const [EmailCodeOTP, setEmailCodeOTP] = useState();
  const [EmailCode, setEmailCode] = useState();
  let navigate = useNavigate();
  const [ForgotPasswordModalShow, setForgotPasswordModalShow] = useState(false);
  const [Country_list, setCountry_list] = useState([]);
  const [input, setInput] = useState({
    member_name: "",
    social_type_term: "",
    email: "",
    phone: "",
    // country_code: "",
    password: "",
    confirm_password: "",
    profile_pic: "",
    is_phone_verified: "",
    errors: {
      member_name: "",
      social_type_term: "",
      email: "",
      phone: "",
      // country_code: "",
      password: "",
      profile_pic: "",
      is_phone_verified: "",
      // ValidationRules: [
      //   {
      //     FieldName: "firstName",
      //     ValidationType: "required",
      //     ValidationMessage: "This Field is a required field",
      //   },
      //   {
      //     FieldName: "lastName",
      //     ValidationType: "required",
      //     ValidationMessage: "This Field is a required field",
      //   },
      //   {
      //     FieldName: "email",
      //     ValidationType: "required",
      //     ValidationMessage: "This Field is a required field",
      //   },
      //   {
      //     FieldName: "password",
      //     ValidationType: "required",
      //     ValidationMessage: "This Field is a required field",
      //   },
      //   {
      //     FieldName: "mobile",
      //     ValidationType: "required",
      //     ValidationMessage: "This Field is a required field",
      //   },
      // ],
    },
  });

  async function getCountryCode() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_COUNTRY_LIST,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCountry_list(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });

    if (obj.isValid) {
      if (input.email !== "") {
        localStorage.setItem("forget_email", input.email);
        setEmailCode(input.email);
        let body = {
          email: input.email.trim(),
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.FORGOT_PASSWORD,
            body: body,
          },
          true
        );

        let response = apiResponse(true, resData, setLoading);

        if (response?.isValidate) {
          setForgotPasswordModalShow(true);
          setOTPType('email')
          // console.log("Success AAAAAA::", response);
        }

        if (!response?.isValidate) {
          console.log("Error in forgot passowrd::", response);
        }
      } else {
        // debu
        localStorage.setItem("forget_phone", input.phone);
        localStorage.setItem("forget_code", PhoneCountryCode);
        let body = {
          phone: input.phone.trim(),
          // country_code:
          //   input.country_code.trim() !== "" ? input.country_code.trim() : "1",
          country_code: PhoneCountryCode.toString(),
        };

        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.FORGOT_PASSWORD,
            body: body,
          },
          true
        );

        let response = apiResponse(true, resData, setLoading);

        if (response?.isValidate) {
          setForgotPasswordModalShow(true);
          setOTPType('phone')

          let temp = [];
          temp.push({
            // code:
            //   input.country_code.trim() !== ""
            //     ? input.country_code.trim()
            //     : "1",
            code: PhoneCountryCode.toString(),
            phone: input.phone.trim(),
            otp: response.data.data.otp,
          });
          setPhoneCodeOTP(temp);
        }
        if (!response?.isValidate) {
          // console.log("Error  AAAAAAA::", response);
        }
      }
    }
  };

  useEffect(() => {
    getCountryCode();
  }, []);
  const [showForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const handleForgotPasswordModal = () => {
    setForgotPasswordModal(true);
  };

  const closeForgotPasswordModal = () => {
    setForgotPasswordModal(false);
  };

  return (
    <>
      <div className="sign-in-main forgot-password-main">
        <Container>
          <div className="sign-in-wrapper">
            <div className="d-flex justify-content-between align-items-center title-with-arrow">
              <img
                src={leftArrow}
                alt="previous"
                className="previous-step"
                onClick={() => navigate("/sign-in")}
              />
              <h3 className="text-center m-0  page-head">Forgot Password</h3>
              <img
                src={leftArrow}
                alt="previous"
                style={{ visibility: "hidden" }}
              />
            </div>
            <div className="sign-in-inner-main">
              <div className="sign-in-inner-all">
                <div className="sign-in-logo text-center">
                  <img src={forgotPasswordImage} alt="forgotPasswordImage" />
                </div>
                <div className="sign-in-inputs">
                  <p className="medium-text">
                    Enter the email associated with your account and we’ll send
                    an email with instructions to reset your password.
                  </p>
                  <Form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    {input.email === "" && (
                      <>
                        <InputGroup>
                          {/* <Form.Select
                            aria-label="select country_code"
                            className="countr-code"
                            onChange={(e) => {
                              setInput({
                                ...input,
                                ["country_code"]: e.target.value,
                              });
                            }}
                            isInvalid={input.errors.country_code}
                          >
                            <option>+1</option>
                            {Country_list?.length > 0 &&
                              Country_list.map((item, index) => (
                                <option key={index} value={item.phonecode}>
                                  {"+" + item.phonecode}
                                </option>
                              ))}
                            {input.errors.country_code && (
                              <Form.Control.Feedback type="invalid">
                                {input.errors.country_code}
                              </Form.Control.Feedback>
                            )}
                          </Form.Select> */}
                          <Button
                            className="countr-code"
                            onClick={() => setPhoneCodeModalShow(true)}
                          >
                            +{PhoneCountryCode}
                          </Button>
                          <Form.Control
                            type="text"
                            
                            placeholder="Enter your Mobile Number"
                            value={input.phone}
                            onChange={(e) =>
                              setInput({
                                ...input,
                                ["phone"]: e.target.value,
                              })
                            }
                            isInvalid={input.errors.phone}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </InputGroup>
                        {input.errors.phone && (
                          <Form.Control.Feedback type="invalid">
                            {input.errors.phone}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}

                    {input.phone === "" &&
                      // input.country_code === "" &&
                      input.email === "" && (
                        <p className="small-text text-center">OR</p>
                      )}

                    {input.phone === "" && 
                    // input.country_code === "" &&
                     (
                      <>
                        <Form.Control
                          type="email"
                          placeholder="Enter your Email Address"
                          value={input.email}
                          onChange={(e) =>
                            setInput({
                              ...input,
                              ["email"]: e.target.value,
                            })
                          }
                          isInvalid={input.errors.email}
                        />
                        {input.errors.email && (
                          <Form.Control.Feedback type="invalid">
                            {input.errors.email}
                          </Form.Control.Feedback>
                        )}
                      </>
                    )}

                    <div className="text-center get-otp-btn">
                      {Loading ? (
                        <button type="submit" className="red-btn w-100">
                          Loading...
                        </button>
                      ) : (
                        <button type="submit" className="red-btn w-100">
                          Get OTP
                        </button>
                      )}
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Container>

        {ForgotPasswordModalShow && (
          <ForgotPasswordModal
            show={ForgotPasswordModalShow}
            PhoneCodeOTP={PhoneCodeOTP}
            OTPType={OTPType}
            EmailCode={EmailCode}
            // OTPData={OTPData}
            SignUpData={undefined}
            onHide={() => setForgotPasswordModalShow(false)}
          />
        )}
        {PhoneCodeModalShow && (
          <PhoneCodeModal
            // show={PhoneCodeModalShow}
            // CountryList={Country_list}
            // PhoneCode={setPhoneCountryCode}
            show={PhoneCodeModalShow}
            CountryList={Country_list}
            PhoneCode={setPhoneCountryCode}
            CountryID={setPhoneCountryID}
            
            onHide={() => setPhoneCodeModalShow(false)}
          />
        )}
      </div>
    </>
  );
};

export default Forgot_Password;
