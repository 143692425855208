import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import logo from "../images/onlylogo.svg";
import imagePlaceholder from "../images/placeholder.png";
import yellowLogo from "../images/yellowLogo.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import MembershipBuyModal from "./MembershipBuyModal";
import moment from "moment";
import Loader, { LoaderType } from "../components/Loader";

function SubsciptionModal(props) {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [Subscriptiondata, setSubscriptiondata] = useState("");
  const [SubscribebtnLoader, setSubscribebtnLoader] = useState(false);
  const [SubscribeType, setSubscribeType] = useState("");
  const [Membershipmodalshow, setMembershipmodalshow] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [MembershipDetail, setMembershipDetail] = useState("");
  async function getSubscriptionList() {
    setIsLoading(true);
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_LIST,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setSubscriptionList(response.data.data.list);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fnSubscribe(type) {
    if (Subscriptiondata !== "") {
      // setMembershipmodalshow(true);
      setSubscribebtnLoader(true);
      let body = {
        session_type: "app_member_level",
        // cancel_url: "http://localhost:3000/settings",
        cancel_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
        package_id: Subscriptiondata?.subscription_id,
        // package_price_id: Subscriptiondata?.package_price_id,
        success_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CLUB_SESSION_CREATE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setSubscribebtnLoader);
      if (response?.isValidate) {
        if (
          response?.data?.data?.url !== undefined &&
          response?.data?.data?.url !== null
        ) {
          window.open(response.data.data.url, "_self");
        }
        props?.onHide();
        props?.bindList();
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
    if (SubscribeType === "cancel") {
      setMembershipmodalshow(true);
    }
    setSubscribeType(type);
  }

  async function getSubscriptionDetails() {
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_DETAILS,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setMembershipDetail(response?.data?.data);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }

  useEffect(() => {
    getSubscriptionList();
    getSubscriptionDetails();
  }, []);

  return (
    <div className="SubsciptionModal-Main">
      <Modal className="subscription-modal-main" size="lg" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Subscription
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="settings-memberships-main subcription-modal-membership">
            <div className="subcription-modal-membership-top-text text-center mb-0">
              {props?.SubscribeModalValue !== undefined && (
                <>
                  <p>Buy subscription to book this club at</p>

                  <h6>
                    ${props?.SubscribeModalValue?.discount_min_price.toFixed(2)}
                    -$
                    {props?.SubscribeModalValue?.discount_max_price.toFixed(
                      2
                    )}{" "}
                    <span>
                      (${props?.SubscribeModalValue?.min_price.toFixed(2)}-$
                      {props?.SubscribeModalValue?.max_price.toFixed(2)})
                    </span>
                  </h6>
                </>
              )}
            </div>

            <div className="s">
              {props.Tag === "settingPage" ? (
                <div className="">
                  <div className="settings-memberships-main">
                    {/* <h5>Memberships</h5> */}
                    <div className="settings-memberships-inner">
                      {isLoading ? (
                        <Loader type={LoaderType.HomePost} count={1} />
                      ) : (
                        <div>
                          {SubscriptionList?.length > 0 ? (
                            SubscriptionList?.map((s_item, s_index) => (
                              <div
                                className="plan-premium-main"
                                style={{ background: "#ffffff" }}
                              >
                                <div className="plan-header">
                                  <div>
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="plan-header-logo">
                                        <img src={logo} alt="logo" />
                                      </div>
                                      <div className="plan-name">
                                        <h6>{s_item?.subscription_name}</h6>
                                        {/* <p>Trainer</p> */}
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div
                                  className="booking-status-badge"
                                  style={{ background: "#EF333F" }}
                                >
                                  <p>Popular</p>
                                </div> */}
                                </div>
                                <div className="plan-body-main">
                                  {s_item?.prices?.length > 0 &&
                                    s_item?.prices.map((su_item, su_index) => (
                                      <div
                                        className="plan-detail-single"
                                        key={su_index}
                                      >
                                        {/* <h6>
                                                ${su_item?.price}{" "}
                                                <span>
                                                  /{su_item?.interval_type}
                                                </span>
                                              </h6> */}
                                        {/* <Form.Check
                                                type="checkbox"
                                                className="circular-checkbox"
                                              /> */}
                                        <div className="red-radio-btn plan-detail-check-box">
                                          {/* <Form.Check
                                            type="radio"
                                            disabled={
                                              parseInt(
                                                props?.SubscribeModalData
                                                  ?.subscriptions[0].price
                                              ) === parseInt(su_item?.price)
                                                ? true
                                                : false
                                            }
                                            name="gender"
                                            label={
                                              su_item?.price +
                                              "/" +
                                              su_item?.interval_type
                                            }
                                            id={su_item?.price}
                                            value={su_item?.price}
                                            // checked={
                                            //   input.gender_type_term ===
                                            //   su_item?.price
                                            // }
                                            // onChange={(e) => {
                                            //   setSubscribePrice(
                                            //     e.target.value
                                            //   );
                                            // }}
                                            onClick={() =>
                                              setSubscriptiondata(su_item)
                                            }
                                          /> */}
                                          <Form.Check
                                            type="radio"
                                            disabled={props?.SubscribeModalData?.subscriptions.some(
                                              (subscription) =>
                                                parseInt(subscription.price) ===
                                                  parseInt(su_item?.price) &&
                                                subscription?.subscription_name ===
                                                  s_item?.subscription_name
                                            )}
                                            name="gender"
                                            label={`${su_item?.price}/${su_item?.interval_type}`}
                                            id={su_item?.price}
                                            value={su_item?.price}
                                            onClick={() => {
                                              setSubscriptiondata(su_item);
                                              // debugger;
                                            }}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>

                                {/* <div className="plan-detail-badge">
                                    <span className="dark-badge d-block text-center">
                                      This package includes Standard Package &
                                      Trainer benefits
                                    </span>
                                  </div> */}
                                <div className="membership-elements-all mt-0">
                                  {s_item?.benefits?.length > 0 &&
                                    s_item?.benefits.map((s_item1, s_index) => (
                                      <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                        <div className="plan-header-logo">
                                          {s_item1?.term_icon !== null &&
                                          s_item1?.term_icon !== "" &&
                                          s_item1?.term_icon !== undefined ? (
                                            <img
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              src={s_item1?.term_icon}
                                              alt="club-detail-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  imagePlaceholder;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={imagePlaceholder}
                                              alt="club-detail-image"
                                            />
                                          )}
                                        </div>
                                        <div className="plan-name">
                                          <h4>{s_item1?.term_name}</h4>
                                          <p>{s_item1?.term_details}</p>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            ))
                          ) : (
                            <Loader type={LoaderType.NODATAFOUND} />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mt-3 sticky-footer-btn pb-3">
                      <button
                        className="red-btn red-border w-100"
                        onClick={() => fnSubscribe("buy")}
                      >
                        {SubscribebtnLoader ? "Loading..." : "Subscribe"}
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {LoginInfo?.is_subscriber === 1 ? (
                    <>
                      {MembershipDetail?.subscriptions?.length > 0 &&
                        MembershipDetail?.subscriptions?.map(
                          (item_ss, index_ss) => (
                            <div className="settings-has-memberships-main">
                              <div className="settings-wallet-header">
                                <h5 className="text-center">Membership</h5>
                                <div
                                  className="my-single-booking align-items-center"
                                  style={{ background: "transparent" }}
                                >
                                  <div className="court1Image">
                                    <img src={yellowLogo} alt="logo" />
                                  </div>
                                  <div className="courtContent court-and-court-name">
                                    <h5 className="border-0 m-0 p-0">
                                      {item_ss.subscription_name} Membership
                                    </h5>
                                    <p className="font-white">
                                      Your Membership Expires on{" "}
                                      {moment(item_ss.end_date).format(
                                        "DD MMM, yyyy"
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="settings-has-memberships-bottom">
                                <div className="membership-element-single-wrap-up">
                                  {/* MembershipData */}
                                  <div className="membership-element-single-diff-para">
                                    <p>
                                      ${item_ss.price}{" "}
                                      <span>/{item_ss.interval_type}</span>
                                    </p>
                                  </div>

                                  {item_ss.benefits?.length > 0 ? (
                                    item_ss.benefits.map((s_item, s_index) => (
                                      <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                        <div className="plan-header-logo">
                                          {s_item?.term_icon !== null &&
                                          s_item?.term_icon !== "" &&
                                          s_item?.term_icon !== undefined ? (
                                            <img
                                              style={{
                                                width: "30px",
                                                height: "30px",
                                              }}
                                              src={s_item?.term_icon}
                                              alt="club-detail-image"
                                              onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src =
                                                  imagePlaceholder;
                                              }}
                                            />
                                          ) : (
                                            <img
                                              src={imagePlaceholder}
                                              alt="club-detail-image"
                                            />
                                          )}
                                        </div>
                                        <div className="plan-name">
                                          <h4>{s_item?.term_name}</h4>
                                          <p>{s_item?.term_details}</p>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <Loader type={LoaderType.NODATAFOUND} />
                                  )}
                                </div>
                                {/* <div className="mt-3 d-flex     justify-content-between">
                               <Button
                                 className="red-btn red-border "
                                 onClick={() =>
                                   fnSubscribeModal(MembershipDetail)
                                 }
                               >
                                 Manage
                               </Button>
                               <Button
                                 className="red-btn red-border "
                                 onClick={() => fnSubscribe("cancel")}
                               >
                                 Cancel
                               </Button>
                             </div> */}
                              </div>
                            </div>
                          )
                        )}
                      <div className="mt-3 d-flex     justify-content-between">
                        {/* <Button
                                 className="red-btn red-border "
                                 onClick={() =>
                                   fnSubscribeModal(MembershipDetail)
                                 }
                               >
                                 Manage
                               </Button> */}
                        <Button
                          className="red-btn red-border "
                          onClick={() => fnSubscribe("cancel")}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  ) : (
                    <div className="">
                      <div className="settings-memberships-main">
                        <div className="settings-memberships-inner">
                          {isLoading ? (
                            <Loader type={LoaderType.HomePost} count={1} />
                          ) : (
                            <div>
                              {SubscriptionList?.length > 0 ? (
                                SubscriptionList?.map((s_item, s_index) => (
                                  <div
                                    className="plan-premium-main"
                                    style={{ background: "#ffffff" }}
                                  >
                                    <div className="plan-header">
                                      <div>
                                        <div className="d-flex align-items-center gap-2">
                                          <div className="plan-header-logo">
                                            <img src={logo} alt="logo" />
                                          </div>
                                          <div className="plan-name">
                                            <h6>{s_item?.subscription_name}</h6>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="plan-body-main">
                                      {s_item?.prices?.length > 0 &&
                                        s_item?.prices.map(
                                          (su_item, su_index) => (
                                            <div
                                              className="plan-detail-single"
                                              key={su_index}
                                            >
                                              <div className="red-radio-btn plan-detail-check-box">
                                                <Form.Check
                                                  type="radio"
                                                  name="gender"
                                                  label={
                                                    su_item?.price +
                                                    "/" +
                                                    su_item?.interval_type
                                                  }
                                                  id={su_item?.price}
                                                  value={su_item?.price}
                                                  onClick={() =>
                                                    setSubscriptiondata(su_item)
                                                  }
                                                />
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                    <span
                                      className="dark-badge mb-3 d-flex m-auto  having-list-inside"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          s_item?.prices[0]
                                            ?.subscription_details,
                                      }}
                                    ></span>

                                    <div className="membership-elements-all mt-0">
                                      {s_item?.benefits?.length > 0 &&
                                        s_item?.benefits.map(
                                          (s_item1, s_index) => (
                                            <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                              <div className="plan-header-logo">
                                                {s_item1?.term_icon !== null &&
                                                s_item1?.term_icon !== "" &&
                                                s_item1?.term_icon !==
                                                  undefined ? (
                                                  <img
                                                    style={{
                                                      width: "30px",
                                                      height: "30px",
                                                    }}
                                                    src={s_item1?.term_icon}
                                                    alt="club-detail-image"
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null;
                                                      currentTarget.src =
                                                        imagePlaceholder;
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={imagePlaceholder}
                                                    alt="club-detail-image"
                                                  />
                                                )}
                                              </div>
                                              <div className="plan-name">
                                                <h4>{s_item1?.term_name}</h4>
                                                <p>{s_item1?.term_details}</p>
                                              </div>
                                            </div>
                                          )
                                        )}
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <Loader type={LoaderType.NODATAFOUND} />
                              )}
                            </div>
                          )}
                        </div>
                        <div className="mt-3 sticky-footer-btn pb-3">
                          <button
                            className="red-btn red-border w-100"
                            onClick={() => fnSubscribe("buy")}
                          >
                            Subscribe
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {Membershipmodalshow && (
        <MembershipBuyModal
          Subscriptiondata={Subscriptiondata}
          SubscriptionList={SubscriptionList}
          MembershipDetail={MembershipDetail}
          show={Membershipmodalshow}
          SubscribeType={SubscribeType}
          onHide={() => setMembershipmodalshow(false)}
        />
      )}
    </div>
  );
}

export default SubsciptionModal;
