import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Row,
  Spinner,
} from "react-bootstrap/esm";
import { confirmAlert } from "react-confirm-alert";
import Slider from "react-slick";
import add from "../images/circularAdd.jpg";
import editProfilePencil from "../images/editProfilePencil.svg";
import comment from "../images/comment.svg";
import like from "../images/like.svg";
import liked from "../images/liked.png";
import imagePlaceholder from "../images/placeholder.png";
import redAdd from "../images/redAdd.png";
import block from "../images/block.png";
import deleteIcon from "../images/deleteIcon.svg";
import share from "../images/shareIcon.svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import CommentsModal from "../Modal/CommentsModal";
import FollowersAndFollowingModal from "../Modal/FollowersAndFollowingModal";
import HomeReportModal from "../Modal/HomeReportModal";
import Loader, { LoaderType } from "../components/Loader";
import rightArrow from "../images/rightArrow.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import SuggestedForModal from "../Modal/SuggestedForModal";
import StarRateModal from "../Modal/StarRateModal";
import ShareProfileModal from "../Modal/ShareProfileModal";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";

function ProfileDetail() {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  let param = useParams();
  let navigate = useNavigate();
  const [ProfileDetail, setProfileDetail] = useState();
  const [FollowButtonLoader, setFollowButtonLoader] = useState(false);
  const [BlockedLoader, setBlockedLoader] = useState(false);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [StarRateModalShow, setStarRateModalShow] = useState(false);
  const [RateType, setRateType] = useState();
  const [ShareProfileData, setShareProfileData] = useState();
  const [ShareProfileModalShow, setShareProfileModalShow] = useState(false);
  const [SuggestedModalShow, setSuggestedModalShow] = useState(false);
  const [CommentModalShow, setCommentModalShow] = useState(false);
  const [RateModalType, setRateModalType] = useState("");
  const [ReportModalShow, setReportModalShow] = useState(false);
  const [BookingDetail, setBookingDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [HomePost, setHomePost] = useState([]);
  const [RateCourtModalShow, setRateCourtModalShow] = useState(false);
  const [RecentBooking, setRecentBooking] = useState([]);
  const [CommentPost, setCommentPost] = useState();
  const [ReportPost, setReportPost] = useState();
  const [ModalType, setModalType] = useState("");
  const [error, setError] = useState(null);
  const [SuggestedPeople, setSuggestedPeople] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [FollowType, setFollowType] = useState();
  // const [showFollowersAndFollowingModal, setFollowersAndFollowingModal] =
  //   useState(false);
  const [FollowersAndFollowingModalShow, setFollowersAndFollowingModalShow] =
    useState(false);
  const handleFollowersAndFollowingModal = (type) => {
    setFollowersAndFollowingModalShow(true);
    setFollowType(type);
  };

  // const closeFollowersAndFollowingModal = () => {
  //   setFollowersAndFollowingModal(false);
  // };
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  async function getHomePostList(pageno) {
    // setLoading(true);
    setIsLoading(true);
    setError(null);
    try {
      let body = {
        member_id: param.id,
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_LIST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        if (pageno === 1) {
          setHomePost(response?.data?.data?.list);
        } else {
          setHomePost((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  async function getSuggestedpeople() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SUGGESTED_PEOPLE,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setSuggestedPeople(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnLikeDeslikes(item, value) {
    let body = {
      post_id: item.post_id,
      is_like: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_LIKE_DISLIKE_POST,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnInterest(item, value) {
    let body = {
      post_id: item.post_id,
    };
    let body1 = {
      interest_id: item.interest_id,
    };
    // console.log('body',body,value);
    // return
    if (value === "add") {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_ADD_INTEREST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_REMOVE_INTEREST,
          body: body1,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  }
  async function fnDeletePost(item) {
    let body = {
      post_id: item.post_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_DELETE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  async function getMemberProfile() {
    setLoading(true);
    let body = {
      member_id: param.id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_MEMBER_PROFILE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setProfileDetail(response?.data?.data?.user);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnBlocked(item) {
    setBlockedLoader(true);
    let body = {
      member_id: item?.member_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_BLOCK,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setBlockedLoader);
    if (response?.isValidate) {
      getHomePostList();
      navigate("/");
      // setFollowingMembersList(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  function fnCommentModal(item, type) {
    setCommentPost(item);
    setCommentModalShow(true);
    setModalType(type);
  }

  function fnReportModal(item) {
    setReportModalShow(true);
    setReportPost(item);
  }

  async function fnAddRemoveFollowing(item, value) {
    setFollowButtonLoader(true);
    let body = {
      receiver_member_id: item?.member_id,
      follow: value,
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setFollowButtonLoader);
    if (response?.isValidate) {
      // getFollowerMembers();
      // getFollowingMembers();
      getMemberProfile();

      // setFollowingMembersList(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    getMemberProfile();
    getHomePostList(pageNumber);
    getSuggestedpeople();
    // getSuggestedpeople();
    // getRecentBooking();
  }, []);

  const handleLoadMore = () => {
    getHomePostList(pageNumber);
  };

  

  function fnRedirectProfile(data) {
    if (LoginInfo?.association_id === data.member_id) {
      navigate("/settings");
      // onClick={() => navigate("/my-booking")}
    }
  }

  function fnStarRate(type) {
    setStarRateModalShow(true);
    setRateType(type);
  }

  function fnShareProfile(data) {
    setShareProfileData(data);
    setShareProfileModalShow(true);
  }

  return (
    <div className="profile-detail-page-main">
      {BlockedLoader && <LoaderOverlay />}
      <Container className="small-container homeDashboardMain">
        <Row>
          <Col xl={7} md={7}>
            {isLoading ? (
              <Loader type={LoaderType.HomePost} count={6} />
            ) : HomePost.length > 0 ? (
              HomePost?.map((item, index) => (
                <>
                  <div className="all-post-section-main">
                    {item?.type_of_post === "upload_score" ? (
                      <div className="single-post-wrapper FindClubCard-main">
                        <div className="single-post-top">
                          <Link
                            className="FindClubCard-main"
                            to={{
                              pathname: "/profile-detail/" + item.member_id,
                            }}
                          >
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {item?.profile_pic !== null &&
                                item?.profile_pic !== "" &&
                                item?.profile_pic !== undefined ? (
                                  <img
                                    src={item?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                <h5>
                                  {item?.member_name}{" "}
                                  <span>shared a score</span>
                                </h5>
                                <p>{moment(item?.created_at).fromNow()} </p>
                              </div>
                            </div>
                          </Link>
                          <div>
                            <Dropdown className="header-dropdown">
                              <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                              <Dropdown.Menu>
                                {LoginInfo?.association_id ===
                                  item?.member_id && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to delete this?",
                                        message: "This action cannot be undone",
                                        buttons: [
                                          {
                                            label: "No",
                                          },
                                          {
                                            label: "Yes",
                                            onClick: () => fnDeletePost(item),
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    <span className="d-flex align-items-center gap-1">
                                      <img src={deleteIcon} alt="deleteIcon" />
                                      Delete
                                    </span>
                                  </Dropdown.Item>
                                )}
                                {LoginInfo?.association_id !==
                                  item?.member_id && (
                                  <Dropdown.Item
                                    onClick={() => fnReportModal(item)}
                                  >
                                    Report
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div
                          className="single-post-middle"
                          onClick={() => fnCommentModal(item, "comment")}
                        >
                          <div className="post-section">
                            <div className="recent-result-wrapper-main">
                              <div className="recent-result-wrapper">
                                {item?.score_details.score.length > 0 &&
                                  item?.score_details.score.map(
                                    (s_item, s_index) => (
                                      <div className="recent-result-single-team">
                                        <p className="team-name">
                                          {s_item.team_name}
                                        </p>
                                        <div className="two-players-section">
                                          {s_item?.players.length > 0 &&
                                            s_item.players.map(
                                              (p_item, p_index) => (
                                                <div className="single-players-section">
                                                  {p_item.player_profile !==
                                                    null &&
                                                  p_item.player_profile !==
                                                    "" &&
                                                  p_item.player_profile !==
                                                    undefined ? (
                                                    <img
                                                      src={
                                                        p_item.player_profile
                                                      }
                                                      alt="club-detail-image"
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          imagePlaceholder;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={imagePlaceholder}
                                                      alt="club-detail-image"
                                                    />
                                                  )}
                                                  <h6>{p_item.player_name}</h6>
                                                  <p>
                                                    {p_item.avg_rating.toFixed(
                                                      1
                                                    )}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div className="recent-result-scores-wrapper">
                                          {s_item?.score?.map(
                                            (ss_item, ss_index) => (
                                              <h6
                                                className="recent-result-scores"
                                                key={ss_index}
                                              >
                                                {ss_item}
                                              </h6>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-post-bottom">
                          <div className="like-and-comment">
                            <div className="like-with-like-number">
                              <img
                                src={item?.is_like === 1 ? liked : like}
                                alt="like"
                                onClick={() =>
                                  fnLikeDeslikes(
                                    item,
                                    item?.is_like === 1 ? 0 : 1
                                  )
                                }
                              />
                              {item?.total_likes > 0 ? (
                                <p onClick={() => fnCommentModal(item, "like")}>
                                  {item?.total_likes} likes
                                </p>
                              ) : (
                                <p>{item?.total_likes} likes</p>
                              )}
                            </div>

                            <div
                              className="like-with-like-number"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <img src={comment} alt="like" />
                              <p>{item?.total_comment} comments</p>
                            </div>
                            {item?.total_interested_user > 0 && (
                              <div className="like-with-like-number">
                                <img
                                  onClick={() =>
                                    fnInterest(
                                      item,
                                      item?.is_interested === 1
                                        ? "remove"
                                        : "add"
                                    )
                                  }
                                  src={item?.is_interested === 1 ? redAdd : add}
                                  alt="like"
                                />
                                <p
                                  onClick={() =>
                                    fnCommentModal(item, "interested")
                                  }
                                >
                                  {item?.total_interested_user} Interested
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : item?.type_of_post === "normal" ? (
                      <>
                        <div className="single-post-wrapper FindClubCard-main">
                          <div className="single-post-top">
                            <Link
                              className="FindClubCard-main"
                              to={{
                                pathname: "/profile-detail/" + item.member_id,
                              }}
                            >
                              <div className="d-flex align-items-center profile-and-time">
                                <div className="write-something-profile">
                                  {item?.profile_pic !== null &&
                                  item?.profile_pic !== "" &&
                                  item?.profile_pic !== undefined ? (
                                    <img
                                      src={item?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                                <div className="username-with-time">
                                  <h5>
                                    {item?.member_name}{" "}
                                    <span>shared a post</span>
                                  </h5>
                                  <p>{moment(item?.created_at).fromNow()}</p>
                                </div>
                              </div>
                            </Link>
                            <div>
                              <Dropdown className="header-dropdown">
                                <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {LoginInfo?.association_id ===
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        confirmAlert({
                                          title:
                                            "Are you sure you want to delete this?",
                                          message:
                                            "This action cannot be undone",
                                          buttons: [
                                            {
                                              label: "No",
                                            },
                                            {
                                              label: "Yes",
                                              onClick: () => fnDeletePost(item),
                                            },
                                          ],
                                        })
                                      }
                                    >
                                      <span className="d-flex align-items-center gap-1">
                                        <img
                                          src={deleteIcon}
                                          alt="deleteIcon"
                                        />
                                        Delete
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                  {LoginInfo?.association_id !==
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() => fnReportModal(item)}
                                    >
                                      Report
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div
                            className="single-post-middle"
                            onClick={() => fnCommentModal(item, "comment")}
                          >
                            <div className="post-section">
                              <Slider {...settings}>
                                {item?.media_files?.length > 0 &&
                                  item?.media_files.map((m_item, m_index) => (
                                    <div className="slide-out-of-total">
                                      <p className="slide-out-of-total-para">
                                        {m_index + 1}/{" "}
                                        {item?.media_files?.length}
                                      </p>
                                      <div>
                                        {m_item?.image_path !== null &&
                                        m_item?.image_path !== "" &&
                                        m_item?.image_path !== undefined ? (
                                          <img
                                            src={m_item?.image_path}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </Slider>
                            </div>
                          </div>
                          <div className="single-post-bottom">
                            <div className="like-and-comment">
                              <div className="like-with-like-number">
                                <img
                                  src={item?.is_like === 1 ? liked : like}
                                  alt="like"
                                  onClick={() =>
                                    fnLikeDeslikes(
                                      item,
                                      item?.is_like === 1 ? 0 : 1
                                    )
                                  }
                                />
                                {item?.total_likes > 0 ? (
                                  <p
                                    onClick={() => fnCommentModal(item, "like")}
                                  >
                                    {item?.total_likes} likes
                                  </p>
                                ) : (
                                  <p>{item?.total_likes} likes</p>
                                )}
                              </div>

                              <div
                                className="like-with-like-number"
                                onClick={() => fnCommentModal(item, "comment")}
                              >
                                <img src={comment} alt="like" />
                                <p>{item?.total_comment} comments</p>
                              </div>
                              {item?.total_interested_user > 0 && (
                                <div className="like-with-like-number">
                                  <img
                                    src={
                                      item?.is_interested === 1 ? redAdd : add
                                    }
                                    alt="like"
                                  />
                                  <p
                                    onClick={() =>
                                      fnCommentModal(item, "interested")
                                    }
                                  >
                                    {item?.total_interested_user} Interested
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="post-detail">
                              <p>
                                {item?.comment !== null ? item?.comment : <></>}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      item?.type_of_post === "interest_activity" && (
                        <>
                          <div className="single-post-wrapper FindClubCard-main">
                            <div className="single-post-top">
                              <Link
                                className="FindClubCard-main"
                                to={{
                                  pathname: "/profile-detail/" + item.member_id,
                                }}
                              >
                                <div className="d-flex align-items-center profile-and-time">
                                  <div className="write-something-profile">
                                    {item?.profile_pic !== null &&
                                    item?.profile_pic !== "" &&
                                    item?.profile_pic !== undefined ? (
                                      <img
                                        src={item?.profile_pic}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </div>
                                  <div className="username-with-time">
                                    <h5>
                                      {item?.member_name}{" "}
                                      <span>shared a post</span>
                                    </h5>
                                    <p>{moment(item?.created_at).fromNow()}</p>
                                  </div>
                                </div>
                              </Link>
                              <div>
                                <Dropdown className="header-dropdown">
                                  <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {LoginInfo?.association_id ===
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this?",
                                            message:
                                              "This action cannot be undone",
                                            buttons: [
                                              {
                                                label: "No",
                                              },
                                              {
                                                label: "Yes",
                                                onClick: () =>
                                                  fnDeletePost(item),
                                              },
                                            ],
                                          })
                                        }
                                      >
                                        <span className="d-flex align-items-center gap-1">
                                          <img
                                            src={deleteIcon}
                                            alt="deleteIcon"
                                          />
                                          Delete
                                        </span>
                                      </Dropdown.Item>
                                    )}
                                    {LoginInfo?.association_id !==
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() => fnReportModal(item)}
                                      >
                                        Report
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div
                              className="single-post-middle"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <div className="post-section">
                                <div className="slide-out-of-total">
                                  {item?.club_details?.profile_pic !== null &&
                                  item?.club_details?.profile_pic !== "" &&
                                  item?.club_details?.profile_pic !==
                                    undefined ? (
                                    <img
                                      src={item?.club_details?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="single-post-bottom">
                              <div className="like-and-comment">
                                <div className="like-with-like-number">
                                  <img
                                    src={item?.is_like === 1 ? liked : like}
                                    alt="like"
                                    onClick={() =>
                                      fnLikeDeslikes(
                                        item,
                                        item?.is_like === 1 ? 0 : 1
                                      )
                                    }
                                  />
                                  {item?.total_likes > 0 ? (
                                    <p
                                      onClick={() =>
                                        fnCommentModal(item, "like")
                                      }
                                    >
                                      {item?.total_likes} likes
                                    </p>
                                  ) : (
                                    <p>{item?.total_likes} likes</p>
                                  )}
                                </div>
                                <div
                                  className="like-with-like-number"
                                  onClick={() =>
                                    fnCommentModal(item, "comment")
                                  }
                                >
                                  <img src={comment} alt="like" />
                                  <p>{item?.total_comment} comments</p>
                                </div>

                                <>
                                  {item?.interested_users.find(
                                    (f) =>
                                      f.member_id === LoginInfo?.association_id
                                  ) ? (
                                    <div
                                      className="like-with-like-number"
                                      onClick={() =>
                                        fnCommentModal(item, "interested")
                                      }
                                    >
                                      <img src={redAdd} alt="like" />

                                      <p>
                                        {item?.total_interested_user} Interested
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="like-with-like-number">
                                      {item?.total_interested_user === 0 ? (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p>
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p
                                            onClick={() =>
                                              fnCommentModal(item, "interested")
                                            }
                                          >
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </>
                              </div>
                              <div className="post-detail">
                                <p>
                                  {item?.comment !== null ? (
                                    item?.comment
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}

                    {(index + 1) % 14 === 0 && (
                      <>
                        {SuggestedPeople?.length > 0 && (
                          <div className="Suggestion-section-main">
                            <div className="suggestion-title">
                              <h5>Suggested for you</h5>
                              <Link onClick={() => setSuggestedModalShow(true)}>
                                <span>see all</span>{" "}
                                <img src={rightArrow} alt="more" />
                              </Link>
                            </div>
                            <div className="all-suggestion-wrapper">
                              {SuggestedPeople?.length > 0 &&
                                SuggestedPeople?.map((item_su, index_su) => (
                                  <div className="suggestion-single">
                                    <Link
                                      to={{
                                        pathname:
                                          "/profile-detail/" +
                                          item_su.member_id,
                                      }}
                                    >
                                      {item_su?.profile_pic !== null &&
                                      item_su?.profile_pic !== "" &&
                                      item_su?.profile_pic !== undefined ? (
                                        <img
                                          src={item_su?.profile_pic}
                                          alt="profile"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="profile"
                                        />
                                      )}

                                      <p
                                        className={
                                          item_su?.member_type === "Standard"
                                            ? "profile-standard-after-initials-title"
                                            : ""
                                        }
                                        title={item_su?.member_name}
                                      >
                                        {item_su?.member_name?.length > 15
                                          ? item_su?.member_name?.slice(0, 15) +
                                            "..."
                                          : item_su?.member_name}{" "}
                                      </p>
                                    </Link>
                                    <button
                                      className="red-btn w-100"
                                      onClick={() =>
                                        fnAddRemoveFollowing(item_su)
                                      }
                                    >
                                      {/* {item_su?.member_id} Follow */}
                                      {item_su?.is_following === 1
                                        ? "Unfollow"
                                        : "Follow"}
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </>
              ))
            ) : (
              <Loader type={LoaderType.NODATAFOUND} />
            )}
            {!RemoveMoreButtion && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </Col>

          <Col xl={5} md={5}>
            {Loading ? (
              <Loader type={LoaderType.ProfileCard} count={5} />
            ) : (
              <div className="profile-detail-page-right">
                <div className="profile-detail-page-right-inner">
                  <div
                  //  onClick={() => fnRedirectProfile(ProfileDetail)}
                  >
                    <div className="editProfilePencil-wrapper">
                      {ProfileDetail?.profile_pic !== null &&
                      ProfileDetail?.profile_pic !== "" &&
                      ProfileDetail?.profile_pic !== undefined ? (
                        <img
                          // onClick={() => fnRedirectProfile(ProfileDetail)}
                          className={
                            ProfileDetail?.is_subscriber === 1
                              ? " profile-detail-page-right-inner-image profile-detail-page-right-inner-image-golden"
                              : "profile-detail-page-right-inner-image"
                          }
                          src={ProfileDetail?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                        />
                      ) : (
                        <img
                          // onClick={() => fnRedirectProfile(ProfileDetail)}
                          className={
                            ProfileDetail?.is_subscriber === 1
                              ? " profile-detail-page-right-inner-image profile-detail-page-right-inner-image-golden"
                              : "profile-detail-page-right-inner-image"
                          }
                          src={imagePlaceholder}
                          alt="club-detail-image"
                        />
                      )}
                      {LoginInfo?.association_id ===
                        ProfileDetail?.member_id && (
                        <img
                          src={editProfilePencil}
                          className="editProfilePencil"
                          alt="edit profile"
                          onClick={() => fnRedirectProfile(ProfileDetail)}
                        />
                      )}
                    </div>

                    <Dropdown className="my-differenr-dropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <span
                          // onClick={() => alert("ppp")}
                          className="d-flex gap-1 align-items-center dark-badge profile-detail-badge-star"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill="#FFB500"
                          >
                            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                          </svg>
                          <p className="font-white">
                            {ProfileDetail?.trainer_avg_rating === 0
                              ? "0.0"
                              : ProfileDetail?.trainer_avg_rating?.toFixed(1)}
                          </p>
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => fnStarRate("MemberRate")}
                          className="d-flex align-items-center gap-1"
                        >
                          View member rating (
                          {ProfileDetail?.avg_rating === 0
                            ? "0.0"
                            : ProfileDetail?.avg_rating?.toFixed(1)}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 22 20"
                            fill="#FFB500"
                          >
                            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                          </svg>
                          )
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex align-items-center gap-1"
                          onClick={() => fnStarRate("TrainerRate")}
                        >
                          View trainer rating ({" "}
                          {ProfileDetail?.trainer_avg_rating === 0
                            ? "0.0"
                            : ProfileDetail?.trainer_avg_rating?.toFixed(
                                1
                              )}{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 22 20"
                            fill="#FFB500"
                          >
                            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                          </svg>
                          )
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                    <h4 className="name-having-initials">
                      {ProfileDetail?.member_name}
                      {ProfileDetail?.member_type === "Standard" && (
                        <span className="profile-standard-after-initials-title"></span>
                      )}
                      {ProfileDetail?.member_type === "Premium" && (
                        <span className="profile-premium-after-initials-title"></span>
                      )}
                    </h4>
                  </div>
                  <p
                    className={
                      ProfileDetail?.is_subscriber === 1 ? "para-golden" : ""
                    }
                  >
                    {ProfileDetail?.member_type} Member
                  </p>
                  <span>{ProfileDetail?.country}</span>
                  <div className="post-following-followers-wrapper">
                    <div className="post-following-followers-inner">
                      <h6>{ProfileDetail?.total_posts}</h6>
                      <span>Posts</span>
                    </div>
                    <div
                      className="post-following-followers-inner"
                      onClick={() =>
                        handleFollowersAndFollowingModal("Followings")
                      }
                    >
                      <h6>{ProfileDetail?.total_followings}</h6>
                      <span>Followings</span>
                    </div>
                    <div
                      className="post-following-followers-inner"
                      onClick={() =>
                        handleFollowersAndFollowingModal("Followers")
                      }
                    >
                      <h6>{ProfileDetail?.total_followers}</h6>
                      <span>Followers</span>
                    </div>
                  </div>
                  {/* this section will occure only in someone else profile */}
                  {LoginInfo?.association_id !== ProfileDetail?.member_id && (
                    <div className="unfollow-and-three-dots">
                      <button
                        className="red-btn red-border w-100 d-flex align-items-center justify-content-center"
                        onClick={() =>
                          fnAddRemoveFollowing(
                            ProfileDetail,
                            ProfileDetail?.is_following === 1 ? 0 : 1
                          )
                        }
                      >
                        <span>
                          {ProfileDetail?.is_following === 1
                            ? "Unfollow"
                            : "Follow"}
                        </span>
                        {FollowButtonLoader && (
                          <Spinner animation="grow" className="custom-loader" />
                        )}
                      </button>
                      <DropdownButton className="three-dots-btn" title="">
                        <Dropdown.Item
                          eventKey="1"
                          //  onClick={()=>fnBlocked(ProfileDetail)}
                          onClick={() =>
                            confirmAlert({
                              title: "Are you sure you want to block this?",

                              buttons: [
                                {
                                  label: "No",
                                },
                                {
                                  label: "Yes",
                                  onClick: () => fnBlocked(ProfileDetail),
                                },
                              ],
                            })
                          }
                        >
                          <div className="block-share-menu-item">
                            <img src={block} alt="block" />
                            <p>Block</p>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => fnShareProfile(ProfileDetail)}
                        >
                          <div className="block-share-menu-item">
                            <img src={share} alt="share" />
                            <p>Share Profile</p>
                          </div>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  )}

                  {LoginInfo?.association_id === ProfileDetail?.member_id ? (
                    <>
                      <div className="level-information-on-badge">
                        <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                        <p>Level</p>
                      </div>
                      <div
                        className={
                          ProfileDetail?.is_subscriber === 1
                            ? " level-information-on-badge level-information-on-badge-for-standard-user"
                            : "level-information-on-badge"
                        }
                      >
                        <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                        <p>Level</p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="level-information-on-badge">
                        <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                        <p>Level</p>
                      </div>
                      <div
                        //  className="level-information-on-badge"
                        className={
                          ProfileDetail?.is_subscriber === 1
                            ? " level-information-on-badge level-information-on-badge-for-standard-user"
                            : "level-information-on-badge"
                        }
                      >
                        <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                        <p>Level</p>
                      </div>
                    </>
                  )}

                  {/* <div className="level-information-on-badge">
                  <h5>{ProfileDetail?.current_level?.toFixed(1)}</h5>
                  <p>Level</p>
                </div> */}
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
      {/* <FollowersAndFollowingModal
        showFollowersAndFollowingModal={showFollowersAndFollowingModal}
        closeFollowersAndFollowingModal={closeFollowersAndFollowingModal}
      /> */}

      {FollowersAndFollowingModalShow && (
        <FollowersAndFollowingModal
          bindList={getMemberProfile}
          show={FollowersAndFollowingModalShow}
          ProfileDetail={ProfileDetail}
          FollowType={FollowType}
          // CommentPost={CommentPost}
          // ModalType={ModalType}
          onHide={() => setFollowersAndFollowingModalShow(false)}
        />
      )}
      {CommentModalShow && (
        <CommentsModal
          bindList={getHomePostList}
          show={CommentModalShow}
          CommentPost={CommentPost}
          ModalType={ModalType}
          onHide={() => setCommentModalShow(false)}
        />
      )}
      {SuggestedModalShow && (
        <SuggestedForModal
          show={SuggestedModalShow}
          bindList={getSuggestedpeople}
          onHide={() => setSuggestedModalShow(false)}
        />
      )}
      {ReportModalShow && (
        <HomeReportModal
          show={ReportModalShow}
          ReportPost={ReportPost}
          onHide={() => setReportModalShow(false)}
        />
      )}
      {StarRateModalShow && (
        <StarRateModal
          show={StarRateModalShow}
          Type={RateType}
          // ClubDetail={ClubDetail}
          ProfileDetail={ProfileDetail}
          onHide={() => setStarRateModalShow(false)}
        />
      )}
      {ShareProfileModalShow && (
        <ShareProfileModal
          show={ShareProfileModalShow}
          Type="profile"
          ShareProfileData={ShareProfileData}
          onHide={() => setShareProfileModalShow(false)}
        />
      )}
    </div>
  );
}

export default ProfileDetail;
