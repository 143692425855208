import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import passwordmatch from "../images/passwordmatch.svg";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import changePasswordImage from "../images/changePassword.svg";
import HidePass from "../images/hidePassword.svg";
import ShowPass from "../images/showPassword.svg";
// import { commonservices } from "../../services/CommonServices";
// import { apiCall } from "../../services/AxiosService";
// import { API_URL } from "../../services/APIRoute";
// import { apiResponse } from "../../services/APIResponse";
import leftArrow from "../images/leftArrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";

const Change_Password = () => {
  const [PasswordShowIcon, setPasswordShowIcon] = useState(false);
  const [Check_Pass, setCheck_Pass] = useState(false);
  let useremail = localStorage.getItem("forget_email")
    ? localStorage.getItem("forget_email")
    : "";
  let userphone = localStorage.getItem("forget_phone")
    ? localStorage.getItem("forget_phone")
    : "";
  let usercode = localStorage.getItem("forget_code")
    ? localStorage.getItem("forget_code")
    : "";

  let param = useParams();

  let navigate = useNavigate();

  const [input, setInput] = useState({
    password: "",
    errors: {
      password: "",
      ValidationRules: [
        {
          FieldName: "password",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });

    if (obj.isValid) {
      if (input.password !== input.confirm_password) {
        // Password and confirm password mismatch
        // setLoading(false);
        toast.error("Passwords do not match");
        return;
      }
      if (useremail !== "") {
        let body = {
          email: useremail,
          password: input.password.trim(),
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.CHANGE_PASSWORD,
            body: body,
          },
          true
        );

        let response = apiResponse(true, resData);

        if (response?.isValidate) {
          navigate(`/sign-in`);
        }

        if (!response?.isValidate) {
        }
      } else {
        let body = {
          phone: userphone,
          country_code: usercode,
          password: input.password.trim(),
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.CHANGE_PASSWORD,
            body: body,
          },
          true
        );
        let response = apiResponse(true, resData);
        if (response?.isValidate) {
          navigate(`/sign-in`);
        }
        if (!response?.isValidate) {
        }
      }
    }
  };
  const showHidePass = (e) => {
    setPasswordShowIcon(!PasswordShowIcon);

    // let button = document.getElementById("showHidePassBtn");
    // let PassInput = document.querySelector(".password-show-hide");
    // const open = button.querySelector(".open-icon");
    // const close = button.querySelector(".close-icon");
    // const currentValue = PassInput.getAttribute("type");
    // const newValue = currentValue === "password" ? "text" : "password";
    // PassInput.setAttribute("type", newValue);
    // if (newValue == "password") {
    //   open.style.display = "inline-flex";
    //   close.style.display = "none";
    // } else if (newValue == "text") {
    //   close.style.display = "inline-flex";
    //   open.style.display = "none";
    // } else {
    //   open.style.display = "inline-flex";
    //   close.style.display = "none";
    // }
  };
  return (
    // <>
    //   <h3 className="text-center m-3">Change Password</h3>
    //   <Container>
    //     <Row>
    //       <Form
    //         onSubmit={(e) => {
    //           handleSubmit(e);
    //         }}
    //       >
    //         <InputGroup className="password-group">
    //           <Form.Control
    //             type="password"
    //             placeholder="Enter Password"
    //             value={input.password}
    //             onChange={(e) => {
    //               setInput({
    //                 ...input,
    //                 ["password"]: e.target.value,
    //               });
    //             }}
    //             isInvalid={input.errors.password}
    //           />
    //           {input.errors.password && (
    //             <Form.Control.Feedback type="invalid">
    //               {input.errors.password}
    //             </Form.Control.Feedback>
    //           )}
    //         </InputGroup>
    //         <InputGroup className="password-group">
    //           <Form.Control
    //             type="password"
    //             placeholder="Enter confirm_password"
    //             value={input.confirm_password}
    //             onChange={(e) => {
    //               setInput({
    //                 ...input,
    //                 ["confirm_password"]: e.target.value,
    //               });
    //             }}
    //             isInvalid={input.errors.confirm_password}
    //           />
    //           {input.errors.confirm_password && (
    //             <Form.Control.Feedback type="invalid">
    //               {input.errors.confirm_password}
    //             </Form.Control.Feedback>
    //           )}
    //         </InputGroup>

    //         {/* <FloatingLabel
    //           controlId="floatingInput"
    //           label="Confirm New Password"
    //           className="mb-3"
    //         >
    //           <Form.Control
    //             type="password"
    //             placeholder="Confirm New Password"
    //             value={input.confirm_password}
    //             onChange={(e) =>
    //               setInput({
    //                 ...input,
    //                 ["confirm_password"]: e.target.value,
    //               })
    //             }
    //             isInvalid={input.errors.confirm_password}
    //           />
    //           {input.errors.confirm_password && (
    //             <Form.Control.Feedback type="invalid">
    //               {input.errors.confirm_password}
    //             </Form.Control.Feedback>
    //           )}
    //         </FloatingLabel> */}

    //         <div className="text-center">
    //           <Button type="submit" variant="primary">
    //             Submit
    //           </Button>
    //         </div>
    //       </Form>
    //     </Row>
    //   </Container>
    // </>

    <div className="sign-in-main sign-up-main forgot-password-main">
      <Container>
        <div className="sign-in-wrapper">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <img  onClick={() => navigate("/sign-in")} src={leftArrow} alt="previous" className="previous-step" />
            <h3 className="text-center m-0  page-head">Change Password</h3>
            <img
              src={leftArrow}
              alt="previous"
              style={{ visibility: "hidden" }}
             
            />
          </div>
          <div className="sign-in-inner-main">
            <div className="sign-in-inner-all">
              <div className="sign-in-logo text-center">
                <img src={changePasswordImage} alt="forgotPasswordImage" />
              </div>
              <div className="sign-in-inputs">
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  <InputGroup
                    className={
                      input.errors.password
                        ? "error password-group"
                        : "password-group"
                    }
                  >
                    <Form.Control
                      // type="password"
                      type={PasswordShowIcon ? "text" : "password"}
                      style={{ borderRight: "none" }}
                      className="password-show-hide"
                      placeholder="Enter Password"
                      value={input.password}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["password"]: e.target.value,
                        });
                      }}
                      isInvalid={input.errors.password}
                    />
                    {input.errors.password && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.password}
                      </Form.Control.Feedback>
                    )}
                    {/* <Button
                      className="border-0"
                      id="showHidePassBtn"
                      onClick={showHidePass}
                    >
                      <img
                        src={ShowPass}
                        className="open-icon"
                        alt="OpenPass"
                      />
                      <img
                        src={HidePass}
                        className="close-icon"
                        alt="ClosePass"
                      />
                    </Button> */}
                    <Button
                      className="border-0"
                      id="showHidePassBtn"
                      onClick={showHidePass}
                    >
                      <img
                        // src={ShowPass}
                        src={PasswordShowIcon ? HidePass : ShowPass}
                        className="open-icon"
                        alt="OpenPass"
                      />
                      {/* <img
                        src={HidePass}
                        className="close-icon"
                        alt="ClosePass"
                      /> */}
                    </Button>
                  </InputGroup>
                  <InputGroup className="password-group">
                    <Form.Control
                      // type="password"
                      type={PasswordShowIcon ? "text" : "password"}
                      className="password-show-hide"
                      placeholder="Enter confirm_password"
                      value={input.confirm_password}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          ["confirm_password"]: e.target.value,
                        });

                        if (e.target.value === input.password) {
                          setCheck_Pass(true);
                        } else {
                          setCheck_Pass(false);
                        }
                      }}
                      // isInvalid={input.errors.confirm_password}
                    />
                    {Check_Pass && (
                      <Button
                        className="border-0"
                        style={{ marginBottom: "10px", cursor: "auto" }}
                      >
                        <img src={passwordmatch} alt="passwordmatch" />{" "}
                      </Button>
                    )}
                    {/* {input.errors.confirm_password && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.confirm_password}
                      </Form.Control.Feedback>
                    )} */}
                    {/* <Button
                      className="border-0"
                      id="showHidePassBtn1"
                      onClick={showHidePass1}
                    >
                      <img
                        src={ShowPass}
                        className="open-icon"
                        alt="OpenPass"
                      />
                      <img
                        src={HidePass}
                        className="close-icon"
                        alt="ClosePass"
                      />
                    </Button> */}
                  </InputGroup>

                  <div className="text-center get-otp-btn">
                    <button type="submit" className="red-btn w-100">
                      Submit
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Change_Password;
