import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { apiResponse } from "../services/APIResponse";
import DatePicker from "react-datepicker";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const lib = ["places"];

const OpenPlayModal = (props) => {
  const [Loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [StartTime, setStartTime] = useState(new Date());
  const [MaxStartTime, setMaxStartTime] = useState(new Date());
  const [EndTime, setEndTime] = useState(new Date());
  const [Duration, setDuration] = useState([]);
  const [FinalDuration, setFinalDuration] = useState();
  const [BookPrice, setBookPrice] = useState(0);

  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let result = "";

      if (hours > 0) {
        result += hours.toFixed() + "h";
      }

      if (minutes > 0) {
        result += (result.length > 0 ? " " : "") + minutes.toFixed() + "m";
      }

      return result;
    }
  }

  useEffect(() => {
    if (props?.AvailableSlot) {
      setStartTime(new Date(props?.AvailableSlot?.startdate));

      const startTime = new Date(props?.AvailableSlot?.startdate);
      const endTime = new Date(props?.AvailableSlot?.endDate);

      const temp = moment.duration(props?.AvailableSlot?.data?.min_time);
      const totalMinTimeInMinutes = temp.asMinutes();

      const timeDifferenceInMilliseconds = endTime - startTime;
      const totalTimeInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
      let NewtotalTimeInMinutes = totalTimeInMinutes - totalMinTimeInMinutes;

      const intervalInMinutes = 30;
      const totalIntervals = NewtotalTimeInMinutes / intervalInMinutes;

      const totalTimeArray = [];

      for (let i = 0; i < totalIntervals; i++) {
        const intervalStartDate = new Date(
          startTime.getTime() + i * intervalInMinutes * 60 * 1000
        );
        const intervalEndDate = new Date(
          intervalStartDate.getTime() + intervalInMinutes * 60 * 1000
        );

        const totalTimeObject = {
          startDate: intervalStartDate,
          endDate: intervalEndDate,
        };
        totalTimeArray.push(totalTimeObject);
      }

      const extraIntervalStartDate = new Date(
        startTime.getTime() + totalIntervals * intervalInMinutes * 60 * 1000
      );
      const extraIntervalEndDate = new Date(
        extraIntervalStartDate.getTime() + intervalInMinutes * 60 * 1000
      );

      const extraTotalTimeObject = {
        startDate: extraIntervalStartDate,
        endDate: extraIntervalEndDate,
      };
      totalTimeArray.push(extraTotalTimeObject);

      setEndTime(new Date(totalTimeArray[totalTimeArray.length - 1].startDate));
      setDuration(totalTimeArray);

      let temp1 = [totalMinTimeInMinutes];
      while (temp1[temp1.length - 1] < totalTimeInMinutes) {
        temp1.push(temp1[temp1.length - 1] + 30);
      }

      setDuration(temp1);

      const totalStartTimeInMinutes = startTime / (1000 * 60);

      setMaxStartTime(
        new Date(startTime.getTime() + totalMinTimeInMinutes * 60 * 1000)
      );

      // FinalDuration
      const hours = Math.floor(totalMinTimeInMinutes / 60);
      const remainingMinutes = totalMinTimeInMinutes % 60;
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(remainingMinutes).padStart(2, "0");
      const formattedSeconds = "00";

      setFinalDuration(
        `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
      );
    }
  }, [props?.AvailableSlot]);

  function fnStartTimeHandle(item) {
    setStartTime(item);

    const startTime = new Date(props?.AvailableSlot?.startdate);
    const endTime = new Date(item);
    const timeDifferenceInMilliseconds = endTime - startTime;
    const totalTimeInMinutes = timeDifferenceInMilliseconds / (1000 * 60);

    const temp = moment.duration(props?.AvailableSlot?.data?.min_time);
    const totalMinTimeInMinutes = temp.asMinutes();
    if (totalTimeInMinutes > totalMinTimeInMinutes) {
      toast.error("Select valid time");
    } else {
      setEndTime(
        new Date(endTime.getTime() + totalMinTimeInMinutes * 60 * 1000)
      );
    }
  }

  function fnDurationHandle(mins) {
    // debugger;
    setEndTime(new Date(StartTime.getTime() + mins * 60 * 1000));
    const hours = Math.floor(mins / 60);
    const remainingMinutes = mins % 60;
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");
    const formattedSeconds = "00";
    setFinalDuration(
      `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    );

    const totalMins =
      (props?.GetCountOpenPlay?.hour || 0) * 60 +
      (props?.GetCountOpenPlay?.min || 0);
    const selectedMins = parseInt(mins);

    let finalMins = selectedMins - totalMins;

    let priceVale = finalMins / 30;

    const finalPrice = props?.AvailableSlot?.price * priceVale;

    setBookPrice(finalPrice);
  }

  async function fnBook(e) {
    e.preventDefault();
    setLoading(true);
    let body = {
      is_buy_now: 1,
      items: [
        {
          booking_slot_id: props?.AvailableSlot?.data?.booking_slot_id,
          item_type: "club_booking",
          start_time: props?.AvailableSlot?.startdate,
          duration: FinalDuration,
        },
      ],
    };
   
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_ADD,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      navigate(`/cart-confirmation/${response?.data?.data?.cart_id}`);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  // console.log("Duration", Duration);
  // console.log("FinalDuration", FinalDuration);
  // console.log("EndTime", EndTime);

  return (
    <>
      <Modal className="one-play-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Open Play
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Form
        // onSubmit={(e) => {
        //   handleSubmit(e);
        // }}
        >
          <Modal.Body className="">
            <h5 className="mb-3">
              Open Play for{" "}
              {commonservices.getDateInFormatCustom(
                props?.AvailableSlot?.startdate,
                props?.NewTimeZOnw,
                "DD-MM-yyyy"
              )}{" "}
              from{" "}
              {commonservices.getTimeInFormat(
                props?.AvailableSlot?.startdate,
                props?.NewTimeZOnw
              )}
              -
              {commonservices.getTimeInFormat(
                props?.AvailableSlot?.endDate,
                props?.NewTimeZOnw
              )}
              , Price $
              {props?.AvailableSlot?.price === 0
                ? "0.0"
                : props?.AvailableSlot?.price?.toFixed(2)}
              /30m
            </h5>
            <strong>
              {props?.GetCountOpenPlay?.hour > 0 &&
                props?.GetCountOpenPlay?.hour + "h"}{" "}
              {props?.GetCountOpenPlay?.min > 0 &&
                props?.GetCountOpenPlay?.min + "Min"}{" "}
              free session(s)
            </strong>
            <div className="d-flex     justify-content-between align-items-center">
              <p>Start Time</p>
              <div>
                <DatePicker
                  selected={StartTime}
                  onChange={(e) => fnStartTimeHandle(e)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={30}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  minTime={StartTime}
                  maxTime={MaxStartTime}
                />
              </div>
            </div>
            <div className="d-flex mt-3     justify-content-between align-items-center">
              <p>Duration</p>
              <select
                aria-label="select club_activity_option"
                className="black-colored-focused form-select open-play-select"
                onChange={(e) => fnDurationHandle(e.target.value)}
              >
                {Duration?.length > 0 &&
                  Duration?.map((item, index) => (
                    <option value={item}>{fnHour(item)}</option>
                  ))}
              </select>
            </div>
            <div className="d-flex mt-3     justify-content-between align-items-center">
              <p>End Time</p>
              <DatePicker
                disabled
                selected={EndTime}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="H:mm"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="w-100">
              <button onClick={(e) => fnBook(e)} className="red-btn w-100">
                {Loading
                  ? "Loading..."
                  : `Book($${
                      BookPrice === 0 ? "0.00" : BookPrice?.toFixed(2)
                    })`}
              </button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default OpenPlayModal;
