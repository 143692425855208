import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal, Nav, Tab, Tabs } from "react-bootstrap";
import searchIcon from "../images/searchIcon.svg";
import profile2 from "../images/player2.jpg";
import post2 from "../images/post2.png";
import imagePlaceholder from "../images/placeholder.png";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import Loader, { LoaderType } from "../components/Loader";
import { Link } from "react-router-dom";

function SuggestedForModal(props) {
  const [Loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState(props?.FollowType);
  const [FollowerMembersList, setFollowerMembersList] = useState([]);
  const [FollowerMembersLoading, setFollowerMembersLoading] = useState(false);
  const [FollowingMembersList, setFollowingMembersList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTermFollowing, setSearchTermFollowing] = useState("");
  const [searchFollower, setsearchFollower] = useState("");
  // const [isLoading, setIsLoading] = useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [error, setError] = useState(null);

  async function getSuggestedpeople(pageno) {
    setLoading(true);
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SUGGESTED_PEOPLE,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (pageno === 1) {
        setFollowerMembersList(response?.data?.data?.list);
      } else {
        setFollowerMembersList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  async function fnAddRemoveFollowing(item, value) {
    let body = {
      receiver_member_id: item?.member_id,
      follow: value,
    };
    // debugger;
    // return;
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData);
    if (response?.isValidate) {
      getSuggestedpeople(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    getSuggestedpeople(pageNumber);
  }, []);

  const handleLoadMore = () => {
    getSuggestedpeople(pageNumber);
  };

  return (
    <Modal className="likes-modal-main" size="lg" centered {...props}>
      <Modal.Header closeButton>
        <div style={{ visibility: "hidden" }}>A</div>
        <Modal.Title id="contained-modal-title-vcenter">
          Suggested for you
        </Modal.Title>
        <div style={{ visibility: "hidden" }}>A</div>
      </Modal.Header>
      <Modal.Body id="global-search">
        <div className="club-detail-activity-tabs">
          <div className="all-sungle-likes-wrapper all-followings">
            {Loading && !FollowerMembersList?.length > 0 ? (
              <Loader type={LoaderType.InviteFriend} count={5} />
            ) : (
              <>
                {FollowerMembersList?.length > 0 ? (
                  FollowerMembersList?.map((item_fw, index_fw) => (
                    <div
                      // to={{
                      //   pathname: "/profile-detail/" + item_fw?.member_id,
                      // }}
                      className="likes-signle single-notification-single-wrapper2   invite-friends-single1 d-flex align-items-center justify-content-between"
                    >
                      <Link
                        to={{
                          pathname: "/profile-detail/" + item_fw?.member_id,
                        }}
                        className="single-post-top"
                      >
                        <div className="d-flex align-items-center profile-and-time">
                          <div className="write-something-profile">
                            {item_fw?.profile_pic !== null &&
                            item_fw?.profile_pic !== "" &&
                            item_fw?.profile_pic !== undefined ? (
                              <img
                                src={item_fw?.profile_pic}
                                alt="club-detail-image"
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = imagePlaceholder;
                                }}
                              />
                            ) : (
                              <img
                                src={imagePlaceholder}
                                alt="club-detail-image"
                              />
                            )}
                          </div>
                          <div className="username-with-time">
                            {/* <h5>
                              {item_fw?.member_name}

                              {item_fw?.member_type === "Standard" && (
                                <span className="profile-after-initials">
                                  S
                                </span>
                              )}
                              {item_fw?.member_type === "Premium" && (
                                <span className="profile-after-initials">
                                  P
                                </span>
                              )}
                            </h5> */}
                            <h5
                              className={
                                item_fw?.member_type === "Standard"
                                  ? "profile-standard-after-initials-title"
                                  : item_fw?.member_type === "Premium"
                                  ? "profile-premium-after-initials-title"
                                  : ""
                              }
                            >
                              {item_fw?.member_name}
                            </h5>
                            <p>{item_fw?.nickname}</p>
                          </div>
                        </div>
                      </Link>
                      <button
                        // onClick={() => fnRemoveFollowing(item_fw)}
                        onClick={() =>
                          fnAddRemoveFollowing(
                            item_fw,
                            item_fw?.is_following === 1 ? 0 : 1
                          )
                        }
                        className="gray-btn"
                        style={{
                          height: "34px",
                          fontSize: "14px",
                          width: "100px",
                        }}
                      >
                        {item_fw?.is_following === 1 ? "Unfollow" : "Follow"}
                      </button>
                    </div>
                  ))
                ) : (
                  <Loader type={LoaderType.NODATAFOUND} />
                )}
              </>
            )}

            {Loading && FollowerMembersList?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={4} />
            )}

            {FollowerMembersList?.length > 0 && (
              <>
                {!RemoveMoreButtion && (
                  <div className="text-center w-100 mb-3 view-more-btn">
                    <button
                      className="red-btn red-border width150"
                      onClick={handleLoadMore}
                      disabled={Loading}
                    >
                      {Loading ? "Loading..." : "View More"}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SuggestedForModal;
