import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";
import GroupMembersModal from "./GroupMembersModal";
import { useSelector } from "react-redux";

const InvitePlayerModal = (props) => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [Loading, setLoading] = useState(false);
  const [LoadingSubmit, setLoadingSubmit] = useState(false);
  const [GroupMembersModalShow, setGroupMembersModalShow] = useState(false);
  const [CurrentGroupID, setCurrentGroupID] = useState();
  const [GroupList, setGroupList] = useState([]);
  const [GroupListMembersList, setGroupListMembersList] = useState([]);
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [RemoveMoreButtionGroupList, setRemoveMoreButtionGroupList] =
    useState(false);
  const [invitedPlayers, setInvitedPlayers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [GroupListPageNumber, setGroupListPageNumber] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFollower, setsearchFollower] = useState("");
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [input, setInput] = useState({
    no_of_people: "",
  });

  const [MemberConnection, setMemberConnection] = useState([]);

  async function getMemberconnections(pageno) {
    setLoading(true);
    try {
      let body = {};
      let resData = "";
      //   page: pageno,
      //   limit: 10,
      //   search: searchFollower,
      // };
      // sub_order_id
      if (props?.PlayerModalType === "Friends") {
        body = {
          sub_order_id: props?.BookingDetail?.sub_order_id,
          page: pageno,
          limit: 10,
          search: searchFollower,
        };
        resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.ORDER_BOOKING_CONNECTIONS,

            body: body,
          },
          false
        );
      } else {
        body = {
          page: pageno,
          limit: 10,
          search: searchFollower,
        };
        resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.MEMBER_CONNECTIONS,
            body: body,
          },
          false
        );
      }
      // let resData = await apiCall(
      //   {
      //     method: "POST",
      //     url:
      //       API_URL.BASEURL + props?.PlayerModalType === "Friends"
      //         ? API_URL.ORDER_BOOKING_CONNECTIONS
      //         : API_URL.MEMBER_CONNECTIONS,
      //     body: body,
      //   },
      //   false
      // );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        if (pageno === 1) {
          setMemberConnection(response?.data?.data?.list);
        } else {
          setMemberConnection((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        setLoading(false);
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  async function getGroupList(pageno) {
    setIsLoading(true);
    let body = {
      page: pageno,
      limit: 10,
      search: searchQuery,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setIsLoading);
    if (response?.isValidate) {
      setCurrentGroupID(response?.data?.data?.list[0]);
      // setGroupList(response?.data?.data?.list);
      if (pageno === 1 || pageno === undefined) {
        setGroupList(response?.data?.data?.list);
      } else {
        setGroupList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionGroupList(true);
      } else {
        setRemoveMoreButtionGroupList(false);
      }
      if (response?.data?.data?.totalPages > pageno) {
        setGroupListPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  const handleLoadMoreGroupList = () => {
    getGroupList(GroupListPageNumber);
  };

  useEffect(() => {
    getMemberconnections(pageNumber);
  }, [searchFollower]);

  useEffect(() => {
    getGroupList(1);
  }, [searchQuery]);
  const handleLoadMore = () => {
    getMemberconnections(pageNumber);
  };

  async function getGroupMembersList(player) {
    let body = {
      group_id: player?.group_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_GET_GROUP_MEMBERS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGroupListMembersList(response?.data?.data?.list);

      const temp = response?.data?.data?.list.filter((element1) => {
        return !props?.BookingDetail?.players_details.some((element) => {
          return (
            element.member_id === element1.member_id &&
            element.action_status === "accepted"
          );
        });
      });

      setInvitedPlayers(temp);
    }

    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnInviteFriendData(player) {
    if (props?.PlayerModalType === "Groups") {
      getGroupMembersList(player);
    } else {
      if (invitedPlayers.some((p) => p.member_id === player.member_id)) {
        const updatedPlayers = invitedPlayers.filter(
          (p) => p.member_id !== player.member_id
        );
        setInvitedPlayers(updatedPlayers);
      } else {
        setInvitedPlayers([...invitedPlayers, player]);
      }
    }
  }

  const handleSubmit = async (e) => {
    setLoadingSubmit(true);
    e.preventDefault();
    if (props?.PlayerModalType === "MaxPlayer") {
      const body = {
        sub_order_id: props?.param,
        max_no_of_players: input.no_of_people,
        order_id: props?.BookingDetail?.order_id,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_PLAYER_SAVE_LIMIT,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setLoadingSubmit);
      if (response?.isValidate) {
        // setLoadingSubmit(false);
        props.bindList();
        props.onHide();
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else if (props?.PlayerModalType === "Friends") {
      const playerIds = invitedPlayers.map((player) => player.member_id);
      const body = {
        sub_order_id: props?.param,
        players: playerIds,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_PLAYER_SAVE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData);
      if (response?.isValidate) {
        props.bindList();
        props.onHide();
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else if (props?.PlayerModalType === "HomeScore") {
      props.CurrentGroupIDHome(CurrentGroupID);
      props.onHide();
    } else {
      const playerIds = invitedPlayers
        .filter((player) => player.member_id !== LoginInfo.association_id)
        .map((player) => player.member_id);

      const body = {
        sub_order_id: props?.param,
        players: playerIds,
      };

      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ORDER_PLAYER_SAVE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData);
      if (response?.isValidate) {
        props.bindList();
        props.onHide();
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  };
  
  return (
    <>
      <Modal
        className="clubactivity-modal-main  invite-only-player-modal-main-wrapper"
        size="md"
        centered
        {...props}
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>A</div>
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.PlayerModalType === "MaxPlayer" ? (
                <>Update max no. of Player</>
              ) : props?.PlayerModalType === "Friends" ? (
                <>Invite Player</>
              ) : (
                <>My Groups</>
              )}{" "}
            </Modal.Title>
            <div style={{ visibility: "hidden" }}>A</div>
          </Modal.Header>

          {/* <input type="text" placeholder="Search.." /> */}
          {props?.PlayerModalType === "MaxPlayer" ? (
            <>
              <Modal.Body className="add-no-of-people-main" id="global-search">
                <Form.Control
                  type="number"
                  placeholder="0"
                  onChange={(e) => {
                    setInput({
                      ...input,
                      ["no_of_people"]: e.target.value,
                    });
                  }}
                  className="black-colored-focused text-center"
                />
              </Modal.Body>
              <Modal.Footer>
                {LoadingSubmit ? (
                  <button type="submit" className="red-btn w-100">
                    Loading...
                  </button>
                ) : (
                  <button className="red-btn otp-btn w-100">Save</button>
                )}
              </Modal.Footer>
            </>
          ) : props?.PlayerModalType === "Friends" ? (
            <>
              <Modal.Body id="global-search">
                <div className="invite-friends-main">
                  <div className="modal-header-search-group w-100">
                    <InputGroup className="search-input-group">
                      <Form.Control
                        p
                        type="text"
                        placeholder="Search..."
                        // value={searchQuery}
                        // onChange={handleSearchChange}
                        value={searchFollower}
                        onChange={(e) => setsearchFollower(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={searchIcon}
                          alt="search"
                          className="black-search-icon"
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>

                <div className="invite-friends-main">
                  {Loading && !MemberConnection?.length > 0 ? (
                    <Loader type={LoaderType.InviteFriend} count={4} />
                  ) : (
                    <>
                      {MemberConnection?.length > 0 ? (
                        MemberConnection.map((item, index) => (
                          <div key={index} className="invite-friends-single">
                            <div className="invite-friends-single-img-name">
                              {item?.profile_pic !== null &&
                              item?.profile_pic !== "" &&
                              item?.profile_pic !== undefined ? (
                                <img
                                  src={item?.profile_pic}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                  className="invite-friends-single-image"
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                  className="invite-friends-single-image"
                                />
                              )}
                              <p>{item?.member_name}</p>
                            </div>

                            <button
                              className={`gray-btn ${
                                invitedPlayers.some(
                                  (p) => p.member_id === item.member_id
                                )
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => fnInviteFriendData(item)}
                              disabled={invitedPlayers.some(
                                (p) => p.member_id === item.member_id
                              )}
                              style={{ height: "34px", fontSize: "14px" }}
                            >
                              {invitedPlayers.some(
                                (p) => p.member_id === item.member_id
                              )
                                ? "Added"
                                : "Add"}
                            </button>
                          </div>
                        ))
                      ) : (
                        <Loader type={LoaderType.NODATAFOUND} />
                      )}
                    </>
                  )}
                  {Loading && MemberConnection?.length > 0 && (
                    <Loader type={LoaderType.InviteFriend} count={4} />
                  )}

                  {MemberConnection?.length > 0 && (
                    <>
                      {!RemoveMoreButtion && (
                        <div className="text-center w-100  view-more-btn">
                          <button
                            className="red-btn red-border width150"
                            onClick={handleLoadMore}
                            disabled={Loading}
                          >
                            {Loading ? "Loading..." : "View More"}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {LoadingSubmit ? (
                  <button type="submit" className="red-btn w-100">
                    Loading...
                  </button>
                ) : (
                  <button className="red-btn otp-btn w-100">Save</button>
                )}
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Body id="global-search">
                <div className="invite-friends-main">
                  <div className="modal-header-search-group w-100">
                    <InputGroup className="search-input-group">
                      <Form.Control
                        p
                        type="text"
                        placeholder="Search..."
                        // value={searchQuery}
                        // onChange={handleSearchChange}
                        value={searchFollower}
                        onChange={(e) => setsearchFollower(e.target.value)}
                      />
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={searchIcon}
                          alt="search"
                          className="black-search-icon"
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                </div>

                <div className="invite-friends-main">
                  {isLoading && !GroupList?.length > 0 ? (
                    <Loader type={LoaderType.InviteFriend} count={4} />
                  ) : (
                    <>
                      {GroupList?.length > 0 ? (
                        GroupList.map((item, index) => (
                          <div key={index} className="invite-friends-single ">
                            <div
                              // onClick={() => setCurrentGroupID("group")}
                              onClick={() => {
                                // navigate("/profile-detail/" + item_r?.value);
                                // window.location.reload();
                                setCurrentGroupID(item);
                                setGroupMembersModalShow(true);
                                // getGroupDetail(item_r)
                              }}
                              className="invite-friends-single-img-name cursor-pointer"
                            >
                              {item?.profile_pic !== null &&
                              item?.profile_pic !== "" &&
                              item?.profile_pic !== undefined ? (
                                <img
                                  src={item?.profile_pic}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                  className="invite-friends-single-image"
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                  className="invite-friends-single-image"
                                />
                              )}
                              <div>
                                <p className="profile-standard-after-initials-title group_admin">
                                  {item?.title}
                                </p>
                                <p>{item?.no_of_members} Participant</p>
                              </div>
                            </div>

                            <Button
                              className={`red-btn red-border ${
                                invitedPlayers.some(
                                  (p) => p.group_id === item.group_id
                                )
                                  ? "disabled"
                                  : ""
                              }`}
                              onClick={() => {
                                fnInviteFriendData(item);
                                setCurrentGroupID(item);
                              }}
                              disabled={invitedPlayers.some(
                                (p) => p.group_id === item.group_id
                              )}
                              style={{ height: "34px", fontSize: "14px" }}
                            >
                              {invitedPlayers.some(
                                (p) => p.group_id === item.group_id
                              )
                                ? "Select"
                                : "Select"}
                            </Button>
                          </div>
                        ))
                      ) : (
                        <Loader type={LoaderType.NODATAFOUND} />
                      )}
                    </>
                  )}
                  {isLoading && GroupList?.length > 0 && (
                    <Loader type={LoaderType.InviteFriend} count={4} />
                  )}

                  {GroupList?.length > 0 && (
                    <>
                      {!RemoveMoreButtionGroupList && (
                        <div className="text-center w-100  view-more-btn">
                          <button
                            className="red-btn red-border width150"
                            onClick={handleLoadMoreGroupList}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "View More"}
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                {/* {LoadingSubmit ? (
                  <button type="submit" className="red-btn w-100">
                    Loading...
                  </button>
                ) : ( */}
                <button className="red-btn otp-btn w-100">Save</button>
                {/* )} */}
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>

      {GroupMembersModalShow && (
        <GroupMembersModal
          show={GroupMembersModalShow}
          CurrentGroupID={CurrentGroupID}
          onHide={() => setGroupMembersModalShow(false)}
        />
      )}
    </>
  );
};

export default InvitePlayerModal;
