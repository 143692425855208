import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
const lib = ["places"];

const BankDetailModal = (props) => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [Loading, setLoading] = useState(false);

  async function fnBankDetails() {
    
    if (LoginInfo.stripe_connect_account_status !== "active") {
      setLoading(true);
      let body = {
        // return_url: "http://localhost:3000/settings",
        return_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.TRAINER_GET_PAYMENT_ONBOARDING_LINK,
          body: body,
        },
        false
      );
      if (resData.status === 0) {
        setLoading(false);
        toast.error(resData.message);
      }
      if (resData.status === 1) {
        setLoading(false);
        window.location.href = resData?.data?.link;
      }
    }
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      props.onHide();
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <Modal className="" size="sm" centered {...props}>
        <Modal.Body className="pb-0 text-center">
          Add bank detail to continue
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
            <button onClick={() => fnBankDetails()} className="red-btn w-100">
              {Loading ? "Loading..." : "Bank Details"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BankDetailModal;
