import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { commonservices } from "../services/CommonServices";
import { toast } from "react-toastify";
import leftArrow from "../images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Signup_Steps = () => {
  const UserInfo = useSelector((state) => state.userinfo.Userdata);

  const [Loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [StepData, setStepData] = useState([]);
  const [input, setInput] = useState({});

  const handleNextStep = () => {
    if (validateStep(activeStep)) {
      if (activeStep < StepData.length - 1) {
        setActiveStep(activeStep + 1);
      } else {
        toast.error("You have completed all steps.");
      }
    } else {
      toast.error("Please select an option for the current step.");
    }
  };
  const handlePrevStep = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    } else {
      navigate("/sign-up");
    }
  };

  const validateStep = (stepIndex) => {
    const selectedOption = input[`list[${stepIndex}][selected_option]`];
    return selectedOption !== undefined && selectedOption !== "";
  };

  async function getSignUpSteps() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_SIGN_UP_STEPS,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setStepData(response?.data?.data.list);
    }
    if (!response?.isValidate) {
    }
  }

  useEffect(() => {
    getSignUpSteps();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateStep(activeStep)) {
      setLoading(true);
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SAVE_USER_PROFILE_STEPS,
          body: input,
        },
        true
      );
      let response = apiResponse(true, resData, setLoading);
      if (response?.isValidate) {
        localStorage.setItem("TokenStep", "Pickler");
        window.location.reload();
      }
      if (!response?.isValidate) {
        console.log("Error when registering a new user", response);
      }
    } else {
      toast.error("Please select an option for the current step.");
    }
  };

  const handleOptionClick = (stepIndex, option) => {
    setInput({
      ...input,
      [`list[${stepIndex}][selected_option]`]: option,
      [`list[${stepIndex}][title]`]: StepData[stepIndex].title,
    });
  };

  return (
    <div className="sign-in-main sign-up-steps-main">
      <Container>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          {StepData.length > 0 && activeStep < StepData.length && (
            <>
              <div className="sign-in-wrapper">
                <div className="d-flex justify-content-between align-items-center title-with-arrow">
                  {/* {activeStep > 0 && ( */}
                  {activeStep === 0 ? (
                    <img
                      src={leftArrow}
                      alt="previous"
                      className="previous-step"
                      style={{ visibility: "hidden" }}
                    />
                  ) : (
                    <img
                      src={leftArrow}
                      alt="previous"
                      className="previous-step"
                      onClick={handlePrevStep}
                    />
                  )}

                  {/* )} */}
                  <h3 className="text-center m-0  page-head">
                    Step {activeStep + 1} of {StepData.length}
                  </h3>
                  <img
                    src={leftArrow}
                    alt="previous"
                    style={{ visibility: "hidden" }}
                  />
                </div>
                <div className="sign-in-inner-main">
                  <div className="sign-in-inner-all">
                    {/* <h6 className="sign-up-steps-que">
                      {activeStep === 0 && <>Whats your pickleball level ?</>}
                      {activeStep === 1 && <>What's Your Gender ?</>}
                      {activeStep === 2 && <>How Old are You ?</>}
                      {activeStep === 3 && (
                        <>Matches Played, On an Average in Last 6 Months ?</>
                      )}
                      {activeStep === 4 && <>Your Level of Sportsmenship ?</>}
                      {activeStep > 4 && <>{StepData[activeStep].title}</>}
                    </h6> */}
                    <h6 className="sign-up-steps-que">
                      {StepData.length > 0 && StepData[activeStep] && (
                        <>{StepData[activeStep].title}</>
                      )}
                    </h6>

                    <div className="sign-up-steps-opt">
                      {StepData[activeStep].option.map((c_item, c_index) => (
                        <button
                          type="button"
                          className={
                            c_item.value ===
                            input[`list[${activeStep}][selected_option]`]
                              ? "white-btn-diffborder w-100 active"
                              : "white-btn-diffborder w-100"
                          }
                          key={c_index}
                          value={c_item.value}
                          onClick={() =>
                            handleOptionClick(activeStep, c_item.value)
                          }
                        >
                          {c_item.value}
                        </button>
                      ))}
                    </div>
                    <div className="text-center sign-up-steps-btns">
                      {activeStep < StepData.length - 1 && (
                        <Button
                          onClick={handleNextStep}
                          className="red-btn w-100"
                        >
                          Next
                        </Button>
                      )}
                      {activeStep === StepData.length - 1 && (
                        <Button type="submit" className="red-btn w-100">
                          {Loading ? "Loading..." : "Submit"}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default Signup_Steps;
