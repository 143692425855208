import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import WeekCalender from "../components/CommonComponents/WeekCalender";
import information from "../images/informaton.svg";
import group from "../images/group.svg";
import next from "../images/next.svg";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import previous from "../images/previous.svg";
import graph from "../images/graphIcon.png";
import clock from "../images/clock.svg";
import star from "../images/transparentStar.svg";
import profilepic from "../images/player1.jpg";
import { commonservices } from "../services/CommonServices";
import moment from "moment";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { useSelector } from "react-redux";
import crown from "../images/crown.png";
import Loader, { LoaderType } from "../components/Loader";
import AddToCartModal from "./AddToCartModal";
import SubsciptionModal from "./SubscriptionModal";

function CoachDetailModal(props) {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  // console.log("LoginInfo ::", LoginInfo);
  const [checkBox, setCheckBox] = useState(false);
  const [SlotLoading, setSlotLoading] = useState(false);
  const [AddToCartModalShow, setAddToCartModalShow] = useState(false);
  const [checkBox1, setCheckBox1] = useState(false);
  const [SubscribeModalValue, setSubscribeModalValue] = useState();
  const [SlotbyDate, setSlotbyDate] = useState([]);
  const [ActiveClass, setActiveClass] = useState(false);
  const [StartDate, setStartDate] = useState("");
  const [PassedDate, setPassedDate] = useState("");
  const [AvailableCartData, setAvailableCartData] = useState("");
  const [TempSlot, setTempSlot] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [AvailableSlotLoaing, setAvailableSlotLoaing] = useState(false);
  const [AvailableCours, setAvailableCours] = useState([]);
  const [AvailableCoursLoading, setAvailableCoursLoading] = useState(false);
  const [NewTimeZOnw, setNewTimeZOnw] = useState("");
  const [CourtOnlyView, SetCourtOnlyView] = useState(false);
  const [BookingDates, setBookingDates] = useState([]);
  const [AvailableSlot, setAvailableSlot] = useState({
    startdate: "",
    endDate: "",
    data: "",
    is_available_for_booking: "",
    price: "",
  });

  async function getBookingDates() {
    setLoading(true);
    let body = {
      trainer_id: props?.CoachDetail?.trainer_id,
      limit: 90,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_GET_BOOKING_DATES,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setNewTimeZOnw(response?.data.data.timezone);
      setBookingDates(response?.data.data.list);
      // debugger;
      getSlotbyDate(
        PassedDate !== "" ? PassedDate : response?.data?.data?.list[0]?.date
      );
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function getSlotbyDate(date, value) {
    setAvailableCours([]);
    setStartDate(date);
    setActiveClass(date);
    setSlotLoading(true);
    let body = {
      trainer_id: props?.CoachDetail?.trainer_id,
      date: date !== "" ? moment(date).format("yyyy-MM-DD") : "",
      // is_available_for_booking : slot
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_GET_SLOT_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setSlotLoading);
    if (response?.isValidate) {
      let date = response?.data.data.list.map((item) => {
        if (PassedDate === item.start_time) {
          item.active = true;
        }

        return item;
      });
      setSlotbyDate(date);
      setTempSlot(date);
      fnAvailableSlotsFromApi(checkBox, date);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getBookingDates();
  }, []);

  const fnAvailableSlots = (event) => {
    setCheckBox(event?.target?.checked);
    const checkedValue = event.target.checked ? 1 : 0;
    SetCourtOnlyView(false);
    let data = [];
    setAvailableSlot({
      startdate: "",
      endDate: "",
      data: "",
      is_available_for_booking: "",
      price: "",
    });
    setAvailableCours([]);
    if (checkedValue === 1) {
      SetCourtOnlyView(false);
      data = SlotbyDate.filter((itm) => {
        itm.active = false;
        return itm.is_available_for_booking === checkedValue;
      });
      setSlotbyDate(data);
    } else {
      setSlotbyDate(
        TempSlot.map((item) => {
          item.active = false;
          return item;
        })
      );
    }
  };

  const fnAvailableSlotsFromApi = (event, slotData) => {
    let checkedValue = 1;
    if (event?.target?.checked) {
      setCheckBox(event?.target?.checked);
      checkedValue = event.target.checked ? 1 : 0;
    } else {
      checkedValue = event ? 1 : 0;
    }
    let data = [];
    if (checkedValue === 1) {
      data = slotData.filter((itm) => {
        return itm.is_available_for_booking === checkedValue;
      });
      setSlotbyDate(data);
    } else {
      setSlotbyDate(slotData);
    }
  };

  function fnAddtoCartModal(item) {
    // debugger
    setAddToCartModalShow(true);
    setAvailableCartData(item);
  }

  function fnSubscribeModal(item) {
    setSubscribeModalShow(true);
    setSubscribeModalValue(item);
  }

  return (
    <div className="CoachDetailModal-main">
      <Modal className="CoachDetailModal" size="lg" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Coach Detail
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={4} md={4}>
              <div className="text-center pe-3">
                <div className="width100 m-auto">
                  {props?.CoachDetail?.trainer_image !== null &&
                  props?.CoachDetail?.trainer_image !== "" &&
                  props?.CoachDetail?.trainer_image !== undefined ? (
                    <img
                      src={props?.CoachDetail?.trainer_image}
                      alt="club-detail-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imagePlaceholder1;
                      }}
                      className="w-100 my-image"
                    />
                  ) : (
                    <img
                      src={imagePlaceholder1}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imagePlaceholder1;
                      }}
                      alt="club-detail-image"
                      className="w-100  my-image"
                    />
                  )}
                </div>
                <div className="page-top-section-middle-available-content-single-content mt-2">
                  <h6>{props?.CoachDetail?.trainer_name}</h6>
                  <p>{props?.CoachDetail?.club_details?.club_name}</p>
                  {props?.CoachDetail?.rate !== null && (
                    <div className="page-top-section-middle-booked-content">
                      <h5>
                        $
                        {props?.CoachDetail?.rate === 0
                          ? "0.00"
                          : props?.CoachDetail?.rate?.toFixed(2)}
                      </h5>
                    </div>
                  )}
                  <div className="page-top-section-middle-available-content-single-content-inner justify-content-between">
                    {/* <div className="page-top-section-middle-available-content-single-content-inner-single">
                      <img src={graph} alt="graph" />
                      <span>0-4</span>
                    </div>
                    <div className="page-top-section-middle-available-content-single-content-inner-single">
                      <img src={clock} alt="starIcon" />
                      <span>30 mins</span>
                    </div> */}
                    {/* <div className="page-top-section-middle-available-content-single-content-inner-single">
                      <img src={star} alt="starIcon" />
                      <span>
                        {props?.CoachDetail?.trainer_avg_rating === 0
                          ? "0.0"
                          : props?.CoachDetail?.trainer_avg_rating?.toFixed(1)}
                      </span>
                    </div> */}
                  </div>
                </div>

                {props?.club_details?.club_image !== null &&
                props?.club_details?.club_image !== "" &&
                props?.club_details?.club_image !== undefined ? (
                  <img
                    src={props?.club_details?.club_image}
                    alt="club-detail-image"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imagePlaceholder1;
                    }}
                    className="w-100 mt-5"
                  />
                ) : (
                  <img
                    src={imagePlaceholder1}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imagePlaceholder1;
                    }}
                    alt="club-detail-image"
                    className="w-100 mt-5"
                  />
                )}
              </div>
            </Col>
            <Col xl={8} md={8}>
              <div className="CoachDetailModal-book-court-section-wrapper">
                <div className="book-court-section">
                  <div className="book-court-calendar">
                    {BookingDates?.length > 0 ? (
                      <>
                        <h5>Book court</h5>
                        {LoginInfo?.is_subscriber !== 1 && (
                          <div
                            onClick={() => setSubscribeModalShow(true)}
                            className="top-subcribe-modal-opener"
                          >
                            <div className="top-subcribe-modal-opener-img">
                              <img src={crown} alt="crown" />
                            </div>
                            <div className="top-subcribe-modal-opener-content">
                              <h6>Rate Benefits</h6>
                              <p>
                                Subscribe our Plan to get{" "}
                                <span>10% benefits</span> on rates of Clubs and
                                Coaches{" "}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="book-court-month-arrows">
                          <img src={previous} alt="prev" />
                          <div className="text-center">
                            <h4>
                              {moment(new Date(StartDate)).format("MMMM yyyy")}
                            </h4>
                            <span>{NewTimeZOnw}</span>
                          </div>
                          <img src={next} alt="next" />
                        </div>
                        <div className="book-court-calendar-day-date-wrapper">
                          {BookingDates.map((b_item, b_index) => (
                            <div className="book-court-calendar-day-date">
                              <span
                                onClick={() => getSlotbyDate(b_item.date)}
                                className={
                                  commonservices.getDateInFormatCustom(
                                    b_item.date,
                                    NewTimeZOnw,
                                    "DD-MM-yyyy"
                                  ) ===
                                  commonservices.getDateInFormatCustom(
                                    ActiveClass,
                                    NewTimeZOnw,
                                    "DD-MM-yyyy"
                                  )
                                    ? //
                                      // moment(
                                      //   commonservices.NewUTCFormat(b_item.date)
                                      // ).format("DD-MM-yyyy") ===
                                      // moment(
                                      //   commonservices.NewUTCFormat(ActiveClass)
                                      // ).format("DD-MM-yyyy")
                                      "date-number active"
                                    : "date-number"
                                }
                              >
                                {commonservices.getDateInFormatCustom(
                                  b_item.date,
                                  NewTimeZOnw,
                                  "D"
                                )}
                              </span>
                              <span className="date-day">
                                {commonservices.getDateInFormatCustom(
                                  b_item.date,
                                  NewTimeZOnw,
                                  "ddd"
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <>Slot not available</>
                    )}
                  </div>
                  <div className="show-available-slots-section-wrapper">
                    {/* <div className="legends-of-available-slots-content"> */}
                    {SlotbyDate?.length > 0 && (
                      <>
                        <>
                          <div className="show-available-slots-section">
                            <div className="d-flex align-items-center gap-2">
                              <h6>Show Available Slots</h6>
                            </div>
                            <div className="pickler-switches">
                              <Form.Check
                                type="switch"
                                id="custom-switch"
                                label=""
                                checked={checkBox}
                                onChange={fnAvailableSlots}
                              />
                            </div>
                          </div>
                          <div className="legends-of-available-slots">
                            <div className="legends-of-available-slots-single">
                              <div></div>
                              <p>Drop in Slots</p>
                            </div>
                            <div className="legends-of-available-slots-single">
                              <div style={{ background: "white" }}></div>
                              <p>Normal Slots</p>
                            </div>
                          </div>
                        </>
                        <div className="show-available-slots-section-wrapper">
                          <div className="legends-of-available-slots-content">
                            {SlotLoading ? (
                              <Loader
                                type={LoaderType.AVAILABLE_SLOT}
                                count={6}
                              />
                            ) : (
                              <>
                                {SlotbyDate.map((sl_item, sl_index) => {
                                  return sl_item.slot_type !== "drop_in" ? (
                                    <div
                                      // onClick={()=>alert()}
                                      onClick={() => fnAddtoCartModal(sl_item)}
                                      className={`legends-of-available-slots-inner-para ${
                                        sl_item.active ? "active" : ""
                                      }`}
                                      // className={`legends-of-available-slots-inner-para ${
                                      //   (StartDate !== "" &&
                                      //     StartDate === sl_item.start_time &&
                                      //     EndDate !== "" &&
                                      //     EndDate === sl_item.end_time) ||
                                      //   sl_item.active
                                      //     ? "active"
                                      //     : ""
                                      // }`}
                                      key={sl_index}
                                      // onClick={() => {
                                      //   fnOnSETimeClick(sl_item);
                                      // }}
                                      // onClick={() => {
                                      //   SetCourtOnlyView(true);
                                      //   SlotbyDate.forEach(
                                      //     (item) => (item.active = false)
                                      //   );
                                      //   sl_item.active = true;
                                      //   // fngetCourtsbydate(sl_item);
                                      // }}
                                    >
                                      <p>
                                        {commonservices.getTimeInFormat(
                                          sl_item.start_time,
                                          NewTimeZOnw
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          sl_item.end_time,
                                          NewTimeZOnw
                                        )}
                                        {LoginInfo?.is_subscriber === 1 ? (
                                          <>
                                            <br></br>
                                            <b>
                                              $
                                              {sl_item.discount_price?.toFixed(
                                                2
                                              )}
                                            </b>
                                          </>
                                        ) : (
                                          <>
                                            <br></br>
                                            <b>${sl_item.price?.toFixed(2)}</b>
                                          </>
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() => fnAddtoCartModal(sl_item)}
                                      // onClick={() => {
                                      //   SetCourtOnlyView(false);
                                      //   // SetDropinCourtOnlyView(true);
                                      //   SlotbyDate.forEach(
                                      //     (item) => (item.active = false)
                                      //   );
                                      //   sl_item.active = true;
                                      //   // fngetDropinParticipants(sl_item);
                                      // }}
                                      className={`legends-of-available-slots-inner-para ${
                                        sl_item.active ? "active" : ""
                                      }`}
                                      // className={`legends-of-available-slots-inner-para ${
                                      //   (StartDate !== "" &&
                                      //     StartDate === sl_item.start_time &&
                                      //     EndDate !== "" &&
                                      //     EndDate === sl_item.end_time) ||
                                      //   sl_item.active
                                      //     ? "active"
                                      //     : ""
                                      // }`}
                                    >
                                      <p style={{ background: "#ffeced" }}>
                                        {commonservices.getTimeInFormat(
                                          sl_item.start_time,
                                          NewTimeZOnw
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          sl_item.end_time,
                                          NewTimeZOnw
                                        )}
                                        <div>
                                          {LoginInfo?.is_subscriber === 1 ? (
                                            <>
                                              <br></br>
                                              <b>
                                                $
                                                {sl_item.discount_price?.toFixed(
                                                  2
                                                )}
                                              </b>
                                            </>
                                          ) : (
                                            <>
                                              <br></br>
                                              <b>
                                                $
                                                {sl_item.original_price?.toFixed(
                                                  2
                                                )}
                                              </b>
                                            </>
                                          )}
                                          {/* <b>
                                        ${sl_item.original_price.toFixed(2)}
                                      </b> */}
                                        </div>
                                      </p>
                                      <div className="slots-out-of-all">
                                        <img src={group} alt="group" />
                                        <p>{sl_item.booked_max_count}</p>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {CourtOnlyView && (
                      <>
                        <div className="show-available-courts-only-section">
                          {CourtOnlyView && (
                            <div className="show-available-courts-only-section-header">
                              <h4>Show Available Courts Only</h4>
                              <div className="pickler-switches">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                  checked={checkBox1}
                                  // onChange={fnAvailableCours}
                                />
                              </div>
                            </div>
                          )}

                          <div className="show-available-courts-only-content">
                            {AvailableCoursLoading ? (
                              <Loader
                                type={LoaderType.InviteFriend}
                                count={4}
                              />
                            ) : (
                              <>
                                {AvailableCours?.map((item, index) => (
                                  <div
                                    className={
                                      item.is_available_for_booking === 0
                                        ? "gray show-available-courts-only-content-single"
                                        : "show-available-courts-only-content-single"
                                    }
                                  >
                                    <div>
                                      <h6>{item?.court_title}</h6>
                                      <span>{item?.game_type_term}</span>
                                    </div>
                                    <div>
                                      {item.is_available_for_booking === 0 ? (
                                        <button
                                          className="red-btn red-border"
                                          // onClick={() => fnAddtoCartModal(item)}
                                        >
                                          $
                                          {item?.standard_price === 0
                                            ? "0.00"
                                            : item?.standard_price.toFixed(2)}
                                        </button>
                                      ) : (
                                        <button
                                          className="red-btn red-border"
                                          // onClick={() =>
                                          //   fnAddtoCartModal(item)
                                          // }
                                        >
                                          $
                                          {item?.standard_price === 0
                                            ? "0.00"
                                            : item?.standard_price.toFixed(2)}
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}

                    {AvailableSlot?.data !== "" && (
                      <>
                        <div className="show-available-courts-only-section">
                          <div className="show-available-courts-only-section-header">
                            <h4>
                              Participants(
                              {AvailableSlot.data?.total_booked_people}/
                              {AvailableSlot.data?.total_maximum_people})
                            </h4>
                            <div className="see-all-participants">
                              <p>see all</p>
                              <img src={next} alt="next" />
                            </div>
                          </div>
                          {AvailableSlotLoaing ? (
                            <Loader type={LoaderType.InviteFriend} count={4} />
                          ) : (
                            <div className="show-available-courts-only-content">
                              <div className="dark-badge-item-wrapper">
                                {AvailableSlot.data?.sportsmenship_term_count
                                  ?.length > 0 &&
                                  AvailableSlot.data?.sportsmenship_term_count.map(
                                    (item, index) =>
                                      item.value > 0 && (
                                        <div className="dark-badge-item">
                                          {item?.value} {item?.title}
                                        </div>
                                      )
                                  )}
                              </div>

                              <div className="players">
                                {AvailableSlot.data?.players?.length > 0 &&
                                  AvailableSlot.data?.players.map(
                                    (item, index) => (
                                      <>
                                        <div className="players-inner-wrapper ">
                                          <div className="activity-section-bottom-single-bottom-image">
                                            {item?.profile_pic !== null &&
                                            item?.profile_pic !== "" &&
                                            item?.profile_pic !== undefined ? (
                                              <img
                                                src={item?.profile_pic}
                                                alt="club-detail-image"
                                                onError={({
                                                  currentTarget,
                                                }) => {
                                                  currentTarget.onerror = null;
                                                  currentTarget.src =
                                                    imagePlaceholder1;
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={imagePlaceholder1}
                                                alt="club-detail-image"
                                              />
                                            )}
                                            {/* {item?.value} {item?.title} */}
                                          </div>
                                          <div className="players-inner-wrapper-content">
                                            <h6>{item?.member_name}</h6>
                                            <h6>
                                              {item?.avg_rating !== null
                                                ? item?.avg_rating.toFixed(1)
                                                : "0.0"}
                                            </h6>
                                          </div>
                                        </div>
                                      </>
                                    )
                                  )}
                              </div>
                              {AvailableSlot?.is_available_for_booking === 1 ? (
                                <>
                                  <div className="join-btn-and-price">
                                    <div className="join-btn-and-price-price">
                                      ${AvailableSlot?.price.toFixed(2)}
                                    </div>
                                    <Button
                                      // onClick={() =>
                                      //   fnNotifydropinbooking(AvailableSlot)
                                      // }
                                      // onClick={() =>
                                      //   fnAddtoJoin(AvailableSlot)
                                      // }
                                      className="red-btn red-border"
                                    >
                                      Join
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="join-btn-and-price">
                                    <div className="join-btn-and-price-price">
                                      ${AvailableSlot?.price.toFixed(2)}
                                    </div>
                                    <Button
                                      // onClick={() =>
                                      //   fnNotifydropinbooking(AvailableSlot)
                                      // }
                                      className="red-btn red-border"
                                    >
                                      Notify
                                    </Button>
                                  </div>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className="d-flex gap-3 w-100 CoachDetailModal-book-court-section-buttons">
            <button className="gray-btn w-100">Add to Cart</button>
            <button className="red-btn red-border w-100">Book Now</button>
          </div>
        </Modal.Footer> */}
      </Modal>
      {AddToCartModalShow && (
        <AddToCartModal
          NewTimeZone={NewTimeZOnw}
          bindList={getSlotbyDate}
          show={AddToCartModalShow}
          Type="BookingFromTrainer"
          AvailableCartData={AvailableCartData}
          CoachDetail={props?.CoachDetail}
          onHide={() => setAddToCartModalShow(false)}
        />
      )}
      {SubscribeModalShow && (
        <SubsciptionModal
          show={SubscribeModalShow}
          Tag="coach"
          SubscribeModalValue={SubscribeModalValue}
          onHide={() => setSubscribeModalShow(false)}
        />
      )}
    </div>
  );
}

export default CoachDetailModal;
