import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Range, getTrackBackground } from "react-range";
import logo from "../images/onlylogo.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { useDispatch, useSelector } from "react-redux";
import { ClubFilter, ClubFilterDate } from "../Redux/Action";

const FindClubFilterModal = (props) => {
  // debugger;
  const FilterData = useSelector((state) => state.userinfo.ClubFilter);
  let dispatch = useDispatch();
  //  const RangeNumber = [5, 10, 15, 20, 30, "ALL"];
  const RangeNumber = [5, 10, 15, 20, 30, 0];
  const STEP = 5;
  const MIN = 5;
  const MAX = 60;
  const labels = Array.from({ length: (MAX - MIN) / STEP + 1 }, (_, i) =>
    Math.round(MIN + i * STEP)
  );
  const [input, setInput] = useState({
    sortBy: "",
    gameType: "",
    dropCount: "",
    rangeValue: "30",
    startDate: "",
    endDate: "",
    latitude: "",
    longitude: "",
  });
  const [values, setValues] = useState([5]);
  // const [MIN1, setMIN1] = useState();
  const STEP1 = 1;
  const MIN1 = 0;
  const MAX1 = 5;

  const [Loading, setLoading] = useState(false);
  const [SortByTerm, setSortByTerm] = useState([]);
  const [GameTerm, setGameTerm] = useState([]);

  async function getSportTermList() {
    setLoading(true);
    let body = {
      term: "game_type",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setGameTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSortByTermList() {
    setLoading(true);
    let body = {
      term: "club_sort_by",
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setSortByTerm(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  useEffect(() => {
    getSportTermList();
    getSortByTermList();
    setInput({
      ...input,
      ["startDate"]:
        FilterData !== "" && FilterData?.startDate !== ""
          ? new Date(FilterData?.startDate)
          : new Date(),
      ["endDate"]:
        FilterData !== "" && FilterData?.endDate !== ""
          ? new Date(FilterData?.endDate)
          : new Date(),
      ["sortBy"]: FilterData !== "" ? FilterData?.sortBy : "",
      ["gameType"]: FilterData !== "" ? FilterData?.gameType : "",
      ["dropCount"]: FilterData !== "" ? FilterData?.dropCount : "",
      ["rangeValue"]: FilterData !== "" ? FilterData?.rangeValue : "0",
    });

    setValues([RangeNumber.indexOf(FilterData?.rangeValue)]);
  }, [FilterData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      sortBy: input.sortBy,
      gameType: input.gameType,
      dropCount: input.dropCount,
      rangeValue: RangeNumber[values[0]],
      startDate: input.startDate,
      endDate: input.endDate,
      latitude: input.latitude,
      longitude: input.longitude,
    };
    dispatch(ClubFilter(body));
    props.onHide();
  };

  const handleDateReset = (date) => {
    if (date === "date") {
      let body = {
        sortBy: input.sortBy,
        gameType: input.gameType,
        dropCount: input.dropCount,
        rangeValue: RangeNumber[values[0]],
        latitude: input.latitude,
        longitude: input.longitude,
        startDate: "",
        endDate: "",
      };
      dispatch(ClubFilter(body));
      props.onHide();
    } else {
      let body = {
        sortBy: "",
        gameType: "",
        dropCount: "",
        rangeValue: 0,
        startDate: input.startDate,
        endDate: input.endDate,
        latitude: input.latitude,
        longitude: input.longitude,
      };
      dispatch(ClubFilter(body));
      props.onHide();
    }
  };

  function funLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let temp = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };

          setInput((prevInput) => ({
            ...prevInput,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            // rangeValue:
            //   prevInput.rangeValue !== "30" ? prevInput.rangeValue : "",
          }));

          localStorage.setItem("Location", JSON.stringify(temp));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  return (
    <div className="FindClubFilterModal-Main">
      <Modal
        className="find-club-date-select-modal-main"
        size="md"
        centered
        {...props}
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>
              <img src={logo} alt="logo" />
            </div>
            <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
            <div style={{ visibility: "hidden" }}>
              <img src={logo} alt="logo" />
            </div>
          </Modal.Header>

          {props.FilterType === "Datetime" ? (
            <>
              <Modal.Body>
                <div className="find-club-filter-wrapper">
                  <h6>Select Date & Time</h6>
                  <div className="find-club-filter-datepicker-main">
                    <div className="find-club-filter-datepicker-single">
                      <p>Start Date</p>
                      <DatePicker
                        minDate={new Date()}
                        selected={
                          input.startDate !== "" ? input.startDate : new Date()
                        }
                        onChange={(date) => {
                          funLocation();
                          if (date > input.endDate) {
                            setInput({
                              ...input,
                              ["startDate"]: date,
                              ["endDate"]: date,
                            });
                          } else {
                            setInput({
                              ...input,
                              ["startDate"]: date,
                            });
                          }
                        }}
                      />
                    </div>
                    <div className="find-club-filter-datepicker-single">
                      <p>End Date</p>
                      <DatePicker
                        selected={
                          input.endDate !== "" ? input.endDate : new Date()
                        }
                        minDate={input.startDate}
                        onChange={(date) => {
                          funLocation();
                          setInput({
                            ...input,
                            ["endDate"]: date,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
                  <Button
                    onClick={() => handleDateReset("date")}
                    className="white-btn dark-color w-50"
                  >
                    Reset
                  </Button>
                  <button type="submit" className="red-btn w-50">
                    Apply
                  </button>
                </div>
              </Modal.Footer>
            </>
          ) : (
            <>
              <Modal.Body>
                <div className="find-club-filter-wrapper">
                  <div className="find-club-filter-inner">
                    <h6>Sort by</h6>
                    <div
                      className="find-club-card-timing"
                      style={{ maxWidth: "100%" }}
                    >
                      {SortByTerm.length > 0 &&
                        SortByTerm.map((item, index) => (
                          <button
                            className={
                              item.value === input.sortBy
                                ? "filter-trans-btn  active"
                                : "filter-trans-btn"
                            }
                            type="button"
                            value={item.value}
                            onClick={() => {
                              funLocation();
                              if (item.value === "Distance") {
                                setValues([4]);
                                // return;
                              }
                              if (item.value === "") {
                                // funLocation();
                                setInput((prevInput) => ({
                                  ...prevInput,
                                  sortBy:
                                    item.value !== prevInput.sortBy
                                      ? item.value
                                      : "",
                                }));
                              } else {
                                // localStorage.removeItem("Location");
                                setInput((prevInput) => ({
                                  ...prevInput,
                                  sortBy:
                                    item.value !== prevInput.sortBy
                                      ? item.value
                                      : "",
                                }));
                              }
                            }}
                          >
                            {item.value}
                          </button>
                        ))}
                    </div>
                  </div>
                  <div className="find-club-filter-inner">
                    <h6>Game Type</h6>
                    <div className="find-club-card-timing ">
                      {GameTerm.length > 0 &&
                        GameTerm.map((item, index) => (
                          <button
                            className={
                              item.value === input.gameType
                                ? "filter-trans-btn active"
                                : "filter-trans-btn"
                            }
                            type="button"
                            onClick={() => {
                              funLocation();
                              setInput((prevInput) => ({
                                ...prevInput,
                                gameType:
                                  item.value !== prevInput.gameType
                                    ? item.value
                                    : "",
                              }));
                            }}
                          >
                            {item.value}
                          </button>
                        ))}
                    </div>
                  </div>
                  <div className="dropIn-court">
                    <input
                      type="checkbox"
                      id="DropInCourt"
                      name="DropInCourt"
                      value="drop_in"
                      onChange={(e) => {
                        funLocation();
                        const isChecked = e.target.checked;
                        setInput({
                          ...input,
                          dropCount: isChecked ? e.target.value : "",
                        });
                      }}
                      checked={input.dropCount === "drop_in"}
                    />
                    <label htmlFor="DropInCourt"> Open Play Court</label>
                  </div>
                  <div className="find-club-filter-inner">
                    <h6 style={{ marginBottom: "5px" }}>
                      Distance{" "}
                      <span style={{ fontWeight: "400" }}>
                        (5 miles - 30 miles)
                      </span>
                    </h6>
                    <span className="very-small-text">
                      Select the maximum search distance
                    </span>
                    <div className="range-parent">
                      <div className="dot-range-parent dot-range-parent-number">
                        {RangeNumber?.length > 0 &&
                          RangeNumber.map((item, index) => (
                            <span>{item === 0 ? "All" : item}</span>
                          ))}
                      </div>

                      <div
                        style={{
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          background: "#eee",
                          borderRadius: "25px",
                        }}
                      >
                        <Range
                          values={values}
                          step={STEP1}
                          min={MIN1}
                          max={MAX1}
                          onChange={(newValues) => {
                            if (
                              input.sortBy === "Distance" &&
                              newValues[0] === 5
                            ) {
                              setValues([4]);
                              return;
                            }
                            
                            funLocation();
                            setValues(newValues);
                          }}
                          renderTrack={({ props, children }) => (
                            <div
                              onMouseDown={props.onMouseDown}
                              onTouchStart={props.onTouchStart}
                              style={{
                                ...props.style,
                                height: "24px",
                                display: "flex",
                                width: "100%",
                                // padding: "10px"
                              }}
                              className=""
                            >
                              <div
                                ref={props.ref}
                                style={{
                                  height: "18px",
                                  width: "100%",
                                  borderRadius: "30px",
                                  background: getTrackBackground({
                                    values,
                                    colors: ["#ef333f", "#eee"],
                                    min: MIN1,
                                    max: MAX1,
                                  }),
                                  alignSelf: "center",
                                }}
                              >
                                {children}
                              </div>

                              <div className="dot-range-parent">
                                {RangeNumber?.length > 0 &&
                                  RangeNumber.map((item, index) => (
                                    <span
                                      className="dot-range"
                                      style={
                                        index === 0 ||
                                        index === RangeNumber.length - 1
                                          ? {
                                              background: "transparent",
                                            }
                                          : {
                                              background:
                                                index > values[0]
                                                  ? "black"
                                                  : "white",
                                            }
                                      }
                                    ></span>
                                  ))}
                              </div>
                            </div>
                          )}
                          renderThumb={({ props, isDragged }) => (
                            <div
                              {...props}
                              style={{
                                ...props.style,
                                height: "20px",
                                width: "20px",
                                borderRadius: "50%",
                                backgroundColor: "#ef333f",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "0px 2px 6px #AAA",
                              }}
                            >
                              <div
                                style={{
                                  height: "5px",
                                  borderRadius: "50%",
                                  width: "5px",
                                  backgroundColor: isDragged ? "#fff" : "#fff",
                                }}
                              />
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
                  <button
                    onClick={() => handleDateReset("null")}
                    className="white-btn dark-color w-50"
                  >
                    Reset
                  </button>
                  <button type="submit" className="red-btn w-50">
                    Apply
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default FindClubFilterModal;
