export const API_URL = {
  BASEURL: process.env.REACT_APP_APIBASEPATH,
  SIGNUP: "user_signup",
  GET_COUNTRY_LIST: "get_country_list",
  GET_SIGN_UP_STEPS: "get_sign_up_steps",
  GET_RATINGS: "get_ratings",
  TRAINER_SLOT_BY_DATE: "trainer/slots_by_date",
  SAVE_USER_PROFILE_STEPS: "save_user_profile_steps",
  VERIFY_OTP: "verify_otp",
  POST_REPORT: "post/report",
  POST_CREATE: "post/create",
  SENT_OTP_WITH_AUTH: "send_otp_with_auth",
  SENT_OTP_WITHOUT_AUTH: "send_otp_without_auth",
  LOGIN: "user_login",
  FORGOT_PASSWORD: "forgot_password",
  CHANGE_PASSWORD: "reset_password",
  CLUB_LIST: "club/list",
  GET_NOTIFICATIONS: "get_notifications",
  SUBSCRIPTION_LIST: "subscription/list",
  SUBSCRIPTION_DETAILS: "subscription/details",
  MY_WALLET: "my_wallet",
  GET_USER_PROFILE: "get_user_profile",
  MEMBER_BLOCK_LIST: "member/block_list",
  MY_EPHEMERAL_KEY: "get_ephemeral_key",
  SUBSCRIPTION_BUY_SUBSCRIPTION: "subscription/buy_subscription",
  CLUB_DETAILS: "club/details",
  SUGGESTED_PEOPLE: "suggested_people",
  ORDER_BOOKING_DETAILS: "order/booking_details",
  GET_SHEDULE_DATES_BY_YEAR: "get_schedule_dates_by_year",
  GET_SHEDULES_BY_DATE: "get_schedules_by_date",
  TRAINER_TRAINER_DETAILS: "trainer/trainer_details",
  ORDER_CANCEL: "order/cancel",
  ORDER_PLAYERS_LEAVE_INVITATION: "order/players/leave_invitation",
  ACTIVITY_LIST: "activity/list",
  TRAINER_SAVE_AVAILABILITY: "trainer/save_availability",  
  CLUB_GET_BOOKING_DATES: "club/get_booking_dates",
  TRAINER_GET_BOOKING_DATES: "trainer/get_booking_dates",
  CLUB_GET_SLOT_BY_DATE: "club/get_slots_by_date",
  TRAINER_GET_SLOT_BY_DATE: "trainer/get_slots_by_date",
  MEMBER_CONNECTIONS: "member/connections",
  ORDER_BOOKING_CONNECTIONS: "order/booking_connections",
  GROUP_ADD_UPDATE: "group/add_update",
  GET_CLUB_BY_TIME: "get_club_by_time",
  GET_TRAINER_BY_TIME: "get_trainer_by_time",
  CLUB_GET_DROPIN_PARTICIPANTS: "club/get_dropin_participants",
  CLUB_GET_COURTS_BY_DATE: "club/get_courts_by_date",
  CLUB_NOTIFY_DROPIN_BOOKING: "club/notify_dropin_booking",
  ACTIVITY_SAVE: "activity/save",
  SAVE_RATING: "save_rating",
  POST_LIST: "post/list",
  POST_GET_DASHBOARD_FEEDS: "post/get_dashboard_feeds",
  GROUP_LIST: "group/list",
  ORDER_LIST: "order/list",
  GET_MEMBER_PROFILE: "get_member_profile",
  ORDER_UPLOAD_SCORE: "order/upload_score",
  ORDER_BOOK: "order/book",
  ADD_MONEY_TO_WALLET: "add_money_to_wallet",
  WALLET_DETAILS: "wallet_details",
  CART_LIST: "cart/list",
  CART_GET_ORDER_CONFIRMATION_DETAILS: "cart/get_order_confirmation_details",
  ORDER_GET_ORDER_STATUS: "order/get_order_status",
  CART_REMOVE: "cart/remove",
  POST_DELETE: "post/delete",
  POST_DELETE_COMMENT: "post/delete_comment",
  ORDER_PLAYER_REMOVE: "order/player/remove",
  TRAINER_SLOT_DELETE: "trainer/slot/delete",
  TRAINER_DELETE_SLOT_BY_DATE: "trainer/delete_slots_by_date",
  CART_ADD: "cart/add",
  ORDER_PLAYER_SAVE: "order/players/save",
  SUBSCRIPTION_CANCEL: "subscription/cancel",
  ORDER_PLAYER_SAVE_LIMIT: "order/players/save_limit",
  GET_TERMS: "get_terms",
  TRAINER_GET_AVAILABILITY_SLOTS: "trainer/get_availability_slots",
  TRAINER_SCHEDULE_DATES: "trainer/schedule_dates",
  SETTINGS_MATCHES_SCORES: "settings/matches_scores",
  TRAINER_MY_APPOINTMENTS: "trainer/my_appointments",
  UPDATE_USER_PROFILE: "update_user_profile",
  CLUB_SAVE_CLUB_DETAILS: "club/save_club_details",
  SAVE_ADDRESS: "save_address",
  CLUB_ADD_FAVOURITE: "club/add_favourite",
  TRAINER_LIST: "trainer/list",
  POST_LIKE_DISLIKE_POST: "post/like_dislike_post",
  GROUP_GET_GROUP_DETAIL: "group/get_group_detail",
  GROUP_GET_GROUP_MEMBERS: "group/get_group_members",
  POST_ADD_INTEREST: "post/add_interest",
  POST_REMOVE_INTEREST: "post/remove_interest",
  POST_GET_LIKED_USERS: "post/get_liked_users",
  POST_ITERESTED_USERS: "post/interested_users",
  POST_GET_COMMENTS: "post/get_comments",
  POST_POST_COMMENTS: "post/post_comment",
  MEMBER_GET_FOLLOWERS_MEMBERS: "member/get_followers_members",
  MEMBER_GET_FOLLOWING_MEMBERS: "member/get_following_members",
  FOLLOW_UNFOLLOW_MEMBER: "follow_unfollow_member",
  ORDER_PLAYERS_ACCEPT_REJECT_INVITATION:
    "order/players/accept_reject_invitation",
  MEMBER_UNBLOCK: "member/unblock",
  GROUP_ACCEPT_REJECT_INVITATION: "group/accept_reject_invitation",
  DELETE_ACCOUNT: "delete_account",
  GROUP_DELETE: "group/delete",
  GROUP_LEAVE: "group/leave",
  TRAINER_GET_PAYMENT_ONBOARDING_LINK: "trainer/get_payment_onboarding_link",
  MEMBER_BLOCK: "member/block",
  ORDER_PLAYERS_ALLOW_ACCESS: "order/players/allow_access",
  GLOBAL_SEARCH: "global_search",
  GET_RECENT_UPDATES: "get_recent_updates",
  APP_SETTING: "app_setting",
  MARK_AS_READ_NOTIFICATIONS: "mark_as_read_notifications",
  CLUB_PACKAGE_LIST: "club/package/list",
  CLUB_SESSION_CREATE: "session/create",
  CLUB_SUBSCRIPTION_DETAILS: "club/subscription/details",
  CLUB_SUBSCRIPTION_CANCEL: "club/subscription/cancel",
  CLUB_GET_COUNT_BY_DATE: "club/get_count_by_date",
};
