import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader, { LoaderType } from "../components/Loader";
import imagePlaceholder from "../images/placeholder.png";
import leftarrow from "../images/previous.svg";
import { apiResponse } from "../services/APIResponse";
import { confirmAlert } from "react-confirm-alert";
import { API_URL } from "../services/APIRoute";
import deleleicon from "../images/redDelete.svg";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import moment from "moment";

const AvailabilityScheduleTimeModal = (props) => {
  // debugger;
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [ScheduledateList, setScheduledateList] = useState([]);

  async function getScheduledateList(pageno) {
    setIsLoading(true);
    try {
      let body = {
        date: props?.AvailableScheduleInfo?.booking_date,
        // page: pageno,
        // limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.TRAINER_SLOT_BY_DATE,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setIsLoading);
      if (response?.isValidate) {
        // debugger;
        //  setScheduledateList(response?.data?.data?.list);
        if (pageno === 1) {
          setScheduledateList(response?.data?.data?.list);
        } else {
          setScheduledateList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getScheduledateList(pageNumber);
  }, []);
  const handleLoadMore = () => {
    getScheduledateList(pageNumber);
  };

  async function fnDeleteSlot(item) {
    let body = {
      booking_slot_id: item.booking_slot_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_SLOT_DELETE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getScheduledateList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  async function fnDeleteSlotByDate(item) {
    let body = {
      date: item.booking_date,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_DELETE_SLOT_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      props.bindList()
      props.onHide()
      // getScheduledateList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  console.log("ScheduledateList ppp::", ScheduledateList);
  return (
    <>
      <Modal className="court-ratings-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Availability Schedule
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body id="global-search">
          <div className="settings-availability-schedule-main-wrapper">
            <div className="d-flex align-items-center justify-content-between appointment-detail-title">
              <h5 className="mb-0">Availability Schedules</h5>
              <img
                onClick={() =>
                  confirmAlert({
                    title: "Are you sure want remove this schedule?",
                    // message: "This action cannot be undone",
                    buttons: [
                      {
                        label: "No",
                      },
                      {
                        label: "Yes",
                        onClick: () =>
                          fnDeleteSlotByDate(props?.AvailableScheduleInfo),
                      },
                    ],
                  })
                }
                src={deleleicon}
                alt="deleleicon"
              />
            </div>
            <div className="single-day-settings-availability-schedule">
              <div className="d-flex justify-content-between align-items-center settings-availability-inner-main mb-3">
                <h6 className="m-0">
                  {moment(props?.AvailableScheduleInfo?.booking_date).format(
                    "DD MMM yyyy"
                  )}
                </h6>
                <span className="dark-badge">
                  {moment(props?.AvailableScheduleInfo?.booking_date).format(
                    "dddd"
                  )}
                </span>
              </div>
              {isLoading ? (
                <Loader type={LoaderType.InviteFriend} count={3} />
              ) : (
                <div className="single-day-settings-availability-wrapper">
                  {ScheduledateList?.length > 0 &&
                    ScheduledateList.reverse().map((item, index) => (
                      <div className="single-day-settings-availability-wrapper-single">
                        <p>
                          {commonservices.getTimeInFormat(item?.start_time)} -
                          {commonservices.getTimeInFormat(item?.end_time)}
                        </p>
                        <img
                          src={deleleicon}
                          alt="deleleicon"
                          onClick={() =>
                            confirmAlert({
                              title: "Are you sure you want delete slot?",
                              // message: "This action cannot be undone",
                              buttons: [
                                {
                                  label: "No",
                                },
                                {
                                  label: "Yes",
                                  onClick: () => fnDeleteSlot(item),
                                },
                              ],
                            })
                          }
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AvailabilityScheduleTimeModal;
