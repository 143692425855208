import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import RangeRatting from "../components/CommonComponents/RangeRatting";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";

function RateCourtModal(props) {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [ClubRating, setClubRating] = useState([]);
  const [ClubRatingPeople, setClubRatingPeople] = useState([]);
  const [LoadingPeople, setLoadingPeople] = useState(false);
  const [selectedRating, setSelectedRating] = useState({});
  const minRange = 1;
  const maxRange = 5;
  const step = 0.1;
  const RangeNumber = [];
  for (let i = minRange; i <= maxRange; i += step) {
    RangeNumber.push(i.toFixed(1));
  }
  const [input, setInput] = useState([
    {
      rating_term_id: 0,
      rating: 0,
    },
  ]);

  async function getClubRateList() {
    let body = {
      term: "club_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRating(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getClubRatePeopleList() {
    // setLoading(true);
    let body = {
      term: "member_rating",
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_TERMS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setClubRatingPeople(response.data.data.terms);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnCustomRangeSelect(item, id) {
    setSelectedRating((prevSelectedRating) => ({
      ...prevSelectedRating,
      [id]: item?.toFixed(1),
    }));

    setInput((prevInput) => {
      return [
        ...prevInput,
        {
          rating_term_id: id,
          rating: item?.toFixed(1),
        },
      ];
    });
  }
  useEffect(() => {
    getClubRateList();
    getClubRatePeopleList();
  }, []);

  useEffect(() => {
    const initialSelectedRating = {};
    ClubRatingPeople.forEach((item) => {
      initialSelectedRating[item.term_id] = "1.0";
    });
    setSelectedRating(initialSelectedRating);
  }, [ClubRatingPeople]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (props?.RateModalType === "Count") {
      let body = {
        to_association_id: props?.BookingDetail?.club_details?.club_id,
        to_association_type_term: "club",
        ...ClubRating.reduce((acc, item, index) => {
          const ratingTermId = item.term_id;

          if (ratingTermId !== undefined && ratingTermId !== null) {
            acc[`rating_data[${index}][rating_term_id]`] = ratingTermId;
            acc[`rating_data[${index}][rating]`] =
              selectedRating[ratingTermId] || "1.0";
          }

          return acc;
        }, {}),
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SAVE_RATING,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        props.onHide();
        props.bindList();
      }
      if (!response?.isValidate) {
      }
    }
    if (props?.RateModalType === "People") {
      setLoadingPeople(true);
      let body = {
        to_association_id: props?.Players?.member_id,
        to_association_type_term: "member",
        ...ClubRatingPeople.reduce((acc, item, index) => {
          const ratingTermId = item.term_id;

          if (ratingTermId !== undefined && ratingTermId !== null) {
            acc[`rating_data[${index}][rating_term_id]`] = ratingTermId;
            acc[`rating_data[${index}][rating]`] =
              selectedRating[ratingTermId] || "1.0";
          }

          return acc;
        }, {}),
      };

      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SAVE_RATING,
          body: body,
        },
        true
      );
      let response = apiResponse(false, resData, setLoadingPeople);
      if (response?.isValidate) {
        props.onHide();
        props.bindList();
        props.CloseModal();
      }
      if (!response?.isValidate) {
      }
    }
  };

  return (
    <div className="RateCourtModalMain">
      <Modal
        className="AddForgotPasswordModalMain"
        size="lg"
        centered
        {...props}
      >
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Header closeButton>
            <div style={{ visibility: "hidden" }}>A</div>
            <Modal.Title id="contained-modal-title-vcenter">
              {props?.RateModalType === "People" && "Rate People"}
              {props?.RateModalType === "Count" && "Rate Court "}
            </Modal.Title>
            <div style={{ visibility: "hidden" }}>A</div>
          </Modal.Header>
          {props?.RateModalType === "Count" && (
            <>
              <Modal.Body>
                <div className="rate-court-main">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <div className="booking-detail-image-main">
                      {props?.BookingDetail?.club_details?.club_image !==
                        null &&
                      props?.BookingDetail?.club_details?.club_image !== "" &&
                      props?.BookingDetail?.club_details?.club_image !==
                        undefined ? (
                        <img
                          src={props?.BookingDetail?.club_details?.club_image}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                        />
                      ) : (
                        <img src={imagePlaceholder} alt="club-detail-image" />
                      )}
                    </div>
                    <div className="booking-detail-image-content m-0 w-100">
                      <div className="courtContent">
                        <div className="court-and-court-name">
                          <h5 className="m-0">
                            {props?.BookingDetail?.club_details?.court_title}
                          </h5>
                          <p className="m-0">
                            {props?.BookingDetail?.club_details?.game_type_term}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rate-court-ratings">
                    <h4>Give Ratings </h4>
                    {ClubRating?.length > 0 &&
                      ClubRating.map((item, index) => (
                        <RangeRatting
                          type="dot"
                          item={item}
                          index={index}
                          onFinalChangeData={(value) => {
                            fnCustomRangeSelect(value, item.term_id);
                          }}
                        />
                      ))}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
                  <button type="submit" className="red-btn w-100">
                    Save
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
          {props?.RateModalType === "People" && (
            <>
              <Modal.Body>
                <div className="rate-court-main">
                  <div className="d-flex align-items-center gap-2 mb-3">
                    <div className="booking-detail-image-main">
                      {props?.Players?.profile_pic !== null &&
                      props?.Players?.profile_pic !== "" &&
                      props?.Players?.profile_pic !== undefined ? (
                        <img
                          src={props?.Players?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                        />
                      ) : (
                        <img src={imagePlaceholder} alt="club-detail-image" />
                      )}
                    </div>
                    <div className="booking-detail-image-content m-0 w-100">
                      <div className="courtContent">
                        <div className="court-and-court-name">
                          <h5 className="m-0">{props?.Players?.member_name}</h5>
                          <p className="m-0"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rate-court-ratings">
                    <h4>Give Ratings</h4>
                    {ClubRatingPeople?.length > 0 &&
                      ClubRatingPeople.map((item, index) => (
                        <RangeRatting
                          type="dot"
                          item={item}
                          index={index}
                          onFinalChangeData={(value) => {
                            fnCustomRangeSelect(value, item.term_id);
                          }}
                        />
                      ))}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="w-100 d-flex justify-content-between gap-2 modal-buttons">
                  <button type="submit" className="red-btn w-100">
                    {LoadingPeople ? "Loading..." : "Save"}
                  </button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
}

export default RateCourtModal;
