import logo from "./logo.svg";
import React, { Suspense } from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Layout from "./Layout/Layout";
import Dashboard from "./AllRoutes/Dashboard";
// import SignIn from "./AuthRoutes/sign-in";
import Home from "./AuthRoutes/Home";
import RegisterSignIn from "./AuthRoutes/RegisterSignIn";
// import Signup from "./AuthRoutes/SignUp";
import Forgot_Password from "./AuthRoutes/Forgot_Password";
import Change_Password from "./AuthRoutes/Change_Password";
import Signup_Steps from "./AuthRoutes/Signup_Steps";
import Signup from "./AuthRoutes/Signup";
import FindClub from "./AllRoutes/FindClub";
import FindCoach from "./AllRoutes/FindCoach";
import SignIn from "./AuthRoutes/SignIn";
import ClubDetails from "./AllRoutes/ClubDetails";
import MyBooking from "./AllRoutes/MyBooking";
import Cart from "./AllRoutes/Cart";
import CartConfirmation from "./AllRoutes/CartConfirmation";
import PaymentSuccess from "./AllRoutes/PaymentSuccess";
import BookingDeatil from "./AllRoutes/BookingDeatil";
import Settings from "./AllRoutes/Settings";
import ProfileDetail from "./AllRoutes/ProfileDetail";
import MobilePage from "./AllRoutes/MobilePage";
import CoachDeatail from "./AllRoutes/CoachDeatail";
import CreateGroup from "./AllRoutes/CreateGroup";
// import Signup from "./AuthRoutes/SignUp";

function App() {
  // const token = commonservices.getItem("Token");
  const token = localStorage.getItem("Token");
  const tokenStep = localStorage.getItem("TokenStep");
  const is_logged = token && tokenStep ? true : false;

  const allRoute = () => {
    return (
      <>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Layout />}>
          <Route path="" element={<Navigate to="/home" replace />} />
          <Route
            path="/home"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <Dashboard />
              </Suspense>
            }
          ></Route>
          <Route
            path="/find-club"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <FindClub />
              </Suspense>
            }
          ></Route>
          <Route
            path="/club-details/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <ClubDetails />
              </Suspense>
            }
          ></Route>
          <Route
            path="/club-details/:id/:date"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <ClubDetails />
              </Suspense>
            }
          ></Route>
          <Route
            path="/my-booking"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <MyBooking />
              </Suspense>
            }
          ></Route>
          <Route
            path="/my-booking/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <MyBooking />
              </Suspense>
            }
          ></Route>
          <Route
            path="/profile-detail/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <ProfileDetail />
              </Suspense>
            }
          ></Route>
          <Route
            path="/settings"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <Settings />
              </Suspense>
            }
          ></Route>
          <Route
            path="/create-group"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <CreateGroup />
              </Suspense>
            }
          ></Route>
          <Route
            path="/find-coach"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <FindCoach />
              </Suspense>
            }
          ></Route>
          <Route
            path="/coach-detail/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <CoachDeatail />
              </Suspense>
            }
          ></Route>
          <Route
            path="/cart"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <Cart />
              </Suspense>
            }
          ></Route>
          <Route
            path="/cart-confirmation/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <CartConfirmation />
              </Suspense>
            }
          ></Route>
          <Route
            path="/cart-confirmation"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <CartConfirmation />
              </Suspense>
            }
          ></Route>
          <Route
            path="/booking-detail/:id"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <BookingDeatil />
              </Suspense>
            }
          ></Route>
          
        </Route>
        <Route
          path="/payment-success/:id"
          exact
          element={
            <Suspense fallback={"Loader..."}>
              <PaymentSuccess />
            </Suspense>
          }
        ></Route>
        <Route
            path="/mobile-page"
            exact
            element={
              <Suspense fallback={"Loader..."}>
                <MobilePage />
              </Suspense>
            }
          ></Route>
      </>
    );
  };

  const authRoute = () => {
    return (
      <>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/" element={<Home />} />

        <Route path="/register-signin" element={<RegisterSignIn />} />
        {/* <Route path="/sign-up" element={<Signup />} /> */}
        <Route path="/forgot-password" element={<Forgot_Password />} />
        <Route path="/change-password" element={<Change_Password />} />
        <Route path="/signup-steps" element={<Signup_Steps />} />
        <Route path="/sign-up" element={<Signup />} />
        {/* <Route path="/sign-up" element={<SignUp />} /> */}
      </>
    );
  };

  return (
    <>
      {/* <Chatbot
        config={config}
        messageParser={MessageParser}
        actionProvider={ActionProvider}
      /> */}
      <ToastContainer
        theme="colored"
        containerId="an id"
        draggable={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
      />
      <Router>
        <Routes>{is_logged ? allRoute() : authRoute()}</Routes>
      </Router>
    </>
  );
}

export default App;
