import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { commonservices } from "../services/CommonServices";
import moment from "moment";
import imagePlaceholder from "../images/placeholder.png";
import court1 from "../images/findclub1.png";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CartData } from "../Redux/Action";
import BookCourtTrainerModal from "./BookCourtTrainerModal";
import { toast } from "react-toastify";
import deleteIcon from "../images/redDelete.svg";

const AddToCartModal = (props) => {
  
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [Loading, setLoading] = useState(false);
  const [TrainerData, setTrainerData] = useState();
  const [ClubData, setClubData] = useState();
  const [TrainerBookingClubModalShow, setTrainerBookingClubModalShow] =
    useState(false);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }

  async function getCartNotificationCount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_RECENT_UPDATES,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(CartData(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  async function fnAddtoCart() {
    setLoading(true);
    let body = {};
    if (props?.Type === "BookingFromTrainer") {
      
      if (ClubData !== undefined) {
        body = {
          is_buy_now: 0,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: props?.CoachDetail?.trainer_id,
            },
            {
              booking_slot_id: ClubData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      } else {
        body = {
          is_buy_now: 0,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: props?.CoachDetail?.trainer_id,
            },
          ],
        };
      }
    } else {
      if (TrainerData !== undefined) {
        body = {
          is_buy_now: 0,
          items: [
            {
              booking_slot_id: TrainerData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: TrainerData?.trainer_id,
            },
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      } else {
        body = {
          is_buy_now: 0,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      }
    }
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_ADD,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      getCartNotificationCount();
      props.bindList();
      props.onHide();
      window.location.reload();
    }
    if (!response?.isValidate) {
      toast.error(resData.message);
      console.log("Error  getting country list", response);
    }
  }

  async function fnAddtoJoin(item) {
    // let body = {
    //   is_buy_now: 1,
    //   items: [
    //     {
    //       booking_slot_id: item?.AvailableCartData?.booking_slot_id,
    //       item_type: "club_booking",
    //     },
    //   ],
    // };
    let body = {};
    if (props?.Type === "BookingFromTrainer") {
      
      if (ClubData !== undefined) {
        body = {
          is_buy_now: 1,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: props?.CoachDetail?.trainer_id,
            },
            {
              booking_slot_id: ClubData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      } else {
        body = {
          is_buy_now: 1,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: props?.CoachDetail?.trainer_id,
            },
          ],
        };
      }
    } else {
      if (TrainerData !== undefined) {
        body = {
          is_buy_now: 1,
          items: [
            {
              booking_slot_id: TrainerData?.booking_slot_id,
              item_type: "trainer_booking",
              trainer_id: TrainerData?.trainer_id,
            },
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      } else {
        body = {
          is_buy_now: 1,
          items: [
            {
              booking_slot_id: props?.AvailableCartData?.booking_slot_id,
              item_type: "club_booking",
            },
          ],
        };
      }
    }
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_ADD,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    debugger
    if (response?.isValidate) {
      navigate(`/cart-confirmation/${response?.data?.data?.cart_id}`);
    }
    if (resData?.status === 0) {
      toast.error(resData.message);
    }
  }

  function fnBookCartTrainer(item) {
    setTrainerBookingClubModalShow(true);
    
  }
  console.log("TrainerData pppp", TrainerData);
  return (
    <>
      <Modal className="AddtocartmodalMain" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center"
          >
            {/* Add to Cart */}
            Buy Now
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        {props?.Type === "BookingFromClub" ? (
          <Modal.Body>
            <h5 className="mb-2">Court</h5>
            <div className="activity-section-bottom-single">
              <div className="activity-section-bottom-single-top">
                <p>
                  {commonservices.getDateInFormatCustom(
                    props.AvailableCartData?.start_time,
                    props.NewTimeZone,
                    "dddd DD MMM"
                  )}{" "}
                  |{" "}
                  {commonservices.getTimeInFormat(
                    props.AvailableCartData?.start_time,
                    props.NewTimeZone
                  )}{" "}
                  -{" "}
                  {commonservices.getTimeInFormat(
                    props.AvailableCartData?.end_time,
                    props.NewTimeZone
                  )}
                </p>
                <span>{fnHour(props.AvailableCartData?.total_minutes)}</span>
              </div>
              <div className="activity-section-bottom-single-bottom">
                <div className="activity-section-bottom-single-bottom-image">
                  {props.ClubDetail?.profile_pic !== null &&
                  props.ClubDetail?.profile_pic !== "" &&
                  props.ClubDetail?.profile_pic !== undefined ? (
                    <img
                      src={props.ClubDetail?.profile_pic}
                      alt="club-detail-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imagePlaceholder;
                      }}
                    />
                  ) : (
                    <img src={imagePlaceholder} alt="club-detail-image" />
                  )}
                </div>
                <div className="">
                  <p>{props.AvailableCartData?.court_title}</p>
                  <h6>
                    {props?.ClubDetail !== undefined &&
                      props?.ClubDetail !== null &&
                      props.ClubDetail.club_name}
                  </h6>
                  <p>{props.AvailableCartData?.game_type_term}</p>
                  <h6>${props.AvailableCartData?.standard_price.toFixed(1)}</h6>
                </div>
              </div>
            </div>
            {TrainerData ? (
              <>
                <h5 className="mb-2 mt-2">Coach</h5>
                <div className="activity-section-bottom-single">
                  <div className="activity-section-bottom-single-top">
                    <p>
                      {commonservices.getDateInFormatCustom(
                        TrainerData?.start_time,
                        props?.NewTimeZone,
                        "dddd DD MMM"
                      )}{" "}
                      |{" "}
                      {commonservices.getTimeInFormat(
                        TrainerData?.start_time,
                        props?.NewTimeZone
                      )}{" "}
                      -{" "}
                      {commonservices.getTimeInFormat(
                        TrainerData?.end_time,
                        props?.NewTimeZone
                      )}
                    </p>
                    <span>{fnHour(TrainerData?.total_minutes)}</span>
                  </div>
                  <div className="activity-section-bottom-single-bottom position-relative justify-content-between">
                    <div className="d-flex gap-3">
                      <div className="activity-section-bottom-single-bottom-image">
                        {TrainerData?.trainer_image !== null &&
                        TrainerData?.trainer_image !== "" &&
                        TrainerData?.trainer_image !== undefined ? (
                          <img
                            src={TrainerData?.trainer_image}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                          />
                        ) : (
                          <img src={imagePlaceholder} alt="club-detail-image" />
                        )}
                      </div>
                      <div className="">
                        <span>Trainer</span>
                        <p>{TrainerData?.trainer_name}</p>
                        {/* <span>{TrainerData?.game_type_term}</span> */}
                        <h6>${TrainerData?.price?.toFixed(2)}</h6>
                      </div>
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        className="delete-icon-in-add-to-cart"
                        onClick={() => setTrainerData()}
                      />
                    </div>
                    {/* <button
                      className="red-btn red border px-3"
                      style={{ height: "34px", fontSize: "14px" }}
                      onClick={() => setTrainerData()}
                    
                    >
                      Delete
                    </button> */}
                  </div>
                </div>
              </>
            ) : (
              <div className="book-court-one">
                <h4 className="text-center">Do you want to Book a Trainer ?</h4>
                <div className="text-center">
                  <Button
                    onClick={() => fnBookCartTrainer()}
                    className="plausicon"
                  >
                    +
                  </Button>
                </div>
              </div>
            )}
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              <h5 className="mb-2">Trainer </h5>
              <div className="activity-section-bottom-single">
                <div className="activity-section-bottom-single-top">
                  <p>
                    {commonservices.getDateInFormatCustom(
                      props?.AvailableCartData?.booking_date,
                      props?.NewTimeZone,
                      "dddd DD MMM"
                    )}{" "}
                    |{" "}
                    {commonservices.getTimeInFormat(
                      props?.AvailableCartData?.start_time,
                      props?.NewTimeZone
                    )}{" "}
                    -{" "}
                    {commonservices.getTimeInFormat(
                      props?.AvailableCartData?.end_time,
                      props?.NewTimeZone
                    )}
                  </p>
                  <span>{fnHour(props?.AvailableCartData?.total_minutes)}</span>
                </div>
                <div className="activity-section-bottom-single-bottom">
                  <div className="activity-section-bottom-single-bottom-image">
                    {props.CoachDetail?.trainer_image !== null &&
                    props.CoachDetail?.trainer_image !== "" &&
                    props.CoachDetail?.trainer_image !== undefined ? (
                      <img
                        src={props.CoachDetail?.trainer_image}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagePlaceholder;
                        }}
                      />
                    ) : (
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    )}
                  </div>
                  <div className="">
                    <span>Trainer</span>
                    <p>{props.CoachDetail?.trainer_name}</p>
                    {/* <h6>
                      {props?.ClubDetail !== undefined &&
                        props?.ClubDetail !== null &&
                        props.ClubDetail.club_name}
                    </h6> */}
                    {/* <p>{props.CoachDetail?.game_type_term}</p> */}
                    {/* {props.CoachDetail?.rate !== null && */}
                    <h6>
                      {/* ${props.AvailableCartData?.discount_price?.toFixed(2)} */}
                      {LoginInfo?.is_subscriber === 1 ? (
                        <>
                          ${props.AvailableCartData?.discount_price?.toFixed(2)}
                        </>
                      ) : (
                        <>${props.AvailableCartData?.price?.toFixed(2)}</>
                      )}
                    </h6>
                  </div>
                </div>
              </div>
              {ClubData ? (
                <>
                  {" "}
                  <h5 className="mb-2 mt-2">Court</h5>
                  <div className="activity-section-bottom-single">
                    <div className="activity-section-bottom-single-top">
                      <p>
                        {commonservices.getDateInFormatCustom(
                          ClubData?.start_time,
                          props?.NewTimeZone,
                          "dddd DD MMM"
                        )}{" "}
                        |{" "}
                        {commonservices.getTimeInFormat(
                          ClubData?.start_time,
                          props?.NewTimeZone
                        )}{" "}
                        -{" "}
                        {commonservices.getTimeInFormat(
                          ClubData?.end_time,
                          props?.NewTimeZone
                        )}
                      </p>
                      <span>{fnHour(ClubData?.total_minutes)}</span>
                    </div>
                    <div className="activity-section-bottom-single-bottom position-relative">
                      <div className="activity-section-bottom-single-bottom-image">
                        {ClubData?.club_image !== null &&
                        ClubData?.club_image !== "" &&
                        ClubData?.club_image !== undefined ? (
                          <img
                            src={ClubData?.club_image}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                          />
                        ) : (
                          <img src={imagePlaceholder} alt="club-detail-image" />
                        )}
                      </div>
                      <div className="">
                        <span>{ClubData?.court_title}</span>
                        <p>{ClubData?.club_name}</p>
                        <span>{ClubData?.game_type_term}</span>
                        {/* <h6>
                      {props?.ClubDetail !== undefined &&
                        props?.ClubDetail !== null &&
                        props.ClubDetail.club_name}
                    </h6> */}
                        {/* <p>{props.CoachDetail?.game_type_term}</p> */}
                        <h6>${ClubData?.price?.toFixed(2)}</h6>
                      </div>
                      <img
                        src={deleteIcon}
                        alt="deleteIcon"
                        className="delete-icon-in-add-to-cart"
                        onClick={() => setClubData()}
                      />
                    </div>
                    {/* <button
                      className="red-btn red border px-3"
                      style={{ height: "34px", fontSize: "14px" }}
                      onClick={() => setClubData()}
                    >
                      Delete
                    </button> */}
                  </div>
                </>
              ) : (
                <div className="book-court-one">
                  <h4 className="text-center">Do you want to Book Court ?</h4>
                  <div className="text-center">
                    <Button
                      onClick={() => fnBookCartTrainer()}
                      className="plausicon"
                    >
                      +
                    </Button>
                  </div>
                </div>
              )}
            </Modal.Body>
          </>
        )}

        <Modal.Footer>
          <div className="d-flex align-items-center gap-2 w-100">
            {/* {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="gray-btn w-100" onClick={() => fnAddtoCart()}>
                Add to Cart
              </button>
            )} */}

            <button
              onClick={() => fnAddtoJoin(props)}
              className="red-btn red-border w-100"
            >
              Buy Now
            </button>
          </div>

          {/* {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="red-btn otp-btn w-100">Next</button>
            )} */}
        </Modal.Footer>
      </Modal>
      {TrainerBookingClubModalShow && (
        <BookCourtTrainerModal
          show={TrainerBookingClubModalShow}
          Type={props?.Type}
          TrainerClubDetail={props?.AvailableCartData}
          TrainerData={setTrainerData}
          ClubData={setClubData}
          CoachDetail={
            props?.CoachDetail ? props?.CoachDetail : props?.ClubDetail
          }
          onHide={() => setTrainerBookingClubModalShow(false)}
        />
      )}
    </>
  );
};

export default AddToCartModal;
