import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CartData } from "../Redux/Action";
import Loader, { LoaderType } from "../components/Loader";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { useNavigate } from "react-router-dom";

function NotificationOffcanvas({
  showNotificationOffcanvas,
  closeNotificationOffcanvas,
}) {
  const [Loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  const [pageNumber, setPageNumber] = useState(1);
  let navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [NotifyID, setNotifyID] = useState([]);
  const handleClose = () => setShow(false);

  async function getCartNotificationCount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_RECENT_UPDATES,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(CartData(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  async function getNotificationList(pageno) {
    setLoading(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_NOTIFICATIONS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        if (pageno === 1) {
          setNotificationList(response?.data?.data?.list);
        } else {
          setNotificationList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
        let notify_ids = [];
        response?.data?.data?.list?.forEach((element) => {
          if (element?.is_read === 0) {
            notify_ids.push(element.notify_id);
          }
        });
        setNotifyID(notify_ids);

        // if (NotificationList?.length > 0) {
        //   let temp = [];
        //   NotificationList?.forEach((element) => {
        //     if (element?.is_read === 1) {
        //       temp.push(element);
        //     }
        //   });
        //   setNotificationList(temp);
        // }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fnReadNotification(notify_ids) {
    let body = {
      notify_ids: notify_ids,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MARK_AS_READ_NOTIFICATIONS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);

    if (response?.isValidate) {
      getCartNotificationCount();
      getNotificationList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  async function fnAcceptRejectInvitation(item, value) {
    let temp1 = JSON.parse(item?.data);
    let body = {
      booking_player_id: temp1?.booking_player_id,
      notify_id: item?.notify_id,
      action_type: value,
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_PLAYERS_ACCEPT_REJECT_INVITATION,
        body: body,
      },
      false
    );
    let response = "";
    if (resData.status === 0) {
      toast.success(resData?.message);
      getNotificationList(1);
    } else {
      response = apiResponse(true, resData);
      if (response?.isValidate) {
        getNotificationList(1);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
  }
  async function fnAcceptRejectGroupInvitation(item, value) {
    let temp1 = JSON.parse(item?.data);
    let body = {
      group_member_id: temp1?.group_member_id,
      notify_id: item?.notify_id,
      action_type: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_ACCEPT_REJECT_INVITATION,
        body: body,
      },
      false
    );
    let response = "";
    if (resData.status === 0) {
      toast.success(resData?.message);
      getNotificationList(1);
    } else {
      response = apiResponse(true, resData);
      if (response?.isValidate) {
        getNotificationList(1);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
  }

  // useEffect(() => {
  //   if (NotifyID) {
  //     fnReadNotification(NotifyID);
  //   }
  // }, [NotifyID]);
  useEffect(() => {
    if (NotifyID.length > 0 && showNotificationOffcanvas) {
      fnReadNotification(NotifyID);
    }
  }, [NotifyID, showNotificationOffcanvas]);

  useEffect(() => {
    getNotificationList(pageNumber);
  }, []);

  

  function fnN_Name(item) {
    let temp = JSON.parse(item.data);
    return temp.from_username;
  }
  function fnN_Nameordercancelled(item) {
    let temp = JSON.parse(item.data);
    return temp.club_name;
  }
  function fnN_ProfilePic(item) {
    let temp = JSON.parse(item.data);
    if (temp.profile_pic !== null) {
      return temp.profile_pic;
    } else {
      return imagePlaceholder;
    }
  }
  function fnN_WalleteProfilePic(item) {
    let temp = JSON.parse(item.data);

    if (temp?.sender_profile_pic !== null) {
      return temp?.sender_profile_pic;
    } else {
      return imagePlaceholder;
    }
  }
  function fnN_WalletecreditProfilePic(item) {
    return imagePlaceholder;
  }

  function fnN_OrderProfilePic(item) {
    return imagePlaceholder;
  }
  function fnN_CartProfilePic(item) {
    return imagePlaceholder;
  }
  function fnN_LikesProfilePic(item) {
    let temp = JSON.parse(item.data);
    if (temp.profile_pic !== null) {
      return temp.profile_pic;
    } else if (temp.image !== null) {
      return temp.image;
    } else {
      return imagePlaceholder;
    }
  }

  const handleLoadMore = () => {
    getNotificationList(pageNumber);
  };

  return (
    <div className="NotificationOffcanvas">
      <Offcanvas
      
        show={showNotificationOffcanvas}
        onHide={closeNotificationOffcanvas}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Offcanvas.Title>Notifications</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body id="offcanvas-body">
          {Loading && !NotificationList?.length > 0 ? (
            <>
              <Loader type={LoaderType.InviteFriend} count={5} />
            </>
          ) : (
            <div className="all-notifications-main">
              {NotificationList?.length > 0 ? (
                NotificationList?.map((item, index) => (
                  <>
                    {item?.notification_type === "reject_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                        <div className="text-end">{item?.booking_invitation_status}</div>
                      </div>
                    )}
                    {/* {item?.notification_type === "reject_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{fnN_Nameordercancelled(item)}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                        <div className="text-end">Invitation Rejected</div>
                      </div>
                    )} */}
                    {item?.notification_type === "accept_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>  
                        {/* {item?.booking_invitation_status && */}
                        {/* <div className="text-end">
                          {item?.booking_invitation_status}
                        </div> */}
                        {/* } */}
                      </div>
                    )}
                    {/* {item?.notification_type === "accept_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{fnN_Nameordercancelled(item)}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>                      
                        <div className="text-end">
                          Invitation {item?.booking_invitation_status}
                        </div>
                      </div>
                    )} */}
                    {item?.notification_type === "subscription_renew" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "wallet_credit_success" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_WalletecreditProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "order_book" && (
                      <div onClick={()=>  {navigate("/my-booking");closeNotificationOffcanvas()}} className="single-notification-single-wrapper FindClubCard-main">
                        
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_CartProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "upload_score" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_WalletecreditProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "cancel_refund" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_WalletecreditProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "add_player_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{fnN_Name(item)}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                            {item?.booking_invitation_status === "pending" ? (
                              <div className="accept-reject-btns">
                                <button
                                  className="red-btn red-border w-100"
                                  style={{
                                    height: "30px",
                                    maxWidth: "100px",
                                    fontSize: "16px",
                                  }}
                                  onClick={() =>
                                    confirmAlert({
                                      title:
                                        "Are you sure you want to accept invitation?",
                                      buttons: [
                                        {
                                          label: "No",
                                        },
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            fnAcceptRejectInvitation(
                                              item,
                                              "accepted"
                                            ),
                                        },
                                      ],
                                    })
                                  }
                                >
                                  Accept
                                </button>
                                <button
                                  className="white-btn dark-color w-100"
                                  style={{
                                    height: "30px",
                                    maxWidth: "100px",
                                    fontSize: "16px",
                                  }}
                                  onClick={() =>
                                    confirmAlert({
                                      title:
                                        "Are you sure you want to reject invitation?",
                                      // message: "This action cannot be undone",
                                      buttons: [
                                        {
                                          label: "No",
                                        },
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            fnAcceptRejectInvitation(
                                              item,
                                              "rejected"
                                            ),
                                        },
                                      ],
                                    })
                                  }
                                >
                                  Reject{" "}
                                </button>
                              </div>
                            ) : (
                              <div className="text-end">
                                Invitation Accepted
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "post_comment" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_OrderProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "post_like" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_WalletecreditProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                    {item?.notification_type === "cart" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img
                              src={fnN_CartProfilePic(item)}
                              alt={item?.notification_title}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src = imagePlaceholder;
                              }}
                            />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                          </div>
                        </div>
                      </div>
                    )}
                     {item?.notification_type === "group_invitation" && (
                      <div className="single-notification-single-wrapper FindClubCard-main">
                        {item?.is_read === 0 && <div className="red-dot"></div>}
                        <div className="single-notification-single">
                          <div className="single-notification-single-image">
                            <img src={fnN_ProfilePic(item)} alt="profile2" />
                          </div>
                          <div className="single-notification-single-content w-100">
                            <div className="single-notification-single-name-hrs">
                              <h4>{item?.notification_title}</h4>
                              <span>
                                {commonservices.getAgo(item?.created_at)}
                              </span>
                            </div>
                            <span>{item?.notification_description}</span>
                            {item?.group_invitation_status === "pending" ? (
                              <div className="accept-reject-btns">
                                <button
                                  className="red-btn red-border w-100"
                                  style={{
                                    height: "30px",
                                    maxWidth: "100px",
                                    fontSize: "16px",
                                  }}
                                  onClick={() =>
                                    confirmAlert({
                                      title:
                                        "Are you sure you want to accept invitation?",
                                      buttons: [
                                        {
                                          label: "No",
                                        },
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            fnAcceptRejectGroupInvitation(
                                              item,
                                              "accepted"
                                            ),
                                        },
                                      ],
                                    })
                                  }
                                >
                                  Accept
                                </button>
                                <button
                                  className="white-btn dark-color w-100"
                                  style={{
                                    height: "30px",
                                    maxWidth: "100px",
                                    fontSize: "16px",
                                  }}
                                  onClick={() =>
                                    confirmAlert({
                                      title:
                                        "Are you sure you want to reject invitation?",
                                      // message: "This action cannot be undone",
                                      buttons: [
                                        {
                                          label: "No",
                                        },
                                        {
                                          label: "Yes",
                                          onClick: () =>
                                            fnAcceptRejectGroupInvitation(
                                              item,
                                              "rejected"
                                            ),
                                        },
                                      ],
                                    })
                                  }
                                >
                                  Reject{" "}
                                </button>
                              </div>
                            ) : (
                              <div className="text-end text-capitalize">
                                group invitation {item?.group_invitation_status}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))
              ) : (
                <Loader type={LoaderType.NODATAFOUND} />
              )}
            </div>
          )}
          {Loading && NotificationList?.length > 0 && (
            <Loader type={LoaderType.InviteFriend} count={5} />
          )}

          {!RemoveMoreButtion && (
            <div className="text-center w-100 mb-3 view-more-btn">
              <button
                className="red-btn red-border width150"
                onClick={handleLoadMore}
                disabled={Loading}
              >
                {Loading ? "Loading..." : "View More"}
              </button>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default NotificationOffcanvas;
