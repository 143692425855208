import { Breadcrumb, Container } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";

function InPageNav({ pageNav }) {
  const navigate = useNavigate();
  return (
    <Container
      fluid={true}
      className="p-0 main-top-section d-flex justify-content-between"
    >
      <Breadcrumb className="align-items-center d-flex h-100">
        <Breadcrumb.Item
          onClick={() => navigate("/")}
          className="align-items-center d-flex"
        >
          Home
          {/* <i className="fa-regular fa-house"> </i> */}
        </Breadcrumb.Item>
        {pageNav.map((page_item, index) => {
          return (
            <Breadcrumb.Item
              key={index}
              active={pageNav.active}
              onClick={() => navigate(page_item.link)}
              className={
                window.location.pathname.includes(page_item.link)
                  ? "align-items-center d-flex active"
                  : "align-items-center d-flex"
              }
            >
              {"> "} {page_item.name}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </Container>
  );
}

export default InPageNav;
