// import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import React, { useEffect, useState } from "react";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
// import GoogleLogin from "../images/google.svg";
import appleIcon from "../images/apple.svg";
import fbIcon from "../images/fb.svg";
import HidePass from "../images/hidePassword.svg";
import registerSignInLogo from "../images/registerSignInLogo.svg";
import ShowPass from "../images/showPassword.svg";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { commonservices } from "../services/CommonServices";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLogin from "../components/GoogleLogin";
import { UserInfo } from "../Redux/Action";
import { useDispatch } from "react-redux";
import PhoneCodeModal from "../Modal/PhoneCodeModal";
import { toast } from "react-toastify";
// import { apiResponse } from "../../services/APIResponse";
// import { API_URL } from "../../services/APIRoute";
// import { apiCall } from "../../services/AxiosService";
// import { commonservices } from "../../services/CommonServices";

const SignIn = () => {
  let dispatch = useDispatch();
  let clientId = process.env.REACT_APP_GOOGLE_CLIENTID;
  const [PhoneCountryCode, setPhoneCountryCode] = useState();
  const [PhoneCountryID, setPhoneCountryID] = useState();
  const [PhoneCodeModalShow, setPhoneCodeModalShow] = useState(false);

  const [Country_list, setCountry_list] = useState([]);
  const [Loading, setLoading] = useState(false);

  let navigate = useNavigate();

  const [input, setInput] = useState({
    email: "",
    phone: "",
    // country_code: "",
    password: "",
    errors: {
      email: "",
      phone: "",
      // country_code: "",
      password: "",
      ValidationRules: [
        {
          FieldName: "password",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });

  async function getCountryCode() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_COUNTRY_LIST,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setCountry_list(response?.data?.data?.list);
      response?.data?.data?.list?.forEach((element) => {
        if (element?.sortname === "US") {
          setPhoneCountryID(element.id);
          setPhoneCountryCode(element.phonecode)
        }
      });
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });

    if (obj.isValid) {
      if (input.email !== "") {
        let body = {
          authentication_type: "email",
          email: input.email.trim(),
          password: input.password.trim(),
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.LOGIN,
            body: body,
          },
          true
        );

        let response = apiResponse(false, resData, setLoading);
        // debugger
        if (response?.isValidate) {
          // dispatch(UserInfo(response?.data.data));
          // if (response?.data.data.is_profile_step_completed === 0) {
          //   navigate("/signup-steps");
          //   window.location.reload();
          // }

          // localStorage.setItem("Token", response?.data.data.auth_token);
          // localStorage.setItem("TokenStep", "Pickler");

          // window.location.reload();
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          if (response?.data.data.is_profile_step_completed !== 1) {
            navigate("/signup-steps");
            window.location.reload();
          } else {
            localStorage.setItem("TokenStep", "Pickler");
            window.location.reload();
          }
        }

        if (!response?.isValidate) {
          toast.error(resData?.message);
        }
      } else {
        let body = {
          authentication_type: "email",
          phone: input.phone.trim(),
          country_code: PhoneCountryCode.toString(),
          country_id: PhoneCountryID.toString(),
          // country_code:
          //   input.country_code.trim() !== "" ? input.country_code.trim() : "1",
          // country_code: 91,
          password: input.password.trim(),
        };

        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.LOGIN,
            body: body,
          },
          true
        );

        let response = apiResponse(true, resData, setLoading);
        if (response?.isValidate) {
          // dispatch(UserInfo(response?.data.data));
          // localStorage.setItem("Token", response?.data.data.auth_token);
          // localStorage.setItem("TokenStep", "Pickler");
          // window.location.reload();
          // dispatch(UserInfo(response?.data.data));
          // if (response?.data.data.is_profile_step_completed === 0) {
          //   navigate("/signup-steps");
          //   window.location.reload();
          // }

          // localStorage.setItem("Token", response?.data.data.auth_token);
          // localStorage.setItem("TokenStep", "Pickler");

          // window.location.reload();
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          if (response?.data.data.is_profile_step_completed !== 1) {
            navigate("/signup-steps");
            window.location.reload();
          } else {
            localStorage.setItem("TokenStep", "Pickler");
            window.location.reload();
          }
        }

        if (!response?.isValidate) {
        }
      }
    }
  };
  // commonservices.setItem('prakash','prakash123')
  useEffect(() => {
    getCountryCode();
  }, []);
  const showHidePass = (e) => {
    let button = document.getElementById("showHidePassBtn");
    let PassInput = document.querySelector(".password-show-hide");
    const open = button.querySelector(".open-icon");
    const close = button.querySelector(".close-icon");
    const currentValue = PassInput.getAttribute("type");
    const newValue = currentValue === "password" ? "text" : "password";
    PassInput.setAttribute("type", newValue);
    if (newValue == "password") {
      open.style.display = "inline-flex";
      close.style.display = "none";
    } else if (newValue == "text") {
      close.style.display = "inline-flex";
      open.style.display = "none";
    } else {
      open.style.display = "inline-flex";
      close.style.display = "none";
    }
  };
  return (
    <div className="sign-in-main only-sign-in-main">
      <Container>
        <div className="sign-in-wrapper diff-height-sign-in-wrapper">
          <h3 className="text-center m-3 page-head">Sign In</h3>
          <div className="sign-in-inner-main">
            <div className="sign-in-inner-all">
              <div className="sign-in-logo text-center">
                <img src={registerSignInLogo} alt="pickler" title="Pickler" />
              </div>
              <div className="sign-in-inputs">
                <Form
                  onSubmit={(e) => {
                    handleSubmit(e);
                  }}
                >
                  {input.email === "" && (
                    <InputGroup>
                      {/* <Form.Select
                        aria-label="select country_code"
                        className="countr-code"
                        onChange={(e) => {
                          setInput({
                            ...input,
                            ["country_code"]: e.target.value,
                          });
                        }}
                        isInvalid={input.errors.country_code}
                      >
                        <option>+1</option>
                        {Country_list?.length > 0 &&
                          Country_list.map((item, index) => (
                            <option key={index} value={item.phonecode}>
                              {"+" + item.phonecode}
                            </option>
                          ))}
                        {input.errors.country_code && (
                          <Form.Control.Feedback type="invalid">
                            {input.errors.country_code}
                          </Form.Control.Feedback>
                        )}
                      </Form.Select> */}
                      <Button
                        className="countr-code"
                        onClick={() => setPhoneCodeModalShow(true)}
                      >
                        +{PhoneCountryCode}
                      </Button>
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        value={input.phone}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            ["phone"]: e.target.value,
                          })
                        }
                        isInvalid={input.errors.phone}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {input.errors.phone && (
                        <Form.Control.Feedback type="invalid">
                          {input.errors.phone}
                        </Form.Control.Feedback>
                      )}
                    </InputGroup>
                  )}

                  {input.phone === "" &&
                    // input.country_code === "" &&

                    input.email === "" && (
                      <p className="small-text text-center">OR</p>
                    )}

                  {input.phone === "" && (
                    // && input.country_code === ""

                    <>
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email Address"
                        value={input.email}
                        onChange={(e) =>
                          setInput({
                            ...input,
                            ["email"]: e.target.value,
                          })
                        }
                        isInvalid={input.errors.email}
                      />
                      {input.errors.email && (
                        <Form.Control.Feedback type="invalid">
                          {input.errors.email}
                        </Form.Control.Feedback>
                      )}
                    </>
                  )}

                  <InputGroup
                    className={
                      input.errors.password
                        ? "error password-group mt-3"
                        : "password-group mt-3"
                    }
                  >
                    <Form.Control
                      type="password"
                      style={{ borderRight: "none" }}
                      className="password-show-hide"
                      placeholder="Enter Password"
                      value={input.password}
                      onChange={(e) =>
                        setInput({
                          ...input,
                          ["password"]: e.target.value,
                        })
                      }
                      isInvalid={input.errors.password}
                    />
                    {input.errors.password && (
                      <Form.Control.Feedback type="invalid">
                        {input.errors.password}
                      </Form.Control.Feedback>
                    )}
                    <Button
                      className="border-0"
                      id="showHidePassBtn"
                      onClick={showHidePass}
                    >
                      <img
                        src={ShowPass}
                        className="open-icon"
                        alt="OpenPass"
                      />
                      <img
                        src={HidePass}
                        className="close-icon"
                        alt="ClosePass"
                      />
                    </Button>
                  </InputGroup>

                  <p
                    onClick={() => navigate("/forgot-password")}
                    className="text-end small-text forgot-password"
                    style={{ cursor: "pointer" }}
                  >
                    Forgot Password ?
                  </p>
                  <div className="text-center">
                    {Loading ? (
                      <button type="submit" className="red-btn w-100">
                        Loading...
                      </button>
                    ) : (
                      <button type="submit" className="red-btn w-100">
                        Sign in
                      </button>
                    )}

                    {/* <div className="register-signin-buttons">
                      <p className="small-text text-center">Or sign in with</p>
                      <div className="social-media-icon">
                       
                        <GoogleOAuthProvider clientId={clientId}>
                          <GoogleLogin />
                        </GoogleOAuthProvider>
                        <Link to="">
                          <img src={fbIcon} alt="fb" title="facebook" />
                        </Link>
                        <Link to="">
                          <img src={appleIcon} alt="apple" title="Apple" />
                        </Link>
                      </div>
                    </div> */}
                    <div className="small-text text-center dont-have-account mt-5">
                      Don’t have account?{" "}
                      <span
                        className="sign-up-link"
                        onClick={() => navigate("/sign-up")}
                      >
                        Sign up
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </Container>
      {PhoneCodeModalShow && (
        <PhoneCodeModal
          show={PhoneCodeModalShow}
          CountryList={Country_list}
          PhoneCode={setPhoneCountryCode}
          CountryID={setPhoneCountryID}
          // SignUpData={SignUpData}
          onHide={() => setPhoneCodeModalShow(false)}
        />
      )}
    </div>
  );
};

export default SignIn;
