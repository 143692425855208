import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AppSetting, CartData } from "../Redux/Action";
import Loader, { LoaderType } from "../components/Loader";
import deleteIcon from "../images/deleteIcon.svg";
import information from "../images/informaton.svg";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { toast } from "react-toastify";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";
const Cart = () => {
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  const [Loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [PolicyHour, setPolicyHour] = useState(24);
  const [cartAPItime, setcartAPItime] = useState();
  const [CartData1, setCartData1] = useState([]);
  const [CartTimer, setCartTimer] = useState();
  let dispatch = useDispatch();
  const [SubTotal, setSubTotal] = useState(0);
  const [TotalItems, setTotalItems] = useState(0);
  async function getAppSetting() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.APP_SETTING,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(AppSetting(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  async function getCartList() {
    setLoading(true);
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_LIST,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (response?.data?.data?.timer_start_time !== null) {
        fnTimerofCart(response?.data?.data?.timer_start_time);
      }
      setCartData1(response?.data?.data?.list);
      setTotalItems(response?.data.data.total_items);
      const subtotalItem = response?.data.data?.sub_list.find(
        (item) => item.title === "Subtotal"
      );
      if (subtotalItem) {
        const subtotalValue = subtotalItem.value;
        setSubTotal(subtotalValue);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  // useEffect(() => {
  //   getCartList();
  // }, []);

  useEffect(() => {
    getCartList();
    // debugger;
    if (AppSettingData) {
      const Appmilliseconds = AppSettingData?.order_cancellation_hours;
      const Apphours = Appmilliseconds / 3600;
      setPolicyHour(Apphours);
    }
  }, []);

  // function fnTimerofCart(start_timer) {
  //   let current_time = new Date();
  //   let timer_start_time = new Date(
  //     commonservices.getDayDateMonthTimeSeconds(start_timer)
  //   );

  //   let diff = (current_time - timer_start_time) / 1000;

  //   const timerInterval = setInterval(() => {
  //     diff = (new Date() - timer_start_time) / 1000;

  //     if (diff < AppSettingData?.cart_timer_limit) {
  //       const minutes = Math.floor(
  //         (AppSettingData?.cart_timer_limit - diff) / 60
  //       );
  //       const seconds = Math.floor(
  //         (AppSettingData?.cart_timer_limit - diff) % 60
  //       );

  //       setCartTimer(`${minutes}  : ${seconds} `);
  //     } else {
  //       setCartTimer("0");
  //       clearInterval(timerInterval);
  //     }
  //   }, 1000);
  // }
  function fnTimerofCart(start_timer) {
    let current_time = new Date();
    let timer_start_time = new Date(
      commonservices.getDayDateMonthTimeSeconds(start_timer)
    );

    let diff = (current_time - timer_start_time) / 1000;

    const timerInterval = setInterval(() => {
      diff = (new Date() - timer_start_time) / 1000;

      if (diff < AppSettingData?.cart_timer_limit) {
        const minutes = Math.floor(
          (AppSettingData?.cart_timer_limit - diff) / 60
        );
        const seconds = Math.floor(
          (AppSettingData?.cart_timer_limit - diff) % 60
        );

        // Format minutes and seconds to always have two digits
        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = seconds.toString().padStart(2, "0");

        setCartTimer(`${formattedMinutes}:${formattedSeconds}`);
      } else {
        setCartTimer("00:00");
        clearInterval(timerInterval);
      }
    }, 1000);
  }

  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }

  async function getCartNotificationCount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_RECENT_UPDATES,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(CartData(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  async function fnDeleteCart(item) {
    let body = {
      cart_id: item.cart_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_REMOVE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      getCartNotificationCount();
      getCartList();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  async function fnConfirmCart(item) {
    let body = {
      is_buy_now: 1,
      items: [
        {
          booking_slot_id: item?.data?.booking_slot_id,
          item_type: "club_booking",
        },
      ],
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_ADD,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      // navigate(`/cart-confirmation/${response?.data?.data?.cart_id}`);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnContinuePayment() {
    // /cart-confirmation/
    if (CartData1?.length > 0) {
      let temp = CartData1.filter((f) => f.cart_status !== "new");
      console.log(temp);
      if (temp?.length > 0) {
        toast.error(
          "One of item in your cart is sold out or not available, remove it and continue"
        );
        return;
      } else {
        navigate("/cart-confirmation");
      }
    }
  }

  console.log("CartData ::", CartData1);

  return (
    <>
      <div className="cart-page-main">
        <div className="small-container container">
          <h6 className="cart-title">Cart</h6>
          {Loading ? (
            <LoaderOverlay />
          ) : (
            <>
              {CartData1?.length > 0 ? (
                <Row className="cart-page-wrapper">
                  <Col xl={7}>
                    <div className="cart-page-left-main">
                      <div className="cart-page-left-title">
                        <h5>({TotalItems} Items)</h5>
                        {CartTimer !== "00:00" && (
                          <h5 className="timer">{CartTimer}</h5>
                        )}
                      </div>
                      {CartData1?.map((item, index) => (
                        <>
                          <div className="my-cart-item-combine">
                            {item?.items?.length > 0 &&
                              item?.items.map((item_b, index_b) =>
                                item_b?.item_type === "club_booking" ? (
                                  <div
                                    className={
                                      item_b.item_type === "club_booking"
                                        ? "activity-section-bottom-single activity-section-bottom-single-diff position-relative club_booking"
                                        : "activity-section-bottom-single activity-section-bottom-single-diff position-relative"
                                    }
                                  >
                                    <div className="activity-section-bottom-single-top">
                                      <p>
                                        {commonservices.getDayDateMonth(
                                          item_b?.start_time,
                                          item_b?.timezone
                                        )}{" "}
                                        |{" "}
                                        {commonservices.getTimeInFormat(
                                          item_b?.start_time,
                                          item_b?.timezone
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          item_b?.end_time,
                                          item_b?.timezone
                                        )}
                                      </p>
                                      <span>{fnHour(item_b?.duration)}</span>
                                    </div>
                                    <div className="activity-section-bottom-single-bottom d-flex align-items-start gap-2">
                                      <div>
                                        <img
                                          src={imagePlaceholder}
                                          alt="imagePlaceholder"
                                          className="my-image"
                                        />
                                      </div>
                                      <div className="">
                                        <p>{item_b.court_title}</p>
                                        <h6>{item_b.club_name}</h6>
                                        <p>{item_b.game_type_term}</p>
                                        <h6 className="m-0">
                                          $
                                          {item_b?.total_amount === 0
                                            ? "0.00"
                                            : item_b?.total_amount?.toFixed(2)}
                                        </h6>
                                        {item_b?.cart_item_status ===
                                          "not_available" && (
                                          <p className="font-red">
                                            <b>Not available</b>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="my-delt-icon"
                                      onClick={() =>
                                        confirmAlert({
                                          title:
                                            "Are you sure you want to delete this?",
                                          message:
                                            "This action cannot be undone",
                                          buttons: [
                                            {
                                              label: "No",
                                            },
                                            {
                                              label: "Yes",
                                              onClick: () => fnDeleteCart(item),
                                            },
                                          ],
                                        })
                                      }
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                  </div>
                                ) : null
                              )}

                            {item?.items?.length > 0 &&
                              item?.items.map((item_b, index_b) =>
                                item_b?.item_type === "trainer_booking" ? (
                                  <div
                                    className={
                                      item_b.item_type === "club_booking"
                                        ? "activity-section-bottom-single activity-section-bottom-single-diff position-relative trainer_booking"
                                        : "activity-section-bottom-single activity-section-bottom-single-diff position-relative"
                                    }
                                  >
                                    <div className="activity-section-bottom-single-top">
                                      <p>
                                        {commonservices.getDayDateMonth(
                                          item_b?.start_time,
                                          item_b?.timezone
                                        )}{" "}
                                        |{" "}
                                        {commonservices.getTimeInFormat(
                                          item_b?.start_time,
                                          item_b?.timezone
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          item_b?.end_time,
                                          item_b?.timezone
                                        )}
                                      </p>
                                      <span>{fnHour(item_b?.duration)}</span>
                                    </div>
                                    <div className="activity-section-bottom-single-bottom d-flex align-items-start gap-2">
                                      <div>
                                        <img
                                          src={imagePlaceholder}
                                          alt="imagePlaceholder"
                                          className="my-image"
                                        />
                                      </div>
                                      <div className="">
                                        <p>Trainer</p>
                                        <h6>{item_b.trainer_name}</h6>
                                        <h6>
                                          $
                                          {item_b?.total_amount === 0
                                            ? "0.00"
                                            : item_b?.total_amount?.toFixed(2)}
                                        </h6>
                                        {item_b?.cart_item_status ===
                                          "not_available" && (
                                          <p className="font-red">
                                            <b>Not available</b>
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="my-delt-icon"
                                      onClick={() =>
                                        confirmAlert({
                                          title:
                                            "Are you sure you want to delete this?",
                                          message:
                                            "This action cannot be undone",
                                          buttons: [
                                            {
                                              label: "No",
                                            },
                                            {
                                              label: "Yes",
                                              onClick: () => fnDeleteCart(item),
                                            },
                                          ],
                                        })
                                      }
                                    >
                                      <img src={deleteIcon} alt="deleteIcon" />
                                    </div>
                                  </div>
                                ) : null
                              )}
                          </div>
                        </>
                      ))}
                    </div>
                  </Col>
                  <Col xl={5}>
                    <div className="cart-page-right-main">
                      <h5>Summary</h5>
                      <div className="summary-inner">
                        <div className="summary-inner-sub-total">
                          <p>Sub Total</p>
                          <p>${SubTotal?.toFixed(2)}</p>
                        </div>
                        <div className="cancellation-policy">
                          <div className="d-flex align-items-center gap-2">
                            <img src={information} alt="information" />
                            <p>Cancellation Policy</p>
                          </div>
                          <p className="red-text">Before {PolicyHour} hours</p>
                        </div>
                        <div className="continue-payment-btn">
                          <button
                            className="red-btn red-border w-100"
                            onClick={() => fnContinuePayment()}
                          >
                            {/* <Link to="/cart-confirmation/"> */}
                            Continue Payment
                            {/* </Link> */}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                <div className="cart-empty-message">Cart is empty</div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Cart;
