import React from "react";
import Container from "react-bootstrap/Container";
import { Outlet } from "react-router-dom";
import Header from "./Header";
// import SidebarLeft from "./SidebarLeft";

function Layout() {
  return (
    <div id="layout-wrapper">
      <Header />
      <div className="d-flex">
        {/* <Sidebar /> */}
        <main className="main-content w-100">
            <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Layout;
