// import React from "react";
import { Form, Modal } from "react-bootstrap";

import React, { useState } from "react";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";

const HomeReportModal = (props) => {
  const [Loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    comment: "",
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let body = {
      post_id: props?.ReportPost?.post_id,
      comment: input?.comment,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_REPORT,
        body: body,
      },
      false
    );

    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      props.onHide();
      props.bindList();
    }

    if (!response?.isValidate) {
      console.log("Error in forgot passowrd::", response);
    }
  };

  return (
    <>
      <Modal className="membership-buy-modal" size="lg" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Report</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Body>
            <p className="text-center mb-3">
              Are you sure want report this post?
            </p>
            <Form.Control
              as="textarea"
              placeholder="Reason"
              style={{ height: "100px" }}
              onChange={(e) => {
                setInput({
                  ...input,
                  ["comment"]: e.target.value,
                });
              }}
              className="black-colored-focused"
            />
          </Modal.Body>

          <Modal.Footer>
            {/* {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="red-btn otp-btn w-100">Next</button>
            )} */}

            {/* {props?.SubscribeType === "cancel" && ( */}
            <div className="d-flex gap-2 w-100">
              <button
                className="white-btn dark-color red-border w-100"
                onClick={() => props.onHide()}
              >
                No
              </button>
              {/* <button className="red-btn otp-btn w-100">Yes</button> */}
              {Loading ? (
                <button className="red-btn red-border w-100">Loading...</button>
              ) : (
                <button
                  className="red-btn red-border w-100"
                  // onClick={() => handleSubmitCancel()}
                >
                  Yes
                </button>
              )}
            </div>
            {/* )} */}
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default HomeReportModal;
