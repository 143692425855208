import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import GlobalSearchModal from "../Modal/GlobalSearchModal";
import NotificationOffcanvas from "../Modal/NotificationOffcanvas";
import bellIcon from "../images/bellIcon.svg";
import cartIcon from "../images/cartIcon.svg";
import mainLogo from "../images/dashboardLogo.svg";
import imagePlaceholder from "../images/placeholder.png";
import searchIcon from "../images/searchIcon.svg";
import { apiCall } from "../services/AxiosService";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { AppSetting, CartData } from "../Redux/Action";

const Header = () => {
  let dispatch = useDispatch();
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const CartData1 = useSelector((state) => state.userinfo.CartData);

  let navigate = useNavigate();
  const [timezone, settimezone] = useState("");
  const [CartNotificationCount, setCartNotificationCount] = useState();
  const [GlobalsearchModalshow, setGlobalsearchModalshow] = useState(false);
  const removeToken = () => {
    localStorage.clear();
    window.location.reload();
  };
  const [showNotificationOffcanvas, setNotificationOffcanvas] = useState(false);
  const handleNotificationOffcanvas = () => {
    setNotificationOffcanvas(true);
  };

  const closeNotificationOffcanvas = () => {
    setNotificationOffcanvas(false);
  };

  useEffect(() => {
    const timeZone1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone1 !== "" && timeZone1 !== undefined && timeZone1 !== null) {
      localStorage.setItem("timezone", timeZone1);
    }
  }, [timezone]);

  async function getCartNotificationCount() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GET_RECENT_UPDATES,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(CartData(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }
  async function getAppSetting() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.APP_SETTING,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      dispatch(AppSetting(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  const handleItemClick = () => {
    navigate("/my-booking");
    window.location.reload();
  };
  function fnProfileDetail() {
    navigate("/profile-detail/" + LoginInfo?.association_id);
    window.location.reload();
  }

  useEffect(() => {
    getCartNotificationCount();
    getAppSetting();
  }, []);

  return (
    <>
      <div className="header-main">
        <Navbar expand="lg" className="bg-body-tertiary">
          <Container className="big-container">
            <Navbar.Brand href="/home">
              <img src={mainLogo} alt="" />
            </Navbar.Brand>
            <div className="d-flex align-items-center header-right-main-mobile">
              <InputGroup className="search-input-group">
                <InputGroup.Text id="basic-addon1">
                  <img src={searchIcon} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>

              <div className="d-flex align-items-center header-icons-wrapper">
                <div
                  className="headerIcon"
                  onClick={handleNotificationOffcanvas}
                >
                  <img src={bellIcon} alt="alert" />
                </div>

                {/* <div
                  className="headerIcon ddd"
                  onClick={() => navigate("/cart")}
                >
                  <img src={cartIcon} alt="cart" />
                </div> */}

                <div className="header-profile-image">
                  {LoginInfo?.profile_pic !== null &&
                  LoginInfo?.profile_pic !== "" &&
                  LoginInfo?.profile_pic !== undefined ? (
                    <Link to="/profile-detail">
                      <img
                        src={LoginInfo?.profile_pic}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagePlaceholder;
                        }}
                      />
                    </Link>
                  ) : (
                    <Link to="/profile-detail">
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    </Link>
                  )}
                </div>

                <Dropdown className="header-dropdown">
                  <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <div onClick={handleItemClick}> My Bookings</div>
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => navigate("/settings")}>
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeToken}>Log Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto align-items-center main-navigations">
                <NavLink to="/home">home</NavLink>
                <NavLink to="/find-club">Clubs</NavLink>
                <NavLink to="/find-coach">Coach</NavLink>
              </Nav>
            </Navbar.Collapse>
            <div className="d-flex align-items-center header-right-main">
              <InputGroup className="search-input-group">
                <InputGroup.Text id="basic-addon1">
                  <img src={searchIcon} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  onClick={() => setGlobalsearchModalshow(true)}
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                />
              </InputGroup>
              <div className="d-flex align-items-center header-icons-wrapper">
                <div
                  className="headerIcon"
                  onClick={handleNotificationOffcanvas}
                >
                  <img src={bellIcon} alt="alert" />
                  {CartData1?.unread_notification_count > 0 && (
                    <h5 style={{ color: "#fff" }}>
                      {CartData1?.unread_notification_count}
                    </h5>
                  )}
                </div>
                {/* <div className="headerIcon" onClick={() => navigate("/cart")}>
                  <img src={cartIcon} alt="cart" />
                  {CartData1?.cart_item_count > 0 && (
                    <h5 style={{ color: "#fff" }}>
                      {CartData1?.cart_item_count}
                    </h5>
                  )}
                </div> */}
                <div
                  onClick={() => fnProfileDetail()}
                  className="header-profile-image"
                >
                  {/* {LoginInfo?.profile_pic !== null &&
                  LoginInfo?.profile_pic !== "" &&
                  LoginInfo?.profile_pic !== undefined ? ( */}
                  <div

                  // to={{
                  //   pathname:
                  //     "/profile-detail/" + LoginInfo?.association_id
                  //       ? LoginInfo?.association_id
                  //       : LoginInfo?.member_id,
                  // }}
                  >
                    {LoginInfo?.profile_pic !== null &&
                    LoginInfo?.profile_pic !== "" &&
                    LoginInfo?.profile_pic !== undefined ? (
                      <img
                        src={LoginInfo?.profile_pic}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagePlaceholder;
                        }}
                      />
                    ) : (
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    )}
                  </div>
                  {/* ) : (
                    <Link
                      to={{
                        pathname:
                          "/profile-detail/" + LoginInfo?.association_id
                            ? LoginInfo?.association_id
                            : LoginInfo?.member_id,
                      }}
                    >
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    </Link>
                  )} */}
                </div>
                <Dropdown className="header-dropdown">
                  <Dropdown.Toggle id="dropdown-basic"></Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleItemClick}>
                      My Bookings
                    </Dropdown.Item>

                    <Dropdown.Item onClick={() => navigate("/settings")}>
                      Settings
                    </Dropdown.Item>
                    <Dropdown.Item onClick={removeToken}>Log Out</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Container>
        </Navbar>
      </div>
      {showNotificationOffcanvas && (
        <NotificationOffcanvas
          showNotificationOffcanvas={showNotificationOffcanvas}
          closeNotificationOffcanvas={closeNotificationOffcanvas}
        />
      )}
      {GlobalsearchModalshow && (
        <GlobalSearchModal
          show={GlobalsearchModalshow}
          // ClubDetail={ClubDetail}
          onHide={() => setGlobalsearchModalshow(false)}
        />
      )}
    </>
  );
};

export default Header;
