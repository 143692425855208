import React from "react";
import logo from "../images/onlylogo.svg"
import twoMobile from "../images/twoMobileImage.png"
import gplay from "../images/gplay.jpg"
import appstore from "../images/appstore.jpg"
import { Link } from "react-router-dom";

function MobilePage() {
  return (
    <div className="mobile-page-wrapper-main">
      <div className="mobile-page-inner">
        <img src={logo} alt="logo" className="mobile-logo-image"/>
        <h4>Enhance Your Pickler Experience!</h4>
        <span>Optimize for Smoother Navigation</span>
        <img src={twoMobile} alt="twoMobile" className="twomobImge"/>
        <p>
          We noticed that your screen size is below 767x1024 pixels. To provide
          you with the best Pickler experience, we recommend downloading our
          mobile app from the
        </p>
        <div className="d-flex mobile-app-links">
        <Link to="/"><img src={gplay} alt="gplay" /></Link>
        <Link to="/"><img src={appstore} alt="appstore" /></Link>
        </div>
      </div>
    </div>
  );
}

export default MobilePage;
