import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";

const DayRestrictionModal = (props) => {
  const [restrictionList, setRestrictionList] = useState([]);

  useEffect(() => {
    if (props?.DayRestrictionsData) {
      const groupedData = props.DayRestrictionsData.reduce((grouped, day) => {
        const dayName = day.day_term;
        if (!grouped[dayName]) {
          grouped[dayName] = { day: dayName, data: [] };
        }
        grouped[dayName].data.push(day);
        return grouped;
      }, {});

      setRestrictionList(groupedData);
    }
  }, [props?.DayRestrictionsData]);

  return (
    <Modal className="" size="lg" centered {...props}>
      <Modal.Header closeButton>
        <div style={{ visibility: "hidden" }}>A</div>
        <Modal.Title id="contained-modal-title-vcenter">
          Day Restrictions
        </Modal.Title>
        <div style={{ visibility: "hidden" }}>A</div>
      </Modal.Header>

      <Modal.Body className="g-map-modal-body ">
        <div className="settings-availability-inner-main">
          {Object.keys(restrictionList).map((item_av, index_av) => (
            <React.Fragment key={index_av}>
              <h6 className="text-capitalize">
                {restrictionList[item_av]?.day}
              </h6>
              <div className="legends-of-available-slots-content">
                {restrictionList[item_av]?.data !== null &&
                  restrictionList[item_av]?.data?.length > 0 &&
                  restrictionList[item_av]?.data.map((item_sl, index_sl) => (
                    <div
                      className="legends-of-available-slots-inner-para"
                      key={index_sl}
                    >
                      <p>
                        {moment(item_sl?.start_time, "HH:mm").format("HH:mm")} -{" "}
                        {moment(item_sl?.end_time, "HH:mm").format("HH:mm")}
                      </p>
                    </div>
                  ))}
              </div>
            </React.Fragment>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DayRestrictionModal;
