import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal, Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Loader, { LoaderType } from "../components/Loader";
import imagePlaceholder from "../images/placeholder.png";
import searchIcon from "../images/searchIcon.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";

function FollowersAndFollowingModal(props) {
  const [Loading, setLoading] = useState(false);
  const [AddRemoveLoading, setAddRemoveLoading] = useState(false);
  let navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(props?.FollowType);
  const [FollowerMembersList, setFollowerMembersList] = useState([]);
  const [FollowerMembersLoading, setFollowerMembersLoading] = useState(false);
  const [FollowingMembersList, setFollowingMembersList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [PageNumberFollowing, setPageNumberFollowing] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [RemoveMoreButtionFollowing, setRemoveMoreButtionFollowing] =
    useState(false);
  const [searchTermFollowing, setSearchTermFollowing] = useState("");
  const [searchFollower, setsearchFollower] = useState("");

  async function getFollowerMembers(pageno) {
    setFollowerMembersLoading(true);
    let body = {
      page: pageno,
      limit: 10,
      member_id: props?.ProfileDetail?.member_id,
      search: searchFollower,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_GET_FOLLOWERS_MEMBERS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setFollowerMembersLoading);
    if (response?.isValidate) {
      if (pageno === 1) {
        setFollowerMembersList(response?.data?.data?.list);
      } else {
        setFollowerMembersList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      } else {
        setRemoveMoreButtion(false);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  async function getFollowingMembers(pageno) {
    setLoading(true);
    let body = {
      member_id: props?.ProfileDetail?.member_id,
      page: pageno,
      limit: 10,
      search: searchTermFollowing,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_GET_FOLLOWING_MEMBERS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (pageno === 1) {
        setFollowingMembersList(response?.data?.data?.list);
      } else {
        setFollowingMembersList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionFollowing(true);
      } else {
        setRemoveMoreButtionFollowing(false);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumberFollowing(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }  
  async function fnAddRemoveFollowing(item, value) {
    setAddRemoveLoading(true)
    let body = {
      receiver_member_id: item?.member_id,
      follow: value,
    };    
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData,setAddRemoveLoading);
    if (response?.isValidate) {
      getFollowerMembers(1);
      getFollowingMembers(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    // debugger
    getFollowerMembers(1);
  }, [searchFollower]);

  const handleLoadMore = () => {
    getFollowerMembers(pageNumber);
  };

  useEffect(() => {
    getFollowingMembers(1);
  }, [searchTermFollowing]);
  const handleLoadMoreFollowing = () => {
    getFollowingMembers(PageNumberFollowing);
  };

  return (
    <Modal className="likes-modal-main" size="lg" centered {...props}>
      {Loading && <LoaderOverlay />}      
      <Modal.Header closeButton>
        <div style={{ visibility: "hidden" }}>A</div>
        <Modal.Title id="contained-modal-title-vcenter">
          {props?.ProfileDetail?.member_name}
        </Modal.Title>
        <div style={{ visibility: "hidden" }}>A</div>
      </Modal.Header>
      <Modal.Body id="global-search" className="pb-0">
        <div className="club-detail-activity-tabs hoverblack">
          <Tabs activeKey={activeTab} onSelect={(key) => setActiveTab(key)}>
            <Tab eventKey="Followings" title="Following">
              <div className="modal-header-search-group w-100">
                <InputGroup className="search-input-group">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchTermFollowing}
                    onChange={(e) => setSearchTermFollowing(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={searchIcon}
                      alt="search"
                      className="black-search-icon"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>

              <div className="all-sungle-likes-wrapper all-followings">
                {Loading && !FollowingMembersList?.length > 0 ? (
                  <Loader type={LoaderType.InviteFriend} count={5} />
                ) : (
                  <>
                    {FollowingMembersList?.length > 0 ? (
                      FollowingMembersList?.map((item_fw, index_fw) => (
                        <div
                          // to={{
                          //   pathname: "/profile-detail/" + item_fw?.member_id,
                          // }}

                          className="invite-friends-single1 single-notification-single-wrapper1 FindClubCard-main likes-signle d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={() => {
                              navigate("/profile-detail/" + item_fw?.member_id);
                              props.onHide(); // Close the modal
                              window.location.reload();
                            }}
                            className="single-post-top"
                          >
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {item_fw?.profile_pic !== null &&
                                item_fw?.profile_pic !== "" &&
                                item_fw?.profile_pic !== undefined ? (
                                  <img
                                    src={item_fw?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                <h5
                                  className={
                                    item_fw?.member_type === "Standard"
                                      ? "profile-standard-after-initials-title"
                                      : item_fw?.member_type === "Premium"
                                      ? "profile-premium-after-initials-title"
                                      : ""
                                  }
                                >
                                  {item_fw?.member_name}
                                </h5>
                                <p>{item_fw?.nickname}</p>
                              </div>
                            </div>
                          </div>
                          <button
                            // onClick={() => fnRemoveFollowing(item_fw)}
                            onClick={() =>
                              fnAddRemoveFollowing(
                                item_fw,
                                item_fw?.is_following === 1 ? 0 : 1
                              )
                            }
                            className="gray-btn"
                            style={{
                              height: "34px",
                              fontSize: "14px",
                              width: "100px",
                            }}
                          >
                            {item_fw?.is_following === 1
                              ? "Following"
                              : "Follow"}
                          </button>
                        </div>
                      ))
                    ) : (
                      <Loader type={LoaderType.NODATAFOUND} />
                    )}
                  </>
                )}
                {Loading && FollowingMembersList?.length > 0 && (
                  <Loader type={LoaderType.InviteFriend} count={4} />
                )}

                {FollowingMembersList?.length > 0 && (
                  <>
                    {!RemoveMoreButtionFollowing && (
                      <div className="text-center w-100 mb-3 view-more-btn sticky-footer-btn">
                        <button
                          className="red-btn red-border width150"
                          onClick={handleLoadMoreFollowing}
                          disabled={Loading}
                        >
                          {Loading ? "Loading..." : "View More"}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Tab>
            <Tab eventKey="Followers" title="Followers">
              <div className="modal-header-search-group w-100">
                <InputGroup className="search-input-group">
                  <Form.Control
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchFollower}
                    onChange={(e) => setsearchFollower(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={searchIcon}
                      alt="search"
                      className="black-search-icon"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="all-sungle-likes-wrapper all-followings">
                {FollowerMembersLoading && !FollowerMembersList?.length > 0 ? (
                  <Loader type={LoaderType.InviteFriend} count={5} />
                ) : (
                  <>
                    {FollowerMembersList?.length > 0 ? (
                      FollowerMembersList?.map((item_fw, index_fw) => (
                        <div
                          // to={{
                          //   pathname: "/profile-detail/" + item_fw?.member_id,
                          // }}

                          className="likes-signle single-notification-single-wrapper2   invite-friends-single1 d-flex align-items-center justify-content-between"
                        >
                          <div
                            onClick={() => {
                              navigate("/profile-detail/" + item_fw?.member_id);
                              props.onHide(); // Close the modal
                              window.location.reload();
                            }}
                            className="single-post-top"
                          >
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {item_fw?.profile_pic !== null &&
                                item_fw?.profile_pic !== "" &&
                                item_fw?.profile_pic !== undefined ? (
                                  <img
                                    src={item_fw?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                {/* <h5>
                              {item_fw?.member_name}

                              {item_fw?.member_type === "Standard" && (
                                <span className="profile-after-initials">
                                  S
                                </span>
                              )}
                              {item_fw?.member_type === "Premium" && (
                                <span className="profile-after-initials">
                                  P
                                </span>
                              )}
                            </h5> */}
                                <h5
                                  className={
                                    item_fw?.member_type === "Standard"
                                      ? "profile-standard-after-initials-title"
                                      : item_fw?.member_type === "Premium"
                                      ? "profile-premium-after-initials-title"
                                      : ""
                                  }
                                >
                                  {item_fw?.member_name}
                                </h5>
                                <p>{item_fw?.nickname}</p>
                              </div>
                            </div>
                          </div>
                          <button
                            // onClick={() => fnRemoveFollowing(item_fw)}
                            onClick={() =>
                              fnAddRemoveFollowing(
                                item_fw,
                                item_fw?.is_following === 1 ? 0 : 1
                              )
                            }
                            className="gray-btn"
                            style={{
                              height: "34px",
                              fontSize: "14px",
                              width: "100px",
                            }}
                          >
                            {item_fw?.is_following === 1
                              ? "Following"
                              : "Follow"}
                          </button>
                        </div>
                      ))
                    ) : (
                      <Loader type={LoaderType.NODATAFOUND} />
                    )}
                  </>
                )}
                {FollowerMembersLoading && FollowerMembersList?.length > 0 && (
                  <Loader type={LoaderType.InviteFriend} count={5} />
                )}
                {FollowerMembersList?.length > 0 && (
                  <>
                    {!RemoveMoreButtion && (
                      <div className="text-center w-100 mb-3 view-more-btn sticky-footer-btn">
                        <button
                          className="red-btn red-border width150"
                          onClick={handleLoadMore}
                          disabled={FollowerMembersLoading}
                        >
                          {FollowerMembersLoading ? "Loading..." : "View More"}
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FollowersAndFollowingModal;
