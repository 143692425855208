import React, { useEffect, useState } from "react";
import {
  Form,
  InputGroup,
  Nav,
  NavItem,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import $ from "jquery";
import crown from "../images/crown.png";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import searchLocation from "../images/seachLocation.svg";
import heartred from "../images/heart-red.svg";
import heartwhite from "../images/heart-white.svg";
import tableView from "../images/tableView.svg";
import horizontalLines from "../images/threeHorizontalLines.svg";
import gridView from "../images/gridView.svg";
import group from "../images/group.svg";
import calendar from "../images/calendar.svg";
import information from "../images/informaton.svg";
import findclubImage from "../images/findclub1.png";
import starIcon from "../images/starIcon.svg";
import star from "../images/transparentStar.svg";
import graph from "../images/graphIcon.png";
import profilepic from "../images/player1.jpg";
import tableProfile from "../images/tableProfile.svg";
import likeIcon from "../images/like.svg";
import { Link, useNavigate } from "react-router-dom";
import InPageNav from "../components/PageNav/InPageNav";
import SingleFindCoach from "../components/CommonComponents/SingleFindCoach";
import SingleCoachWithStatus from "../components/CommonComponents/SingleCoachWithStatus";
import CoachDetailModal from "../Modal/CoachDetailModal";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { apiResponse } from "../services/APIResponse";
import Loader, { LoaderType } from "../components/Loader";
import GmapModal from "../Modal/GmapModal";
import { commonservices } from "../services/CommonServices";
import FindClubFilterModal from "../Modal/FindClubFilterModal";
import CoachFilterModal from "../Modal/CoachFilterModal";
import { useDispatch, useSelector } from "react-redux";
import { TrainerFilter } from "../Redux/Action";
import SubsciptionModal from "../Modal/SubscriptionModal";

const FindCoach = () => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const TrainerData = useSelector((state) => state.userinfo.TrainerFilter);
  let Location1 = JSON.parse(localStorage.getItem("Location"));

  // console.log("Location1 ::", Location1);
  let dispatch = useDispatch();
  const pageNav = [
    {
      name: "Coach",
      link: "/find-coach",
      active: true,
    },
  ];
  const [GmapModalshow, setGmapModalshow] = useState(false);
  const [CoachList, setCoachList] = useState([]);
  let navigate = useNavigate();
  const [FilterType, setFilterType] = useState("");
  const [GridShow, setGridShow] = useState(true);
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  const [NewTimeZOnw, setNewTimeZOnw] = useState("");
  const [SearchClub, setSearchClub] = useState("");
  const [CoachFilterShow, setCoachFilterShow] = useState(false);
  const [CoachDetailModalShow, setCoachDetailModalShow] = useState(false);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [SubscribeModalValue, setSubscribeModalValue] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [CoachLoader, setCoachLoader] = useState(false);
  const [CoachDetail, setCoachDetail] = useState();

  const [showCoachDetailModal, setCoachDetailModal] = useState(false);
  const handleCoachDetailModal = (item) => {
    setCoachDetailModalShow(true);
    setCoachDetail(item);
  };

  const closeCoachDetailModal = () => {
    setCoachDetailModal(false);
  };
  
  function funLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let temp = [];
          temp = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          localStorage.setItem("Location", JSON.stringify(temp));
          // setLocationMain(temp);
          const updatedClubFilter = {
            ...TrainerData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            sortBy: "Distance",
            rangeValue: 30,
          };
          dispatch(TrainerFilter(updatedClubFilter));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  async function getCoachList(pageno) {
    setCoachLoader(true);
    let body = {
      timezone: localStorage.getItem("timezone"),
      sort_by: TrainerData !== "" ? TrainerData?.sortBy.toLowerCase() : "",
      latitude: TrainerData !== "" ? TrainerData?.latitude : "",
      longitude: TrainerData !== "" ? TrainerData?.longitude : "",
      distance:
        // FilterData !== ""
        //   ? FilterData?.rangeValue === "ALL"
        //     ? ""
        //     :
        TrainerData !== "" ? TrainerData?.rangeValue : "",
      start_date: commonservices.getDateInFormat(TrainerData?.startDate),
      end_date: commonservices.getDateInFormat(TrainerData?.endDate),
      search: SearchClub,
      page: pageno,
      limit: 10,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.TRAINER_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setCoachLoader);
    if (response?.isValidate) {
      // debugger
      // setNewTimeZOnw(response?.data.data.timezone);
      if (pageno === 1 || pageno === undefined) {
        setCoachList(response?.data?.data?.list);
      } else {
        setCoachList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      } else {
        setRemoveMoreButtion(false);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnFavourite(item, value) {
    // debugger;
    let body = {
      module_id: item.trainer_id,
      module_type_term: "member",
      is_favourite: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_ADD_FAVOURITE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getCoachList(1);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnGmapModal() {
    setGmapModalshow(true);
  }

  useEffect(() => {
    getCoachList(1);
  }, [TrainerData, SearchClub]);
  useEffect(() => {
    funLocation();
  }, []);

  const handleLoadMore = () => {
    getCoachList(pageNumber);
  };

  const handleFindClubFilterModal = (type) => {
    setFilterType(type);
    setCoachFilterShow(true);
  };
  function fnClearSort(type) {
    if (type === "sortBy") {
      const updatedClubFilter = { ...TrainerData, sortBy: "" };
      dispatch(TrainerFilter(updatedClubFilter));
      // localStorage.removeItem("Location");
    }
    if (type === "rangeValue") {
      if (TrainerData.sortBy === "Distance") {
        const updatedClubFilter = {
          ...TrainerData,
          rangeValue: 0,
          sortBy: "",
          latitude: "",
          longitude: "",
        };
        dispatch(TrainerFilter(updatedClubFilter));
      }else{
        const updatedClubFilter = {
          ...TrainerData,
          rangeValue: 0,
          // sortBy: "",
          latitude: "",
          longitude: "",
        };
        dispatch(TrainerFilter(updatedClubFilter));
      }
      
      // localStorage.removeItem("Location");
    }
  }

  function fnSubscribeModal(item) {
    setSubscribeModalShow(true);
    setSubscribeModalValue(item);
  }

  // console.log("CoachList ::", CoachList);

  // For Datatable
  $(document).ready(function () {
    $("table#tableArrival").DataTable({
      destroy: true,
      lengthChange: false,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#tableArrival")
        .DataTable({
          destroy: true,
          lengthChange: false,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);

  return (
    <div className="find-coach-main">
      <div className="big-container container">
        <div className="page-top-section">
          <div className="page-top-section-left-wrapper">
            <div className="page-top-section-left">
              <div className="show-route">
                <InPageNav pageNav={pageNav} />
              </div>
            </div>
            {TrainerData !== "" &&
            commonservices.getDateInFormat(TrainerData.startDate) !== "" ? (
              <div
                className="date-picker-button-main"
                onClick={() => handleFindClubFilterModal("Datetime")}
              >
                <>
                  <img src={calendar} alt="calendarIcon" />
                  <span>
                    {commonservices.getDateInFormat(
                      new Date(TrainerData.startDate)
                    ) === commonservices.getDateInFormat(new Date()) &&
                    commonservices.getDateInFormat(
                      new Date(TrainerData.endDate)
                    ) === commonservices.getDateInFormat(new Date()) ? (
                      "Today"
                    ) : (
                      <>
                        {commonservices.getDateInFormat(
                          TrainerData.startDate
                        ) ===
                        commonservices.getDateInFormat(TrainerData.endDate) ? (
                          <>
                            {commonservices.getDateInFormat(
                              TrainerData.startDate
                            )}
                          </>
                        ) : (
                          <>
                            {commonservices.getDateInFormat(
                              TrainerData.startDate
                            )}{" "}
                            -{" "}
                            {commonservices.getDateInFormat(
                              TrainerData.endDate
                            )}
                          </>
                        )}
                      </>
                    )}
                  </span>
                </>
              </div>
            ) : (
              <div
                className="date-picker-button-main"
                onClick={() => handleFindClubFilterModal("Datetime")}
              >
                <img src={calendar} alt="calendarIcon" />
                <span>Today</span>
              </div>
            )}
            <div className="all-sort-by-main">
              {TrainerData !== "" && TrainerData?.sortBy !== "" && (
                <div className="sort-by-find-club-wrapper">
                  <div className="sort-by-find-club">{TrainerData?.sortBy}</div>
                  <span onClick={() => fnClearSort("sortBy")}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-circle-x-filled"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="#ffffff"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                        stroke-width="0"
                        fill="#ef333f"
                      />
                    </svg>
                  </span>
                </div>
              )}
              <div>
                {TrainerData !== "" && TrainerData.rangeValue > 0 && (
                  <div className="sort-by-find-club-wrapper">
                    <div className="sort-by-find-club">
                      {"0" + "-" + TrainerData.rangeValue + "miles"}
                    </div>
                    <span onClick={() => fnClearSort("rangeValue")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-circle-x-filled"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                          stroke-width="0"
                          fill="#ef333f"
                        />
                      </svg>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="page-top-section-right">
            <InputGroup className="search-input-group location-search-group">
              <InputGroup.Text id="basic-addon1" onClick={() => fnGmapModal()}>
                {/* <img 
                src={searchLocation}
                 alt="search" /> */}
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={(e) => setSearchClub(e.target.value)}
              />
            </InputGroup>
            <div
              className="top-menu-items"
              onClick={() => handleFindClubFilterModal()}
            >
              <img src={horizontalLines} alt="horizontalLines" />
            </div>
            <div
              className="top-menu-items"
              onClick={() => navigate("/my-booking")}
            >
              <img src={calendar} alt="calendar" />
            </div>
            <div
              className={
                GridShow
                  ? "top-menu-items top-menu-item-diff active"
                  : "top-menu-items top-menu-item-diff"
              }
              onClick={() => setGridShow(true)}
            >
              <img src={gridView} alt="gridView" />
            </div>
            <div
              className={
                !GridShow
                  ? "top-menu-items top-menu-item-diff active"
                  : "top-menu-items top-menu-item-diff"
              }
              onClick={() => setGridShow(false)}
            >
              <img src={tableView} alt="tableView" />
            </div>
          </div>
        </div>
        {GridShow ? (
          <>
            {LoginInfo?.is_subscriber !== 1 && (
              <div
                onClick={() => {
                  setSubscribeModalShow(true);
                  setSubscribeModalValue();
                }}
                className="top-subcribe-modal-opener"
              >
                <div className="top-subcribe-modal-opener-img">
                  <img src={crown} alt="crown" />
                </div>
                <div className="top-subcribe-modal-opener-content">
                  <h6>Rate Benefits</h6>
                  <p>
                    Subscribe our Plan to get <span>10% benefits</span> on rates
                    of Clubs and Coaches{" "}
                  </p>
                </div>
              </div>
            )}
            <div className="page-top-section-middle-tab mt-3">
              {CoachLoader && !CoachList?.length > 0 ? (
                <>
                  <div className="find-clubs-wrapper" id="find-clubs-wrapper">
                    <Loader type={LoaderType.ClubCard} count={4} />
                  </div>
                </>
              ) : (
                <>
                  <div
                    //  className="page-top-section-middle-available-content-wrapper"
                    className={
                      CoachList.length > 0
                        ? "page-top-section-middle-available-content-wrapper"
                        : ""
                    }
                  >
                    {CoachList.length > 0 ? (
                      CoachList.map((item, index) => (
                        <>
                          <div className="page-top-section-middle-available-content-single">
                            <div className="page-top-section-middle-available-content-single-img">
                              {item?.trainer_image !== null &&
                              item?.trainer_image !== "" &&
                              item?.trainer_image !== undefined ? (
                                <img
                                  src={item?.trainer_image}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder1;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder1}
                                  alt="club-detail-image"
                                />
                              )}
                            </div>
                            <div className="page-top-section-middle-available-content-single-content">
                              <h6>{item?.trainer_name}</h6>
                              {/* <p>{item?.trainer_name}</p> */}
                              <div className="page-top-section-middle-available-content-single-content-inner">
                                {/* <div className="page-top-section-middle-available-content-single-content-inner-single">
                          <img src={graph} alt="graph" />
                          <span>0-4</span>
                        </div> */}
                                <div className="page-top-section-middle-available-content-single-content-inner-single">
                                  <img src={star} alt="starIcon" />
                                  <span>
                                    {item?.trainer_avg_rating === 0
                                      ? "0.0"
                                      : item?.trainer_avg_rating?.toFixed(1)}
                                  </span>
                                </div>
                              </div>
                              <div className="page-top-section-middle-available-content-single-content-btns">
                                <div
                                  className={
                                    item?.rate == null
                                      ? "visibility-hidden width100"
                                      : "padding-not-needed"
                                  }
                                >
                                  {item?.rate !== null && (
                                    <h5 title={item?.rate}>
                                      $
                                      {item?.rate === 0
                                        ? "0.00"
                                        : item?.rate?.toFixed(2)}
                                    </h5>
                                  )}
                                </div>
                                <button
                                  className="red-btn red-border w-100"
                                  onClick={() => handleCoachDetailModal(item)}
                                >
                                  Book Coach
                                </button>
                              </div>
                            </div>
                            <div
                              className={
                                item?.is_favorite === 1
                                  ? "coach-like-btn active"
                                  : "coach-like-btn"
                              }
                              //  className="coach-like-btn"
                            >
                              <svg
                                onClick={() =>
                                  fnFavourite(
                                    item,
                                    item.is_favorite === 1 ? 0 : 1
                                  )
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g clip-path="url(#clip0_44_3663)">
                                  <path
                                    d="M19.4998 12.572L11.9998 20L4.49981 12.572C4.00512 12.0906 3.61546 11.512 3.35536 10.8726C3.09527 10.2333 2.97037 9.54694 2.98855 8.85693C3.00673 8.16691 3.16758 7.48813 3.46097 6.86333C3.75436 6.23853 4.17395 5.68125 4.6933 5.22657C5.21265 4.7719 5.82052 4.42968 6.47862 4.22147C7.13673 4.01327 7.83082 3.94358 8.51718 4.0168C9.20354 4.09001 9.86731 4.30455 10.4667 4.6469C11.0661 4.98925 11.5881 5.45199 11.9998 6.00599C12.4133 5.45602 12.9359 4.99731 13.5349 4.6586C14.1339 4.31988 14.7963 4.10844 15.4807 4.03751C16.1652 3.96658 16.8569 4.03769 17.5126 4.24639C18.1683 4.45508 18.7738 4.79687 19.2914 5.25036C19.8089 5.70385 20.2272 6.25928 20.5202 6.88189C20.8132 7.50449 20.9746 8.18088 20.9941 8.8687C21.0137 9.55653 20.8911 10.241 20.6339 10.8792C20.3768 11.5175 19.9907 12.0958 19.4998 12.578"
                                    stroke="#291F1F"
                                    stroke-width="1.8"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_44_3663">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center gap-1 align-items-center w-100">
                        <img src={information} alt="info" />
                        <p>Data not found</p>
                      </div>
                    )}
                  </div>
                </>
              )}
             
              {!RemoveMoreButtion && (
                <div className="text-center w-100 mb-3 view-more-btn">
                  <button
                    className="red-btn red-border width150"
                    onClick={handleLoadMore}
                    disabled={CoachLoader}
                  >
                    {CoachLoader ? "Loading..." : "View More"}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="table-container-main">
            <Table id="tableArrival" responsive>
              <thead>
                <tr>
                  <th>coach Name</th>
                  <th>ratings</th>
                  <th>Price</th>
                  <th>action</th>
                  <th className="sticky-th"></th>
                </tr>
              </thead>
              <tbody>
                {CoachList.length > 0 &&
                  CoachList.map((item, index) => (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          {item?.trainer_image !== null &&
                          item?.trainer_image !== "" &&
                          item?.trainer_image !== undefined ? (
                            <img
                              src={item?.trainer_image}
                              alt="club-detail-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder1;
                              }}
                              className="profile-image-in-table"
                            />
                          ) : (
                            <img
                              src={imagePlaceholder1}
                              alt="club-detail-image"
                              className="profile-image-in-table"
                            />
                          )}
                          <span>{item?.trainer_name}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <span>
                            {item?.trainer_avg_rating === 0
                              ? "0.0"
                              : item?.trainer_avg_rating?.toFixed(1)}
                          </span>
                          <img
                            src={starIcon}
                            alt="starIcon"
                            className="star-icon-in-table"
                          />
                        </div>
                      </td>
                      <td>
                        {item?.rate !== null ? (
                          <h5>
                            $
                            {item?.rate === 0 ? "0.00" : item?.rate?.toFixed(2)}
                          </h5>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td>
                        {/* <img src={likeIcon} alt="likeIcon" /> */}
                        {item.is_favorite === 1 ? (
                          <>
                            <img
                              onClick={() => fnFavourite(item, 0)}
                              src={heartred}
                              alt="heartred"
                            />
                            {/* <img
                                          src={crown}
                                          alt="heartred"
                                        /> */}
                          </>
                        ) : (
                          <>
                            <img
                              src={heartwhite}
                              alt="heartwhite"
                              onClick={() => fnFavourite(item, 1)}
                            />
                            {/* <img
                                          src={crown}
                                          alt="heartwhite"
                                        /> */}
                          </>
                        )}
                      </td>
                      <td className="text-end">
                        <button
                          className="red-btn red-border"
                          onClick={() => handleCoachDetailModal(item)}
                        >
                          Book Coach
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      </div>
      {CoachDetailModalShow && (
        <CoachDetailModal
          show={CoachDetailModalShow}
          CoachDetail={CoachDetail}
          // NewTimeZOnw={NewTimeZOnw}
          onHide={() => setCoachDetailModalShow(false)}
        />
      )}
      {CoachFilterShow && (
        <CoachFilterModal
          show={CoachFilterShow}
          FilterType={FilterType}
          onHide={() => setCoachFilterShow(false)}
        />
      )}
      {GmapModalshow && (
        <GmapModal
          show={GmapModalshow}
          onHide={() => setGmapModalshow(false)}
        />
      )}
      {SubscribeModalShow && (
        <SubsciptionModal
          show={SubscribeModalShow}
          Tag="coach"
          SubscribeModalValue={SubscribeModalValue}
          onHide={() => setSubscribeModalShow(false)}
        />
      )}
    </div>
  );
};

export default FindCoach;
