import React, { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import registerSignInLogo from "../images/registerSignInLogo.svg";
// import googleIcon from '../../images/google.svg'
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppSetting, UserInfo } from "../Redux/Action";
import FacebookPage from "../components/FacebookPage";
import GoogleLogin from "../components/GoogleLogin";
import appleIcon from "../images/apple.svg";
import fbIcon from "../images/fb.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";

function RegisterSignIn() {
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  const [timezone, settimezone] = useState("");
  // console.log("AppSettingData ::", AppSettingData);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let clientId = process.env.REACT_APP_GOOGLE_CLIENTID;
  const [CheckTerms, setCheckTerms] = useState("");

  useEffect(() => {
    const timeZone1 = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone1 !== "" && timeZone1 !== undefined && timeZone1 !== null) {
      localStorage.setItem("timezone", timeZone1);
    }
  }, [timezone]);

  async function getAppSetting() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.APP_SETTING,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // alert('pppp')
      // console.log("getAppSetting ::", response);
      dispatch(AppSetting(response?.data?.data));
    }
    if (!response?.isValidate) {
      console.log("Error  getting GET_RECENT_UPDATES", response);
    }
  }

  useEffect(() => {
    getAppSetting();
  }, []);

  // console.log("CheckTerms ::", CheckTerms);

  // const [id, setId] = useState("");
  // const [idToken, setIdToken] = useState("");
  // const [email, setEmail] = useState("");
  // const [packageId, setPackageId] = useState("");

  async function SignInSignUp(temp) {
    // debugger
    //code here for login / signup
    let body = {
      authentication_type: "apple",
      social_token: temp.social_token,
      social_id: temp.social_id,
      email: temp.email,
      member_name: temp.member_name,
      nickname: temp.nickname,
    };
    // debugger;
    // console.log("Apple userInfo ::", body);

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.LOGIN,
        body: body,
      },
      true
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      // debugger
      if (response?.data?.data?.is_user_exists === 0) {
        let body1 = {
          social_type_term: "apple",
          social_token: temp.social_token,
          social_id: temp.social_id,
          email: temp.email,
          member_name: temp.member_name,
          nickname: temp.nickname,
        };
        let resData1 = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.SIGNUP,
            body: body1,
          },
          true
        );
        let response1 = apiResponse(true, resData1);
        if (response1?.isValidate) {
          dispatch(UserInfo(response1?.data.data));
          localStorage.setItem("Token", response1?.data.data.auth_token);
          if (response1?.data.data.is_profile_step_completed !== 1) {
            navigate("/signup-steps");
            window.location.reload();
          } else {
            window.location.reload();
          }
        }
      } else {
        if (response?.data.data.is_profile_step_completed !== 1) {
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          navigate("/signup-steps");
          window.location.reload();
        } else {
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          localStorage.setItem("TokenStep", "Pickler");
          window.location.reload();
        }
      }
    }
  }

  useEffect(() => {
    // debugger;
    // Function to parse URL parameters
    const getUrlParameter = (name) => {
      name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
      const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
      const results = regex.exec(window.location.search);
      return results === null
        ? ""
        : decodeURIComponent(results[1].replace(/\+/g, " "));
    };
    // debugger;
    // Extract data from URL
    const extractedId = getUrlParameter("id");
    const extractedIdToken = getUrlParameter("idToken");
    const extractedEmail = getUrlParameter("email");
    const extractedPackageId = getUrlParameter("package");

    const emailParts = extractedEmail.split("@");
    const extractedName = emailParts.length > 0 ? emailParts[0] : "";
    // debugger;
    // Set state variables with extracted data
    // setId(extractedId);
    // setIdToken(extractedIdToken);
    // setEmail(extractedEmail);
    // setPackageId(extractedPackageId);

    let temp = {
      social_token: extractedIdToken,
      social_id: extractedId,
      email: extractedEmail,
      member_name: extractedName,
      nickname: extractedName,
    };
    // debugger
    if (temp.email) {
      SignInSignUp(temp);
    }
  }, []);

  return (
    <div className="register-signin-main">
      {/* <div>
        <p>ID: {id}</p>
        <p>ID Token: {idToken}</p>
        <p>Email: {email}</p>
        <p>Package ID: {packageId}</p>
      </div> */}
      <Container>
        <div className="register-signin-wrapper">
          <div className="register-signin-logo text-center">
            <img src={registerSignInLogo} />
          </div>
          <div className="register-signin-buttons">
            <Link to="/sign-up">
              <button className="red-btn w-100">Register</button>
            </Link>
            <Link to="/sign-in">
              <button className="white-btn w-100">Sign in</button>
            </Link>
            <p className="small-text text-center">Or continue with</p>

            <div className="social-media-icon">
              <GoogleOAuthProvider clientId={clientId}>
                {/* <GoogleLogin  check={CheckTerms} /> */}
                {CheckTerms !== 1 && <GoogleLogin check={CheckTerms} />}
              </GoogleOAuthProvider>

              {/* <FacebookPage  /> */}

              {CheckTerms === "1" ? (
                <FacebookPage />
              ) : (
                <div onClick={() => toast.error("Click on Terms & Conditions")}>
                  <img src={fbIcon} alt="fb" title="facebook" />
                </div>
              )}

              {/* <Link to="">
                <img src={fbIcon} alt="fb" title="facebook" />
              </Link> */}
              {CheckTerms === "1" ? (
                <a href="https://bottlenose-deep-amber.glitch.me/web">
                  {/* <AppleLoginMain /> */}
                  <img src={appleIcon} alt="apple" title="Apple" />
                </a>
              ) : (
                <a onClick={() => toast.error("Click on Terms & Conditions")}>
                  <img src={appleIcon} alt="apple" title="Apple" />
                </a>
              )}
            </div>
            {/* <Form.Check
              type="checkbox"
              id="is_phone_verified"
              name="is_phone_verified"
              onChange={(e) => {
                setCheckTerms(e.target.checked ? "1" : "0");
              }}
              value="1"
              label="I accept Terms and Conditions and Privacy policy"
            /> */}
            <div className="d-flex gap-2 align-items-center diff-check justify-content-center">
              <Form.Check
                type="checkbox"
                id="Terms"
                name="Terms"
                value="1"
                onChange={(e) => {
                  setCheckTerms(e.target.checked ? "1" : "0");
                }}
              />
              <Form.Label for="Terms" className="font-white m-0 d-flex">
                I accept{" "}
                <a
                  target="_blank"
                  href={AppSettingData?.term_and_conditions_url}
                  className="mx-1"
                >
                  <b>Terms and Conditions</b>
                </a>{" "}
                and{" "}
                <a
                  target="_blank"
                  href={AppSettingData?.privacy_policy_url}
                  className="mx-1"
                >
                  <b>Privacy Policy</b>
                </a>
              </Form.Label>
            </div>

            <div className="club-manager-btn mt-5">
              <Link to="">
                <button className="white-btn-diffborder w-100">
                  Are you a Club Manager?
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default RegisterSignIn;
