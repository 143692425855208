import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import InPageNav from "../components/PageNav/InPageNav";
import graph from "../images/graphIcon.png";
import information from "../images/informaton.svg";
import callIcon from "../images/callIcon.svg";
import locationIcon from "../images/locationIcon.svg";
import imagePlaceholder from "../images/placeholder.png";
import profilepic from "../images/player1.jpg";
import star from "../images/transparentStar.svg";
import { useParams } from "react-router-dom";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { useSelector } from "react-redux";
import { commonservices } from "../services/CommonServices";
import StarRateModal from "../Modal/StarRateModal";
import rateCourt from "../images/rateCourt.svg";
import RateTrainerModal from "../Modal/RateTrainerModal";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";

function CoachDeatail() {
  const AppSettingData = useSelector((state) => state.userinfo.AppSetting);
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [PolicyHour, setPolicyHour] = useState(24);
  let param = useParams();
  const [StarRateModalShow, setStarRateModalShow] = useState(false);
  const [BookingDetailLoader, setBookingDetailLoader] = useState(false);
  const [RateTrainerModalShow, setRateTrainerModalShow] = useState(false);
  const [CancelBookingModalShow, setCancelBookingModalShow] = useState(false);
  const [NewTimeZOnw, setNewTimeZOnw] = useState("");
  const [StartHour, setStartHour] = useState("");
  const [BookingDetail, setBookingDetail] = useState();
  const pageNav = [
    {
      name: "Club",
      link: "/find-club",
      active: true,
    },
    {
      name: "My Booking",
      link: "/my-booking",
      active: true,
    },
    {
      name: "Trainer Detail",
      link: "/booking-detail",
      active: false,
    },
  ];

  async function getBookingDetail() {
    setBookingDetailLoader(true);
    let body = {
      sub_order_id: param.id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_BOOKING_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setBookingDetailLoader);
    if (response?.isValidate) {
      setNewTimeZOnw(response?.data.data.timezone);
      setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getBookingDetail();
    if (AppSettingData) {
      const Appmilliseconds = AppSettingData?.order_cancellation_hours;
      const Apphours = Appmilliseconds / 3600;
      setPolicyHour(Apphours);
    }
  }, []);

  function fnCancelBookingModal() {
    setCancelBookingModalShow(true);
  }

  async function fnleaveInvitation(BookingDetail) {
    let body = {
      booking_player_id: BookingDetail?.players_details[0]?.booking_player_id,
      notify_id: BookingDetail?.notify_id,
    };

    // return;
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_PLAYERS_LEAVE_INVITATION,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData);
    if (response?.isValidate) {
      getBookingDetail();
      // setBookingDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  console.log("Trainer BookingDetail ::", BookingDetail);
  console.log("LoginInfo ::", LoginInfo);

  function Add24Hours() {
    if (BookingDetail !== undefined) {
      let current_time = new Date();
      let timer_start_time = new Date(
        commonservices.getDayDateMonthTimeSeconds(
          BookingDetail?.trainer_details?.start_time
        )
      );
      let diff = (timer_start_time - current_time) / 1000;
      let order_cancellation_hours = AppSettingData?.order_cancellation_hours
        ? AppSettingData?.order_cancellation_hours
        : 86400;
      if (diff < order_cancellation_hours) {
        setStartHour(false);
      } else {
        setStartHour(true);
      }
    }
  }

  useEffect(() => {
    if (BookingDetail) {
      Add24Hours();
    }
  }, [BookingDetail]);

  console.log("StartHour ::", StartHour);

  return (
    <div className="CoachDeatail-main-wrapper">
      {BookingDetailLoader && <LoaderOverlay />}
      <div className="big-container container">
        <div className="page-top-section">
          <div className="page-top-section-left">
            <div className="show-route">
              <InPageNav pageNav={pageNav} />
            </div>
          </div>
          <div className="page-top-section-right">
            <div
              onClick={() => setRateTrainerModalShow(true)}
              className="page-top-section-right-single-wrapper"
            >
              <img src={rateCourt} alt="rateCourt" />
              <p>Rate Trainer </p>
            </div>
            {BookingDetail?.phone !== "" &&
              BookingDetail?.phone !== undefined &&
              BookingDetail?.phone !== null && (
                <a
                  href={"tel:+" + BookingDetail?.phone}
                  className="club-detail-map-top page-top-section-right-single-wrapper"
                >
                  <img src={callIcon} alt="call" />
                </a>
              )}
          </div>
        </div>
        <div className="small-container container">
          <Row>
            <Col xl={6}>
              <div className="SingleCoachWithStatus-main">
                <div className="page-top-section-middle-available-content-single">
                  <div className="page-top-section-middle-available-content-single-img">
                    {BookingDetail?.trainer_details?.trainer_image !== null &&
                    BookingDetail?.trainer_details?.trainer_image !== "" &&
                    BookingDetail?.trainer_details?.trainer_image !==
                      undefined ? (
                      <img
                        src={BookingDetail?.trainer_details?.trainer_image}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagePlaceholder;
                        }}
                      />
                    ) : (
                      <img src={imagePlaceholder} alt="club-detail-image" />
                    )}
                  </div>
                  <div className="page-top-section-middle-available-content-single-content">
                    <h6>{BookingDetail?.trainer_details?.trainer_name}</h6>
                    <p>{BookingDetail?.trainer_club_details?.club_name}</p>
                    <div className="page-top-section-middle-booked-content">
                      {/* <h5>$18</h5> */}
                      <h5>${BookingDetail?.trainer_details?.total_amount}</h5>
                    </div>
                    <div className="page-top-section-middle-available-content-single-content-inner m-0">
                      {/* <div className="page-top-section-middle-available-content-single-content-inner-single">
                        <img src={graph} alt="graph" />
                        <span>0-4</span>
                      </div> */}
                      <div
                        onClick={() => setStarRateModalShow(true)}
                        className="page-top-section-middle-available-content-single-content-inner-single"
                      >
                        <img src={star} alt="starIcon" />
                        <span>
                          {BookingDetail?.trainer_details
                            ?.trainer_avg_rating === 0
                            ? "0.0"
                            : BookingDetail?.trainer_details?.trainer_avg_rating?.toFixed(
                                1
                              )}
                        </span>
                      </div>
                    </div>
                  </div>
                  {BookingDetail?.order_item_status === "booked" && (
                    <div className="booking-status-badge">
                      <p>{BookingDetail?.order_item_status}</p>
                    </div>
                  )}
                  {BookingDetail?.order_item_status === "cancelled" && (
                    <div
                      className="booking-status-badge"
                      style={{ background: "#EF333F" }}
                    >
                      <p>{BookingDetail?.order_item_status}</p>
                    </div>
                  )}
                  {BookingDetail?.order_item_status === "completed" && (
                    <div
                      className="booking-status-badge"
                      style={{ background: "#E0E0E0" }}
                    >
                      <p style={{ color: "#000000" }}>
                        {BookingDetail?.order_item_status}
                      </p>
                    </div>
                  )}
                </div>
                <div className="SingleCoachWithStatus-main-bottom">
                  <div className="page-top-section-middle-booked-bottom-section">
                    <div className="page-top-section-middle-booked-bottom-section-inner">
                      <h6>Your Booked Date</h6>
                      {/* <p>11 June, 2023 (Tuesday)</p> */}
                      <p>
                        {commonservices.getDateInFormatCustom(
                          BookingDetail?.trainer_details?.start_time,
                          NewTimeZOnw,
                          "DD dddd MMM yyyy"
                        )}
                      </p>
                    </div>
                    <div className="page-top-section-middle-booked-bottom-section-inner">
                      <h6>Your Booked Slot</h6>
                      {/* <span className="dark-badge">09:00</span> */}
                      <span className="dark-badge w-100">
                        {commonservices.getTimeInFormat(
                          BookingDetail?.trainer_details?.start_time,
                          NewTimeZOnw
                        )}
                        -
                        {commonservices.getTimeInFormat(
                          BookingDetail?.trainer_details?.end_time,
                          NewTimeZOnw
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {BookingDetail?.trainer_club_details !== "" &&
                BookingDetail?.trainer_club_details !== undefined &&
                BookingDetail?.trainer_club_details !== null && (
                  <div className="interestedcourt-wrapper mt-3 mb-3 max-500">
                    <h5>Court Detail</h5>
                    <div className="interestedcourt-inner mt-2">
                      <Card>
                        {BookingDetail?.trainer_club_details?.club_image !==
                          null &&
                        BookingDetail?.trainer_club_details?.club_image !==
                          "" &&
                        BookingDetail?.trainer_club_details?.club_image !==
                          undefined ? (
                          <img
                            src={
                              BookingDetail?.trainer_club_details?.club_image
                            }
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                            className="card-img-top"
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt="club-detail-image"
                            className="card-img-top"
                          />
                        )}
                        <Card.Body>
                          <Card.Title className="d-block">
                            <div className="d-flex justify-content-between align-items-center">
                              <div>
                                <h6
                                  className="mb-1"
                                  style={{ fontWeight: "500" }}
                                >
                                  {
                                    BookingDetail?.trainer_club_details
                                      ?.club_name
                                  }
                                </h6>
                                <h6 className="mb-1">
                                  {
                                    BookingDetail?.trainer_club_details
                                      ?.court_title
                                  }
                                </h6>
                                <p>
                                  {
                                    BookingDetail?.trainer_club_details
                                      ?.game_type_term
                                  }
                                </p>
                              </div>
                              <div>
                                {BookingDetail?.latitude !== "" &&
                                  BookingDetail?.longitude !== "" &&
                                  BookingDetail?.latitude !== undefined &&
                                  BookingDetail?.latitude !== null &&
                                  BookingDetail?.longitude !== undefined &&
                                  BookingDetail?.longitude !== null && (
                                    <a
                                      className="d-flex align-items-center"
                                      target="_blank"
                                      href={`https://www.google.co.in/maps/dir/?saddr=&daddr=${BookingDetail?.latitude},${BookingDetail?.longitude}&directionsmode=driving`}
                                    >
                                      <img src={locationIcon} alt="location" />
                                    </a>
                                  )}
                              </div>
                            </div>
                          </Card.Title>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                )}
            </Col>
            <Col xl={6}>
              {BookingDetail?.payment_details !== "" &&
                BookingDetail?.payment_details !== undefined &&
                BookingDetail?.payment_details !== null && (
                  <div className="booking-detail-right-bottom mt-0 profile-setting-main">
                    <h5>Payment Details</h5>
                    <div className="booking-detail-right-bottom setting-payment-detail">
                      {BookingDetail?.payment_details?.length > 0 &&
                        BookingDetail?.payment_details.map(
                          (item_b, index_b) => (
                            <>
                              <div
                                className="booking-detail-right-bottom-single"
                                key={index_b}
                              >
                                <p>{item_b?.title}</p>
                                <span>{item_b?.value}</span>
                              </div>
                            </>
                          )
                        )}
                      {BookingDetail?.order_item_status !== "completed" && (
                        <div className="cancellation-policy">
                          <div className="d-flex align-items-center gap-2">
                            <img src={information} alt="information" />
                            <p>Cancellation Policy</p>
                          </div>
                          <p className="red-text">Before {PolicyHour} hours</p>
                        </div>
                      )}

                      {BookingDetail?.order_item_status !== "completed" &&
                        BookingDetail?.order_item_status !== "cancelled" && (
                          <>
                            {LoginInfo?.association_id ===
                            BookingDetail?.from_member_id ? (
                              <>
                                {/* {Add24Hours()} */}
                                {StartHour && (
                                  <>
                                    <button
                                      className="red-btn red-border w-100"
                                      onClick={() => fnCancelBookingModal()}
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <button
                                className="red-btn red-border w-100"
                                onClick={() => fnleaveInvitation(BookingDetail)}
                              >
                                Leave Invitation
                              </button>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                )}
            </Col>
          </Row>
        </div>
      </div>
      {StarRateModalShow && (
        <StarRateModal
          show={StarRateModalShow}
          ProfileDetail={BookingDetail}
          Type="TrainerRatefromDetail"
          onHide={() => setStarRateModalShow(false)}
        />
      )}
      {RateTrainerModalShow && (
        <RateTrainerModal
          show={RateTrainerModalShow}
          BookingDetail={BookingDetail}
          bindList={getBookingDetail}
          onHide={() => setRateTrainerModalShow(false)}
        />
      )}
    </div>
  );
}

export default CoachDeatail;
