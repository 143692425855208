import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import paymentsuccess from "../images/successtick.svg";
import paymentfail from "../images/paymentfailed.svg";
import processing from "../images/processing.svg";
import toparrow from "../images/toparrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";

const PaymentSuccess = () => {
  const [otpTimer, setOtpTimer] = useState(60);
  let param = useParams();
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);
  const [StatusProcess, setStatusProcess] = useState(false);
  let navigate = useNavigate();
  const [PaymentStatus, setPaymentStatus] = useState("");

  async function getOrderStatus() {
    setLoading(true);

    let body = {
      ref_id: param.id,      
      ref_type_term: "booking",
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_GET_ORDER_STATUS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setStatusProcess(false);
      setPaymentStatus(response?.data.data.payment_status);
    }
    if (!response?.isValidate) {
      setError(true);
      console.log("Error  getting country list", response);
    }
  }
  useEffect(() => {
    setStatusProcess(true);

    const otpTimerIntervalId = setInterval(() => {
      setOtpTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    const getOrderStatusIntervalId = setInterval(() => {
      getOrderStatus();
    }, 3000);

    // Clear intervals when component unmounts
    return () => {
      clearInterval(otpTimerIntervalId);
      clearInterval(getOrderStatusIntervalId);
    };
  }, []); // Empty dependency array to run only once on mount

  return (
    <div className="sign-in-main payment-success-main">
      {Error ? (
        <>
          <Container>
            <Container>
              <Container>
                <div className="sign-in-wrapper">
                  <div className="sign-in-inner-main">
                    <div className="sign-in-inner-all">
                      <div className="payment-success-image">
                        <img src={paymentfail} alt="paymentsuccess" />
                      </div>
                      <div className="payment-success-content text-center">
                        <h4>Payment Failed</h4>
                        <p className="payment-success-para">
                          Your Yearly Plan Subscription has Failed
                        </p>
                        {/* <h5>Paid $60.00</h5>
                  <p>To : Rocks Lane - Bishops Park</p>
                  <p>07/01/2023 - 19:45</p> */}
                      </div>
                    </div>
                  </div>
                  <div className="payment-success-bottom-part">
                    {/* <img src={toparrow} alt="next" />
              <p>Show Transactions Details</p> */}
                  </div>
                </div>
              </Container>
            </Container>
          </Container>
        </>
      ) : (
        <>
          {StatusProcess ? (
            <>
              <Container>
                <div className="sign-in-wrapper">
                  <div className="sign-in-inner-main">
                    <div className="sign-in-inner-all">
                      <div className="payment-success-image">
                        <img src={processing} alt="paymentsuccess" />
                      </div>
                      <div className="payment-success-content text-center">
                        <h4>Processing</h4>
                        <p className="payment-success-para">
                          Please wait a moment while we process your payment.
                        </p>
                        {otpTimer > 0 && <h5>00:{otpTimer}</h5>}
                      </div>
                    </div>
                  </div>
                  <div className="payment-success-bottom-part">
                      {/* <img src={toparrow} alt="next" />
              <p>Show Transactions Details</p> */}
                      <button
                        className="red-btn red-border"
                        onClick={() => navigate("/")}
                      >
                        Go Home
                      </button>
                    </div>
                  {/* <div className="payment-success-bottom-part"></div> */}
                </div>
              </Container>
            </>
          ) : (
            <>
              {PaymentStatus === "succeeded" && (
                <Container>
                  <div className="sign-in-wrapper">
                    <div className="sign-in-inner-main">
                      <div className="sign-in-inner-all">
                        <div className="payment-success-image">
                          <img src={paymentsuccess} alt="paymentsuccess" />
                        </div>
                        <div className="payment-success-content text-center">
                          <h4>Payment Success</h4>
                          <p className="payment-success-para">
                            Your Payment is Successful
                          </p>
                          {/* <h5>Paid $60.00</h5>
                  <p>To : Rocks Lane - Bishops Park</p>
                  <p>07/01/2023 - 19:45</p> */}
                        </div>
                      </div>
                    </div>
                    <div className="payment-success-bottom-part">
                      {/* <img src={toparrow} alt="next" />
              <p>Show Transactions Details</p> */}
                      <button
                        className="red-btn red-border"
                        onClick={() => navigate("/")}
                      >
                        Go Home
                      </button>
                    </div>
                  </div>
                </Container>
              )}
              {PaymentStatus === "canceled" && (
                <Container>
                  <Container>
                    <div className="sign-in-wrapper">
                      <div className="sign-in-inner-main">
                        <div className="sign-in-inner-all">
                          <div className="payment-success-image">
                            <img src={paymentfail} alt="paymentsuccess" />
                          </div>
                          <div className="payment-success-content text-center">
                            <h4>Payment Canceled</h4>
                            <p className="payment-success-para">
                              Your Yearly Plan Subscription has Failed
                            </p>
                            {/* <h5>Paid $60.00</h5>
                  <p>To : Rocks Lane - Bishops Park</p>
                  <p>07/01/2023 - 19:45</p> */}
                          </div>
                        </div>
                      </div>
                      <div className="payment-success-bottom-part">
                        {/* <img src={toparrow} alt="next" />
              <p>Show Transactions Details</p> */}
                      </div>
                    </div>
                  </Container>
                </Container>
              )}
              {PaymentStatus === "failed" && (
                <Container>
                  <Container>
                    <Container>
                      <div className="sign-in-wrapper">
                        <div className="sign-in-inner-main">
                          <div className="sign-in-inner-all">
                            <div className="payment-success-image">
                              <img src={paymentfail} alt="paymentsuccess" />
                            </div>
                            <div className="payment-success-content text-center">
                              <h4>Payment Failed</h4>
                              <p className="payment-success-para">
                                Your Yearly Plan Subscription has Failed
                              </p>
                              {/* <h5>Paid $60.00</h5>
                  <p>To : Rocks Lane - Bishops Park</p>
                  <p>07/01/2023 - 19:45</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="payment-success-bottom-part">
                          {/* <img src={toparrow} alt="next" />
              <p>Show Transactions Details</p> */}
                        </div>
                      </div>
                    </Container>
                  </Container>
                </Container>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentSuccess;
