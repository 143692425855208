import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import FindClubFilterModal from "../Modal/FindClubFilterModal";
import SubsciptionModal from "../Modal/SubscriptionModal";
import Loader, { LoaderType } from "../components/Loader";
import InPageNav from "../components/PageNav/InPageNav";
import calendar from "../images/calendar.svg";
import crown from "../images/crown.png";
import gridView from "../images/gridView.svg";
import group from "../images/group.svg";
import heartred from "../images/heart-red.svg";
import heartwhite from "../images/heart-white.svg";
import information from "../images/informaton.svg";
import { useDispatch, useSelector } from "react-redux";
import GmapModal from "../Modal/GmapModal";
import { ClubFilter } from "../Redux/Action";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import searchLocation from "../images/seachLocation.svg";
import tableView from "../images/tableView.svg";
import horizontalLines from "../images/threeHorizontalLines.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import ClubSubscriptionModal from "../Modal/ClubSubscriptionModal";

const FindClub = () => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  let dispatch = useDispatch();
  // let Location1 = JSON.parse(localStorage.getItem("Location"));

  // console.log("Location1 ::", Location1);

  const FilterData = useSelector((state) => state.userinfo.ClubFilter);
  const FilterDateTime1 = useSelector((state) => state.userinfo.ClubFilterdate);
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  const [ClubSubscribeModalShow, setClubSubscribeModalShow] = useState(false);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  // const [LocationMain, setLocationMain] = useState(Location1);
  const [SubscribeModalValue, setSubscribeModalValue] = useState();
  const [ClubSubscribeModalValue, setClubSubscribeModalValue] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageNav = [
    {
      name: "Club",
      link: "/find-club",
      active: true,
    },
  ];
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [error, setError] = useState(null);

  const [location, setLocation] = useState(null);
  const [ClubList, setClubList] = useState([]);
  const [TotalPAGE, setTotalPAGE] = useState("");
  const [timezone, settimezone] = useState("");
  const [Loading, setLoading] = useState(false);
  const [GridShow, setGridShow] = useState(true);
  const [NewTimeZOnw, setNewTimeZOnw] = useState("");
  const [SearchClub, setSearchClub] = useState("");
  const [FilterType, setFilterType] = useState();
  const [showFindClubFilter, setshowFindClubFilter] = useState(false);
  const [GmapModalshow, setGmapModalshow] = useState(false);
  const [DateTimefromFilter, setDateTimefromFilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });
  const [FilterName, setFilterName] = useState({
    sortBy: "",
    gameType: "",
    dropCount: "",
    rangeValue: 0,
    startDate: new Date(),
    endDate: new Date(),
  });
  // console.log("FilterData ::", FilterData);
  // console.log("LocationMain ::", LocationMain);

  function funLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let temp = [];
          temp = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          localStorage.setItem("Location", JSON.stringify(temp));
          // setLocationMain(temp);
          const updatedClubFilter = {
            ...FilterData,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            sortBy: "Distance",
            rangeValue: 30,
          };
          dispatch(ClubFilter(updatedClubFilter));
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  async function getClubList(pageno) {
    setLoading(true);
    setIsLoading(true);
    setError(null);
    try {
      let body = {
        timezone: localStorage.getItem("timezone"),
        latitude: FilterData !== "" ? FilterData.latitude : "",
        longitude: FilterData !== "" ? FilterData.longitude : "",
        sort_by: FilterData !== "" ? FilterData?.sortBy.toLowerCase() : "",
        slot_type: FilterData !== "" ? FilterData?.dropCount.toLowerCase() : "",
        distance:
          // FilterData !== ""
          //   ? FilterData?.rangeValue === "ALL"
          //     ? ""
          //     :
          FilterData !== "" ? FilterData?.rangeValue : "",
        game_type: FilterData !== "" ? FilterData?.gameType.toLowerCase() : "",
        start_date: commonservices.getDateInFormat(FilterData?.startDate),
        end_date: commonservices.getDateInFormat(FilterData?.endDate),
        search: SearchClub,
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CLUB_LIST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setNewTimeZOnw(response?.data.data.timezone);
        if (pageno === 1 || pageno === undefined) {
          setItems(response?.data?.data?.list);
        } else {
          setItems((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      // debugger;
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        } else {
          setRemoveMoreButtion(false);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }
  const handleFindClubFilterModal = (time) => {
    setFilterType(time);
    setshowFindClubFilter(true);
  };

  async function fnFavourite(item, value) {
    let body = {
      module_id: item.club_id,
      module_type_term: "club",
      is_favourite: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_ADD_FAVOURITE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      getClubList(1);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  useEffect(() => {
    // funLocation();
    getClubList(1);
    // if (FilterData.rangeValue !== "") {
    //   localStorage.removeItem("Location");
    // }
  }, [FilterData, SearchClub]);

  useEffect(() => {
    funLocation();
  }, []);

  const handleLoadMore = () => {
    getClubList(pageNumber);
  };

  // console.log("club list 111 ::", items);

  function fnSubscribeModal(item) {
    setSubscribeModalShow(true);
    setSubscribeModalValue(item);
  }
  function fnClubSubscribeModal(item) {
    setClubSubscribeModalShow(true);
    setClubSubscribeModalValue(item);
  }
  function fnClearSort(type) {
    if (type === "sortBy") {
      const updatedClubFilter = { ...FilterData, sortBy: "" };
      dispatch(ClubFilter(updatedClubFilter));
      localStorage.removeItem("Location");
    }
    if (type === "gameType") {
      const updatedClubFilter = { ...FilterData, gameType: "" };
      dispatch(ClubFilter(updatedClubFilter));
    }
    if (type === "dropCount") {
      const updatedClubFilter = { ...FilterData, dropCount: "" };
      dispatch(ClubFilter(updatedClubFilter));
    }
    if (type === "rangeValue") {
      // const updatedClubFilter = {
      //   ...FilterData,
      //   rangeValue: 0,
      //   sortBy: "",
      //   latitude: "",
      //   longitude: "",
      // };
      // dispatch(ClubFilter(updatedClubFilter));
      // localStorage.removeItem("Location");
      if (FilterData.sortBy === "Distance") {
        const updatedClubFilter = {
          ...FilterData,
          rangeValue: 0,
          sortBy: "",
          latitude: "",
          longitude: "",
        };
        dispatch(ClubFilter(updatedClubFilter));
      }else{
        const updatedClubFilter = {
          ...FilterData,
          rangeValue: 0,
          // sortBy: "",
          latitude: "",
          longitude: "",
        };
        dispatch(ClubFilter(updatedClubFilter));
      }
    }
  }
  function fnGmapModal() {
    setGmapModalshow(true);
  }

  // For Datatable
  $(document).ready(function () {
    $("table#ClubTable").DataTable({
      destroy: true,
      lengthChange: false,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#ClubTable")
        .DataTable({
          destroy: true,
          lengthChange: false,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1],
            },
          ],
        })
        .destroy();
    });
  }, []);
  return (
    <>
      <div className="find-clubs-main">
        <div className="big-container container">
          <div className="page-top-section">
            <div className="page-top-section-left-wrapper">
              <div className="page-top-section-left">
                <div className="show-route">
                  <InPageNav pageNav={pageNav} />
                </div>
              </div>
              {/* {FilterData !== "" &&
              commonservices.getDateInFormat(FilterData.startDate) !== "" ? (
                <div
                  className="date-picker-button-main"
                  onClick={() => handleFindClubFilterModal("Datetime")}
                >
                  <>
                    <img src={calendar} alt="calendarIcon" />
                    <span>
                      {commonservices.getDateInFormat(
                        new Date(FilterData.startDate)
                      ) === commonservices.getDateInFormat(new Date()) &&
                      commonservices.getDateInFormat(
                        new Date(FilterData.endDate)
                      ) === commonservices.getDateInFormat(new Date()) ? (
                        "Today"
                      ) : (
                        <>
                          {commonservices.getDateInFormat(FilterData.startDate)}{" "}
                          - {commonservices.getDateInFormat(FilterData.endDate)}
                        </>
                      )}
                    </span>
                  </>
                </div>
              ) : (
                <div
                  className="date-picker-button-main"
                  onClick={() => handleFindClubFilterModal("Datetime")}
                >
                  <img src={calendar} alt="calendarIcon" />
                  <span>Today</span>
                </div>
              )} */}
              {FilterData !== "" &&
              commonservices.getDateInFormat(FilterData.startDate) !== "" ? (
                <div
                  className="date-picker-button-main"
                  onClick={() => handleFindClubFilterModal("Datetime")}
                >
                  <>
                    <img src={calendar} alt="calendarIcon" />
                    <span>
                      {commonservices.getDateInFormat(
                        new Date(FilterData.startDate)
                      ) === commonservices.getDateInFormat(new Date()) &&
                      commonservices.getDateInFormat(
                        new Date(FilterData.endDate)
                      ) === commonservices.getDateInFormat(new Date()) ? (
                        "Today"
                      ) : (
                        <>
                          {commonservices.getDateInFormat(
                            FilterData.startDate
                          ) ===
                          commonservices.getDateInFormat(FilterData.endDate) ? (
                            <>
                              {commonservices.getDateInFormat(
                                FilterData.startDate
                              )}
                            </>
                          ) : (
                            <>
                              {commonservices.getDateInFormat(
                                FilterData.startDate
                              )}{" "}
                              -{" "}
                              {commonservices.getDateInFormat(
                                FilterData.endDate
                              )}
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </>
                </div>
              ) : (
                <div
                  className="date-picker-button-main"
                  onClick={() => handleFindClubFilterModal("Datetime")}
                >
                  <img src={calendar} alt="calendarIcon" />
                  <span>Today</span>
                </div>
              )}

              <div className="all-sort-by-main">
                {FilterData !== "" && FilterData?.sortBy !== "" && (
                  <div className="sort-by-find-club-wrapper">
                    <div className="sort-by-find-club">
                      {FilterData?.sortBy}
                    </div>
                    <span onClick={() => fnClearSort("sortBy")}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-circle-x-filled"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="#ffffff"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                          stroke-width="0"
                          fill="#ef333f"
                        />
                      </svg>
                    </span>
                  </div>
                )}
                {FilterData !== "" && FilterData?.gameType !== "" && (
                  <div>
                    <div className="sort-by-find-club-wrapper">
                      <div className="sort-by-find-club">
                        {FilterData?.gameType}
                      </div>
                      <span onClick={() => fnClearSort("gameType")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-circle-x-filled"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                            stroke-width="0"
                            fill="#ef333f"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                )}
                {FilterData !== "" && FilterData?.dropCount !== "" && (
                  <div>
                    <div className="sort-by-find-club-wrapper">
                      <div className="sort-by-find-club">
                        {/* {FilterData?.dropCount} */}
                        Open play
                      </div>
                      <span onClick={() => fnClearSort("dropCount")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-circle-x-filled"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                            stroke-width="0"
                            fill="#ef333f"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                )}
                {FilterData !== "" && FilterData.rangeValue > 0 && (
                  <div>
                    <div className="sort-by-find-club-wrapper">
                      <div className="sort-by-find-club">
                        {"0" + "-" + FilterData.rangeValue + "miles"}
                      </div>
                      <span onClick={() => fnClearSort("rangeValue")}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="icon icon-tabler icon-tabler-circle-x-filled"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path
                            d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z"
                            stroke-width="0"
                            fill="#ef333f"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="page-top-section-right">
              <InputGroup className="search-input-group location-search-group">
                <InputGroup.Text
                  id="basic-addon1"
                  onClick={() => fnGmapModal()}
                >
                  <img src={searchLocation} alt="search" />
                </InputGroup.Text>
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  onChange={(e) => setSearchClub(e.target.value)}
                />
              </InputGroup>
              <div
                className="top-menu-items"
                onClick={() => handleFindClubFilterModal()}
              >
                <img src={horizontalLines} alt="horizontalLines" />
              </div>
              <div
                className="top-menu-items"
                onClick={() => navigate("/my-booking")}
              >
                <img src={calendar} alt="calendar" />
              </div>
              <div
                className={
                  GridShow
                    ? "top-menu-items top-menu-item-diff active"
                    : "top-menu-items top-menu-item-diff"
                }
                onClick={() => setGridShow(true)}
              >
                <img src={gridView} alt="gridView" />
              </div>
              <div
                className={
                  !GridShow
                    ? "top-menu-items top-menu-item-diff active"
                    : "top-menu-items top-menu-item-diff"
                }
                onClick={() => setGridShow(false)}
              >
                <img src={tableView} alt="tableView" />
              </div>
            </div>
          </div>
          {GridShow ? (
            <>
              {LoginInfo?.is_subscriber !== 1 && (
                <div
                  onClick={() => {
                    setSubscribeModalShow(true);
                    setSubscribeModalValue();
                  }}
                  className="top-subcribe-modal-opener"
                >
                  <div className="top-subcribe-modal-opener-img">
                    <img src={crown} alt="crown" />
                  </div>
                  <div className="top-subcribe-modal-opener-content">
                    <h6>Rate Benefits</h6>
                    <p>
                      Subscribe our Plan to get <span>10% benefits</span> on
                      rates of Clubs and Coaches{" "}
                    </p>
                  </div>
                </div>
              )}
              {isLoading && !items?.length > 0 ? (
                <>
                  <div className="find-clubs-wrapper" id="find-clubs-wrapper">
                    <Loader type={LoaderType.ClubCard} count={4} />
                  </div>
                </>
              ) : (
                <div
                  className={
                    items.length > 0
                      ? "find-clubs-wrapper"
                      : "find-clubs-wrapper data-not-found"
                  }
                  id="find-clubs-wrapper"
                >
                  <>
                    {items.length > 0 ? (
                      items.map((item, index) => (
                        <Card>
                          <Link
                            className="FindClubCard-main"
                            to={{ pathname: "/club-details/" + item.club_id }}
                          >
                            <Card.Img
                              variant="top"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder1;
                              }}
                              src={
                                item.profile_pic
                                  ? item.profile_pic
                                  : imagePlaceholder1
                              }
                            />
                            <Card.Body>
                              <Card.Title>
                                <div>
                                  <h4 title={item.address}>
                                    {item?.club_name?.slice(0, 15) + "..."}{" "}
                                    {/* <br />
                                    {item?.city} */}
                                  </h4>
                                </div>

                                {/* code */}
                                {LoginInfo?.is_subscriber === 1 ? (
                                  <>
                                  
                                    {item?.is_club_subscribed === 1 ? (
                                      <>
                                      
                                        {item?.club_dis_min_price ===
                                        item?.club_dis_max_price ? (
                                          <>
                                            <h6>
                                              {/* {item?.club_dis_max_price?.toFixed(2)} */}
                                              {item?.club_dis_min_price === 0 &&
                                              item?.club_dis_max_price === 0 ? (
                                                ""
                                              ) : (
                                                <h6>
                                                  $
                                                  {item?.club_dis_max_price?.toFixed(
                                                    2
                                                  )}
                                                </h6>
                                              )}
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            {item?.club_dis_min_price !== 0 &&
                                            item?.club_dis_max_price !== 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.club_dis_min_price > 0
                                                    ? item?.club_dis_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.club_dis_max_price > 0
                                                    ? item?.club_dis_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.club_dis_min_price !==
                                                0 &&
                                              item?.club_dis_max_price === 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.club_dis_min_price > 0
                                                    ? item?.club_dis_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.club_dis_max_price > 0
                                                    ? item?.club_dis_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.club_dis_min_price ===
                                                0 &&
                                              item?.club_dis_max_price !== 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.club_dis_min_price > 0
                                                    ? item?.club_dis_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.club_dis_max_price > 0
                                                    ? item?.club_dis_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.club_dis_min_price ===
                                                0 &&
                                              item?.club_dis_max_price === 0 ? (
                                              ""
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                      
                                        {item?.discount_min_price ===
                                        item?.discount_max_price ? (
                                          <>
                                            <h6>
                                              {/* {item?.discount_max_price?.toFixed(2)} */}
                                              {item?.discount_min_price === 0 &&
                                              item?.discount_max_price === 0 ? (
                                                ""
                                              ) : (
                                                <h6>
                                                  {/* $  */}
                                                  {item?.discount_max_price > 0 &&'$'+item?.discount_max_price?.toFixed(
                                                    2
                                                  )}
                                                </h6>
                                              )}
                                            </h6>
                                          </>
                                        ) : (
                                          <>
                                            {item?.discount_min_price !== 0 &&
                                            item?.discount_max_price !== 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.discount_min_price > 0
                                                    ? item?.discount_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.discount_max_price > 0
                                                    ? item?.discount_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.discount_min_price !==
                                                0 &&
                                              item?.discount_max_price === 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.discount_min_price > 0
                                                    ? item?.discount_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.discount_max_price > 0
                                                    ? item?.discount_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.discount_min_price ===
                                                0 &&
                                              item?.discount_max_price !== 0 ? (
                                              <div className="card-title-right">
                                                <h6>
                                                  $
                                                  {item?.discount_min_price > 0
                                                    ? item?.discount_min_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}{" "}
                                                  - $
                                                  {item?.discount_max_price > 0
                                                    ? item?.discount_max_price?.toFixed(
                                                        2
                                                      )
                                                    : 0}
                                                </h6>
                                              </div>
                                            ) : item?.discount_min_price ===
                                                0 &&
                                              item?.discount_max_price === 0 ? (
                                              ""
                                            ) : (
                                              ""
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {item?.min_price === item?.max_price ? (
                                      <>
                                        {/* <h6>${item?.max_price?.toFixed(2)}</h6> */}
                                        {item?.min_price === 0 &&
                                        item?.max_price === 0 ? (
                                          ""
                                        ) : (
                                          <h6>
                                            ${item?.max_price?.toFixed(2)}
                                          </h6>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {item.min_price !== 0 &&
                                        item.max_price !== 0 ? (
                                          <div className="card-title-right">
                                            <h6>
                                              $
                                              {item.min_price > 0
                                                ? item?.min_price?.toFixed(2)
                                                : 0}{" "}
                                              - $
                                              {item.max_price > 0
                                                ? item?.max_price?.toFixed(2)
                                                : 0}
                                            </h6>
                                          </div>
                                        ) : item.min_price !== 0 &&
                                          item.max_price === 0 ? (
                                          <div className="card-title-right">
                                            <h6>
                                              $ 
                                              {item.min_price > 0
                                                ? item?.min_price?.toFixed(2)
                                                : 0}{" "}
                                              - $ 
                                              {item.max_price > 0
                                                ? item?.max_price?.toFixed(2)
                                                : 0}
                                            </h6>
                                          </div>
                                        ) : item.min_price === 0 &&
                                          item.max_price !== 0 ? (
                                          <div className="card-title-right">
                                            <h6>
                                              $ 
                                              {item.min_price > 0
                                                ? item?.min_price?.toFixed(2)
                                                : 0}{" "}
                                              - $
                                              {item.max_price > 0
                                                ? item?.max_price?.toFixed(2)
                                                : 0}
                                            </h6>
                                          </div>
                                        ) : item.min_price === 0 &&
                                          item.max_price === 0 ? (
                                          ""
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </Card.Title>
                              <Card.Text>
                                {/* {item?.distance !== null &&
                                  item?.distance !== undefined &&
                                  item?.distance !== "" && ( */}
                                <p>
                                  {item?.distance !== null &&
                                    item?.distance !== undefined &&
                                    item?.distance !== "" &&
                                    item?.distance?.toFixed(2) + " miles - "}
                                  {item?.address}
                                </p>
                                {/* )} */}

                                <div className="find-club-card-timingd">
                                  {item?.time_slots.length > 0 ? (
                                    <>
                                      <div className="time-slot-title mt-2 mb-3">
                                        {/* <p>Time slot for Today</p> */}
                                        {FilterData !== "" &&
                                        commonservices.getDateInFormat(
                                          FilterData.startDate
                                        ) !== "" ? (
                                          <div>
                                            <>
                                              <span>
                                                Time slot for{" "}
                                                {commonservices.getDateInFormat(
                                                  new Date(FilterData.startDate)
                                                ) ===
                                                  commonservices.getDateInFormat(
                                                    new Date()
                                                  ) &&
                                                commonservices.getDateInFormat(
                                                  new Date(FilterData.endDate)
                                                ) ===
                                                  commonservices.getDateInFormat(
                                                    new Date()
                                                  ) ? (
                                                  "Today"
                                                ) : (
                                                  <>
                                                    {commonservices.getDateInFormat(
                                                      FilterData.startDate
                                                    )}{" "}
                                                    -{" "}
                                                    {commonservices.getDateInFormat(
                                                      FilterData.endDate
                                                    )}
                                                  </>
                                                )}{" "}
                                                ({item?.timezone})
                                              </span>
                                            </>
                                          </div>
                                        ) : (
                                          <p>
                                            <span>
                                              Time slot for Today (
                                              {item?.timezone})
                                            </span>
                                          </p>
                                        )}
                                      </div>
                                      <div className="find-club-card-timing">
                                        {item?.time_slots.map((item1, index1) =>
                                          item1.slot_type === "drop_in" ? (
                                            <div className="legends-of-available-slots-content d-flex">
                                              <div className="legends-of-available-slots-inner-para">
                                                <p
                                                  style={{
                                                    background: "#FFECED",
                                                  }}
                                                >
                                                  {commonservices.getTimeInFormat(
                                                    item1.start_time,
                                                    item.timezone
                                                  )}{" "}
                                                  -{" "}
                                                  {commonservices.getTimeInFormat(
                                                    item1.end_time,
                                                    item.timezone
                                                  )}{" "}
                                                  <span className="d-block">
                                                    {/* ${item.discount_price} */}
                                                    {LoginInfo?.user_type_term ===
                                                    "member" ? (
                                                      <>
                                                        $
                                                        {item1.discount_price?.toFixed(
                                                          2
                                                        )}/30m
                                                      </>
                                                    ) : (
                                                      <>
                                                        $
                                                        {item1.original_price?.toFixed(
                                                          2
                                                        )}/30m
                                                      </>
                                                    )}
                                                  </span>
                                                </p>
                                                <div className="slots-out-of-all">
                                                  <img
                                                    src={group}
                                                    alt="group"
                                                  />
                                                  <p>
                                                    {item1.booked_max_count}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            // <span

                                            // >
                                            //   {commonservices.getTimeInFormat(
                                            //     item1.start_time,
                                            //     item.timezone
                                            //   )}{" "}
                                            //   -{" "}
                                            //   {commonservices.getTimeInFormat(
                                            //     item1.end_time,
                                            //     item.timezone
                                            //   )}
                                            // </span>
                                            <Link
                                              to={{
                                                pathname:
                                                  "/club-details/" +
                                                  item.club_id +
                                                  "/" +
                                                  item1.start_time +
                                                  "_" +
                                                  item1.end_time,
                                              }}
                                            >
                                              {commonservices.getTimeInFormat(
                                                item1.start_time,
                                                item.timezone
                                              )}{" "}
                                              -{" "}
                                              {commonservices.getTimeInFormat(
                                                item1.end_time,
                                                item.timezone
                                              )}
                                            </Link>
                                          )
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {item?.timezone !== "UTC" && (
                                        <span className="text-center not-available d-flex align-items-center gap-2 w-100 disabled">
                                          <img src={information} alt="info" />
                                          Not available for Today(
                                          {item?.timezone})
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </Card.Text>

                              {item?.approx_people_count !== 0 && (
                                <div className="approx-person">
                                  <img src={group} alt="group" />
                                  <p>Approx 0 - {item?.approx_people_count}</p>
                                </div>
                              )}
                            </Card.Body>
                          </Link>
                          <div
                            onClick={() =>
                              fnFavourite(item, item.is_favorite === 1 ? 0 : 1)
                            }
                            className={
                              item?.is_favorite === 1
                                ? "add-wishlist active"
                                : "add-wishlist"
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.1459 9.39301L8.99886 14.4907L3.85181 9.39301C3.51231 9.06265 3.2449 8.66557 3.0664 8.22678C2.8879 7.78799 2.80219 7.317 2.81467 6.84346C2.82714 6.36992 2.93753 5.90409 3.13888 5.4753C3.34023 5.04652 3.62817 4.66407 3.98459 4.35204C4.34101 4.04001 4.75817 3.80515 5.20981 3.66227C5.66146 3.51938 6.13779 3.47155 6.60882 3.5218C7.07986 3.57205 7.53538 3.71928 7.94672 3.95423C8.35806 4.18917 8.71629 4.50674 8.99886 4.88694C9.28266 4.5095 9.64131 4.19471 10.0524 3.96225C10.4634 3.7298 10.918 3.5847 11.3877 3.53602C11.8575 3.48734 12.3322 3.53614 12.7821 3.67936C13.2321 3.82258 13.6477 4.05714 14.0029 4.36836C14.358 4.67958 14.6451 5.06076 14.8462 5.48804C15.0473 5.91532 15.158 6.3795 15.1714 6.85154C15.1849 7.32358 15.1007 7.7933 14.9243 8.23132C14.7478 8.66934 14.4828 9.06622 14.1459 9.39713"
                                stroke="white"
                                stroke-width="1.2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </div>
                          {item?.no_of_club_packages > 0  ? (
                            <>
                              <div
                                className="sss"
                                onClick={() => fnClubSubscribeModal(item)}
                              >
                                <img
                                  src={crown}
                                  alt="crown"
                                  className="add-wishlist crown"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              {LoginInfo?.is_subscriber !== 1 && (
                                <>
                                  {item.min_price > 0 && item.max_price > 0 && (
                                    <div
                                      className="sss"
                                      onClick={() => fnSubscribeModal(item)}
                                    >
                                      <img
                                        src={crown}
                                        alt="crown"
                                        className="add-wishlist crown"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </Card>
                      ))
                    ) : (
                      <div className="d-flex justify-content-center gap-1 align-items-center w-100">
                        <img src={information} alt="info" />
                        <p>Data not found</p>
                      </div>
                    )}
                  </>
                </div>
              )}
              {isLoading && items?.length > 0 && (
                <div className="find-clubs-wrapper" id="find-clubs-wrapper">
                  <Loader type={LoaderType.ClubCard} count={4} />
                </div>
              )}
              {/* {items?.length > 0 && ( */}
              <>
                {!RemoveMoreButtion && (
                  <div className="text-center w-100 mb-3 view-more-btn">
                    <button
                      className="red-btn red-border width150"
                      onClick={handleLoadMore}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "View More"}
                    </button>
                  </div>
                )}
              </>
              {/* )} */}
            </>
          ) : (
            <>
              <Col>
                {isLoading ? (
                  <>
                    <Loader type={LoaderType.Table} count={5} />
                  </>
                ) : (
                  <>
                    <Row>
                      <Table id="ClubTable" responsive>
                        <thead>
                          <tr>
                            <th>COURT NAME</th>
                            <th>DISTANCE</th>
                            <th>AREA</th>
                            {/* <th className="width150">SLOT</th>5 */}
                            <th className="width200">NO. OF PEOPLES</th>
                            <th className="width100">PRICE</th>
                            <th>ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          {items.length > 0 &&
                            items.map((item, index) => (
                              <tr>
                                <td>
                                  <Link
                                    className="FindClubCard-main"
                                    to={{
                                      pathname:
                                        "/club-details/" + item.club_id + "/",
                                    }}
                                  >
                                    <div className="table-profile-img">
                                      <img
                                        src={
                                          item.profile_pic
                                            ? item.profile_pic
                                            : imagePlaceholder1
                                        }
                                        alt=""
                                        className="profile-image-in-table"
                                      />
                                      <span>
                                        {item.club_name},{item.city}
                                      </span>
                                    </div>
                                  </Link>
                                </td>
                                <td>
                                  {item?.distance
                                    ? item?.distance?.toFixed(2) + "miles"
                                    : "-"}
                                </td>
                                <td>{item?.country}</td>

                                <td>
                                  <div className="d-flex align-items-center gap-2">
                                    <span>
                                      {item.approx_people_count === 0 ? (
                                        "-"
                                      ) : (
                                        <>
                                          <img
                                            src={group}
                                            alt="group"
                                            className="filter-dark"
                                          />{" "}
                                          Approx {item.approx_people_count}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  {" "}
                                  {LoginInfo?.is_subscriber === 1 ? (
                                    <>
                                      {item.discount_min_price !== 0 &&
                                      item.discount_max_price !== 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.discount_min_price > 0
                                              ? item?.discount_min_price?.toFixed(
                                                  2
                                                )
                                              : 0}{" "}
                                            - $
                                            {item.discount_max_price > 0
                                              ? item?.discount_max_price?.toFixed(
                                                  2
                                                )
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : item.discount_min_price !== 0 &&
                                        item.discount_max_price === 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.discount_min_price > 0
                                              ? item?.discount_min_price?.toFixed(
                                                  2
                                                )
                                              : 0}{" "}
                                            - $
                                            {item.discount_max_price > 0
                                              ? item?.discount_max_price?.toFixed(
                                                  2
                                                )
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : item.discount_min_price === 0 &&
                                        item.discount_max_price !== 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.discount_min_price > 0
                                              ? item?.discount_min_price?.toFixed(
                                                  2
                                                )
                                              : 0}{" "}
                                            - $
                                            {item.discount_max_price > 0
                                              ? item?.discount_max_price?.toFixed(
                                                  2
                                                )
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item.min_price !== 0 &&
                                      item.max_price !== 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.min_price > 0
                                              ? item?.min_price?.toFixed(2)
                                              : 0}{" "}
                                            - $
                                            {item.max_price > 0
                                              ? item?.max_price?.toFixed(2)
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : item.min_price !== 0 &&
                                        item.max_price === 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.min_price > 0
                                              ? item?.min_price?.toFixed(2)
                                              : 0}{" "}
                                            - $
                                            {item.max_price > 0
                                              ? item?.max_price?.toFixed(2)
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : item.min_price === 0 &&
                                        item.max_price !== 0 ? (
                                        <div className="card-title-right">
                                          <h6>
                                            $
                                            {item.min_price > 0
                                              ? item?.min_price?.toFixed(2)
                                              : 0}{" "}
                                            - $
                                            {item.max_price > 0
                                              ? item?.max_price?.toFixed(2)
                                              : 0}
                                          </h6>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  <div className="all-action-main">
                                    {item.is_favorite === 1 ? (
                                      <>
                                        <img
                                          onClick={() => fnFavourite(item, 0)}
                                          src={heartred}
                                          alt="heartred"
                                        />
                                        {/* <img
                                          src={crown}
                                          alt="heartred"
                                        /> */}
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          src={heartwhite}
                                          alt="heartwhite"
                                          onClick={() => fnFavourite(item, 1)}
                                        />
                                        {/* <img
                                          src={crown}
                                          alt="heartwhite"
                                        /> */}
                                      </>
                                    )}
                                    {/* prakash */}
                                    <>
                                      {LoginInfo?.is_subscriber !== 1 && (
                                        <>
                                          {item.min_price > 0 &&
                                            item.max_price > 0 && (
                                              <>
                                                <img
                                                  src={crown}
                                                  onClick={() =>
                                                    fnSubscribeModal(item)
                                                  }
                                                  alt="crown"
                                                  // className="add-wishlist crown"
                                                />
                                              </>
                                            )}
                                        </>
                                      )}
                                    </>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </Row>
                  </>
                )}
              </Col>
            </>
          )}
          {SubscribeModalShow && (
            <SubsciptionModal
              show={SubscribeModalShow}
              Tag="null"
              SubscribeModalValue={SubscribeModalValue}
              onHide={() => setSubscribeModalShow(false)}
            />
          )}
          {ClubSubscribeModalShow && (
            <ClubSubscriptionModal
              show={ClubSubscribeModalShow}
              ClubSubscribeModalValue={ClubSubscribeModalValue}
              onHide={() => setClubSubscribeModalShow(false)}
            />
          )}
          {showFindClubFilter && (
            <FindClubFilterModal
              show={showFindClubFilter}
              setFilterName={setFilterName}
              FilterType={FilterType}
              FilterData={FilterName}
              setDateTimefromFilter={setDateTimefromFilter}
              onHide={() => setshowFindClubFilter(false)}
            />
          )}
          {GmapModalshow && (
            <GmapModal
              show={GmapModalshow}
              onHide={() => setGmapModalshow(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default FindClub;
