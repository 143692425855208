import React from "react";
import profile from "../../images/player1.jpg";
import plusIcon from "../../images/plusIcon.svg";
import uploadIcon from "../../images/uploadIcon.svg";
import threedots from "../../images/threeDots.svg";
import post1 from "../../images/post1.png";
import liked from "../../images/liked.png";
import comment from "../../images/comment.svg";
import add from "../../images/circularAdd.jpg";

const SinglePost = (props) => {
  return (
    <>
      <div className="single-post-wrapper">
        <div className="single-post-top">
          <div className="d-flex align-items-center profile-and-time">
            <div className="write-something-profile">
              <img src={props.profilepic} alt="profile" />
            </div>
            <div className="username-with-time">
              <h5>
                {props.username} <span>shared a score</span>
              </h5>
              <p>{props.time} ago</p>
            </div>
          </div>
          <div>
            <img src={threedots} alt="threedots" />
          </div>
        </div>
        <div className="single-post-middle">
          <div className="post-section">
            <img src={props.postImage} alt="post" />
          </div>
        </div>
        <div className="single-post-bottom">
          <div className="like-and-comment">
            <div className="like-with-like-number">
              <img src={liked} alt="like" />
              <p>{props.likes} likes</p>
            </div>

            <div className="like-with-like-number">
              <img src={comment} alt="like" />
              <p>{props.comments} comments</p>
            </div>
            <div className="like-with-like-number">
              <img src={add} alt="like" />
              <p>20 Interested</p>
            </div>
          </div>
          <div className="post-detail">
            <p>{props.postDetail}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SinglePost;
