import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import homeLogo from "../images/homeLogo.svg";

function Home() {
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(function () {
      navigate("/register-signin");
    }, 2000);
  }, []);

  return (
    <div className="home-main">
      <Container>
        <div className="home-logo-wrapper">
          <Link to="/register-signin">
            <img src={homeLogo} alt="Pickler" />
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default Home;
