import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap/esm";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import CommentsModal from "../Modal/CommentsModal";
import deleteIcon from "../images/deleteIcon.svg";
import CreatePostModal from "../Modal/CreatePostModal";
import HomeReportModal from "../Modal/HomeReportModal";
import InterestModal from "../Modal/InterestModal";
import LikesModal from "../Modal/LikesModal";
import RateCourtModal from "../Modal/RateCourtModal";
import SuggestedForModal from "../Modal/SuggestedForModal";
import UploadScoreModal from "../Modal/UploadScoreModal";
import Loader, { LoaderType } from "../components/Loader";
import add from "../images/circularAdd.jpg";
import comment from "../images/comment.svg";
import groupIcon from "../images/groupIcon.svg";
import like from "../images/like.svg";
import liked from "../images/liked.png";
import imagePlaceholder from "../images/placeholder.png";
import plusIcon from "../images/plusIcon.svg";
import infoIcon from "../images/informaton.svg";
import redAdd from "../images/redAdd.png";
import rightArrow from "../images/rightArrow.svg";
import uploadIcon from "../images/uploadIcon.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import { ClubFilter, TrainerFilter } from "../Redux/Action";
import UploadScoreModalMain from "../Modal/UploadScoreModal";

const Dashboard = () => {
  let navigate = useNavigate();
  let Location1 = JSON.parse(localStorage.getItem("Location"));
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [CreatePostModalShow, setCreatePostModalShow] = useState(false);
  const [CommentModalShow, setCommentModalShow] = useState(false);
  const [SuggestedModalShow, setSuggestedModalShow] = useState(false);
  const [RateModalType, setRateModalType] = useState("");
  const [UploadRateCourtModalShow, setUploadRateCourtModalShow] =
    useState(false);
  const [ReportModalShow, setReportModalShow] = useState(false);
  const [BookingDetail, setBookingDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  let dispatch = useDispatch();
  const [HomePost, setHomePost] = useState([]);
  const [RateCourtModalShow, setRateCourtModalShow] = useState(false);
  const [RecentBooking, setRecentBooking] = useState([]);
  const [CommentPost, setCommentPost] = useState();
  const [ReportPost, setReportPost] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [ModalType, setModalType] = useState("");
  const [LocationMain, setLocationMain] = useState();
  const [SuggestedPeople, setSuggestedPeople] = useState([]);
  const [showUploadScoreModal, setUploadScoreModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const handleCreatePostModal = () => {
    setCreatePostModalShow(true);
  };
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);

  // let plus = totalPages + 1;

  // console.log("12121212", plus);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }
  function fnBookingRateModal(type) {
    setUploadRateCourtModalShow(true);
    // setRateModalType(type);
  }
  async function fnAddRemoveFollowing(item, value) {
    let body = {
      receiver_member_id: item?.member_id,
      follow: 1,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getSuggestedpeople();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  // console.log("totalPages ::", totalPages);
  const closeUploadScoreModal = () => {
    setUploadScoreModal(false);
  };
  const [showLikesModal, setLikesModal] = useState(false);
  const handleLikesModal = () => {
    setLikesModal(true);
  };

  const closeLikesModal = () => {
    setLikesModal(false);
  };
  const [showInterestModal, setInterestModal] = useState(false);
  const closeInterestModal = () => {
    setInterestModal(false);
  };
  async function getHomePostList(pageno) {
    setIsLoading(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_GET_DASHBOARD_FEEDS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        if (pageno === 1 || pageno === undefined) {
          setHomePost(response?.data?.data?.list);
        } else {
          setHomePost((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }
  async function getRecentBooking() {
    let body = {
      is_recent_bookings: 1,
      // page: 1,
      // limit: 20,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setRecentBooking(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSuggestedpeople() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SUGGESTED_PEOPLE,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setSuggestedPeople(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnLikeDeslikes(item, value) {
    let body = {
      post_id: item.post_id,
      is_like: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_LIKE_DISLIKE_POST,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function funLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let temp = [];
          temp = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          // localStorage.setItem("Location", JSON.stringify(temp));
          setLocationMain(temp);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }
  async function fnInterest(item, value) {
    let body = {
      post_id: item.post_id,
    };
    let body1 = {
      interest_id: item.interest_id,
    };
    if (value === "add") {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_ADD_INTEREST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_REMOVE_INTEREST,
          body: body1,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  }
  async function fnDeletePost(item) {
    let body = {
      post_id: item.post_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_DELETE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  function fnCommentModal(item, type) {
    setCommentPost(item);
    setCommentModalShow(true);
    setModalType(type);
  }

  function fnReportModal(item) {
    setReportModalShow(true);
    setReportPost(item);
  }
  useEffect(() => {
    funLocation();
    getHomePostList(1);
    getSuggestedpeople();
    getRecentBooking(1);
  }, []);

  

  useEffect(() => {
    // debugger
    let body = {
      sortBy: "Distance",
      gameType: "",
      dropCount: "",
      rangeValue: 30,
      startDate: new Date(),
      endDate: new Date(),
      latitude: LocationMain !== null ? LocationMain?.latitude : "",
      longitude: LocationMain !== null ? LocationMain?.longitude : "",
    };
    dispatch(ClubFilter(body));

    let body1 = {
      sortBy: "Distance",
      rangeValue: 30,
      startDate: new Date(),
      endDate: new Date(),
      latitude: LocationMain !== null ? LocationMain?.latitude : "",
      longitude: LocationMain !== null ? LocationMain?.longitude : "",
    };
    dispatch(TrainerFilter(body1));
  }, [LocationMain]);

  

  const handleLoadMore = () => {
    getHomePostList(pageNumber);
  };

  return (
    <div className="homeDashboardMain">
      <Container className="small-container">
        <Row>
          <Col xl={7} md={7}>
            <div className="write-something-main mb-2">
              <div className="write-something-bottom">
                <button
                  className="create-btn"
                  type="button"
                  onClick={() => handleCreatePostModal()}
                >
                  <img src={plusIcon} alt="plus" />
                  <span>Create Post</span>
                </button>
                <button
                  className="create-btn"
                  type="button"
                  onClick={() => fnBookingRateModal("Score")}
                >
                  <img src={uploadIcon} alt="upload" />
                  <span>Upload Score</span>
                </button>
                <button
                  className="create-btn"
                  type="button"
                  onClick={() => navigate("/create-group")}
                >
                  <img src={groupIcon} alt="upload" />
                  <span>Social Group </span>
                </button>
              </div>
            </div>
            <div className="all-post-section-main">
              {isLoading ? (
                <Loader type={LoaderType.HomePost} count={6} />
              ) : HomePost?.length > 0 ? (
                HomePost?.map((item, index) => (
                  <>
                    {item?.type_of_post === "upload_score" ? (
                      <div className="single-post-wrapper FindClubCard-main">
                        <div className="single-post-top">
                          <Link
                            className="FindClubCard-main"
                            to={{
                              pathname: "/profile-detail/" + item.member_id,
                            }}
                          >
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {item?.profile_pic !== null &&
                                item?.profile_pic !== "" &&
                                item?.profile_pic !== undefined ? (
                                  <img
                                    src={item?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                <h5>
                                  {item?.member_name}{" "}
                                  <span>shared a score</span>
                                </h5>
                                <p>{moment(item?.created_at).fromNow()} </p>
                              </div>
                            </div>
                          </Link>
                          <div>
                            <Dropdown className="header-dropdown">
                              <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                              <Dropdown.Menu>
                                {LoginInfo?.association_id ===
                                  item?.member_id && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to delete this?",
                                        message: "This action cannot be undone",
                                        buttons: [
                                          {
                                            label: "No",
                                          },
                                          {
                                            label: "Yes",
                                            onClick: () => fnDeletePost(item),
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    <span className="d-flex align-items-center gap-1">
                                      <img src={deleteIcon} alt="infoIcon" />
                                      Delete
                                    </span>
                                  </Dropdown.Item>
                                )}
                                {LoginInfo?.association_id !==
                                  item?.member_id && (
                                  <Dropdown.Item
                                    className="pl-1"
                                    onClick={() => fnReportModal(item)}
                                  >
                                    <span className="d-flex align-items-center gap-1">
                                      <img src={infoIcon} alt="infoIcon" />
                                      Report
                                    </span>
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div
                          className="single-post-middle"
                          onClick={() => fnCommentModal(item, "comment")}
                        >
                          <div className="post-section">
                            <div className="recent-result-wrapper-main">
                              <div className="recent-result-wrapper">
                                {item?.score_details?.score?.length > 0 &&
                                  item?.score_details?.score?.map(
                                    (s_item, s_index) => (
                                      <div className="recent-result-single-team">
                                        <p className="team-name">
                                          {s_item.team_name}
                                        </p>
                                        <div className="two-players-section">
                                          {s_item?.players.length > 0 &&
                                            s_item.players.map(
                                              (p_item, p_index) => (
                                                <div className="single-players-section">
                                                  {p_item.player_profile !==
                                                    null &&
                                                  p_item.player_profile !==
                                                    "" &&
                                                  p_item.player_profile !==
                                                    undefined ? (
                                                    <img
                                                      src={
                                                        p_item.player_profile
                                                      }
                                                      alt="club-detail-image"
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          imagePlaceholder;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={imagePlaceholder}
                                                      alt="club-detail-image"
                                                    />
                                                  )}
                                                  <h6>{p_item.player_name}</h6>
                                                  <p>
                                                    {p_item.avg_rating.toFixed(
                                                      1
                                                    )}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div className="recent-result-scores-wrapper">
                                          {s_item?.score?.map(
                                            (ss_item, ss_index) => (
                                              <h6
                                                className="recent-result-scores"
                                                key={ss_index}
                                              >
                                                {ss_item}
                                              </h6>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-post-bottom">
                          <div className="like-and-comment">
                            <div className="like-with-like-number">
                              <img
                                src={item?.is_like === 1 ? liked : like}
                                alt="like"
                                onClick={() =>
                                  fnLikeDeslikes(
                                    item,
                                    item?.is_like === 1 ? 0 : 1
                                  )
                                }
                              />
                              {item?.total_likes > 0 ? (
                                <p onClick={() => fnCommentModal(item, "like")}>
                                  {item?.total_likes} likes
                                </p>
                              ) : (
                                <p>{item?.total_likes} likes</p>
                              )}
                            </div>

                            <div
                              className="like-with-like-number"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <img src={comment} alt="like" />
                              <p>{item?.total_comment} comments</p>
                            </div>
                            {item?.total_interested_user > 0 && (
                              <div className="like-with-like-number">
                                <img
                                  onClick={() =>
                                    fnInterest(
                                      item,
                                      item?.is_interested === 1
                                        ? "remove"
                                        : "add"
                                    )
                                  }
                                  src={item?.is_interested === 1 ? redAdd : add}
                                  alt="like"
                                />
                                <p
                                  onClick={() =>
                                    fnCommentModal(item, "interested")
                                  }
                                >
                                  {item?.total_interested_user} Interested
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : item?.type_of_post === "normal" ? (
                      <>
                        <div className="single-post-wrapper FindClubCard-main">
                          <div className="single-post-top">
                            <Link
                              className="FindClubCard-main"
                              to={{
                                pathname: "/profile-detail/" + item.member_id,
                              }}
                            >
                              <div className="d-flex align-items-center profile-and-time">
                                <div className="write-something-profile">
                                  {item?.profile_pic !== null &&
                                  item?.profile_pic !== "" &&
                                  item?.profile_pic !== undefined ? (
                                    <img
                                      src={item?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                                <div className="username-with-time">
                                  <h5>
                                    {item?.member_name}{" "}
                                    <span>shared a post</span>
                                  </h5>
                                  <p>{moment(item?.created_at).fromNow()}</p>
                                </div>
                              </div>
                            </Link>
                            <div>
                              <Dropdown className="header-dropdown">
                                <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {LoginInfo?.association_id ===
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        confirmAlert({
                                          title:
                                            "Are you sure you want to delete this?",
                                          message:
                                            "This action cannot be undone",
                                          buttons: [
                                            {
                                              label: "No",
                                            },
                                            {
                                              label: "Yes",
                                              onClick: () => fnDeletePost(item),
                                            },
                                          ],
                                        })
                                      }
                                    >
                                      <span className="d-flex align-items-center gap-1">
                                        <img src={deleteIcon} alt="infoIcon" />
                                        Delete
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                  {LoginInfo?.association_id !==
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() => fnReportModal(item)}
                                    >
                                      <span className="d-flex align-items-center gap-1">
                                        <img src={infoIcon} alt="infoIcon" />
                                        Report
                                      </span>
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div
                            className="single-post-middle"
                            onClick={() => fnCommentModal(item, "comment")}
                          >
                            <div className="post-section">
                              <Slider {...settings}>
                                {item?.media_files?.length > 0 &&
                                  item?.media_files.map((m_item, m_index) => (
                                    <div className="slide-out-of-total">
                                      <p className="slide-out-of-total-para">
                                        {m_index + 1}/{" "}
                                        {item?.media_files?.length}
                                      </p>
                                      <div>
                                        {m_item?.image_path !== null &&
                                        m_item?.image_path !== "" &&
                                        m_item?.image_path !== undefined ? (
                                          <img
                                            src={m_item?.image_path}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </Slider>
                            </div>
                          </div>
                          <div className="single-post-bottom">
                            <div className="like-and-comment">
                              <div className="like-with-like-number">
                                <img
                                  src={item?.is_like === 1 ? liked : like}
                                  alt="like"
                                  onClick={() =>
                                    fnLikeDeslikes(
                                      item,
                                      item?.is_like === 1 ? 0 : 1
                                    )
                                  }
                                />
                                {item?.total_likes > 0 ? (
                                  <p
                                    onClick={() => fnCommentModal(item, "like")}
                                  >
                                    {item?.total_likes} likes
                                  </p>
                                ) : (
                                  <p>{item?.total_likes} likes</p>
                                )}
                              </div>

                              <div
                                className="like-with-like-number"
                                onClick={() => fnCommentModal(item, "comment")}
                              >
                                <img src={comment} alt="like" />
                                <p>{item?.total_comment} comments</p>
                              </div>
                              {item?.total_interested_user > 0 && (
                                <div className="like-with-like-number">
                                  <img
                                    src={
                                      item?.is_interested === 1 ? redAdd : add
                                    }
                                    alt="like"
                                  />
                                  <p
                                    onClick={() =>
                                      fnCommentModal(item, "interested")
                                    }
                                  >
                                    {item?.total_interested_user} Interested
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="post-detail">
                              <p>
                                {item?.comment !== null ? item?.comment : <></>}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      item?.type_of_post === "interest_activity" && (
                        <>
                          <div className="single-post-wrapper FindClubCard-main">
                            <div className="single-post-top">
                              <Link
                                className="FindClubCard-main"
                                to={{
                                  pathname: "/profile-detail/" + item.member_id,
                                }}
                              >
                                <div className="d-flex align-items-center profile-and-time">
                                  <div className="write-something-profile">
                                    {item?.profile_pic !== null &&
                                    item?.profile_pic !== "" &&
                                    item?.profile_pic !== undefined ? (
                                      <img
                                        src={item?.profile_pic}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </div>
                                  <div className="username-with-time">
                                    <h5>
                                      {item?.member_name}{" "}
                                      <span>shared a post</span>
                                    </h5>
                                    <p>{moment(item?.created_at).fromNow()}</p>
                                  </div>
                                </div>
                              </Link>
                              <div>
                                <Dropdown className="header-dropdown">
                                  <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {LoginInfo?.association_id ===
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this?",
                                            message:
                                              "This action cannot be undone",
                                            buttons: [
                                              {
                                                label: "No",
                                              },
                                              {
                                                label: "Yes",
                                                onClick: () =>
                                                  fnDeletePost(item),
                                              },
                                            ],
                                          })
                                        }
                                      >
                                        <span className="d-flex align-items-center gap-1">
                                          <img
                                            src={deleteIcon}
                                            alt="infoIcon"
                                          />
                                          Delete
                                        </span>
                                      </Dropdown.Item>
                                    )}
                                    {LoginInfo?.association_id !==
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() => fnReportModal(item)}
                                      >
                                        <span className="d-flex align-items-center gap-1">
                                          <img src={infoIcon} alt="infoIcon" />
                                          Report
                                        </span>
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div
                              className="single-post-middle"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <div className="post-section">
                                <div className="slide-out-of-total">
                                  {item?.club_details?.profile_pic !== null &&
                                  item?.club_details?.profile_pic !== "" &&
                                  item?.club_details?.profile_pic !==
                                    undefined ? (
                                    <img
                                      src={item?.club_details?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="single-post-bottom">
                              <div className="like-and-comment">
                                <div className="like-with-like-number">
                                  <img
                                    src={item?.is_like === 1 ? liked : like}
                                    alt="like"
                                    onClick={() =>
                                      fnLikeDeslikes(
                                        item,
                                        item?.is_like === 1 ? 0 : 1
                                      )
                                    }
                                  />
                                  {item?.total_likes > 0 ? (
                                    <p
                                      onClick={() =>
                                        fnCommentModal(item, "like")
                                      }
                                    >
                                      {item?.total_likes} likes
                                    </p>
                                  ) : (
                                    <p>{item?.total_likes} likes</p>
                                  )}
                                </div>
                                <div
                                  className="like-with-like-number"
                                  onClick={() =>
                                    fnCommentModal(item, "comment")
                                  }
                                >
                                  <img src={comment} alt="like" />
                                  <p>{item?.total_comment} comments</p>
                                </div>

                                <>
                                  {item?.interested_users.find(
                                    (f) =>
                                      f.member_id === LoginInfo?.association_id
                                  ) ? (
                                    <div
                                      className="like-with-like-number"
                                      onClick={() =>
                                        fnCommentModal(item, "interested")
                                      }
                                    >
                                      <img src={redAdd} alt="like" />

                                      <p>
                                        {item?.total_interested_user} Interested
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="like-with-like-number">
                                      {item?.total_interested_user === 0 ? (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p>
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p
                                            onClick={() =>
                                              fnCommentModal(item, "interested")
                                            }
                                          >
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </>
                              </div>
                              <div className="post-detail">
                                <p>
                                  {item?.comment !== null ? (
                                    item?.comment
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}

                    {(index + 1) % 14 === 0 && (
                      <>
                        {SuggestedPeople?.length > 0 && (
                          <div className="Suggestion-section-main">
                            <div className="suggestion-title">
                              <h5>Suggested for you</h5>
                              <Link onClick={() => setSuggestedModalShow(true)}>
                                <span>see all</span>{" "}
                                <img src={rightArrow} alt="more" />
                              </Link>
                            </div>
                            <div className="all-suggestion-wrapper">
                              {SuggestedPeople?.length > 0 &&
                                SuggestedPeople?.map((item_su, index_su) => (
                                  <div className="suggestion-single">
                                    <Link
                                      to={{
                                        pathname:
                                          "/profile-detail/" +
                                          item_su.member_id,
                                      }}
                                    >
                                      {item_su?.profile_pic !== null &&
                                      item_su?.profile_pic !== "" &&
                                      item_su?.profile_pic !== undefined ? (
                                        <img
                                          src={item_su?.profile_pic}
                                          alt="profile"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="profile"
                                        />
                                      )}

                                      <p
                                        className={
                                          item_su?.member_type === "Standard"
                                            ? "profile-standard-after-initials-title"
                                            : ""
                                        }
                                        title={item_su?.member_name}
                                      >
                                        {item_su?.member_name?.length > 15
                                          ? item_su?.member_name?.slice(0, 15) +
                                            "..."
                                          : item_su?.member_name}{" "}
                                      </p>
                                    </Link>
                                    <button
                                      className="red-btn w-100"
                                      onClick={() =>
                                        fnAddRemoveFollowing(item_su)
                                      }
                                    >
                                      {/* {item_su?.member_id} Follow */}
                                      {item_su?.is_following === 1
                                        ? "Unfollow"
                                        : "Follow"}
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))
              ) : (
                <Loader type={LoaderType.NODATAFOUND} />
              )}
            </div>
            {HomePost?.length > 0 && (
              <>
                {!RemoveMoreButtion && (
                  <div className="text-center w-100 mb-3 view-more-btn">
                    <button
                      className="red-btn red-border width150"
                      onClick={handleLoadMore}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "View More"}
                    </button>
                  </div>
                )}
              </>
            )}
          </Col>
          {RecentBooking?.length > 0 && (
            <Col xl={5} md={5}>
              <div className="homeDashboard-right-main">
                <div className="settings-wallet-bot-header m-0">
                  <h5 className="m-0">Recent Bookings</h5>
                  <div className="see-all">
                    <Link
                      to={{
                        pathname: "/my-booking/" + "1",
                      }}
                    >
                      <span>see all</span> <img src={rightArrow} alt="more" />
                    </Link>
                  </div>
                </div>
                <div
                  className="homeDashboard-right-wrapper"
                  id="ActivityListID"
                >
                  {Loading ? (
                    <Loader type={LoaderType.RecentPost} count={7} />
                  ) : (
                    <>
                      {RecentBooking?.length > 0 ? (
                        RecentBooking?.map((item_r, index_r) => (
                          <>
                            <Link
                              className="ActivityList-class"
                              to={{
                                pathname:
                                  "/booking-detail/" + item_r.sub_order_id,
                              }}
                            >
                              <div className="activity-section-bottom-single">
                                <div className="activity-section-bottom-single-top">
                                  <p>
                                    {commonservices.getDayDateMonth(
                                      item_r?.items[0]?.start_time
                                    )}{" "}
                                    |{" "}
                                    {commonservices.getTimeInFormat(
                                      item_r?.items[0]?.start_time
                                    )}{" "}
                                    -{" "}
                                    {commonservices.getTimeInFormat(
                                      item_r?.items[0]?.end_time
                                    )}
                                  </p>
                                  <span>
                                    {fnHour(item_r?.items[0]?.duration)}
                                  </span>
                                </div>
                                <div className="">
                                  {/* {item_r?.items?.length > 0 &&
                                    item_r?.items.map((item_b1, index_b1) =>
                                      item_b1.item_type === "club_booking" ? (
                                        <div className="activity-section-bottom-single-bottom d-block">
                                          <p>{item_b1?.court_title}</p>
                                          <h6 className="mb-0">
                                            {item_b1?.club_name}
                                          </h6>
                                          <span>{item_b1?.game_type_term}</span>
                                        </div>
                                      ) : (
                                        <div className="activity-section-bottom-single-bottom d-block">
                                          Comming Soon
                                        </div>
                                      )
                                    )} */}
                                  {item_r?.items?.length > 0 && (
                                    <div className="d-flex justify-content-between   align-items-center">
                                      {item_r.items.map((item_b1, index_b1) =>
                                        item_b1.item_type === "club_booking" ? (
                                          <div
                                            key={`club_booking_${index_b1}`}
                                            className="activity-section-bottom-single-bottom d-block"
                                          >
                                            <p>{item_b1?.court_title}</p>
                                            <h6 className="mb-0">
                                              {item_b1?.club_name}
                                            </h6>
                                            <span>
                                              {item_b1?.game_type_term}
                                            </span>
                                          </div>
                                        ) : null
                                      )}

                                      {item_r.items.map((item_b1, index_b1) =>
                                        item_b1.item_type ===
                                        "trainer_booking" ? (
                                          <div
                                            key={`trainer_booking_${index_b1}`}
                                            className="activity-section-bottom-single-bottom d-block"
                                          >
                                            {/* <p>Coming Soon</p> */}
                                            <p>Trainer</p>
                                            <h6 className="mb-0">
                                              {item_b1?.trainer_name}
                                            </h6>
                                            <span>
                                              {item_b1?.game_type_term}
                                            </span>
                                          </div>
                                        ) : null
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </Link>
                          </>
                        ))
                      ) : (
                        <div className="text-center">
                          You have not done any bookings as yet
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>
          )}
        </Row>
        {CreatePostModalShow && (
          <CreatePostModal
            bindList={getHomePostList}
            show={CreatePostModalShow}
            onHide={() => setCreatePostModalShow(false)}
          />
        )}

        <UploadScoreModal
          showUploadScoreModal={showUploadScoreModal}
          closeUploadScoreModal={closeUploadScoreModal}
        />
        <LikesModal
          showLikesModal={showLikesModal}
          closeLikesModal={closeLikesModal}
        />
        {CommentModalShow && (
          <CommentsModal
            bindList={getHomePostList}
            show={CommentModalShow}
            CommentPost={CommentPost}
            ModalType={ModalType}
            onHide={() => setCommentModalShow(false)}
          />
        )}
        {ReportModalShow && (
          <HomeReportModal
            show={ReportModalShow}
            bindList={getHomePostList}
            ReportPost={ReportPost}
            onHide={() => setReportModalShow(false)}
          />
        )}
        {UploadRateCourtModalShow && (
          <UploadScoreModalMain
            show={UploadRateCourtModalShow}
            // param={param?.id}
            bindList={getHomePostList}
            onHide={() => setUploadRateCourtModalShow(false)}
          />
        )}
        {/* {RateCourtModalShow && (
          <RateCourtModal
            show={RateCourtModalShow}
            RateModalType={RateModalType}
            bindList={getHomePostList}
            onHide={() => setRateCourtModalShow(false)}
          />
        )} */}
        {SuggestedModalShow && (
          <SuggestedForModal
            show={SuggestedModalShow}
            bindList={getSuggestedpeople}
            onHide={() => setSuggestedModalShow(false)}
          />
        )}
        <InterestModal
          showInterestModal={showInterestModal}
          closeInterestModal={closeInterestModal}
        />
      </Container>
    </div>
  );
};
export default Dashboard;
