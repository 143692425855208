import React from "react";

const LoaderOverlay = () => {
  return (
    <div className="loader-over-lay">
      <div className="loader-main">
        <div class="loading"></div>
      </div>
    </div>
  );
};

export default LoaderOverlay;
