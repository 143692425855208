import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap/esm";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import searchIcon from "../images/searchIcon.svg";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import CommentsModal from "../Modal/CommentsModal";
import CreatePostModal from "../Modal/CreatePostModal";
import HomeReportModal from "../Modal/HomeReportModal";
import InterestModal from "../Modal/InterestModal";
import LikesModal from "../Modal/LikesModal";
import RateCourtModal from "../Modal/RateCourtModal";
import SuggestedForModal from "../Modal/SuggestedForModal";
import UploadScoreModal from "../Modal/UploadScoreModal";
import Loader, { LoaderType } from "../components/Loader";
import add from "../images/circularAdd.jpg";
import comment from "../images/comment.svg";
import deleteIcon from "../images/Delete (1).svg";
import createIcon from "../images/Add.svg";
import EditIcon from "../images/Update.svg";
import leaveIcon from "../images/Leave.svg";
import like from "../images/like.svg";
import liked from "../images/liked.png";
import imagePlaceholder from "../images/placeholder.png";
import plusIcon from "../images/plusIcon.svg";
import redAdd from "../images/redAdd.png";
import rightArrow from "../images/rightArrow.svg";
import uploadIcon from "../images/uploadIcon.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import AddUpdateGroupModal from "../Modal/AddUpdateGroupModal";
import UploadScoreModalMain from "../Modal/UploadScoreModal";

const CreateGroup = () => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [CreatePostModalShow, setCreatePostModalShow] = useState(false);
  const [CommentModalShow, setCommentModalShow] = useState(false);
  const [CurrentGroupID, setCurrentGroupID] = useState();
  const [AddUpdateModalShow, setAddUpdateModalShow] = useState(false);
  const [AddUpdateModalType, setAddUpdateModalType] = useState(false);
  const [SuggestedModalShow, setSuggestedModalShow] = useState(false);
  const [RateModalType, setRateModalType] = useState("GroupScore");
  const [ReportModalShow, setReportModalShow] = useState(false);
  const [BookingDetail, setBookingDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [UploadRateCourtModalShow, setUploadRateCourtModalShow] =
    useState(false);
  const [HomePost, setHomePost] = useState([]);
  let navigate = useNavigate();
  const [GroupDetail, setGroupDetail] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [RateCourtModalShow, setRateCourtModalShow] = useState(false);
  const [GroupList, setGroupList] = useState([]);
  const [CommentPost, setCommentPost] = useState();
  const [ReportPost, setReportPost] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [GroupListPageNumber, setGroupListPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [ModalType, setModalType] = useState("");
  const [SuggestedPeople, setSuggestedPeople] = useState([]);
  const [showUploadScoreModal, setUploadScoreModal] = useState(false);
  const [Loading, setLoading] = useState(false);
  const handleCreatePostModal = () => {
    setCreatePostModalShow(true);
  };
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [RemoveMoreButtionGroupList, setRemoveMoreButtionGroupList] =
    useState(false);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }
  function fnBookingRateModal(type) {
    setUploadRateCourtModalShow(true);
    // setRateModalType(type);
  }
  async function fnAddRemoveFollowing(item, value) {
    let body = {
      receiver_member_id: item?.member_id,
      follow: 1,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.FOLLOW_UNFOLLOW_MEMBER,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getSuggestedpeople();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  // console.log("totalPages ::", totalPages);
  const closeUploadScoreModal = () => {
    setUploadScoreModal(false);
  };
  const [showLikesModal, setLikesModal] = useState(false);
  const handleLikesModal = () => {
    setLikesModal(true);
  };

  const closeLikesModal = () => {
    setLikesModal(false);
  };
  const [showInterestModal, setInterestModal] = useState(false);
  const closeInterestModal = () => {
    setInterestModal(false);
  };
  async function getHomePostList(pageno) {
    setIsLoading(true);
    try {
      let body = {
        group_id: CurrentGroupID?.group_id,
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_GET_DASHBOARD_FEEDS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        if (pageno === 1 || pageno === undefined) {
          setHomePost(response?.data?.data?.list);
        } else {
          setHomePost((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }
  async function getGroupList(pageno) {
    setLoading(true);
    let body = {
      page: pageno,
      limit: 10,
      search: searchQuery,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      setCurrentGroupID(response?.data?.data?.list[0]);
      // setGroupList(response?.data?.data?.list);
      if (pageno === 1 || pageno === undefined) {
        setGroupList(response?.data?.data?.list);
      } else {
        setGroupList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtionGroupList(true);
      }
      if (response?.data?.data?.totalPages > pageno) {
        setGroupListPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSuggestedpeople() {
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.SUGGESTED_PEOPLE,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setSuggestedPeople(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnLikeDeslikes(item, value) {
    let body = {
      post_id: item.post_id,
      is_like: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_LIKE_DISLIKE_POST,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function fnInterest(item, value) {
    let body = {
      post_id: item.post_id,
    };
    let body1 = {
      interest_id: item.interest_id,
    };
    if (value === "add") {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_ADD_INTEREST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } else {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.POST_REMOVE_INTEREST,
          body: body1,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        getHomePostList(1);
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    }
  }
  async function fnDeletePost(item) {
    let body = {
      post_id: item.post_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.POST_DELETE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getHomePostList(1);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  function fnCommentModal(item, type) {
    setCommentPost(item);
    setCommentModalShow(true);
    setModalType(type);
  }

  function fnReportModal(item) {
    setReportModalShow(true);
    setReportPost(item);
  }
  useEffect(() => {
    getHomePostList(1);
    getSuggestedpeople();
  }, [CurrentGroupID]);
  useEffect(() => {
    getGroupList(1);
  }, [searchQuery]);

  

  useEffect(() => {
    getGroupDetail(CurrentGroupID);
  }, [CurrentGroupID]);

  const handleLoadMore = () => {
    getHomePostList(pageNumber);
  };
  const handleLoadMoreGroupList = () => {
    getGroupList(GroupListPageNumber);
  };

  async function getGroupDetail(item) {
    let body = {
      group_id: item?.group_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_GET_GROUP_DETAIL,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGroupDetail(response?.data?.data);
      //   if (props?.AddUpdateModalType === "UPDATE") {
      //     setInput({
      //       ...input,
      //       ["title"]: response?.data?.data?.title,
      //       ["profile_pic"]: response?.data?.data?.profile_pic,
      //     });
      //  setFile(response?.data?.data?.profile_pic)
      //   }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  

  async function fnDeleteGroup(group_id) {
    let body = {
      group_id: group_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_DELETE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getGroupList();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  async function fnLeaveGroup(group_id) {
    let body = {
      group_id: group_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_LEAVE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      getGroupList();
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  

  return (
    <div className="homeDashboardMain">
      <Container className="small-container">
        <Row>
          <Col xl={7} md={7}>
            <div className="write-something-main mb-2">
              <div className="write-something-bottom">
                <button
                  className="create-btn"
                  type="button"
                  onClick={() => handleCreatePostModal()}
                >
                  <img src={plusIcon} alt="plus" />
                  <span>Create Post</span>
                </button>
                <button
                  className="create-btn"
                  type="button"
                  onClick={() => fnBookingRateModal("")}
                >
                  <img src={uploadIcon} alt="upload" />
                  <span>Upload Score</span>
                </button>
              </div>
            </div>
            <div className="all-post-section-main">
              {isLoading ? (
                <Loader type={LoaderType.HomePost} count={6} />
              ) : HomePost?.length > 0 ? (
                HomePost?.map((item, index) => (
                  <>
                    {item?.type_of_post === "upload_score" ? (
                      <div className="single-post-wrapper FindClubCard-main">
                        <div className="single-post-top">
                          <Link
                            className="FindClubCard-main"
                            to={{
                              pathname: "/profile-detail/" + item.member_id,
                            }}
                          >
                            <div className="d-flex align-items-center profile-and-time">
                              <div className="write-something-profile">
                                {item?.profile_pic !== null &&
                                item?.profile_pic !== "" &&
                                item?.profile_pic !== undefined ? (
                                  <img
                                    src={item?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="username-with-time">
                                <h5>
                                  {item?.member_name}{" "}
                                  <span>shared a score</span>
                                </h5>
                                <p>{moment(item?.created_at).fromNow()} </p>
                              </div>
                            </div>
                          </Link>
                          <div>
                            <Dropdown className="header-dropdown">
                              <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                              <Dropdown.Menu>
                                {LoginInfo?.association_id ===
                                  item?.member_id && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      confirmAlert({
                                        title:
                                          "Are you sure you want to delete this?",
                                        message: "This action cannot be undone",
                                        buttons: [
                                          {
                                            label: "No",
                                          },
                                          {
                                            label: "Yes",
                                            onClick: () => fnDeletePost(item),
                                          },
                                        ],
                                      })
                                    }
                                  >
                                    Delete
                                  </Dropdown.Item>
                                )}
                                {LoginInfo?.association_id !==
                                  item?.member_id && (
                                  <Dropdown.Item
                                    onClick={() => fnReportModal(item)}
                                  >
                                    Report
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        <div
                          className="single-post-middle"
                          onClick={() => fnCommentModal(item, "comment")}
                        >
                          <div className="post-section">
                            <div className="recent-result-wrapper-main">
                              <div className="recent-result-wrapper">
                                {item?.score_details?.score?.length > 0 &&
                                  item?.score_details?.score.map(
                                    (s_item, s_index) => (
                                      <div className="recent-result-single-team">
                                        <p className="team-name">
                                          {s_item.team_name}
                                        </p>
                                        <div className="two-players-section">
                                          {s_item?.players.length > 0 &&
                                            s_item.players.map(
                                              (p_item, p_index) => (
                                                <div className="single-players-section">
                                                  {p_item.player_profile !==
                                                    null &&
                                                  p_item.player_profile !==
                                                    "" &&
                                                  p_item.player_profile !==
                                                    undefined ? (
                                                    <img
                                                      src={
                                                        p_item.player_profile
                                                      }
                                                      alt="club-detail-image"
                                                      onError={({
                                                        currentTarget,
                                                      }) => {
                                                        currentTarget.onerror =
                                                          null;
                                                        currentTarget.src =
                                                          imagePlaceholder;
                                                      }}
                                                    />
                                                  ) : (
                                                    <img
                                                      src={imagePlaceholder}
                                                      alt="club-detail-image"
                                                    />
                                                  )}
                                                  <h6>{p_item.player_name}</h6>
                                                  <p>
                                                    {p_item.avg_rating.toFixed(
                                                      1
                                                    )}
                                                  </p>
                                                </div>
                                              )
                                            )}
                                        </div>
                                        <div className="recent-result-scores-wrapper">
                                          {s_item?.score?.map(
                                            (ss_item, ss_index) => (
                                              <h6
                                                className="recent-result-scores"
                                                key={ss_index}
                                              >
                                                {ss_item}
                                              </h6>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="single-post-bottom">
                          <div className="like-and-comment">
                            <div className="like-with-like-number">
                              <img
                                src={item?.is_like === 1 ? liked : like}
                                alt="like"
                                onClick={() =>
                                  fnLikeDeslikes(
                                    item,
                                    item?.is_like === 1 ? 0 : 1
                                  )
                                }
                              />
                              {item?.total_likes > 0 ? (
                                <p onClick={() => fnCommentModal(item, "like")}>
                                  {item?.total_likes} likes
                                </p>
                              ) : (
                                <p>{item?.total_likes} likes</p>
                              )}
                            </div>

                            <div
                              className="like-with-like-number"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <img src={comment} alt="like" />
                              <p>{item?.total_comment} comments</p>
                            </div>
                            {item?.total_interested_user > 0 && (
                              <div className="like-with-like-number">
                                <img
                                  onClick={() =>
                                    fnInterest(
                                      item,
                                      item?.is_interested === 1
                                        ? "remove"
                                        : "add"
                                    )
                                  }
                                  src={item?.is_interested === 1 ? redAdd : add}
                                  alt="like"
                                />
                                <p
                                  onClick={() =>
                                    fnCommentModal(item, "interested")
                                  }
                                >
                                  {item?.total_interested_user} Interested
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : item?.type_of_post === "normal" ? (
                      <>
                        <div className="single-post-wrapper FindClubCard-main">
                          <div className="single-post-top">
                            <Link
                              className="FindClubCard-main"
                              to={{
                                pathname: "/profile-detail/" + item.member_id,
                              }}
                            >
                              <div className="d-flex align-items-center profile-and-time">
                                <div className="write-something-profile">
                                  {item?.profile_pic !== null &&
                                  item?.profile_pic !== "" &&
                                  item?.profile_pic !== undefined ? (
                                    <img
                                      src={item?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                                <div className="username-with-time">
                                  <h5>
                                    {item?.member_name}{" "}
                                    <span>shared a post</span>
                                  </h5>
                                  <p>{moment(item?.created_at).fromNow()}</p>
                                </div>
                              </div>
                            </Link>
                            <div>
                              <Dropdown className="header-dropdown">
                                <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                <Dropdown.Menu>
                                  {LoginInfo?.association_id ===
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() =>
                                        confirmAlert({
                                          title:
                                            "Are you sure you want to delete this?",
                                          message:
                                            "This action cannot be undone",
                                          buttons: [
                                            {
                                              label: "No",
                                            },
                                            {
                                              label: "Yes",
                                              onClick: () => fnDeletePost(item),
                                            },
                                          ],
                                        })
                                      }
                                    >
                                      Delete
                                    </Dropdown.Item>
                                  )}
                                  {LoginInfo?.association_id !==
                                    item?.member_id && (
                                    <Dropdown.Item
                                      onClick={() => fnReportModal(item)}
                                    >
                                      Report
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>

                          <div
                            className="single-post-middle"
                            onClick={() => fnCommentModal(item, "comment")}
                          >
                            <div className="post-section">
                              <Slider {...settings}>
                                {item?.media_files?.length > 0 &&
                                  item?.media_files.map((m_item, m_index) => (
                                    <div className="slide-out-of-total">
                                      <p className="slide-out-of-total-para">
                                        {m_index + 1}/{" "}
                                        {item?.media_files?.length}
                                      </p>
                                      <div>
                                        {m_item?.image_path !== null &&
                                        m_item?.image_path !== "" &&
                                        m_item?.image_path !== undefined ? (
                                          <img
                                            src={m_item?.image_path}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </Slider>
                            </div>
                          </div>
                          <div className="single-post-bottom">
                            <div className="like-and-comment">
                              <div className="like-with-like-number">
                                <img
                                  src={item?.is_like === 1 ? liked : like}
                                  alt="like"
                                  onClick={() =>
                                    fnLikeDeslikes(
                                      item,
                                      item?.is_like === 1 ? 0 : 1
                                    )
                                  }
                                />
                                {item?.total_likes > 0 ? (
                                  <p
                                    onClick={() => fnCommentModal(item, "like")}
                                  >
                                    {item?.total_likes} likes
                                  </p>
                                ) : (
                                  <p>{item?.total_likes} likes</p>
                                )}
                              </div>

                              <div
                                className="like-with-like-number"
                                onClick={() => fnCommentModal(item, "comment")}
                              >
                                <img src={comment} alt="like" />
                                <p>{item?.total_comment} comments</p>
                              </div>
                              {item?.total_interested_user > 0 && (
                                <div className="like-with-like-number">
                                  <img
                                    src={
                                      item?.is_interested === 1 ? redAdd : add
                                    }
                                    alt="like"
                                  />
                                  <p
                                    onClick={() =>
                                      fnCommentModal(item, "interested")
                                    }
                                  >
                                    {item?.total_interested_user} Interested
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="post-detail">
                              <p>
                                {item?.comment !== null ? item?.comment : <></>}
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      item?.type_of_post === "interest_activity" && (
                        <>
                          <div className="single-post-wrapper FindClubCard-main">
                            <div className="single-post-top">
                              <Link
                                className="FindClubCard-main"
                                to={{
                                  pathname: "/profile-detail/" + item.member_id,
                                }}
                              >
                                <div className="d-flex align-items-center profile-and-time">
                                  <div className="write-something-profile">
                                    {item?.profile_pic !== null &&
                                    item?.profile_pic !== "" &&
                                    item?.profile_pic !== undefined ? (
                                      <img
                                        src={item?.profile_pic}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </div>
                                  <div className="username-with-time">
                                    <h5>
                                      {item?.member_name}{" "}
                                      <span>shared a post</span>
                                    </h5>
                                    <p>{moment(item?.created_at).fromNow()}</p>
                                  </div>
                                </div>
                              </Link>
                              <div>
                                <Dropdown className="header-dropdown">
                                  <Dropdown.Toggle id="dropdown"></Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    {LoginInfo?.association_id ===
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() =>
                                          confirmAlert({
                                            title:
                                              "Are you sure you want to delete this?",
                                            message:
                                              "This action cannot be undone",
                                            buttons: [
                                              {
                                                label: "No",
                                              },
                                              {
                                                label: "Yes",
                                                onClick: () =>
                                                  fnDeletePost(item),
                                              },
                                            ],
                                          })
                                        }
                                      >
                                        Delete
                                      </Dropdown.Item>
                                    )}
                                    {LoginInfo?.association_id !==
                                      item?.member_id && (
                                      <Dropdown.Item
                                        onClick={() => fnReportModal(item)}
                                      >
                                        Report
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            <div
                              className="single-post-middle"
                              onClick={() => fnCommentModal(item, "comment")}
                            >
                              <div className="post-section">
                                <div className="slide-out-of-total">
                                  {item?.club_details?.profile_pic !== null &&
                                  item?.club_details?.profile_pic !== "" &&
                                  item?.club_details?.profile_pic !==
                                    undefined ? (
                                    <img
                                      src={item?.club_details?.profile_pic}
                                      alt="club-detail-image"
                                      onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = imagePlaceholder;
                                      }}
                                    />
                                  ) : (
                                    <img
                                      src={imagePlaceholder}
                                      alt="club-detail-image"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="single-post-bottom">
                              <div className="like-and-comment">
                                <div className="like-with-like-number">
                                  <img
                                    src={item?.is_like === 1 ? liked : like}
                                    alt="like"
                                    onClick={() =>
                                      fnLikeDeslikes(
                                        item,
                                        item?.is_like === 1 ? 0 : 1
                                      )
                                    }
                                  />
                                  {item?.total_likes > 0 ? (
                                    <p
                                      onClick={() =>
                                        fnCommentModal(item, "like")
                                      }
                                    >
                                      {item?.total_likes} likes
                                    </p>
                                  ) : (
                                    <p>{item?.total_likes} likes</p>
                                  )}
                                </div>
                                <div
                                  className="like-with-like-number"
                                  onClick={() =>
                                    fnCommentModal(item, "comment")
                                  }
                                >
                                  <img src={comment} alt="like" />
                                  <p>{item?.total_comment} comments</p>
                                </div>

                                <>
                                  {item?.interested_users.find(
                                    (f) =>
                                      f.member_id === LoginInfo?.association_id
                                  ) ? (
                                    <div
                                      className="like-with-like-number"
                                      onClick={() =>
                                        fnCommentModal(item, "interested")
                                      }
                                    >
                                      <img src={redAdd} alt="like" />

                                      <p>
                                        {item?.total_interested_user} Interested
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="like-with-like-number">
                                      {item?.total_interested_user === 0 ? (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p>
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      ) : (
                                        <>
                                          <img
                                            onClick={() =>
                                              fnInterest(
                                                item,
                                                item?.is_interested === 1
                                                  ? "remove"
                                                  : "add"
                                              )
                                            }
                                            src={add}
                                            alt="like"
                                          />{" "}
                                          <p
                                            onClick={() =>
                                              fnCommentModal(item, "interested")
                                            }
                                          >
                                            {item?.total_interested_user}{" "}
                                            Interested
                                          </p>
                                        </>
                                      )}
                                    </div>
                                  )}
                                </>
                              </div>
                              <div className="post-detail">
                                <p>
                                  {item?.comment !== null ? (
                                    item?.comment
                                  ) : (
                                    <></>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    )}

                    {(index + 1) % 14 === 0 && (
                      <>
                        {SuggestedPeople?.length > 0 && (
                          <div className="Suggestion-section-main">
                            <div className="suggestion-title">
                              <h5>Suggested for you</h5>
                              <Link onClick={() => setSuggestedModalShow(true)}>
                                <span>see all</span>{" "}
                                <img src={rightArrow} alt="more" />
                              </Link>
                            </div>
                            <div className="all-suggestion-wrapper">
                              {SuggestedPeople?.length > 0 &&
                                SuggestedPeople?.map((item_su, index_su) => (
                                  <div className="suggestion-single">
                                    <Link
                                      to={{
                                        pathname:
                                          "/profile-detail/" +
                                          item_su.member_id,
                                      }}
                                    >
                                      {item_su?.profile_pic !== null &&
                                      item_su?.profile_pic !== "" &&
                                      item_su?.profile_pic !== undefined ? (
                                        <img
                                          src={item_su?.profile_pic}
                                          alt="profile"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              imagePlaceholder;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder}
                                          alt="profile"
                                        />
                                      )}

                                      <p
                                        className={
                                          item_su?.member_type === "Standard"
                                            ? "profile-standard-after-initials-title"
                                            : ""
                                        }
                                        title={item_su?.member_name}
                                      >
                                        {item_su?.member_name?.length > 15
                                          ? item_su?.member_name?.slice(0, 15) +
                                            "..."
                                          : item_su?.member_name}{" "}
                                      </p>
                                    </Link>
                                    <button
                                      className="red-btn w-100"
                                      onClick={() =>
                                        fnAddRemoveFollowing(item_su)
                                      }
                                    >
                                      {/* {item_su?.member_id} Follow */}
                                      {item_su?.is_following === 1
                                        ? "Unfollow"
                                        : "Follow"}
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))
              ) : (
                <Loader type={LoaderType.NODATAFOUND} />
              )}
            </div>
            {!RemoveMoreButtion && HomePost?.length > 0 && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </Col>

          <Col xl={5} md={5}>
            <div className="homeDashboard-right-main">
              <div className="settings-wallet-bot-header m-0">
                <h5 className="m-0">My Groups</h5>
                <div className="all-see-all d-flex gap-2 align-items-center">
                  <div className="see-all">
                    <span
                      onClick={() => {
                        setAddUpdateModalShow(true);
                        setAddUpdateModalType("ADD");
                      }}
                    >
                      <img
                        src={createIcon}
                        alt="createIcon"
                        title="Create group"
                      />
                    </span>
                  </div>
                  {GroupDetail?.created_by === LoginInfo?.association_id ? (
                    <>
                      <div className="see-all">
                        <span
                          onClick={() => {
                            setAddUpdateModalShow(true);
                            setAddUpdateModalType("UPDATE");
                          }}
                        >
                          <img
                            src={EditIcon}
                            alt="EditIcon"
                            title="Edit group"
                          />
                        </span>
                      </div>
                      <div className="see-all">
                        <span
                          onClick={() =>
                            confirmAlert({
                              title: "Delete Group",
                              message:
                                "Are you sure you want delete this group?",
                              buttons: [
                                {
                                  label: "No",
                                },
                                {
                                  label: "Yes",
                                  onClick: () => fnDeleteGroup(CurrentGroupID),
                                },
                              ],
                            })
                          }
                        >
                          <img
                            src={deleteIcon}
                            alt="deleteIcon"
                            title="Detele group"
                          />
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      {GroupList?.length > 0 && (
                        <div className="see-all">
                          <span
                            onClick={() =>
                              confirmAlert({
                                title: "Leave Group",
                                message:
                                  "Are you sure you want leave this group?",
                                buttons: [
                                  {
                                    label: "No",
                                  },
                                  {
                                    label: "Yes",
                                    onClick: () => fnLeaveGroup(CurrentGroupID),
                                  },
                                ],
                              })
                            }
                          >
                            <img
                              src={leaveIcon}
                              alt="leaveIcon"
                              title="Leave group"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="modal-header-search-group w-100 my-3">
                <InputGroup className="search-input-group">
                  <Form.Control
                    id="search-input"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <InputGroup.Text id="basic-addon1">
                    <img
                      src={searchIcon}
                      alt="search"
                      className="black-search-icon"
                    />
                  </InputGroup.Text>
                </InputGroup>
              </div>
              <div className="homeDashboard-right-wrapper" id="ActivityListID">
                {Loading && !GroupList?.length > 0 ? (
                  <Loader type={LoaderType.InviteFriend} count={5} />
                ) : (
                  <>
                    {GroupList?.length > 0 ? (
                      GroupList?.map((item_r, index_r) => (
                        <>
                          <div
                            // to={{ pathname: "/profile-detail/" + item?.value }}
                            onClick={() => {
                              // navigate("/profile-detail/" + item_r?.value);
                              // window.location.reload();
                              setCurrentGroupID(item_r);
                              // getGroupDetail(item_r)
                            }}
                            // className="single-notification-single-wrapper"
                            className={
                              CurrentGroupID === item_r
                                ? "active single-notification-single-wrapper"
                                : "single-notification-single-wrapper"
                            }
                          >
                            <div className="gmap-marker">
                              <div className="gmapImage">
                                {item_r?.profile_pic !== null &&
                                item_r?.profile_pic !== "" &&
                                item_r?.profile_pic !== undefined ? (
                                  <img
                                    src={item_r?.profile_pic}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="gmapContent">
                                <h5
                                  className={
                                    item_r?.ownership === "group_admin"
                                      ? "profile-standard-after-initials-title border-0 m-0 p-0 group_admin"
                                      : item_r?.ownership === "Premium"
                                      ? "profile-premium-after-initials-title border-0 m-0 p-0"
                                      : "border-0 m-0 p-0"
                                  }
                                >
                                  {item_r?.title?.length > 20
                                    ? item_r?.title?.slice(0, 20) + "..."
                                    : item_r?.title}
                                </h5>
                                <p className="">
                                  {item_r?.no_of_members} Participant
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <Loader type={LoaderType.NODATAFOUND} />
                    )}
                  </>
                )}
                {Loading && GroupList?.length > 0 && (
                  <Loader type={LoaderType.InviteFriend} count={5} />
                )}

                {!RemoveMoreButtionGroupList && (
                  <div className="text-center w-100 mb-3 view-more-btn">
                    <button
                      className="red-btn red-border width150"
                      onClick={handleLoadMoreGroupList}
                      disabled={Loading}
                    >
                      {Loading ? "Loading..." : "View More"}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        {CreatePostModalShow && (
          <CreatePostModal
            type="SocialGroup"
            CurrentGroupID={CurrentGroupID}
            bindList={getHomePostList}
            show={CreatePostModalShow}
            onHide={() => setCreatePostModalShow(false)}
          />
        )}

        <UploadScoreModal
          showUploadScoreModal={showUploadScoreModal}
          closeUploadScoreModal={closeUploadScoreModal}
        />
        <LikesModal
          showLikesModal={showLikesModal}
          closeLikesModal={closeLikesModal}
        />
        {CommentModalShow && (
          <CommentsModal
            bindList={getHomePostList}
            show={CommentModalShow}
            CommentPost={CommentPost}
            ModalType={ModalType}
            onHide={() => setCommentModalShow(false)}
          />
        )}
        {ReportModalShow && (
          <HomeReportModal
            show={ReportModalShow}
            bindList={getHomePostList}
            ReportPost={ReportPost}
            onHide={() => setReportModalShow(false)}
          />
        )}
        {/* {RateCourtModalShow && (
          <RateCourtModal
            type="SocialGroup"
            CurrentGroupID={CurrentGroupID}
            show={RateCourtModalShow}
            GroupType='GroupType'
            RateModalType={RateModalType}
            bindList={getHomePostList}
            onHide={() => setRateCourtModalShow(false)}
          />
        )} */}
        {UploadRateCourtModalShow && (
          <UploadScoreModalMain
            type="SocialGroup"
            show={UploadRateCourtModalShow}
            CurrentGroupID={CurrentGroupID}
            GroupType="GroupType"
            RateModalType={RateModalType}
            bindList={getHomePostList}
            onHide={() => setUploadRateCourtModalShow(false)}
          />
        )}
        {SuggestedModalShow && (
          <SuggestedForModal
            show={SuggestedModalShow}
            bindList={getSuggestedpeople}
            onHide={() => setSuggestedModalShow(false)}
          />
        )}
        {AddUpdateModalShow && (
          <AddUpdateGroupModal
            show={AddUpdateModalShow}
            AddUpdateModalType={AddUpdateModalType}
            bindList={getGroupList}
            GroupDetail={GroupDetail}
            CurrentGroupID={CurrentGroupID?.group_id}
            onHide={() => setAddUpdateModalShow(false)}
          />
        )}
        <InterestModal
          showInterestModal={showInterestModal}
          closeInterestModal={closeInterestModal}
        />
      </Container>
    </div>
  );
};
export default CreateGroup;
