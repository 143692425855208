import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader, { LoaderType } from "../components/Loader";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";

const StarRateModal = (props) => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [RatingUserList, setRatingUserList] = useState([]);

  async function getRatingUserlist(pageno) {
    setIsLoading(true);
    let body = {};
    debugger
    try {
      // if (props?.BookingDetail) {
      //   body = {
      //     association_type_term: "club",
      //     association_id: props?.BookingDetail?.club_details?.club_id,
      //     page: pageno,
      //     limit: 10,
      //   };
      // } else {
      if (props?.Type === "TrainerRate") {
        body = {
          association_type_term: "trainer",
          association_id: props?.ProfileDetail?.member_id,
          page: pageno,
          limit: 10,
        };
      } else if (props?.Type === "TrainerRatefromDetail") {
        body = {
          association_type_term: "trainer",
          association_id: props?.ProfileDetail?.trainer_details?.trainer_id,
          page: pageno,
          limit: 10,
        };
      } else if (props?.Type === "MemberRate") {
        body = {
          association_type_term: "member",
          association_id: props?.ProfileDetail?.member_id,
          page: pageno,
          limit: 10,
        };
      }else if (props?.Type === "BookingRate") {
        body = {
          association_type_term: "club",
          association_id: props?.BookingDetail?.club_details?.club_id,
          page: pageno,
          limit: 10,
        };
      } else {
        body = {
          association_type_term: "club",
          // association_id: props?.ClubDetail?.club_id,
          association_id: props?.BookingDetail?.club_details?.club_id,
          page: pageno,
          limit: 10,
        };
      }
      // }

      // let body = {
      //   association_type_term:
      //     props?.Type === "TrainerRate" ? "member" : "club",
      //   association_id:
      //     props?.Type === "TrainerRate"
      //       ? props?.BookingDetail?.trainer_club_details?.club_id
      //       : props?.BookingDetail?.club_details?.club_id,
      //   page: pageno,
      //   limit: 10,
      // };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_RATINGS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setIsLoading);
      if (response?.isValidate) {
        // debugger;
        //  setRatingUserList(response?.data?.data?.list);
        if (pageno === 1) {
          setRatingUserList(response?.data?.data?.list);
        } else {
          setRatingUserList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getRatingUserlist(pageNumber);
  }, []);
  const handleLoadMore = () => {
    getRatingUserlist(pageNumber);
  };

  return (
    <>
      <Modal className="court-ratings-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.Type === "TrainerRate" ||
            props?.Type === "TrainerRatefromDetail"
              ? "Trainer "
              : props?.Type === "MemberRate"
              ? "Member "
              : "Club "}
            Ratings
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body id="global-search">
          <div className="court-ratings-modal-inner-top">
            {props?.BookingDetail ? (
              <div className="d-flex align-items-center gap-2">
                <h5>
                  {props?.BookingDetail?.club_details?.avg_ratings === 0
                    ? "0.0"
                    : props?.BookingDetail?.club_details?.avg_ratings?.toFixed(
                        1
                      )}
                </h5>
                <div className="">
                  <div className="d-flex gap-2 align-items-center mb-1 gap-1">
                    {[...Array(5)].map((_, index) => (
                      <svg
                        key={index}
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="20"
                        viewBox="0 0 22 20"
                        fill={
                          index <
                          props?.BookingDetail?.club_details?.avg_ratings
                            ? "#FFB500"
                            : "#BBBBBB"
                        }
                      >
                        <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                      </svg>
                    ))}
                  </div>
                  <p className="m-0">
                    Based on {RatingUserList?.length} Ratings
                  </p>
                </div>
              </div>
            ) : (
              <>
                {props?.Type === "BookingRate" ? (
                  <div className="d-flex align-items-center gap-2">
                    <h5>
                      {props?.ClubDetail?.avg_rating === 0
                        ? "0.0"
                        : props?.ClubDetail?.avg_rating?.toFixed(1)}
                    </h5>
                    <div className=" ">
                      <div className="d-flex align-items-center gap-1 mb-1">
                        {[...Array(5)].map((_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="20"
                            viewBox="0 0 22 20"
                            fill={
                              index < props?.ClubDetail?.avg_rating
                                ? "#FFB500"
                                : "#BBBBBB"
                            }
                          >
                            <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                          </svg>
                        ))}
                      </div>
                      <p className="m-0">
                        Based on {RatingUserList?.length} Ratings
                      </p>
                    </div>
                  </div>
                ) : (
                  <div>
                    {props?.Type === "MemberRate" ? (
                      <div className="d-flex align-items-center gap-2">
                        <h5>
                          {props?.ProfileDetail?.avg_rating === 0
                            ? "0.0"
                            : props?.ProfileDetail?.avg_rating?.toFixed(1)}
                        </h5>
                        <div className="">
                          <div className="d-flex gap-2 align-items-center gap-1 mb-1">
                            {[...Array(5)].map((_, index) => (
                              <svg
                                key={index}
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="20"
                                viewBox="0 0 22 20"
                                fill={
                                  index < props?.ProfileDetail?.avg_rating
                                    ? "#FFB500"
                                    : "#BBBBBB"
                                }
                              >
                                <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                              </svg>
                            ))}
                          </div>
                          <p className="m-0">
                            Based on {RatingUserList?.length} Ratings
                          </p>
                        </div>
                      </div>
                    ) : props?.Type === "TrainerRatefromDetail" ? (
                      <>
                        <div className="d-flex align-items-center gap-2">
                          <h5>
                            {props?.ProfileDetail?.trainer_details
                              ?.trainer_avg_rating === 0
                              ? "0.0"
                              : props?.ProfileDetail?.trainer_details?.trainer_avg_rating?.toFixed(
                                  1
                                )}
                          </h5>
                          <div className="">
                            <div className="d-flex gap-2 align-items-center mb-1 gap-1">
                              {[...Array(5)].map((_, index) => (
                                <svg
                                  key={index}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="20"
                                  viewBox="0 0 22 20"
                                  fill={
                                    index <
                                    props?.ProfileDetail?.trainer_details
                                      ?.trainer_avg_rating
                                      ? "#FFB500"
                                      : "#BBBBBB"
                                  }
                                >
                                  <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                                </svg>
                              ))}
                            </div>
                            <p className="m-0">
                              Based on {RatingUserList?.length} Ratings
                            </p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex align-items-center gap-2">
                          <h5>
                            {props?.ProfileDetail?.trainer_avg_rating === 0
                              ? "0.0"
                              : props?.ProfileDetail?.trainer_avg_rating?.toFixed(
                                  1
                                )}
                          </h5>
                          <div className="">
                            <div className="d-flex gap-2 align-items-center mb-1 gap-1">
                              {[...Array(5)].map((_, index) => (
                                <svg
                                  key={index}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="22"
                                  height="20"
                                  viewBox="0 0 22 20"
                                  fill={
                                    index <
                                    props?.ProfileDetail?.trainer_avg_rating
                                      ? "#FFB500"
                                      : "#BBBBBB"
                                  }
                                >
                                  <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                                </svg>
                              ))}
                            </div>
                            <p className="m-0">
                              Based on {RatingUserList?.length} Ratings
                            </p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="court-ratings-modal-inner-bottom">
            {isLoading && !RatingUserList?.length > 0 ? (
              <>
                <div>
                  <Loader type={LoaderType.InviteFriend} count={4} />
                </div>
              </>
            ) : (
              <>
                {RatingUserList?.length > 0 &&
                  RatingUserList.map((item, index) => (
                    <div className="court-ratings-modal-inner-bottom-list-single invite-friends-single">
                      <div className="court-ratings-modal-bottom-list-img">
                        {item?.profile_pic !== null &&
                        item?.profile_pic !== "" &&
                        item?.profile_pic !== undefined ? (
                          <img
                            src={item?.profile_pic}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                            className="invite-friends-single-image"
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt="club-detail-image"
                            className="invite-friends-single-image"
                          />
                        )}
                        <div>
                          <p>{item?.member_name}</p>
                          <span>{commonservices.getAgo(item?.created_at)}</span>
                        </div>
                      </div>
                      <div className="d-flex gap-2 align-items-center">
                        <p>{item.overall_rating}</p>
                        <div className="d-flex gap-2 align-items-center">
                          {[...Array(5)].map((_, index) => (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill={
                                index < item.overall_rating
                                  ? "#FFB500"
                                  : "#BBBBBB"
                              }
                            >
                              <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                            </svg>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </>
            )}
            {isLoading && RatingUserList?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={5} />
            )}

            {!RemoveMoreButtion && RatingUserList?.length > 0 && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                >
                  {isLoading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default StarRateModal;
