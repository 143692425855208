import axios from "axios";
import { commonservices } from "./CommonServices";

let tokenValue = "";
// DATA SEND LIKE BELOW OBJECT
// {
//   method: "",
//   url: "",
//   body: {
//     username: "",
//     password: "",
//   }
// }

// IF formType PARAMETER IS TRUE THEN IT WILL BE FORM DATA OTHERWISE NORMAL JSON

export const apiCall = async (data, formType) => {
  // debugger;
  tokenValue = localStorage.getItem("Token");

  if (tokenValue !== "" && tokenValue !== undefined && tokenValue !== null) {
    try {
      let formData = new FormData();

      if (formType) {
        for (const [key, value] of Object.entries(data?.body)) {
          if (
            typeof value === "object" &&
            Array.isArray(value) &&
            key !== "componentjson"
          ) {
            value?.map((item, index) => {
              for (const [nestedKey, nestedValue] of Object.entries(item)) {
                formData.append(`${key}[${index}].${nestedKey}`, nestedValue);
              }
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }
      let response = await axios({
        method: data.method,
        url: data?.url,
        maxBodyLength: Infinity,
        data: formType ? formData : data?.body,
        headers: formType
          ? commonservices.getHeadersFromData()
          : commonservices.getHeaders(),
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  } else {
    try {
      let formData = new FormData();

      if (formType) {
        for (const [key, value] of Object.entries(data?.body)) {
          if (
            typeof value === "object" &&
            Array.isArray(value) &&
            key !== "componentjson"
          ) {
            value?.map((item, index) => {
              for (const [nestedKey, nestedValue] of Object.entries(item)) {
                formData.append(`${key}[${index}].${nestedKey}`, nestedValue);
              }
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }
      let response = await axios({
        method: data.method,
        url: data?.url,
        data: formType ? formData : data?.body,
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  }
};

export const apiCallformData = async (data, formType) => {
  if (tokenValue !== "" && tokenValue !== undefined && tokenValue !== null) {
    try {
      let response = await axios({
        method: "POST",
        url: data?.url,
        maxBodyLength: Infinity,
        data: formType ? data?.body : data?.body,
        headers: formType
          ? commonservices.getHeadersFromData()
          : commonservices.getHeaders(),
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  } else {
    try {
      let formData = new FormData();

      if (formType) {
        for (const [key, value] of Object.entries(data?.body)) {
          if (
            typeof value === "object" &&
            Array.isArray(value) &&
            key !== "componentjson"
          ) {
            value?.map((item, index) => {
              for (const [nestedKey, nestedValue] of Object.entries(item)) {
                formData.append(`${key}[${index}].${nestedKey}`, nestedValue);
              }
            });
          } else {
            formData.append(`${key}`, value);
          }
        }
      }
      let response = await axios({
        method: data.method,
        url: data?.url,
        data: formType ? formData : data?.body,
        headers: formType
          ? commonservices.getHeadersFromData()
          : commonservices.getHeaders(),      
      });
      return response?.data;
    } catch (error) {
      return error;
    }
  }
};
