import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import profile from "../images/player2.jpg";
import star from "../images/starIcon.svg";
import { useState } from "react";

function RatePeopleModal({ showRatePeopleModal, closeRatePeopleModal }) {
  const RangeNumber = [5, 10, 15, 20, 30, 50, "ALL"];
  const [input, setInput] = useState({
    sortBy: "",
    gameType: "",
    dropCount: "",
    rangeValue: "",
  });

  function fnCustomRangeSelect(item) {
    if (item === "ALL") {
      // setNewRangeValue("");
      setInput({
        ...input,
        ["rangeValue"]: "",
      });
    } else {
      // setNewRangeValue(item);
      setInput({
        ...input,
        ["rangeValue"]: item,
      });
    }
  }

  return (
    <div className="RatePeopleModalMain">
      <Modal
        className="AddForgotPasswordModalMain"
        size="md"
        centered
        show={showRatePeopleModal}
        onHide={closeRatePeopleModal}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Rate People
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body>
          <div className="rate-court-main">
            <div className="d-flex align-items-center gap-2 mb-3">
              <div className="booking-detail-image-main">
                <img src={profile} alt="detailImg" />
              </div>
              <div className="booking-detail-image-content m-0">
                <div className="courtContent">
                  <div className="court-and-court-name">
                    <h5>Jack Jonas</h5>
                    <p>Level 1</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="rate-court-ratings">
              <h4>Give Ratings</h4>
              <div className="slidermain-with-title">
                <div className="slidermain-with-title-header">
                  <h5>Player Skill</h5>
                  <div className="fill-star-with-ratings">
                    <img src={star} alt="star" />
                    <p>4.0</p>
                  </div>
                </div>

                <div className="slidermain">
                  <div className="d-flex redrange rnagenumber w-100">
                    {RangeNumber?.length > 0 &&
                      RangeNumber.map((item, index) => (
                        <span onClick={() => {
                          fnCustomRangeSelect(item);
                        }}
                          
                          // className="rangeslider"
                          className={
                            input.rangeValue === item
                              ? "active rangeslider "
                              : "rangeslider"
                          }
                        >
                          {item}
                        </span>
                      ))}
                  </div>
                </div>
                <div className="d-flex redrange rnagenumber mt-2 w-100">
                  {RangeNumber?.length > 0 &&
                    RangeNumber.map((item, index) => (
                      <span
                        onClick={() => fnCustomRangeSelect(item)}
                        // className="rangeslider"
                        className={
                          input.rangeValue === item
                            ? "active rangeslider starfill"
                            : "rangeslider"
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path
                            d="M7.17826 0.176107C7.33423 -0.0587023 7.66576 -0.0587023 7.82174 0.176107L10.2311 3.80412C10.2851 3.88629 10.3663 3.94463 10.4588 3.96847L14.7017 5.0663C14.987 5.13984 15.096 5.50387 14.9028 5.73559L12.1611 9.01799C12.0933 9.09893 12.0584 9.20493 12.0647 9.31258L12.3026 13.5646C12.319 13.8588 12.0435 14.0749 11.779 13.9755L7.63245 12.4163C7.54663 12.3842 7.45336 12.3842 7.36754 12.4163L3.22097 13.9755C2.95645 14.0749 2.68099 13.8588 2.69745 13.5646L2.93536 9.31258C2.9414 9.20493 2.90676 9.09893 2.83896 9.01799L0.0973481 5.73559C-0.096127 5.50387 0.0131717 5.13984 0.29827 5.0663L4.54136 3.96847C4.63377 3.94463 4.71469 3.88629 4.76893 3.80412L7.17826 0.176107Z"
                            fill="#BBBBBB"
                          />
                        </svg>
                      </span>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="red-btn red-border w-100">Save</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default RatePeopleModal;
