import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import addbtn from "../images/addwithbg.svg";
import profilePic from "../images/imagePlaceholder.svg";
import groupIcon from "../images/groupIcon.svg";
import deleteIcon from "../images/redDelete.svg";
import imagePlaceholder from "../images/placeholder.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import InviteFriendsForGroupModal from "./InviteFriendsForGroupModal";
import { useSelector } from "react-redux";

const AddUpdateGroupModal = (props) => {
  // debugger;
  // console.log("props ::", props);
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [Loading, setLoading] = useState(false);
  const [InviteFriendData, setInviteFriendData] = useState([]);
  const [InviteFriendModal, setInviteFriendModal] = useState(false);
  const [searchFriend, setsearchFriend] = useState("");
  const [GroupDetail, setGroupDetail] = useState();
  const [GroupMemberList, setGroupMemberList] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [file, setFile] = useState("");
  const [MemberConnection, setMemberConnection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputFile = useRef();
  const [input, setInput] = useState({
    title: "",
    profile_pic: "",
    errors: {
      title: "",
      ValidationRules: [
        {
          FieldName: "title",
          ValidationType: "required",
          ValidationMessage: "This Field is a required field",
        },
      ],
    },
  });

  function fnInviteFriendData(selectedItems) {
    // Update the state with the selected friend data
    const newInviteFriendData = [
      ...props?.InviteData,
      { ...selectedItems, TeamM: props.TeamM, TeamName: props.TeamName },
    ];
    setInviteFriendData(newInviteFriendData);
    props.InviteFriendData(newInviteFriendData);
    props.onHide();
  }

  function fnInviteFriend(item) {
    setInviteFriendModal(true);
  }

  function fnDisable(item) {
    if (props?.InviteData?.length > 0) {
      return props.InviteData.some(
        (element) => element.member_id === item.member_id
      );
    }
    return false;
  }

  async function getGroupDetail() {
    let body = {
      group_id: props?.CurrentGroupID,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_GET_GROUP_DETAIL,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGroupDetail(response?.data?.data);
      if (props?.AddUpdateModalType === "UPDATE") {
        setInput({
          ...input,
          ["title"]: response?.data?.data?.title,
          ["profile_pic"]: response?.data?.data?.profile_pic,
        });
        setFile(response?.data?.data?.profile_pic);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getGroupMember() {
    let body = {
      group_id: props?.CurrentGroupID,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.GROUP_GET_GROUP_MEMBERS,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGroupMemberList(response?.data?.data?.list);
      if (props?.AddUpdateModalType === "UPDATE") {
        setInviteFriendData(response?.data?.data?.list);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getGroupDetail();
    getGroupMember();
  }, []);

  // useEffect(() => {
  //   debugger
  //   console.log(GroupMemberList);
  //   if (props?.AddUpdateModalType === 'UPDATE') {
  //     setInviteFriendData()
  //   }
  // }, [GroupMemberList])

  const handleSubmit = async (e) => {
    e.preventDefault();
    let obj = commonservices.fnCheckValidationOfObject(input);
    setInput({
      ...obj.obj,
    });

    if (obj.isValid) {
      setLoading(true);

      let body = {};
      if (props?.AddUpdateModalType === "UPDATE") {
        
        // debugger
        body = {
          group_id: props?.CurrentGroupID,
          title: input.title.trim(),
          profile_pic: input.profile_pic ? input.profile_pic : "",
        };
      } else {
        body = {
          title: input.title.trim(),
          profile_pic: document.getElementById("profileImg").files[0]
            ? document.getElementById("profileImg").files[0]
            : "",
        };
      }
      // InviteFriendData.forEach((item, index) => {
      //   // body[`members[${index}]`] = item.member_id;
      // });
      body[`members`] = InviteFriendData.filter(
        (member) =>
          member.member_id !== LoginInfo?.association_id &&
          member.member_id !== null
      ).map((member) => member.member_id);

      

      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GROUP_ADD_UPDATE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setLoading);
      if (response?.isValidate) {
        props.onHide();
        props.bindList();
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
  };

  function fnRemove(item) {
    const updatedInviteFriendData = InviteFriendData.filter(
      (friend) => friend.member_id !== item.member_id
    );
    setInviteFriendData(updatedInviteFriendData);
  }
  

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      const img = new Image();
      const reader = new FileReader();

      reader.onload = (e) => {
        img.src = e.target.result;

        img.onload = async () => {
          // Set the maximum width and height for the compressed image
          const maxWidth = 800;
          const maxHeight = 800;

          // Resize the image
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a data URL with compression quality
          const compressedDataURL = canvas.toDataURL("image/jpeg", 0.7);

          // Set the compressed image to state or perform further actions
          setInput({
            ...input,
            ["profile_pic"]: compressedDataURL,
          });
          setFile(compressedDataURL);
        };
      };

      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <Modal className="add-group-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            {props?.AddUpdateModalType === "UPDATE" ? "Update" : "Add"} Group
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        >
          <Modal.Body id="global-search">
            <div className="text-center profileImageSelection group-profile">
              <input
                type="file"
                className="form-control d-none"
                id="profileImg"
                ref={inputFile}
                // accept="image/png, image/gif, image/jpeg"
                // onChange={(e) => {
                //   if (e?.target?.files?.[0]) {
                //     if (
                //       e?.target?.files?.[0]?.type !== "image/png" &&
                //       e?.target?.files?.[0]?.type !== "image/jpeg"
                //     ) {
                //     }
                //     setInput({
                //       ...input,
                //       ["profile_pic"]: e?.target?.files?.[0],
                //     });
                //     setFile(URL.createObjectURL(e.target.files[0]));
                //   }
                // }}
                accept="image/png, image/gif, image/jpeg"
                onChange={handleImageChange}
              />
              {input?.profile_pic ? (
                <img
                  src={file}
                  alt=""
                  type="file"
                  className="image-select"
                  onClick={() => {
                    inputFile.current.click();
                  }}
                />
              ) : (
                <img
                  src={groupIcon}
                  alt=""
                  type="file"
                  className="image-select"
                  onClick={() => {
                    inputFile.current.click();
                  }}
                />
              )}
            </div>
            {/* <h6>Group Name</h6> */}
            <Form.Control
              type="text"
              placeholder="Enter group Name"
              className="black-colored-focused form-control"
              value={input.title}
              onChange={(e) =>
                setInput({
                  ...input,
                  ["title"]: e.target.value,
                })
              }
              isInvalid={input.errors.title}
            />
            {input.errors.title && (
              <Form.Control.Feedback type="invalid">
                {input.errors.title}
              </Form.Control.Feedback>
            )}
            <div className="invite-friends-btn mt-2 mb-2">
              <h5>Group Members</h5>
              <Button onClick={() => fnInviteFriend()} className="my-add-btn">
                <img src={addbtn} alt="add" />
              </Button>
            </div>
            {input.errors.members && (
              <Form.Control.Feedback type="invalid">
                {input.errors.members}
              </Form.Control.Feedback>
            )}
            {InviteFriendData?.length > 0 &&
              InviteFriendData.map((item, index) => (
                <div key={index} className="invite-friends-single">
                  <div className="invite-friends-single-img-name align-items-start">
                    {item?.profile_pic !== null &&
                    item?.profile_pic !== "" &&
                    item?.profile_pic !== undefined ? (
                      <img
                        src={item?.profile_pic}
                        alt="club-detail-image"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = imagePlaceholder;
                        }}
                        className="invite-friends-single-image"
                      />
                    ) : (
                      <img
                        src={imagePlaceholder}
                        alt="club-detail-image"
                        className="invite-friends-single-image"
                      />
                    )}
                    <div>
                      <p
                        className={
                          item?.member_type === "Standard"
                            ? "profile-standard-after-initials-title"
                            : item?.member_type === "Premium"
                            ? "profile-premium-after-initials-title"
                            : ""
                        }
                      >
                        {item?.member_name}
                      </p>
                      <p>{item?.nickname}</p>
                      {item?.action_status === "pending" && (
                        <p className="font-yellow font14">Pending</p>
                      )}
                      {item?.action_status === undefined && (
                        <p className="font-yellow font14">New</p>
                      )}
                    </div>
                  </div>
                  {item?.ownership === "group_admin" ? (
                    <>Owner</>
                  ) : (
                    // <button
                    //   className="white-btn dark-color"
                    //   style={{ height: "34px", fontSize: "14px" }}
                    //   // onClick={() => fnInviteFriendData(item)}

                    // >
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={() => fnRemove(item)}
                      className="cursor-pointer"
                    />
                    // </button>
                  )}
                </div>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="red-btn red-border w-100 mb-2">
              {Loading ? "Loading..." : "Save"}
            </button>
          </Modal.Footer>
        </Form>
      </Modal>

      {InviteFriendModal && (
        <InviteFriendsForGroupModal
          show={InviteFriendModal}
          InviteFriendData={setInviteFriendData}
          InviteData={InviteFriendData}
          onHide={() => setInviteFriendModal(false)}
        />
      )}
    </>
  );
};

export default AddUpdateGroupModal;
