import React, { useState } from "react";
import { Table } from "react-bootstrap";
import DayRestrictionModal from "../../Modal/DayRestrictionModal";

const ClubMembershipBenefits = (props) => {
  const [DayRestrictionsModalShow, setDayRestrictionsModalShow] =
    useState(false);
  const [DayRestrictionsData, setDayRestrictionsData] = useState();
  // free reservation
  function fnDay() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "free_dayly_per_player"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnWeek() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "free_weekly_per_player"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnMonth() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "free_monthly_per_player"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  // reservation
  function fnReservationDay() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "slot_allow_per_day"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnReservationWeek() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "slot_allow_per_week"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnReservationMonth() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "slot_allow_per_month"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  // no. of courts
  function fnNoofCourtsDay() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "court_allow_per_day"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnNoofCourtsWeek() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "court_allow_per_week"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  function fnNoofCourtsMonth() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "court_allow_per_month"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  // Free Guest
  function fnfreeguestDay() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "no_of_allow_free_guest"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  // Repeated Guest
  function fnRepeatedguestDay() {
    let temp = props?.benifits?.rules.find(
      (f) => f.rule_type === "no_of_same_guest"
    );
    return temp ? temp.rule_value || "-" : "-";
  }
  //ends line
  function fnEnsLine() {
    let temp = props?.benifits?.rules.find(
      (f) =>
        f.rule_type === "allow_booking_after_free_trial" && f.rule_value === "1"
    );

    return temp ? (
      (
        <li>
          You can book at discounted price after your free reservations ends.
        </li>
      ) || <></>
    ) : (
      <></>
    );
  }

  return (
    <>
      <div className="special-table-in-modal">
        <Table responsive>
          <thead>
            <tr>
              <th>Benifits</th>
              <th className="width100">Day</th>
              <th className="width100">Week</th>
              <th className="width100">Month</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Free Reservation</td>
              <td className="red-text">{fnDay()}</td>
              <td className="red-text">{fnWeek()}</td>
              <td className="red-text">{fnMonth()}</td>
            </tr>
            <tr>
              <td>Reservation</td>
              <td className="red-text">{fnReservationDay()}</td>
              <td className="red-text">{fnReservationWeek()}</td>
              <td className="red-text">{fnReservationMonth()}</td>
            </tr>
            <tr>
              <td>No. of Courts</td>
              <td className="red-text">{fnNoofCourtsDay()}</td>
              <td className="red-text">{fnNoofCourtsWeek()}</td>
              <td className="red-text">{fnNoofCourtsMonth()}</td>
            </tr>
            <tr>
              <td>Free Guest</td>
              <td className="red-text">-</td>
              <td className="red-text">-</td>
              <td className="red-text">{fnfreeguestDay()}</td>
            </tr>
            <tr>
              <td>Repeated Guest</td>
              <td className="red-text">-</td>
              <td className="red-text">-</td>
              <td className="red-text">{fnRepeatedguestDay()}</td>
            </tr>
          </tbody>
        </Table>
        <div className="special-table-in-modal-listing">
          <ul>
            {props?.benifits?.free_trial_days > 0 && (
              <li>Free trial for {props?.benifits?.free_trial_days} days.</li>
            )}
            {props?.benifits?.reservation_type === "booking,drop_in" ? (
              <li>Court & Open play</li>
            ) : props?.benifits?.reservation_type === "booking" ? (
              <li>Court</li>
            ) : (
              <li>Open play</li>
            )}
            {props?.benifits?.discount_type === "percentage" && (
              <li>
                Get {props?.benifits?.discount_value?.toFixed(1)}% discount.
              </li>
            )}
            {props?.benifits?.discount_type === "value" && (
              <li>
                Get ${props?.benifits?.discount_value?.toFixed(1)} discount.
              </li>
            )}
            {/* {props?.benifits?.rule_type === "allow_booking_after_free_trial" &&
              props?.benifits?.rule_value === 1 && (
                <li>
                  You can book at discounted price after your free reservation
                  ends.
                </li>
              )} */}
            {fnEnsLine()}
            {/* <li>Free trial for 5 days.</li>

            <li>Get 30% discount.</li>
            <li>
              You can book after your free reservation end at discounted price.
            </li> */}
            {props?.benifits?.day_restriction?.length > 0 && (
              <li
                onClick={() => {
                  setDayRestrictionsModalShow(true);
                  setDayRestrictionsData(props?.benifits?.day_restriction);
                }}
              >
                <span className="font-red text-decoration-underline cursor-pointer">
                  Day restrictions
                </span>
              </li>
            )}
          </ul>
        </div>
      </div>
      {DayRestrictionsModalShow && (
        <DayRestrictionModal
          show={DayRestrictionsModalShow}
          DayRestrictionsData={DayRestrictionsData}
          onHide={() => setDayRestrictionsModalShow(false)}
        />
      )}
    </>
  );
};

export default ClubMembershipBenefits;
