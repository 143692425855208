import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import logo from "../images/onlylogo.svg";
import imagePlaceholder from "../images/placeholder.png";
import yellowLogo from "../images/yellowLogo.png";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import MembershipBuyModal from "./MembershipBuyModal";
import moment from "moment";
import Loader, { LoaderType } from "../components/Loader";
import { useNavigate } from "react-router-dom";
import { commonservices } from "../services/CommonServices";
import CancelClubMembershipModal from "./CancelClubMembershipModal";
import redTick from "../images/redtick.svg";
import percentage from "../images/percentage.svg";
import redcalendar from "../images/redcalendar.svg";
import trainer from "../images/trainer.svg";
import ClubMembershipBenefits from "../components/CommonComponents/ClubMembershipBenefits";
import { toast } from "react-toastify";

function ClubSubscriptionModal(props) {
  // console.log("ClubSubscriptionModal props ::", props);
  let navigate = useNavigate();
  const [ActiveClub, setActiveClub] = useState("Club");
  const [ActivePackage_Id, setActivePackage_Id] = useState(null);
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [Subscriptiondata, setSubscriptiondata] = useState({
    package_id: "",
    package_price_id: "",
  });
  const [SubscribeType, setSubscribeType] = useState("");
  const [SubscribebtnLoader, setSubscribebtnLoader] = useState(false);
  const [CancelModalShow, setCancelModalShow] = useState(false);
  const [CancelModalValue, setCancelModalValue] = useState();
  const [Membershipmodalshow, setMembershipmodalshow] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [MembershipDetailList, setMembershipDetailList] = useState([]);
  async function getSubscriptionDetails() {
    let body = {
      club_id: props?.ClubSubscribeModalValue?.club_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_SUBSCRIPTION_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setMembershipDetailList(response?.data?.data?.list);
      let temp = response?.data?.data?.list.find(
        (f) => f.subscription_status === "active"
      );

      if (temp !== undefined) {
        setActivePackage_Id(temp?.package_id);
        setActiveClub("Active");
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    getSubscriptionDetails();
    // getClubSubscriptionList();
  }, [setMembershipDetailList]);

  useEffect(() => {
    getClubSubscriptionList();
  }, [ActivePackage_Id]);

  async function getClubSubscriptionList() {
    setIsLoading(true);
    let body = {
      club_id: props?.ClubSubscribeModalValue?.club_id,
      package_id: ActivePackage_Id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_PACKAGE_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setIsLoading);
    if (response?.isValidate) {
      setSubscriptionList(response.data.data.list);
    }
  }
  async function fnSubscribe() {
    if (Subscriptiondata.package_id !== "") {
      setSubscribebtnLoader(true);
      let body = {
        session_type: "club_level",
        // cancel_url: "http://localhost:3000/find-club",
        cancel_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}find-club`,
        package_id: Subscriptiondata?.package_id,
        package_price_id: Subscriptiondata?.package_price_id?.package_price_id,
        success_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}find-club`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CLUB_SESSION_CREATE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setSubscribebtnLoader);
      if (response?.isValidate) {
        window.open(response.data.data.url, "_self");
        setActiveClub("Active");
        getSubscriptionDetails();

        setSubscriptiondata({
          package_id: "",
          package_price_id: "",
        });
      }
    } else {
      toast.error("Select mwmbership to subscribe");
    }
  }

  // useEffect(() => {
  //   getClubSubscriptionList();
  // }, []);

  function fnCancelSubscribe(value) {
    setCancelModalShow(true);
    setCancelModalValue(value);
  }
  // console.log("MembershipDetailList ::", MembershipDetailList);
  // console.log("SubscriptionList ::", SubscriptionList);
  return (
    <div className="SubsciptionModal-Main">
      <Modal className="subscription-modal-main" size="lg" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Club Membership Plans
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <>
            {ActiveClub === "Active" ? (
              <div className="settings-memberships-main subcription-modal-membership">
                <div className="">
                  {MembershipDetailList?.length > 0 && (
                    <>
                      {MembershipDetailList.filter(
                        (item_ss) => item_ss.subscription_status === "active"
                      ).map((item_ss, index_ss) => (
                        <React.Fragment key={index_ss}>
                          <h5 className="mb-3">Active Membership</h5>
                          <div className="settings-has-memberships-main mb-3">
                            <div className="settings-wallet-header">
                              <div
                                className="my-single-booking align-items-center"
                                style={{ background: "transparent" }}
                              >
                                <div className="court1Image">
                                  <img src={yellowLogo} alt="logo" />
                                </div>
                                <div className="courtContent court-and-court-name">
                                  <h5 className="border-0 m-0 p-0">
                                    {item_ss?.package_name}
                                  </h5>
                                  <p className="font-white">
                                    Next renewal date is{" "}
                                    {commonservices.getDateInFormatCustom(
                                      item_ss?.next_billing_invoice_date,
                                      null,
                                      "DD MMM,yyyy"
                                    )}
                                  </p>
                                  <h5 className="border-0 m-0 p-0">
                                    {item_ss?.currency +
                                      " " +
                                      item_ss?.plan_price?.toFixed(2)}
                                    <span className="font-white">
                                      /{item_ss?.interval_type}
                                    </span>
                                  </h5>
                                  {item_ss?.registration_fee && (
                                    <h5 className="border-0 m-0 p-0">
                                      +{"$" + item_ss?.registration_fee}{" "}
                                      Registration fee
                                    </h5>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="settings-has-memberships-bottom">
                              <div className="membership-element-single-wrap-up">
                                <ClubMembershipBenefits benifits={item_ss} />
                                {item_ss?.subscription_status === "active" && (
                                  <Button
                                    className="red-btn red-border w-100 "
                                    onClick={() => fnCancelSubscribe(item_ss)}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                      {MembershipDetailList.filter(
                        (item_ss) => item_ss.subscription_status !== "active"
                      ).map((item_ss, index_ss) => (
                        <React.Fragment key={index_ss}>
                          {index_ss === 0 && (
                            <h5 className="mb-3">Other Membership</h5>
                          )}
                          <div className="settings-has-memberships-main mb-3">
                            <div className="settings-wallet-header">
                              <div
                                className="my-single-booking align-items-center"
                                style={{ background: "transparent" }}
                              >
                                <div className="court1Image">
                                  <img src={yellowLogo} alt="logo" />
                                </div>
                                <div className="courtContent court-and-court-name">
                                  <h5 className="border-0 m-0 p-0">
                                    {item_ss?.package_name}
                                  </h5>
                                  <p className="font-white">
                                    Next reneewal date is{" "}
                                    {commonservices.getDateInFormatCustom(
                                      item_ss?.next_billing_invoice_date,
                                      null,
                                      "DD MMM,yyyy"
                                    )}
                                  </p>
                                  <h5 className="border-0 m-0 p-0">
                                    {item_ss?.currency +
                                      " " +
                                      item_ss?.plan_price?.toFixed(2)}
                                    <span className="font-white">
                                      /{item_ss?.interval_type}
                                    </span>
                                  </h5>
                                  {item_ss?.registration_fee && (
                                    <h5 className="border-0 m-0 p-0">
                                      +{"$" + item_ss?.registration_fee}{" "}
                                      Registration fee
                                    </h5>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="settings-has-memberships-bottom">
                              <div className="membership-element-single-wrap-up">
                                {/* prakash */}
                                <ClubMembershipBenefits benifits={item_ss} />
                                {item_ss?.subscription_status === "active" && (
                                  <Button
                                    className="red-btn red-border w-100 "
                                    onClick={() => fnCancelSubscribe(item_ss)}
                                  >
                                    Cancel
                                  </Button>
                                )}
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </>
                  )}
                </div>
              </div>
            ) : (
              <>
                <div className="settings-memberships-main">
                  <div className="settings-memberships-inner">
                    {isLoading ? (
                      <Loader type={LoaderType.HomePost} count={1} />
                    ) : (
                      <>
                        <div>
                          {SubscriptionList?.length > 0 ? (
                            SubscriptionList?.map((s_item, s_index) => (
                              // <>
                              //   {MembershipDetailList?.every(
                              //     (subscription) =>
                              //       parseInt(subscription?.package_id) !==
                              //       parseInt(s_item?.package_id)
                              //   ) && (
                              <>
                                {s_index === 0 && (
                                  <h5 className="my-3">Club Membership</h5>
                                )}
                                <div
                                  className="plan-premium-main"
                                  style={{ background: "#ffffff" }}
                                >
                                  <div className="plan-header">
                                    <div>
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="plan-header-logo">
                                          <img src={logo} alt="logo" />
                                        </div>
                                        <div className="plan-name">
                                          <h6>{s_item?.package_name}</h6>
                                          {/* {s_item?.free_trial_days > 0 && (
                                            <h5>
                                              Free trial for{" "}
                                              {s_item?.free_trial_days} days
                                            </h5>
                                          )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="plan-body-main">
                                    {s_item?.prices?.length > 0 &&
                                      s_item?.prices.map(
                                        (su_item, su_index) => (
                                          <>
                                            <div
                                              key={su_index}
                                              className="red-radio-btn plan-detail-check-box plan-detail-single"
                                            >
                                              <Form.Check.Label
                                                for={su_item?.price}
                                              >
                                                <div>
                                                  <span className="font-red me-2">
                                                    {s_item?.currency}
                                                  </span>
                                                  <span className="font-red">
                                                    {`${su_item?.price?.toFixed(
                                                      2
                                                    )}`}
                                                  </span>
                                                  <span>
                                                    {"/" +
                                                      su_item?.interval_type}
                                                  </span>
                                                  {su_item?.registration_fee && (
                                                    <div>
                                                      <span>
                                                        $
                                                        {su_item?.registration_fee?.toFixed(
                                                          2
                                                        )}{" "}
                                                        Registration fee
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                              </Form.Check.Label>

                                              <Form.Check
                                                type="radio"
                                                // disabled={MembershipDetailList?.some(
                                                //   (subscription) =>
                                                //     parseInt(
                                                //       subscription.plan_price
                                                //     ) ===
                                                //       parseInt(
                                                //         su_item?.price
                                                //       ) &&
                                                //     parseInt(
                                                //       subscription?.package_id
                                                //     ) ===
                                                //       parseInt(
                                                //         s_item?.package_id
                                                //       )
                                                // )}
                                                name="gender"
                                                // label={`${
                                                //   "$" +
                                                //   su_item?.price?.toFixed(2)
                                                // }/${su_item?.interval_type}`}
                                                id={su_item?.price}
                                                value={su_item?.price}
                                                onClick={() =>
                                                  setSubscriptiondata({
                                                    package_id:
                                                      s_item?.package_id,
                                                    package_price_id: su_item,
                                                  })
                                                }
                                              />
                                            </div>
                                          </>
                                        )
                                      )}
                                    {/* <h5 className="py-2">Benefits</h5> */}
                                    {/* here */}
                                    <ClubMembershipBenefits benifits={s_item} />

                                    {/* {s_item?.rules?.length > 0 &&
                                      s_item?.rules.map((su_item, su_index) => (
                                        <h6 className="py-2">
                                          {su_item?.rule_name}:{" "}
                                          <span className="font-red">
                                            {su_item?.rule_value}
                                          </span>
                                          {su_item?.rule}
                                        </h6>
                                      ))} */}
                                  </div>
                                  <div className="membership-elements-all mt-0">
                                    {s_item?.benefits?.length > 0 &&
                                      s_item?.benefits.map(
                                        (s_item1, s_index) => (
                                          <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                            <div className="plan-header-logo">
                                              {s_item1?.term_icon !== null &&
                                              s_item1?.term_icon !== "" &&
                                              s_item1?.term_icon !==
                                                undefined ? (
                                                <img
                                                  style={{
                                                    width: "30px",
                                                    height: "30px",
                                                  }}
                                                  src={s_item1?.term_icon}
                                                  alt="club-detail-image"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      imagePlaceholder;
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={imagePlaceholder}
                                                  alt="club-detail-image"
                                                />
                                              )}
                                            </div>
                                            <div className="plan-name">
                                              <h4>{s_item1?.term_name}</h4>
                                              <p>{s_item1?.term_details}</p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </div>
                              </>
                              //   )}
                              // </>
                            ))
                          ) : (
                            <Loader type={LoaderType.NODATAFOUND} />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  {SubscriptionList?.length > 0 &&
                    SubscriptionList?.map((s_item, s_index) => (
                      <>
                        {s_index === 0 && (
                          <>
                            <div
                              className="plan-premium-main"
                              style={{ background: "#ffffff" }}
                            ></div>
                            <div className="mt-3 sticky-footer-btn pb-3">
                              <button
                                className="red-btn red-border w-100"
                                onClick={() => fnSubscribe()}
                              >
                                {SubscribebtnLoader
                                  ? "Loading..."
                                  : "Subscribe"}
                              </button>
                            </div>
                          </>
                        )}
                        {/* </>
                      )} */}
                      </>
                    ))}
                </div>
              </>
            )}
          </>
        </Modal.Body>
        {ActiveClub === "Active" && (
          <Modal.Footer>
            <button
              className="red-btn red-border w-100"
              onClick={() => setActiveClub("Club")}
            >
              Manage
            </button>
          </Modal.Footer>
        )}
      </Modal>
      {CancelModalShow && (
        <CancelClubMembershipModal
          show={CancelModalShow}
          CancelModalValue={CancelModalValue}
          setMembershipDetailList={getSubscriptionDetails}
          onHide={() => {
            setCancelModalShow(false);
            // props.onHide();
          }}
        />
      )}
    </div>
  );
}

export default ClubSubscriptionModal;
