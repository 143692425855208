import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";
import information from "../images/informaton.svg";

const AddPlayerModal = (props) => {
  // console.log("props ::", props);
  const [Loading, setLoading] = useState(false);
  const [searchFriend, setsearchFriend] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [MemberConnection, setMemberConnection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function getMemberconnections(pageno) {
    setLoading(true);
    try {
      let body = {
        page: pageno,
        limit: 10,
        search: searchFriend,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.MEMBER_CONNECTIONS,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        if (pageno === 1) {
          setMemberConnection(response?.data?.data?.list);
        } else {
          setMemberConnection((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getMemberconnections(pageNumber);
  }, [props?.InviteData, searchFriend]);

  function fnInviteFriendData(selectedItems) {
    const newInviteFriendData = [
      ...props?.InviteData,
      { ...selectedItems, TeamM: props.TeamM, TeamName: props.TeamName },
    ];
    setInviteFriendData1(newInviteFriendData);
    props.InviteFriendData(newInviteFriendData);
    props.onHide();
  }

  function fnDisable(item) {
    if (props?.InviteData?.length > 0) {
      return props.InviteData.some(
        (element) => element.member_id === item.member_id
      );
    }
    return false;
  }

  const handleLoadMore = () => {
    getMemberconnections(pageNumber);
  };

  return (
    <>
      <Modal
        className="invite-friend-modal-main-wrapper"
        size="md"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite Friends
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body id="global-search">
          <InputGroup className="search-input-group mb-4">
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              className="dark-color-text"
              value={searchFriend}
              onChange={(e) => setsearchFriend(e.target.value)}
            />
            <InputGroup.Text id="basic-addon1">
              <img
                src={searchIcon}
                alt="search"
                className="black-search-icon"
              />
            </InputGroup.Text>
          </InputGroup>
          {/* {Loading ? (
            <Loader type={LoaderType.InviteFriend} count={5} />
          ) : ( */}
          <div className="invite-friends-main">
            {Loading && !MemberConnection?.length > 0 ? (
              <>
                <Loader type={LoaderType.InviteFriend} count={5} />
              </>
            ) : (
              <>
                {MemberConnection?.length > 0 ? (
                  MemberConnection.map((item, index) => (
                    <div key={index} className="invite-friends-single">
                      <div className="invite-friends-single-img-name">
                        {item?.profile_pic !== null &&
                        item?.profile_pic !== "" &&
                        item?.profile_pic !== undefined ? (
                          <img
                            src={item?.profile_pic}
                            alt="club-detail-image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = imagePlaceholder;
                            }}
                            className="invite-friends-single-image"
                          />
                        ) : (
                          <img
                            src={imagePlaceholder}
                            alt="club-detail-image"
                            className="invite-friends-single-image"
                          />
                        )}
                        <p>{item?.member_name}</p>
                      </div>
                      <Button
                        className={`red-btn red-border ${
                          fnDisable(item) ? "disabled" : ""
                        }`}
                        onClick={() => fnInviteFriendData(item)}
                        disabled={fnDisable(item)}
                        style={{ height: "34px", fontSize: "14px" }}
                      >
                        {props?.InviteData.includes(item.member_id)
                          ? "Invited"
                          : "Invite"}
                      </Button>
                    </div>
                  ))
                ) : (
                  <div className="text-center d-flex align-items-center justify-content-center gap-1">
                    <img src={information} alt="information" />{" "}
                    <p>No data found</p>
                  </div>
                )}
              </>
            )}
            {Loading && MemberConnection?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={5} />
            )}

            {!RemoveMoreButtion && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={Loading}
                >
                  {Loading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddPlayerModal;
