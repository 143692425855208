import React, { useEffect, useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import imagePlaceholder from "../images/placeholder.png";
import searchIcon from "../images/searchIcon.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import Loader, { LoaderType } from "../components/Loader";

const GlobalSearchModal = (props) => {
  const [Loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [GlobalSearchList, setGlobalSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  async function getGlobalSearch(pageno) {
    
    // if (searchQuery.length > 3) {
      setLoading(true); 

      try {
        let body = {
          page: pageno,
          limit: 10,
          search: searchQuery,
        };
        let resData = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.GLOBAL_SEARCH,
            body: body,
          },
          false
        );
        let response = apiResponse(false, resData, setLoading);
        if (response?.isValidate) {
          if (pageno === 1) {
            setGlobalSearchList(response?.data?.data?.users);
          } else {
            setGlobalSearchList((prevItems) => [
              ...prevItems,
              ...response?.data?.data?.users,
            ]);
          }
        }
        if (response?.data?.data?.totalPages) {
          if (pageno === response?.data?.data?.totalPages) {
            setRemoveMoreButtion(true);
          }
          setTotalPages(response?.data?.data?.totalPages);
          if (response?.data?.data?.totalPages > pageno) {
            setPageNumber(pageno + 1);
          }
        }
        if (!response?.isValidate) {
          console.log("Error getting country list", response);
        }
      } catch (error) {
        console.error("Error fetching club data:", error);
      }
    // }
  }

  useEffect(() => {
    getGlobalSearch(1);
  }, [searchQuery]);

  const handleLoadMore = () => {
    getGlobalSearch(pageNumber);
  };

  useEffect(() => {
    const searchInput = document.getElementById("search-input");

    if (searchInput) {
      searchInput.focus();
    }
  }, []);
  return (
    <>
      <Modal size="lg" style={{ overflow: "hidden" }} {...props} centered>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Search</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body id="global-search">
          <div className="modal-header-search-group w-100">
            <InputGroup className="search-input-group">
              <Form.Control
                id="search-input"
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <InputGroup.Text id="basic-addon1">
                <img
                  src={searchIcon}
                  alt="search"
                  className="black-search-icon"
                />
              </InputGroup.Text>
            </InputGroup>
          </div>
          <div className="invite-friends-main invite-friends-main-global-search">
            {Loading && GlobalSearchList?.length > 0 ? (
              <Loader type={LoaderType.InviteFriend} count={4} />
            ) : (
              <>
                {GlobalSearchList?.length > 0 ? (
                  GlobalSearchList?.map((item, index) => (
                    <>
                      {item?.type === "member" && (
                        <div
                          // to={{ pathname: "/profile-detail/" + item?.value }}
                          onClick={() => {
                            navigate("/profile-detail/" + item?.value);
                            props.onHide(); // Close the modal
                            window.location.reload();
                          }}
                          className="single-notification-single-wrapper"
                        >
                          <div className="gmap-marker  mb-3">
                            <div className="gmapImage">
                              {item?.profile !== null &&
                              item?.profile !== "" &&
                              item?.profile !== undefined ? (
                                <img
                                  src={item?.profile}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                />
                              )}
                            </div>
                            <div className="gmapContent">
                              <h5
                                className={
                                  item?.member_type === "Standard"
                                    ? "profile-standard-after-initials-title border-0 m-0 p-0"
                                    : item?.member_type === "Premium"
                                    ? "profile-premium-after-initials-title border-0 m-0 p-0"
                                    : "border-0 m-0 p-0"
                                }
                              >
                                {item?.title?.length > 20
                                  ? item?.title?.slice(0, 20) + "..."
                                  : item?.title}
                              </h5>
                              <p className="">
                                {item?.sub_title?.length > 20
                                  ? item?.sub_title?.slice(0, 25) + "..."
                                  : item?.sub_title}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {item?.type === "club" && (
                        <div
                          className="single-notification-single-wrapper"
                          // to={{ pathname: "/club-details/" + item?.value }}
                          onClick={() => {
                            navigate("/club-details/" + item?.value);
                            props.onHide(); // Close the modal
                            window.location.reload();
                          }}
                        >
                          <div className="gmap-marker  mb-3">
                            <div className="gmapImage">
                              {item?.profile !== null &&
                              item?.profile !== "" &&
                              item?.profile !== undefined ? (
                                <img
                                  src={item?.profile}
                                  alt="club-detail-image"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagePlaceholder;
                                  }}
                                />
                              ) : (
                                <img
                                  src={imagePlaceholder}
                                  alt="club-detail-image"
                                />
                              )}
                            </div>
                            <div className="gmapContent">
                              <h5 className="border-0 m-0 p-0">
                                {item?.title?.length > 20
                                  ? item?.title?.slice(0, 20) + "..."
                                  : item?.title}
                              </h5>
                              <p className="">{item?.type}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))
                ) : (
                  <div className="text-center my-5">
                    <h4>No data found</h4>
                  </div>
                )}
              </>
            )}
            {Loading && GlobalSearchList?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={4} />
            )}

            {GlobalSearchList?.length > 0 && (
              <>
                {!RemoveMoreButtion && (
                  <div className="text-center w-100 mb-3 view-more-btn">
                    <button
                      className="red-btn red-border width150"
                      onClick={handleLoadMore}
                      disabled={Loading}
                    >
                      {Loading ? "Loading..." : "View More"}
                    </button>
                  </div>
                )}
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GlobalSearchModal;
