import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import information from "../images/informaton.svg";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";

const InviteFriendsModal = (props) => {
  const [Loading, setLoading] = useState(false);
  const [searchFollower, setsearchFollower] = useState("");
  const [InviteFriendData1, setInviteFriendData1] = useState([]);
  const [MemberConnection, setMemberConnection] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [error, setError] = useState(null);

  async function getMemberconnections(pageno) {
    setLoading(true);
    let body = {
      page: pageno,
      limit: 10,
      search: searchFollower,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.MEMBER_CONNECTIONS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (pageno === 1) {
        setMemberConnection(response?.data?.data?.list);
      } else {
        setMemberConnection((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    getMemberconnections(pageNumber);
  }, [searchFollower]);

  function fnInviteFriendData(item) {
    if (!InviteFriendData1.includes(item)) {
      setInviteFriendData1([...InviteFriendData1, item]);
      props.InviteFriendData(InviteFriendData1);
    }
  }

  useEffect(() => {
    if (
      props?.InviteData !== null &&
      props?.InviteData !== "" &&
      props?.InviteData !== undefined
    ) {
      setInviteFriendData1(...InviteFriendData1, props?.InviteData);
    }
  }, []);

  useEffect(() => {
    props.InviteFriendData(InviteFriendData1);
  }, [InviteFriendData1]);

  const handleLoadMore = () => {
    getMemberconnections(pageNumber);
  };

  return (
    <>
      <Modal
        className="invite-friend-modal-main-wrapper only-for-invite-club-friend"
        size="md"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Invite Friends
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body id="global-search">
          <div className="modal-header-search-group w-100">
            <InputGroup className="search-input-group">
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                // className="dark-color-text"
                // value={searchTerm}
                // onChange={(e) => setSearchTerm(e.target.value)}
                value={searchFollower}
                onChange={(e) => setsearchFollower(e.target.value)}
              />
              <InputGroup.Text id="basic-addon1">
                <img
                  src={searchIcon}
                  alt="search"
                  className="black-search-icon"
                />
              </InputGroup.Text>
            </InputGroup>
          </div>
          {Loading && !MemberConnection?.length > 0 ? (
            <>
              <Loader type={LoaderType.InviteFriend} count={5} />
            </>
          ) : (
            <div className="invite-friends-main mt-2">
              {MemberConnection?.length > 0 ? (
                MemberConnection.map((item, index) => (
                  <div key={index} className="invite-friends-single">
                    <div className="invite-friends-single-img-name">
                      {item?.profile_pic !== null &&
                      item?.profile_pic !== "" &&
                      item?.profile_pic !== undefined ? (
                        <img
                          src={item?.profile_pic}
                          alt="club-detail-image"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imagePlaceholder;
                          }}
                          className="invite-friends-single-image"
                        />
                      ) : (
                        <img
                          src={imagePlaceholder}
                          alt="club-detail-image"
                          className="invite-friends-single-image"
                        />
                      )}
                      <p>{item?.member_name}</p>
                    </div>
                    <button
                      className={`red-btn red-border ${
                        InviteFriendData1.includes(item) ? "disabled" : ""
                      }`}
                      onClick={() => fnInviteFriendData(item)}
                      disabled={InviteFriendData1.includes(item)}
                      style={{ height: "34px", fontSize: "14px" }}
                    >
                      {InviteFriendData1.includes(item) ? "Invited" : "Invite"}
                    </button>
                  </div>
                ))
              ) : (
                <div className="d-flex justify-content-center gap-1 align-items-center w-100">
                  <img src={information} alt="info" />
                  <p>Data not found</p>
                </div>
              )}
            </div>
          )}
          {Loading && MemberConnection?.length > 0 && (
            <Loader type={LoaderType.InviteFriend} count={5} />
          )}

          {!RemoveMoreButtion && (
            <div className="text-center w-100 mb-3 view-more-btn">
              <button
                className="red-btn red-border width150"
                onClick={handleLoadMore}
                disabled={Loading}
              >
                {Loading ? "Loading..." : "View More"}
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteFriendsModal;
