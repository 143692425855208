import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import imagePlaceholder from "../images/imagePlaceholder1.png";
import React, { useEffect, useState } from "react";
import searchIcon from "../images/searchIcon.svg";
import Spinner from "react-bootstrap/Spinner";
import locationpin from "../images/locationPin.png";
import mappin from "../images/mappin.svg";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { Link } from "react-router-dom";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";
const lib = ["places"];

const GmapModal = (props) => {
  const [location, setLocation] = useState(null);
  const [ClubList, setClubList] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [latitude, setlatitude] = useState("");
  const [longitude, setlongitude] = useState("");
  let presentLocation = {
    lat: parseFloat(latitude),
    lng: parseFloat(longitude),
  };
  const [center, setCenter] = useState();
  const [ZOOM, setZOOM] = useState(14);
  const [searchBox, setSearchBox] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setlatitude(position?.coords?.latitude);
          setlongitude(position?.coords?.longitude);
          // debugger;
          setCenter({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
          getClubList(position?.coords?.latitude, position?.coords?.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    // setZOOM(10);
  };

  const onPlacesChanged = () => {
    const [place] = searchBox.getPlaces();
    if (place) {
      const selectedLat = place.geometry.location.lat();
      const selectedLng = place.geometry.location.lng();

      setLocation(`${selectedLat},${selectedLng}`);
      setCenter({
        lat: selectedLat,
        lng: selectedLng,
      });

      getClubList(selectedLat, selectedLng);
    }
    
    setZOOM(13);
  };

  const onSBLoad = (ref) => {
    // debugger;
    setSearchBox(ref);
  };
  async function getClubList(lat, long) {
    setLoading(true);
    let body = {
      timezone: localStorage.getItem("timezone"),
      latitude: lat,
      longitude: long,
      // latitude:
      //   location?.latitude !== "" &&
      //   location?.latitude !== undefined &&
      //   location?.latitude !== null
      //     ? location?.latitude
      //     : "",
      // longitude:
      //   location?.longitude !== "" &&
      //   location?.longitude !== undefined &&
      //   location?.longitude !== null
      //     ? location?.longitude
      //     : "",
      sort_by: "",
      slot_type: "",
      distance: 50,
      game_type: "",
      start_date: "",
      end_date: "",
      search: "",
      page: "",
      limit: 50,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      let temp = [];

      if (response?.data.data.list.length > 0) {
        response?.data.data.list.forEach((element) => {
          temp.push({
            profile: element?.profile_pic,
            id: element?.club_id,
            clubname: element?.club_name,
            name:
              element?.address !== "" && element?.address !== null
                ? element?.address
                : "No address",
            position: {
              lat: parseFloat(element?.latitude),
              lng: parseFloat(element?.longitude),
            },
          });
        });
      }
      setClubList(temp);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  const handleCurrentLocation = () => {
    // debugger;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          getClubList(latitude, longitude);
          setZOOM(13);
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by your browser");
    }
  };
  // useEffect(() => {
  //   getClubList();
  // }, []);

  

  return (
    <>
      <Modal className="google-map-modal" size="xl" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Google Map
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body className="g-map-modal-body p-0">
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_APIKEY}
            libraries={lib}
          >
            <GoogleMap
              mapContainerStyle={{ height: "100%", width: "100%" }}
              center={center ? center : presentLocation}
              zoom={ZOOM}
              options={{ mapTypeControl: false }}
            >
              <StandaloneSearchBox
                onPlacesChanged={onPlacesChanged}
                onLoad={onSBLoad}
              >
                <div className="modal-header-search-group d-flex gap-3 w-100 align-items-center mt-2">
                  <InputGroup className="search-input-group width300 ms-3">
                    <Form.Control
                      placeholder="Search"
                      aria-label="Search"
                      aria-describedby="basic-addon5"
                    />
                    <InputGroup.Text id="basic-addon5">
                      <img
                        src={searchIcon}
                        alt="search"
                        className="black-search-icon"
                      />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="location-pin" onClick={handleCurrentLocation}>
                    <img src={locationpin} alt="locationpin" />
                  </div>
                  {Loading && (
                    // <Spinner animation="grow" className="custom-loader" />
                    <LoaderOverlay />
                  )}
                </div>
              </StandaloneSearchBox>

              {center && (
                <Marker
                  position={center}
                  icon={{
                    url: mappin,
                  }}
                />
              )}

              {ClubList.length > 0 &&
                ClubList.map(({ id, name, position, clubname, profile }) => (
                  <div className="marker-on-map">
                    <Marker
                      key={id}
                      position={position}
                      onClick={() => handleActiveMarker(id)}
                      options={{ shape: { type: "circle" } }}
                      icon={{
                        url:
                          profile !== null &&
                          profile !== "" &&
                          profile !== undefined
                            ? profile
                            : imagePlaceholder,
                        scaledSize: new window.google.maps.Size(30, 30),
                        shape: {
                          coords: [0, 0, 40],
                          type: "circle",
                        },
                        borderRadius: "50%",
                      }}
                    >
                      {activeMarker === id ? (
                        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                          <Link to={{ pathname: "/club-details/" + id }}>
                            <div className="gmap-marker">
                              <div className="gmapImage">
                                {profile !== null &&
                                profile !== "" &&
                                profile !== undefined ? (
                                  <img
                                    src={profile}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagePlaceholder;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="gmapContent">
                                <h5 className="border-0 m-0 p-0">
                                  {clubname?.length > 20
                                    ? clubname?.slice(0, 20) + "..."
                                    : clubname}
                                </h5>
                                <p className="">
                                  {name?.length > 20
                                    ? name?.slice(0, 25) + "..."
                                    : name}
                                </p>
                              </div>
                            </div>
                          </Link>
                        </InfoWindow>
                      ) : null}
                    </Marker>
                  </div>
                ))}
            </GoogleMap>
          </LoadScript>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GmapModal;
