import React, { useState, useEffect } from "react";
import imagePlaceholder from "../images/placeholder.png";
import { Form, InputGroup, Modal } from "react-bootstrap";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import information from "../images/informaton.svg";
import { apiResponse } from "../services/APIResponse";
import searchIcon from "../images/searchIcon.svg";
import Loader, { LoaderType } from "../components/Loader";
import { commonservices } from "../services/CommonServices";

const BookCourtTrainerModal = (props) => {
  // console.log("BookCourtTrainerModal ::",props);
  const [Loading, setLoading] = useState(false);
  const [searchFollower, setsearchFollower] = useState("");
  const [TrainerData1, setTrainerData1] = useState([]);
  const [MemberConnection, setMemberConnection] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [NotificationList, setNotificationList] = useState([]);
  const [error, setError] = useState(null);

  async function getMemberconnections(pageno) {
    setLoading(true);
    let resData = {};
    if (props?.Type !== "BookingFromClub") {
      let body = {
        start_time: props?.TrainerClubDetail?.start_time,
        end_time: props?.TrainerClubDetail?.end_time,
        page: pageno,
        limit: 10,
        search: searchFollower,
        trainer_id: props?.CoachDetail?.trainer_id,
      };
      resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_CLUB_BY_TIME,
          body: body,
        },
        false
      );
    } else {
      let body = {
        start_time: props?.TrainerClubDetail?.start_time,
        end_time: props?.TrainerClubDetail?.end_time,
        page: pageno,
        limit: 10,
        search: searchFollower,
        club_id: props?.CoachDetail?.club_id,
      };
      resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_TRAINER_BY_TIME,
          body: body,
        },
        false
      );
    }

    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (pageno === 1) {
        setMemberConnection(response?.data?.data?.list);
      } else {
        setMemberConnection((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      } else {
        setRemoveMoreButtion(false);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }

  useEffect(() => {
    getMemberconnections(pageNumber);
  }, [searchFollower]);

  function fnTrainerData(item) {
    props?.TrainerData(item);
    props?.onHide();
  }
  function fnClubData(item) {
    props?.ClubData(item);
    props?.onHide();
  }

  

  const handleLoadMore = () => {
    getMemberconnections(pageNumber);
  };

  return (
    <>
      <Modal
        className="invite-friend-modal-main-wrapper"
        size="md"
        centered
        {...props}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Add to Cart
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        {props?.Type !== "BookingFromClub" ? (
          // club
          <Modal.Body id="global-search">
            <div className="modal-header-search-group w-100">
              <InputGroup className="search-input-group">
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchFollower}
                  onChange={(e) => setsearchFollower(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="black-search-icon"
                  />
                </InputGroup.Text>
              </InputGroup>
            </div>
            {Loading && !MemberConnection?.length > 0 ? (
              <>
                <Loader type={LoaderType.InviteFriend} count={5} />
              </>
            ) : (
              <>
                <div className="invite-friends-main">
                  {MemberConnection?.length > 0 ? (
                    MemberConnection.map((item, index) => (
                      <div key={index} className="invite-friends-single mt-4">
                        <div className="invite-friends-single-img-name align-items-start">
                          {item?.club_image !== null &&
                          item?.club_image !== "" &&
                          item?.club_image !== undefined ? (
                            <img
                              src={item?.club_image}
                              alt="club-detail-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder;
                              }}
                              className="invite-friends-single-image"
                            />
                          ) : (
                            <img
                              src={imagePlaceholder}
                              alt="club-detail-image"
                              className="invite-friends-single-image"
                            />
                          )}
                          <div>
                            <p>{item?.club_name}</p>
                            <p>{item?.address}</p>
                            <p>
                              <b>${item?.price}</b>
                            </p>
                          </div>
                        </div>
                        <button
                          className={`red-btn red-border px-3${
                            TrainerData1.includes(item) ? "disabled" : ""
                          }`}
                          onClick={() => fnClubData(item)}
                          disabled={TrainerData1.includes(item)}
                          style={{ height: "34px", fontSize: "14px" }}
                        >
                          {TrainerData1.includes(item) ? "Added" : "Add"}
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="mt-4 d-flex justify-content-center gap-1 align-items-center w-100">
                      {/* <img src={information} alt="info" />
                  <p>Data not found ss</p> */}
                      <h5>No clubs found for selected time slot!</h5>
                    </div>
                  )}
                </div>
              </>
            )}
            {Loading && MemberConnection?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={5} />
            )}
            {!RemoveMoreButtion && MemberConnection?.length > 0 && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={Loading}
                >
                  {Loading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </Modal.Body>
        ) : (
          // trainer
          <Modal.Body id="global-search">
            <div className="modal-header-search-group w-100">
              <InputGroup className="search-input-group">
                <Form.Control
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="basic-addon1"
                  value={searchFollower}
                  onChange={(e) => setsearchFollower(e.target.value)}
                />
                <InputGroup.Text id="basic-addon1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="black-search-icon"
                  />
                </InputGroup.Text>
              </InputGroup>
            </div>
            {Loading && !MemberConnection?.length > 0 ? (
              <>
                <Loader type={LoaderType.InviteFriend} count={5} />
              </>
            ) : (
              <>
                <div className="invite-friends-main">
                  {MemberConnection?.length > 0 ? (
                    MemberConnection.map((item, index) => (
                      <div key={index} className="invite-friends-single mt-4">
                        <div className="invite-friends-single-img-name align-items-start">
                          {item?.trainer_image !== null &&
                          item?.trainer_image !== "" &&
                          item?.trainer_image !== undefined ? (
                            <img
                              src={item?.trainer_image}
                              alt="club-detail-image"
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = imagePlaceholder;
                              }}
                              className="invite-friends-single-image"
                            />
                          ) : (
                            <img
                              src={imagePlaceholder}
                              alt="club-detail-image"
                              className="invite-friends-single-image"
                            />
                          )}
                          <div>
                            <p>{item?.trainer_name}</p>
                            <p>{item?.club_details?.club_name}</p>
                            <p>
                              {commonservices.getDateInFormatCustom(
                                item?.start_time,
                                item?.timezone,
                                "DD-MM-yyyy"
                              )}
                            </p>
                            <p>
                              <b>${item?.discount_price}</b>
                            </p>
                          </div>
                        </div>

                        <button
                          className={`red-btn red-border px-3${
                            TrainerData1.includes(item) ? "disabled" : ""
                          }`}
                          onClick={() => fnTrainerData(item)}
                          disabled={TrainerData1.includes(item)}
                          style={{ height: "34px", fontSize: "14px" }}
                        >
                          {TrainerData1.includes(item) ? "Added" : "Add"}
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className="mt-4 d-flex justify-content-center gap-1 align-items-center w-100">
                      {/* <img src={information} alt="info" />
                  <p>Data not found ss</p> */}
                      <h5>No clubs found for selected time slot!</h5>
                    </div>
                  )}
                </div>
              </>
            )}
            {Loading && MemberConnection?.length > 0 && (
              <Loader type={LoaderType.InviteFriend} count={5} />
            )}
            {!RemoveMoreButtion && MemberConnection?.length > 0 && (
              <div className="text-center w-100 mb-3 view-more-btn">
                <button
                  className="red-btn red-border width150"
                  onClick={handleLoadMore}
                  disabled={Loading}
                >
                  {Loading ? "Loading..." : "View More"}
                </button>
              </div>
            )}
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default BookCourtTrainerModal;
