import React from "react";
import { Col, Row, Modal, Form, InputGroup } from "react-bootstrap";
import logo from "../images/onlylogo.svg";
import profile2 from "../images/player2.jpg";
import post2 from "../images/post2.png";
import SinglePost from "../components/CommonComponents/SinglePost";
import searchIcon from "../images/searchIcon.svg";
import threedots from "../images/threeDots.svg";

function LikesModal({ showLikesModal, closeLikesModal }) {
  return (
    <div className="likes-modal-wrapper">
      <Modal
        className="likes-modal-main"
        size="xl"
        centered
        show={showLikesModal}
        onHide={closeLikesModal}
      >
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Likes</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body>
          <div className="like-modal-inner-wrapper">
            <Row>
              <Col xl={7} md={7} className="like-modal-inner-left">
                <SinglePost
                  profilepic={profile2}
                  username="Melisa Robert"
                  time="3 hours"
                  postImage={post2}
                  likes="140"
                  comments="120"
                  postDetail="The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using..."
                />
              </Col>
              <Col xl={5} md={5}>
                <div className="all-likes-wrapper">
                  <h5 className="likes-title">Likes</h5>
                  <div className="modal-header-search-group w-100">
                    <InputGroup className="search-input-group">
                      <Form.Control
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                      />
                      <InputGroup.Text id="basic-addon1">
                        <img
                          src={searchIcon}
                          alt="search"
                          className="black-search-icon"
                        />
                      </InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="all-sungle-likes-wrapper">
                    <div className="likes-signle d-flex align-items-center justify-content-between">
                      <div className="single-post-top">
                        <div className="d-flex align-items-center profile-and-time">
                          <div className="write-something-profile">
                            <img src={profile2} alt="profile" />
                          </div>
                          <div className="username-with-time">
                            <h5>Jack Jonas</h5>
                            <p>jack_76</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="red-btn red-border"
                        style={{
                          height: "34px",
                          fontSize: "14px",
                          width: "100px",
                        }}
                      >
                        Follow
                      </button>
                    </div>
                    <div className="likes-signle d-flex align-items-center justify-content-between">
                      <div className="single-post-top">
                        <div className="d-flex align-items-center profile-and-time">
                          <div className="write-something-profile">
                            <img src={profile2} alt="profile" />
                          </div>
                          <div className="username-with-time">
                            <h5>Jack Jonas</h5>
                            <p>jack_76</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="red-btn red-border"
                        style={{
                          height: "34px",
                          fontSize: "14px",
                          width: "100px",
                        }}
                      >
                        Follow
                      </button>
                    </div>
                    <div className="likes-signle d-flex align-items-center justify-content-between">
                      <div className="single-post-top">
                        <div className="d-flex align-items-center profile-and-time">
                          <div className="write-something-profile">
                            <img src={profile2} alt="profile" />
                          </div>
                          <div className="username-with-time">
                            <h5>Jack Jonas</h5>
                            <p>jack_76</p>
                          </div>
                        </div>
                      </div>
                      <button
                        className="red-btn red-border"
                        style={{
                          height: "34px",
                          fontSize: "14px",
                          width: "100px",
                        }}
                      >
                        Follow
                      </button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LikesModal;
