import React, { useEffect, useState } from "react";
import { Col, Row, Modal, Form, InputGroup } from "react-bootstrap";
import cancel from "../images/Cancel.png";
import success from "../images/Success.png";
import pending from "../images/Process.png";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { commonservices } from "../services/CommonServices";

function TrasactionModal(props) {
  const [TransactionList, setTransactionList] = useState([]);
  async function getTransactionList() {
    // setLoading(true);
    let body = {
      wallet_trans_id: props?.TransactionValue?.wallet_trans_id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.WALLET_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setTransactionList(response?.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getTransactionList();
  }, []);

  return (
    <Modal className="add-balance-modal-main" size="md" centered {...props}>
      <Modal.Header closeButton>
        <div style={{ visibility: "hidden" }}>A</div>
        <Modal.Title id="contained-modal-title-vcenter">
          Transactions
        </Modal.Title>
        <div style={{ visibility: "hidden" }}>A</div>
      </Modal.Header>
      <Modal.Body>
        <div className="add-balance-top mb-0">
          <p className="mb-1">Amount</p>
          <div className="d-flex align-items-center gap-2 ">
            <h6>${TransactionList?.amount?.toFixed(2)}</h6>
            {TransactionList?.transaction_status === "pending" && (
              <img
                src={pending}
                alt="pending"
                className="cancel-payment-icon"
              />
            )}
            {TransactionList?.transaction_status === "cancel" ||
              (TransactionList?.transaction_status === null && (
                <img
                  src={cancel}
                  alt="cancel"
                  className="cancel-payment-icon"
                />
              ))}
            {TransactionList?.transaction_status === "succeeded" && (
              <img
                src={success}
                alt="success"
                className="cancel-payment-icon"
              />
            )}
          </div>
          {TransactionList?.transaction_status === "pending" && (
            <p className="font-yellow">pending</p>
          )}
          {TransactionList?.transaction_status === "succeeded" && (
            <p className="font-green">succeeded</p>
          )}
        </div>
        <div className="transaction-review">
          <h6>{TransactionList?.narration}</h6>
        </div>
        <div className="court-and-court-name">
          <p>{TransactionList?.trans_id}</p>
          <p>
            {commonservices.getDayDateMonthTimeTransaction(
              TransactionList?.created_at
            )}
          </p>
        </div>

        {/* <br />
        <img src={cancel} alt="cancel" className="cancel-payment-icon" />
        <div className="add-balance-top mb-0">
          <p className="mb-1">Amount</p>
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center gap-2 ">
              <h6>$999999.99</h6>
              <img
                src={success}
                alt="success"
                className="cancel-payment-icon"
              />
            </div>
            <p className="font-green">succeeded</p>
          </div>
        </div>
        <div className="transaction-review">
          <h6>Minus Amount From Wallet</h6>
        </div>
        <div className="court-and-court-name">
          <p>Mon 30 Oct 2023| 09:15</p>
        </div>

        <br />

        <div className="add-balance-top mb-0">
          <p className="mb-1">Amount</p>
          <div className="d-flex align-items-center justify-content-between ">
            <div className="d-flex align-items-center gap-2 ">
              <h6>$999999.99</h6>
              <img
                src={pending}
                alt="pending"
                className="cancel-payment-icon"
              />
            </div>
            <p className="font-yellow">pending</p>
          </div>
        </div>
        <div className="transaction-review">
          <h6>Add Amount To Wallet</h6>
        </div>
        <div className="court-and-court-name">
          <p>123456789aaaaaa12345678</p>
          <p>Mon 30 Oct 2023| 09:15</p>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}

export default TrasactionModal;
