import React, { useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import searchIcon from "../images/searchIcon.svg";
import information from "../images/informaton.svg";
import Loader, { LoaderType } from "../components/Loader";

const PhoneCodeModal = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  function fnPhonecall(item) {
    // debugger
    props.PhoneCode(item?.phonecode);
    props.CountryID(item?.id);
    props.onHide();
  }

  return (
    <>
      <Modal className="phone-code-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">Search</Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body>
          <div className="invite-friends-main">
            <div className="modal-header-search-group pt-2 px-3 w-100">
              <InputGroup className="search-input-group my-search-diff">
                <Form.Control
                  type="text"
                  className=""
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <InputGroup.Text id="basic-addon1">
                  <img
                    src={searchIcon}
                    alt="search"
                    className="black-search-icon"
                  />
                </InputGroup.Text>
              </InputGroup>
            </div>
          </div>
          <div className="country-list">
            {props?.CountryList.length > 0 ? (
              props?.CountryList.filter((item) => {
                const searchTerm = searchQuery.toLowerCase();
                return (
                  item.sortname.toLowerCase().includes(searchTerm) ||
                  item.name.toLowerCase().includes(searchTerm) ||
                  (item.phonecode &&
                    item.phonecode.toString().includes(searchQuery))
                );
              }).map((item, index) => (
                <div
                  key={index}
                  onClick={() => fnPhonecall(item)}
                  className="d-flex align-items-center justify-content-between single-country"
                >
                  <div className="d-flex gap-2 align-items-center">
                    <p className="mb-2">{item.sortname}</p>
                    <p className="mb-2">{item.name}</p>
                  </div>
                  <p className="">+{item.phonecode}</p>
                </div>
              ))
            ) : (
              <Loader type={LoaderType.NODATAFOUND} />
            )}

            {/* Display "No data found" message if no search results */}
            {props?.CountryList.length > 0 &&
              props?.CountryList.filter((item) => {
                const searchTerm = searchQuery.toLowerCase();
                return (
                  item.sortname.toLowerCase().includes(searchTerm) ||
                  item.name.toLowerCase().includes(searchTerm) ||
                  (item.phonecode &&
                    item.phonecode.toString().includes(searchQuery))
                );
              }).length === 0 && (
                <div className="text-center d-flex align-items-center justify-content-center gap-1">
                  <img src={information} alt="information" />{" "}
                  <Loader type={LoaderType.NODATAFOUND} />
                </div>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PhoneCodeModal;
