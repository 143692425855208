import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddToCartModal from "../Modal/AddToCartModal";
import ClubActivityModal from "../Modal/ClubActivityModal";
import StarRateModal from "../Modal/StarRateModal";
import SubsciptionModal from "../Modal/SubscriptionModal";
import LoaderOverlay from "../components/CommonComponents/LoaderOverlay";
import Map from "../components/GoogleMap";
import Loader, { LoaderType } from "../components/Loader";
import InPageNav from "../components/PageNav/InPageNav";
import addIcon from "../images/add-btn.svg";
import calendar from "../images/calendar.svg";
import calendarsmall from "../images/calendarsmall.svg";
import callIcon from "../images/callIcon.svg";
import clock from "../images/clock.svg";
import crown from "../images/crown.png";
import group from "../images/group.svg";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import information from "../images/informaton.svg";
import locationIcon from "../images/locationIcon.svg";
import next from "../images/next.svg";
import previous from "../images/previous.svg";
import statusIcon from "../images/statusIcon.svg";
import webIcon from "../images/webIcon.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";
import OpenPlayModal from "../Modal/OpenPlayModal";
import ClubSubscriptionModal from "../Modal/ClubSubscriptionModal";
import { toast } from "react-toastify";

const ClubDetails = () => {
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [StartDate, setStartDate] = useState("");
  const [PassedDate, setPassedDate] = useState("");

  let ClubIndex = 1;
  let navigate = useNavigate();
  let param = useParams();

  const [Loading, setLoading] = useState(false);
  const [GetCountOpenPlay, setGetCountOpenPlay] = useState({
    hour: "",
    min: "",
  });
  const [GetCountForCourt, setGetCountForCourt] = useState();
  const [ClubDetailLoader, setClubDetailLoader] = useState(false);
  const [DropInSlotShow, setDropInSlotShow] = useState(false);
  const [SlotLoading, setSlotLoading] = useState(false);
  const [AddToCartModalShow, setAddToCartModalShow] = useState(false);
  const [ClubSubscribeModalShow, setClubSubscribeModalShow] = useState(false);
  const [ClubSubscribeModalValue, setClubSubscribeModalValue] = useState();
  const [AvailableCours, setAvailableCours] = useState([]);
  const [AvailableCoursLoading, setAvailableCoursLoading] = useState(false);
  const [AvailableTempCours, setAvailableTempCours] = useState([]);
  const [AvailableSlotLoaing, setAvailableSlotLoaing] = useState(false);
  const [OpenPlayModalShow, setOpenPlayModalShow] = useState(false);
  const [AvailableSlot, setAvailableSlot] = useState({
    startdate: "",
    endDate: "",
    data: "",
    is_available_for_booking: "",
    price: "",
  });
  const [timezone, settimezone] = useState("");
  const [AvailableCartData, setAvailableCartData] = useState("");
  const [ActiveClass, setActiveClass] = useState(false);
  const [ActivityModalShow, setActivityModalShow] = useState(false);
  const [CourtOnlyView, SetCourtOnlyView] = useState(false);
  const [DropinCourtOnlyView, SetDropinCourtOnlyView] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [StarRateModalShow, setStarRateModalShow] = useState(false);
  const [ClubDetail, setClubDetail] = useState();
  const [ActivityList, setActivityList] = useState([]);
  const [ActivityType, setActivityType] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  const [BookingDates, setBookingDates] = useState([]);
  const [SlotbyDate, setSlotbyDate] = useState([]);
  const [NewTimeZOnw, setNewTimeZOnw] = useState("");
  const [TempSlot, setTempSlot] = useState([]);
  const pageNav = [
    {
      name: "Club",
      link: "/find-club",
      active: false,
    },
    {
      name: ClubDetail?.club_name,
      link: "/club-details",
      active: true,
    },
  ];
  const [checkBox, setCheckBox] = useState(false);
  const [checkBox1, setCheckBox1] = useState(false);

  async function getClubDeatils() {
    setClubDetailLoader(true);
    let body = {
      club_id: param.id,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setClubDetailLoader);
    if (response?.isValidate) {
      // debugger
      setNewTimeZOnw(response?.data.data.timezone);
      setClubDetail(response.data.data);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getBookingDates() {
    setLoading(true);
    let body = {
      club_id: param.id,
      limit: 90,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_GET_BOOKING_DATES,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      // setNewTimeZOnw(response?.data.data.timezone);
      setBookingDates(response?.data.data.list);
      getSlotbyDate(
        PassedDate !== "" ? PassedDate : response?.data?.data?.list[0]?.date
      );
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getSlotbyDate(date, value) {
    setAvailableCours([]);
    setStartDate(date);
    // setEndDate(edate);
    // if (value === "") {
    //   setStartDate("");
    //   fngetCourtsbydate();
    // }

    setActiveClass(date);
    setSlotLoading(true);
    let body = {
      club_id: param.id,
      date: date !== "" ? date : "",
      // is_available_for_booking : slot
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_GET_SLOT_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setSlotLoading);
    if (response?.isValidate) {
      let date = response?.data.data.list.map((item) => {
        if (PassedDate === item.start_time) {
          item.active = true;
        }

        return item;
      });

      // debugger;
      setSlotbyDate(date);
      setTempSlot(date);
      fnAvailableSlotsFromApi(checkBox, date);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function getActivityList(pageno) {
    setIsLoading(true);
    try {
      let body = {
        club_id: param.id,
        page: pageno,
        limit: 10,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.ACTIVITY_LIST,
          body: body,
        },
        false
      );
      let response = apiResponse(false, resData, setLoading);
      if (response?.isValidate) {
        if (pageno === 1) {
          setActivityList(response?.data?.data?.list);
        } else {
          setActivityList((prevItems) => [
            ...prevItems,
            ...response?.data?.data?.list,
          ]);
        }
      }
      if (response?.data?.data?.totalPages) {
        if (pageno === response?.data?.data?.totalPages) {
          setRemoveMoreButtion(true);
        } else {
          setRemoveMoreButtion(false);
        }
        setTotalPages(response?.data?.data?.totalPages);
        if (response?.data?.data?.totalPages > pageno) {
          setPageNumber(pageno + 1);
        }
      }
      if (!response?.isValidate) {
        console.log("Error  getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }
  function fnHour(time) {
    if (time !== undefined && time !== null) {
      const temp = moment.duration(time);
      const minutes = temp.asMinutes();

      if (minutes < 60) {
        return minutes.toFixed() + " min";
      } else {
        const hours = Math.floor(minutes / 60);
        return hours.toFixed() + " hr";
      }
    }
  }

  // console.log("ActivityList ::", ActivityList);

  const fnAvailableSlots = (event) => {
    // debugger;
    setCheckBox(event?.target?.checked);
    const checkedValue = event.target.checked ? 1 : 0;
    SetCourtOnlyView(false);
    // const checkedValue = event.target.checked ? 1 : 0;
    let data = [];
    setAvailableSlot({
      startdate: "",
      endDate: "",
      data: "",
      is_available_for_booking: "",
      price: "",
    });
    setAvailableCours([]);
    if (checkedValue === 1) {
      SetCourtOnlyView(false);
      data = SlotbyDate.filter((itm) => {
        itm.active = false;
        return itm.is_available_for_booking === checkedValue;
      });
      setSlotbyDate(data);
    } else {
      setSlotbyDate(
        TempSlot.map((item) => {
          item.active = false;
          return item;
        })
      );
    }
  };

  const fnAvailableSlotsFromApi = (event, slotData) => {
    let checkedValue = 1;
    if (event?.target?.checked) {
      setCheckBox(event?.target?.checked);
      checkedValue = event.target.checked ? 1 : 0;
    } else {
      checkedValue = event ? 1 : 0;
    }
    let data = [];
    if (checkedValue === 1) {
      data = slotData.filter((itm) => {
        return itm.is_available_for_booking === checkedValue;
      });
      setSlotbyDate(data);
    } else {
      setSlotbyDate(slotData);
    }
  };
  async function fngetCourtsbydate(item) {
    setAvailableCoursLoading(true);
    setAvailableSlotLoaing(true);

    let body = {
      club_id: param.id,
      slot_type: "booking",
      date_time: item.start_time,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_GET_COURTS_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setAvailableCoursLoading);
    if (response?.isValidate) {
      setAvailableSlot({
        startdate: "",
        endDate: "",
        data: "",
        is_available_for_booking: "",
        price: "",
      });
      setAvailableCours(response?.data.data.list);
      setAvailableTempCours(response?.data.data.list);
      fnAvailableCourtsFromApi(checkBox1, response?.data.data.list);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  async function Get_Count_by_date(item) {
    let body = {
      club_id: param.id,
      start_time: item.start_time,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_GET_COUNT_BY_DATE,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setGetCountForCourt(response?.data?.data?.no_of_free_booking);
      let noOfFreeBookings = response?.data?.data?.no_of_free_booking;
      let totalDurationInMinutes;
      if (noOfFreeBookings > 1) {
        const bookingDurationInMinutes = 30;
        totalDurationInMinutes = noOfFreeBookings * bookingDurationInMinutes;
      } else {
        totalDurationInMinutes = 30;
      }
      const hours = Math.floor(totalDurationInMinutes / 60);
      const minutes = totalDurationInMinutes % 60;
      setGetCountOpenPlay({
        hour: hours,
        min: minutes,
      });
      //court count
      // let ISfree_booking_allowed = response?.data?.data?.is_free_booking_allowed;
      // let totalDurationInMinutes1;
      // if (ISfree_booking_allowed > 1) {
      //   const bookingDurationInMinutes = 30;
      //   totalDurationInMinutes1 = ISfree_booking_allowed * bookingDurationInMinutes;
      // } else {
      //   totalDurationInMinutes1 = 30;
      // }
      // const hours1 = Math.floor(totalDurationInMinutes1 / 60);
      // const minutes1 = totalDurationInMinutes1 % 60;
      // setGetCountForCourt({
      //   hour: hours1,
      //   min: minutes1,
      // });
    }
  }
  console.log("GetCountOpenPlay ::", GetCountOpenPlay);
  console.log("GetCountForCourt ::", GetCountForCourt);
  const fnAvailableCours = (event) => {
    //  debugger
    // const checkedValue = event.target.checked ? 1 : 0;
    // let data = [];
    // if (checkedValue === 1) {
    //   data = AvailableCours.filter((itm) => {
    //     return itm.is_available_for_booking === checkedValue;
    //   });

    //   setAvailableCours(data);
    // } else {
    //   setAvailableCours(AvailableTempCours);
    // }
    setCheckBox1(event?.target?.checked);
    const checkedValue = event.target.checked ? 1 : 0;

    // const checkedValue = event.target.checked ? 1 : 0;
    let data = [];
    if (checkedValue === 1) {
      data = AvailableCours.filter((itm) => {
        return itm.is_available_for_booking === checkedValue;
      });
      setAvailableCours(data);
    } else {
      setAvailableCours(AvailableTempCours);
    }
  };
  const fnAvailableCourtsFromApi = (event, courtData) => {
    let checkedValue = 1;
    if (event?.target?.checked) {
      setCheckBox1(event?.target?.checked);
      checkedValue = event.target.checked ? 1 : 0;
    } else {
      checkedValue = event ? 1 : 0;
    }
    let data = [];
    if (checkedValue === 1) {
      data = courtData.filter((itm) => {
        return itm.is_available_for_booking === checkedValue;
      });
      setAvailableCours(data);
    } else {
      setAvailableCours(courtData);
    }
  };

  async function fngetDropinParticipants(item) {
    // debugger
    setAvailableSlotLoaing(true);
    setAvailableCoursLoading(true);
    // debugger;
    let body = {
      club_id: param.id,
      start_time: item.start_time,
      end_time: item.end_time,
      price: item.discount_price,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_GET_DROPIN_PARTICIPANTS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setAvailableSlotLoaing);
    if (response?.isValidate) {
      setDropInSlotShow(true);
      console.log("Data ::", response?.data.data);
      setAvailableCoursLoading(false);
      // debugger;
      setAvailableCours([]);
      setAvailableTempCours([]);
      // setAvailableSlot(response?.data.data);
      setAvailableSlot({
        startdate: item.start_time,
        endDate: item.end_time,
        data: response?.data.data,
        is_available_for_booking: item.is_available_for_booking,
        price:
          ClubDetail?.is_subscriber === 1
            ? item.discount_price
            : item.original_price,
      });
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }
  // console.log("AvailableSlot ::::::", AvailableSlot);
  async function fnAddtoJoin(item) {
    let body = {
      is_buy_now: 1,
      items: [
        {
          booking_slot_id: item?.data?.booking_slot_id,
          item_type: "club_booking",
        },
      ],
    };

    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CART_ADD,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      navigate(`/cart-confirmation/${response?.data?.data?.cart_id}`);
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnNotifydropinbooking(item) {
    setLoading(true);
    let body = {
      club_id: param.id,
      start_time: item.startdate,
      end_time: item.endDate,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_NOTIFY_DROPIN_BOOKING,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  async function fnFavourite(item, value) {
    let body = {
      module_id: item.club_id,
      module_type_term: "club",
      is_favourite: value,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_ADD_FAVOURITE,
        body: body,
      },
      false
    );

    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      getClubDeatils();
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  function fnClubActivity(type) {
    setActivityModalShow(true);
    setActivityType(type);
  }

  function fnStarRate() {
    setStarRateModalShow(true);
  }

  function fnAddtoCartModal(item) {
    setAddToCartModalShow(true);
    setAvailableCartData(item);
  }
  // console.log("AvailableCours ::", AvailableCours);
  useEffect(() => {
    if (param?.date) {
      const [startDateString, edate] = param?.date.split("_");

      // debugger;

      setPassedDate(startDateString);
      let body = {
        club_id: param.id,
        slot_type: "booking",
        start_time: startDateString,
      };
      // fngetCourtsbydate(body);
      // getSlotbyDate(startDateString, "1");
    }
  }, []);

  useEffect(() => {
    getClubDeatils();
    getBookingDates();
    getActivityList(1);
  }, []);

  const handleLoadMore = () => {
    getActivityList(pageNumber);
  };

  useEffect(() => {
    // Scroll from top to bottom smoothly when the component loads
    window.scrollTo(0, 0);
  }, []);
  // console.log("SlotbyDate ::", SlotbyDate);
  function fnOnSETimeClick(item) {
    // debugger;

    setStartDate(item.start_time);
    let body = {
      club_id: param.id,
      slot_type: "booking",
      start_time: item.start_time,
    };

    fngetCourtsbydate(body);
  }

  function fnClubSubscribeModal(item) {
    setClubSubscribeModalShow(true);
    setClubSubscribeModalValue(item);
  }

  return (
    <div className="find-clubs-main">
      {ClubDetailLoader && <LoaderOverlay />}
      <div className="big-container container">
        <div className="page-top-section">
          <div className="page-top-section-left">
            <div className="show-route">
              <InPageNav pageNav={pageNav} />
            </div>
          </div>
          <div className="page-top-section-right">
            <div
              className="top-menu-items"
              onClick={() => navigate("/my-booking")}
            >
              <img src={calendar} alt="calendar" />
            </div>
          </div>
        </div>
        <div className="club-detail-main">
          <Row className="club-detail-main-inner-wrapper">
            <Col xl={7}>
              <div className="club-detail-left-main">
                <div className="club-detail-img">
                  {ClubDetail?.profile_pic !== null &&
                  ClubDetail?.profile_pic !== "" &&
                  ClubDetail?.profile_pic !== undefined ? (
                    <img
                      src={ClubDetail?.profile_pic}
                      alt="club-detail-image"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = imagePlaceholder1;
                      }}
                    />
                  ) : (
                    <img src={imagePlaceholder1} alt="club-detail-image" />
                  )}
                </div>

                <div className="club-detail-content-wrapper">
                  <h3>{ClubDetail?.club_name}</h3>
                  <div
                    onClick={() => fnStarRate(ClubDetail)}
                    className="d-flex align-items-center justify-content-between mt-2"
                  >
                    <h4>Club Ratings</h4>

                    {ClubDetail !== "" &&
                      ClubDetail?.avg_rating !== null &&
                      ClubDetail?.avg_rating !== undefined &&
                      ClubDetail?.avg_rating !== "" && (
                        <span
                          // onClick={() => fnStarRate(ClubDetail)}
                          className="club-detail-stars"
                        >
                          {[...Array(5)].map((_, index) => (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="20"
                              viewBox="0 0 22 20"
                              fill={
                                index < ClubDetail.avg_rating
                                  ? "#FFB500"
                                  : "#BBBBBB"
                              }
                            >
                              <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                            </svg>
                          ))}
                        </span>
                      )}
                  </div>

                  <div
                    onClick={() =>
                      fnFavourite(
                        ClubDetail,
                        ClubDetail.is_favorite === 1 ? 0 : 1
                      )
                    }
                    className={
                      ClubDetail?.is_favorite === 1
                        ? "add-wishlist active"
                        : "add-wishlist"
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M14.1459 9.39301L8.99886 14.4907L3.85181 9.39301C3.51231 9.06265 3.2449 8.66557 3.0664 8.22678C2.8879 7.78799 2.80219 7.317 2.81467 6.84346C2.82714 6.36992 2.93753 5.90409 3.13888 5.4753C3.34023 5.04652 3.62817 4.66407 3.98459 4.35204C4.34101 4.04001 4.75817 3.80515 5.20981 3.66227C5.66146 3.51938 6.13779 3.47155 6.60882 3.5218C7.07986 3.57205 7.53538 3.71928 7.94672 3.95423C8.35806 4.18917 8.71629 4.50674 8.99886 4.88694C9.28266 4.5095 9.64131 4.19471 10.0524 3.96225C10.4634 3.7298 10.918 3.5847 11.3877 3.53602C11.8575 3.48734 12.3322 3.53614 12.7821 3.67936C13.2321 3.82258 13.6477 4.05714 14.0029 4.36836C14.358 4.67958 14.6451 5.06076 14.8462 5.48804C15.0473 5.91532 15.158 6.3795 15.1714 6.85154C15.1849 7.32358 15.1007 7.7933 14.9243 8.23132C14.7478 8.66934 14.4828 9.06622 14.1459 9.39713"
                        stroke="white"
                        stroke-width="1.2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="available-courts-detail-main">
                  <h6>{ClubDetail?.total_courts} Courts available</h6>
                  {ClubDetail !== "" &&
                    ClubDetail?.club_services !== null &&
                    ClubDetail?.club_services !== null &&
                    ClubDetail?.club_services !== "" && (
                      <div className="available-courts-amanities">
                        {ClubDetail?.club_services.length > 0 &&
                          ClubDetail?.club_services.map((item, index) => (
                            <div
                              // className="single-amanity"
                              className={`${ClubIndex === 1 && "1 orange"} ${
                                ClubIndex === 2 && "2 red"
                              } ${ClubIndex === 3 && "3 blue"}  ${
                                ClubIndex > 2
                                  ? (ClubIndex = 1)
                                  : (ClubIndex = ClubIndex + 1)
                              }`}
                              key={index}
                            >
                              <div className="single-amanity-img">
                                <img
                                  src={item.service_image}
                                  alt={item.service_name}
                                />
                              </div>
                              <p>{item.service_name}</p>
                            </div>
                          ))}
                      </div>
                    )}
                </div>
                <div className="approx-people-on-court">
                  <img src={group} alt="group" />
                  <p>
                    Approx 0-{ClubDetail?.approx_people_count} peoples on court
                  </p>
                </div>
                {ClubDetail?.club_opening_closing_time?.length > 0 && (
                  <div className="club-detail-opening-hours">
                    <h6>Opening Hours</h6>
                    {ClubDetail !== "" &&
                      ClubDetail?.club_opening_closing_time !== null &&
                      ClubDetail?.club_opening_closing_time !== undefined &&
                      ClubDetail?.club_opening_closing_time !== "" && (
                        <div className="club-detail-days-and-time">
                          {ClubDetail?.club_opening_closing_time.length > 0 &&
                            ClubDetail?.club_opening_closing_time.map(
                              (item, index) => (
                                <div className="club-detail-days-and-time-single">
                                  <p>{item.day_term}</p>
                                  <span>
                                    {item.start_time?.slice(0, -3)} -{" "}
                                    {item.end_time?.slice(0, -3)}
                                  </span>
                                </div>
                              )
                            )}
                        </div>
                      )}
                  </div>
                )}
                <div className="club-detail-map-section">
                  <div className="club-detail-map-top-wrapper">
                    {ClubDetail?.latitude !== "" &&
                      ClubDetail?.longitude !== "" &&
                      ClubDetail?.latitude !== undefined &&
                      ClubDetail?.latitude !== null &&
                      ClubDetail?.longitude !== undefined &&
                      ClubDetail?.longitude !== null && (
                        <a
                          className="d-flex align-items-center"
                          target="_blank"
                          href={`https://www.google.co.in/maps/dir/?saddr=&daddr=${ClubDetail?.latitude},${ClubDetail?.longitude}&directionsmode=driving`}
                        >
                          <div className="club-detail-map-top-image me-2">
                            <img src={locationIcon} alt="location" />
                          </div>
                          <p>Directions</p>
                        </a>
                      )}
                    {ClubDetail?.website !== "" &&
                      ClubDetail?.website !== undefined &&
                      ClubDetail?.website !== null && (
                        <a
                          href={"https://www." + ClubDetail?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="club-detail-map-top"
                        >
                          <div className="club-detail-map-top-image">
                            <img src={webIcon} alt="webIcon" />
                          </div>
                          <p>Web</p>
                        </a>
                      )}
                    {ClubDetail?.phone_no !== "" &&
                      ClubDetail?.phone_no !== undefined &&
                      ClubDetail?.phone_no !== null && (
                        <a
                          href={"tel:+" + ClubDetail?.phone_no}
                          className="club-detail-map-top"
                        >
                          <div className="club-detail-map-top-image">
                            <img src={callIcon} alt="call" />
                          </div>
                          <p>Call</p>
                        </a>
                      )}
                  </div>

                  <div className="club-detail-map-bottom">
                    <Map data={ClubDetail} />
                  </div>
                </div>
                {ClubDetail !== "" &&
                  ClubDetail?.recent_results !== null &&
                  ClubDetail?.recent_results !== null &&
                  ClubDetail?.recent_results !== "" && (
                    <div className="club-detail-recent-result-main mb-4">
                      <h3>Recent Results</h3>
                      <div className="all-results-main all-results-main-in-club-detail-page">
                        {ClubDetail?.recent_results.length > 0 &&
                          ClubDetail?.recent_results.map((item, index) => (
                            <div className="recent-result-wrapper-main w-100 width300">
                              <div className="recent-result-wrapper">
                                {item?.score.length > 0 &&
                                  item.score.map((s_item, s_index) => (
                                    <div className="recent-result-single-team">
                                      <p className="team-name">
                                        {s_item.team_name}
                                      </p>
                                      <div className="two-players-section">
                                        {s_item?.players.length > 0 &&
                                          s_item.players.map(
                                            (p_item, p_index) => (
                                              <div className="single-players-section">
                                                {p_item.player_profile !==
                                                  null &&
                                                p_item.player_profile !== "" &&
                                                p_item.player_profile !==
                                                  undefined ? (
                                                  <img
                                                    src={p_item.player_profile}
                                                    alt="club-detail-image"
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null;
                                                      currentTarget.src =
                                                        imagePlaceholder1;
                                                    }}
                                                  />
                                                ) : (
                                                  <img
                                                    src={imagePlaceholder1}
                                                    alt="club-detail-image"
                                                  />
                                                )}
                                                <h6>{p_item.player_name}</h6>
                                                <p>
                                                  {p_item?.avg_rating === 0
                                                    ? "0.0"
                                                    : p_item?.avg_rating?.toFixed(
                                                        1
                                                      )}
                                                </p>
                                              </div>
                                            )
                                          )}
                                      </div>
                                      <div className="recent-result-scores-wrapper">
                                        {s_item?.score?.map(
                                          (ss_item, ss_index) => (
                                            <h6
                                              className="recent-result-scores"
                                              key={ss_index}
                                            >
                                              {ss_item}
                                            </h6>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              {item.datetime !== null &&
                                item.datetime !== "" && (
                                  <div className="recent-result-date-and-time">
                                    <p>
                                      June 26{" "}
                                      {moment(item.datetime).format("MMM DD")} |
                                      09:00 - 10:00
                                    </p>
                                  </div>
                                )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
              </div>
            </Col>

            <Col xl={5} className="club-detail-right-fixed-width">
              <div className="club-detail-right-main">
                {ClubDetail?.is_bookable !== 0 && (
                  <div className="book-court-section">
                    <h5>Book court</h5>
                    {ClubDetail?.no_of_club_packages > 0 && (
                      <div
                        onClick={() => fnClubSubscribeModal(ClubDetail)}
                        className="top-subcribe-modal-opener"
                      >
                        <div className="top-subcribe-modal-opener-img">
                          <img src={crown} alt="crown" />
                        </div>
                        <div className="top-subcribe-modal-opener-content">
                          <h6>Club Membership Plan</h6>
                          {ClubDetail?.no_of_active_subscription > 0 && (
                            <p>
                              you have {ClubDetail?.no_of_active_subscription}{" "}
                              membership
                            </p>
                          )}
                          {ClubDetail?.no_of_club_packages > 0 && (
                            <p>
                              This club provide{" "}
                              {ClubDetail?.no_of_club_packages} membership plan
                            </p>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="book-court-calendar">
                      {BookingDates?.length > 0 ? (
                        <>
                          {/* {LoginInfo?.is_subscriber !== 1 && (
                            <div
                              onClick={() => setSubscribeModalShow(true)}
                              className="top-subcribe-modal-opener"
                            >
                              <div className="top-subcribe-modal-opener-img">
                                <img src={crown} alt="crown" />
                              </div>
                              <div className="top-subcribe-modal-opener-content">
                                <h6>Rate Benefits</h6>
                                <p>
                                  Subscribe our Plan to get{" "}
                                  <span>10% benefits</span> on rates of Clubs
                                  and Coaches{" "}
                                </p>
                              </div>
                            </div>
                          )} */}
                          <div className="book-court-month-arrows">
                            <img src={previous} alt="prev" />
                            <div className="text-center">
                              <h4>
                                {moment(new Date(StartDate)).format(
                                  "MMMM yyyy"
                                )}
                              </h4>
                              <span>{NewTimeZOnw}</span>
                            </div>
                            <img src={next} alt="next" />
                          </div>

                          <div className="book-court-calendar-day-date-wrapper">
                            {BookingDates.map((b_item, b_index) => (
                              <div className="book-court-calendar-day-date">
                                <span
                                  onClick={() => {
                                    setDropInSlotShow(false);
                                    SetCourtOnlyView(false);
                                    getSlotbyDate(b_item.date);
                                  }}
                                  className={
                                    commonservices.getDateInFormatCustom(
                                      b_item.date,
                                      NewTimeZOnw,
                                      "DD-MM-yyyy"
                                    ) ===
                                    commonservices.getDateInFormatCustom(
                                      ActiveClass,
                                      NewTimeZOnw,
                                      "DD-MM-yyyy"
                                    )
                                      ? //
                                        // moment(
                                        //   commonservices.NewUTCFormat(b_item.date)
                                        // ).format("DD-MM-yyyy") ===
                                        // moment(
                                        //   commonservices.NewUTCFormat(ActiveClass)
                                        // ).format("DD-MM-yyyy")
                                        "date-number active"
                                      : "date-number"
                                  }
                                >
                                  {commonservices.getDateInFormatCustom(
                                    b_item.date,
                                    NewTimeZOnw,
                                    "DD"
                                  )}
                                </span>
                                <span className="date-day">
                                  {moment(
                                    commonservices.NewUTCFormat(b_item.date)
                                  ).format("ddd")}
                                  {/* moment(utcDateString).format('D') */}
                                </span>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : (
                        <>
                          <h5 className="text-center pt-5">
                            Slot not available
                          </h5>
                        </>
                      )}
                    </div>

                    {/* {Loading ? (
                    <>
                      <Loader type={LoaderType.AVAILABLE_SLOT} count={6} />
                    </>
                  ) : ( */}
                    <div className="show-available-slots-section-wrapper">
                      {/* <div className="legends-of-available-slots-content"> */}
                      {SlotbyDate?.length > 0 && (
                        <>
                          <>
                            <div className="show-available-slots-section">
                              <div className="d-flex align-items-center gap-2">
                                <h6>Show Available Sessions</h6>
                              </div>
                              <div className="pickler-switches">
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label=""
                                  checked={checkBox}
                                  onChange={fnAvailableSlots}
                                />
                              </div>
                            </div>
                            <div className="legends-of-available-slots">
                              <div className="legends-of-available-slots-single">
                                <div style={{ background: "white" }}></div>
                                <p>Normal Slots</p>
                              </div>
                              <div className="legends-of-available-slots-single">
                                <div></div>
                                <p>Open Play Slots</p>
                              </div>
                            </div>
                          </>
                          <div className="show-available-slots-section-wrapper">
                            <div className="">
                              {SlotLoading ? (
                                <Loader
                                  type={LoaderType.AVAILABLE_SLOT}
                                  count={6}
                                />
                              ) : (
                                <>
                                  {SlotbyDate?.filter(
                                    (s1) => s1?.slot_type === "booking"
                                  ).map((s1, index) => (
                                    <div className="">
                                      {index === 0 && (
                                        <p key={index}>
                                          <strong>Court</strong>
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                  <div className="legends-of-available-slots-content">
                                    {SlotbyDate.map(
                                      (sl_item, sl_index) =>
                                        sl_item.slot_type !== "drop_in" && (
                                          <>
                                            <div
                                              className={`legends-of-available-slots-inner-para ${
                                                sl_item.active ? "active" : ""
                                              }`}
                                              key={sl_index}
                                              onClick={() => {
                                                setDropInSlotShow(false);
                                                Get_Count_by_date(sl_item);
                                                if (
                                                  sl_item?.allow_non_club_member !==
                                                    1 &&
                                                  ClubDetail?.is_club_subscribed !==
                                                    1
                                                ) {
                                                  toast.error(
                                                    "Only club members can book this session. You can buy our membership to reserve this session."
                                                  );
                                                } else {
                                                  SetCourtOnlyView(true);
                                                  SlotbyDate.forEach(
                                                    (item) =>
                                                      (item.active = false)
                                                  );
                                                  sl_item.active = true;
                                                  fngetCourtsbydate(sl_item);
                                                }
                                              }}
                                            >
                                              <p>
                                                {commonservices.getTimeInFormat(
                                                  sl_item.start_time,
                                                  NewTimeZOnw
                                                )}{" "}
                                                -{" "}
                                                {commonservices.getTimeInFormat(
                                                  sl_item.end_time,
                                                  NewTimeZOnw
                                                )}
                                              </p>
                                            </div>
                                          </>
                                        )
                                    )}
                                  </div>
                                  {SlotbyDate?.filter(
                                    (s1) => s1?.slot_type === "drop_in"
                                  ).map((s1, index) => (
                                    <div className="mt-3 mb-4">
                                      {index === 0 && (
                                        <p key={index}>
                                          <strong>Open Play</strong>
                                        </p>
                                      )}
                                    </div>
                                  ))}
                                  {/* <div>Open Play</div> */}
                                  <div className="legends-of-available-slots-content">
                                    {SlotbyDate.map(
                                      (sl_item, sl_index) =>
                                        sl_item.slot_type === "drop_in" && (
                                          <>
                                            <div
                                              onClick={() => {
                                                Get_Count_by_date(sl_item);
                                                if (
                                                  sl_item?.allow_non_club_member !==
                                                    1 &&
                                                  ClubDetail?.is_club_subscribed !==
                                                    1
                                                ) {
                                                  toast.error(
                                                    "Only club members can book this session. You can buy our membership to reserve this session."
                                                  );
                                                } else {
                                                  SetCourtOnlyView(false);
                                                  SetDropinCourtOnlyView(true);
                                                  SlotbyDate.forEach(
                                                    (item) =>
                                                      (item.active = false)
                                                  );
                                                  sl_item.active = true;
                                                  fngetDropinParticipants(
                                                    sl_item
                                                  );
                                                }
                                              }}
                                              className={`legends-of-available-slots-inner-para ${
                                                sl_item.active ? "active" : ""
                                              }`}
                                            >
                                              <p
                                                style={{
                                                  background: "#ffeced",
                                                }}
                                              >
                                                {commonservices.getTimeInFormat(
                                                  sl_item.start_time,
                                                  NewTimeZOnw
                                                )}{" "}
                                                -{" "}
                                                {commonservices.getTimeInFormat(
                                                  sl_item.end_time,
                                                  NewTimeZOnw
                                                )}
                                                <div>
                                                  {ClubDetail?.is_subscriber ===
                                                  1 ? (
                                                    <b>
                                                      $
                                                      {sl_item.discount_price?.toFixed(
                                                        2
                                                      )}
                                                      /30m
                                                    </b>
                                                  ) : (
                                                    <b>
                                                      $
                                                      {sl_item.original_price?.toFixed(
                                                        2
                                                      )}
                                                      /30m
                                                    </b>
                                                  )}
                                                </div>
                                              </p>
                                              <div className="slots-out-of-all">
                                                <img src={group} alt="group" />
                                                <p>
                                                  {sl_item.booked_max_count}
                                                </p>
                                              </div>
                                            </div>
                                          </>
                                        )
                                    )}
                                  </div>
                                  {/* //   );
                                  // })} */}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {CourtOnlyView && (
                        <>
                          <div className="show-available-courts-only-section">
                            {CourtOnlyView && (
                              <div className="show-available-courts-only-section-header">
                                <div>
                                  <h4>Show Available Courts Only</h4>
                                  {GetCountForCourt > 0 && (
                                    <strong>
                                      {GetCountForCourt} free session(s)
                                    </strong>
                                  )}
                                </div>
                                <div className="pickler-switches">
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    label=""
                                    checked={checkBox1}
                                    onChange={fnAvailableCours}
                                  />
                                </div>
                              </div>
                            )}

                            <div className="show-available-courts-only-content">
                              {AvailableCoursLoading ? (
                                <Loader
                                  type={LoaderType.InviteFriend}
                                  count={4}
                                />
                              ) : (
                                <>
                                  {AvailableCours?.map((item, index) => (
                                    <div
                                      className={
                                        item.is_available_for_booking === 0
                                          ? "gray show-available-courts-only-content-single"
                                          : "show-available-courts-only-content-single"
                                      }
                                    >
                                      <div>
                                        <h6>{item?.court_title}</h6>
                                        <span>{item?.game_type_term}</span>
                                      </div>
                                      <div>
                                        {item.is_available_for_booking === 0 ? (
                                          <button
                                            className="red-btn red-border"
                                            // onClick={() => fnAddtoCartModal(item)}
                                          >
                                            $
                                            {item?.standard_price === 0
                                              ? "0.00"
                                              : item?.standard_price.toFixed(2)}
                                          </button>
                                        ) : (
                                          <button
                                            className="red-btn red-border"
                                            onClick={() =>
                                              fnAddtoCartModal(item)
                                            }
                                          >
                                            $
                                            {item?.standard_price === 0
                                              ? "0.00"
                                              : item?.standard_price.toFixed(2)}
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      {DropInSlotShow && (
                        <>
                          <div className="show-available-courts-only-section">
                            <div className="show-available-courts-only-section-header">
                              <div>
                                <h4>
                                  Participants(
                                  {AvailableSlot.data?.total_booked_people}/
                                  {AvailableSlot.data?.total_maximum_people})
                                </h4>
                                <strong>
                                  {GetCountOpenPlay?.hour > 0 &&
                                    GetCountOpenPlay?.hour + "h"}{" "}
                                  {GetCountOpenPlay?.min > 0 &&
                                    GetCountOpenPlay?.min + "Min"}{" "}
                                  free session(s)
                                </strong>
                              </div>
                              <div className="see-all-participants">
                                <p>see all</p>
                                <img src={next} alt="next" />
                              </div>
                            </div>
                            {AvailableSlotLoaing ? (
                              <Loader
                                type={LoaderType.InviteFriend}
                                count={4}
                              />
                            ) : (
                              <div className="show-available-courts-only-content">
                                <div className="dark-badge-item-wrapper">
                                  {AvailableSlot.data?.sportsmenship_term_count
                                    ?.length > 0 &&
                                    AvailableSlot.data?.sportsmenship_term_count.map(
                                      (item, index) =>
                                        item.value > 0 && (
                                          <div className="dark-badge-item">
                                            {item?.value} {item?.title}
                                          </div>
                                        )
                                    )}
                                </div>

                                <div className="players">
                                  {AvailableSlot.data?.players?.length > 0 &&
                                    AvailableSlot.data?.players.map(
                                      (item, index) => (
                                        <>
                                          <div className="players-inner-wrapper ">
                                            <div className="activity-section-bottom-single-bottom-image">
                                              {item?.profile_pic !== null &&
                                              item?.profile_pic !== "" &&
                                              item?.profile_pic !==
                                                undefined ? (
                                                <img
                                                  src={item?.profile_pic}
                                                  alt="club-detail-image"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      imagePlaceholder1;
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={imagePlaceholder1}
                                                  alt="club-detail-image"
                                                />
                                              )}
                                              {/* {item?.value} {item?.title} */}
                                            </div>
                                            <div className="players-inner-wrapper-content">
                                              <h6>{item?.member_name}</h6>
                                              <h6>
                                                {item?.avg_rating !== null
                                                  ? item?.avg_rating.toFixed(1)
                                                  : "0.0"}
                                              </h6>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    )}
                                </div>
                                {AvailableSlot?.is_available_for_booking ===
                                1 ? (
                                  <>
                                    <div className="join-btn-and-price">
                                      <Button className="join-btn-and-price-price white-btn">
                                        $
                                        {AvailableSlot?.price === 0
                                          ? "0.0"
                                          : AvailableSlot?.price?.toFixed(2)}
                                        /30m
                                      </Button>
                                      <Button
                                        // onClick={() =>
                                        //   fnNotifydropinbooking(AvailableSlot)
                                        // }
                                        onClick={() => {
                                          setOpenPlayModalShow(true);
                                          // fnAddtoJoin(AvailableSlot)
                                        }}
                                        className="red-btn red-border"
                                      >
                                        Join
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="join-btn-and-price">
                                      <div className="join-btn-and-price-price">
                                        ${AvailableSlot?.price.toFixed(2)}
                                      </div>
                                      <Button
                                        onClick={() =>
                                          fnNotifydropinbooking(AvailableSlot)
                                        }
                                        className="red-btn red-border"
                                      >
                                        Notify
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {/* )} */}
                  </div>
                )}
                {ClubDetail?.is_activity !== 0 && (
                  <div className="club-detail-activity-section">
                    <h5 className="m-0">Activity</h5>
                    <div className="club-detail-activity-top">
                      <div className="club-detail-activity-add-buttons">
                        <button
                          className="d-flex align-items-center gap-2"
                          onClick={() => fnClubActivity("Interest")}
                        >
                          <img src={addIcon} alt="addIcon" />
                          <span>Add Interest</span>
                        </button>
                        <button
                          className="d-flex align-items-center gap-2"
                          onClick={() => fnClubActivity("People")}
                        >
                          <img src={addIcon} alt="addIcon" />
                          <span>Add no.of peoples</span>
                        </button>
                        <button
                          className="d-flex align-items-center gap-2"
                          onClick={() => fnClubActivity("Status")}
                        >
                          <img src={statusIcon} alt="addIcon" />
                          <span>Add Court Status</span>
                        </button>
                      </div>
                    </div>
                    <div
                      className="activity-section-bottom"
                      id="ActivityListID"
                    >
                      {isLoading && !ActivityList?.length > 0 ? (
                        <Loader type={LoaderType.InviteFriend} count={3} />
                      ) : (
                        <>
                          {ActivityList?.length > 0 ? (
                            ActivityList.map((a_item, a_index) => (
                              <>
                                {a_item?.activity_type_term ===
                                  "add_people_activity" && (
                                  <div className="activity-section-bottom-single FindClubCard-main">
                                    <div className="activity-section-bottom-single-bottom">
                                      <div className="activity-section-bottom-single-bottom-image">
                                        {a_item?.posted_by_profile !== null &&
                                        a_item?.posted_by_profile !== "" &&
                                        a_item?.posted_by_profile !==
                                          undefined ? (
                                          <img
                                            src={a_item?.posted_by_profile}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder1;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder1}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                      <div className="">
                                        {/* <p>{a_item?.interest_details?.comment}</p> */}
                                        <p>
                                          {a_item?.title !== "" &&
                                          a_item?.title !== undefined &&
                                          a_item?.title !== null ? (
                                            <>{a_item?.title}</>
                                          ) : (
                                            <>
                                              Added to interest by{" "}
                                              {
                                                a_item?.interest_details
                                                  ?.member_name
                                              }{" "}
                                              for{" "}
                                              {commonservices.getDayDateMonthTime(
                                                a_item?.created_at
                                              )}
                                            </>
                                          )}
                                        </p>
                                        <span>
                                          {commonservices.getDayDateMonthTime(
                                            a_item?.created_at
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {a_item?.activity_type_term ===
                                  "add_interest_activity" && (
                                  <div className="activity-section-bottom-single FindClubCard-main">
                                    <div className="activity-section-bottom-single-bottom">
                                      <div className="activity-section-bottom-single-bottom-image">
                                        {a_item?.posted_by_profile !== null &&
                                        a_item?.posted_by_profile !== "" &&
                                        a_item?.posted_by_profile !==
                                          undefined ? (
                                          <img
                                            src={a_item?.posted_by_profile}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder1;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder1}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                      <div className="">
                                        {/* <p>{a_item?.interest_details?.comment}</p> */}
                                        <p>
                                          {a_item?.title !== "" &&
                                          a_item?.title !== undefined &&
                                          a_item?.title !== null ? (
                                            <>{a_item?.title}</>
                                          ) : (
                                            <>
                                              Added to interest by{" "}
                                              {
                                                a_item?.interest_details
                                                  ?.member_name
                                              }{" "}
                                              for{" "}
                                              {commonservices.getDayDateMonthTime(
                                                a_item?.created_at
                                              )}
                                            </>
                                          )}
                                        </p>
                                        <span>
                                          {commonservices.getDayDateMonthTime(
                                            a_item?.created_at
                                          )}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="activity-section-bottom-diff-img">
                                      {a_item?.club_profile_pic !== null &&
                                      a_item?.club_profile_pic !== "" &&
                                      a_item?.club_profile_pic !== undefined ? (
                                        <img
                                          src={a_item?.club_profile_pic}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src =
                                              imagePlaceholder1;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder1}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </div>
                                    <p>{a_item?.interest_details?.comment}</p>
                                  </div>
                                )}
                                {a_item?.activity_type_term ===
                                  "club_booking_activity" && (
                                  <div
                                    className="activity-section-bottom-single FindClubCard-main"
                                    style={{ position: "relative" }}
                                  >
                                    {a_item?.order_item_status ===
                                    "completed" ? (
                                      <div
                                        class="booking-status-badge"
                                        style={{ background: "#e0e0e0" }}
                                      >
                                        <p style={{ color: "#000" }}>
                                          Completed
                                        </p>
                                      </div>
                                    ) : (
                                      <div className="activity-section-bottom-single-top">
                                        <p>
                                          {commonservices.getDayDateMonth(
                                            a_item?.booking_details?.start_time
                                          )}{" "}
                                          |{" "}
                                          {commonservices.getTimeInFormat(
                                            a_item?.booking_details?.start_time
                                          )}{" "}
                                          -{" "}
                                          {commonservices.getTimeInFormat(
                                            a_item?.booking_details?.end_time
                                          )}
                                        </p>
                                        <span>
                                          {fnHour(
                                            a_item?.booking_details?.duration
                                          )}
                                        </span>
                                      </div>
                                    )}
                                    <div className="activity-section-bottom-single-bottom">
                                      <div className="activity-section-bottom-single-bottom-image">
                                        {a_item?.booking_details?.club_image !==
                                          null &&
                                        a_item?.booking_details?.club_image !==
                                          "" &&
                                        a_item?.booking_details?.club_image !==
                                          undefined ? (
                                          <img
                                            src={
                                              a_item?.booking_details
                                                ?.club_image
                                            }
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder1;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder1}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>

                                      <div className="">
                                        <p>
                                          {a_item?.booking_details?.court_title}
                                        </p>
                                        <h6>
                                          {a_item?.booking_details?.club_name}
                                        </h6>
                                        <span>
                                          {
                                            a_item?.booking_details
                                              ?.game_type_term
                                          }
                                        </span>
                                        {a_item?.order_item_status ===
                                          "completed" && (
                                          <div className="booking-date-and-time">
                                            <div className="booking-date">
                                              <img
                                                src={calendarsmall}
                                                alt="calendarsmall"
                                              />
                                              <p>
                                                {commonservices.getDateInFormat(
                                                  a_item?.booking_details
                                                    ?.start_time
                                                )}
                                              </p>
                                            </div>
                                            <div className="booking-date">
                                              <img src={clock} alt="clock" />
                                              <p>
                                                {" "}
                                                {commonservices.getTimeInFormat(
                                                  a_item?.booking_details
                                                    ?.start_time
                                                )}{" "}
                                                -{" "}
                                                {commonservices.getTimeInFormat(
                                                  a_item?.booking_details
                                                    ?.end_time
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                    {a_item?.order_item_status ===
                                      "completed" && (
                                      <div className="activity-score-points-wrapper">
                                        <p>
                                          Score :{" "}
                                          {
                                            a_item?.booking_details
                                              ?.score_points
                                          }{" "}
                                          points
                                        </p>

                                        <div className="activity-score-points-stars">
                                          {[...Array(5)].map((_, index) => (
                                            <svg
                                              key={index}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="22"
                                              height="20"
                                              viewBox="0 0 22 20"
                                              fill={
                                                index <
                                                a_item?.booking_details
                                                  ?.avg_ratings
                                                  ? "#FFB500"
                                                  : "#BBBBBB"
                                              }
                                            >
                                              <path d="M10.5281 0.251581C10.7569 -0.0838604 11.2431 -0.0838604 11.4719 0.251581L15.0056 5.43446C15.0849 5.55185 15.2039 5.63519 15.3395 5.66924L21.5625 7.23757C21.981 7.34263 22.1407 7.86267 21.8574 8.19371L17.8363 12.8828C17.7369 12.9985 17.6857 13.1499 17.6949 13.3037L18.0438 19.378C18.0679 19.7983 17.6639 20.107 17.2759 19.965L11.1943 17.7375C11.0684 17.6917 10.9316 17.6917 10.8057 17.7375L4.72409 19.965C4.33612 20.107 3.93212 19.7983 3.95626 19.378L4.3052 13.3037C4.31405 13.1499 4.26324 12.9985 4.16381 12.8828L0.142777 8.19371C-0.140986 7.86267 0.0193185 7.34263 0.437463 7.23757L6.66066 5.66924C6.79619 5.63519 6.91488 5.55185 6.99443 5.43446L10.5281 0.251581Z" />
                                            </svg>
                                          ))}

                                          {/* <p>
                                      {a_item?.booking_details?.avg_ratings?.toFixed(
                                        1
                                      )}
                                    </p> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                                {a_item?.activity_type_term ===
                                  "club_status_activity" && (
                                  <div className="activity-section-bottom-single FindClubCard-main">
                                    <div className="activity-section-bottom-single-bottom">
                                      <div className="activity-section-bottom-single-bottom-image">
                                        {a_item?.posted_by_profile !== null &&
                                        a_item?.posted_by_profile !== "" &&
                                        a_item?.posted_by_profile !==
                                          undefined ? (
                                          <img
                                            src={a_item?.posted_by_profile}
                                            alt="club-detail-image"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                imagePlaceholder1;
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={imagePlaceholder1}
                                            alt="club-detail-image"
                                          />
                                        )}
                                      </div>
                                      <div className="width100inresponsive">
                                        <div>{a_item?.title}</div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <span>
                                            {commonservices.getDayDateMonthTime(
                                              a_item?.created_at
                                            )}
                                          </span>
                                          {a_item?.details?.club_status ===
                                          "Open" ? (
                                            <span
                                              className="green-badge"
                                              style={{ color: "#ffffff" }}
                                            >
                                              {a_item?.details?.club_status}
                                            </span>
                                          ) : a_item?.details?.club_status ===
                                            "Closed" ? (
                                            <span className="red-badge">
                                              {a_item?.details?.club_status}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))
                          ) : (
                            <div className="text-center d-flex align-items-center justify-content-center gap-1">
                              <img src={information} alt="information" />{" "}
                              <p>No data found</p>
                            </div>
                          )}
                        </>
                      )}
                      {isLoading && ActivityList?.length > 0 && (
                        <Loader type={LoaderType.InviteFriend} count={3} />
                      )}

                      {!RemoveMoreButtion && (
                        <div className="text-center w-100 mb-3 view-more-btn">
                          <button
                            className="red-btn red-border width150"
                            onClick={handleLoadMore}
                            disabled={isLoading}
                          >
                            {isLoading ? "Loading..." : "View More"}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {SubscribeModalShow && (
        <SubsciptionModal
          show={SubscribeModalShow}
          // SubscribeModalValue={""}
          onHide={() => setSubscribeModalShow(false)}
        />
      )}
      {ActivityModalShow && (
        <ClubActivityModal
          show={ActivityModalShow}
          ClubId={param.id}
          ClubDetail={ClubDetail}
          ActivityType={ActivityType}
          bindList={getActivityList}
          bindList2={getClubDeatils}
          onHide={() => setActivityModalShow(false)}
        />
      )}
      {StarRateModalShow && (
        <StarRateModal
          show={StarRateModalShow}
          Type="BookingRate"
          ClubDetail={ClubDetail}
          onHide={() => setStarRateModalShow(false)}
        />
      )}
      {OpenPlayModalShow && (
        <OpenPlayModal
          GetCountOpenPlay={GetCountOpenPlay}
          show={OpenPlayModalShow}
          AvailableSlot={AvailableSlot}
          Type="BookingFromClub"
          NewTimeZOnw={NewTimeZOnw}
          AvailableCartData={AvailableCartData}
          ClubDetail={ClubDetail}
          onHide={() => setOpenPlayModalShow(false)}
        />
      )}
      {AddToCartModalShow && (
        <AddToCartModal
          NewTimeZone={NewTimeZOnw}
          bindList={getSlotbyDate}
          show={AddToCartModalShow}
          Type="BookingFromClub"
          AvailableCartData={AvailableCartData}
          ClubDetail={ClubDetail}
          onHide={() => setAddToCartModalShow(false)}
        />
      )}
      {ClubSubscribeModalShow && (
        <ClubSubscriptionModal
          show={ClubSubscribeModalShow}
          ClubSubscribeModalValue={ClubSubscribeModalValue}
          onHide={() => setClubSubscribeModalShow(false)}
        />
      )}
    </div>
  );
};

export default ClubDetails;
