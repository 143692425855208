import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader, { LoaderType } from "../Loader";
import standardMemberlogo from "../../images/standard_member.svg";
import walletIcon from "../../images/walletIcon.svg";
import logo from "../../images/onlylogo.svg";
import yellowLogo from "../../images/yellowLogo.png";
import imagePlaceholder from "../../images/placeholder.png";
import moment from "moment";
import { Button, Form } from "react-bootstrap";
import { apiCall } from "../../services/AxiosService";
import { API_URL } from "../../services/APIRoute";
import { apiResponse } from "../../services/APIResponse";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import SubsciptionModal from "../../Modal/SubscriptionModal";
import MembershipBuyModal from "../../Modal/MembershipBuyModal";
import { EditUserInfo } from "../../Redux/Action";

const SubscriptionDetailComponent = () => {
  let dispatch = useDispatch();
  const LoginInfo = useSelector((state) => state.userinfo.UserInfo);
  const [MembershipLoading, setMembershipLoading] = useState(false);
  const [SubscriptionList, setSubscriptionList] = useState([]);
  const [SubscriptionLoader, setSubscriptionLoader] = useState(false);
  const [MembershipDetail, setMembershipDetail] = useState("");
  const [MembershipData, setMembershipData] = useState([]);
  const [Subscriptiondata, setSubscriptiondata] = useState("");
  const [NewSubscribeLoader, setNewSubscribeLoader] = useState(false);
  const [SubscribeModalShow, setSubscribeModalShow] = useState(false);
  const [SubscribeModalValue, setSubscribeModalValue] = useState();
  const [Membershipmodalshow, setMembershipmodalshow] = useState(false);
  const [SubscribeType, setSubscribeType] = useState("");
  function fnSubscribeModal(item) {
    setSubscribeModalShow(true);
    setSubscribeModalValue(item);
  }
  async function fnSubscribe(type) {
    if (Subscriptiondata !== "") {
      // setMembershipmodalshow(true);
      setNewSubscribeLoader(true);
      let body = {
        session_type: "app_member_level",
        cancel_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
        package_id: Subscriptiondata?.subscription_id,
        // package_price_id: Subscriptiondata?.package_price_id,
        success_url: `${process.env.REACT_APP_SUBSCRIBE_PAGE_URL}settings`,
      };
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.CLUB_SESSION_CREATE,
          body: body,
        },
        false
      );
      let response = apiResponse(true, resData, setNewSubscribeLoader);
      if (response?.isValidate) {
        if (
          response?.data?.data?.url !== undefined &&
          response?.data?.data?.url !== null
        ) {
          window.open(response.data.data.url, "_self");
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    }
    if (SubscribeType === "cancel") {
      setMembershipmodalshow(true);
    }
    setSubscribeType(type);
  }
  async function getSubscriptionList() {
    setSubscriptionLoader(true);
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_LIST,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setSubscriptionList(response.data.data.list);
        if (LoginInfo?.is_subscriber === 1) {
          let temp = "";
          response?.data?.data?.list?.forEach((element) => {
            temp = element?.prices?.filter(
              (f) => f.subscription_id === LoginInfo?.subscription_id
            );
          });

          // console.log('temptemp',temp);
          // let temp = response.data.data.list.find(
          //   (f) => f.subscription_id === LoginInfo?.subscription_id
          // );
          setMembershipData(temp);
        }
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    } finally {
      setSubscriptionLoader(false);
    }
  }
  async function getSubscriptionDetails() {
    setMembershipLoading(true);
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.SUBSCRIPTION_DETAILS,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        setMembershipLoading(false);
        setMembershipDetail(response?.data?.data);
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  async function getAuthProfile() {
    try {
      let resData = await apiCall(
        {
          method: "POST",
          url: API_URL.BASEURL + API_URL.GET_USER_PROFILE,
        },
        false
      );
      let response = apiResponse(false, resData);
      if (response?.isValidate) {
        // console.log("GET_USER_PROFILE", response?.data?.data?.user);
        let temp = [];
        temp.push({
          ...response?.data?.data?.user,
          association_id: response?.data?.data?.user?.member_id,
        });
        dispatch(EditUserInfo(temp[0]));
        // dispatch(EditUserInfo(response?.data?.data?.user));
      }
      if (!response?.isValidate) {
        console.log("Error getting country list", response);
      }
    } catch (error) {
      console.error("Error fetching club data:", error);
    }
  }
  useEffect(() => {
    getAuthProfile();
    getSubscriptionDetails();
    getSubscriptionList();
  }, []);

  return (
    <>
      <div className="settings-right-side-main">
        {LoginInfo?.is_subscriber === 1 ? (
          <>
            {MembershipLoading ? (
              <Loader type={LoaderType.HomePost} count={1} />
            ) : (
              <>
                {MembershipDetail?.subscriptions?.length > 0 &&
                  MembershipDetail?.subscriptions?.map((item_ss, index_ss) => (
                    <>
                      {item_ss?.payment_status === "active" ? (
                        <h4 className="mb-2 mt-3">Current Plan</h4>
                      ) : (
                        <h4 className="my-3">Upcoming Plan</h4>
                      )}
                      <div className="settings-has-memberships-main">
                        <div className="settings-wallet-header">
                          {/* <h5 className="text-center">
                                        Membership
                                      </h5> */}
                          <div
                            className="my-single-booking align-items-center"
                            style={{ background: "transparent" }}
                          >
                            <div className="court1Image">
                              <img
                                src={
                                  item_ss?.subscription_name === "Standard"
                                    ? standardMemberlogo
                                    : yellowLogo
                                }
                                alt="logo"
                              />
                            </div>
                            <div className="courtContent court-and-court-name">
                              <h5 className="border-0 m-0 p-0">
                                {item_ss.subscription_name} Membership
                              </h5>
                              {item_ss?.payment_status === "active" ? (
                                <p className="font-white">
                                  {/* Your Membership Expires on{" "} */}
                                  Next
                                  {item_ss?.subscription_status === "active" &&
                                    " renewal "}
                                  date is{" "}
                                  {moment(
                                    item_ss?.next_billing_invoice_date
                                  ).format("DD MMM, yyyy")}
                                </p>
                              ) : (
                                <p className="font-white">
                                  {/* Your Membership Expires on{" "} */}
                                  Your Next Membership is
                                  {item_ss?.subscription_status ===
                                    "schedule" && " schedule "}
                                  on{" "}
                                  {moment(item_ss?.end_date).format(
                                    "DD MMM, yyyy"
                                  )}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="settings-has-memberships-bottom">
                          <div className="membership-element-single-wrap-up">
                            {/* MembershipData */}

                            <div className="membership-element-single-diff-para">
                              <h5 className="mb-0">
                                Membership for{" "}
                                {item_ss?.interval_type === "year"
                                  ? "12 Month(s)"
                                  : item_ss?.interval_type === "day"
                                  ? `${item_ss?.validity_period + " Day(s)"}`
                                  : `${
                                      item_ss?.validity_period + " Month(s)"
                                    }`}{" "}
                                Plan
                              </h5>
                              <p>
                                ${item_ss.price}{" "}
                                <span>/{item_ss.interval_type}</span>
                              </p>
                            </div>
                            {item_ss.subscription_details !== null && (
                              <span
                                className="dark-badge"
                                dangerouslySetInnerHTML={{
                                  __html: item_ss.subscription_details,
                                }}
                              />
                            )}

                            {item_ss.benefits?.length > 0 ? (
                              item_ss.benefits.map((s_item, s_index) => (
                                <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                  <div className="plan-header-logo">
                                    {s_item?.term_icon !== null &&
                                    s_item?.term_icon !== "" &&
                                    s_item?.term_icon !== undefined ? (
                                      <img
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={s_item?.term_icon}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </div>
                                  <div className="plan-name">
                                    <h4>{s_item?.term_name}</h4>
                                    <p>{s_item?.term_details}</p>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <Loader type={LoaderType.NODATAFOUND} />
                            )}
                          </div>
                          {/* <div className="mt-3 d-flex     justify-content-between">
                                <Button
                                  className="red-btn red-border "
                                  onClick={() =>
                                    fnSubscribeModal(MembershipDetail)
                                  }
                                >
                                  Manage
                                </Button>
                                <Button
                                  className="red-btn red-border "
                                  onClick={() => fnSubscribe("cancel")}
                                >
                                  Cancel
                                </Button>
                              </div> */}
                        </div>
                      </div>
                    </>
                  ))}
              </>
            )}
            <div className="mt-3 d-flex     justify-content-between">
              <Button
                className="red-btn red-border "
                onClick={() => fnSubscribeModal(MembershipDetail)}
              >
                Manage
              </Button>
              <Button
                className="red-btn red-border "
                onClick={(e) => fnSubscribe("cancel")}
              >
                Cancel
              </Button>
            </div>
          </>
        ) : (
          <div className="">
            <div className="settings-memberships-main">
              <h5>Memberships</h5>
              <div className="settings-memberships-inner">
                {SubscriptionLoader ? (
                  <Loader type={LoaderType.HomePost} count={1} />
                ) : (
                  <div>
                    {SubscriptionList?.length > 0 ? (
                      SubscriptionList?.map((s_item, s_index) => (
                        <div className="plan-premium-main">
                          <div className="plan-header">
                            <div>
                              <div className="d-flex align-items-center gap-2">
                                <div className="plan-header-logo">
                                  <img src={logo} alt="logo" />
                                </div>
                                <div className="plan-name">
                                  <h6>{s_item?.subscription_name}</h6>
                                  {/* <p>Trainer</p> */}
                                </div>
                              </div>
                            </div>
                            {/* <div
                                      className="booking-status-badge"
                                      style={{ background: "#EF333F" }}
                                    >
                                      <p>Popular</p>
                                    </div> */}
                          </div>
                          <div className="plan-body-main">
                            {s_item?.prices?.length > 0 &&
                              s_item?.prices.map((su_item, su_index) => (
                                <div
                                  className="plan-detail-single"
                                  key={su_index}
                                >
                                  {/* <h6>
                                                ${su_item?.price}{" "}
                                                <span>
                                                  /{su_item?.interval_type}
                                                </span>
                                              </h6> */}
                                  {/* <Form.Check
                                                type="checkbox"
                                                className="circular-checkbox"
                                              /> */}
                                  <div className="red-radio-btn plan-detail-check-box">
                                    <Form.Check
                                      type="radio"
                                      name="gender"
                                      label={
                                        su_item?.price +
                                        "/" +
                                        su_item?.interval_type
                                      }
                                      id={su_item?.price}
                                      value={su_item?.price}
                                      // checked={
                                      //   input.gender_type_term ===
                                      //   su_item?.price
                                      // }
                                      // onChange={(e) => {
                                      //   setSubscribePrice(
                                      //     e.target.value
                                      //   );
                                      // }}
                                      onClick={() =>
                                        setSubscriptiondata(su_item)
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div className="plan-detail-badge">
                                    <span className="dark-badge d-block text-center">
                                      This package includes Standard Package &
                                      Trainer benefits
                                    </span>
                                  </div> */}
                          <span
                            className="dark-badge d-flex m-auto  having-list-inside"
                            dangerouslySetInnerHTML={{
                              __html: s_item?.prices[0]?.subscription_details,
                            }}
                          >
                            {/* {s_item?.prices[0]?.subscription_details} */}
                          </span>
                          <div className="membership-elements-all">
                            {s_item?.benefits?.length > 0 &&
                              s_item?.benefits.map((s_item1, s_index) => (
                                <div className="d-flex gap-2 membership-element-single p-0 mb-3">
                                  <div className="plan-header-logo">
                                    {s_item1?.term_icon !== null &&
                                    s_item1?.term_icon !== "" &&
                                    s_item1?.term_icon !== undefined ? (
                                      <img
                                        style={{
                                          width: "30px",
                                          height: "30px",
                                        }}
                                        src={s_item1?.term_icon}
                                        alt="club-detail-image"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null; // prevents looping
                                          currentTarget.src = imagePlaceholder;
                                        }}
                                      />
                                    ) : (
                                      <img
                                        src={imagePlaceholder}
                                        alt="club-detail-image"
                                      />
                                    )}
                                  </div>
                                  <div className="plan-name">
                                    <h4>{s_item1?.term_name}</h4>
                                    <p>{s_item1?.term_details}</p>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Loader type={LoaderType.NODATAFOUND} />
                    )}
                  </div>
                )}
              </div>
              <div className="mt-3">
                <button
                  className="red-btn red-border w-100"
                  onClick={() => {
                    if (Subscriptiondata) {
                      confirmAlert({
                        title: "Are you sure you want to buy this membership?",
                        buttons: [
                          {
                            label: "No",
                          },
                          {
                            label: "Yes",
                            onClick: () => fnSubscribe("buy"),
                          },
                        ],
                      });
                    } else {
                      toast.error("Select membership");
                    }
                  }}
                >
                  {NewSubscribeLoader ? "Loading..." : "Subscribe"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {SubscribeModalShow && (
        <SubsciptionModal
          show={SubscribeModalShow}
          bindList={getSubscriptionDetails}
          Tag="settingPage"
          SubscribeModalData={SubscribeModalValue}
          onHide={() => setSubscribeModalShow(false)}
        />
      )}
      {Membershipmodalshow && (
        <MembershipBuyModal
          Subscriptiondata={Subscriptiondata}
          SubscriptionList={SubscriptionList}
          MembershipDetail={MembershipDetail}
          bindList={getSubscriptionDetails}
          show={Membershipmodalshow}
          SubscribeType={SubscribeType}
          onHide={() => setMembershipmodalshow(false)}
        />
      )}
    </>
  );
};

export default SubscriptionDetailComponent;
