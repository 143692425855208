// import React from "react";
import { Form, Modal } from "react-bootstrap";

import React, { useState } from "react";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";

const CancelClubMembershipModal = (props) => {
  const [Loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    reason_for_cancellation: "",
  });
  async function handleSubmitCancel() {
    setLoading(true);
    let body = {
      user_subscription_id: props?.CancelModalValue?.user_subscription_id,
      reason_for_cancellation: input.reason_for_cancellation,
    };

    // return
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_SUBSCRIPTION_CANCEL,
        body: body,
      },
      false
    );
    let response = apiResponse(true, resData, setLoading);
    if (response?.isValidate) {
      props.onHide();
      props.setMembershipDetailList();
      // window.location.reload();
    }
  }

  return (
    <>
      <Modal className="membership-buy-modal" size="lg" centered {...props}>
        {/* <Form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
        > */}
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure you want to cancel this membership ?
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            placeholder="Reason for cancel..."
            style={{ height: "100px" }}
            onChange={(e) => {
              setInput({
                ...input,
                ["reason_for_cancellation"]: e.target.value,
              });
            }}
            className="black-colored-focused"
          />
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex gap-2 w-100">
            <button
              className="white-btn dark-color red-border w-100"
              onClick={() => props.onHide()}
            >
              No
            </button>

            <button
              className="red-btn red-border w-100"
              onClick={() => handleSubmitCancel()}
            >
              {Loading ? "Loading..." : "Yes"}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelClubMembershipModal;
