import React, { useState } from "react";
import { Form, InputGroup, Modal } from "react-bootstrap";
import searchIcon from "../images/searchIcon.svg";
import information from "../images/informaton.svg";
import Loader, { LoaderType } from "../components/Loader";

const BankStripeModal = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  function fnPhonecall(item) {
    // debugger
    // props.PhoneCode(item?.phonecode);
    // props.CountryID(item?.id);
    // props.onHide();
  }

  return (
    <>
      <Modal className="phone-code-modal-main" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Bank Stripe
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body>
          <iframe
            src={props?.BankStripeModalLink}
            title="External Link"
            width="100%"
            height="100%"
          ></iframe>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BankStripeModal;
