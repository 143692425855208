import React, { useEffect, useState } from "react";
import { apiCall } from "../../services/AxiosService";
import { API_URL } from "../../services/APIRoute";
import yellowLogo from "../../images/yellowLogo.png";
import { apiResponse } from "../../services/APIResponse";
import { Button } from "react-bootstrap";
import { commonservices } from "../../services/CommonServices";
import Loader, { LoaderType } from "../Loader";

const ClubMemberShipList = () => {
  const [MembershipDetailList, setMembershipDetailList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  async function getSubscriptionDetails() {
    setIsLoading(true);
    let body = {
      club_id: null,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.CLUB_SUBSCRIPTION_DETAILS,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setIsLoading);
    if (response?.isValidate) {
      setMembershipDetailList(response?.data?.data?.list);
    }
    if (!response?.isValidate) {
      console.log("Error getting country list", response);
    }
  }
  useEffect(() => {
    getSubscriptionDetails();
    // getClubSubscriptionList();
  }, [setMembershipDetailList]);
  return (
    <div className="settings-right-side-main">
      {isLoading ? (
        <Loader type={LoaderType.HomePost} count={1} />
      ) : (
        <>
          {MembershipDetailList?.length > 0 && (
            <>
              <h5 className="mb-3">Active Membership</h5>
              {MembershipDetailList?.map((item_ss, index_ss) => (
                <>
                  <div className="settings-has-memberships-main mb-3">
                    <div className="settings-wallet-header">
                      <div
                        className="my-single-booking align-items-center"
                        style={{ background: "transparent" }}
                      >
                        <div className="court1Image">
                          <img src={yellowLogo} alt="logo" />
                        </div>
                        <div className="courtContent court-and-court-name">
                          <h5 className="border-0 m-0 p-0">
                            {item_ss?.package_name}
                          </h5>
                          <p className="font-white">
                            Next reneewal date is{" "}
                            {commonservices.getDateInFormatCustom(
                              item_ss?.next_billing_invoice_date,
                              null,
                              "DD-MM-yyyy HH:mm:ss"
                            )}
                          </p>
                          <h5 className="border-0 m-0 p-0">
                            {"$" + item_ss?.paid_amount?.toFixed(2)}
                            <span className="font-white">
                              /{item_ss?.interval_type}
                            </span>
                          </h5>
                          {item_ss?.registration_fee && (
                            <h5 className="border-0 m-0 p-0">
                              +{"$" + item_ss?.registration_fee} Registration
                              fee
                            </h5>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="settings-has-memberships-bottom">
                      <div className="membership-element-single-wrap-up">
                        <h5>Benefits</h5>
                        <div className="membership-element-single-diff-para">
                          {item_ss?.rules?.length > 0 &&
                            item_ss?.rules.map((item_rs, index_rs) => (
                              <h6 className="py-2">
                                {item_rs?.rule}
                                {/* :{" "}
                      <span className="font-red">
                        {item_rs?.rule_value}
                      </span> */}
                              </h6>
                            ))}
                        </div>
                        {item_ss?.subscription_status === "active" &&
                          item_ss?.subscriptionTypeTerm !== "assigned" && (
                            <Button
                              className="red-btn red-border w-100 "
                              // onClick={() => fnCancelSubscribe(item_ss)}
                            >
                              Cancel
                            </Button>
                          )}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default ClubMemberShipList;
