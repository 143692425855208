import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import Loader, { LoaderType } from "../components/Loader";
import InPageNav from "../components/PageNav/InPageNav";
import calendarsmall from "../images/calendarsmall.svg";
import clock from "../images/clock.svg";
import gridView from "../images/gridView.svg";
import imagePlaceholder1 from "../images/imagePlaceholder1.png";
import tableView from "../images/tableView.svg";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import { commonservices } from "../services/CommonServices";

const MyBooking = () => {
  let param = useParams();
  let navigate = useNavigate();
  
  // MyBooking
  const pageNav = [
    {
      name: "Club",
      link: "/find-club",
      active: true,
    },
    {
      name: "My Booking",
      link: "/my-booking",
      active: false,
    },
  ];

  const [GridShow, setGridShow] = useState(true);
  const [RemoveMoreButtion, setRemoveMoreButtion] = useState(false);
  const [BookingList, setBookingList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [Loading, setLoading] = useState(false);
  async function getBookingList(pageno) {
    setLoading(true);
    let body = {};
    if (param?.id !== undefined) {
      body = {
        page: pageno,
        limit: 10,
        is_recent_bookings: param?.id,
      };
    } else {
      body = {
        page: pageno,
        limit: 10,
      };
    }
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ORDER_LIST,
        body: body,
      },
      false
    );
    let response = apiResponse(false, resData, setLoading);
    if (response?.isValidate) {
      if (pageno === 1 || pageno === undefined) {
        setBookingList(response?.data?.data?.list);
      } else {
        setBookingList((prevItems) => [
          ...prevItems,
          ...response?.data?.data?.list,
        ]);
      }
    }
    if (response?.data?.data?.totalPages) {
      if (pageno === response?.data?.data?.totalPages) {
        setRemoveMoreButtion(true);
      }
      setTotalPages(response?.data?.data?.totalPages);
      if (response?.data?.data?.totalPages > pageno) {
        setPageNumber(pageno + 1);
      }
    }
    if (!response?.isValidate) {
      console.log("Error  getting country list", response);
    }
  }

  useEffect(() => {
    getBookingList(pageNumber);
  }, []);

  // For Datatable
  $(document).ready(function () {
    $("table#myBookingTable").DataTable({
      destroy: true,
      lengthChange: false,
      searching: false,
      dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [-1] /* 1st one, start by the right */,
        },
      ],
    });
  });
  useEffect(() => {
    $(document).ready(function () {
      $("table#myBookingTable")
        .DataTable({
          destroy: true,
          lengthChange: false,
          searching: false,
          dom: "ft<'tbl-info'<'length-info'li<'paggination'p>>>",
          language: {
            info: "Showing <b> _PAGE_ </b> of <b>_PAGES_ </b>Results",
            paginate: {
              previous:
                '<span class="next-icon"><svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.25 1.5L0.75 6L5.25 10.5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/></svg></span>',
              next: '<span class="next-icon"><svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.75 1.5L5.25 6L0.75 10.5" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/></svg></span>',
            },
          },
          aoColumnDefs: [
            {
              bSortable: false,
              aTargets: [-1] /* 1st one, start by the right */,
            },
          ],
        })
        .destroy();
    });
  }, []);

  const handleLoadMore = () => {
    getBookingList(pageNumber);
  };

  function fnOnlyTrainerLink(item) {
    if (item?.items?.length > 0) {
      let temp = item?.items.filter((f) => f.item_type === "club_booking");
      
      if (temp.length > 0) {
        navigate("/booking-detail/" + item.sub_order_id);
      } else {
        navigate("/coach-detail/" + item.sub_order_id);
      }
    }
  }

  

  return (
    <div className="find-clubs-main my-booking-main">
      <div className="big-container container">
        <div className="page-top-section">
          <div className="page-top-section-left">
            <div className="show-route">
              {/* <p>Home {">"} Club</p>
            <div className="top-sec-datepicker"></div> */}
              <InPageNav pageNav={pageNav} />
            </div>
          </div>
          <div className="page-top-section-right">
            {/* <div className="top-menu-items">
              <img src={gridView} alt="gridView" />
            </div>
            <div className="top-menu-items">
              <img src={tableView} alt="tableView" />
            </div> */}
            <div
              // className="top-menu-items"
              className={
                GridShow
                  ? "top-menu-items top-menu-item-diff active"
                  : "top-menu-items top-menu-item-diff"
              }
              onClick={() => setGridShow(true)}
            >
              <img src={gridView} alt="gridView" />
            </div>
            <div
              className={
                !GridShow
                  ? "top-menu-items top-menu-item-diff active"
                  : "top-menu-items top-menu-item-diff"
              }
              onClick={() => setGridShow(false)}
            >
              <img src={tableView} alt="tableView" />
            </div>
          </div>
        </div>
        {GridShow ? (
          <>
            <div className="my-booking-wrapper mb-5">
              <div
                className={
                  BookingList.length > 0
                    ? "all-my-bookings"
                    : "all-my-bookings data-not-found"
                }
              >
                {Loading && !BookingList?.length > 0 ? (
                  <Loader type={LoaderType.BookingCard} count={6} />
                ) : BookingList?.length > 0 ? (
                  BookingList.map((item, index) => (
                    <>
                      <div className="my-single-booking d-block">
                        {item?.items?.length > 0 &&
                          item?.items.map((item_b, index_b) =>
                            item_b?.item_type === "club_booking" ? (
                              <div
                                // to={{
                                //   pathname:
                                //     "/booking-detail/" + item_b.sub_order_id,
                                // }}
                                onClick={() => fnOnlyTrainerLink(item)}
                                className={
                                  item_b?.slot_type === "drop_in cursor-pointer"
                                    ? "pink-bg only-one-trainer cursor-pointer"
                                    : "only-one-trainer cursor-pointer"
                                }
                                // className="my-single-booking"
                                key={index}
                              >
                                <>
                                  <div className="d-flex mb-2 twoInOneBooking">
                                    <div className="court1Image">
                                      {item_b?.club_image !== null &&
                                      item_b?.club_image !== "" &&
                                      item_b?.club_image !== undefined ? (
                                        <img
                                          src={item_b?.club_image}
                                          alt="club-detail-image"
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src =
                                              imagePlaceholder1;
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={imagePlaceholder1}
                                          alt="club-detail-image"
                                        />
                                      )}
                                    </div>
                                    <div className="courtContent">
                                      <div className="court-and-court-name">
                                        <p>{item_b?.court_title}</p>
                                        <h5>{item_b?.club_name}</h5>
                                      </div>
                                      <div className="booking-court-type">
                                        <p>{item_b?.game_type_term}</p>
                                      </div>
                                      {item_b?.max_no_of_players > 0 && (
                                        <div className="booking-court-type">
                                          <p>
                                            Members{" "}
                                            {item_b?.accept_player_count}
                                            {"/"}
                                            {item_b?.max_no_of_players}
                                          </p>
                                        </div>
                                      )}
                                      <div className="booking-date-and-time">
                                        <div className="booking-date">
                                          <img
                                            src={calendarsmall}
                                            alt="calendarsmall"
                                          />
                                          <p>
                                            {" "}
                                            {commonservices.getDateInFormat(
                                              item_b?.start_time
                                            )}
                                          </p>
                                        </div>
                                        <div className="booking-date">
                                          <img src={clock} alt="clock" />
                                          <p>
                                            {" "}
                                            {commonservices.getTimeInFormat(
                                              item_b?.start_time,
                                              
                                            )}{" "}
                                            -{" "}
                                            {commonservices.getTimeInFormat(
                                              item_b?.end_time
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                                {item?.order_item_status === "booked" && (
                                  <div className="booking-status-badge">
                                    <p>{item?.order_item_status}</p>
                                  </div>
                                )}
                                {item?.order_item_status === "cancelled" && (
                                  <div
                                    className="booking-status-badge"
                                    style={{ background: "#EF333F" }}
                                  >
                                    <p>{item?.order_item_status}</p>
                                  </div>
                                )}
                                {item?.order_item_status === "completed" && (
                                  <div
                                    className="booking-status-badge"
                                    style={{ background: "#E0E0E0" }}
                                  >
                                    <p style={{ color: "#000000" }}>
                                      {item?.order_item_status}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : null
                          )}
                        {item.items.map((item_b1, index_b1) =>
                          item_b1.item_type === "trainer_booking" ? (
                            <div
                              onClick={() => fnOnlyTrainerLink(item)}
                              // to={{
                              //   pathname:
                              //     "/coach-detail/" + item_b1.sub_order_id,
                              // }}
                              className={
                                item_b1.item_type === "club_booking"
                                  ? "d-flex twoInOneBooking align-items-center only-one-trainer pt-2 trainer-bottom"
                                  : "d-flex twoInOneBooking align-items-center only-one-trainer pt-2"
                              }
                            >
                              <div className="court1Image">
                                {/* <img src={imagePlaceholder1} alt="court1" /> */}
                                {item_b1?.trainer_profile !== null &&
                                item_b1?.trainer_profile !== "" &&
                                item_b1?.trainer_profile !== undefined ? (
                                  <img
                                    src={item_b1?.trainer_profile}
                                    alt="club-detail-image"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null; // prevents looping
                                      currentTarget.src = imagePlaceholder1;
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagePlaceholder1}
                                    alt="club-detail-image"
                                  />
                                )}
                              </div>
                              <div className="court-and-court-name">
                                <p>Trainer</p>
                                <h5>{item_b1?.trainer_name}</h5>
                                <div className="booking-date-and-time">
                                  <div className="booking-date">
                                    <img
                                      src={calendarsmall}
                                      alt="calendarsmall"
                                    />
                                    <p>
                                      {" "}
                                      {commonservices.getDateInFormat(
                                        item_b1?.start_time,
                                        item_b1?.timezone
                                      )}
                                    </p>
                                  </div>
                                  <div className="booking-date">
                                    <img src={clock} alt="clock" />
                                    <p>
                                      {" "}
                                      {commonservices.getTimeInFormat(
                                        item_b1?.start_time,
                                        item_b1?.timezone
                                      )}{" "}
                                      -{" "}
                                      {commonservices.getTimeInFormat(
                                        item_b1?.end_time,
                                        item_b1?.timezone
                                      )}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {item?.order_item_status === "booked" && (
                                <div className="booking-status-badge">
                                  <p>{item?.order_item_status}</p>
                                </div>
                              )}
                              {item?.order_item_status === "cancelled" && (
                                <div
                                  className="booking-status-badge"
                                  style={{ background: "#EF333F" }}
                                >
                                  <p>{item?.order_item_status}</p>
                                </div>
                              )}
                              {item?.order_item_status === "completed" && (
                                <div
                                  className="booking-status-badge"
                                  style={{ background: "#E0E0E0" }}
                                >
                                  <p style={{ color: "#000000" }}>
                                    {item?.order_item_status}
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : null
                        )}
                      </div>
                    </>
                  ))
                ) : (
                  <div className="text-center">
                    You have not done any bookings as yet
                  </div>
                )}
              </div>
              {Loading && BookingList?.length > 0 && (
                <div className="mt-2">
                  <Loader type={LoaderType.BookingCard} count={6} />
                </div>
              )}
              {!RemoveMoreButtion && (
                <div className="text-center w-100 mb-3 view-more-btn">
                  <button
                    className="red-btn red-border width150"
                    onClick={handleLoadMore}
                    disabled={Loading}
                  >
                    {Loading ? "Loading..." : "View More"}
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <Col>
              {Loading ? (
                <>
                  <Loader type={LoaderType.Table} count={5} />
                </>
              ) : (
                <>
                  <Row>
                    <Table
                      //  id="myBookingTable"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th className="text-center width250">TITLE</th>
                          <th className="width100">DATE</th>
                          <th>TIME</th>
                          <th className="width150">TYPE</th>
                          <th>STATUS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BookingList.length > 0 &&
                          BookingList.map((item, index) => (
                            <>
                              {item?.items.length > 0 &&
                                item?.items.map((item_c, item_i) =>
                                  item_c?.item_type === "club_booking" ? (
                                    <tr>
                                      <td>
                                        <Link
                                          to={{
                                            pathname:
                                              "/booking-detail/" +
                                              item.sub_order_id,
                                          }}
                                        >
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="">
                                              {item_c?.club_image !== null &&
                                              item_c?.club_image !== "" &&
                                              item_c?.club_image !==
                                                undefined ? (
                                                <img
                                                  src={item_c?.club_image}
                                                  alt="club-detail-image"
                                                  className="profile-image-in-table"
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      imagePlaceholder1;
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={imagePlaceholder1}
                                                  alt="club-detail-image"
                                                  className="profile-image-in-table"
                                                />
                                              )}
                                            </div>
                                            <div className="booking-detail-image-content m-0">
                                              <div className="courtContent w-100">
                                                <div className="court-and-court-name">
                                                  <p
                                                    className="mb-0"
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {item_c?.court_title}
                                                  </p>
                                                  <h5
                                                    style={{ fontSize: "16px" }}
                                                    className="my-booking-table-heading"
                                                  >
                                                    {item_c?.club_name}
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                      <td>
                                        {commonservices.getDateInFormat(
                                          item_c?.start_time
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {commonservices.getTimeInFormat(
                                          item_c?.start_time
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          item_c?.end_time
                                        )}
                                      </td>
                                      <td>{item_c?.game_type_term}</td>
                                      <td>
                                        <div className="all-action-main">
                                          {item?.order_item_status ===
                                            "booked" && (
                                            <span className="green-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "confirm" && (
                                            <span className="yellow-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "cancelled" && (
                                            <span className="red-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "completed" && (
                                            <span className="grey-badge">
                                              <p style={{ color: "#000000" }}>
                                                {item?.order_item_status}
                                              </p>
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ) : (
                                    // <tr>Comming Soon</tr>
                                    <tr>
                                      <td>
                                        <Link
                                          // to={{
                                          //   pathname:
                                          //     "/booking-detail/" +
                                          //     item.sub_order_id,
                                          // }}
                                          to={{
                                            pathname:
                                              "/coach-detail/" +
                                              item.sub_order_id,
                                          }}
                                        >
                                          <div className="d-flex align-items-center gap-2">
                                            <div className="">
                                              {item_c?.trainer_profile !==
                                                null &&
                                              item_c?.trainer_profile !== "" &&
                                              item_c?.trainer_profile !==
                                                undefined ? (
                                                <img
                                                  src={item_c?.trainer_profile}
                                                  alt="club-detail-image"
                                                  style={{ objectFit: "cover" }}
                                                  onError={({
                                                    currentTarget,
                                                  }) => {
                                                    currentTarget.onerror =
                                                      null;
                                                    currentTarget.src =
                                                      imagePlaceholder1;
                                                  }}
                                                  className="profile-image-in-table"
                                                />
                                              ) : (
                                                <img
                                                  src={imagePlaceholder1}
                                                  alt="club-detail-image"
                                                  className="profile-image-in-table"
                                                />
                                              )}
                                            </div>
                                            <div className="booking-detail-image-content m-0">
                                              <div className="courtContent w-100">
                                                <div className="court-and-court-name">
                                                  <p
                                                    className="mb-0"
                                                    style={{ fontSize: "12px" }}
                                                  >
                                                    {/* {item_c?.court_title} */}
                                                    Trainer
                                                  </p>
                                                  <h5
                                                    style={{ fontSize: "16px" }}
                                                    className="my-booking-table-heading"
                                                  >
                                                    {item_c?.trainer_name}
                                                  </h5>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </td>
                                      <td>
                                        {commonservices.getDateInFormat(
                                          item_c?.start_time
                                        )}
                                      </td>
                                      <td>
                                        {" "}
                                        {commonservices.getTimeInFormat(
                                          item_c?.start_time
                                        )}{" "}
                                        -{" "}
                                        {commonservices.getTimeInFormat(
                                          item_c?.end_time
                                        )}
                                      </td>
                                      <td>-</td>
                                      <td>
                                        <div className="all-action-main">
                                          {item?.order_item_status ===
                                            "booked" && (
                                            <span className="green-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "confirm" && (
                                            <span className="yellow-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "cancelled" && (
                                            <span className="red-badge">
                                              <p>{item?.order_item_status}</p>
                                            </span>
                                          )}
                                          {item?.order_item_status ===
                                            "completed" && (
                                            <span className="grey-badge">
                                              <p style={{ color: "#000000" }}>
                                                {item?.order_item_status}
                                              </p>
                                            </span>
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </Row>
                </>
              )}
            </Col>
          </>
        )}
      </div>
    </div>
  );
};

export default MyBooking;
