import axios from "axios";
import React from "react";
import { LoginSocialFacebook } from "reactjs-social-login";
import fbIcon from "../images/fb.svg";
import { apiCall } from "../services/AxiosService";
import { API_URL } from "../services/APIRoute";
import { apiResponse } from "../services/APIResponse";
import { UserInfo } from "../Redux/Action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const FacebookPage = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const handleLogin = async (provider, data) => {
    let res = await axios(
      `https://graph.facebook.com/${data.userID}?fields=id,name,email,picture&access_token=` +
        data.accessToken
    );
    // console.log("Token ::", data.accessToken);
    // console.log("final response ::", res);

    let body = {
      authentication_type: "facebook",
      social_token: data.accessToken,
      social_id: res.data.id,
      email: res.data.email,
      member_name: res.data.name,
      nickname: res.data.name,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.LOGIN,
        body: body,
      },
      true
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      if (response?.data?.data?.is_user_exists === 0) {
        let body1 = {
          social_type_term: "facebook",
          social_token: data.accessToken,
          social_id: res.data.id,
          email: res.data.email,
          member_name: res.data.name,
          nickname: res.data.name,
        };
        let resData1 = await apiCall(
          {
            method: "POST",
            url: API_URL.BASEURL + API_URL.SIGNUP,
            body: body1,
          },
          true
        );
        let response1 = apiResponse(true, resData1);
        if (response1?.isValidate) {
          dispatch(UserInfo(response1?.data.data));
          localStorage.setItem("Token", response1?.data.data.auth_token);
          if (response1?.data.data.is_profile_step_completed !== 1) {
            navigate("/signup-steps");
            window.location.reload();
          } else {
            window.location.reload();
          }
        }
      } else {
        if (response?.data.data.is_profile_step_completed !== 1) {
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          navigate("/signup-steps");
          window.location.reload();
        } else {
          dispatch(UserInfo(response?.data.data));
          localStorage.setItem("Token", response?.data.data.auth_token);
          localStorage.setItem("TokenStep", "Pickler");
          window.location.reload();
        }
      }
    }
  };

  // console.log(
  //   "process.env.REACT_APP_FACEBOOK_APPID",
  //   process.env.REACT_APP_FACEBOOK_APPID
  // );

  return (
    <>
      <div>
        <div>
          <LoginSocialFacebook
            isOnlyGetToken
            appId={process.env.REACT_APP_FACEBOOK_APPID}
            onResolve={({ provider, data }) => {
              handleLogin(provider, data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <img src={fbIcon} alt="fb" title="facebook" />
          </LoginSocialFacebook>
        </div>
      </div>
    </>
  );
};

export default FacebookPage;
