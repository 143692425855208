import { commonservices } from "../../services/CommonServices";
export const UserInfoInitState = {
  UserInfo: commonservices.getDecryptData("UserInfo")
    ? JSON.parse(commonservices.getDecryptData("UserInfo"))
    : [],
  AppSetting: commonservices.getDecryptData("AppSetting")
    ? JSON.parse(commonservices.getDecryptData("AppSetting"))
    : [],
  FinalSlots: localStorage.getItem("FinalSlots")
    ? JSON.parse(localStorage.getItem("FinalSlots"))
    : [],
  // UserInfo: localStorage.getItem("UserInfo")
  //   ? JSON.parse(localStorage.getItem("UserInfo"))
  //   : [],
  ClubFilter: localStorage.getItem("clubFilter")
    ? JSON.parse(localStorage.getItem("clubFilter"))
    : "",
  TrainerFilter: localStorage.getItem("trainerFilter")
    ? JSON.parse(localStorage.getItem("trainerFilter"))
    : "",
  ClubFilterDate: localStorage.getItem("clubFilterdate")
    ? JSON.parse(localStorage.getItem("clubFilterdate"))
    : [],
  CartData: localStorage.getItem("cartData")
    ? JSON.parse(localStorage.getItem("cartData"))
    : [],
};
