import React from "react";
import { Modal } from "react-bootstrap";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import profile from "../images/post2.png";
import { useNavigate } from "react-router-dom";

const StripePaymentModal = (props) => {
  console.log("sss");
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
console.log("SettingShow ::",props.setSettingShow);
  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (props?.PayType === "Wallet") {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      props.setSettingShow("Wallet");
      setIsProcessing(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/settings/`,
        },
      });

      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsProcessing(false);
      props.onHide();
    } else if (props?.PayType === "confirmpage") {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
      setIsProcessing(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/cart-confirmation/`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsProcessing(false);
      // props.onHide();
    } else {
      if (!stripe || !elements) {
        return;
      }
      setIsProcessing(true);
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success/${props?.PaymentData?.order_details.order_id}`,
        },
      });
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsProcessing(false);
    }
    // props.onHide();
  };
  return (
    <>
      <Modal className="" size="md" centered {...props}>
        <Modal.Header closeButton>
          <div style={{ visibility: "hidden" }}>A</div>
          <Modal.Title id="contained-modal-title-vcenter">
            Stripe Payment
          </Modal.Title>
          <div style={{ visibility: "hidden" }}>A</div>
        </Modal.Header>

        <Modal.Body>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement id="payment-element" />
            <button
              disabled={isProcessing || !stripe || !elements}
              id="submit"
              className="red-btn red-border w-100 mt-4"
            >
              {props?.PayType === "Wallet" ? (
                <span id="button-text">
                  {isProcessing
                    ? "Processing ..."
                    : `Pay now ${
                        "$" + props?.PaymentData?.transaction_details?.amount
                      }`}
                </span>
              ) : (
                <span id="button-text">
                  {isProcessing ? "Processing ..." : `Pay now`}
                </span>
              )}
            </button>
            {/* Show any error or success messages */}
            {/* {message && <div id="payment-message">{message}</div>} */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          {/* {Loading ? (
              <button type="submit" className="red-btn w-100">
                Loading...
              </button>
            ) : (
              <button className="red-btn otp-btn w-100">Next</button>
            )} */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StripePaymentModal;
