import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { apiResponse } from "../services/APIResponse";
import { API_URL } from "../services/APIRoute";
import { apiCall } from "../services/AxiosService";
import StripePaymentModal from "./StripePaymentModal";

function AddBalanceModal(props) {
  const [PaymentData, setPaymentData] = useState();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const [StripModalShow, setStripModalShow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  const [BalancePrice, setBalancePrice] = useState(0);

  async function fnStripePayment(amount) {
    setLoading(true)
    let body = {
      amount: amount,
    };
    let resData = await apiCall(
      {
        method: "POST",
        url: API_URL.BASEURL + API_URL.ADD_MONEY_TO_WALLET,
        body: body,
      },
      true
    );
    let response = apiResponse(false, resData);
    if (response?.isValidate) {
      setLoading(false)
      setClientSecret(response?.data?.data.payment_intent.client_secret);
      setPaymentData(response?.data.data);
      setStripModalShow(true);
    }
    if (!response?.isValidate) {
      console.log("Error when register new user", response);
    }
  }
  return (
    <Modal className="add-balance-modal-main" size="md" centered {...props}>
      <Modal.Header closeButton>
        <div style={{ visibility: "hidden" }}>A</div>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Balance
        </Modal.Title>
        <div style={{ visibility: "hidden" }}>A</div>
      </Modal.Header>
      <Modal.Body>
        <div className="add-balance-inner">
          <div className="text-center add-balance-top">
            <p>Available Balance</p>
            {props?.PayType === "confirmpage" ? (
              <>
                {props?.CurrentBalance === null ? (
                  <h6 className="fw-500">
                    $<b>0</b>
                  </h6>
                ) : (
                  <h6 className="fw-500">
                    $<b>{props?.CurrentBalance}</b>
                  </h6>
                )}
              </>
            ) : (
              <>
                {props?.WalletCurrentBalance <= 0 ? (
                  <h6 className="fw-500">
                    $<b>0</b>
                  </h6>
                ) : (
                  <h6 className="fw-500">
                    $<b>{props?.WalletCurrentBalance}</b>
                  </h6>
                )}
              </>
            )}
            {/* {props?.CurrentBalance !== null ? (
              <h6 className="fw-500">
                $<b>0</b>
              </h6>
            ) : (
              <>
                {props?.WalletCurrentBalance < 0 ? (
                  <h6 className="fw-500">
                    $<b>0</b>
                  </h6>
                ) : (
                  <h6 className="fw-500">
                    $<b>{props?.WalletCurrentBalance}</b>
                  </h6>
                )}
              </>
            )} */}
          </div>
          <div className="add-no-of-people-main add-balance-inner-main">
            <h5>Add Balance</h5>

            <Form.Control
              placeholder={"$" + BalancePrice}
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              maxLength={6}
              aria-label="0.00"
              aria-describedby="basic-addon1"
              className="black-colored-focused text-start"
              style={{ fontSize: "40px" }}
              onChange={(e) => {
                setBalancePrice(e.target.value);
              }}
            />
            <div className="all-duration-main">
              <div
                // className="duration-inner active"
                className={
                  BalancePrice === 50
                    ? "duration-inner active"
                    : "duration-inner"
                }
                onClick={() => setBalancePrice(50)}
              >
                $50.00
              </div>
              <div
                className={
                  BalancePrice === 75
                    ? "duration-inner active"
                    : "duration-inner"
                }
                onClick={() => setBalancePrice(75)}
              >
                $75.00
              </div>
              <div
                className={
                  BalancePrice === 100
                    ? "duration-inner active"
                    : "duration-inner"
                }
                onClick={() => setBalancePrice(100)}
              >
                $100.00
              </div>
              <div
                className={
                  BalancePrice === 125
                    ? "duration-inner active"
                    : "duration-inner"
                }
                onClick={() => setBalancePrice(125)}
              >
                $125.00
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {BalancePrice > 0 && (
          <button
            className="red-btn red-border w-100"
            onClick={() => fnStripePayment(BalancePrice)}
          >
            {/* Add ${BalancePrice} */}
            {Loading ? 'Loading...':`Add $${BalancePrice}`}
          </button>
        )}
      </Modal.Footer>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          {StripModalShow && (
            <StripePaymentModal
              PaymentData={PaymentData}
              // PayType="Wallet"
              PayType={
                props?.PayType === "confirmpage" ? "confirmpage" : "Wallet"
              }
              show={StripModalShow}
              onHide={() => {
                setStripModalShow(false);
                props.onHide();
              }}
              setSettingShow={props.setSettingShow}
            />
          )}
        </Elements>
      )}
    </Modal>
  );
}

export default AddBalanceModal;
